import axios from 'axios';
import Store from './Store';
import { getItem, setItem } from './indexDB/db';
import { AppDefaults, Utils } from '../helpers';
import {
  setLoggedInUserRole,
  setOrgInfo,
  setPartnerOrgData,
  setSelfAccounData,
  setSelfStatus,
} from './reducers/OrganizationsReducer';

export const getOrganizations = async (route) => {
  if (!route) return;
  const res = await axios.get(route, Utils.requestHeader());
  const response = res?.data;
  let result;

  // Set organizations if request is successful
  if (response?.meta?.code === 200) {
    let organizationsListData = response?.data?.organizations;
    Utils.vmsLogger().log('organizationsListData', organizationsListData);

    let loggedInUserData = response?.data?.account;
    if (loggedInUserData) {
      Store.dispatch(setSelfAccounData(loggedInUserData));
      Store.dispatch(setLoggedInUserRole(loggedInUserData?.role));
    }
    if (organizationsListData) {
      Store.dispatch(setSelfStatus('1'));
      let primaryOrg = organizationsListData?.filter((e) => {
        return e.orgType === 'INSTALLER_ORG';
      });
      if (primaryOrg.length === 1) {
        let primaryOrgData = primaryOrg[0];
        Store.dispatch(setPartnerOrgData(primaryOrgData));
      }

      let custOrgs = organizationsListData?.filter((e) => {
        return e.orgType !== 'INSTALLER_ORG';
      });
      let primaryOrgData = organizationsListData[0];
      if (
        loggedInUserData?.orgType == AppDefaults?.ORG_TYPE_CUSTOMER &&
        primaryOrgData
      ) {
        Store.dispatch(
          setOrgInfo({
            orgId: primaryOrgData?.orgId,
            orgName: primaryOrgData?.orgName,
            shortName: primaryOrgData?.shortName,
          }),
        );
      }
      if (custOrgs.length >= 1) {
        const orgs = await getCustomerOrgData();
        if (
          custOrgs &&
          custOrgs?.length === 1 &&
          custOrgs?.[0]?.orgType === AppDefaults.ORG_TYPE_CUSTOMER
        ) {
          const devices = orgs?.[0]?.devices;
          const locations = orgs?.[0]?.locations;

          custOrgs[0] = {
            ...custOrgs[0], // Spread the existing object at index 0
            devices,
            locations,
          };
          await setCustomerOrgData(custOrgs);
        } else {
          await setCustomerOrgData(custOrgs);
        }
      }
    }

    result = {
      status: 'success',
      msg: 'organizations listing data retrieved`',
    };
  } else {
    if (res?.code) {
      result = { status: 'failure', msg: `${res.code}: ${res.message}` };
    } else if (response?.data) {
      result = { status: 'failure', msg: response?.data?.userMsg };
    } else {
      result = { status: 'failure', msg: 'Unknown internal API error' };
    }
  }
  return result;
};

export const getCustomerOrgData = async () => {
  return await getItem('customerOrgData');
};

export const setCustomerOrgData = async (data) => {
  return await setItem('customerOrgData', data);
};

export const resetCustomerOrgData = async () => {
  return await setItem('customerOrgData', []);
};
