import { useCallback, useEffect, useState } from 'react';
import { Button, Container, Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
// import { getSelectedOrganization } from '../../../../store/reducers/AccountReducer';

import { PrimaryButton, SiteToast } from '../../../../components/common';
import { LicenseStatuses, Utils, constants } from '../../../../helpers';
import useLicensesStore from '../../../../store/LicensesStore';

import './DetachLicenseForm.scss';
import { getSelectedOrganization } from '../../../../store/AccountStoreIDB';
import { observerInstance } from '../../../../store/indexDB/observer';
import useDebouncedCallback from '../../../../hooks/useDebouncedCallback';
import { getOrgInfo } from '../../../../store/reducers/OrganizationsReducer';
import { LicenseType } from '../../../../helpers/enums';

const DetachLicenseForm = ({
  deviceId,
  licenseKey,
  orgId,
  licenseRecType,
  callBack,
}) => {
  const [userMsg, setUserMsg] = useState('');
  const orgInfo = useSelector(getOrgInfo);

  const { setLicenses } = useLicensesStore();
  // const orgDetails = useSelector(getSelectedOrganization);
  const [orgDetails, setOrgDetails] = useState();
  const [showLoader, setShowLoader] = useState(false);

  const loadSelectedOrgData = useCallback(async () => {
    const org = await getSelectedOrganization();
    setOrgDetails(org || {});
  }, []);

  const debouncedLoadSelectedOrgData = useDebouncedCallback(
    loadSelectedOrgData,
    1000,
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'selectedOrganization') {
        await debouncedLoadSelectedOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadSelectedOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadSelectedOrgData]);

  const detachLicenseFromDevice = async () => {
    setShowLoader(true);
    setUserMsg('');
    try {
      if (!deviceId || !licenseKey) {
        return;
      }

      const reqBody = {
        deviceId: deviceId,
        licenseKey: licenseKey,
      };

      const res = await axios.put(
        `/user/orgs/${orgDetails?.orgId ? orgDetails?.orgId : orgInfo?.orgId}/licenses/detach/v2`,
        reqBody,
        Utils.requestHeader(),
      );

      if (res?.data?.meta?.code === 200) {
        await setLicenses(`/user/orgs/${orgId}/licenses`);
        setShowLoader(false);
        if (
          res?.data?.data?.[0]?.licenseStatus ==
          LicenseStatuses?.ACTIVE_UNASSIGNED
        ) {
          if (res?.data?.data?.[0]?.licenseType !== LicenseType?.ADD_ON) {
            callBack(true);
          } else {
            callBack(false);
          }
        } else {
          callBack(false);
        }
      } else {
        setUserMsg(res?.data.meta.userMsg);
        setShowLoader(false);
      }
    } catch (error) {
      setUserMsg(error.msg);
      setShowLoader(false);
    }
  };

  return (
    <Container>
      <Row>
        {licenseRecType === LicenseStatuses.EDGELICENSE ? (
          <div
            className="p-0 m-t-15"
            dangerouslySetInnerHTML={{
              __html: constants.DEVICE_LICENSE_DEACTIVATION_WARNING,
            }}
          />
        ) : (
          constants.ORG_DASHBOARD_LICENSES_DETACH_LICENSE_DESCRIPTION
        )}
      </Row>
      <Row>
        <Col>
          <div
            className={`detach-license-button-wrapper ${licenseRecType === LicenseStatuses.EDGELICENSE ? '' : 'display-f'}`}
          >
            {licenseRecType === LicenseStatuses.EDGELICENSE ? (
              <>
                <PrimaryButton
                  className="cancel-button m-t-15"
                  onClick={() => {
                    callBack && callBack(false);
                  }}
                >
                  {constants.ORG_DASHBOARD_LICENSES_CANCEL_LABEL}
                </PrimaryButton>
                <PrimaryButton
                  variant="outline-danger"
                  className="detach-license-button m-t-10 w-600"
                  backgroundColor={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue(
                    showLoader ? '--brand_primary' : '--brand_white',
                  )}
                  color={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--brand_primary')}
                  hoverColor={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--brand_white')}
                  loader={showLoader}
                  onClick={() => detachLicenseFromDevice()}
                >
                  {constants.LICENSE_CONTINUE_TEXT}
                </PrimaryButton>
              </>
            ) : (
              <div className="m-t-10">
                <PrimaryButton
                  width="158px"
                  variant="outline-danger"
                  className="detach-license-button"
                  backgroundColor={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue(
                    showLoader ? '--brand_primary' : '--brand_white',
                  )}
                  color={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--brand_primary')}
                  hoverColor={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--brand_white')}
                  loader={showLoader}
                  onClick={() => detachLicenseFromDevice()}
                >
                  {constants.LICENSE_CONTINUE_TEXT}
                </PrimaryButton>
                <PrimaryButton
                  className="cancel-button"
                  width="78px"
                  onClick={() => {
                    callBack && callBack(false);
                  }}
                >
                  {constants.ORG_DASHBOARD_LICENSES_CANCEL_LABEL}
                </PrimaryButton>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <SiteToast
        customCss="analytics-toast"
        position="top-end"
        show={!!userMsg}
        title={constants.ERROR_TOAST_TITLE}
        body={userMsg}
        variant="error"
        delay={5000}
      />
    </Container>
  );
};

export default DetachLicenseForm;
