import { Utils } from '../../helpers';
import store from '../../store/Store';
import {
  getChannelTimeStamp,
  setChannelTimeStamp,
  setIsRemoteStreamPlay,
  setLiveStream,
  setRemoteStreamAudio,
  setSinglePlaybackLoaded,
  setStremBitmapData,
} from '../../store/reducers/StreamingReducer';
import * as wsConnection from '../connection/wssConnection';

let peerConnection;
let updatedTimestamp;

export const getLocalStream = (serverDetails, deviceId) => {
  createPeerConnection(serverDetails, deviceId);
};

const createPeerConnection = (serverDetails, deviceId) => {
  const configuration = {
    iceServers: [
      {
        urls: `${serverDetails?.stun.protocol}:${serverDetails?.stun.host}:${serverDetails?.stun.port}`,
      },
      {
        urls: `${serverDetails?.turn.protocol}:${serverDetails?.turn.host}:${serverDetails?.turn.port}`,
        username: `${serverDetails.turn.userName}`,
        credential: `${serverDetails.turn.password}`,
      },
    ],
    iceTransportPolicy: 'all',
    rtcpMuxPolicy: 'require',
    bundlePolicy: 'balanced',
  };
  Utils.vmsLogger().log('createPeerConnection ~ configuration:', configuration);

  peerConnection = new RTCPeerConnection(configuration);

  // create data channel to receive timestamp
  const channel = peerConnection.createDataChannel('data');

  channel.onopen = (e) => {
    Utils.vmsLogger().log(
      'Playback: data channel is open',
      deviceId,
      ' ~ ',
      Utils.getTimesinmili(),
    );
  };

  channel.onmessage = (message) => {
    if (message?.data) {
      const timestamp = message.data.replace('Timestamp:', '');
      if (!store.getState(getChannelTimeStamp)?.streaming?.channelTimeStamp) {
        store.dispatch(setChannelTimeStamp(true));
      }
      if (updatedTimestamp !== parseInt(timestamp, 10)) {
        updatedTimestamp = parseInt(timestamp, 10);
      }
    }
  };

  channel.onerror = (error) => {
    Utils.vmsLogger().log('Playback: Data-channel error', error);
  };

  channel.onclose = (e) => {
    Utils.vmsLogger().log('Playback: Data-channel close', e);
  };

  // const transceiver = peerConnection.addTransceiver('video', {
  //   direction: 'recvonly',
  // });
  // const capabilities = RTCRtpSender.getCapabilities('video').codecs;
  // let h264_codecs = [];
  // // iterate over supported codecs and pull out the codecs we want
  // capabilities.forEach((codec, idx) => {
  //   if (
  //     codec.mimeType === 'video/H264' &&
  //     codec.sdpFmtpLine.includes('packetization-mode=1')
  //   ) {
  //     h264_codecs.push(codec);
  //   }
  // });
  // transceiver.setCodecPreferences(h264_codecs);
  // const transceiver_a = peerConnection.addTransceiver('audio', {
  //   direction: 'recvonly',
  // });
  // const capabilities_a = RTCRtpSender.getCapabilities('audio').codecs;
  // let audio_codecs = [];
  // // iterate over supported codecs and pull out the codecs we want
  // capabilities_a.forEach((codec, idx) => {
  //   if (
  //     codec.mimeType === 'audio/PCMU' ||
  //     codec.mimeType === 'audio/PCMA' ||
  //     codec.mimeType === 'audio/opus'
  //   ) {
  //     audio_codecs.push(codec);
  //   }
  // });
  // transceiver_a.setCodecPreferences(audio_codecs);

  peerConnection.addTransceiver('video', {
    direction: 'recvonly',
  });

  peerConnection.addTransceiver('audio', {
    direction: 'recvonly',
  });

  sendOffer();
  // peerConnection.onnegotiationneeded = (ev) => {
  //   peerConnection.createOffer()
  //     .then((offer) => peerConnection.setLocalDescription(offer))
  //     .then(() =>
  //         sendOffer(),
  //     )
  //     .catch((err) => {
  //       // handle error
  //       Utils.vmsLogger().error(err)
  //     });
  // };

  peerConnection.ontrack = ({ streams: [stream] }) => {
    if (stream.getAudioTracks().length) {
      store.dispatch(setRemoteStreamAudio(stream));
    }
    if (stream?.getVideoTracks()?.length) {
      const track = stream?.getVideoTracks()?.[0];

      const video = document.createElement('video');
      video.srcObject = new MediaStream([track]);
      video.addEventListener('loadedmetadata', () => {
        // const aspectRatio = 16 / 9;
        let width = video.videoWidth;
        let height = video.videoHeight;
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(video, 0, 0, width, height);
        // const imageData = ctx.getImageData(0, 0, width, height);
        const data = {
          StreamHeight: height,
          StreamWidth: width,
          // You may need to handle imageData to send it or process it further
        };
        store.dispatch(setStremBitmapData(data));
      });
      video.play().catch((error) => {
        Utils.vmsLogger().error('Error playing video: ', error);
      });
    }
    store.dispatch(setLiveStream(stream));
  };

  peerConnection.onicecandidate = (event) => {
    if (event.candidate) {
      wsConnection.sendWebRTCCandidate(event);
    }
  };

  peerConnection.onconnectionstatechange = (event) => {
    if (peerConnection.connectionState === 'connected') {
      Utils.vmsLogger().log(
        'Playback: peer connection connected',
        deviceId,
        ' ~ ',
        Utils.getTimesinmili(),
      );
    } else if (peerConnection.connectionState === 'failed') {
      createPeerConnection();
    }
  };
};

export const renderTimestamp = () => {
  return updatedTimestamp;
};

const sendOffer = async () => {
  peerConnection.onnegotiationneeded = (ev) => {
    peerConnection
      .createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      })
      .then((offer) => {
        peerConnection.setLocalDescription(offer);
        wsConnection.sendWebRTCOffer(offer);
      })
      .catch((err) => {
        // handle error
        Utils.vmsLogger().error(err);
      });
  };

  // const offer = await peerConnection.createOffer({
  //   offerToReceiveAudio: true,
  //   offerToReceiveVideo: true,
  // });

  // await peerConnection.setLocalDescription(offer);
  // wsConnection.sendWebRTCOffer(offer);
};

export const handleAnswer = async (data) => {
  await peerConnection
    ?.setRemoteDescription(new RTCSessionDescription(data))
    .then((data) => {})
    .catch((error) => Utils.vmsLogger().error('error', error));
};

export const handleCandidate = async (data, device_id) => {
  Utils.vmsLogger().log(
    'Playback:  handleCandidate ~ candidate:',
    device_id,
    ' ',
    data.candidate.candidate,
    Utils.getTimesinmili(),
  );
  try {
    if (peerConnection.localDescription) {
      var candidate = new RTCIceCandidate(data.candidate);
      peerConnection
        .addIceCandidate(candidate)
        .catch((err) => Utils.vmsLogger().log('err/...', err));
    }
  } catch (err) {
    Utils.vmsLogger().error(
      'Playback: error occured when trying to add received ice candidate',
      err,
    );
  }
};

export const handleLeaveCall = async () => {
  store.dispatch(setLiveStream(null));
  if (peerConnection) {
    peerConnection.close();
    peerConnection.onicecandidate = null;
    peerConnection.ontrack = null;
    peerConnection = null;
  }
};
