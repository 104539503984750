import { useEffect, useRef, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { constants, Utils } from '../../helpers';
import { Spinner } from './';

import useEventsStore from '../../store/EventsStore';

import { ArrowRight } from 'react-bootstrap-icons';
import NoSnapshot from '../../assets/images/nosnapshot.svg';

import 'react-lazy-load-image-component/src/effects/blur.css';

const ImageFetch = ({
  customClass,
  time,
  imageURL = null,
  cdnValue,
  deviceId,
  liveSnapshot,
  clipId = null,
  clipSnapshot = false,
  sourceURL,
  cacheName,
  isFromSearchDashboard = false,
  deviceName,
  onLiveClick,
  scrollPosition,
  handleImageFetchError = null,
}) => {
  const [newSrc, setNewSrc] = useState('');
  const [selectedSnapSrc, setSelectedSnapSrc] = useState('');
  const [snapshot, setSnapshot] = useState(null);

  const [hovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(!hovered);

  const { getSelectedEvent } = useEventsStore();

  const imageRef = useRef();

  useEffect(() => {
    const checkAspectRatio = () => {
      const imageSnapshot = imageRef.current;

      if (liveSnapshot && imageSnapshot) {
        imageSnapshot.onload = () => {
          const w = imageSnapshot.naturalWidth;
          const h = imageSnapshot.naturalHeight;
          const r = Utils?.getGreatestCommonDivisor(w, h);
        };
      }
    };

    checkAspectRatio();
  }, []);

  useEffect(() => {
    const newTime = Utils.getUnixDate(Utils.getDate(time / 1000));
    const cacheKey = Utils.generateCacheKey(clipId);
    let cache, cachedResponse;

    const loadImage = async () => {
      let imageSource = await Utils.getJpegFromCache(
        imageURL,
        sourceURL,
        cacheName,
        clipId,
      );

      Utils.vmsLogger().info('*** ImageFetch imageSource: ', imageSource);

      setNewSrc(imageSource);
    };

    const isImageCached = async () => {
      let response = false;
      try {
        cache = await caches.open(cacheName);
        response = await cache.match(cacheKey);
      } catch (error) {
        Utils.vmsLogger().error(error);
      } finally {
        return response;
      }
    };

    const getCachedImage = async (response) => {
      // Fetch the image from cache
      const blob = await response.blob();

      return URL.createObjectURL(blob);
    };

    const setImageSource = async () => {
      try {
        let eventSnapshot;

        if (
          deviceId &&
          newTime &&
          (!imageURL || imageURL === 'fetch') &&
          cdnValue?.protocol &&
          cdnValue?.host
        ) {
          cachedResponse = await isImageCached();
          if (cachedResponse) {
            // Fetch the image from cache
            eventSnapshot = await getCachedImage(cachedResponse);
            setNewSrc(eventSnapshot);
          } else {
            const bucket = cdnValue.bucket.replace('${deviceId}', deviceId);
            const date = Utils.fetchDateInUnix(newTime);
            let responseClone;

            const snapshotURL =
              sourceURL ||
              `${cdnValue?.protocol}://${cdnValue?.host}/${bucket}/${date}/${newTime}.jpg`;

            fetch(snapshotURL, {
              credentials: 'include',
            })
              .then((response) => {
                responseClone = response.clone();

                // Save to Cache Storage if we're able to retrieve
                // the snapshot image
                if (response.ok === true) {
                  cache?.put(cacheKey, responseClone);
                }

                return response.blob();
              })
              .then((blob) => {
                Utils.vmsLogger().info(
                  '*** ImageFetch setImageSource blob: ',
                  blob,
                );
                if (
                  getSelectedEvent() &&
                  Utils.getUnixDate(
                    Utils.getDate(getSelectedEvent() / 1000),
                  ) === newTime
                ) {
                  setSelectedSnapSrc(URL.createObjectURL(blob));
                  setNewSrc(URL.createObjectURL(blob));
                } else if (blob?.type) {
                  Utils.vmsLogger().log(
                    '*** ImageFetch setImageSource blob.type: ',
                    blob.type,
                  );
                  setNewSrc(URL.createObjectURL(blob));
                } else {
                  setNewSrc(blob);
                }
              });
          }
        } else if (imageURL) {
          Utils.vmsLogger().info('*** ImageFetch imageURL: ', imageURL);

          if (clipSnapshot) {
            loadImage();
          } else {
            setNewSrc(imageURL);
          }
        }
      } catch (error) {
        Utils.vmsLogger().error(error);
      }
    };

    try {
      setImageSource();
    } catch (error) {
      Utils.vmsLogger().error(error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, deviceId]);

  return (
    <div
      id="image_wrapper"
      className={`clip-snapshot-image-wrapper ${
        customClass ? customClass : ''
      }`}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
    >
      {deviceName && (
        <>
          <span className={hovered ? 'hovered device-name' : 'device-name'}>
            {deviceName}
          </span>
          <span
            onClick={() => onLiveClick()}
            className={hovered ? 'hovered detail-camera' : 'detail-camera'}
          >
            {constants.CAMERA_DETAIL}
            <ArrowRight width={18} />
          </span>
        </>
      )}
      {liveSnapshot ? (
        <img
          ref={imageRef}
          id={`imgSnapshot_${time}`}
          src={snapshot}
          alt=""
          className="live-snapshot"
          onLoad={(event) => {}}
          onError={(event) => (event.target.src = NoSnapshot)}
        />
      ) : (
        <>
          {selectedSnapSrc || newSrc ? (
            <LazyLoadImage
              ref={imageRef}
              id={`staticSnapshot_${time}`}
              className={`${isFromSearchDashboard ? ' img-event' : ''}`}
              alt="snapshot image"
              src={newSrc || NoSnapshot}
              scrollPosition={scrollPosition}
              effect="blur"
              wrapperProps={{
                style: { transitionDelay: '0.4s' },
              }}
              placeholderSrc={
                <Spinner
                  height="24"
                  width="24"
                  strokeColor={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--primary_40')}
                  strokeWidth="7"
                  fill="none"
                />
              }
              onLoad={(event) => {
                event.target.style.display = 'inline-block';
              }}
              onError={(event) => {
                if (handleImageFetchError) {
                  handleImageFetchError();
                } else {
                  event.target.src = NoSnapshot;
                }
              }}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default ImageFetch;
