import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import { Button, Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment-timezone';

import {
  BreadcrumbList,
  Header,
  PrimaryButton,
  SiteModal,
  SiteSpinner,
  SiteToast,
  Tabs,
} from '../../components/common';
import { AddTags as EditTags, CloseIncident, StatusLog } from './modalForms/';
import {
  ContributorsTab,
  DetailsTab,
  EvidenceTab,
  ExportTab,
} from './tabContents';

import { constants, roles, Utils } from '../../helpers/';
import timezones from '../../data/support/timezone.json';

import { usePoliciesStore } from '../../store/policiesStore';
import { useOrganizations } from '../../store/OrganizationsStore';
// import { useCustomerOrgDevices } from '../../store/CustomerOrgDevicesStore';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';

import { BsCloudDownload } from 'react-icons/bs';
import {
  HiOutlineFolderOpen,
  HiOutlineInformationCircle,
  HiOutlineUsers,
} from 'react-icons/hi';

import './IncidentDetails.scss';
import { isBoolean } from 'lodash';
import { useSelector } from 'react-redux';
import { getCustomerOrgData } from '../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';

const defaultIncidentFormData = {
  status: '',
  resolution: '',
  time: 0,
  note: '',
};

const IncidentDetails = ({ incident, ...props }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const incidentId = searchParams.get('incidentId');
  const tabIndex = parseInt(searchParams.get('activeTab')) || 0;

  // Component States
  const [numReloads, setNumReloads] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showCloseIncidentModal, setShowCloseIncidentModal] = useState(false);
  const [
    showReopenIncidentConfirmationModal,
    setShowReopenIncidentConfirmationModal,
  ] = useState(false);
  const [showEditTagsModal, setShowEditTagsModal] = useState(false);
  const [showStatusLogModal, setShowStatusLogModal] = useState(false);
  const [showPendingChangesModal, setShowPendingChangesModal] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [hasData, setHasData] = useState(false);
  const [incidentAPICalled, setIncidentAPICalled] = useState(true);
  const [includesIncidentVideo, setIncludesIncidentVideo] = useState(false);
  const [hasPendingChanges, setHasPendingChanges] = useState(false);
  const [selectedIncident, setSelectedIncident] = useState(null);
  const [incidentDate, setIncidentDate] = useState(new Date());
  const [incidentDateTime, setIncidentDateTime] = useState(null);
  const [incidentTimezone, setIncidentTimeZone] = useState(null);
  const [incidentTimezones, setIncidentTimezones] = useState([]);
  const [locationId, setLocationId] = useState(null);
  const [areaId, setAreaId] = useState(null);
  const [currentLocationAreas, setCurrentLocationAreas] = useState(null);
  const [availableTags, setAvailableTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [isTagFormModified, setIsTagFormModified] = useState(false);
  const [disableFutureTime, setDisableFutureTime] = useState(true);

  const [updateIncidentDate, setUpdateIncidentDate] = useState(new Date());
  const [updateIncidentDateTime, setUpdateIncidentDateTime] = useState(null);
  const [incidentContributors, setIncidentContributors] = useState([]);
  const [statusClosed, setStatusClosed] = useState(false);
  const [activeSection, setActiveSection] = useState(tabIndex);
  const [saveTabSettingsDataFlag, setSaveTabSettingsDataFlag] = useState(false);
  const [areAllChangesReverted, setAreAllChangesReverted] = useState(false);
  const [isFormData, setIsFormData] = useState({});
  const [formData, setFormData] = useState({
    summary: '',
    description: '',
    locationId: locationId,
    areaId: areaId,
    timezone: null,
    incidentDateTime: null,
    tags: tags,
  });
  const [resolution, setResolution] = useState('');
  const [updateIncidentFormData, setUpdateIncidentFormData] = useState(
    defaultIncidentFormData,
  );
  const [formSubmitted, setFormSubmitted] = useState(false);

  const getLoggedInUserPolicies = usePoliciesStore(
    (state) => state.getLoggedInUserPolicies,
  );
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData,
  );
  const loggedInUser = getLoggedInUserData();
  const { getCustomerOrgLocationsData } = useCustomerOrgLocations();
  const [orgDetails, setOrgDetails] = useState();
  const currentUserPolicies = getLoggedInUserPolicies();

  const breadcrumbList = [
    {
      url: '/incidents/manage.html',
      title: constants.CUSTOMERS_INCIDENTS_TITLE,
    },
    { url: '', title: selectedIncident?.summary },
  ];

  const tabs = [
    {
      icon: <HiOutlineInformationCircle size={20} />,
      label: constants.INCIDENTS_PAGE_TAB_DETAILS_LABEL,
      clickHandler: (index) => setSectionBlock(index),
    },
    {
      icon: <HiOutlineFolderOpen size={20} />,
      label: constants.INCIDENTS_PAGE_TAB_EVIDENCE_LABEL,
      clickHandler: (index) => setSectionBlock(index),
    },
    {
      icon: <HiOutlineUsers size={20} />,
      label: constants.INCIDENTS_PAGE_TAB_CONTRIBUTORS_LABEL,
      clickHandler: (index) => setSectionBlock(index),
    },
    {
      icon: <BsCloudDownload size={18} />,
      label: constants.INCIDENTS_PAGE_TAB_EXPORT_LABEL,
      clickHandler: (index) => setSectionBlock(index),
    },
  ];

  const tabAPIEndpoint = {
    0: {
      // Details
      url: (orgId, incidentId) =>
        `/incident/orgs/${orgId}/incidents/${incidentId}`,
    },
    2: {
      // Contributors
      url: (orgId, incidentId) =>
        `/incident/orgs/${orgId}/incidents/${incidentId}/users`,
    },
  };

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setOrgDetails(orgs?.[0] || {});
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000,
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  useEffect(() => {
    if (orgDetails?.orgId) {
      const fetchIncidentDetails = async () => {
        try {
          let incident, incidentTags, currentFormData;
          setShowLoader(true);
          const res = await axios.get(
            `/incident/orgs/${orgDetails?.orgId}/incidents/${incidentId}`,
            {
              ...Utils.requestHeader(),
              credentials: 'include',
              withCredentials: true,
            },
          );

          const responseData = res?.data;

          if (responseData?.meta?.code === 200) {
            incident = responseData?.data?.incident;
            setSelectedIncident(incident);
            incidentTags = incident?.tags;
            setStatusClosed(incident?.status?.toUpperCase() === 'CLOSED');
            setIncidentTimeZone(incident?.timezone);

            if (Array.isArray(incidentTags) && orgDetails?.orgId) {
              await fetchCurrentTags(incidentTags);
              setTags([...incidentTags]);
            }

            currentFormData = {
              ...incident,
              timezone: Utils.getTimezone(incident.timezone, 'olson'),
              tags:
                Array.isArray(incidentTags) && incidentTags.length > 0
                  ? [...incidentTags]
                  : [],
            };

            await new Promise((resolve) => {
              setFormData(currentFormData);
              setIsFormData(currentFormData);
              resolve();
            });

            setHasData(true);
            setIncidentAPICalled(false);
          } else {
            if (res?.code) {
              setUserMsg(`${res.code}: ${res.message}`);
            } else if (responseData?.data) {
              setUserMsg(responseData?.data?.userMsg);
            }
            setShowToast(true);
            setIncidentAPICalled(false);
          }
        } catch (error) {
          setUserMsg(error);
          setShowToast(true);
          setIncidentAPICalled(false);
        } finally {
          setShowLoader(false);
          setIncidentAPICalled(false);
        }
      };

      fetchIncidentDetails();
      fetchLocationAreas();

      if (orgDetails?.orgId) {
        fetchCurrentTags();
      }

      setIncidentTimezones([
        ...timezones?.data.map((timezone) => {
          return {
            label: timezone.label,
            value: timezone.location,
          };
        }),
      ]);

      setActiveSection(activeSection);
    }
  }, [numReloads, orgDetails?.orgId]);

  const fetchLocationAreas = useCallback(() => {
    const userOrgLocations = getCustomerOrgLocationsData();
    const locationAreas = [];

    getDefaultLocationArea(userOrgLocations);

    try {
      if (Array.isArray(userOrgLocations) && userOrgLocations.length > 0) {
        let i = 0,
          locationsLength = userOrgLocations.length,
          location,
          sublist;

        // We're using a while-loop because it's more performant
        // than a for-loop, for-of-loop, .forEach)_ or .map()
        while (i < locationsLength) {
          location = userOrgLocations[i];
          sublist = location?.areas?.map((area) => {
            return {
              sublistItemId: area?.areaId,
              sublistItemName: area?.areaName,
            };
          });

          locationAreas.push({
            listItemId: location.locationId,
            listItemName: location.locationName,
            listItemTimezone: Utils.getTimezone(location.timezone, 'olson'),
            sublist: [...sublist],
          });
          i++;
        }

        // Sort the locations by location name (listItemName) in ascending order
        locationAreas.sort((locA, locB) => {
          if (
            locA?.listItemName?.toLowerCase() <
            locB?.listItemName?.toLowerCase()
          ) {
            return -1;
          }

          if (
            locA?.listItemName?.toLowerCase() >
            locB?.listItemName?.toLowerCase()
          ) {
            return 1;
          }

          return 0;
        });
      } else {
        throw new Error('ERROR: no organization locations available');
      }
    } catch (err) {
      Utils.vmsLogger().error(err);
    } finally {
      setCurrentLocationAreas([...locationAreas]);
    }
  }, []);

  const getDefaultLocationArea = (organizationLocations) => {
    try {
      if (
        Array.isArray(organizationLocations) &&
        organizationLocations.length > 0
      ) {
        const locationsFromTimezone = organizationLocations.filter(
          (location) => location?.timezone === selectedIncident?.timezone,
        );

        if (locationsFromTimezone.length > 0) {
          setLocationId(locationsFromTimezone[0].locationId);
          setAreaId(locationsFromTimezone[0]?.areas[0]?.areaId);
        }
      } else {
        throw new Error('ERROR: no organization devices found');
      }
    } catch (err) {
      Utils.vmsLogger().error(err);
    }
  };

  const fetchTimezoneLocation = (timezone) => {
    let selectedTimezone;

    if (
      !timezone ||
      !Array.isArray(incidentTimezones) ||
      incidentTimezones.length < 1
    ) {
      return;
    }

    selectedTimezone = Utils.getTimezone(timezone, 'olson');

    return selectedTimezone;
  };

  // Get current tags
  const fetchCurrentTags = async (incidentTags) => {
    try {
      const res = await axios.get(
        `/partner/orgs/${orgDetails?.orgId}/tags?category=IM`,
        {
          ...Utils.requestHeader(),
          timeout: 60000,
          credentials: 'include',
          withCredentials: true,
        },
      );

      const responseData = res?.data;
      const selectTags = [];
      let tagIndex = -1;

      if (responseData?.meta?.code === 200) {
        if (
          Array.isArray(responseData?.data?.tags) &&
          responseData?.data?.tags?.length > 0
        ) {
          responseData?.data?.tags.forEach((tag) => {
            tagIndex = incidentTags?.findIndex(
              (incidentTag) =>
                incidentTag === tag?.name || incidentTag?.tagId === tag?.tagId,
            );

            selectTags.push({
              ...tag,
              assigned: tagIndex !== -1,
            });
          });
          setAvailableTags([...selectTags]);
        } else {
          setAvailableTags([]);
        }
      } else {
      }
    } catch (err) {
      Utils.vmsLogger().error(err);
    }
  };

  const combineDateTime = (currentDate, currentTime) => {
    let combinedDateTime;

    if (!currentDate || !currentTime) return;

    try {
      const date = moment(currentDate).format('YYYY-MM-DD');
      const time = moment(currentTime).format('hh:mm:ss A').valueOf();

      combinedDateTime = moment
        .tz(`${date} ${time}`, 'YYYY-MM-DD hh:mm:ss A', incidentTimezone)
        .utc();
    } catch (err) {
      Utils.vmsLogger().error(err);
    }

    return combinedDateTime?.valueOf();
  };

  const setSectionBlock = (index) => {
    if (hasPendingChanges) {
      setShowPendingChangesModal(true);
    } else {
      setSaveTabSettingsDataFlag(false);
      setActiveSection(index);
    }
  };

  const handleStatusUpdate = async () => {
    if (selectedIncident?.status?.toUpperCase() !== 'CLOSED') {
      setShowReopenIncidentConfirmationModal(false);
      setShowCloseIncidentModal(true);
    } else {
      setShowCloseIncidentModal(false);
      setShowReopenIncidentConfirmationModal(true);
    }
  };

  const handleUpdateIncidentDateChange = (value) => {
    if (!value) return;

    setUpdateIncidentDate(value);
    setHasPendingChanges(true);
  };

  const saveIncidentHandler = () => {};

  const handleInputChange = (e) => {
    const { name, value } = e?.target;
    if (!name) return;

    setFormData((prev) => {
      let newFormData = {
        ...prev,
        [name]: value,
      };

      return newFormData;
    });
  };

  const handleUpdateInputChange = (e) => {
    const { name, value } = e?.target;
    if (!name || !value) return;

    setUpdateIncidentFormData((prev) => {
      let newFormData = {
        ...prev,
        [name]: value,
      };

      setHasPendingChanges(true);

      return newFormData;
    });
  };

  const handleContributorToggle = (accountId) => {
    const accountIndex = incidentContributors?.indexOf(accountId);

    if (accountIndex > -1) {
      incidentContributors.slice(accountIndex, 1);
    } else {
      setIncidentContributors([...incidentContributors, accountId]);
    }
  };

  const editTagsSaveHandler = async () => {
    try {
      let filteredTags = availableTags.filter((tag) => tag?.assigned === true);

      let normalizedTags;

      if (!Array.isArray(filteredTags) || filteredTags.length < 1) {
        return;
      }

      if (filteredTags.length > 0) {
        normalizedTags = filteredTags.map((filteredTag) => filteredTag.name);
        setTags([...normalizedTags]);
      } else {
        normalizedTags = [];
      }

      setFormData((prev) => {
        return {
          ...prev,
          tags: [...normalizedTags],
        };
      });

      setShowEditTagsModal(false);

      setHasPendingChanges(true);
    } catch (err) {
      Utils.vmsLogger().error(err);
    }
  };

  const handleSaveButtonClick = async (tab) => {
    try {
      setShowLoader(true);
      let reqBody;

      if (tab === 0) {
        reqBody = {
          ...formData,
        };
      } else {
        // CONTRIBUTORS
        reqBody = {
          accountIds: [],
        };
      }

      let response = await axios.put(
        tabAPIEndpoint[`${tab}`].url(orgDetails?.orgId, incidentId),
        reqBody,
        Utils.requestHeader(),
      );

      let resData = response?.data;

      if (resData?.meta.code === 200) {
        setFormSubmitted(true);
        setSelectedIncident({
          ...selectedIncident,
          ...resData?.data?.incident,
        });
        setIsFormData(resData?.data?.incident);
        setHasPendingChanges(false);
        setShowPendingChangesModal(false);
        setAreAllChangesReverted(false);
      } else {
        setUserMsg(resData.meta?.userMsg);
        setShowToast(true);
        Utils.vmsLogger().error(
          `ERROR: ${resData?.meta?.code} - ${resData?.meta?.desc}`,
        );
      }
    } catch (err) {
      Utils.vmsLogger().error(err);
    } finally {
      setShowLoader(false);
      setFormSubmitted(false);
    }
  };

  const updateIncidentHandler = async (incidentStatus) => {
    if (
      !incidentStatus ||
      (incidentStatus.toUpperCase() !== 'REOPEN' &&
        incidentStatus.toUpperCase() !== 'CLOSE')
    ) {
      return;
    }

    try {
      setShowLoader(true);
      let reqBody;

      // Currently only have two supported statuses that an incident
      // can be changed to: REOPEN and CLOSE
      if (incidentStatus.toUpperCase() === 'REOPEN') {
        reqBody = {
          status: 'reopened',
          resolution: selectedIncident?.resolution,
          time: '',
          note: constants.INCIDENTS_REOPEN_INCIDENT_DEFAULT_NOTE,
        };
      } else {
        reqBody = {
          ...updateIncidentFormData,
          status: 'closed',
          time: combineDateTime(updateIncidentDate, updateIncidentDateTime),
        };
      }

      let response = await axios.put(
        `/incident/orgs/${orgDetails?.orgId}/incidents/${incidentId}/status`,
        reqBody,
        {
          ...Utils.requestHeader(),
          credentials: 'include',
          withCredentials: true,
        },
      );

      let resData = response?.data;

      if (resData?.meta.code === 200) {
        setFormSubmitted(true);

        if (incidentStatus.toUpperCase() === 'REOPEN') {
          if (hasPendingChanges) {
            setShowPendingChangesModal(true);
          } else {
            setShowReopenIncidentConfirmationModal(false);
            setActiveSection(0);
          }
        } else {
          setShowCloseIncidentModal(false);
        }

        setNumReloads((prev) => prev + 1);
        setUpdateIncidentFormData(defaultIncidentFormData);
        setFormSubmitted(false);
        setHasPendingChanges(false);
        reqBody = {};
      } else {
        setShowToast(true);
        setUserMsg(resData?.meta?.userMsg);
        Utils.vmsLogger().error(
          `ERROR: ${resData?.meta?.code} - ${resData?.meta?.desc}`,
        );
      }
    } catch (err) {
      Utils.vmsLogger().error(err);
    } finally {
      setShowLoader(false);
    }
  };

  const tabContents = [
    {
      // Details
      tabComponentProps: {
        statusClosed,
        formData,
        availableTags,
        tags,
        setTags,
        currentLocationAreas,
        incidentTimezones,
        disableFutureTime,
        fetchTimezoneLocation,
        handleInputChange,
        setShowEditTagsModal,
        setShowStatusLogModal,
        setIncidentTimeZone,
        setIncidentDate,
        setIncidentDateTime,
        combineDateTime,
        setLocationId,
        setAreaId,
        setHasPendingChanges,
      },
    },
    {
      // Evidence
      tabComponentProps: {
        selectedIncident,
        setShowToast,
        setUserMsg,
        statusClosed,
        setIncludesIncidentVideo,
        fetchTimezoneLocation,
      },
    },
    {
      // Contributors
      tabComponentProps: {
        orgDetails,
        selectedIncident,
        incidentContributors,
        setIncidentContributors,
        setShowToast,
        setUserMsg,
        statusClosed,
        setShowPendingChangesModal,
      },
    },
    {
      // Export
      tabComponentProps: {
        orgDetails,
        selectedIncident,
        includesIncidentVideo,
        setShowToast,
        setUserMsg,
        statusClosed,
      },
    },
  ];

  // Renders the appropriate page content based on which section tab is active
  const sectionContent = (tabSection) => {
    if (isNaN(tabSection)) {
      return;
    }
    switch (tabSection) {
      case 0: // Details tab
        return (
          <DetailsTab
            {...tabContents[tabSection].tabComponentProps}
            selectedIncident={selectedIncident}
            areAllChangesReverted={areAllChangesReverted}
          />
        );

      case 1: // Evidence Tab
        return <EvidenceTab {...tabContents[tabSection].tabComponentProps} />;

      case 2: // Contributors Tab
        return (
          <ContributorsTab {...tabContents[tabSection].tabComponentProps} />
        );

      case 3: // Export Tab
        return <ExportTab {...tabContents[tabSection].tabComponentProps} />;

      default:
        return <DetailsTab {...tabContents[tabSection].tabComponentProps} />;
    }
  };

  const resetValues = async () => {
    await Promise.all([
      new Promise((resolve) => {
        setFormData(isFormData);
        resolve();
      }),
      new Promise((resolve) => {
        setAreAllChangesReverted(true);
        resolve();
      }),
      new Promise((resolve) => {
        setHasPendingChanges(false);
        resolve();
      }),
      new Promise((resolve) => {
        setShowPendingChangesModal(false);
        resolve();
      }),
    ]);
    setAreAllChangesReverted(false);
  };

  return (
    <div className="App incident-details">
      <Header />
      <Container className="container-top-offset mw-100" fluid>
        <Row className="mb-5">
          <Col>
            <Container className="mw-100">
              {showLoader || incidentAPICalled ? (
                <div
                  className="position-absolute top-50"
                  style={{ left: '47%' }}
                >
                  <SiteSpinner height="50px" width="50px" />
                  <div className="mt-2 text-dark">{constants.LOADING}</div>
                </div>
              ) : (
                <>
                  <form onSubmit={handleSubmit(saveIncidentHandler)}>
                    <Row>
                      <div className="page-header mt-4 mb-5">
                        <Row xs={1} md={2} className="g-4">
                          <Col
                            md={6}
                            lg={6}
                            xl={6}
                            xs={12}
                            className="left-align"
                          >
                            <BreadcrumbList list={breadcrumbList} />
                            <div className="page-title">
                              {selectedIncident?.summary}
                            </div>
                            <div className="page-incident-id">
                              {constants.INCIDENTS_DETAILS_INCIDENT_ID_TITLE}:{' '}
                              {selectedIncident?.incidentId}
                            </div>
                          </Col>
                          <Col
                            md={6}
                            lg={6}
                            xl={6}
                            xs={12}
                            className="text-end"
                          >
                            {(currentUserPolicies?.update_incident_status ||
                              currentUserPolicies?.view_created_incident ||
                              currentUserPolicies?.view_shared_incident) && (
                              <>
                                {currentUserPolicies?.update_incident_status && (
                                  <Button
                                    variant="outline-primary"
                                    size="md"
                                    className="close-incident-button"
                                    onClick={(e) => {
                                      e?.preventDefault();
                                      handleStatusUpdate &&
                                        handleStatusUpdate();
                                    }}
                                  >
                                    <span className="close-incident-button-label">
                                      {selectedIncident?.status?.toUpperCase() !==
                                      'CLOSED'
                                        ? constants.INCIDENTS_PAGE_CLOSE_INCIDENT_BUTTON_LABEL
                                        : constants.INCIDENTS_PAGE_REOPEN_BUTTON_LABEL}
                                    </span>
                                  </Button>
                                )}
                                {activeSection === 0 && (
                                  <Button
                                    variant="primary"
                                    size="md"
                                    className={`save-changes-button${
                                      !hasPendingChanges || statusClosed
                                        ? ' disabled'
                                        : ''
                                    }`}
                                    onClick={(e) => {
                                      e?.preventDefault();
                                      if (!statusClosed) {
                                        handleSaveButtonClick(activeSection);
                                      }
                                    }}
                                  >
                                    <span className="save-changes-button-label">
                                      {
                                        constants.INCIDENTS_PAGE_SAVE_BUTTON_LABEL
                                      }
                                    </span>
                                  </Button>
                                )}
                              </>
                            )}
                          </Col>
                        </Row>
                        {/* Toast */}
                        <Row>
                          <div className="toast-wrapper-incident">
                            <SiteToast
                              customCss="incident-details-toast"
                              position="top-end"
                              show={showToast}
                              title={constants.ERROR_TOAST_TITLE}
                              body={userMsg}
                              delay={5000}
                              onCloseCallback={setShowToast}
                            />
                          </div>
                        </Row>
                      </div>
                    </Row>
                    <Row>
                      <div className="page-body">
                        {hasData ? (
                          <Container className="incident-container mw-100">
                            {/* Section Tabs */}
                            <Row className="incident-tabs">
                              <Col>
                                <Tabs
                                  tabCollection={tabs}
                                  customTabWidth={280}
                                  defaultActive={tabIndex}
                                  hasPendingChanges={hasPendingChanges}
                                  showPendingModal={() => {
                                    setShowPendingChangesModal(true);
                                  }}
                                  selectedTab={activeSection}
                                />
                              </Col>
                            </Row>
                            {sectionContent(activeSection)}
                          </Container>
                        ) : (
                          <Container className="mw-100">
                            <Row>
                              <Col>
                                <Box className="no-incidents-container">
                                  <p className="no-incident-alert">
                                    {
                                      constants.INCIDENTS_PAGE_NO_CONTENT_MESSAGE
                                    }
                                  </p>
                                </Box>
                              </Col>
                            </Row>
                          </Container>
                        )}
                      </div>
                    </Row>
                  </form>
                  {/**** Modals ****/}
                  {/* Close Incident */}
                  <SiteModal
                    key={'close-incident-modal'}
                    classes="close-incident-modal"
                    wrapperClass="close-incident-modal-wrapper"
                    modalTitle={constants.INCIDENTS_CLOSE_INCIDENT_MODAL_TITLE}
                    showModal={showCloseIncidentModal}
                    hideModal={() => {
                      setUpdateIncidentFormData(defaultIncidentFormData);
                      setShowCloseIncidentModal(false);
                    }}
                    modalFooter={
                      <>
                        <div className="mb-1 close-incident-button-wrapper">
                          <PrimaryButton
                            className="close-incident-save-btn"
                            loader={showLoader}
                            type="submit"
                            disabled={
                              !updateIncidentFormData?.resolution ||
                              !updateIncidentFormData?.note
                            }
                            onClick={() => {
                              updateIncidentHandler('close');
                            }}
                          >
                            {
                              constants.INCIDENTS_CLOSE_INCIDENT_MODAL_CONFIRM_BUTTON_LABEL
                            }
                          </PrimaryButton>
                        </div>
                        <div className="close-incident-button-wrapper">
                          <Button
                            className="close-incident-cancel-btn"
                            variant="outline-secondary"
                            onClick={(e) => {
                              e?.preventDefault();
                              setUpdateIncidentFormData(
                                defaultIncidentFormData,
                              );
                              setShowCloseIncidentModal(false);
                            }}
                          >
                            {
                              constants.INCIDENTS_CLOSE_INCIDENT_MODAL_CANCEL_BUTTON_LABEL
                            }
                          </Button>
                        </div>
                      </>
                    }
                  >
                    <CloseIncident
                      incidentId={selectedIncident?.incidentId}
                      timezone={selectedIncident?.timezone}
                      resolution={resolution}
                      fetchTimezoneLocation={fetchTimezoneLocation}
                      selectedIncidentDate={selectedIncident?.incidentDateTime}
                      updateIncidentDate={updateIncidentDate}
                      updateIncidentDateTime={updateIncidentDateTime}
                      handleUpdateInputChange={handleUpdateInputChange}
                      handleUpdateIncidentDateChange={
                        handleUpdateIncidentDateChange
                      }
                      setUpdateIncidentDateTime={(e) => {
                        setUpdateIncidentDateTime(e);
                      }}
                      openModal={(showModal) => {
                        setShowCloseIncidentModal(showModal);
                        setStatusClosed(true);
                      }}
                      callback={setStatusClosed}
                    />
                  </SiteModal>
                  {/* Re-open Incident */}
                  <SiteModal
                    key={'reopen-incident-modal'}
                    classes="reopen-incident-modal"
                    wrapperClass="reopen-incident-modal-wrapper"
                    modalTitle={constants.INCIDENTS_REOPEN_INCIDENT_MODAL_TITLE}
                    showModal={showReopenIncidentConfirmationModal}
                    hideModal={() =>
                      setShowReopenIncidentConfirmationModal(false)
                    }
                    modalFooter={
                      <>
                        <div className="mb-1 reopen-incident-button-wrapper">
                          <PrimaryButton
                            className="reopen-incident-save-btn"
                            loader={showLoader}
                            type="submit"
                            onClick={() => {
                              updateIncidentHandler('reopen');
                            }}
                          >
                            {
                              constants.INCIDENTS_CLOSE_INCIDENT_MODAL_CONFIRM_BUTTON_LABEL
                            }
                          </PrimaryButton>
                        </div>
                        <div className="reopen-incident-button-wrapper">
                          <Button
                            className="reopen-incident-cancel-btn"
                            variant="outline-secondary"
                            onClick={(e) => {
                              e?.preventDefault();
                              setShowReopenIncidentConfirmationModal(false);
                            }}
                          >
                            {
                              constants.INCIDENTS_CLOSE_INCIDENT_MODAL_CANCEL_BUTTON_LABEL
                            }
                          </Button>
                        </div>
                      </>
                    }
                  >
                    <span className="reopen-incident-description">
                      {Utils.replaceStringValues(
                        constants.INCIDENTS_REOPEN_INCIDENT_MODAL_DESCRIPTION,
                        '$incidentSummary',
                        selectedIncident?.summary,
                      )}
                    </span>
                  </SiteModal>
                  {/* Edit Tags */}
                  <SiteModal
                    key={'edit-tags-modal'}
                    classes="edit-tags-modal"
                    wrapperClass="edit-tags-modal-wrapper"
                    modalTitle={constants.INCIDENTS_EDIT_TAGS_MODAL_TITLE}
                    showModal={showEditTagsModal}
                    hideModal={() => setShowEditTagsModal(false)}
                    modalFooter={
                      <div className="edit-tags-button-group">
                        <div className="mb-3 edit-tags-button-wrapper">
                          <PrimaryButton
                            className={`edit-tags-save-btn${
                              !isTagFormModified ? ' disabled' : ''
                            }`}
                            loader={showLoader}
                            type="button"
                            disabled={!isTagFormModified}
                            onClick={(e) => {
                              e?.preventDefault();
                              editTagsSaveHandler();
                            }}
                          >
                            {
                              constants.INCIDENTS_MANAGE_TAGS_MODAL_SAVE_BUTTON_LABEL
                            }
                          </PrimaryButton>
                        </div>
                        <div className="edit-tags-button-wrapper">
                          <Button
                            className="edit-tags-cancel-btn"
                            variant="outline-secondary"
                            onClick={() => {
                              setShowEditTagsModal(false);
                            }}
                          >
                            {
                              constants.INCIDENTS_MANAGE_TAGS_MODAL_CANCEL_BUTTON_LABEL
                            }
                          </Button>
                        </div>
                      </div>
                    }
                  >
                    <EditTags
                      availableTags={availableTags}
                      currentTags={tags}
                      incidentTagsChangeHandler={setAvailableTags}
                      setIsTagFormModified={setIsTagFormModified}
                      openModal={(showModal) => {
                        setShowEditTagsModal(showModal);
                      }}
                    />
                  </SiteModal>
                  {/* View Status Logs */}
                  <SiteModal
                    key={'view-status-logs-modal'}
                    classes="view-status-logs-modal"
                    wrapperClass="view-status-logs-modal-wrapper"
                    modalTitle={constants.INCIDENTS_STATUS_LOG_MODAL_TITLE}
                    showModal={showStatusLogModal}
                    hideModal={() => setShowStatusLogModal(false)}
                    modalFooter={
                      <div className="status-log-button-group">
                        <div className="mt-3 status-log-button-wrapper">
                          <PrimaryButton
                            className="status-log-back-btn"
                            type="button"
                            onClick={(e) => {
                              e?.preventDefault();
                              setShowStatusLogModal(false);
                            }}
                          >
                            {
                              constants.INCIDENTS_STATUS_LOG_MODAL_BACK_BUTTON_LABEL
                            }
                          </PrimaryButton>
                        </div>
                      </div>
                    }
                  >
                    <StatusLog
                      incidentId={selectedIncident?.incidentId}
                      incidentStatus={selectedIncident?.status}
                      incidentResolution={selectedIncident?.resolution}
                      incidentTimezone={selectedIncident?.timezone}
                      fetchTimezoneLocation={fetchTimezoneLocation}
                      openModal={(showModal) => {
                        setShowStatusLogModal(showModal);
                      }}
                    />
                  </SiteModal>
                  {/* Pending Changes */}
                  <SiteModal
                    key="pending-changes-confirmation-modal"
                    classes="pending-changes-confirmation-modal"
                    wrapperClass="'pending-changes-confirmation-modal-wrapper"
                    modalTitle={constants.INCIDENTS_PENDING_CHANGES_MODAL_TITLE}
                    showModal={showPendingChangesModal}
                    hideModal={() => setShowPendingChangesModal(false)}
                    modalFooter={
                      <div className="pending-changes-button-group">
                        <div className="mb-3 pending-changes-button-wrapper">
                          <PrimaryButton
                            className="pending-changes-save-btn"
                            loader={showLoader}
                            type="button"
                            onClick={(e) => {
                              e?.preventDefault();
                              handleSaveButtonClick(activeSection);
                            }}
                          >
                            {
                              constants.INCIDENTS_PENDING_CHANGES_MODAL_SAVE_CHANGES_BUTTON_LABEL
                            }
                          </PrimaryButton>
                        </div>
                        <div className="pending-changes-button-wrapper">
                          <Button
                            className="pending-changes-cancel-btn"
                            variant="outline-secondary"
                            onClick={() => {
                              resetValues();
                            }}
                          >
                            {
                              constants.INCIDENTS_PENDING_CHANGES_MODAL_DISCARD_BUTTON_LABEL
                            }
                          </Button>
                        </div>
                      </div>
                    }
                  >
                    <div>
                      {
                        constants.INCIDENTS_PENDING_CHANGES_MODAL_CONFIRMATION_MESSAGE
                      }
                    </div>
                  </SiteModal>
                </>
              )}
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default IncidentDetails;
