import { useCallback, useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  Autocomplete,
  Chip,
  Divider,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@mui/material';

import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';

import { useCustomerOrgUsers } from '../../store/CustomerOrgUsersStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';

import {
  HiOutlineDotsVertical,
  HiOutlineExclamationCircle,
  HiOutlineMail,
} from 'react-icons/hi';
import { IoClose } from 'react-icons/io5';
import { LuClock4 } from 'react-icons/lu';

import { ContextMenu, PrimaryButton, Tabs } from '../../components/common';
import { RolesEnum, constants, Utils } from '../../helpers';
import colors from '../../assets/css/_colors.scss';
import './Share.scss';
import { getCustomerOrgData } from '../../store/OrganizationsStoreIDB';
import { useSelector } from 'react-redux';
import { observerInstance } from '../../store/indexDB/observer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';

const SHARE_STATUS = {
  VIEWED: constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_VIEWED_STATUS,
  SHARED: constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_SHARED_STATUS,
  EXPIRED: constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_EXPIRED_STATUS,
  REVOKED: constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_REVOKED_STATUS,
};

const Share = ({
  clipId,
  grantCallback,
  revokeCallback,
  extendCallback,
  setNotificationData,
}) => {
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const [hasExternalEmail, setHasExternalEmail] = useState(false);
  const [showNoExternalEmailMsg, setShowNoExternalEmailMsg] = useState(false);
  const [alreadyInvited, setAlreadyInvited] = useState(false);
  const [alreadyAddedEmail, setAlreadyAddedEmail] = useState('');
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [currentClipId, setCurrentClipId] = useState(clipId);
  const [expirationTime, setExpirationTime] = useState(null);
  const [orgDetails, setOrgDetails] = useState();
  const [custOrgUsers, setCustOrgUsers] = useState([]);
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const [subscribers, setSubscribers] = useState([]);
  const [inviteeEmails, setInviteeEmails] = useState([]);
  const [hasExternalSubscribers, setHasExternalSubscribers] = useState(false);

  const getCustomerOrgUsersData = useCustomerOrgUsers(
    (state) => state.getCustomerOrgUsersData,
  );
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData,
  );
  const customerOrgUsers = getCustomerOrgUsersData();

  const expirationTimes = {
    // 1 hour
    0: () => getExpiration(1),
    // 12 hours
    1: () => getExpiration(12),
    // 24 hours
    2: () => getExpiration(24),
    // Never expire
    3: () => 95649033600000,
  };

  const tabs = [
    {
      icon: null,
      label: constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_EXPIRATION_OPTION_1,
      clickHandler: (index) => setExpirationTime(expirationTimes[index]()),
    },
    {
      icon: null,
      label: constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_EXPIRATION_OPTION_2,
      clickHandler: (index) => setExpirationTime(expirationTimes[index]()),
    },
    {
      icon: null,
      label: constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_EXPIRATION_OPTION_3,
      clickHandler: (index) => setExpirationTime(expirationTimes[index]()),
    },
    {
      icon: null,
      label: constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_EXPIRATION_OPTION_4,
      clickHandler: (index) => setExpirationTime(expirationTimes[index]()),
    },
  ];

  const shareExternalSubscriberContextMenu = [
    {
      id: 'revokeAccess',
      icon: null,
      text: constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_CONTEXT_MENU_REVOKE_ACCESS_TEXT,
      clickHandler: (e, data) => {
        handleRevokeAccess(data);
      },
      isExpandable: false,
    },
    {
      id: 'extendAccess',
      icon: null,
      text: constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_CONTEXT_MENU_EXTEND_ACCESS_TEXT,
      clickHandler: (e, data) => {
        return;
      },
      isExpandable: true,
      submenuComponent: (data) => {
        return (
          <List disablePadding>
            {[1, 12, 24, 0].map((extension, extensionIndex) => (
              <ListItem
                key={`extension-${extensionIndex}`}
                className="menu-item-expandable-list-item"
                onClick={(e) => {
                  e?.stopPropagation();
                  handleExtendAccess(data, extension);
                }}
                sx={{ willChange: 'transform' }}
                disableRipple
                disableTouchRipple
                disableFocusRipple
                disableGutters
              >
                <ListItemIcon className="menu-item-expandable-list-item-icon">
                  <LuClock4 color={'#000'} size={'0.85rem'} />
                </ListItemIcon>
                <ListItemText
                  className="menu-item-expandable-list-item-text"
                  primary={`${
                    extension === 0
                      ? constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_CONTEXT_MENU_NEVER_EXPIRES_TEXT
                      : `${
                          extension > 1
                            ? extension +
                              ' ' +
                              constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_CONTEXT_MENU_EXPIRATION_PLURAL_UNIT
                            : extension +
                              ' ' +
                              constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_CONTEXT_MENU_EXPIRATION_SINGULAR_UNIT
                        }`
                  }`}
                />
              </ListItem>
            ))}
          </List>
        );
      },
    },
  ];

  const shareInternalSubscriberGrantedContextMenu = [
    {
      id: 'revokeAccess',
      icon: null,
      text: constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_CONTEXT_MENU_REVOKE_ACCESS_TEXT,
      clickHandler: (e, data) => {
        handleRevokeAccess(data);
      },
      isExpandable: false,
    },
  ];

  const shareInternalSubscriberRevokedContextMenu = [
    {
      id: 'grantAccess',
      icon: null,
      text: constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_CONTEXT_MENU_GRANT_ACCESS_TEXT,
      clickHandler: (e, data) => {
        handleExtendAccess(data, 0);
      },
      isExpandable: false,
    },
  ];

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setOrgDetails(orgs?.[0] || {});
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000,
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };

    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orgDetails?.orgId) {
      fetchSubscribers();
      const activeUsers = customerOrgUsers?.filter(
        (user) =>
          user.accountStatus === 'ACTIVE' && user.role !== RolesEnum.ROLE2VMS,
      );
      setCustOrgUsers(structuredClone(activeUsers));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgDetails?.orgId]);

  const isAccessExpired = (dateTime) => {
    const currentEpochTimestamp = Date.now();

    if (isNaN(dateTime)) return false;

    const currentDate = moment(currentEpochTimestamp);
    const expiryDate = moment(dateTime);

    return expiryDate.isBefore(currentDate);
  };

  const checkForExternalUsers = (inviteeEmails) => {
    let hasExternalUsers = false;
    let orgUser;

    if (!Array.isArray(inviteeEmails)) return true;

    for (const inviteeEmail of inviteeEmails) {
      orgUser = customerOrgUsers.find(
        (user) => user?.email?.toUpperCase() === inviteeEmail?.toUpperCase(),
      );
      if (!_.isObject(orgUser)) {
        hasExternalUsers = true;
        break;
      } else if (orgUser?.role === RolesEnum.ROLE2VMS) {
        hasExternalUsers = false;
        setUserIsAdmin(true);
        break;
      } else {
        setUserIsAdmin(false);
      }
    }

    setHasExternalEmail(hasExternalUsers);
    setHasExternalSubscribers(hasExternalUsers);
  };

  const getExpiration = (futureHourOffset) => {
    const currentTime = moment();

    const newTime = currentTime.add(futureHourOffset, 'hours');

    // Get the date-time value in milliseconds
    return newTime.valueOf();
  };

  const getSubscriberStatus = (subscriber) => {
    if (!subscriber || isNaN(subscriber.expiry) || !subscriber.status) return;

    let statusContent;

    if (isAccessExpired(subscriber.expiry)) {
      if (subscriber.isExternal) {
        statusContent = SHARE_STATUS['EXPIRED'];
      } else {
        statusContent = SHARE_STATUS['REVOKED'];
      }
    } else {
      statusContent = SHARE_STATUS[subscriber.status];
    }

    return statusContent;
  };

  const fetchSubscribers = async () => {
    try {
      let clipOwner, nonOwners, sortedSubscribers;

      if (!currentClipId || !orgDetails.orgId) return;

      const res = await axios.get(
        `/clip/orgs/${orgDetails?.orgId}/clips/${currentClipId}/share`,
        Utils.requestHeader(),
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        if (Array.isArray(responseData.data?.subscribers)) {
          let clipSubscribers = responseData.data.subscribers.map(
            (subscriber) => {
              const augmentedSubscriber = {
                ...subscriber,
                name:
                  customerOrgUsers.find(
                    (custOrgUser) =>
                      custOrgUser?.accountId === subscriber?.accountId,
                  )?.name || subscriber.email,
              };

              return augmentedSubscriber;
            },
          );

          // Sort non-owner subscribers by listing the clip
          // owner first
          clipOwner = clipSubscribers.find(
            (subscriber) => subscriber.owner === true,
          );

          // Retrieve all the non-owner subscribers
          nonOwners = clipSubscribers.filter(
            (subscriber) => subscriber.owner === false,
          );

          // Sort them by their email addresses
          nonOwners.sort((sub1, sub2) => {
            if (sub1.email < sub2.email) {
              return -1;
            }

            if (sub1.email > sub2.email) {
              return 1;
            }

            return 0;
          });

          sortedSubscribers = [{ ...clipOwner }, ...nonOwners];

          setHasExternalSubscribers(
            sortedSubscribers.find(
              (subscriber) => subscriber?.isExternal === true,
            ),
          );

          setSubscribers([...sortedSubscribers]);
        }
      } else {
        if (res?.code) {
          Utils.vmsLogger().error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };

  const handleShareButtonClick = async () => {
    try {
      setShowButtonLoader(true);
      const reqBody = {
        emailIds: inviteeEmails,
        expiry: expirationTime || expirationTimes[0](),
      };

      const res = await axios.post(
        `/clip/orgs/${orgDetails?.orgId}/clips/${currentClipId}/share`,
        reqBody,
        Utils.requestHeader(),
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        grantCallback && grantCallback(responseData?.data?.subscribers);
      } else {
        if (res?.code) {
          Utils.vmsLogger().error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    } finally {
      setShowButtonLoader(false);
    }
  };

  const handleRevokeAccess = async (subscriber) => {
    try {
      if (!subscriber) return;

      const reqBody = {
        expiry: moment(new Date()).subtract(24, 'hours').valueOf(),
      };

      const res = await axios.put(
        `/clip/orgs/${orgDetails?.orgId}/clips/${currentClipId}/share/${subscriber?.sharedId}/access`,
        reqBody,
        Utils.requestHeader(),
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        const modifiedSubscriber = responseData?.data;
        revokeCallback && revokeCallback(modifiedSubscriber);
        await fetchSubscribers();
      } else {
        if (res?.code) {
          Utils.vmsLogger().error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };

  const handleExtendAccess = async (subscriber, extension) => {
    try {
      if (!subscriber || isNaN(extension)) return;

      let specifiedExtension = extension;
      let newExpiry;

      // Ensure specifiedExtension is a number
      if (+specifiedExtension === 0) {
        newExpiry = 95649033600000;
      } else {
        newExpiry = moment().add(specifiedExtension, 'hours').valueOf();
      }

      const reqBody = {
        expiry: newExpiry,
      };

      const res = await axios.put(
        `/clip/orgs/${orgDetails?.orgId}/clips/${currentClipId}/share/${subscriber.sharedId}/access`,
        reqBody,
        Utils.requestHeader(),
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        const modifiedSubscriber = responseData?.data;
        extendCallback && extendCallback(modifiedSubscriber);
        await fetchSubscribers();
      } else if (res?.code) {
        throw new Error(`${res.code}: ${res.message}`);
      } else if (responseData?.data) {
        throw new Error(responseData?.data?.userMsg);
      } else {
        throw new Error('ERROR: unable to update access');
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };

  return (
    <div className="share-container">
      <div className="share-invite-form">
        <Autocomplete
          disablePortal
          classes={{ paper: { color: '#000' } }}
          id="share-invite-combo-box"
          className="share-invite-email-field"
          options={custOrgUsers}
          getOptionLabel={(option) => {
            return option.accountId
              ? `${option.name} - ${option.email}`
              : option;
          }}
          limitTags={3}
          ChipProps={{
            className: 'share-invite-email-field-chip',
            deleteIcon: (
              <IoClose
                className="share-invite-email-field-chip-delete-icon"
                color={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--primary_32')}
                size={'18px'}
              />
            ),
          }}
          sx={{ m: 1 }}
          renderInput={(params) => (
            <TextField
              id={params.id}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <div className="email-icon-wrapper">
                    <InputAdornment position="start">
                      <HiOutlineMail
                        className="share-invite-email-icon"
                        size={'1.2rem'}
                      />
                    </InputAdornment>
                  </div>
                ),
              }}
              {...params}
              className={`${params.inputProps.className} share-invite-email-input`}
              disabled={params.disabled}
              placeholder={
                constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_PLACEHOLDER_TEXT
              }
              label=""
              error={emailInvalid}
              fullWidth
            />
          )}
          onBlur={(e) => {
            const emailString = e?.target.value;
            let currentInvitees = inviteeEmails || [];

            if (Utils.isEmailValid(emailString)) {
              if (
                loggedInUserData?.canShareAndDownload === true ||
                loggedInUserData?.role === RolesEnum.ROLE2VMS
              ) {
                if (currentInvitees.length > 0) {
                  currentInvitees.push(emailString);
                } else {
                  currentInvitees = [emailString];
                }

                setInviteeEmails([...currentInvitees]);
                // Check if email address does not belong to one of
                // the accounts in the user's organization
                if (
                  Array.isArray(custOrgUsers) &&
                  custOrgUsers.findIndex(
                    (user) =>
                      user?.email?.toLowerCase() === emailString?.toLowerCase(),
                  ) === -1
                ) {
                  setHasExternalSubscribers(false);
                  setShowNoExternalEmailMsg(false);
                }
              } else if (
                loggedInUserData?.canShareAndDownload !== true &&
                loggedInUserData?.role !== RolesEnum.ROLE2VMS
              ) {
                setShowNoExternalEmailMsg(true);
              }
            }

            checkForExternalUsers(currentInvitees);
          }}
          onChange={(e, value, reason, data) => {
            e?.preventDefault();
            let alreadyInvited = false,
              currentInvitees,
              subscriberIndex;

            setShowNoExternalEmailMsg(false);
            setEmailInvalid(false);
            setAlreadyInvited(false);
            setAlreadyAddedEmail(null);
            setUserIsAdmin(false);

            if (value.length > 0) {
              setHasExternalEmail(false);
              currentInvitees = [
                ...value.map((account) => {
                  if (account.accountId) {
                    if (
                      subscribers.findIndex(
                        (subscriber) => subscriber.email === account.email,
                      ) !== -1
                    ) {
                      setAlreadyInvited(true);
                      setAlreadyAddedEmail(account.email);
                    } else {
                      setHasExternalSubscribers(false);
                      return account.email;
                    }
                    setHasExternalSubscribers(false);
                    return account.email;
                  } else {
                    if (Utils.isEmailValid(account)) {
                      let custOrgUserIndex = custOrgUsers.findIndex(
                        (user) =>
                          user?.email?.toUpperCase() === account?.toUpperCase(),
                      );

                      if (custOrgUserIndex === -1) {
                        subscriberIndex = subscribers.findIndex(
                          (subscriber) => subscriber.email === account,
                        );

                        if (subscriberIndex !== -1) {
                          setAlreadyInvited(true);
                          setAlreadyAddedEmail(account);
                        } else {
                          setShowNoExternalEmailMsg(
                            loggedInUserData?.canShareAndDownload !== true &&
                              loggedInUserData?.role !== RolesEnum.ROLE2VMS,
                          );
                        }
                      } else {
                        subscriberIndex = subscribers.findIndex(
                          (subscriber) => subscriber.email === account,
                        );

                        if (subscriberIndex !== -1) {
                          setAlreadyInvited(true);
                          setAlreadyAddedEmail(account);
                        }
                      }
                    } else {
                      setEmailInvalid(true);
                    }

                    return account;
                  }
                }),
              ];
            } else {
              currentInvitees = [];
            }

            if (alreadyInvited === false) {
              if (currentInvitees.length < 1) {
                setHasExternalSubscribers(true);
              }

              checkForExternalUsers(currentInvitees);
            }

            setInviteeEmails([...currentInvitees]);
          }}
          noOptionsText={
            constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_ADD_EXTERNAL_EMAIL_TEXT
          }
          autoComplete={true}
          clearOnBlur={false}
          disableClearable={false}
          freeSolo={true}
          multiple={true}
          openOnFocus={true}
          fullWidth
        />
        {alreadyInvited === false && userIsAdmin === false && (
          <div className="share-invite-form-note">
            {constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_USE_COMMA_TEXT}
          </div>
        )}
        {emailInvalid ? (
          <div className="share-invite-form-error">
            {constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_INVALID_EMAIL_TEXT}
          </div>
        ) : !showNoExternalEmailMsg &&
          alreadyInvited === false &&
          hasExternalEmail ? (
          <div className="share-invite-form-alert">
            <div className="share-invite-form-alert-icon">
              <HiOutlineExclamationCircle
                className="icon-alert"
                size={'1.2rem'}
              />
            </div>
            <div className="share-invite-form-alert-wrapper">
              <div className="share-invite-form-alert-title">
                {
                  constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_EXTERNAL_USERS_TITLE
                }
              </div>
              <div className="share-invite-form-alert-message">
                {
                  constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_EXTERNAL_USERS_MESSAGE
                }
              </div>
            </div>
          </div>
        ) : (
          showNoExternalEmailMsg &&
          alreadyInvited === false && (
            <div className="share-invite-form-alert">
              <div className="share-invite-form-alert-icon">
                <HiOutlineExclamationCircle
                  className="icon-alert"
                  size={'1.2rem'}
                />
              </div>
              <div className="share-invite-form-alert-wrapper">
                <div className="share-invite-form-alert-title">
                  {
                    constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_EXTERNAL_USERS_TITLE
                  }
                </div>
                <div className="share-invite-form-alert-message">
                  {
                    constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_NO_EXTERNAL_USERS_MESSAGE
                  }
                </div>
              </div>
            </div>
          )
        )}
        {alreadyInvited === true && (
          <div className="share-invite-form-error">
            <div className="share-invite-form-error-icon">
              <HiOutlineExclamationCircle
                className="icon-alert"
                size={'1.2rem'}
              />
            </div>
            <div className="share-invite-form-error-message">
              {Utils.replaceStringValues(
                constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_ALREADY_ADDED_TEXT,
                '$emailAddress',
                alreadyAddedEmail,
              )}
            </div>
          </div>
        )}
        {userIsAdmin === true && alreadyInvited === false && (
          <div className="share-invite-form-error">
            <div className="share-invite-form-error-icon">
              <HiOutlineExclamationCircle
                className="icon-alert"
                size={'1.2rem'}
              />
            </div>
            <div className="share-invite-form-error-message">
              {constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_USER_IS_ADMIN}
            </div>
          </div>
        )}
        <PrimaryButton
          className="share-invite-form-submit-btn"
          disabled={
            inviteeEmails.length < 1 ||
            emailInvalid === true ||
            showNoExternalEmailMsg === true ||
            alreadyInvited === true ||
            userIsAdmin === true
          }
          loader={showButtonLoader}
          width={'100%'}
          fontSize={'18px'}
          fontWeight={600}
          onClick={(e) => {
            e?.preventDefault();
            handleShareButtonClick();
          }}
        >
          {constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_SHARE_BUTTON_LABEL}
        </PrimaryButton>
        {/* Set Expiration Time - for external users only */}
        {hasExternalSubscribers === true && alreadyInvited === false && (
          <>
            <div className="mt-3 share-invite-form-section">
              {
                constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_SET_EXPIRATION_TIME_TEXT
              }
            </div>
            <Tabs
              tabCollection={tabs}
              customTabWidth={80}
              customTabHeight={36}
              customFontSize={'14px'}
              customWidth={360}
              defaultActive={0}
              fullWidth={false}
            />
          </>
        )}
        <div className="my-4 horizontal-line">
          <Divider className="share-invite-divider" variant="middle" />
        </div>
        <div className="mt-4 share-invite-form-section">
          {
            constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_MANAGE_WHO_GET_ACCESS_TEXT
          }
        </div>
      </div>
      <div className="share-subscribers">
        <List disablePadding>
          {subscribers.length > 0 &&
            subscribers.map((subscriber) => (
              <ListItem
                key={subscriber?.email}
                className="share-subscriber"
                secondaryAction={
                  <>
                    {!subscriber?.owner && (
                      <span className="share-subscriber-status">
                        {getSubscriberStatus(subscriber)}
                      </span>
                    )}
                    {!subscriber?.owner ? (
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        disableRipple
                        disableTouchRipple
                      >
                        <ContextMenu
                          iconButton={
                            <HiOutlineDotsVertical
                              color={colors.primary_08}
                              size={'1.25rem'}
                            />
                          }
                          menuId={'share-subscriber-menu'}
                          menuItems={
                            subscriber?.isExternal
                              ? shareExternalSubscriberContextMenu
                              : subscriber?.status === 'SHARED'
                                ? shareInternalSubscriberGrantedContextMenu
                                : subscriber?.status === 'VIEWED'
                                  ? shareInternalSubscriberGrantedContextMenu
                                  : shareInternalSubscriberRevokedContextMenu
                          }
                          menuMinWidth="200px"
                          customItemTextClass="share-subscriber-menu-item-option"
                          customData={subscriber}
                          setNotificationData={setNotificationData}
                          disableRipple
                          disableTouchRipple
                        />
                      </IconButton>
                    ) : (
                      <div className="share-subscriber-menu-placeholder"></div>
                    )}
                  </>
                }
                sx={{ willChange: 'transform' }}
                disableRipple
                disableTouchRipple
              >
                <ListItemText
                  className="share-subscriber-name"
                  primary={
                    <OverlayTrigger
                      key={`triggerListItemText${subscriber.email}`}
                      placement="top"
                      overlay={
                        <Tooltip key={`tooltipListItemText${subscriber.email}`}>
                          {subscriber.name || subscriber.email}
                        </Tooltip>
                      }
                    >
                      <div
                        key={`subscriberContactDiv${subscriber.email}`}
                        className="subscriber-contact"
                      >
                        {subscriber.name || subscriber.email}
                      </div>
                    </OverlayTrigger>
                  }
                />
                {subscriber?.owner && (
                  <Chip className="share-subscriber-type owner" label="Owner" />
                )}
                {subscriber?.isExternal && (
                  <Chip
                    className="share-subscriber-type external"
                    label="External"
                  />
                )}
              </ListItem>
            ))}
        </List>
      </div>
    </div>
  );
};

export default Share;
