export const RPLDATA = {
  V0: [
    0, 0, 0, 2, 0.011112, 0.011112, 4, 0.022224, 0.022224, 6, 0.033336,
    0.033336, 8, 0.044448, 0.044448, 10, 0.05556, 0.05556, 12.000001, 0.066672,
    0.066672, 14.000001, 0.077784, 0.077784, 16, 0.088896, 0.088896, 18,
    0.100008, 0.100008, 20, 0.11112, 0.11112, 22, 0.122231, 0.122231, 24.000001,
    0.133343, 0.133343, 26.000001, 0.144454, 0.144454, 28.000001, 0.155566,
    0.155566, 30.000001, 0.166677, 0.166677, 32.000001, 0.177789, 0.177789,
    34.000001, 0.1889, 0.1889, 36.000001, 0.200012, 0.200012, 38, 0.211123,
    0.211123, 40, 0.222234, 0.222234, 42, 0.233345, 0.233345, 44, 0.244456,
    0.244456, 46.000003, 0.255567, 0.255567, 48.000003, 0.266678, 0.266678,
    50.000002, 0.277789, 0.277789, 52.000002, 0.2889, 0.2889, 54.000002,
    0.300011, 0.300011, 56.000002, 0.311122, 0.311122, 58.000002, 0.322233,
    0.322233, 60.000002, 0.333343, 0.333343, 62.000002, 0.344454, 0.344454,
    64.000002, 0.355565, 0.355565, 66.000001, 0.366675, 0.366675, 68.000001,
    0.377786, 0.377786, 70.000001, 0.388896, 0.388896, 72.000001, 0.400007,
    0.400007, 74.000001, 0.411117, 0.411117, 76.000001, 0.422228, 0.422228,
    78.000001, 0.433338, 0.433338, 80.000001, 0.444448, 0.444448, 82, 0.455559,
    0.455559, 84, 0.466669, 0.466669, 86, 0.477779, 0.477779, 88, 0.48889,
    0.48889, 90, 0.5, 0.5, 92.000005, 0.51111, 0.51111, 94, 0.522221, 0.522221,
    96.000005, 0.533331, 0.533331, 98, 0.544441, 0.544441, 100.000005, 0.555552,
    0.555552, 101.999999, 0.566662, 0.566662, 104.000005, 0.577772, 0.577772,
    105.999999, 0.588883, 0.588883, 108.000004, 0.599993, 0.599993, 109.999999,
    0.611104, 0.611104, 112.000004, 0.622214, 0.622214, 113.999999, 0.633325,
    0.633325, 116.000004, 0.644436, 0.644436, 117.999998, 0.655546, 0.655546,
    120.000004, 0.666657, 0.666657, 121.999998, 0.677768, 0.677768, 124.000003,
    0.688879, 0.688879, 125.999998, 0.699989, 0.699989, 128.000003, 0.7111,
    0.7111, 129.999998, 0.722211, 0.722211, 132.000003, 0.733322, 0.733322,
    133.999997, 0.744433, 0.744433, 136.000003, 0.755544, 0.755544, 137.999997,
    0.766655, 0.766655, 140.000002, 0.777766, 0.777766, 141.999997, 0.788878,
    0.788878, 144.000002, 0.799989, 0.799989, 145.999997, 0.8111, 0.8111,
    148.000002, 0.822212, 0.822212, 149.999996, 0.833323, 0.833323, 152.000002,
    0.844434, 0.844434, 153.999996, 0.855546, 0.855546, 156.000001, 0.866657,
    0.866657, 157.999996, 0.877769, 0.877769, 160.000001, 0.888881, 0.888881,
    161.999996, 0.899992, 0.899992, 164.000001, 0.911104, 0.911104, 165.999995,
    0.922216, 0.922216, 168.000001, 0.933328, 0.933328, 169.999995, 0.94444,
    0.94444, 172, 0.955552, 0.955552, 173.999995, 0.966664, 0.966664, 176,
    0.977776, 0.977776, 177.999995, 0.988888, 0.988888, 180, 1, 1,
  ],
  V2: [
    0, 0, 0, 2, 0.01657, 0.01657, 4, 0.033355, 0.033355, 6.000001, 0.050427,
    0.050427, 8.000001, 0.067829, 0.067829, 10.000001, 0.085579, 0.085579,
    12.000002, 0.103701, 0.103701, 14.000002, 0.122211, 0.122211, 16.000002,
    0.141118, 0.141118, 18.000002, 0.160431, 0.160431, 20.000003, 0.180158,
    0.180158, 22.000003, 0.200305, 0.200305, 24.000004, 0.220879, 0.220879,
    26.000004, 0.24189, 0.24189, 28.000004, 0.263342, 0.263342, 30.000004,
    0.285287, 0.285287, 32.000003, 0.307798, 0.307798, 34.000003, 0.330894,
    0.330894, 36.000003, 0.354621, 0.354621, 38.000006, 0.378973, 0.378973,
    40.000006, 0.403958, 0.403958, 42.000006, 0.429573, 0.429573, 44.000005,
    0.455807, 0.455807, 46.000008, 0.482647, 0.482647, 48.000008, 0.510071,
    0.510071, 50.000008, 0.538028, 0.538028, 52.000008, 0.566467, 0.566467,
    54.000008, 0.595279, 0.595279, 56.000007, 0.624245, 0.624245, 58.000007,
    0.652934, 0.652934, 60.000007, 0.680994, 0.680994, 62.000007, 0.708216,
    0.708216, 64.000007, 0.734464, 0.734464, 66.000007, 0.759653, 0.759653,
    68.000007, 0.783753, 0.783753, 70.000007, 0.806784, 0.806784, 72.000006,
    0.828838, 0.828838, 74.000006, 0.850111, 0.850111, 76.000012, 0.870746,
    0.870746, 78.000011, 0.8908, 0.8908, 80.000011, 0.9103, 0.9103, 82.000011,
    0.929263, 0.929263, 84.000011, 0.947707, 0.947707, 86.000011, 0.965637,
    0.965637, 88.000011, 0.983066, 0.983066, 90.000011, 1, 1,
  ],
  V3: [
    0, 0, 0, 2, 0.016706, 0.016706, 4, 0.033472, 0.033472, 6.000001, 0.050321,
    0.050321, 8.000001, 0.067264, 0.067264, 10.000001, 0.084313, 0.084313,
    12.000002, 0.101475, 0.101475, 14.000002, 0.11876, 0.11876, 16.000002,
    0.136174, 0.136174, 18.000002, 0.153725, 0.153725, 20.000003, 0.171423,
    0.171423, 22.000003, 0.189277, 0.189277, 24.000004, 0.207299, 0.207299,
    26.000004, 0.225504, 0.225504, 28.000004, 0.243915, 0.243915, 30.000004,
    0.262564, 0.262564, 32.000003, 0.281509, 0.281509, 34.000003, 0.300786,
    0.300786, 36.000003, 0.320429, 0.320429, 38.000006, 0.340445, 0.340445,
    40.000006, 0.36085, 0.36085, 42.000006, 0.381652, 0.381652, 44.000005,
    0.402859, 0.402859, 46.000008, 0.424478, 0.424478, 48.000008, 0.446512,
    0.446512, 50.000008, 0.468967, 0.468967, 52.000008, 0.491841, 0.491841,
    54.000008, 0.515134, 0.515134, 56.000007, 0.538847, 0.538847, 58.000007,
    0.562972, 0.562972, 60.000007, 0.587506, 0.587506, 62.000007, 0.612471,
    0.612471, 64.000007, 0.637883, 0.637883, 66.000007, 0.663752, 0.663752,
    68.000007, 0.690079, 0.690079, 70.000007, 0.716856, 0.716856, 72.000006,
    0.744068, 0.744068, 74.000006, 0.771673, 0.771673, 76.000012, 0.799583,
    0.799583, 78.000011, 0.827715, 0.827715, 80.000011, 0.856033, 0.856033,
    82.000011, 0.88452, 0.88452, 84.000011, 0.913167, 0.913167, 86.000011,
    0.941967, 0.941967, 88.000011, 0.970912, 0.970912, 90.000011, 1, 1,
  ],
  V4: [
    0, 0, 0, 2, 0.016521, 0.016521, 4, 0.033198, 0.033198, 6, 0.050065,
    0.050065, 8, 0.067139, 0.067139, 10, 0.084436, 0.084436, 12.000001,
    0.101961, 0.101961, 14.000001, 0.119719, 0.119719, 16, 0.137714, 0.137714,
    18, 0.15595, 0.15595, 20, 0.17443, 0.17443, 22, 0.193157, 0.193157,
    24.000001, 0.212143, 0.212143, 26.000001, 0.231383, 0.231383, 28.000001,
    0.250875, 0.250875, 30.000001, 0.270622, 0.270622, 32.000001, 0.290627,
    0.290627, 34.000001, 0.3109, 0.3109, 36.000001, 0.331439, 0.331439, 38,
    0.352242, 0.352242, 40, 0.373313, 0.373313, 42, 0.394653, 0.394653, 44,
    0.416263, 0.416263, 46.000003, 0.438155, 0.438155, 48.000003, 0.460341,
    0.460341, 50.000002, 0.482823, 0.482823, 52.000002, 0.505609, 0.505609,
    54.000002, 0.528704, 0.528704, 56.000002, 0.552111, 0.552111, 58.000002,
    0.575833, 0.575833, 60.000002, 0.59988, 0.59988, 62.000002, 0.624254,
    0.624254, 64.000002, 0.648949, 0.648949, 66.000001, 0.673968, 0.673968,
    68.000001, 0.699313, 0.699313, 70.000001, 0.724986, 0.724986, 72.000001,
    0.75099, 0.75099, 74.000001, 0.777329, 0.777329, 76.000001, 0.804001,
    0.804001, 78.000001, 0.831008, 0.831008, 80.000001, 0.85835, 0.85835, 82,
    0.886022, 0.886022, 84, 0.914021, 0.914021, 86, 0.942354, 0.942354, 88,
    0.971019, 0.971019, 90, 1, 1,
  ],
  IVO: [
    0.0, 0.0, 0.0, 1.20879, 0.02406, 0.02406, 2.41758, 0.04811, 0.04811,
    3.62637, 0.07217, 0.07217, 4.83516, 0.09622, 0.09622, 6.04396, 0.12028,
    0.12028, 7.25275, 0.14434, 0.14434, 8.46154, 0.1684, 0.1684, 9.67033,
    0.19246, 0.19246, 10.87912, 0.21652, 0.21652, 12.08791, 0.24058, 0.24058,
    13.2967, 0.26464, 0.26464, 14.50549, 0.28871, 0.28871, 15.71429, 0.31278,
    0.31278, 16.92308, 0.33685, 0.33685, 18.13187, 0.36092, 0.36092, 19.34066,
    0.385, 0.385, 20.54945, 0.40908, 0.40908, 21.75824, 0.43316, 0.43316,
    22.96703, 0.45725, 0.45725, 24.17582, 0.48134, 0.48134, 25.38462, 0.50544,
    0.50544, 26.59341, 0.52953, 0.52953, 27.8022, 0.55364, 0.55364, 29.01099,
    0.57775, 0.57775, 30.21978, 0.60186, 0.60186, 31.42857, 0.62598, 0.62598,
    32.63736, 0.65011, 0.65011, 33.84615, 0.67424, 0.67424, 35.05495, 0.69838,
    0.69838, 36.26374, 0.72253, 0.72253, 37.47253, 0.74668, 0.74668, 38.68132,
    0.77085, 0.77085, 39.89011, 0.79502, 0.79502, 41.0989, 0.8192, 0.8192,
    42.30769, 0.84339, 0.84339, 43.51648, 0.86759, 0.86759, 44.72527, 0.8918,
    0.8918, 45.93407, 0.91603, 0.91603, 47.14286, 0.94026, 0.94026, 48.35165,
    0.96451, 0.96451, 49.56044, 0.98877, 0.98877, 50.76923, 1.01304, 1.01304,
    51.97802, 1.03733, 1.03733, 53.18681, 1.06163, 1.06163, 54.3956, 1.08595,
    1.08595, 55.6044, 1.11028, 1.11028, 56.81319, 1.13464, 1.13464, 58.02198,
    1.159, 1.159, 59.23077, 1.18339, 1.18339, 60.43956, 1.20779, 1.20779,
    61.64835, 1.23222, 1.23222, 62.85714, 1.25666, 1.25666, 64.06593, 1.28113,
    1.28113, 65.27473, 1.30561, 1.30561, 66.48352, 1.33012, 1.33012, 67.69231,
    1.35465, 1.35465, 68.9011, 1.37921, 1.37921, 70.10989, 1.40378, 1.40378,
    71.31868, 1.42839, 1.42839, 72.52747, 1.45302, 1.45302, 73.73626, 1.47767,
    1.47767, 74.94505, 1.50235, 1.50235, 76.15385, 1.52706, 1.52706, 77.36264,
    1.55179, 1.55179, 78.57143, 1.57655, 1.57655, 79.78022, 1.60134, 1.60134,
    80.98901, 1.62615, 1.62615, 82.1978, 1.651, 1.651, 83.40659, 1.67587,
    1.67587, 84.61538, 1.70077, 1.70077, 85.82418, 1.72569, 1.72569, 87.03297,
    1.75065, 1.75065, 88.24176, 1.77563, 1.77563, 89.45055, 1.80064, 1.80064,
    90.65934, 1.82568, 1.82568, 91.86813, 1.85075, 1.85075, 93.07692, 1.87584,
    1.87584, 94.28571, 1.90096, 1.90096, 95.49451, 1.9261, 1.9261, 96.7033,
    1.95127, 1.95127, 97.91209, 1.97647, 1.97647, 99.12088, 2.0017, 2.0017,
    100.32967, 2.02695, 2.02695, 101.53846, 2.05222, 2.05222, 102.74725,
    2.07753, 2.07753, 103.95604, 2.10286, 2.10286, 105.16484, 2.12822, 2.12822,
    106.37363, 2.15361, 2.15361, 107.58242, 2.17903, 2.17903, 108.79121,
    2.20449, 2.20449, 110.0, 2.22999, 2.22999,
  ],
  A0__V: [
    0.0, 0.0, 0.0, 1.764706, 0.034181, 0.034181, 3.529412, 0.068415, 0.068415,
    5.294118, 0.102756, 0.102756, 7.058824, 0.137259, 0.137259, 8.823529,
    0.171984, 0.171984, 10.588235, 0.206993, 0.206993, 12.352941, 0.242357,
    0.242357, 14.117647, 0.27815, 0.27815, 15.882353, 0.314454, 0.314454,
    17.647059, 0.351362, 0.351362, 19.411765, 0.388972, 0.388972, 21.176471,
    0.427394, 0.427394, 22.941176, 0.466743, 0.466743, 24.705882, 0.50714,
    0.50714, 26.470588, 0.548704, 0.548704, 28.235294, 0.591549, 0.591549, 30.0,
    0.635765, 0.635765, 31.764706, 0.681409, 0.681409, 33.529412, 0.728486,
    0.728486, 35.294118, 0.77694, 0.77694, 37.058824, 0.826649, 0.826649,
    38.823529, 0.87744, 0.87744, 40.588235, 0.929118, 0.929118, 42.352941,
    0.981511, 0.981511, 44.117647, 1.034509, 1.034509, 45.882353, 1.088098,
    1.088098, 47.647059, 1.142385, 1.142385, 49.411765, 1.197605, 1.197605,
    51.176471, 1.25412, 1.25412, 52.941176, 1.312412, 1.312412, 54.705882,
    1.373041, 1.373041, 56.470588, 1.436543, 1.436543, 58.235294, 1.503212,
    1.503212, 60.0, 1.572734, 1.572734, 61.764706, 1.643861, 1.643861,
    63.529412, 1.714533, 1.714533, 65.294118, 1.782596, 1.782596, 67.058824,
    1.846545, 1.846545, 68.823529, 1.905708, 1.905708, 70.588235, 1.959998,
    1.959998, 72.352941, 2.009607, 2.009607, 74.117647, 2.05481, 2.05481,
    75.882353, 2.095884, 2.095884, 77.647059, 2.133077, 2.133077, 79.411765,
    2.166613, 2.166613, 81.176471, 2.1967, 2.1967, 82.941176, 2.223541,
    2.223541, 84.705882, 2.247346, 2.247346, 86.470588, 2.268331, 2.268331,
    88.235294, 2.286727, 2.286727, 90.0, 2.301062, 2.301062,
  ],
  A1UST: [
    0.0, 0.0, 0.0, 1.46774, 0.02534, 0.03312, 2.93548, 0.05067, 0.06622,
    4.40323, 0.07596, 0.09927, 5.87097, 0.1012, 0.1322, 7.33871, 0.12638,
    0.16517, 8.80645, 0.15151, 0.198, 10.27419, 0.17657, 0.23077, 11.74194,
    0.2016, 0.26347, 13.20968, 0.22659, 0.29615, 14.67742, 0.25159, 0.32883,
    16.14516, 0.2766, 0.36155, 17.6129, 0.30168, 0.39437, 19.08065, 0.32685,
    0.42733, 20.54839, 0.35216, 0.46049, 22.01613, 0.37766, 0.49392, 23.48387,
    0.40339, 0.52768, 24.95161, 0.4294, 0.56185, 26.41935, 0.45573, 0.59648,
    27.8871, 0.48243, 0.63164, 29.35484, 0.50956, 0.6674, 30.82258, 0.53713,
    0.7038, 32.29032, 0.56519, 0.7409, 33.75806, 0.59376, 0.77873, 35.22581,
    0.62286, 0.8173, 36.69355, 0.65248, 0.85663, 38.16129, 0.68262, 0.8967,
    39.62903, 0.71325, 0.93748, 41.09677, 0.74434, 0.97892, 42.56452, 0.77585,
    1.02097, 44.03226, 0.80773, 1.06355, 45.5, 0.83993, 1.1066, 46.96774,
    0.8724, 1.15006, 48.43548, 0.9051, 1.19389, 49.90323, 0.93802, 1.23804,
    51.37097, 0.97112, 1.28251, 52.83871, 1.00441, 1.3273, 54.30645, 1.03789,
    1.37242, 55.77419, 1.07158, 1.41791, 57.24194, 1.10551, 1.46381, 58.70968,
    1.13971, 1.51019, 60.17742, 1.17423, 1.5571, 61.64516, 1.20911, 1.60461,
    63.1129, 1.24439, 1.65279, 64.58065, 1.2801, 1.70169, 66.04839, 1.31628,
    1.75137, 67.51613, 1.35295, 1.80185, 68.98387, 1.39012, 1.85317, 70.45161,
    1.42779, 1.90533, 71.91935, 1.46595, 1.95831, 73.3871, 1.50454, 2.01206,
    74.85484, 1.54352, 2.06641, 76.32258, 1.58281, 2.12146, 77.79032, 1.62231,
    2.17699, 79.25806, 1.6619, 2.23286, 80.72581, 1.70147, 2.28888, 82.19355,
    1.74086, 2.34487, 83.66129, 1.77993, 2.40057, 85.12903, 1.81851, 2.45573,
    86.59677, 1.85644, 2.50998, 88.06452, 1.89353, 2.56304, 89.53226, 1.92958,
    2.61428, 91.0, 1.9644, 2.66314,
  ],
  A8TRT: [
    0.0, 0.0, 0.0, 1.51667, 0.02607, 0.02966, 3.03333, 0.05216, 0.05935, 4.55,
    0.07829, 0.0891, 6.06667, 0.1045, 0.1189, 7.58333, 0.13081, 0.14889, 9.1,
    0.15722, 0.17899, 10.61667, 0.18378, 0.20926, 12.13333, 0.21049, 0.23971,
    13.65, 0.23738, 0.27039, 15.16667, 0.26446, 0.30131, 16.68333, 0.29176,
    0.33249, 18.2, 0.31927, 0.36396, 19.71667, 0.34703, 0.39572, 21.23333,
    0.37504, 0.42781, 22.75, 0.40331, 0.46023, 24.26667, 0.43186, 0.49301,
    25.78333, 0.4607, 0.52616, 27.3, 0.48983, 0.55969, 28.81667, 0.51927,
    0.59362, 30.33333, 0.54903, 0.62797, 31.85, 0.5791, 0.66274, 33.36667,
    0.60951, 0.69797, 34.88333, 0.64026, 0.73365, 36.4, 0.67136, 0.76981,
    37.91667, 0.70281, 0.80647, 39.43333, 0.73462, 0.84363, 40.95, 0.7668,
    0.8813, 42.46667, 0.79935, 0.91951, 43.98333, 0.83227, 0.95827, 45.5,
    0.86556, 0.99757, 47.01667, 0.89921, 1.03743, 48.53333, 0.93323, 1.07785,
    50.05, 0.9676, 1.11883, 51.56667, 1.00231, 1.16036, 53.08333, 1.03734,
    1.20244, 54.6, 1.07268, 1.24506, 56.11667, 1.1083, 1.2882, 57.63333,
    1.14417, 1.33184, 59.15, 1.18027, 1.37597, 60.66667, 1.21655, 1.42054,
    62.18333, 1.253, 1.46555, 63.7, 1.28955, 1.51095, 65.21667, 1.32619,
    1.55671, 66.73333, 1.36285, 1.60279, 68.25, 1.3995, 1.64917, 69.76667,
    1.43609, 1.69579, 71.28333, 1.47256, 1.74262, 72.8, 1.50888, 1.78961,
    74.31667, 1.54497, 1.8367, 75.83333, 1.58079, 1.88385, 77.35, 1.61627,
    1.93103, 78.86667, 1.65134, 1.97812, 80.38333, 1.68594, 2.02503, 81.9,
    1.71999, 2.0717, 83.41667, 1.7534, 2.1179, 84.93333, 1.78609, 2.16351,
    86.45, 1.81786, 2.20834, 87.96667, 1.84876, 2.25116, 89.48333, 1.87863,
    2.29397, 91.0, 1.90737, 2.3355,
  ],
  B0__V: [
    0.0, 0.0, 0.0, 2.02222, 0.01703, 0.01703, 4.04444, 0.0341, 0.0341, 6.06667,
    0.05125, 0.05125, 8.08889, 0.06853, 0.06853, 10.11111, 0.08597, 0.08597,
    12.13333, 0.10361, 0.10361, 14.15556, 0.1215, 0.1215, 16.17778, 0.13967,
    0.13967, 18.2, 0.15815, 0.15815, 20.22222, 0.17698, 0.17698, 22.24444,
    0.19619, 0.19619, 24.26667, 0.2158, 0.2158, 26.28889, 0.23584, 0.23584,
    28.31111, 0.25633, 0.25633, 30.33333, 0.27729, 0.27729, 32.35556, 0.29872,
    0.29872, 34.37778, 0.32063, 0.32063, 36.4, 0.34302, 0.34302, 38.42222,
    0.36588, 0.36588, 40.44444, 0.38921, 0.38921, 42.46667, 0.413, 0.413,
    44.48889, 0.43722, 0.43722, 46.51111, 0.46184, 0.46184, 48.53333, 0.48685,
    0.48685, 50.55556, 0.51219, 0.51219, 52.57778, 0.53783, 0.53783, 54.6,
    0.56371, 0.56371, 56.62222, 0.58978, 0.58978, 58.64444, 0.61597, 0.61597,
    60.66667, 0.64221, 0.64221, 62.68889, 0.66843, 0.66843, 64.71111, 0.69456,
    0.69456, 66.73333, 0.72053, 0.72053, 68.75556, 0.74627, 0.74627, 70.77778,
    0.77172, 0.77172, 72.8, 0.79686, 0.79686, 74.82222, 0.82165, 0.82165,
    76.84444, 0.84608, 0.84608, 78.86667, 0.87017, 0.87017, 80.88889, 0.89392,
    0.89392, 82.91111, 0.91738, 0.91738, 84.93333, 0.94057, 0.94057, 86.95556,
    0.96349, 0.96349, 88.97778, 0.98599, 0.98599, 91.0, 1.00731, 1.00731,
  ],
  B4QQV: [
    0.0, 0.0, 0.0, 1.0, 0.009488, 0.009488, 2.0, 0.018975, 0.018975, 3.0,
    0.028461, 0.028461, 4.0, 0.037948, 0.037948, 5.0, 0.047436, 0.047436, 6.0,
    0.056926, 0.056926, 7.0, 0.066418, 0.066418, 8.0, 0.075914, 0.075914, 9.0,
    0.085414, 0.085414, 10.0, 0.094919, 0.094919, 11.0, 0.10443, 0.10443,
    12.000001, 0.113947, 0.113947, 13.000001, 0.123472, 0.123472, 14.000001,
    0.133004, 0.133004, 15.0, 0.142545, 0.142545, 16.0, 0.152096, 0.152096,
    17.0, 0.161656, 0.161656, 18.0, 0.171226, 0.171226, 19.0, 0.180808,
    0.180808, 20.0, 0.1904, 0.1904, 21.0, 0.200005, 0.200005, 22.0, 0.209622,
    0.209622, 23.000001, 0.219252, 0.219252, 24.000001, 0.228896, 0.228896,
    25.000001, 0.238555, 0.238555, 26.000001, 0.248228, 0.248228, 27.000001,
    0.257916, 0.257916, 28.000001, 0.267618, 0.267618, 29.000001, 0.277335,
    0.277335, 30.000001, 0.287072, 0.287072, 31.000001, 0.296825, 0.296825,
    32.000001, 0.306594, 0.306594, 33.000001, 0.316377, 0.316377, 34.000001,
    0.326184, 0.326184, 35.000001, 0.336008, 0.336008, 36.000001, 0.345847,
    0.345847, 37.0, 0.355708, 0.355708, 38.0, 0.36559, 0.36559, 39.0, 0.375488,
    0.375488, 40.0, 0.385407, 0.385407, 41.0, 0.39535, 0.39535, 42.0, 0.405307,
    0.405307, 43.0, 0.415291, 0.415291, 44.0, 0.425296, 0.425296, 45.0,
    0.435314, 0.435314, 46.000003, 0.445371, 0.445371, 47.0, 0.455444, 0.455444,
    48.000003, 0.465544, 0.465544, 49.0, 0.475685, 0.475685, 50.000002, 0.48585,
    0.48585, 51.0, 0.496055, 0.496055, 52.000002, 0.506309, 0.506309, 53.0,
    0.5166, 0.5166, 54.000002, 0.526932, 0.526932, 54.999999, 0.537334,
    0.537334, 56.000002, 0.547786, 0.547786, 56.999999, 0.558292, 0.558292,
    58.000002, 0.568859, 0.568859, 58.999999, 0.579515, 0.579515, 60.000002,
    0.590242, 0.590242, 60.999999, 0.601047, 0.601047, 62.000002, 0.611938,
    0.611938, 62.999999, 0.62292, 0.62292, 64.000002, 0.634004, 0.634004,
    64.999999, 0.645196, 0.645196, 66.000001, 0.656513, 0.656513, 66.999999,
    0.667954, 0.667954, 68.000001, 0.679529, 0.679529, 68.999999, 0.691246,
    0.691246, 70.000001, 0.703123, 0.703123, 70.999998, 0.715172, 0.715172,
    72.000001, 0.727401, 0.727401, 72.999998, 0.739821, 0.739821, 74.000001,
    0.752445, 0.752445, 74.999998, 0.765285, 0.765285, 76.000001, 0.778384,
    0.778384, 76.999998, 0.791743, 0.791743, 78.000001, 0.805362, 0.805362,
    78.999998, 0.819312, 0.819312, 80.000001, 0.833565, 0.833565, 80.999998,
    0.848186, 0.848186, 82.0, 0.863158, 0.863158, 82.999998, 0.878568, 0.878568,
    84.0, 0.894391, 0.894391, 84.999998, 0.910662, 0.910662, 86.0, 0.927452,
    0.927452, 86.999997, 0.944754, 0.944754, 88.0, 0.962596, 0.962596,
    88.999997, 0.981004, 0.981004, 90.0, 1.0, 1.0,
  ],
  B5SST: [
    0.0, 0.0, 0.0, 2.0, 0.016146, 0.016146, 4.0, 0.032316, 0.032316, 6.0,
    0.048525, 0.048525, 8.0, 0.06478, 0.064787, 10.0, 0.081109, 0.081109, 12.0,
    0.097506, 0.097506, 14.0, 0.113985, 0.113985, 16.0, 0.130556, 0.130556,
    18.0, 0.147228, 0.147228, 20.0, 0.164011, 0.164011, 22.0, 0.180914,
    0.180914, 24.0, 0.197947, 0.197947, 26.000001, 0.215125, 0.215125,
    27.999999, 0.23245, 0.23245, 30.0, 0.249928, 0.249928, 32.000001, 0.26759,
    0.26759, 33.999999, 0.285424, 0.285424, 36.0, 0.303451, 0.303451, 38.000001,
    0.321685, 0.321685, 39.999999, 0.340123, 0.340123, 42.0, 0.358802, 0.358802,
    44.000001, 0.3777, 0.3777, 45.999999, 0.396873, 0.396873, 48.0, 0.416284,
    0.416284, 49.999998, 0.436004, 0.436004, 52.000002, 0.455992, 0.455992,
    54.0, 0.476323, 0.476323, 55.999998, 0.496961, 0.496961, 58.000002,
    0.517962, 0.517962, 60.0, 0.539319, 0.539319, 61.999998, 0.561046, 0.561046,
    64.000002, 0.583185, 0.583185, 66.0, 0.605712, 0.605712, 67.999998, 0.62866,
    0.62866, 70.000002, 0.652056, 0.652056, 72.0, 0.675887, 0.675887, 73.999998,
    0.70017, 0.70017, 76.000002, 0.72492, 0.72492, 78.0, 0.750151, 0.750151,
    79.999998, 0.775876, 0.775876, 82.000002, 0.802102, 0.802102, 84.0,
    0.828836, 0.828836, 85.999998, 0.856076, 0.856076, 88.000002, 0.883831,
    0.883831, 90.0, 0.912109, 0.912109, 91.999998, 0.940906, 0.940906, 93.0,
    0.9555, 0.9555,
  ],
  B6SST: [
    0.0, 0.0, 0.0, 1.0, 0.008914, 0.008914, 2.0, 0.017836, 0.017836, 2.999999,
    0.026768, 0.026768, 3.999999, 0.0357, 0.035709, 4.999999, 0.044662,
    0.044662, 5.999999, 0.053627, 0.053627, 6.999999, 0.062607, 0.062607,
    7.999999, 0.071601, 0.071601, 8.999998, 0.080611, 0.080611, 9.999998,
    0.089638, 0.089638, 10.999998, 0.098686, 0.098686, 11.999998, 0.107754,
    0.107754, 12.999998, 0.116842, 0.116842, 13.999998, 0.125953, 0.125953,
    14.999998, 0.135088, 0.135088, 15.999998, 0.144247, 0.144247, 16.999996,
    0.153433, 0.153433, 17.999996, 0.162646, 0.162646, 18.999996, 0.171887,
    0.171887, 19.999996, 0.181159, 0.181159, 20.999996, 0.190465, 0.190465,
    21.999996, 0.199804, 0.199804, 22.999996, 0.209177, 0.209177, 23.999996,
    0.218586, 0.218586, 24.999996, 0.228032, 0.228032, 25.999996, 0.237517,
    0.237517, 26.999996, 0.247041, 0.247041, 27.999996, 0.256606, 0.256606,
    28.999996, 0.266214, 0.266214, 29.999996, 0.27587, 0.27587, 30.999995,
    0.285572, 0.285572, 31.999995, 0.295322, 0.295322, 32.999995, 0.305121,
    0.305121, 33.999993, 0.31497, 0.31497, 34.999993, 0.324872, 0.324872,
    35.999992, 0.334827, 0.334827, 36.999992, 0.344837, 0.344837, 37.999992,
    0.354906, 0.354906, 38.999992, 0.365038, 0.365038, 39.999992, 0.375229,
    0.375229, 40.999992, 0.385483, 0.385483, 41.999992, 0.395801, 0.395801,
    42.999992, 0.406184, 0.406184, 43.999992, 0.416634, 0.416634, 44.999992,
    0.427154, 0.427154, 45.999992, 0.437751, 0.437751, 46.999989, 0.44842,
    0.44842, 47.999992, 0.459163, 0.459163, 48.999989, 0.469979, 0.469979,
    49.999992, 0.48087, 0.48087, 50.999989, 0.491842, 0.491842, 51.999992,
    0.5029, 0.5029, 52.999989, 0.514037, 0.514037, 53.999991, 0.525255,
    0.525255, 54.999989, 0.536557, 0.536557, 55.999991, 0.547956, 0.547956,
    56.999989, 0.559442, 0.559442, 57.999991, 0.571016, 0.571016, 58.999988,
    0.582681, 0.582681, 59.999991, 0.594448, 0.594448, 60.999988, 0.606314,
    0.606314, 61.999991, 0.618276, 0.618276, 62.999988, 0.630335, 0.630335,
    63.999991, 0.6425, 0.6425, 64.999988, 0.654777, 0.654777, 65.999991,
    0.667158, 0.667158, 66.999988, 0.679644, 0.679644, 67.999985, 0.692239,
    0.692239, 68.999988, 0.704944, 0.704944, 69.999985, 0.717772, 0.717772,
    70.999988, 0.730714, 0.730714, 71.999985, 0.743772, 0.743772, 72.999988,
    0.756947, 0.756947, 73.999985, 0.77024, 0.77024, 74.999987, 0.783653,
    0.783653, 75.999985, 0.797187, 0.797187, 76.999987, 0.810845, 0.810845,
    77.999985, 0.824627, 0.824627, 78.999987, 0.838534, 0.838534, 79.999985,
    0.852568, 0.852568, 80.999987, 0.86673, 0.86673, 81.999984, 0.88102,
    0.88102, 82.999987, 0.895439, 0.895439, 83.999984, 0.909987, 0.909987,
    84.999987, 0.924666, 0.924666, 85.999984, 0.939474, 0.939474, 86.999987,
    0.954412, 0.954412, 87.999984, 0.969479, 0.969479, 88.999987, 0.984676,
    0.984676, 90.0, 1.0, 1.0,
  ],
  B72YV: [
    0.0, 0.0, 0.0, 1.16667, 0.14231, 0.16532, 2.33333, 0.28475, 0.33082, 3.5,
    0.42746, 0.49665, 4.66667, 0.57058, 0.66299, 5.83333, 0.71424, 0.83, 7.0,
    0.85857, 0.99785, 8.16667, 1.0037, 1.16669, 9.33333, 1.14975, 1.33669, 10.5,
    1.29685, 1.508, 11.66667, 1.44511, 1.68076, 12.83333, 1.59463, 1.85512,
    14.0, 1.74553, 2.0312, 15.16667, 1.89789, 2.20914, 16.33333, 2.0518,
    2.38904, 17.5, 2.20735, 2.57101, 18.66667, 2.36459, 2.75514, 19.83333,
    2.52358, 2.94151, 21.0, 2.68437, 3.13019, 22.16667, 2.84698, 3.32123,
    23.33333, 3.01145, 3.51467, 24.5, 3.17777, 3.71053, 25.66667, 3.34594,
    3.90883, 26.83333, 3.51594, 4.10956, 28.0, 3.68774, 4.31271, 29.16667,
    3.86132, 4.51826, 30.33333, 4.03661, 4.72616, 31.5, 4.21356, 4.93638,
    32.66667, 4.3921, 5.14886, 33.83333, 4.57216, 5.36354, 35.0, 4.75366,
    5.58035, 36.16667, 4.93652, 5.79922, 37.33333, 5.12064, 6.02007, 38.5,
    5.30592, 6.24281, 39.66667, 5.49226, 6.46736, 40.83333, 5.67954, 6.69361,
    42.0, 5.86765, 6.92144, 43.16667, 6.05646, 7.15073, 44.33333, 6.24582,
    7.38135, 45.5, 6.4356, 7.61315, 46.66667, 6.62563, 7.84594, 47.83333,
    6.81574, 8.07955, 49.0, 7.00575, 8.31376, 50.16667, 7.19545, 8.54836,
    51.33333, 7.38464, 8.78309, 52.5, 7.57311, 9.0177, 53.66667, 7.76062,
    9.25189, 54.83333, 7.94693, 9.48539, 56.0, 8.1318, 9.71788, 57.16667,
    8.31498, 9.94905, 58.33333, 8.49624, 10.1786, 59.5, 8.67531, 10.40622,
    60.66667, 8.85196, 10.63161, 61.83333, 9.02597, 10.85447, 63.0, 9.1971,
    11.07454, 64.16667, 9.36515, 11.29156, 65.33333, 9.52992, 11.50531, 66.5,
    9.69125, 11.71556, 67.66667, 9.84895, 11.92215, 68.83333, 10.0029, 12.12491,
    70.0, 10.15296, 12.3237, 71.16667, 10.29902, 12.5184, 72.33333, 10.44098,
    12.70892, 73.5, 10.57876, 12.89516, 74.66667, 10.71228, 13.07706, 75.83333,
    10.84149, 13.25454, 77.0, 10.96633, 13.42754, 78.16667, 11.08675, 13.596,
    79.33333, 11.20273, 13.75983, 80.5, 11.31422, 13.91897, 81.66667, 11.42121,
    14.07332, 82.83333, 11.52367, 14.22277, 84.0, 11.62158, 14.36719, 85.16667,
    11.71493, 14.50643, 86.33333, 11.8037, 14.64033, 87.5, 11.8879, 14.76869,
    88.66667, 11.96752, 14.89131, 89.83333, 12.043, 15.008, 91.0, 12.11304,
    15.11841,
  ],
  B8QQT: [
    0, 0.0, 0.0, 2, 0.033, 0.033, 4, 0.066, 0.066, 6, 0.1, 0.1, 8, 0.133, 0.133,
    10, 0.166, 0.166, 12, 0.2, 0.2, 14, 0.233, 0.233, 16, 0.268, 0.268, 18,
    0.302, 0.302, 20, 0.336, 0.336, 22, 0.37, 0.37, 24, 0.405, 0.405, 26, 0.44,
    0.44, 28, 0.475, 0.475, 30, 0.51, 0.51, 32, 0.546, 0.546, 34, 0.582, 0.582,
    36, 0.618, 0.618, 38, 0.654, 0.654, 40, 0.691, 0.691, 42, 0.728, 0.728, 44,
    0.766, 0.766, 46, 0.803, 0.803, 48, 0.842, 0.842, 50, 0.88, 0.88, 52, 0.919,
    0.919, 54, 0.959, 0.959, 56, 0.999, 0.999, 58, 1.039, 1.039, 60, 1.08, 1.08,
    62, 1.121, 1.121, 64, 1.163, 1.163, 66, 1.206, 1.206, 68, 1.249, 1.249, 70,
    1.292, 1.292, 72, 1.337, 1.337, 74, 1.381, 1.381, 76, 1.427, 1.427, 78,
    1.472, 1.472, 80, 1.519, 1.519, 82, 1.565, 1.565, 84, 1.613, 1.613, 86,
    1.66, 1.66, 88, 1.708, 1.708, 89, 1.734, 1.734, 90, 1.755, 1.755,
  ],
  B9VVT: [
    0.0, 0.0, 0.0, 0.95, 0.0216, 0.0216, 1.9, 0.0433, 0.0433, 2.85, 0.0649,
    0.0649, 3.8, 0.0866, 0.0866, 4.75, 0.1082, 0.1082, 5.7, 0.1299, 0.1299,
    6.65, 0.1516, 0.1516, 7.6, 0.1733, 0.1733, 8.55, 0.1951, 0.1951, 9.5,
    0.2168, 0.2168, 10.45, 0.2386, 0.2386, 11.4, 0.2604, 0.2604, 12.35, 0.2822,
    0.2822, 13.3, 0.3041, 0.3041, 14.25, 0.326, 0.326, 15.2, 0.348, 0.348,
    16.15, 0.37, 0.37, 17.1, 0.392, 0.392, 18.05, 0.4141, 0.4141, 19.0, 0.4363,
    0.4363, 19.95, 0.4585, 0.4585, 20.9, 0.4807, 0.4807, 21.85, 0.5031, 0.5031,
    22.8, 0.5255, 0.5255, 23.75, 0.5479, 0.5479, 24.7, 0.5705, 0.5705, 25.65,
    0.5931, 0.5931, 26.6, 0.6158, 0.6158, 27.55, 0.6386, 0.6386, 28.5, 0.6614,
    0.6614, 29.45, 0.6844, 0.6844, 30.4, 0.7075, 0.7075, 31.35, 0.7306, 0.7306,
    32.3, 0.7539, 0.7539, 33.25, 0.7773, 0.7773, 34.2, 0.8008, 0.8008, 35.15,
    0.8244, 0.8244, 36.1, 0.8482, 0.8482, 37.05, 0.8721, 0.8721, 38.0, 0.8962,
    0.8962, 38.95, 0.9204, 0.9204, 39.9, 0.9447, 0.9447, 40.85, 0.9692, 0.9692,
    41.8, 0.9939, 0.9939, 42.75, 1.0187, 1.0187, 43.7, 1.0438, 1.0438, 44.65,
    1.069, 1.069, 45.6, 1.0944, 1.0944, 46.55, 1.1201, 1.1201, 47.5, 1.1459,
    1.1459, 48.45, 1.172, 1.172, 49.4, 1.1983, 1.1983, 50.35, 1.2248, 1.2248,
    51.3, 1.2516, 1.2516, 52.25, 1.2786, 1.2786, 53.2, 1.3059, 1.3059, 54.15,
    1.3334, 1.3334, 55.1, 1.3612, 1.3612, 56.05, 1.3892, 1.3892, 57.0, 1.4176,
    1.4176, 57.95, 1.4462, 1.4462, 58.9, 1.475, 1.475, 59.85, 1.5042, 1.5042,
    60.8, 1.5336, 1.5336, 61.75, 1.5633, 1.5633, 62.7, 1.5932, 1.5932, 63.65,
    1.6234, 1.6234, 64.6, 1.6539, 1.6539, 65.55, 1.6846, 1.6846, 66.5, 1.7155,
    1.7155, 67.45, 1.7466, 1.7466, 68.4, 1.778, 1.778, 69.35, 1.8095, 1.8095,
    70.3, 1.8412, 1.8412, 71.25, 1.8731, 1.8731, 72.2, 1.9051, 1.9051, 73.15,
    1.9373, 1.9373, 74.1, 1.9696, 1.9696, 75.05, 2.0019, 2.0019, 76.0, 2.0344,
    2.0344, 76.95, 2.067, 2.067, 77.9, 2.0996, 2.0996, 78.85, 2.1323, 2.1323,
    79.8, 2.1651, 2.1651, 80.75, 2.1979, 2.1979, 81.7, 2.2307, 2.2307, 82.65,
    2.2636, 2.2636, 83.6, 2.2966, 2.2966, 84.55, 2.3296, 2.3296, 85.5, 2.3627,
    2.3627, 86.45, 2.3958, 2.3958, 87.4, 2.429, 2.429, 88.35, 2.4623, 2.4623,
    89.3, 2.4957, 2.4957, 90.25, 2.5292, 2.5292, 91.2, 2.5628, 2.5628, 92.15,
    2.5965, 2.5965, 93.1, 2.6303, 2.6303, 94.05, 2.6642, 2.6642, 95.0, 2.6981,
    2.6981,
  ],
  C1ZZV: [
    0, 0, 0, 1.02747, 0.02009, 0.02009, 2.05495, 0.04019, 0.04019, 3.08242,
    0.06029, 0.06029, 4.10989, 0.08041, 0.08041, 5.13736, 0.10055, 0.10055,
    6.16484, 0.12071, 0.12071, 7.19231, 0.1409, 0.1409, 8.21978, 0.16112,
    0.16112, 9.24725, 0.18138, 0.18138, 10.27473, 0.20167, 0.20167, 11.3022,
    0.22201, 0.22201, 12.32967, 0.2424, 0.2424, 13.35714, 0.26283, 0.26283,
    14.38462, 0.28331, 0.28331, 15.41209, 0.30385, 0.30385, 16.43956, 0.32444,
    0.32444, 17.46703, 0.34509, 0.34509, 18.49451, 0.3658, 0.3658, 19.52198,
    0.38658, 0.38658, 20.54945, 0.40741, 0.40741, 21.57692, 0.42831, 0.42831,
    22.6044, 0.44927, 0.44927, 23.63187, 0.47031, 0.47031, 24.65934, 0.49141,
    0.49141, 25.68681, 0.51259, 0.51259, 26.71429, 0.53383, 0.53383, 27.74176,
    0.55515, 0.55515, 28.76923, 0.57655, 0.57655, 29.7967, 0.59802, 0.59802,
    30.82418, 0.61957, 0.61957, 31.85165, 0.6412, 0.6412, 32.87912, 0.6629,
    0.6629, 33.90659, 0.68469, 0.68469, 34.93407, 0.70656, 0.70656, 35.96154,
    0.7285, 0.7285, 36.98901, 0.75052, 0.75052, 38.01648, 0.77262, 0.77262,
    39.04396, 0.7948, 0.7948, 40.07143, 0.81705, 0.81705, 41.0989, 0.83937,
    0.83937, 42.12637, 0.86175, 0.86175, 43.15385, 0.8842, 0.8842, 44.18132,
    0.90671, 0.90671, 45.20879, 0.92927, 0.92927, 46.23626, 0.95188, 0.95188,
    47.26374, 0.97454, 0.97454, 48.29121, 0.99723, 0.99723, 49.31868, 1.01994,
    1.01994, 50.34615, 1.04268, 1.04268, 51.37363, 1.06543, 1.06543, 52.4011,
    1.08819, 1.08819, 53.42857, 1.11094, 1.11094, 54.45604, 1.13367, 1.13367,
    55.48352, 1.15638, 1.15638, 56.51099, 1.17906, 1.17906, 57.53846, 1.20169,
    1.20169, 58.56593, 1.22427, 1.22427, 59.59341, 1.24679, 1.24679, 60.62088,
    1.26922, 1.26922, 61.64835, 1.29157, 1.29157, 62.67582, 1.31383, 1.31383,
    63.7033, 1.33597, 1.33597, 64.73077, 1.358, 1.358, 65.75824, 1.3799, 1.3799,
    66.78571, 1.40165, 1.40165, 67.81319, 1.42326, 1.42326, 68.84066, 1.4447,
    1.4447, 69.86813, 1.46598, 1.46598, 70.8956, 1.48707, 1.48707, 71.92308,
    1.50797, 1.50797, 72.95055, 1.52867, 1.52867, 73.97802, 1.54916, 1.54916,
    75.00549, 1.56944, 1.56944, 76.03297, 1.58949, 1.58949, 77.06044, 1.60931,
    1.60931, 78.08791, 1.6289, 1.6289, 79.11538, 1.64824, 1.64824, 80.14286,
    1.66733, 1.66733, 81.17033, 1.68617, 1.68617, 82.1978, 1.70475, 1.70475,
    83.22527, 1.72307, 1.72307, 84.25275, 1.74112, 1.74112, 85.28022, 1.75892,
    1.75892, 86.30769, 1.77646, 1.77646, 87.33516, 1.79374, 1.79374, 88.36264,
    1.81077, 1.81077, 89.39011, 1.82756, 1.82756, 90.41758, 1.8441, 1.8441,
    91.44505, 1.86046, 1.86046, 92.47253, 1.87663, 1.87663, 93.5, 1.89264,
    1.89264,
  ],
  C2TTV: [
    0.0, 0.0, 0.0, 1.02747, 0.01289, 0.01289, 2.05495, 0.02579, 0.02579,
    3.08242, 0.03871, 0.03871, 4.10989, 0.05164, 0.0516, 5.13736, 0.0646,
    0.0646, 6.16484, 0.07758, 0.07758, 7.19231, 0.09061, 0.09061, 8.21978,
    0.10368, 0.10368, 9.24725, 0.11679, 0.11679, 10.27473, 0.12996, 0.12996,
    11.3022, 0.1432, 0.1432, 12.32967, 0.15649, 0.15649, 13.35714, 0.16986,
    0.16986, 14.38462, 0.18331, 0.18331, 15.41209, 0.19684, 0.19684, 16.43956,
    0.21045, 0.21045, 17.46703, 0.22415, 0.22415, 18.49451, 0.23795, 0.23795,
    19.52198, 0.25184, 0.25184, 20.54945, 0.26584, 0.26584, 21.57692, 0.27994,
    0.27994, 22.6044, 0.29415, 0.29415, 23.63187, 0.30847, 0.30847, 24.65934,
    0.3229, 0.3229, 25.68681, 0.33745, 0.33745, 26.71429, 0.35211, 0.35211,
    27.74176, 0.36689, 0.36689, 28.76923, 0.38179, 0.38179, 29.7967, 0.39681,
    0.39681, 30.82418, 0.41194, 0.41194, 31.85165, 0.4272, 0.4272, 32.87912,
    0.44257, 0.44257, 33.90659, 0.45805, 0.45805, 34.93407, 0.47365, 0.47365,
    35.96154, 0.48936, 0.48936, 36.98901, 0.50518, 0.50518, 38.01648, 0.5211,
    0.5211, 39.04396, 0.53713, 0.53713, 40.07143, 0.55325, 0.55325, 41.0989,
    0.56946, 0.56946, 42.12637, 0.58575, 0.58575, 43.15385, 0.60213, 0.60213,
    44.18132, 0.61858, 0.61858, 45.20879, 0.63509, 0.63509, 46.23626, 0.65165,
    0.65165, 47.26374, 0.66826, 0.66826, 48.29121, 0.68491, 0.68491, 49.31868,
    0.70159, 0.70159, 50.34615, 0.71829, 0.71829, 51.37363, 0.73499, 0.73499,
    52.4011, 0.75168, 0.75168, 53.42857, 0.76836, 0.76836, 54.45604, 0.78501,
    0.78501, 55.48352, 0.80163, 0.80163, 56.51099, 0.81819, 0.81819, 57.53846,
    0.83468, 0.83468, 58.56593, 0.8511, 0.8511, 59.59341, 0.86744, 0.86744,
    60.62088, 0.88367, 0.88367, 61.64835, 0.8998, 0.8998, 62.67582, 0.91581,
    0.91581, 63.7033, 0.93169, 0.93169, 64.73077, 0.94742, 0.94742, 65.75824,
    0.96301, 0.96301, 66.78571, 0.97843, 0.97843, 67.81319, 0.99368, 0.99368,
    68.84066, 1.00875, 1.00875, 69.86813, 1.02362, 1.02362, 70.8956, 1.0383,
    1.0383, 71.92308, 1.05276, 1.05276, 72.95055, 1.067, 1.067, 73.97802,
    1.08101, 1.08101, 75.00549, 1.09477, 1.09477, 76.03297, 1.10829, 1.10829,
    77.06044, 1.12155, 1.12155, 78.08791, 1.13454, 1.13454, 79.11538, 1.14726,
    1.14726, 80.14286, 1.15969, 1.15969, 81.17033, 1.17184, 1.17184, 82.1978,
    1.1837, 1.1837, 83.22527, 1.19526, 1.19526, 84.25275, 1.20653, 1.20653,
    85.28022, 1.2175, 1.2175, 86.30769, 1.22817, 1.22817, 87.33516, 1.23857,
    1.23857, 88.36264, 1.24869, 1.24869, 89.39011, 1.25855, 1.25855, 91.44505,
    1.27761, 1.27761, 92.47253, 1.28688, 1.28688, 93.5, 1.29608, 1.29608,
  ],
  C322V: [
    0, 0, 0, 1, 0.1122, 0.1122, 2, 0.22441, 0.22441, 3, 0.33662, 0.33662, 4,
    0.44884, 0.44884, 5, 0.56107, 0.56107, 6, 0.67332, 0.67332, 7, 0.78558,
    0.78558, 8, 0.89787, 0.89787, 9, 1.01018, 1.01018, 10, 1.12252, 1.12252, 11,
    1.23489, 1.23489, 1, 1.34729, 1.34729, 13, 1.45974, 1.45974, 14, 1.57223,
    1.57223, 15, 1.68477, 1.68477, 16, 1.79736, 1.79736, 17, 1.91001, 1.91001,
    1, 2.02273, 2.02273, 19, 2.13552, 2.13552, 20, 2.24838, 2.24838, 21,
    2.36133, 2.36133, 22, 2.47437, 2.47437, 23, 2.58751, 2.58751, 2, 2.70075,
    2.70075, 25, 2.81411, 2.81411, 26, 2.92759, 2.92759, 27, 3.04121, 3.04121,
    28, 3.15497, 3.15497, 29, 3.26888, 3.26888, 3, 3.38296, 3.38296, 31,
    3.49722, 3.49722, 32, 3.61166, 3.61166, 33, 3.72631, 3.72631, 34, 3.84118,
    3.84118, 35, 3.95628, 3.95628, 3, 4.07162, 4.07162, 37, 4.18723, 4.18723,
    38, 4.30311, 4.30311, 39, 4.41929, 4.41929, 40, 4.53579, 4.53579, 41,
    4.65262, 4.65262, 4, 4.7698, 4.7698, 43, 4.88735, 4.88735, 44, 5.0053,
    5.0053, 45, 5.12366, 5.12366, 46, 5.24246, 5.24246, 47, 5.36171, 5.36171, 4,
    5.48145, 5.48145, 49, 5.6017, 5.6017, 50, 5.72247, 5.72247, 51, 5.8438,
    5.8438, 52, 5.9657, 5.9657, 53, 6.08821, 6.08821, 5, 6.21134, 6.21134, 55,
    6.33512, 6.33512, 56, 6.45957, 6.45957, 57, 6.58472, 6.58472, 58, 6.71058,
    6.71058, 59, 6.83718, 6.83718, 60, 6.96454, 6.96454, 61, 7.09267, 7.09267,
    62, 7.22159, 7.22159, 63, 7.35133, 7.35133, 64, 7.48188, 7.48188, 65,
    7.61326, 7.61326, 66, 7.74548, 7.74548, 67, 7.87855, 7.87855, 68, 8.01246,
    8.01246, 69, 8.14723, 8.14723, 70, 8.28286, 8.28286, 71, 8.41933, 8.41933,
    72, 8.55666, 8.55666, 73, 8.69483, 8.69483, 74, 8.83384, 8.83384, 75,
    8.97369, 8.97369, 76, 9.11438, 9.11438, 77, 9.2559, 9.2559, 7, 9.39826,
    9.39826, 79, 9.54146, 9.54146, 80, 9.68551, 9.68551, 81, 9.83044, 9.83044,
    82, 9.97626, 9.97626, 83, 10.12301, 10.12301, 84, 10.27073, 10.27073, 85,
    10.4195, 10.4195, 86, 10.5694, 10.5694, 87, 10.72054, 10.72054, 88,
    10.87307, 10.87307, 89, 11.02719, 11.02719, 90, 11.18321, 11.18321, 91,
    11.34136, 11.34136,
  ],
  C4VVV: [
    0.0, 0.0, 0.0, 1.20879, 0.02406, 0.02406, 2.41758, 0.04811, 0.04811,
    3.62637, 0.07217, 0.07217, 4.83516, 0.09622, 0.09622, 6.04396, 0.12028,
    0.12028, 7.25275, 0.14434, 0.14434, 8.46154, 0.1684, 0.1684, 9.67033,
    0.19246, 0.19246, 10.87912, 0.21652, 0.21652, 12.08791, 0.24058, 0.24058,
    13.2967, 0.26464, 0.26464, 14.50549, 0.28871, 0.28871, 15.71429, 0.31278,
    0.31278, 16.92308, 0.33685, 0.33685, 18.13187, 0.36092, 0.36092, 19.34066,
    0.385, 0.385, 20.54945, 0.40908, 0.40908, 21.75824, 0.43316, 0.43316,
    22.96703, 0.45725, 0.45725, 24.17582, 0.48134, 0.48134, 25.38462, 0.50544,
    0.50544, 26.59341, 0.52953, 0.52953, 27.8022, 0.55364, 0.55364, 29.01099,
    0.57775, 0.57775, 30.21978, 0.60186, 0.60186, 31.42857, 0.62598, 0.62598,
    32.63736, 0.65011, 0.65011, 33.84615, 0.67424, 0.67424, 35.05495, 0.69838,
    0.69838, 36.26374, 0.72253, 0.72253, 37.47253, 0.74668, 0.74668, 38.68132,
    0.77085, 0.77085, 39.89011, 0.79502, 0.79502, 41.0989, 0.8192, 0.8192,
    42.30769, 0.84339, 0.84339, 43.51648, 0.86759, 0.86759, 44.72527, 0.8918,
    0.8918, 45.93407, 0.91603, 0.91603, 47.14286, 0.94026, 0.94026, 48.35165,
    0.96451, 0.96451, 49.56044, 0.98877, 0.98877, 50.76923, 1.01304, 1.01304,
    51.97802, 1.03733, 1.03733, 53.18681, 1.06163, 1.06163, 54.3956, 1.08595,
    1.08595, 55.6044, 1.11028, 1.11028, 56.81319, 1.13464, 1.13464, 58.02198,
    1.159, 1.159, 59.23077, 1.18339, 1.18339, 60.43956, 1.20779, 1.20779,
    61.64835, 1.23222, 1.23222, 62.85714, 1.25666, 1.25666, 64.06593, 1.28113,
    1.28113, 65.27473, 1.30561, 1.30561, 66.48352, 1.33012, 1.33012, 67.69231,
    1.35465, 1.35465, 68.9011, 1.37921, 1.37921, 70.10989, 1.40378, 1.40378,
    71.31868, 1.42839, 1.42839, 72.52747, 1.45302, 1.45302, 73.73626, 1.47767,
    1.47767, 74.94505, 1.50235, 1.50235, 76.15385, 1.52706, 1.52706, 77.36264,
    1.55179, 1.55179, 78.57143, 1.57655, 1.57655, 79.78022, 1.60134, 1.60134,
    80.98901, 1.62615, 1.62615, 82.1978, 1.651, 1.651, 83.40659, 1.67587,
    1.67587, 84.61538, 1.70077, 1.70077, 85.82418, 1.72569, 1.72569, 87.03297,
    1.75065, 1.75065, 88.24176, 1.77563, 1.77563, 89.45055, 1.80064, 1.80064,
    90.65934, 1.82568, 1.82568, 91.86813, 1.85075, 1.85075, 93.07692, 1.87584,
    1.87584, 94.28571, 1.90096, 1.90096, 95.49451, 1.9261, 1.9261, 96.7033,
    1.95127, 1.95127, 97.91209, 1.97647, 1.97647, 99.12088, 2.0017, 2.0017,
    100.32967, 2.02695, 2.02695, 101.53846, 2.05222, 2.05222, 102.74725,
    2.07753, 2.07753, 103.95604, 2.10286, 2.10286, 105.16484, 2.12822, 2.12822,
    106.37363, 2.15361, 2.15361, 107.58242, 2.17903, 2.17903, 108.79121,
    2.20449, 2.20449, 110.0, 2.22999, 2.22999,
  ],
  C7SST: [
    0, 0, 0, 1, 0.008470987, 0.008470987, 2, 0.016941974, 0.016941974, 3,
    0.025412961, 0.025412961, 4, 0.033883947, 0.033883947, 5, 0.04235493,
    0.042354934, 6, 0.050825921, 0.050825921, 7, 0.059296908, 0.059296908, 8,
    0.067767895, 0.067767895, 9, 0.076238882, 0.076238882, 10, 0.085133418,
    0.085133418, 11, 0.093604405, 0.093604405, 12, 0.102075392, 0.102075392, 13,
    0.110969928, 0.110969928, 14, 0.119440915, 0.119440915, 15, 0.12833545,
    0.128335451, 16, 0.136806438, 0.136806438, 17, 0.145700974, 0.145700974, 18,
    0.15459551, 0.15459551, 19, 0.163490047, 0.163490047, 20, 0.17238458,
    0.172384583, 21, 0.181279119, 0.181279119, 22, 0.190173655, 0.190173655, 23,
    0.199068191, 0.199068191, 24, 0.207962728, 0.207962728, 25, 0.217280813,
    0.217280813, 26, 0.226175349, 0.226175349, 27, 0.235493435, 0.235493435, 28,
    0.244811521, 0.244811521, 29, 0.254129606, 0.254129606, 30, 0.263447692,
    0.263447692, 31, 0.272765777, 0.272765777, 32, 0.282507412, 0.282507412, 33,
    0.291825498, 0.291825498, 34, 0.301567133, 0.301567133, 35, 0.311308767,
    0.311308767, 36, 0.321050402, 0.321050402, 37, 0.330792037, 0.330792037, 38,
    0.340533672, 0.340533672, 39, 0.35069885, 0.350698856, 40, 0.360440491,
    0.360440491, 41, 0.370605676, 0.370605676, 42, 0.38077086, 0.38077086, 43,
    0.391359593, 0.391359593, 44, 0.40152477, 0.401524778, 45, 0.412113511,
    0.412113511, 46, 0.422702245, 0.422702245, 47, 0.433290978, 0.433290978, 48,
    0.444303261, 0.444303261, 49, 0.455315544, 0.455315544, 50, 0.466327827,
    0.466327827, 51, 0.47734011, 0.47734011, 52, 0.488352393, 0.488352393, 53,
    0.499788225, 0.499788225, 54, 0.511224058, 0.511224058, 55, 0.52265989,
    0.52265989, 56, 0.534519271, 0.534519271, 57, 0.546378653, 0.546378653, 58,
    0.558238035, 0.558238035, 59, 0.570097416, 0.570097416, 60, 0.581956798,
    0.581956798, 61, 0.594239729, 0.594239729, 62, 0.60652266, 0.60652266, 63,
    0.618805591, 0.618805591, 64, 0.631088522, 0.631088522, 65, 0.643795002,
    0.643795002, 66, 0.656077933, 0.656077933, 67, 0.668784413, 0.668784413, 68,
    0.681067344, 0.681067344, 69, 0.693773825, 0.693773825, 70, 0.706480305,
    0.706480305, 71, 0.719186785, 0.719186785, 72, 0.731893266, 0.731893266, 73,
    0.744599746, 0.744599746, 74, 0.757306226, 0.757306226, 75, 0.770012706,
    0.770012706, 76, 0.782719187, 0.782719187, 77, 0.795425667, 0.795425667, 78,
    0.80813214, 0.808132147, 79, 0.820838628, 0.820838628, 80, 0.833545108,
    0.833545108, 81, 0.846251588, 0.846251588, 82, 0.858958069, 0.858958069, 83,
    0.871664549, 0.871664549, 84, 0.88394748, 0.88394748, 85, 0.89665396,
    0.89665396, 86, 0.908936891, 0.908936891, 87, 0.921219822, 0.921219822, 88,
    0.933926302, 0.933926302, 89, 0.946209233, 0.946209233, 90, 0.958068615,
    0.958068615, 91, 0.970351546, 0.970351546, 92, 0.982210928, 0.982210928, 93,
    0.994070309, 0.994070309, 93.5, 1, 1,
  ],
  C8WWT: [
    0.0, 0.0, 0.0, 1.0, 0.014562, 0.014562, 2.0, 0.029111, 0.029111, 3.0,
    0.043645, 0.043645, 4.0, 0.05815, 0.058157, 5.0, 0.072649, 0.072649, 6.0,
    0.087116, 0.087116, 7.0, 0.101559, 0.101559, 8.0, 0.115973, 0.115973, 9.0,
    0.130357, 0.130357, 10.0, 0.144712, 0.144712, 11.0, 0.159035, 0.159035,
    12.000001, 0.173325, 0.173325, 13.000001, 0.187581, 0.187581, 14.000001,
    0.201801, 0.201801, 15.0, 0.215983, 0.215983, 16.0, 0.230127, 0.230127,
    17.0, 0.24423, 0.24423, 18.0, 0.258291, 0.258291, 19.0, 0.27231, 0.27231,
    20.0, 0.286284, 0.286284, 21.0, 0.300213, 0.300213, 22.0, 0.314095,
    0.314095, 23.000001, 0.327928, 0.327928, 24.000001, 0.341711, 0.341711,
    25.000001, 0.355443, 0.355443, 26.000001, 0.369123, 0.369123, 27.000001,
    0.382747, 0.382747, 28.000001, 0.396314, 0.396314, 29.000001, 0.409817,
    0.409817, 30.000001, 0.423259, 0.423259, 31.000001, 0.43664, 0.43664,
    32.000001, 0.449956, 0.449956, 33.000001, 0.463205, 0.463205, 34.000001,
    0.476386, 0.476386, 35.000001, 0.489496, 0.489496, 36.000001, 0.502531,
    0.502531, 37.0, 0.515489, 0.515489, 38.0, 0.528368, 0.528368, 39.0,
    0.541163, 0.541163, 40.0, 0.55387, 0.55387, 41.0, 0.566484, 0.566484, 42.0,
    0.579005, 0.579005, 43.0, 0.591428, 0.591428, 44.0, 0.60375, 0.60375, 45.0,
    0.615964, 0.615964, 46.000003, 0.628059, 0.628059, 47.0, 0.640034, 0.640034,
    48.000003, 0.651883, 0.651883, 49.0, 0.663602, 0.663602, 50.000002,
    0.675185, 0.675185, 51.0, 0.68663, 0.68663, 52.000002, 0.697933, 0.697933,
    53.0, 0.709082, 0.709082, 54.000002, 0.720082, 0.720082, 54.999999,
    0.730928, 0.730928, 56.000002, 0.741619, 0.741619, 56.999999, 0.752151,
    0.752151, 58.000002, 0.762522, 0.762522, 58.999999, 0.772731, 0.772731,
    60.000002, 0.782775, 0.782775, 60.999999, 0.792653, 0.792653, 62.000002,
    0.802361, 0.802361, 62.999999, 0.811899, 0.811899, 64.000002, 0.821263,
    0.821263, 64.999999, 0.830454, 0.830454, 66.000001, 0.839468, 0.839468,
    66.999999, 0.848303, 0.848303, 68.000001, 0.856959, 0.856959, 68.999999,
    0.865426, 0.865426, 70.000001, 0.873711, 0.873711, 70.999998, 0.881813,
    0.881813, 72.000001, 0.88973, 0.88973, 72.999998, 0.897463, 0.897463,
    74.000001, 0.90501, 0.90501, 74.999998, 0.91237, 0.91237, 76.000001,
    0.919543, 0.919543, 76.999998, 0.926522, 0.926522, 78.000001, 0.933306,
    0.933306, 78.999998, 0.939901, 0.939901, 80.000001, 0.946307, 0.946307,
    80.999998, 0.952525, 0.952525, 82.0, 0.958558, 0.958558, 82.999998, 0.9644,
    0.9644, 84.0, 0.970036, 0.970036, 84.999998, 0.975496, 0.975496, 86.0,
    0.980762, 0.980762, 86.999997, 0.985845, 0.985845, 88.0, 0.990737, 0.990737,
    88.999997, 0.995454, 0.995454, 90.0, 1.0, 1.0,
  ],
  C9VVT: [
    0, 0.0, 0.0, 1, 0.0232, 0.0232, 2, 0.0463, 0.0463, 3, 0.0694, 0.0694, 4,
    0.0924, 0.0924, 5, 0.1153, 0.1153, 6, 0.1382, 0.1382, 7, 0.1611, 0.1611, 8,
    0.1839, 0.1839, 9, 0.2067, 0.2067, 10, 0.2295, 0.2295, 11, 0.2523, 0.2523,
    12, 0.275, 0.275, 13, 0.2978, 0.2978, 14, 0.3206, 0.3206, 15, 0.3433,
    0.3433, 16, 0.3661, 0.3661, 17, 0.389, 0.389, 18, 0.4118, 0.4118, 19,
    0.4347, 0.4347, 20, 0.4577, 0.4577, 21, 0.4807, 0.4807, 22, 0.5038, 0.5038,
    23, 0.5269, 0.5269, 24, 0.5501, 0.5501, 25, 0.5734, 0.5734, 26, 0.5967,
    0.5967, 27, 0.6202, 0.6202, 28, 0.6438, 0.6438, 29, 0.6674, 0.6674, 30,
    0.6912, 0.6912, 31, 0.7151, 0.7151, 32, 0.7392, 0.7392, 33, 0.7633, 0.7633,
    34, 0.7876, 0.7876, 35, 0.8121, 0.8121, 36, 0.8367, 0.8367, 37, 0.8614,
    0.8614, 38, 0.8864, 0.8864, 39, 0.9115, 0.9115, 40, 0.9368, 0.9368, 41,
    0.9622, 0.9622, 42, 0.9879, 0.9879, 43, 1.0137, 1.0137, 44, 1.0398, 1.0398,
    45, 1.0661, 1.0661, 46, 1.0926, 1.0926, 47, 1.1193, 1.1193, 48, 1.1463,
    1.1463, 49, 1.1735, 1.1735, 50, 1.2009, 1.2009, 51, 1.2286, 1.2286, 52,
    1.2566, 1.2566, 53, 1.2848, 1.2848, 54, 1.3133, 1.3133, 55, 1.342, 1.342,
    56, 1.3711, 1.3711, 57, 1.4005, 1.4005, 58, 1.4301, 1.4301, 59, 1.4601,
    1.4601, 60, 1.4903, 1.4903, 61, 1.5209, 1.5209, 62, 1.5518, 1.5518, 63,
    1.5831, 1.5831, 64, 1.6147, 1.6147, 65, 1.6466, 1.6466, 66, 1.6789, 1.6789,
    67, 1.7115, 1.7115, 68, 1.7445, 1.7445, 69, 1.7779, 1.7779, 70, 1.8117,
    1.8117, 71, 1.8458, 1.8458, 72, 1.8804, 1.8804, 73, 1.9153, 1.9153, 74,
    1.9507, 1.9507, 75, 1.9864, 1.9864, 76, 2.0226, 2.0226, 77, 2.0592, 2.0592,
    78, 2.0962, 2.0962, 79, 2.1337, 2.1337, 80, 2.1716, 2.1716, 81, 2.21, 2.21,
    82, 2.2488, 2.2488, 83, 2.2881, 2.2881, 84, 2.3279, 2.3279, 85, 2.3681,
    2.3681, 86, 2.4088, 2.4088, 87, 2.4501, 2.4501, 88, 2.4918, 2.4918, 89,
    2.534, 2.534, 90, 2.5767, 2.5767, 91, 2.62, 2.62, 92, 2.6638, 2.6638, 92.5,
    2.6859, 2.6859,
  ],
  D0ZZV: [
    0.0, 0.0, 0.0, 1.0, 0.01957, 0.01957, 2.0, 0.03915, 0.03915, 3.0, 0.05873,
    0.05873, 4.0, 0.07832, 0.07832, 5.0, 0.09792, 0.09792, 6.0, 0.11754,
    0.11754, 7.0, 0.13718, 0.13718, 8.0, 0.15684, 0.15684, 9.0, 0.17653,
    0.17653, 10.0, 0.19626, 0.19626, 11.0, 0.21601, 0.21601, 12.0, 0.2358,
    0.2358, 13.0, 0.25564, 0.25564, 14.0, 0.27552, 0.27552, 15.0, 0.29545,
    0.29545, 16.0, 0.31544, 0.31544, 17.0, 0.33548, 0.33548, 18.0, 0.35558,
    0.35558, 19.0, 0.37574, 0.37574, 20.0, 0.39597, 0.39597, 21.0, 0.41627,
    0.41627, 22.0, 0.43664, 0.43664, 23.0, 0.45708, 0.45708, 24.0, 0.4776,
    0.4776, 25.0, 0.49819, 0.49819, 26.0, 0.51886, 0.51886, 27.0, 0.53961,
    0.53961, 28.0, 0.56044, 0.56044, 29.0, 0.58135, 0.58135, 30.0, 0.60234,
    0.60234, 31.0, 0.62341, 0.62341, 32.0, 0.64456, 0.64456, 33.0, 0.66578,
    0.66578, 34.0, 0.68709, 0.68709, 35.0, 0.70847, 0.70847, 36.0, 0.72992,
    0.72992, 37.0, 0.75145, 0.75145, 38.0, 0.77305, 0.77305, 39.0, 0.79471,
    0.79471, 40.0, 0.81644, 0.81644, 41.0, 0.83823, 0.83823, 42.0, 0.86007,
    0.86007, 43.0, 0.88197, 0.88197, 44.0, 0.90392, 0.90392, 45.0, 0.92591,
    0.92591, 46.0, 0.94794, 0.94794, 47.0, 0.97001, 0.97001, 48.0, 0.9921,
    0.9921, 49.0, 1.01421, 1.01421, 50.0, 1.03634, 1.03634, 51.0, 1.05847,
    1.05847, 52.0, 1.08061, 1.08061, 53.0, 1.10275, 1.10275, 54.0, 1.12487,
    1.12487, 55.0, 1.14697, 1.14697, 56.0, 1.16904, 1.16904, 57.0, 1.19108,
    1.19108, 58.0, 1.21307, 1.21307, 59.0, 1.23501, 1.23501, 60.0, 1.25689,
    1.25689, 61.0, 1.2787, 1.2787, 62.0, 1.30043, 1.30043, 63.0, 1.32207,
    1.32207, 64.0, 1.34362, 1.34362, 65.0, 1.36506, 1.36506, 66.0, 1.38638,
    1.38638, 67.0, 1.40759, 1.40759, 68.0, 1.42866, 1.42866, 69.0, 1.44959,
    1.44959, 70.0, 1.47036, 1.47036, 71.0, 1.49098, 1.49098, 72.0, 1.51143,
    1.51143, 73.0, 1.5317, 1.5317, 74.0, 1.55178, 1.55178, 75.0, 1.57166,
    1.57166, 76.0, 1.59134, 1.59134, 77.0, 1.6108, 1.6108, 78.0, 1.63004,
    1.63004, 79.0, 1.64905, 1.64905, 80.0, 1.66783, 1.66783, 81.0, 1.68635,
    1.68635, 82.0, 1.70463, 1.70463, 83.0, 1.72265, 1.72265, 84.0, 1.7404,
    1.7404, 85.0, 1.75789, 1.75789, 86.0, 1.77511, 1.77511, 87.0, 1.79206,
    1.79206, 88.0, 1.80873, 1.80873, 89.0, 1.82514, 1.82514, 90.0, 1.84128,
    1.84128, 91.0, 1.85716, 1.85716, 92.0, 1.8728, 1.8728, 93.0, 1.8882, 1.8882,
    94.0, 1.90339, 1.90339, 95.0, 1.9184, 1.9184,
  ],
  D1SST: [
    0, 0, 0, 1, 28, 28, 2, 56, 56, 3, 85, 85, 4, 113, 113, 5, 141, 141, 6, 169,
    169, 7, 197, 197, 8, 226, 226, 9, 254, 254, 10, 282, 282, 11, 310, 310, 12,
    338, 338, 13, 366, 366, 14, 394, 394, 15, 423, 423, 16, 451, 451, 17, 479,
    479, 18, 507, 507, 19, 535, 535, 20, 563, 563, 21, 591, 591, 22, 619, 619,
    23, 647, 647, 24, 675, 675, 25, 703, 703, 26, 731, 731, 27, 759, 759, 28,
    787, 787, 29, 814, 814, 30, 842, 842, 31, 870, 870, 32, 898, 898, 33, 925,
    925, 34, 953, 953, 35, 980, 980, 36, 1008, 1008, 37, 1035, 1035, 38, 1063,
    1063, 39, 1090, 1090, 40, 1117, 1117, 41, 1145, 1145, 42, 1172, 1172, 43,
    1199, 1199, 44, 1226, 1226, 45, 1253, 1253, 46, 1280, 1280, 47, 1306, 1306,
    48, 1333, 1333, 49, 1360, 1360, 50, 1386, 1386, 51, 1412, 1412, 52, 1439,
    1439, 53, 1465, 1465, 54, 1491, 1491, 55, 1517, 1517, 56, 1542, 1542, 57,
    1568, 1568, 58, 1593, 1593, 59, 1619, 1619, 60, 1644, 1644, 61, 1669, 1669,
    62, 1693, 1693, 63, 1718, 1718, 64, 1742, 1742, 65, 1766, 1766, 66, 1790,
    1790, 67, 1814, 1814, 68, 1838, 1838, 69, 1861, 1861, 70, 1884, 1884, 71,
    1907, 1907, 72, 1930, 1930, 73, 1952, 1952, 74, 1975, 1975, 75, 1997, 1997,
    76, 2018, 2018, 77, 2040, 2040, 78, 2061, 2061, 79, 2082, 2082, 80, 2103,
    2103, 81, 2123, 2123, 82, 2144, 2144, 83, 2164, 2164, 84, 2184, 2184, 85,
    2203, 2203, 86, 2222, 2222, 87, 2241, 2241, 88, 2260, 2260, 89, 2279, 2279,
    90, 2297, 2297, 91, 2315, 2315, 92, 2333, 2333, 93, 2351, 2351, 93.5, 2360,
    2360,
  ],
  D2WWV: [
    0.0, 0.0, 0.0, 1.0, 0.0222, 0.0222, 2.0, 0.0444, 0.0444, 3.0, 0.06661,
    0.06661, 4.0, 0.08883, 0.08883, 5.0, 0.11105, 0.11105, 6.0, 0.13329,
    0.13329, 7.0, 0.15555, 0.15555, 8.0, 0.17783, 0.17783, 9.0, 0.20013,
    0.20013, 10.0, 0.22245, 0.22245, 11.0, 0.2448, 0.2448, 12.0, 0.26718,
    0.26718, 13.0, 0.28959, 0.28959, 14.0, 0.31204, 0.31204, 15.0, 0.33453,
    0.33453, 16.0, 0.35705, 0.35705, 17.0, 0.37963, 0.37963, 18.0, 0.40224,
    0.40224, 19.0, 0.42491, 0.42491, 20.0, 0.44762, 0.44762, 21.0, 0.47039,
    0.47039, 22.0, 0.49322, 0.49322, 23.0, 0.51611, 0.51611, 24.0, 0.53905,
    0.53905, 25.0, 0.56206, 0.56206, 26.0, 0.58514, 0.58514, 27.0, 0.60828,
    0.60828, 28.0, 0.6315, 0.6315, 29.0, 0.65478, 0.65478, 30.0, 0.67815,
    0.67815, 31.0, 0.70159, 0.70159, 32.0, 0.72511, 0.72511, 33.0, 0.74871,
    0.74871, 34.0, 0.7724, 0.7724, 35.0, 0.79617, 0.79617, 36.0, 0.82003,
    0.82003, 37.0, 0.84398, 0.84398, 38.0, 0.86803, 0.86803, 39.0, 0.89217,
    0.89217, 40.0, 0.91641, 0.91641, 41.0, 0.94074, 0.94074, 42.0, 0.96518,
    0.96518, 43.0, 0.98972, 0.98972, 44.0, 1.01437, 1.01437, 45.0, 1.03912,
    1.03912, 46.0, 1.06399, 1.06399, 47.0, 1.08897, 1.08897, 48.0, 1.11406,
    1.11406, 49.0, 1.13927, 1.13927, 50.0, 1.1646, 1.1646, 51.0, 1.19005,
    1.19005, 52.0, 1.21563, 1.21563, 53.0, 1.24133, 1.24133, 54.0, 1.26717,
    1.26717, 55.0, 1.29313, 1.29313, 56.0, 1.31923, 1.31923, 57.0, 1.34546,
    1.34546, 58.0, 1.37183, 1.37183, 59.0, 1.39834, 1.39834, 60.0, 1.425, 1.425,
    61.0, 1.4518, 1.4518, 62.0, 1.47874, 1.47874, 63.0, 1.50583, 1.50583, 64.0,
    1.53307, 1.53307, 65.0, 1.56046, 1.56046, 66.0, 1.588, 1.588, 67.0, 1.61568,
    1.61568, 68.0, 1.64352, 1.64352, 69.0, 1.67151, 1.67151, 70.0, 1.69964,
    1.69964, 71.0, 1.72791, 1.72791, 72.0, 1.75633, 1.75633, 73.0, 1.78489,
    1.78489, 74.0, 1.81357, 1.81357, 75.0, 1.84238, 1.84238, 76.0, 1.87131,
    1.87131, 77.0, 1.90035, 1.90035, 78.0, 1.92948, 1.92948, 79.0, 1.9587,
    1.9587, 80.0, 1.98799, 1.98799, 81.0, 2.01733, 2.01733, 82.0, 2.04671,
    2.04671, 83.0, 2.0761, 2.0761, 84.0, 2.10548, 2.10548, 85.0, 2.13482,
    2.13482, 86.0, 2.1641, 2.1641, 87.0, 2.19329, 2.19329, 88.0, 2.22235,
    2.22235, 89.0, 2.25126, 2.25126, 90.0, 2.27982, 2.27982,
  ],
  D4SST: [
    0, 0, 0, 2, 0.02284264, 0.02284264, 4, 0.045685279, 0.045685279, 6,
    0.068527919, 0.068527919, 8, 0.09137055, 0.091370558, 10, 0.114213198,
    0.114213198, 12, 0.137055838, 0.137055838, 14, 0.159898477, 0.159898477, 16,
    0.18274111, 0.182741117, 18, 0.205583756, 0.205583756, 20, 0.228426396,
    0.228426396, 22, 0.251269036, 0.251269036, 24, 0.27411167, 0.274111675, 26,
    0.296954315, 0.296954315, 28, 0.319796954, 0.319796954, 30, 0.342639594,
    0.342639594, 32, 0.36548223, 0.365482234, 34, 0.388324873, 0.388324873, 36,
    0.411167513, 0.411167513, 38, 0.434010152, 0.434010152, 40, 0.45685279,
    0.456852792, 42, 0.479695431, 0.479695431, 44, 0.502538071, 0.502538071, 46,
    0.525380711, 0.525380711, 48, 0.54822335, 0.54822335, 50, 0.57106599,
    0.57106599, 52, 0.593908629, 0.593908629, 54, 0.616751269, 0.616751269, 56,
    0.639593909, 0.639593909, 58, 0.662436548, 0.662436548, 60, 0.685279188,
    0.685279188, 62, 0.707614213, 0.707614213, 64, 0.72994923, 0.729949239, 66,
    0.752284264, 0.752284264, 68, 0.774619289, 0.774619289, 70, 0.795431472,
    0.795431472, 72, 0.81624365, 0.816243655, 74, 0.836548223, 0.836548223, 76,
    0.856852792, 0.856852792, 78, 0.876649746, 0.876649746, 80, 0.8964467,
    0.896446701, 82, 0.91319797, 0.91319797, 84, 0.929949239, 0.929949239, 86,
    0.943147208, 0.943147208, 88, 0.95634517, 0.956345178, 90, 0.965989848,
    0.965989848, 92, 0.975634518, 0.975634518, 94, 0.982741117, 0.982741117, 96,
    0.98984771, 0.989847716, 98, 0.994923858, 0.994923858, 100, 1, 1,
  ],
  D9SSV: [
    0, 0, 0, 2, 17.72270321, 17.72270321, 4, 35.16625763, 35.16625763, 6,
    52.38870491, 52.38870491, 8, 69.4454008, 69.4454008, 10, 86.38901517,
    86.38901517, 12, 103.269532, 103.269532, 14, 120.1342493, 120.1342493, 16,
    137.0277793, 137.0277793, 18, 153.9920483, 153.9920483, 20, 171.0662967,
    171.0662967, 22, 188.2870789, 188.287078, 2, 205.6882637, 205.6882637, 26,
    223.3010336, 223.3010336, 28, 241.1538855, 241.1538855, 30, 259.2726303,
    259.2726303, 32, 277.680393, 277.680393, 34, 296.3976128, 296.3976128, 36,
    315.4420429, 315.4420429, 38, 334.8287507, 334.8287507, 40, 354.5701176,
    354.5701176, 42, 374.6758391, 374.6758391, 44, 395.152925, 395.152925, 46,
    416.005699, 416.00569, 48, 437.2357989, 437.2357989, 50, 458.8421767,
    458.8421767, 52, 480.8210985, 480.8210985, 54, 503.1661446, 503.1661446, 56,
    525.8682092, 525.8682092, 58, 548.9155007, 548.9155007, 60, 572.2935416,
    572.2935416, 62, 595.9851685, 595.9851685, 64, 619.9705321, 619.9705321, 66,
    644.2270974, 644.2270974, 68, 668.7296431, 668.7296431, 70, 693.4502624,
    693.4502624, 72, 718.3583624, 718.3583624, 74, 743.4206643, 743.4206643, 76,
    768.6012034, 768.6012034, 78, 793.8613294, 793.8613294, 80, 819.1597056,
    819.1597056, 82, 844.4523098, 844.4523098, 84, 869.6924338, 869.6924338, 86,
    894.8306834, 894.8306834, 88, 919.8149786, 919.8149786, 90, 944.5905536,
    944.5905536, 92, 969.0999565, 969.0999565,
  ],
  E3VVV: [
    0, 0, 0, 2, 24.0208243, 24.0208243, 4, 49.01020302, 49.01020302, 6,
    74.02702209, 74.02702209, 8, 98.02040604, 98.02040604, 10, 122.0368797,
    122.0368797, 12, 148.0540442, 148.0540442, 14, 173.0462366, 173.0462366, 16,
    198.0908882, 198.0908882, 18, 223.0807029, 223.0807029, 20, 248.07257,
    248.07257, 22, 273.117191, 273.117191, 24, 300.1066477, 300.1066477, 26,
    327.1238298, 327.1238298, 28, 353.1147122, 353.1147122, 30, 380.1591772,
    380.1591772, 32, 408.1764324, 408.1764324, 34, 434.1658669, 434.1658669, 36,
    463.1824694, 463.1824694, 38, 491.1720676, 491.1720676, 40, 520.2163012,
    520.2163012, 42, 549.2048798, 549.2048798, 44, 578.2214109, 578.2214109, 46,
    608.237618, 608.237618, 48, 638.253868, 638.253868, 50, 669.2421087,
    669.2421087, 52, 700.285656, 700.285656, 54, 731.3015794, 731.3015794, 56,
    764.2885581, 764.2885581, 58, 798.3032005, 798.3032005, 60, 832.3178479,
    832.3178479, 62, 866.3324997, 866.3324997, 64, 901.3467701, 901.3467701, 66,
    936.3610415, 936.3610415, 68, 971.3753137, 971.3753137, 70, 1007.3892,
    1007.3892, 72, 1044.4027, 1044.4027, 74, 1078.417359, 1078.417359, 76,
    1115.430858, 1115.430858, 78, 1152.444359, 1152.444359, 80, 1189.45786,
    1189.45786, 82, 1226.471361, 1226.471361, 84, 1263.484863, 1263.484863, 86,
    1300.498366, 1300.498366, 88, 1337.511869, 1337.511869, 90, 1373.525755,
    1373.525755, 92, 1410.539259, 1410.539259,
  ],
  E5VVT: [
    0, 0, 0, 1, 0.008953048, 0.008953048, 3, 0.026788832, 0.026788832, 5,
    0.043968169, 0.043968169, 7, 0.061815327, 0.061815327, 9, 0.078993621,
    0.078993621, 11, 0.097528535, 0.097528535, 13, 0.114706353, 0.114706353, 15,
    0.133257721, 0.133257721, 17, 0.151105848, 0.151105848, 19, 0.169656892,
    0.169656892, 21, 0.187505481, 0.187505481, 23, 0.207429267, 0.207429267, 25,
    0.2252782, 0.225278206, 27, 0.245201819, 0.245201819, 29, 0.263737514,
    0.263737514, 31, 0.283660983, 0.283660983, 33, 0.302883397, 0.302883397, 35,
    0.323493252, 0.323493252, 37, 0.343416798, 0.343416798, 39, 0.364027016,
    0.364027016, 41, 0.384622044, 0.384622044, 43, 0.406605127, 0.406605127, 45,
    0.427901818, 0.427901818, 47, 0.450556412, 0.450556412, 49, 0.473225974,
    0.473225974, 51, 0.496582114, 0.496582114, 53, 0.519236835, 0.519236835, 55,
    0.543279373, 0.543279373, 57, 0.566635461, 0.566635461, 59, 0.592051104,
    0.592051104, 61, 0.616078856, 0.616078856, 63, 0.642180923, 0.642180923, 65,
    0.666910001, 0.666910001, 67, 0.693012122, 0.693012122, 69, 0.718427761,
    0.718427761, 71, 0.744529921, 0.744529921, 73, 0.769244088, 0.769244088, 75,
    0.79603269, 0.79603269, 77, 0.820761792, 0.820761792, 79, 0.848222201,
    0.848222201, 81, 0.872951222, 0.872951222, 83, 0.899053292, 0.899053292, 85,
    0.924468873, 0.924468873, 87, 0.951257487, 0.951257487, 89, 0.976673104,
    0.976673104, 91, 1.0, 1.0,
  ],
  E0: [
    0.0, 0.0, 0.0, 2.0, 0.011112, 0.011112, 4.0, 0.022224, 0.022224, 6.0,
    0.033336, 0.033336, 8.0, 0.044448, 0.044448, 10.0, 0.05556, 0.05556,
    12.000001, 0.066672, 0.066672, 14.000001, 0.077784, 0.077784, 16.0,
    0.088896, 0.088896, 18.0, 0.100008, 0.100008, 20.0, 0.11112, 0.11112, 22.0,
    0.122231, 0.122231, 24.000001, 0.133343, 0.133343, 26.000001, 0.144454,
    0.144454, 28.000001, 0.155566, 0.155566, 30.000001, 0.166677, 0.166677,
    32.000001, 0.177789, 0.177789, 34.000001, 0.1889, 0.1889, 36.000001,
    0.200012, 0.200012, 38.0, 0.211123, 0.211123, 40.0, 0.222234, 0.222234,
    42.0, 0.233345, 0.233345, 44.0, 0.244456, 0.244456, 46.000003, 0.255567,
    0.255567, 48.000003, 0.266678, 0.266678, 50.000002, 0.277789, 0.277789,
    52.000002, 0.2889, 0.2889, 54.000002, 0.300011, 0.300011, 56.000002,
    0.311122, 0.311122, 58.000002, 0.322233, 0.322233, 60.000002, 0.333343,
    0.333343, 62.000002, 0.344454, 0.344454, 64.000002, 0.355565, 0.355565,
    66.000001, 0.366675, 0.366675, 68.000001, 0.377786, 0.377786, 70.000001,
    0.388896, 0.388896, 72.000001, 0.400007, 0.400007, 74.000001, 0.411117,
    0.411117, 76.000001, 0.422228, 0.422228, 78.000001, 0.433338, 0.433338,
    80.000001, 0.444448, 0.444448, 82.0, 0.455559, 0.455559, 84.0, 0.466669,
    0.466669, 86.0, 0.477779, 0.477779, 88.0, 0.48889, 0.48889, 90.0, 0.5, 0.5,
  ],
  E6WWT: [
    0, 0, 0, 2.5, 0.020510923, 0.020510923, 5.001, 0.041142568, 0.041142568,
    7.501, 0.06163402, 0.06163402, 10, 0.082207251, 0.082207251, 12.503,
    0.10291678, 0.10291678, 15.001, 0.12367304, 0.12367304, 17.501, 0.144402041,
    0.144402041, 20.001, 0.165150512, 0.165150512, 22.501, 0.186054753,
    0.186054753, 25.001, 0.207110869, 0.207110869, 27.501, 0.228264341,
    0.228264341, 30.001, 0.249558005, 0.249558005, 32.5, 0.27094513, 0.27094513,
    35, 0.292562016, 0.292562016, 37.501, 0.314373613, 0.314373613, 40.001,
    0.336430546, 0.336430546, 42.502, 0.358580942, 0.358580942, 45, 0.380922154,
    0.380922154, 47.501, 0.403695627, 0.403695627, 50.001, 0.426757272,
    0.426757272, 52.501, 0.450075938, 0.450075938, 55.001, 0.473690564,
    0.473690564, 57.502, 0.497745239, 0.497745239, 60, 0.522259434, 0.522259434,
    62.501, 0.54727209, 0.54727209, 65.001, 0.572790997, 0.572790997, 67.501,
    0.598874567, 0.598874567, 70.001, 0.625655205, 0.625655205, 72.5,
    0.653121227, 0.653121227, 75.001, 0.681307683, 0.681307683, 77.5,
    0.71050664, 0.71050664, 80, 0.740589587, 0.740589587, 82.501, 0.771716188,
    0.771716188, 85.001, 0.80421356, 0.80421356, 87.501, 0.838167374,
    0.838167374, 90, 0.873620468, 0.873620468, 91.002, 0.888406869, 0.888406869,
    92, 0.903282838, 0.903282838, 93.001, 0.91811597, 0.91811597, 94.001,
    0.933233381, 0.93323381, 95.002, 0.948662331, 0.948662331, 96.001,
    0.964250944, 0.964250944, 97.001, 0.979383932, 0.979383932, 98.002,
    0.993469372, 0.993469372, 98.501, 1, 1,
  ],
  E7ZZV: [
    0, 0, 0, 1.001, 30.96, 30.96, 2, 61.53, 61.53, 3, 92.35, 92.35, 4.005,
    123.32, 123.32, 5.001, 154.14, 154.14, 6, 185.04, 185.04, 7, 216.04, 216.04,
    8.001, 247.4, 247.4, 9.001, 279.1, 279.1, 10, 311.06, 311.06, 11.001,
    343.67, 343.67, 12.001, 376.32, 376.32, 13.002, 409.1, 409.1, 14, 442.17,
    442.17, 15.006, 476, 476, 16.001, 509.9, 509.9, 17, 544.67, 544.67, 18.001,
    579.86, 579.86, 19.001, 615.69, 615.69, 20.001, 651.96, 651.96, 21.001,
    688.93, 688.93, 22.001, 726.27, 726.27, 23.001, 764.37, 764.37, 24, 803.27,
    803.27, 25, 843.05, 843.05, 26.001, 883.39, 883.39, 27.001, 924.53, 924.53,
    28.001, 966.23, 966.23, 29.001, 1008.62, 1008.62, 30.001, 1052.09, 1052.09,
    31, 1096.7, 1096.7, 32.001, 1142.5, 1142.5, 33.001, 1188.89, 1188.89,
    34.003, 1236.05, 1236.05, 35, 1284, 1284, 36.001, 1332.95, 1332.95, 37,
    1382.54, 1382.54, 38.001, 1432.71, 1432.71, 39.001, 1483.28, 1483.28,
    40.001, 1532.48, 1532.48, 41.001, 1581.99, 1581.99, 42, 1633.15, 1633.15,
    43, 1683.77, 1683.77, 44.001, 1733.99, 1733.99, 45, 1782.93, 1782.93, 46,
    1831.69, 1831.69, 47.001, 1879.2, 1879.2, 48.001, 1926.68, 1926.68, 49,
    1972.25, 1972.25, 49.101, 1976.86, 1976.86, 49.201, 1981.39, 1981.39,
    49.301, 1985.91, 1985.91, 49.401, 1990.39, 1990.39, 49.503, 1994.96,
    1994.96, 49.601, 1999.28, 1999.28, 49.701, 2003.6, 2003.6, 49.8, 2007.83,
    2007.83, 49.901, 2012.14, 2012.14, 50.001, 2016.54, 2016.54, 50.102,
    2020.97, 2020.97, 50.201, 2025.21, 2025.21, 50.3, 2029.49, 2029.49, 50.4,
    2033.81, 2033.81, 50.5, 2038.01, 2038.01, 50.601, 2042.2, 2042.2, 50.701,
    2046.34, 2046.34, 50.798, 2050.39, 2050.39, 50.901, 2054.71, 2054.71, 51,
    2058.87, 2058.87, 51.101, 2063.05, 2063.05, 51.201, 2067.09, 2067.09,
    51.301, 2071.19, 2071.19, 51.4, 2075.2, 2075.2, 51.501, 2079.26, 2079.26,
  ],
  E8RRT: [
    0, 0, 0, 1, 0.02416, 0.02416, 2, 0.04832, 0.04832, 3, 0.07248, 0.07248, 4,
    0.09665, 0.09665, 5, 0.12083, 0.12083, 6, 0.14502, 0.14502, 7, 0.16922,
    0.16922, 8, 0.19343, 0.19343, 9, 0.21766, 0.21766, 10, 0.24189, 0.24189, 11,
    0.26615, 0.26615, 12, 0.29041, 0.29041, 13, 0.31469, 0.31469, 14, 0.33898,
    0.33898, 15, 0.36328, 0.36328, 16, 0.38759, 0.38759, 17, 0.4119, 0.4119, 18,
    0.43622, 0.43622, 19, 0.46054, 0.46054, 20, 0.48485, 0.48485, 21, 0.50916,
    0.50916, 22, 0.53346, 0.53346, 23, 0.55774, 0.55774, 24, 0.582, 0.582, 25,
    0.60624, 0.60624, 26, 0.63045, 0.63045, 27, 0.65463, 0.65463, 28, 0.67878,
    0.67878, 29, 0.70288, 0.70288, 30, 0.72693, 0.72693, 31, 0.75094, 0.75094,
    32, 0.77488, 0.77488, 33, 0.79877, 0.79877, 34, 0.82259, 0.82259, 35,
    0.84633, 0.84633, 36, 0.87, 0.87, 37, 0.89359, 0.89359, 38, 0.91708,
    0.91708, 39, 0.94048, 0.94048, 40, 0.96378, 0.96378, 41, 0.98697, 0.98697,
    42, 1.01005, 1.01005, 43, 1.033, 1.033, 44, 1.05582, 1.05582, 45, 1.0785,
    1.0785, 46, 1.10103, 1.10103, 47, 1.12341, 1.12341, 48, 1.14563, 1.14563,
    49, 1.16767, 1.16767, 50, 1.18954, 1.18954, 51, 1.21121, 1.21121, 52,
    1.23269, 1.23269, 53, 1.25397, 1.25397, 54, 1.27503, 1.27503, 55, 1.29587,
    1.29587, 56, 1.31649, 1.31649, 57, 1.33687, 1.33687, 58, 1.35701, 1.35701,
    59, 1.37691, 1.37691, 60, 1.39655, 1.39655, 61, 1.41594, 1.41594, 62,
    1.43507, 1.43507, 63, 1.45392, 1.45392, 64, 1.47251, 1.47251, 65, 1.49082,
    1.49082, 66, 1.50884, 1.50884, 67, 1.52658, 1.52658, 68, 1.54403, 1.54403,
    69, 1.56119, 1.56119, 70, 1.57804, 1.57804, 71, 1.59459, 1.59459, 72,
    1.61083, 1.61083, 73, 1.62676, 1.62676, 74, 1.64237, 1.64237, 75, 1.65765,
    1.65765, 76, 1.6726, 1.6726, 77, 1.68721, 1.68721, 78, 1.70149, 1.70149, 79,
    1.71542, 1.71542, 80, 1.729, 1.729, 81, 1.74224, 1.74224, 82, 1.75511,
    1.75511, 83, 1.76763, 1.76763, 84, 1.77979, 1.77979, 85, 1.79159, 1.79159,
    86, 1.80302, 1.80302, 87, 1.8141, 1.8141, 88, 1.82482, 1.82482, 89, 1.83517,
    1.83517, 90, 1.84518, 1.84518, 91, 1.85483, 1.85483, 92, 1.86413, 1.86413,
    93, 1.87309, 1.87309, 94, 1.88171, 1.88171, 95, 1.88999, 1.88999,
  ],
  F5RRT: [
    0.0, 0.0, 0.0, 1.077778, 0.012226, 0.012226, 2.155556, 0.02445, 0.02445,
    3.233334, 0.036671, 0.036671, 4.311111, 0.048887, 0.048887, 5.388889,
    0.061099, 0.061099, 6.466667, 0.073306, 0.073306, 7.544445, 0.085506,
    0.085506, 8.622223, 0.0977, 0.0977, 9.7, 0.109886, 0.109886, 10.777778,
    0.122066, 0.122066, 11.855556, 0.134236, 0.134236, 12.933334, 0.146399,
    0.146399, 14.011111, 0.158552, 0.158552, 15.088889, 0.170697, 0.170697,
    16.166668, 0.182831, 0.182831, 17.244446, 0.194956, 0.194956, 18.322222,
    0.20707, 0.20707, 19.4, 0.219173, 0.219173, 20.477777, 0.231265, 0.231265,
    21.555555, 0.243346, 0.243346, 22.633333, 0.255414, 0.255414, 23.711111,
    0.267469, 0.267469, 24.788891, 0.279513, 0.279513, 25.866669, 0.291543,
    0.291543, 26.944447, 0.30356, 0.30356, 28.022223, 0.315564, 0.315564, 29.1,
    0.327554, 0.327554, 30.177778, 0.33953, 0.33953, 31.255556, 0.351492,
    0.351492, 32.333336, 0.363437, 0.363437, 33.411114, 0.375367, 0.375367,
    34.488892, 0.387283, 0.387283, 35.566666, 0.399184, 0.399184, 36.644444,
    0.411069, 0.411069, 37.722221, 0.422935, 0.422935, 38.799999, 0.434785,
    0.434785, 39.877777, 0.44662, 0.44662, 40.955555, 0.458439, 0.458439,
    42.033333, 0.470235, 0.470235, 43.111111, 0.482016, 0.482016, 44.188889,
    0.493781, 0.493781, 45.266666, 0.505527, 0.505527, 46.344444, 0.51725,
    0.51725, 47.422222, 0.528958, 0.528958, 48.5, 0.54065, 0.54065, 49.577782,
    0.552312, 0.552312, 50.655556, 0.563953, 0.563953, 51.733337, 0.575573,
    0.575573, 52.811111, 0.587157, 0.587157, 53.888893, 0.598706, 0.598706,
    54.966667, 0.610224, 0.610224, 56.044445, 0.621709, 0.621709, 57.122223,
    0.63314, 0.63314, 58.200001, 0.644529, 0.644529, 59.277779, 0.655874,
    0.655874, 60.355556, 0.667173, 0.667173, 61.433334, 0.678411, 0.678411,
    62.511112, 0.689589, 0.689589, 63.588886, 0.70071, 0.70071, 64.666672,
    0.711772, 0.711772, 65.744446, 0.722771, 0.722771, 66.822227, 0.733702,
    0.733702, 67.900002, 0.74455, 0.74455, 68.977783, 0.755325, 0.755325,
    70.055557, 0.766023, 0.766023, 71.133331, 0.776642, 0.776642, 72.211113,
    0.787178, 0.787178, 73.288887, 0.797626, 0.797626, 74.366669, 0.807985,
    0.807985, 75.444443, 0.81825, 0.81825, 76.522217, 0.828419, 0.828419,
    77.599998, 0.838488, 0.838488, 78.677773, 0.848454, 0.848454, 79.755554,
    0.858316, 0.858316, 80.833328, 0.86807, 0.86807, 81.91111, 0.877715,
    0.877715, 82.988884, 0.887249, 0.887249, 84.066666, 0.896665, 0.896665,
    85.14444, 0.905954, 0.905954, 86.222221, 0.915127, 0.915127, 87.299995,
    0.924184, 0.924184, 88.377777, 0.933104, 0.933104, 89.455551, 0.941905,
    0.941905, 90.533333, 0.950574, 0.950574, 91.611107, 0.959122, 0.959122,
    92.688889, 0.967535, 0.967535, 93.766663, 0.975824, 0.975824, 94.844444,
    0.983993, 0.983993, 95.922218, 0.992046, 0.992046, 97.0, 1.0, 1.0,
  ],
};
