import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import Timeline from 'react-visjs-timeline';
import { Utils, constants } from '../../../helpers';
import {
  play,
  pause,
  calender,
  fullscreen,
  minimize,
  camera,
} from '../../../assets/images';
import {
  disconnectWithMQTT,
  multiStreamSnapDownload,
  mqttPublish,
  mqttSubscribe,
  mqttUnsubscribe,
} from '../../../utils/connection/mqttConnection';
import {
  getEventsScubberData,
  getTimelineData,
  updatedTimelineData,
} from '../../../helpers/timelineData';
import {
  setIsOnMultiCameraViewPage,
  setMaxPeerCountForDevice,
  setMultiLiveStreamLoader,
  setOfferrecived,
  setPlaybackActiveTime,
  setPlaybackBufferMeta,
} from '../../../store/reducers/StreamingReducer';
import LiveGridStructure from './LiveGridStructure';
import PlaybackGridStructure from './PlaybackGridStructure';
import ImageGridStructure from './ImageGridStructure';
import ResponsiveReactGridLayout from './shared/ResponsiveReactGridLayout';
import { SiteModal } from '../../../components/common';
import DateTimePicker from '../../../components/common/DateTimePicker';
import { findTimeZoneFromSelectedValue } from '../../../helpers/commonUtils';
import { setSnapshotImage } from '../../../store/StreamingStoreIDB';
import { devicesMQTTStore } from '../../../store/DevicesMQTTStore';
import { setKeepAliveRequest } from '../../../store/reducers/AccountReducer';
import { resetEvents } from '../../../store/reducers/EventsReducer';
import { useDeviceSnapshots } from '../../../store/DeviceSnapshotsStore';
import {
  cleanVideoElements,
  getNextAvailTime,
  getNoVideoDevices,
  getPeerConnectionState,
  getSendComplete,
  sendContinueCommand,
  sendPlayCommand,
  sendStopCommand,
  startPlaybackProcess,
  startShowNoVideo,
  stopPlaybackConnection,
} from '../../../utils/webRTC/multi-edge-playback-handler';
import {
  cleanupResources,
  disconnectWithWebSocket,
  getCloudNextAvailTime,
  getNoVideoCloudDevices,
  getCloudSendComplete,
  sendCloudContinueRequest,
  sendCloudDisConnectRequest,
  sendCloudPlayRequest,
  sendCloudStopRequest,
} from '../../../utils/webRTC/multi-cloud-playback-handler';
import { debounce } from 'lodash';

const eventClasses = [
  {
    key: 'face',
    eventClasses: ['Face'],
  },
  {
    key: 'person',
    eventClasses: ['Person'],
  },
  {
    key: 'vehicle',
    eventClasses: ['vehicle'],
  },
  {
    key: 'licensePlate',
    eventClasses: ['LicensePlate'],
  },
  {
    key: 'sound',
    eventClasses: ['scream', 'explosion', 'gun-shot', 'glass-breaking'],
  },
];

class DeviceWallTimeline extends React.Component {
  constructor(props) {
    super(props);
    const currentTime = new Date();
    const deviceStart = Utils.getDate(props?.startDate / 1000);
    this.state = {
      minsOptions: {
        width: '100%',
        height: '50px',
        zoomKey: 'ctrlKey',
        stack: false,
        stackSubgroups: false,
        showMajorLabels: true,
        showMinorLabels: false,
        showCurrentTime: true,
        orientation: 'top',
        timeAxis: { scale: 'second', step: 6 },
        format: {
          majorLabels: {
            second: 'hh:mm',
          },
        },
        zoomable: false,
        horizontalScroll: true,
        start: moment().subtract({ minutes: 4, seconds: 20 }).toDate(),
        end: moment(new Date(currentTime))
          .add({ minutes: 4, seconds: 20 })
          .toDate(),
        min: deviceStart,
        max: moment(new Date(currentTime))
          .add({ minutes: 4, seconds: 20 })
          .toDate(),
        selectable: false,
      },
      daysOptions: {
        width: '100%',
        height: '50px',
        zoomKey: 'ctrlKey',
        stack: true,
        stackSubgroups: false,
        showMajorLabels: true,
        showMinorLabels: false,
        showCurrentTime: true,
        orientation: 'top',
        timeAxis: { scale: 'hour', step: 2 },
        format: {
          majorLabels: {
            hour: 'D MMM',
          },
        },
        zoomable: false,
        horizontalScroll: true,
        start: moment(new Date(currentTime))
          .subtract({ days: 1, hours: 6 })
          .toDate(),
        end: moment(new Date(currentTime)).add({ days: 1, hours: 6 }).toDate(),
        min: deviceStart,
        max: moment(new Date(currentTime)).add({ days: 1, hours: 6 }).toDate(),
        selectable: false,
      },
      secsOptions: {
        width: '100%',
        height: '50px',
        zoomKey: 'ctrlKey',
        stack: true,
        stackSubgroups: false,
        showMajorLabels: true,
        showMinorLabels: false,
        showCurrentTime: true,
        orientation: 'top',
        timeAxis: { scale: 'second', step: 1 },
        format: {
          majorLabels: {
            second: 'mm:ss',
          },
          minorLabels: {
            second: 's',
          },
        },
        zoomable: false,
        horizontalScroll: true,
        start: moment(new Date(currentTime)).subtract({ seconds: 40 }).toDate(),
        end: moment(new Date(currentTime)).add({ seconds: 40 }).toDate(),
        min: deviceStart,
        max: moment(new Date(currentTime)).add({ seconds: 40 }).toDate(),
        selectable: false,
      },
      liveStreamMode: true,
      activeTime: new Date(currentTime),
      timeZone: 'America/New_York',
      timezoneValue: moment.tz.guess(),
      offset: '-5',
      manualClicked: false,
      minsView: true,
      secsView: false,
      daysView: false,
      selectedOption: 'Mins',
      clickAction: false,
      cvrMode: false,
      CVRSnapMode: false,
      metaDataHere: [],
      minsMetaData: [],
      daysMetaData: [],
      secsMetaData: [],
      fullscreen: false,
      fullscreenBackup: false,
      muteAudio: false,
      viewId: null,
      startDate: null,
      eventsDataCheck: [],
      liveSnapshot: false,
      moveTimelineImage: false,
      internalEventFromSearch: false,
      eventCVRMode: null,
      fakeTime: true,
      customDeviceMetaData: null,
      customEventsDotsData: null,
      holdEventsDotsData: [],
      showDateTimeModal: false,
      selectedDate: new Date(),
      selectedTime: new Date().setHours(0, 0, 0),
      rawMetaData: [],
      filterDate: '',
      metadataByDeviceId: [],
      noContentDeviceIds: [],
      metaDataControllersRef: [],
      selectedView: this.props?.selectedView,
      subscribeForEvents: [],
      MQTTConnectionStatus: false,
      selectedEventTime: '',
      oneIceSend: false,
      keepAlive: false,
      onlineDeviceCount: 0,
      playbackBufferData: {},
    };
    this.isWindowHidden = false;
    this.isAbleToCallAPI = false;
    this.isAPIcalled = false;
    this.pauseRequestSent = false;
    this.isRangeChanging = false;
    this.minOptionClicked = false;
    this.keepAliveTimer = null;
    this.snapshotURL = null;
    this.snapList = null;
    this.isTimerRunning = React.createRef(false);
    this.selectedViewTimerDeviceIds = React.createRef([]);
    this.timeline = React.createRef();
    this.noVideoCheckTimestamp = 0;
    // this.playbackSyncDeviceIds = {};
    // this.playRequestSentTimeDeviceIds = {};
  }

  componentDidMount = () => {
    Utils.vmsLogger().log(
      'Multi Livestream view entered',
      Utils.getTimesinmili(),
    );
    this.updateTimeZone();
    this.moveTimeline();
    this.dynamicTimeLineInterval = setInterval(() => {
      this.setDynamicTimelineMove();
    }, 1000);
    this.setState({
      CVRSnapMode: false,
    });
    this.props?.setIsOnMultiCameraViewPage(true);
  };

  componentDidUpdate = (props) => {
    if (document.hidden && this.state.liveStreamMode && !this.isWindowHidden) {
      this.isWindowHidden = true;
    } else if (
      !document.hidden &&
      this.state.liveStreamMode &&
      this.isWindowHidden
    ) {
      clearInterval(this.MultiCVRAutoPlay);
      this.isWindowHidden = false;
      this.moveTimeline();
    }

    // online OR offline devices check
    const onlineDevices =
      this.props?.allDevices?.filter(
        (device) =>
          device?.connectionStatus?.toLowerCase() ===
            constants.DEVICES_RETURN_ONLINE_STATUS.toLowerCase() &&
          this.props?.allDeviceIds?.includes(device.deviceId),
      ) ?? [];

    if (onlineDevices?.length !== this.state.onlineDeviceCount) {
      this.setState({
        onlineDeviceCount: onlineDevices?.length,
      });
      if (
        (onlineDevices.length === 0 || !onlineDevices) &&
        this.state.liveStreamMode
      ) {
        this.onPauseCVR(); //if there is no any online device it will stop the timeline only if it is in live stream mode
      }
    }

    if (this.state.viewId !== props.viewId) {
      this.timeline?.current?.$el?.setItems([]);
      this.setState({
        minsMetaData: [],
        daysMetaData: [],
        secsMetaData: [],
      });
      this.isAPIcalled = false;
      if (this.state.metaDataControllersRef.length > 0) {
        this.state.metaDataControllersRef.forEach((controller) =>
          controller.abort(),
        );
      }
      this.setState(
        {
          viewId: props?.viewId,
          metaDataControllersRef: [],
          oneIceSend: false,
        },
        () => {
          this.snapshotURL = null;
          this.handleRemoveSnapshotImage();
          this.updateTimeZone();

          // when user first time enter the view and all devices are offline then timeline will in pause mode.
          if (onlineDevices?.length === 0 || !onlineDevices) {
            this.onPauseCVR();
          } else {
            if (
              this.state.internalEventFromSearch ===
              props.internalEventFromSearch
            ) {
              this.onGoLive();
            }
          }
        },
      );
    }

    if (this.state.startDate !== props.startDate && props?.startDate) {
      this.setState({
        startDate: props?.startDate,
      });
    }

    if (this.state.timezoneValue !== props.timezone && props?.timezone) {
      this.setState({
        timezoneValue: props?.timezone,
      });
      this.updateTimeZone();
    }

    if (this.state.eventCategory !== props.category) {
      this.setState({
        eventCategory: props?.category,
      });
      this.updateEventsPlotting();
    }

    if (this.state.customEventsDotsData !== props.eventDotsData) {
      if (props.eventDotsData !== null && Array.isArray(props.eventDotsData)) {
        this.setState({
          customEventsDotsData: props.eventDotsData,
        });

        const output = props.eventDotsData?.map((item) => ({
          eventMeta: item.eventMeta,
          deviceId: item.src?.srcId,
          eventTimestamp: Number(item.t),
        }));

        const allData = [];

        output?.forEach((insideEvent, i) => {
          insideEvent?.eventMeta?.events?.forEach((actualEvent, ij) => {
            actualEvent?.event?.forEach((newEvent, index) => {
              if (this.props?.allDeviceIds.includes(insideEvent.deviceId)) {
                const eData = {
                  id: `${i}-${ij}-${index}`,
                  class: newEvent.eventClass.toLowerCase(),
                  timestamp: insideEvent?.eventTimestamp,
                };
                allData.push(eData);
              }
            });
          });
        });

        const eventData = allData.map(function (i) {
          return i.class;
        });
        const obj = {
          start: props?.eventDotsData[0]?.t,
          data: eventData,
          type: 'point',
        };

        this.setState({
          holdEventsDotsData: [...this.state.holdEventsDotsData, obj],
        });
      }
    }

    if (
      props.customMetaData &&
      this.state.customDeviceMetaData !== props.customMetaData &&
      !this.isRangeChanging
    ) {
      if (props.customMetaData !== null) {
        const newObj = {
          start: props.customMetaData.start * 1000,
          end: props.customMetaData.end * 1000,
          deviceId: props.customMetaData?.deviceId,
        };
        this.setState({
          customDeviceMetaData: props.customMetaData,
        });

        if (props.customMetaData?.end > this.state.customDeviceMetaData?.end) {
          this.plotUpdatedSlot(newObj);
        }
      }
    }

    if (
      this.props?.streaming?.mqttConnection !== this.state.MQTTConnectionStatus
    ) {
      this.setState({
        MQTTConnectionStatus: this.props?.streaming?.mqttConnection,
      });
      if (
        this.props?.streaming?.mqttConnection &&
        !this.props?.account?.keepAliveRequest &&
        this.props?.views?.selectedView?.devices?.length
      ) {
        this.subscribeRTEventTopic();
      } else if (!this.props?.streaming?.mqttConnection) {
        this.props?.setKeepAliveRequest(false);
      }
    }
    if (
      this.props?.views?.selectedView?.viewId !==
      this.state.selectedView?.viewId
    ) {
      const oldDevices = this.state.selectedView?.devices?.length
        ? JSON.parse(JSON.stringify(this.state.selectedView?.devices))
        : [];
      if (this.state.selectedView?.devices?.length) {
        this.unscubscribeRTEventsTopic(oldDevices);
        this.cleanTimerForLiveEvents(oldDevices, true);
      }
      this.setState({
        selectedView: this.props?.views?.selectedView,
      });
      if (
        this.props?.streaming?.mqttConnection &&
        !this.props?.account?.keepAliveRequest &&
        this.props?.views?.selectedView?.devices?.length
      ) {
        this.subscribeRTEventTopic(oldDevices);
      }
    }

    this.props?.allDeviceIds?.forEach((deviceId) => {
      if (
        props.streaming?.playbackBufferMeta &&
        props.streaming?.playbackBufferMeta?.[deviceId]
      ) {
        this.handleBufferData(deviceId);
      }
    });
  };

  subscribeRTEventTopic = (oldDevices) => {
    let subscribeForEvents = {};
    const eventTopics = [];
    this.props?.views?.selectedView?.devices?.forEach((device) => {
      const subscribeForMetaData = {
        topic: `b/streams/${device}`,
        qos: 0,
      };
      // Subscribe to the app topic
      mqttSubscribe(subscribeForMetaData);

      const deviceDetail = this.props?.allDevices?.find(
        (d) => d.deviceId === device,
      );
      if (deviceDetail) {
        eventTopics.push(`d/rt-events/${deviceDetail?.gatewayId}`);
      }
    });

    if (eventTopics.length) {
      subscribeForEvents = {
        topic: eventTopics,
        qos: 0,
      };
      this.setState({
        subscribeForEvents: subscribeForEvents,
      });
      // Subscribe  to the device topic
      mqttSubscribe(subscribeForEvents);
    }
    this.props?.views?.selectedView?.devices?.forEach((deviceId) => {
      let device = this.props?.allDevices?.find(
        (device) => device.deviceId === deviceId,
      );
      if (device && !oldDevices?.includes(device.deviceId)) {
        device = JSON.parse(JSON.stringify(device));
        device.viewDeviceIds = JSON.stringify(
          this.props?.views?.selectedView?.devices,
        );
        this.subscribeRequest(device);
      }
    });
    this.isTimerRunning.current = true;
    this.selectedViewTimerDeviceIds.current =
      this.props?.views?.selectedView?.devices;
  };

  subscribeRequest = (device) => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const mqttState = devicesMQTTStore.getState();
    // const sessionId = state.getSessionId();
    if (!this.props?.account?.accountId) {
      return;
    }
    this.props?.setKeepAliveRequest(true);

    // Send the request
    const context = {
      topic: `a/rt-events/${device.gatewayId}`,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${device.gatewayId}`,
        from: `${this.props?.account?.accountId}`,
        msg: {
          resource: `ch/${device.deviceId}/camera/events/live-events`,
          action: 'add',
          properties: {
            sessionId: mqttState.sessionId,
            events: [
              {
                detection: [
                  'motion',
                  'person',
                  'vehicle',
                  'lp',
                  'face',
                  'audio',
                  'SoundRecognition',
                ],
                notifications: [
                  'queue-threshold',
                  'tamper',
                  'defocus',
                  'shock',
                ],
                stats: ['queue-count', 'person-count', 'vehicle-count'],
              },
            ],
          },
        },
        publish: `d/notify/${this.props?.account?.accountId}/${mqttState.sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
    this.keepAliveSetTimer(device);
  };

  keepAliveSetTimer = (device) => {
    //Retrieve keep alive session every 90 seconds
    this.keepAliveTimer = setTimeout(() => {
      if (
        this.isTimerRunning.current &&
        this.selectedViewTimerDeviceIds?.current?.includes(device.deviceId) &&
        device.viewDeviceIds ===
          JSON.stringify(this.selectedViewTimerDeviceIds?.current)
      ) {
        this.keepAliveRequest(device);
      }
    }, 90000);
  };

  keepAliveRequest = (device) => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const mqttState = devicesMQTTStore.getState();

    // const sessionId = state.getSessionId();

    if (!this.props?.account?.accountId) {
      return;
    }

    // Send the request
    const context = {
      topic: `a/rt-events/${device.gatewayId}`,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${device.gatewayId}`,
        from: `${this.props?.account?.accountId}`,
        msg: {
          action: 'set',
          resource: `ch/${device.deviceId}/camera/events/live-events`,
          properties: {
            sessionId: mqttState.sessionId,
          },
        },
        publish: `d/notify/${this.props?.account?.accountId}/${mqttState.sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
    if (
      this.isTimerRunning.current &&
      this.selectedViewTimerDeviceIds?.current?.includes(device.deviceId) &&
      device.viewDeviceIds ===
        JSON.stringify(this.selectedViewTimerDeviceIds?.current)
    ) {
      this.keepAliveSetTimer(device);
    }
  };

  deleteRequest = (device) => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const mqttState = devicesMQTTStore.getState();
    // const sessionId = state.getSessionId();
    if (!this.props?.account?.accountId) {
      return;
    }

    // Send the request
    const context = {
      topic: `a/rt-events/${device.gatewayId}`,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${device.gatewayId}`,
        from: `${this.props?.account?.accountId}`,
        msg: {
          resource: `ch/${device.deviceId}/camera/events/live-events`,
          action: 'delete',
          properties: {
            sessionId: mqttState.sessionId,
          },
        },
        publish: `d/notify/${this.props?.account?.accountId}/${mqttState.sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
  };

  cleanTimerForLiveEvents = (deviceList, isViewChange) => {
    this.isTimerRunning.current = null;
    this.selectedViewTimerDeviceIds.current = [];
    deviceList?.forEach((deviceId) => {
      const device = this.props?.allDevices?.find(
        (d) => d.deviceId === deviceId,
      );
      if (
        device &&
        (!this.props?.views?.selectedView?.devices?.includes(device.deviceId) ||
          !isViewChange)
      ) {
        this.deleteRequest(device);
      }
    });
    clearTimeout(this.keepAliveTimer);
    this.props?.setKeepAliveRequest(false);
  };

  unscubscribeRTEventsTopic = (deviceList) => {
    mqttUnsubscribe(this.state.subscribeForEvents);
    deviceList?.forEach((device) => {
      const subscribeForMetaData = {
        topic: `b/streams/${device}`,
        qos: 0,
      };
      // Unsubscribe to the app topic
      mqttUnsubscribe(subscribeForMetaData);
    });
    this.props?.resetEvents();
  };

  handleRemoveSnapshotImage = async () => {
    try {
      // Set the snapshot image to null
      await setSnapshotImage({});
      // Handle any state updates or UI changes here after successful update
    } catch (error) {}
  };

  // moveFunc = (event, deviceId) => {
  //   try {
  //     if (event.target.currentTime) {
  //       const videoCurrentTime = parseInt(event.target.currentTime, 10);
  //       if (this.channelTimeStamp !== videoCurrentTime) {
  //         if (videoCurrentTime > 0) {
  //           this.timeline?.current?.$el?.moveTo(
  //             moment(Utils.getDate(videoCurrentTime)),
  //           );
  //           this.channelTimeStamp = videoCurrentTime;
  //         }

  //         const { currentTime } = event.target;
  //         // let endTime = null;
  //         // for (let i = 0; i < event.target.buffered.length; i++) {
  //         //   const start = event.target.buffered.start(i);
  //         //   const end = event.target.buffered.end(i);
  //         //   if (currentTime >= start && currentTime <= end) {
  //         //     endTime = end;
  //         //     break;
  //         //   }
  //         // }
  //         // if (endTime !== null && currentTime + 15 >= endTime) {
  //         //   if (this.lastEndTime === endTime) return;

  //         //   if (this.props?.isEdgeRecording) {
  //         //     // for FW playback
  //         //     sendContinueCommand('CONTINUE');
  //         //   } else {
  //         //     // for CLOUD playback
  //         //     sendCloudContinueRequest();
  //         //   }
  //         //   this.lastEndTime = endTime;
  //         // }

  //         const activeCurrentTime =
  //           Utils.getUnixDate(this.state.activeTime) * 1000;
  //         const diff =
  //           this.state.playbackBufferData?.[
  //             this.state.playbackBufferData?.length - 1
  //           ]?.end - activeCurrentTime;
  //         // const isSendComplete = getSendComplete();
  //         if (diff === 10000) {
  //           // sendContinueCommand('CONTINUE');
  //           // if (this.props?.isEdgeRecording && isSendComplete) {
  //           if (this.props?.isEdgeRecording) {
  //             // for FW playback
  //             sendContinueCommand('CONTINUE', deviceId);
  //           } else {
  //             // for CLOUD playback
  //             // sendCloudContinueRequest();
  //           }
  //         } else {
  //           if (
  //             activeCurrentTime ===
  //             this.state.playbackBufferData?.[
  //               this.state.playbackBufferData?.length - 1
  //             ]?.end
  //             //   &&
  //             // isSendComplete
  //           ) {
  //             if (this.props?.isEdgeRecording) {
  //               // for FW playback
  //               sendContinueCommand('CONTINUE', deviceId);
  //             }
  //           }
  //         }
  //       }
  //     }
  //   } catch (err) {
  //     Utils.vmsLogger().log('error in timeuupdate event', err);
  //   }
  // };

  getAgesWithGreaterDifference = (arr, endTime) => {
    // Sort the array based on age in ascending order
    arr.sort((a, b) => a.start - b.start);

    // Initialize the result array with the first object
    const result = [arr[0]];

    // Iterate over the remaining objects
    for (let i = 1; i < arr.length; i++) {
      const currentAge = arr[i].start;
      const previousAge = result[result.length - 1].start;

      // Check if the difference is greater than or equal to 3
      if (currentAge - previousAge >= 3000 && currentAge < endTime) {
        result.push(arr[i]);
      }
    }

    return result;
  };

  plotUpdatedSlot = (newObj) => {
    if (newObj?.end && this.state.holdEventsDotsData.length) {
      const newArray = this.getAgesWithGreaterDifference(
        this.state.holdEventsDotsData,
        newObj?.end,
      );
      const minsAdjustment = { minutes: 0 };
      const minsData = updatedTimelineData([newObj], minsAdjustment);
      if (this.state.metadataByDeviceId.length > 0) {
        const findObj = this.state.metadataByDeviceId.find(
          (devMeta) => devMeta?.deviceId === minsData?.[0]?.deviceId,
        );
        const findIndex = this.state.metadataByDeviceId.findIndex(
          (devMeta) => devMeta?.deviceId === minsData?.[0]?.deviceId,
        );
        const newObj = {
          end: Utils.getUnixDate(minsData?.[0]?.end) * 1000,
          id: minsData?.[0]?.id,
          start: Utils.getUnixDate(minsData?.[0]?.start) * 1000,
        };
        findObj?.metadata.push(newObj);
        const updatedArray = [...this.state.metadataByDeviceId];
        if (findIndex > -1) {
          updatedArray.splice(findIndex, 1, findObj);
        }
        this.setState({
          metadataByDeviceId: updatedArray,
        });
      }
      const mergedItem = [...this.state.minsMetaData, ...minsData];
      this.setState({
        minsMetaData: mergedItem,
        rawMetaData: [...this.state.rawMetaData, ...minsData],
      });
      this.setState({
        holdEventsDotsData: [],
      });

      const massagedData = getEventsScubberData(newArray, minsAdjustment);
      const newData = [...this.state.eventsDataCheck, ...massagedData];
      this.setState({
        eventsDataCheck: newData,
      });
      setTimeout(() => {
        this.updateEventsPlotting(newData);
      }, 500);
    } else if (newObj?.end) {
      const minsAdjustment = { minutes: 0 };
      const minsData = updatedTimelineData([newObj], minsAdjustment);
      if (this.state.metadataByDeviceId.length > 0) {
        const findObj = this.state.metadataByDeviceId.find(
          (devMeta) => devMeta?.deviceId === minsData?.[0]?.deviceId,
        );
        const findIndex = this.state.metadataByDeviceId.findIndex(
          (devMeta) => devMeta?.deviceId === minsData?.[0]?.deviceId,
        );
        const newObj = {
          end: Utils.getUnixDate(minsData?.[0]?.end) * 1000,
          id: minsData?.[0]?.id,
          start: Utils.getUnixDate(minsData?.[0]?.start) * 1000,
        };
        findObj?.metadata.push(newObj);
        const updatedArray = [...this.state.metadataByDeviceId];
        if (findIndex > -1) {
          updatedArray.splice(findIndex, 1, findObj);
        }
        this.setState({
          metadataByDeviceId: updatedArray,
        });
      }
      this.setState({
        minsMetaData: [...this.state.minsMetaData, ...minsData],
      });
      this.updateEventsPlotting();
    }
  };

  updateTimeZone = (selectedDate) => {
    const getLocationSelected = findTimeZoneFromSelectedValue(
      this?.props?.timezone,
    );
    const zone = getLocationSelected?.location || moment.tz.guess();

    const UTCDate = moment(selectedDate ? selectedDate : new Date())
      .tz(zone)
      .format();

    if (zone) {
      this.setState({
        timeZone: zone,
        offset: UTCDate,
      });
    }
  };

  moveTimeline = () => {
    clearInterval(this.MultiCVRAutoPlay);
    if (this.state.selectedOption === 'Days') {
      this.MultiCVRAutoPlay = setInterval(() => {
        this.timeline?.current?.$el?.moveTo(
          moment(this.state.liveStreamMode ? new Date() : this.state.activeTime)
            .add({ seconds: 1 })
            .toDate(),
        );
      }, 1000);
    } else {
      this.MultiCVRAutoPlay = setInterval(() => {
        this.timeline?.current?.$el?.moveTo(
          moment(this.state.liveStreamMode ? new Date() : this.state.activeTime)
            .add({ seconds: 1 })
            .toDate(),
        );
      }, 1000);
    }
    setTimeout(() => {
      this.minOptionClicked = false;
    }, 1500);
  };

  componentWillUnmount() {
    if (this.state.metaDataControllersRef.length > 0) {
      this.state.metaDataControllersRef.forEach((controller) =>
        controller.abort(),
      );
    }
    this.setState({
      internalEventFromSearch: false,
      metaDataControllersRef: [],
    });
    clearInterval(this.MultiCVRAutoPlay);
    clearInterval(this.dynamicTimeLineInterval);
    this.props?.setPlaybackActiveTime('');
    this.props?.setMultiLiveStreamLoader(null);
    this.props?.setIsOnMultiCameraViewPage(false);
    this.props?.setMaxPeerCountForDevice(null);
    this.props?.setPlaybackBufferMeta(null);
    // this.playbackSyncDeviceIds = {};
    // this.playRequestSentTimeDeviceIds = {};
    this.props?.setOfferrecived(false);
    disconnectWithMQTT();
    if (this.state.cvrMode) {
      this.sendPauseCVR();
    }
    this.stopUMPPLayer();
    stopPlaybackConnection();
    this.callStopPlaybackConnection(this.props.allDeviceIds);
    if (this.state.selectedView?.devices) {
      this.unscubscribeRTEventsTopic(
        JSON.parse(JSON.stringify(this.state.selectedView?.devices)),
      );
      this.cleanTimerForLiveEvents(
        JSON.parse(JSON.stringify(this.state.selectedView?.devices)),
      );
    } else {
      const { wssConnections } = this.props?.streaming;
      if (wssConnections) {
        Object.keys(wssConnections).forEach((key) => {
          sendCloudDisConnectRequest(key);
        });
      }
    }
  }

  callStopPlaybackConnection = (deviceIdsList) => {
    const deviceCapabilities = this.props.deviceCapabilitiesByDeviceId;
    const isAutoPlay = this.state.cvrMode && !this.state.CVRSnapMode;
    deviceIdsList.forEach((deviceId) => {
      if (
        deviceCapabilities?.[deviceId]?.isEdgeRecording &&
        deviceCapabilities?.[deviceId]?.isSDCard
      ) {
        sendStopCommand(deviceId, isAutoPlay);
        stopPlaybackConnection(deviceId);
      } else {
        sendCloudDisConnectRequest(deviceId);
        disconnectWithWebSocket(deviceId);
      }
    });
  };

  setDynamicTimelineMove = () => {
    const { offset } = this.state;
    this.timeline?.current?.$el.setOptions({
      moment: function (date) {
        return moment(date).utcOffset(offset);
      },
    });
    if (this.state.minsView) {
      this.timeline?.current?.$el.setOptions({
        max: moment(new Date()).add({ minutes: 4, seconds: 21 }).toDate(),
        min: moment(this.state.startDate)
          .subtract({ minutes: 4, seconds: 21 })
          .toDate(),
      });
    } else if (this.state.daysView) {
      this.timeline?.current?.$el.setOptions({
        max: moment(new Date()).add({ days: 1, hours: 6, seconds: 1 }).toDate(),
        min: moment(this.state.startDate)
          .subtract({ days: 1, hours: 6, seconds: 1 })
          .toDate(),
      });
    } else if (this.state.secsView) {
      this.timeline?.current?.$el.setOptions({
        max: moment(new Date()).add({ seconds: 41 }).toDate(),
        min: moment(this.state.startDate).subtract({ seconds: 41 }).toDate(),
      });
    }
  };

  fetchCVRMetaData = (timestamp) => {
    let minOptions;
    if (
      this.state.selectedOption === 'Mins' ||
      this.state.selectedOption === 'Secs'
    ) {
      minOptions = { minutes: 5 };
    } else if (this.state.selectedOption === 'Days') {
      minOptions = { days: 1 };
    }
    const startTime = Utils.getUnixDate(
      moment(timestamp || this.state.activeTime).subtract(minOptions),
    );
    const endTime = Utils.getUnixDate(
      moment(timestamp || this.state.activeTime).add(minOptions),
    );

    let metaData = [];
    const deviceMetadata = [];
    this.props?.allDeviceIds?.forEach((deviceId, index) => {
      if (startTime && startTime.length !== 0 && endTime) {
        if (this.state.metaDataControllersRef?.[index]) {
          this.state.metaDataControllersRef[index].abort();
        }
        const controller = new AbortController();
        const { signal } = controller;
        const data = [...this.state.metaDataControllersRef, controller];
        this.setState({
          metaDataControllersRef: data,
        });
        axios
          .get(
            `timeline/device/${deviceId}/metadata/v2?startTime=${startTime}000&endTime=${endTime}000`,
            {
              ...Utils.requestHeader(),
              signal: signal,
            },
          )
          .then((response) => {
            if (response?.data?.data?.length > 0) {
              if (metaData?.length > 0) {
                metaData = metaData.concat(response.data?.data);
              } else {
                metaData = response?.data?.data;
              }
              const deviceBaseMetadata = {
                deviceId: deviceId,
                metadata: response.data?.data,
              };
              deviceMetadata.push(deviceBaseMetadata);
              this.setState(
                {
                  rawMetaData: metaData,
                  metadataByDeviceId: deviceMetadata,
                },
                () => {
                  this.getItemsMetaData(metaData);
                },
              );
            }
          });
      }
    });
  };

  updateEventsPlotting = (eventsData) => {
    let data = [];
    if (eventsData) {
      data = eventsData;
    } else {
      data = this.state.eventsDataCheck;
    }
    let selectedMetaOption;
    if (this.state.selectedOption.toLowerCase() === 'mins') {
      selectedMetaOption = this.state.minsMetaData;
    } else if (this.state.selectedOption.toLowerCase() === 'days') {
      selectedMetaOption = this.state.daysMetaData;
    } else {
      selectedMetaOption = this.state.secsMetaData;
    }
    let playbackBufferData = this.state.playbackBufferData;
    const playbackBuffer = [];
    Object.keys(playbackBufferData).forEach((key) => {
      const value = playbackBufferData?.[key];

      if (Array.isArray(value)) {
        playbackBuffer.push(...value);
      }
    });
    const mergedItem = [...playbackBuffer, ...data];
    const filteredArr = mergedItem.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    this.timeline?.current?.$el?.setItems(filteredArr);
  };

  onMuteIconClick = () => {
    this.setState({
      muteAudio: !this.state.muteAudio,
    });
  };

  onClickMins = () => {
    this.minOptionClicked = true;
    clearInterval(this.MultiCVRAutoPlay);
    switch (this.state.selectedOption) {
      case 'Mins':
        this.setState(
          {
            minsView: false,
            daysView: true,
            secsView: false,
            selectedOption: 'Days',
            clickAction: true,
          },
          () => {
            this.fetchCVRMetaData();
            this.fetchEventsData();
          },
        );
        break;
      case 'Days':
        this.setState(
          {
            minsView: false,
            daysView: false,
            secsView: true,
            selectedOption: 'Secs',
            clickAction: true,
          },
          () => {
            this.fetchEventsDataInSecs();
          },
        );
        break;
      case 'Secs':
        this.setState(
          {
            minsView: true,
            daysView: false,
            secsView: false,
            selectedOption: 'Mins',
            clickAction: true,
          },
          () => {
            this.fetchEventsData();
          },
        );
        break;
      default:
        break;
    }

    setTimeout(() => {
      this.setState(
        {
          clickAction: false,
        },
        () => {
          this.moveTimeline();
        },
      );
    }, 300);
  };

  onGoLive = () => {
    this.isRangeChanging = false;
    clearInterval(this.MultiCVRAutoPlay);
    this.updateTimeZone();
    this.setState(
      {
        liveStreamMode: true,
        activeTime: new Date(),
        manualClicked: false,
        filterDate: null,
        selectedDate: new Date(),
        selectedTime: new Date().setHours(0, 0, 0),
      },
      () => {
        if (this.state.cvrMode || this.state.CVRSnapMode) {
          stopPlaybackConnection();
          this.props?.allDeviceIds?.forEach((deviceId) => {
            this.handlePausePlayPlayback('pause', null, deviceId);
          });
        }
        const onlineDevices =
          this.props?.allDevices?.filter(
            (device) =>
              device?.connectionStatus?.toLowerCase() ===
                constants.DEVICES_RETURN_ONLINE_STATUS.toLowerCase() &&
              this.props?.allDeviceIds?.includes(device.deviceId),
          ) ?? [];

        if (onlineDevices?.length === 0 || !onlineDevices) {
          this.onPauseCVR();
          this.timeline?.current?.$el?.moveTo(moment(new Date()).toDate());
        } else {
          this.props?.allDeviceIds?.forEach((deviceId) => {
            const loaderObj = {
              deviceId,
              isLoading: true,
            };
            this.props?.setMultiLiveStreamLoader(loaderObj);
          });

          if (
            this.state.CVRSnapMode ||
            this.state.cvrMode ||
            this.state.liveStreamMode
          ) {
            this.setState(
              {
                cvrMode: false,
                CVRSnapMode: false,
              },
              () => {
                this.moveTimeline();
              },
            );
          }
        }
      },
    );
    this.callAllAPIs(new Date());
  };

  callAllAPIs = (timeStamp) => {
    this.fetchEventsData(timeStamp || this.state.activeTime);
    this.fetchCVRMetaData(timeStamp || this.state.activeTime);
    if (this.isAbleToCallAPI) {
      this.isAbleToCallAPI = false;
    }
  };

  fetchSnapshotCanvasById = (typeCanvas, typeVideo, deviceId) => {
    try {
      let canvas = document.getElementById(typeCanvas);
      let video = document.getElementById(typeVideo);
      if (canvas && video) {
        const context = canvas?.getContext('2d');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        if (video?.tagName?.toLocaleLowerCase() === 'video') {
          context?.drawImage(video, 0, 0, canvas.width, canvas.height);
          const data = canvas?.toDataURL('image/png');
          this.snapList = {
            ...this.snapList,
            [deviceId]: this.props?.streaming?.multiLiveStreamLoader?.[deviceId]
              ? useDeviceSnapshots.getState()?.deviceSnapshots?.[deviceId]
              : data,
          };
        }
      }
    } catch (err) {}
  };

  stopUMPPLayer = () => {
    try {
      disconnectWithMQTT();
      const umpPlayers = document.querySelectorAll('ump-player');

      umpPlayers.forEach((umpPlayer) => {
        umpPlayer?.mute();
        umpPlayer?.stop();
      });
    } catch (err) {
      Utils.vmsLogger().log('stop ump player error', err);
    }
  };

  onPauseCVR = async () => {
    if (this.state.cvrMode) {
      this.props?.allDeviceIds?.forEach((deviceId) => {
        const canvasId = `canvas${deviceId}`;
        const videoId = `playback-video${deviceId}`;
        this.fetchPlaybackSnapshot(canvasId, videoId, deviceId);
      });

      this.setState(
        {
          cvrMode: false,
          CVRSnapMode: true,
        },
        () => {
          // this.sendPauseCVR();
          this.props?.allDeviceIds?.forEach((deviceId) => {
            const loaderObj = {
              deviceId,
              isLoading: false,
            };
            this.props?.setMultiLiveStreamLoader(loaderObj);
            this.handlePausePlayPlayback('pause', null, deviceId);
          });
        },
      );
    }
    if (this.state.liveStreamMode) {
      const allDevicesCapabilities = this.props.deviceCapabilitiesByDeviceId;
      this.props?.allDeviceIds?.forEach((liveDeviceId) => {
        const isEdgeRecording =
          allDevicesCapabilities?.[liveDeviceId]?.isEdgeRecording;
        const id = `${liveDeviceId}${liveDeviceId}`;
        this.fetchSnapshotCanvasById(
          `canvas${id}`,
          isEdgeRecording
            ? `live-ump-player-test${id}`
            : `livestream-ump-player-test${id}`,
          liveDeviceId,
        );
      });
      this.stopUMPPLayer();
      this.setState(
        {
          liveStreamMode: false,
          liveSnapshot: true,
          CVRSnapMode: true,
        },
        () => {},
      );
    }
    this.setState({
      manualClicked: true,
    });
    clearInterval(this.MultiCVRAutoPlay);
    this.callAllAPIs();
  };

  // moveServerTimeline = (deviceId) => {
  //   let vid = document.getElementById(`playback-video${deviceId}`);
  //   // Assign an timeupdate event to the <video> element, and execute a function if the current playback position has changed
  //   if (vid) {
  //     vid.addEventListener(
  //       'timeupdate',
  //       (event) => this.moveFunc(event, deviceId),
  //       false,
  //     );

  //     if (this.props?.streaming?.elementUpdated) {
  //       this.props?.setElementUpdated(false);
  //     }
  //   }
  // };

  handleBufferData = (deviceId) => {
    try {
      if (this.state.playbackBufferData?.[deviceId]?.length > 0) {
        const unixEndDate =
          Utils.getUnixDate(
            this.state.playbackBufferData?.[deviceId]?.[
              this.state.playbackBufferData?.[deviceId]?.length - 1
            ]?.end,
          ) * 1000;
        if (
          this.props.streaming?.playbackBufferMeta?.[deviceId]?.end !==
          unixEndDate
        ) {
          const minsAdjustment = { minutes: 0 };
          const updatedBufferData = getTimelineData(
            [this.props.streaming?.playbackBufferMeta?.[deviceId]],
            minsAdjustment,
          );

          const cloneArr = [...this.state.playbackBufferData?.[deviceId]];
          let findIndex = this.state.playbackBufferData?.[deviceId]?.findIndex(
            (buf) =>
              Utils.getUnixDate(buf.start) * 1000 ===
              this.props.streaming?.playbackBufferMeta?.[deviceId]?.start,
          );
          if (findIndex !== -1) {
            cloneArr.splice(findIndex, 1, updatedBufferData[0]);
            this.setState(
              {
                playbackBufferData: {
                  ...this.state.playbackBufferData,
                  [deviceId]: cloneArr,
                },
              },
              () => {
                this.updateEventsPlotting();
              },
            );
          } else {
            this.setState(
              {
                playbackBufferData: {
                  ...this.state.playbackBufferData,
                  [deviceId]: {
                    ...this.state.playbackBufferData?.[deviceId],
                    ...updatedBufferData,
                  },
                },
              },
              () => {
                this.updateEventsPlotting();
              },
            );
          }
        }
      } else {
        const minsAdjustment = { minutes: 0 };
        const updatedBufferData = getTimelineData(
          [this.props.streaming?.playbackBufferMeta?.[deviceId]],
          minsAdjustment,
        );
        this.setState(
          {
            playbackBufferData: {
              ...this.state.playbackBufferData,
              [deviceId]: updatedBufferData,
            },
          },
          () => {
            this.updateEventsPlotting();
          },
        );
      }
    } catch (err) {}
  };

  onPlayCVR = () => {
    this.isRangeChanging = false;
    this.setState(
      {
        cvrMode: true,
        liveStreamMode: false,
        CVRSnapMode: false,
        eventCVRMode: true,
        selectedEventTime: Utils.getUnixDate(this.state.activeTime) * 1000,
      },
      () => {
        this.props?.allDeviceIds?.forEach((deviceId) => {
          const loaderObj = {
            deviceId,
            isLoading: true,
          };
          this.props?.setMultiLiveStreamLoader(loaderObj);
          this.handlePausePlayPlayback('play', null, deviceId);
        });
        // this.sendPlayCVR();
        this.moveTimeline();
      },
    );
  };

  getOptions = () => {
    if (this.state.minsView) {
      return this.state.minsOptions;
    } else if (this.state.daysView) {
      return this.state.daysOptions;
    } else if (this.state.secsView) {
      return this.state.secsOptions;
    }
  };

  sendPlayCVR = (newTime) => {
    const { allDeviceIds } = this.props;
    const { activeTime } = this.state;
    allDeviceIds?.forEach((deviceId) => {
      // this.sendPlayCVRByDeviceId(
      //   deviceId,
      //   newTime ? newTime : activeTime,
      //   deviceId,
      // );
      this.handlePausePlayPlayback(
        'play',
        newTime ? newTime : activeTime,
        deviceId,
      );
    });
  };

  // sendPlayCVRByDeviceId = (deviceId, newTime) => {
  //   const findMetaByDevice = this.state.metadataByDeviceId?.find(
  //     (devMeta) => devMeta.deviceId === deviceId,
  //   );
  //   if (findMetaByDevice && findMetaByDevice?.metadata?.length > 0) {
  //     const isInRange = this.checkMetaInRange(
  //       findMetaByDevice?.metadata,
  //       newTime,
  //     );
  //     if (isInRange) {
  //       const findNoContentDevice = this.state.noContentDeviceIds?.findIndex(
  //         (device) => device === deviceId,
  //       );
  //       const updatedArray = [...this.state.noContentDeviceIds];
  //       if (findNoContentDevice > -1) {
  //         updatedArray.splice(findNoContentDevice, 1);
  //         this.setState({
  //           noContentDeviceIds: updatedArray,
  //         });
  //       }
  //       sendPlayCVR(newTime, deviceId, this.state.oneIceSend);
  //     } else {
  //       const loaderObj = {
  //         deviceId,
  //         isLoading: false,
  //       };
  //       this.props?.setMultiLiveStreamLoader(loaderObj);
  //       const findNoContentDevice = this.state.noContentDeviceIds?.findIndex(
  //         (device) => device === deviceId,
  //       );
  //       if (findNoContentDevice === -1) {
  //         this.setState({
  //           noContentDeviceIds: [...this.state.noContentDeviceIds, deviceId],
  //         });
  //       }
  //     }
  //   }
  // };

  checkMetaInRange = (rawMetaData, newTime) => {
    let status = false;
    for (let range of rawMetaData) {
      if (
        Math.trunc(range?.start / 1000) <=
          Math.trunc(newTime?.getTime() / 1000) &&
        Math.trunc(newTime?.getTime() / 1000) <= Math.trunc(range?.end / 1000)
      ) {
        status = true;
        break;
      }

      if (
        Math.trunc(newTime?.getTime() / 1000) ===
        Math.trunc(range?.start / 1000)
      ) {
        status = true;
        break;
      }
    }
    return status;
  };

  sendPauseCVR = () => {
    const { allDeviceIds } = this.props;
    allDeviceIds?.forEach((deviceId) => {
      // sendPauseCVR(deviceId);
      this.handlePausePlayPlayback('pause', null, deviceId);
    });
  };

  generateEventCountURL = (endTime, startTime) => {
    let aggByOption = '';
    if (this.state?.selectedOption?.toUpperCase() === 'MINS') {
      aggByOption = 'minute';
    } else if (this.state?.selectedOption?.toUpperCase() === 'DAYS') {
      aggByOption = 'day';
    } else if (this.state?.selectedOption?.toUpperCase() === 'SECS') {
      aggByOption = 'second';
    }

    let eventClassesString = eventClasses
      .map((item) =>
        item.eventClasses.map((eventClass) => `&eventClasses=${eventClass}`),
      )
      .flat()
      .join('');

    const eventCountURL = `timeline/orgs/${
      this.props?.account?.userdata[0]?.orgId
    }/events/agg/count?endTime=${endTime}000&startTime=${startTime}000&deviceIds=${this.props?.views?.selectedView?.devices?.join(
      ',',
    )}&ascOrder=false${eventClassesString}&aggBy=${aggByOption}`;

    return eventCountURL;
  };

  fetchEventsData = (moveTime) => {
    const timeOptions =
      this.state?.selectedOption?.toUpperCase() === 'MINS'
        ? { minutes: 3 }
        : this.state?.selectedOption?.toUpperCase() === 'DAYS'
          ? { days: 1 }
          : this.state?.selectedOption?.toUpperCase() === 'SECS'
            ? { seconds: 60 }
            : { minutes: 3 };

    const endTime = Utils.getUnixDate(
      moment(moveTime ? moveTime : this.state.activeTime).add(timeOptions),
    );
    const startTime = Utils.getUnixDate(
      moment(moveTime ? moveTime : this.state.activeTime).subtract(timeOptions),
    );

    if (
      startTime &&
      endTime &&
      this.props?.account?.userdata[0]?.orgId &&
      this.props?.views?.selectedView?.devices?.length
    ) {
      axios
        .get(
          this.generateEventCountURL(
            endTime > Utils.getUnixDate(new Date())
              ? Utils.getUnixDate(new Date())
              : endTime,
            startTime,
          ),
          Utils.requestHeader(),
        )
        .then((response) => {
          if (response?.data?.data) {
            const scrubberEvents = Object.entries(response?.data?.data?.result);
            const finalData =
              scrubberEvents?.map((event) => ({
                start: event[0],
                data: Object.keys(event[1]),
                type: 'point',
              })) || [];

            let timeAdjustment;
            if (this.state?.selectedOption?.toUpperCase() === 'MINS') {
              timeAdjustment = { minutes: 0 };
            } else if (this.state?.selectedOption?.toUpperCase() === 'DAYS') {
              timeAdjustment = { days: 0 };
            } else if (this.state?.selectedOption?.toUpperCase() === 'SECS') {
              timeAdjustment = { seconds: 0 };
            }
            const massagedData = getEventsScubberData(
              finalData,
              timeAdjustment,
            );
            this.setState({
              eventsDataCheck: massagedData,
            });
            this.updateEventsPlotting(massagedData);
          }
        });
    }
  };

  fetchEventsDataInRange = (timestamp) => {
    const endTime = Utils.getUnixDate(
      moment(timestamp || this.state.activeTime).add({
        minutes: 3,
      }),
    );
    const startTime = Utils.getUnixDate(
      moment(timestamp || this.state.activeTime).subtract({
        minutes: 3,
      }),
    );

    if (
      startTime &&
      endTime &&
      this.props?.account?.userdata[0]?.orgId &&
      this.props?.views?.selectedView?.devices?.length
    ) {
      Utils.vmsLogger().log('Livestream count api called');
      axios
        .get(
          this.generateEventCountURL(endTime, startTime),
          Utils.requestHeader(),
        )
        .then((response) => {
          if (response?.data?.data) {
            const scrubberEvents = Object.entries(response?.data?.data?.result);
            const finalData =
              scrubberEvents?.map((event) => ({
                start: event[0],
                data: Object.keys(event[1]),
                type: 'point',
              })) || [];
            const minsAdjustment = { minutes: 0 };
            const massagedData = getEventsScubberData(
              finalData,
              minsAdjustment,
            );
            this.setState({
              eventsDataCheck: massagedData,
              holdEventsDotsData: [],
            });
            this.updateEventsPlotting(massagedData);
          }
        });
    }
  };

  fetchEventsDataInDays = (timestamp) => {
    const endTime = Utils.getUnixDate(
      moment(timestamp || this.state.activeTime).add({
        days: 1,
      }),
    );
    const startTime = Utils.getUnixDate(
      moment(timestamp || this.state.activeTime).subtract({
        days: 1,
      }),
    );
    if (
      startTime &&
      endTime &&
      this.props?.account?.userdata[0]?.orgId &&
      this.props?.views?.selectedView?.devices?.length
    ) {
      axios
        .get(
          this.generateEventCountURL(endTime, startTime),
          Utils.requestHeader(),
        )
        .then((response) => {
          if (response?.data?.data) {
            const scrubberEvents = Object.entries(response?.data?.data?.result);
            const finalData =
              scrubberEvents?.map((event) => ({
                start: event[0],
                data: Object.keys(event[1]),
                type: 'point',
              })) || [];
            const daysAdjustment = { days: 0 };
            const massagedData = getEventsScubberData(
              finalData,
              daysAdjustment,
            );
            this.setState(
              {
                eventsDataCheck: massagedData,
              },
              () => this.updateEventsPlotting(massagedData),
            );
          }
        });
    }
  };

  fetchEventsDataInSecs = (timestamp) => {
    const endTime = Utils.getUnixDate(
      moment(timestamp || this.state.activeTime).add({ seconds: 60 }),
    );
    const startTime = Utils.getUnixDate(
      moment(timestamp || this.state.activeTime).subtract({
        seconds: 60,
      }),
    );

    if (
      startTime &&
      endTime &&
      this.props?.account?.userdata[0]?.orgId &&
      this.props?.views?.selectedView?.devices?.length
    ) {
      axios
        .get(
          this.generateEventCountURL(endTime, startTime),
          Utils.requestHeader(),
        )
        .then((response) => {
          if (response?.data?.data) {
            const scrubberEvents = Object.entries(response?.data?.data?.result);
            const finalData =
              scrubberEvents?.map((event) => ({
                start: event[0],
                data: Object.keys(event[1]),
                type: 'point',
              })) || [];
            const secsAdjustment = { seconds: 0 };
            const massagedData = getEventsScubberData(
              finalData,
              secsAdjustment,
            );
            this.setState(
              {
                eventsDataCheck: massagedData,
              },
              () => this.updateEventsPlotting(massagedData),
            );
          }
        });
    }
  };

  handlePausePlayPlayback = (type, timestamp, deviceId) => {
    try {
      const deviceData = this.getDeviceInfo(deviceId);
      const playbackPlayer = document.getElementById(
        `playback-video${deviceId}`,
      );
      const currentTime = Utils.getUnixDate(
        timestamp || Utils.getDate(playbackPlayer?.currentTime),
      );
      const bufferStartTime =
        playbackPlayer?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.start(
          0,
        );
      const bufferEndTime =
        playbackPlayer?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(0);

      const checkBufferRange =
        currentTime >= bufferStartTime && currentTime <= bufferEndTime;

      const peerConnection = getPeerConnectionState(deviceId);

      const currentDeviceCaps =
        this.props.deviceCapabilitiesByDeviceId?.[deviceId];
      const isAutoPlay = this.state.cvrMode && !this.state.CVRSnapMode;
      if (type === 'pause') {
        const playbackPlayer = document.getElementById(
          `playback-video${deviceId}`,
        );
        if (playbackPlayer && checkBufferRange) {
          playbackPlayer.pause();
        } else {
          // if (this.props?.isEdgeRecording) {
          if (
            currentDeviceCaps?.isEdgeRecording &&
            currentDeviceCaps?.isSDCard === true
          ) {
            const isAutoPlay = this.state.cvrMode && !this.state.CVRSnapMode;
            sendStopCommand(deviceId, isAutoPlay);
            if (peerConnection?.connectionState === 'connected') {
              cleanVideoElements(deviceId);
            } else {
              stopPlaybackConnection(deviceId);
            }
          } else {
            sendCloudStopRequest(deviceId, isAutoPlay);
            cleanupResources(deviceId);
          }
        }
      }
      if (type === 'play') {
        const playTimeToSend = new Date(
          moment(timestamp || this.state.activeTime).subtract({ seconds: 1 }),
        );
        // clearInterval(this.MultiCVRAutoPlay);
        if (playbackPlayer && checkBufferRange) {
          playbackPlayer.currentTime = Utils.getUnixDate(this.state.activeTime);
          playbackPlayer.play();
        } else {
          // this.props?.setStreamLoader(true);
          // this.props?.setPlaybackBufferMeta(null);
          // this.setState(
          //   {
          //     playbackBufferData: {},
          //   },
          //   () => {
          //     this.updateEventsPlotting();
          //   },
          // );
          const requirePlayData = {
            playTime: playTimeToSend,
            audioValue: this.state.muteAudio,
            timeline: this.timeline,
            deviceId: deviceId,
            isAutoPlay: this.state.cvrMode ? true : false,
            deviceName: deviceData?.deviceName,
          };
          if (
            currentDeviceCaps?.isEdgeRecording &&
            currentDeviceCaps?.isSDCard === true
          ) {
            const isAutoPlay = this.state.cvrMode && !this.state.CVRSnapMode;
            // for firmaware playback
            sendStopCommand(deviceId, isAutoPlay);
            if (peerConnection?.connectionState === 'connected') {
              cleanVideoElements(deviceId);
              startPlaybackProcess(requirePlayData);
              sendPlayCommand(playTimeToSend, 'PLAY', deviceId);
            } else {
              stopPlaybackConnection(deviceId);
              setTimeout(() => {
                startPlaybackProcess(requirePlayData);
              }, 1500);
            }
          } else {
            // for cloud playback
            sendCloudStopRequest(deviceId, isAutoPlay);
            cleanupResources(deviceId);
            setTimeout(() => {
              const isElement = sendCloudPlayRequest(requirePlayData);
              // if (isElement === 'elementCreated') {
              //   this.moveServerTimeline();
              // }
            }, 1500);
          }
        }
      }
    } catch (err) {
      Utils.vmsLogger().log('error during pause or play', err);
    }
  };

  debouncedFunction = debounce(() => {
    if (!this.isRangeChanging) {
      this.sendPlayCVR();
      this.moveTimeline();
    }
  }, 1000);

  rangeChangeHandler = (event) => {
    let timeInMin = moment(event.start)
      .add({ minutes: 4, seconds: 20 })
      .toDate();
    let timeInDay = moment(event.start).add({ days: 1, hours: 6 }).toDate();
    let timeInSec = moment(event.start).add({ seconds: 40 }).toDate();
    if (event.byUser && this.state.fakeTime) {
      if (!this.isRangeChanging) {
        this.isRangeChanging = true;
      }
      if (this.snapshotURL) {
        this.snapshotURL = null;
      }
      if (this.snapList) {
        this.snapList = null;
      }
      clearInterval(this.MultiCVRAutoPlay);
      if (
        this.state.selectedOption === 'Mins' &&
        Utils.getUnixDate(timeInMin) !==
          Utils.getUnixDate(this.state.activeTime)
      ) {
        this.setState({
          activeTime: timeInMin,
        });
      } else if (
        this.state.selectedOption === 'Days' &&
        timeInDay !== this.state.activeTime
      ) {
        this.setState({
          activeTime: timeInDay,
        });
      } else if (
        this.state.selectedOption === 'Secs' &&
        timeInSec !== this.state.activeTime
      ) {
        this.setState({
          activeTime: timeInSec,
        });
      }
      if (
        moment(this.state.activeTime).unix() !==
          moment(this.currentTime).unix() &&
        !this.state.clickAction
      ) {
        this.currentTime = this.state.activeTime;
      }
      this.setState({
        CVRSnapMode: true,
        liveSnapshot: false,
        moveTimelineImage: true,
      });
      if (this.state.liveStreamMode) {
        if (!this.pauseRequestSent) {
          this.pauseRequestSent = true;
          disconnectWithMQTT();
        }
        this.setState({
          liveStreamMode: false,
        });
      }
      if (this.state.cvrMode && !this.state.liveStreamMode) {
        if (!this.pauseRequestSent) {
          this.pauseRequestSent = true;
          this.sendPauseCVR();
        }
      }
    } else {
      if (
        this.state.minsView &&
        Utils.getUnixDate(timeInMin) !==
          Utils.getUnixDate(this.state.activeTime)
      ) {
        this.setState({
          activeTime: moment(event.start)
            .add({ minutes: 4, seconds: 20 })
            .toDate(),
        });
      } else if (this.state.daysView) {
        this.setState({
          activeTime: moment(event.start).add({ days: 1, hours: 6 }).toDate(),
        });
      } else if (this.state.secsView) {
        this.setState({
          activeTime: moment(event.start).add({ seconds: 40 }).toDate(),
        });
      }
    }
  };

  rangeChangedHandler = (event) => {
    if (
      moment
        .tz(
          moment(event.start).add({ minutes: 4, seconds: 20 }),
          this.state.timeZone,
        )
        .isDST()
    ) {
      this.updateTimeZone(moment(event.start).add({ minutes: 4, seconds: 20 }));
    }
    if (this.state.cvrMode && !this.minOptionClicked) {
      this.props?.allDeviceIds?.forEach((deviceId) => {
        const unixEndDate = Utils.getUnixDate(
          this.state.playbackBufferData?.[deviceId]?.[
            this.state.playbackBufferData?.[deviceId]?.length - 1
          ]?.end,
        );
        // const unixStartDate = Utils.getUnixDate(
        //   this.state.playbackBufferData?.[deviceId]?.[
        //     this.state.playbackBufferData?.[deviceId]?.length - 1
        //   ]?.start,
        // );
        if (this.state.playbackBufferData?.hasOwnProperty(deviceId)) {
          const unixCurrTime = Utils.getUnixDate(this.state.activeTime);
          const diff = unixEndDate - unixCurrTime;
          let isSendComplete;
          if (
            this.props?.deviceCapabilitiesByDeviceId?.[deviceId]
              ?.isEdgeRecording &&
            this.props?.deviceCapabilitiesByDeviceId?.[deviceId]?.isSDCard
          ) {
            isSendComplete = getSendComplete(deviceId);
          } else {
            isSendComplete = getCloudSendComplete(deviceId);
          }
          // if (unixEndDate > unixCurrTime && unixStartDate < unixCurrTime) {
          //   if (this.props?.streaming?.multiLiveStreamLoader?.[deviceId]) {
          //     const loaderObj = {
          //       deviceId: deviceId,
          //       isLoading: false,
          //     };
          //     this.props?.setMultiLiveStreamLoader(loaderObj);
          //   }
          //   const videoElem = document.getElementById(
          //     `playback-video${deviceId}`,
          //   );
          //   if (Math.abs(unixCurrTime - videoElem?.currentTime) > 1) {
          //     this.playbackSyncDeviceIds[deviceId] = false;
          //   }
          //   if (!this.playbackSyncDeviceIds?.[deviceId] && videoElem) {
          //     this.playbackSyncDeviceIds[deviceId] = true;
          //     videoElem.currentTime = unixCurrTime;
          //   }
          // } else {
          //   if (!this.playRequestSentTimeDeviceIds?.[deviceId]) {
          //     this.playRequestSentTimeDeviceIds[deviceId] = unixCurrTime;
          //   }
          //   if (
          //     unixCurrTime - unixEndDate > 20 &&
          //     isSendComplete &&
          //     unixCurrTime - this.playRequestSentTimeDeviceIds?.[deviceId] > 20
          //   ) {
          //     this.playRequestSentTimeDeviceIds[deviceId] = unixCurrTime;
          //     this.handlePausePlayPlayback('play', unixCurrTime, deviceId);
          //   }
          // }
          if (diff <= 10 && diff > 0 && isSendComplete) {
            if (
              this.props?.deviceCapabilitiesByDeviceId?.[deviceId]
                ?.isEdgeRecording &&
              this.props?.deviceCapabilitiesByDeviceId?.[deviceId]?.isSDCard
            ) {
              // for FW playback
              sendContinueCommand('CONTINUE', deviceId);
            } else {
              // for CLOUD playback
              sendCloudContinueRequest(deviceId);
            }
          }
        }

        const noVideoDiff =
          unixEndDate - Utils.getUnixDate(this.state.activeTime);
        // Utils.vmsLogger().log(
        //   'NO_VIDEO status this.props?.allDeviceIds?.forEach ~ noVideoDiff:',
        //   noVideoDiff,
        // );
        let nextAvailTime;
        let isNoVideoDevice;
        const currentDeviceCaps =
          this.props.deviceCapabilitiesByDeviceId?.[deviceId];
        let noVideoElement = document.getElementById(`noVideo${deviceId}`);
        if (
          currentDeviceCaps?.isEdgeRecording &&
          currentDeviceCaps?.isSDCard === true
        ) {
          nextAvailTime = getNextAvailTime(deviceId);
          isNoVideoDevice = getNoVideoDevices(deviceId);
        } else {
          nextAvailTime = getCloudNextAvailTime(deviceId);
          isNoVideoDevice = getNoVideoCloudDevices(deviceId);
        }
        if (
          isNoVideoDevice !== '' &&
          (!unixEndDate || (noVideoDiff && noVideoDiff <= 0))
        ) {
          // Utils.vmsLogger().log('NO_VIDEO status inside no video< 0');
          if (
            this.noVideoCheckTimestamp <
            (unixEndDate || Utils.getUnixDate(this.state.activeTime))
          ) {
            this.noVideoCheckTimestamp =
              (unixEndDate || Utils.getUnixDate(this.state.activeTime)) + 30;
          }

          // if (!this.state.noContentDeviceIds.includes(deviceId)) {
          if (noVideoElement) {
            noVideoElement.style.display = 'flex';
            const loaderObj = {
              deviceId,
              isLoading: false,
            };
            this.props?.setMultiLiveStreamLoader(loaderObj);
          }
          //   this.setState({
          //     noContentDeviceIds: [...this.state.noContentDeviceIds, deviceId],
          //   });
          // }
        } else {
          if (noVideoElement) {
            noVideoElement.style.display = 'none';
          }
        }
        if (
          nextAvailTime &&
          nextAvailTime <= Utils.getUnixDate(this.state.activeTime) * 1000
        ) {
          // Utils.vmsLogger().log('NO_VIDEO status next available time');
          noVideoElement.style.display = 'none';
          sendPlayCommand(
            Utils.getDate(nextAvailTime / 1000),
            'PLAY',
            deviceId,
          );
          this.noVideoCheckTimestamp = 0;
        }

        if (
          (isNoVideoDevice === deviceId &&
            !nextAvailTime &&
            this.noVideoCheckTimestamp ===
              Utils.getUnixDate(this.state.activeTime)) ||
          (nextAvailTime &&
            this.noVideoCheckTimestamp ===
              Utils.getUnixDate(this.state.activeTime) &&
            noVideoDiff &&
            noVideoDiff <= 0)
        ) {
          Utils.vmsLogger().log(
            'NO_VIDEO status check play request',
            nextAvailTime,
            Utils.getUnixDate(this.state.activeTime),
            this.noVideoCheckTimestamp,
            deviceId,
          );
          this.noVideoCheckTimestamp =
            Utils.getUnixDate(this.state.activeTime) + 30;
          this.handlePausePlayPlayback('play', this.state.activeTime, deviceId);
        }
      });
    }
    if (event.byUser) {
      // this.playbackSyncDeviceIds = {};
      // this.playRequestSentTimeDeviceIds = {};
      if (this.isRangeChanging) {
        this.isRangeChanging = false;
      }
      this.updateTimeZone(moment(event.start).add({ minutes: 4, seconds: 20 }));
      const time =
        this.state.selectedOption === 'Days'
          ? moment(event.end).subtract({ days: 1, hours: 6 }).toDate()
          : moment(event.end).subtract({ minutes: 4, seconds: 20 }).toDate();

      this.setState({
        selectedEventTime: Utils.getUnixDate(time) * 1000,
      });
      const scrubStopTimeDiff = new Date() - time;
      if (scrubStopTimeDiff <= 6000) {
        this.onGoLive();
        this.callAllAPIs(new Date());
      } else {
        if (this.pauseRequestSent) {
          this.pauseRequestSent = false;
        }
        if (
          this.state.cvrMode ||
          (!this.state.manualClicked && time < new Date())
        ) {
          this.setState(
            {
              CVRSnapMode: false,
              cvrMode: true,
            },
            () => {
              this.props?.allDeviceIds?.forEach((deviceId) => {
                const loaderObj = {
                  deviceId,
                  isLoading: true,
                };
                this.props?.setMultiLiveStreamLoader(loaderObj);
              });
              this.debouncedFunction();
            },
          );
        }
        if (this.state.CVRSnapMode && !this.state.cvrMode) {
          this.props?.allDeviceIds?.forEach((deviceId) => {
            const playbackPlayer = document.getElementById(
              `playback-video${deviceId}`,
            );
            const currentTime = Utils.getUnixDate(this.state.activeTime);
            const bufferStartTime =
              playbackPlayer?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.start(
                0,
              );
            const bufferEndTime =
              playbackPlayer?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(
                0,
              );
            const checkBufferRange =
              currentTime >= bufferStartTime && currentTime <= bufferEndTime;
            if (checkBufferRange) {
              playbackPlayer.currentTime = Utils.getUnixDate(time);
            } else {
              if (!this.state.cvrMode && this.state.manualClicked) {
                this.handlePausePlayPlayback(
                  'play',
                  this.state.activeTime,
                  deviceId,
                );
              }
            }
          });
        }
        this.setState({
          moveTimelineImage: false,
        });
        if (!this.state.CVRSnapMode || !this.state.manualClicked) {
          this.setState({
            cvrMode: true,
          });
        }
        this.fetchEventsData();
        this.fetchCVRMetaData();
      }
    }
  };

  getItemsMetaData = (data) => {
    const minsAdjustment = { minutes: 0 };
    const daysAdjustment = { days: 1, hours: 6 };
    const secsAdjustment = { seconds: 0 };

    const minsData = getTimelineData(data, minsAdjustment);
    const daysData = getTimelineData(data, daysAdjustment);
    const secsData = getTimelineData(data, secsAdjustment);

    this.setState(
      {
        minsMetaData: minsData,
        daysMetaData: daysData,
        secsMetaData: secsData,
      },
      () => {
        this.updateEventsPlotting(this.state.eventsDataCheck);
      },
    );
  };

  enterFullScreen = () => {
    this.setState({
      fullscreen: true,
    });
    this.props.enterFullScreen();
  };

  exitFullScreen = () => {
    this.setState({
      fullscreen: false,
    });
    this.props.exitFullScreen();
  };

  onSubmit = (data) => {
    this.props?.allDeviceIds?.forEach((deviceId) => {
      const loaderObj = {
        deviceId,
        isLoading: true,
      };
      this.props?.setMultiLiveStreamLoader(loaderObj);
    });
    // this.playbackSyncDeviceIds = {};
    // this.playRequestSentTimeDeviceIds = {};
    // this.props?.setPlaybackBufferMeta(null);
    // this.setState({
    //   playbackBufferData: {},
    // });
    this.props.setShowJumpToDateModal(false);
    this.updateTimeZone(data.selectedDate);
    this.setState({
      selectedTime: data.selectedTime,
      selectedDate: data.selectedDate,
    });
    if (this.state.fullscreenBackup) {
      this.enterFullScreen();
      this.setState({
        fullscreenBackup: false,
      });
    }
    this.filterObj = data;
    let date = data.filterdate;
    let parsed = moment
      .tz(moment(date, 'YYYY/MM/DD hh:mm:ss A'), this.state.timeZone)
      .format('MMMM Do, YYYY - hh:mm:ss A z');
    this.setState({
      showDateTimeModal: false,
      filterDate: parsed,
    });
    this.jumpToDate(date.getTime());
    this.fetchEventsData(date.getTime());
  };

  jumpToDate = (date) => {
    this.sendPauseCVR();
    disconnectWithMQTT();
    clearInterval(this.MultiCVRAutoPlay);
    this.updateTimeZone(Utils.getDate(date / 1000));
    if (
      this.state.internalEventFromSearch !== this.props.internalEventFromSearch
    ) {
      setTimeout(() => {
        this.setState({
          internalEventFromSearch: this.props.internalEventFromSearch,
        });
      }, 1000);
    }
    if (this.state.activeTime !== Utils.getDate(date / 1000)) {
      this.timeline?.current?.$el?.moveTo(Utils.getDate(date / 1000), [
        { duration: 0 },
      ]);
    }
    setTimeout(() => {
      if (this.state.liveStreamMode || this.state.cvrMode) {
        this.snapshotURL = null;
        this.props?.allDeviceIds?.forEach((deviceId) => {
          const loaderObj = {
            deviceId,
            isLoading: true,
          };
          this.props?.setMultiLiveStreamLoader(loaderObj);
        });
        this.setState(
          {
            cvrMode: true,
            liveStreamMode: false,
            liveSnapshot: false,
            CVRSnapMode: false,
            selectedEventTime: date,
          },
          () => {
            if (this.snapList) {
              this.snapList = null;
            }
            this.sendPlayCVR(Utils.getDate(date / 1000));
            if (!this.state.CVRSnapMode) {
              this.moveTimeline();
            }
          },
        );
      } else {
        this.snapshotURL = null;
        this.snapList = null;
        this.setState(
          {
            CVRSnapMode: true,
            liveSnapshot: false,
            selectedEventTime: date,
          },
          () => {
            this.sendPlayCVR();
          },
        );
      }
    }, 2000);
  };

  hideShowdateTimeModal = (status) => {
    this.setState({
      showDateTimeModal: status,
    });
    this.props.setShowJumpToDateModal(status);
  };

  getDeviceInfo = (deviceId) => {
    if (this.props?.allDevices?.length) {
      const deviceData = this.props?.allDevices?.find(
        (device) => device.deviceId === deviceId,
      );
      return deviceData;
    }
  };

  downloadLiveSnapshotForSafari = async (index) => {
    const { allDeviceIds } = this.props;
    if (index < allDeviceIds?.length) {
      let deviceId = allDeviceIds?.[index];
      const deviceData = this.getDeviceInfo(deviceId);
      const time = moment(this.state.activeTime).format(
        'YYYY-MM-DD - hh.mm.ss A',
      );
      const allDevicesCapabilities = this.props.deviceCapabilitiesByDeviceId;
      const isEdgeRecording =
        allDevicesCapabilities?.[deviceId]?.isEdgeRecording;
      const id = this.state.liveStreamMode
        ? `${deviceId}${deviceId}`
        : `${deviceId}`;
      const playbackElementId = `playback-video${deviceId}`;

      const liveElementId = isEdgeRecording
        ? `live-ump-player-test${id}`
        : `livestream-ump-player-test${id}`;

      const data = await multiStreamSnapDownload(
        `${deviceData?.deviceName} - ${time}`,
        `canvas${id}`,
        this.state.liveStreamMode ? liveElementId : playbackElementId,
        deviceId,
      );

      if (data) {
        index++;
        setTimeout(() => {
          this.downloadLiveSnapshotForSafari(index);
        }, 1500);
      }
    }
  };

  downloadSnapshotsForSafari = async (index) => {
    const { allDeviceIds } = this.props;
    if (index < allDeviceIds?.length) {
      const deviceId = allDeviceIds?.[index];
      const deviceData = this.getDeviceInfo(deviceId);
      const time = moment(this.state.activeTime).format(
        'YYYY-MM-DD - hh.mm.ss A',
      );
      const id = `${deviceId}`;
      const playbackElementId = `playback-video${deviceId}`;

      if (this.state.liveSnapshot) {
        let a = document.createElement('a');
        a.download = `${deviceData?.deviceName} - ${time}.png`;
        const liveSnapEle = document.getElementById(`imgSnapshot_${deviceId}`);
        if (liveSnapEle) {
          a.href = liveSnapEle.src;
          document.body.appendChild(a);
          a.click();
          index++;
        }
      } else {
        const data = await multiStreamSnapDownload(
          `${deviceData?.deviceName} - ${time}`,
          `canvas${id}`,
          playbackElementId,
          deviceId,
        );
        if (data) index++;
      }
      setTimeout(() => {
        this.downloadSnapshotsForSafari(index);
      }, 1500);
    }
  };

  downloadLiveImage = () => {
    // This function used to download snapshot during on going live stream
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      // For safari, as safari blocks multiple downloads at once
      let index = 0;
      this.downloadLiveSnapshotForSafari(index);
    } else {
      const { allDeviceIds } = this.props;
      allDeviceIds?.forEach((deviceId, index) => {
        const deviceData = this.getDeviceInfo(deviceId);
        const time = moment(this.state.activeTime).format(
          'YYYY-MM-DD - hh.mm.ss A',
        );
        const id = `${deviceId}${deviceId}`;
        const allDevicesCapabilities = this.props.deviceCapabilitiesByDeviceId;
        const isEdgeRecording =
          allDevicesCapabilities?.[deviceId]?.isEdgeRecording;
        const elementId = isEdgeRecording
          ? `live-ump-player-test${id}`
          : `livestream-ump-player-test${id}`;
        multiStreamSnapDownload(
          `${deviceData?.deviceName} - ${time}`,
          `canvas${id}`,
          elementId,
          deviceId,
        );
      });
    }
  };

  downloadPlaybackImage = async () => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const time = moment(this.state.activeTime).format(
      'YYYY-MM-DD - hh.mm.ss A',
    );
    if (isSafari) {
      // For safari, as safari blocks multiple downloads at once
      let index = 0;
      if (!this.state.CVRSnapMode && this.state.cvrMode) {
        this.downloadLiveSnapshotForSafari(index);
      } else {
        this.downloadSnapshotsForSafari(index);
      }
    } else {
      const { allDeviceIds } = this.props;
      allDeviceIds?.forEach((deviceId, index) => {
        const deviceData = this.getDeviceInfo(deviceId);
        if (this.state.liveSnapshot) {
          this.downloadLiveSnapshot(deviceId, deviceData?.deviceName);
        } else if (this.state.CVRSnapMode) {
          multiStreamSnapDownload(
            `${deviceData?.deviceName} - ${time}`,
            `canvas${deviceId}`,
            `playback-video${deviceId}`,
            deviceId,
          );
        }
        if (!this.state.CVRSnapMode && this.state.cvrMode) {
          multiStreamSnapDownload(
            `${deviceData?.deviceName} - ${time}`,
            `canvas${deviceId}`,
            `playback-video${deviceId}`,
            deviceId,
          );
        }
      });
    }
  };

  fetchPlaybackSnapshot = async (typeCanvas, typeVideo, deviceId) => {
    try {
      // This function fetch and show the snapshot when playback is running and we click pause button.
      let canvas = document.getElementById(typeCanvas);
      let video = document.getElementById(typeVideo);
      if (canvas && video) {
        const context = canvas?.getContext('2d');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context?.drawImage(video, 0, 0, canvas.width, canvas.height);
        const data = canvas?.toDataURL('image/png');
        this.snapshotURL = {
          ...this.snapshotURL,
          [deviceId]: this.props?.streaming?.multiLiveStreamLoader?.[deviceId]
            ? null
            : data,
        };
      }
    } catch (err) {}
  };

  downloadLiveSnapshot = (deviceId, deviceName) => {
    // This function is used when we pause the live stream and download live snapshot.
    const time = moment(this.state.activeTime).format(
      'YYYY-MM-DD - hh.mm.ss A',
    );
    let a = document.createElement('a');
    const liveSnapEle = document.getElementById(`imgSnapshot_${deviceId}`);
    if (liveSnapEle) {
      a.download = `${deviceName} - ${time}.png`;
      a.href = liveSnapEle.src;
      document.body.appendChild(a);
      a.click();
    }
  };

  render() {
    return (
      <>
        <div className="main-content-container">
          {/* <ResponsiveReactGridLayout
            devicesData={this.props.allDevices}
            entitleData={this.props?.entitleData}
            metadataByDeviceId={this.state.metadataByDeviceId}
            clearLayout={this.props.clearLayout}
            currentViewDeviceList={this.props.currentViewDeviceList}
            selectedViewDevices={this.props.selectedViewDevices}
            setClearLayout={this.props.setClearLayout}
            resetLayout={this.props.resetLayout}
            setCustomViewLayout={this.props.setCustomViewLayout}
            setResetLayout={this.props.setResetLayout}
            setEditLayout={this.props.setEditLayout}
            selectedView={this.state.selectedView}
            isViewUpdateSaved={this.props.isViewUpdateSaved}
            viewUpdateSavedCallback={this.props.viewUpdateSavedCallback}
            viewId={this.props.viewId}
            activeTime={this.state.activeTime}
            timeZone={this.state.timeZone}
            gridItemComponent={
              this.state.liveStreamMode
                ? 'LiveGridItem'
                : this.state.activeTime && this.state.CVRSnapMode
                  ? 'ImageGridItem'
                  : !this.state.CVRSnapMode
                    ? 'PlaybackGridItem'
                    : 'LiveGridItem'
            }
            // ImageGridItem props
            moveTimeline={this.state.moveTimelineImage}
            liveSnapshot={this.state.liveSnapshot}
            time={this.state.activeTime}
            cdnValue={this.props?.streaming?.cdnInfo}
            snapshotImages={this.snapList}
            snapshotURL={this.snapshotURL}
            selectedEventTime={this.state.selectedEventTime}
            // PlaybackGridItem props
            orgDetails={this.props.orgDetails}
            noContentDeviceIds={this.state.noContentDeviceIds}
          /> */}
          {this.state.liveStreamMode ? (
            <LiveGridStructure
              activeTime={this.state.activeTime}
              timeZone={this.state.timeZone}
              viewId={this.props.viewId}
              orgDetails={this.props.orgDetails}
              devicesData={this.props.allDevices}
              selectedView={this.props.selectedView}
              selectedViewDevices={this.props.selectedViewDevices}
              currentViewDeviceList={this.props.currentViewDeviceList}
              setCurrentViewDeviceList={this.props.setCurrentViewDeviceList}
              setCustomViewLayout={this.props.setCustomViewLayout}
              entitleData={this.props?.entitleData}
              metadataByDeviceId={this.state.metadataByDeviceId}
              clearLayout={this.props.clearLayout}
              setClearLayout={this.props.setClearLayout}
              resetLayout={this.props.resetLayout}
              setResetLayout={this.props.setResetLayout}
              setEditLayout={this.props.setEditLayout}
              isViewUpdateSaved={this.props.isViewUpdateSaved}
              viewUpdateSavedCallback={this.props.viewUpdateSavedCallback}
              fullscreen={this.state.fullscreen}
              deviceCapabilitiesByDeviceId={
                this.props.deviceCapabilitiesByDeviceId
              }
            />
          ) : (
            <>
              {/* {(this.state.activeTime && this.state.CVRSnapMode) ||
              this.state.liveSnapshot ? ( */}
              {/* {this.state.activeTime &&
              (this.state.moveTimelineImage || this.state.liveSnapshot) ? ( */}
              {this.state.moveTimelineImage || this.state.liveSnapshot ? (
                <ImageGridStructure
                  moveTimeline={this.state.moveTimelineImage}
                  liveSnapshot={this.state.liveSnapshot}
                  viewId={this.props.viewId}
                  selectedView={this.props.selectedView}
                  time={this.state.activeTime}
                  cdnValue={this.props?.streaming?.cdnInfo}
                  timeZone={this.state.timeZone}
                  orgDetails={this.props.orgDetails}
                  devicesData={this.props.allDevices}
                  selectedViewDevices={this.props.selectedViewDevices}
                  currentViewDeviceList={this.props.currentViewDeviceList}
                  setCurrentViewDeviceList={this.props.setCurrentViewDeviceList}
                  setCustomViewLayout={this.props.setCustomViewLayout}
                  snapshotURL={this.snapshotURL}
                  snapshotImages={this.snapList}
                  clearLayout={this.props.clearLayout}
                  setClearLayout={this.props.setClearLayout}
                  resetLayout={this.props.resetLayout}
                  setResetLayout={this.props.setResetLayout}
                  setEditLayout={this.props.setEditLayout}
                  isViewUpdateSaved={this.props.isViewUpdateSaved}
                  viewUpdateSavedCallback={this.props.viewUpdateSavedCallback}
                  selectedEventTime={this.state.selectedEventTime}
                  fullscreen={this.state.fullscreen}
                />
              ) : null}
              {/* ) : (
                <></>
              )} */}
              <PlaybackGridStructure
                moveTimeline={this.state.moveTimelineImage}
                liveSnapshot={this.state.liveSnapshot}
                activeTime={this.state.activeTime}
                timeZone={this.state.timeZone}
                viewId={this.props.viewId}
                selectedView={this.props.selectedView}
                noContentDeviceIds={this.state.noContentDeviceIds}
                orgDetails={this.props.orgDetails}
                devicesData={this.props.allDevices}
                selectedViewDevices={this.props.selectedViewDevices}
                currentViewDeviceList={this.props.currentViewDeviceList}
                setCurrentViewDeviceList={this.props.setCurrentViewDeviceList}
                setCustomViewLayout={this.props.setCustomViewLayout}
                cdnValue={this.props?.streaming?.cdnInfo}
                selectedEventTime={this.state.selectedEventTime}
                clearLayout={this.props.clearLayout}
                setClearLayout={this.props.setClearLayout}
                resetLayout={this.props.resetLayout}
                setResetLayout={this.props.setResetLayout}
                setEditLayout={this.props.setEditLayout}
                isViewUpdateSaved={this.props.isViewUpdateSaved}
                viewUpdateSavedCallback={this.props.viewUpdateSavedCallback}
                fullscreen={this.state.fullscreen}
                deviceCapabilitiesByDeviceId={
                  this.props.deviceCapabilitiesByDeviceId
                }
                cvrSnapMode={this.state.CVRSnapMode}
              />
            </>
          )}
        </div>
        <div
          className={`stream-timeline-wrapper timeline-controls-page multiview-timeline safari-margins ${this.props?.isDrawerOpen ? 'safari-margins-drawer-open' : ''}`}
        >
          <div className={`scrubber-wrapper`}>
            <div className="timelines-controls-wrapper">
              {this.state.liveStreamMode && (
                <>
                  <div className="timeline-controls-icons">
                    {!(this.state.cvrMode || this.state.liveStreamMode) && (
                      <span
                        onClick={() => this.onPlayCVR()}
                        className="icons-image"
                      >
                        <img width={16} src={play} alt="icon" />
                      </span>
                    )}

                    {(this.state.cvrMode || this.state.liveStreamMode) && (
                      <span
                        onClick={(e) => this.onPauseCVR(e)}
                        className="icons-image"
                      >
                        <img width={16} src={pause} alt="icon" />
                      </span>
                    )}
                    <span
                      className="icons-image"
                      onClick={() => this.downloadLiveImage()}
                    >
                      <img src={camera} alt="icon" />
                    </span>
                    <button onClick={() => this.onClickMins()} className="mins">
                      {this.state.selectedOption === 'Mins' ? 'Mins' : ''}
                      {this.state.selectedOption === 'Days' ? 'Days' : ''}
                      {this.state.selectedOption === 'Secs' ? 'Secs' : ''}
                    </button>
                  </div>
                  <div className="live">
                    Live
                    <span
                      className="filter-date-image"
                      onClick={() => {
                        this.hideShowdateTimeModal(true);
                        if (this.state.fullscreen) {
                          this.exitFullScreen();
                          this.setState({
                            fullscreenBackup: true,
                          });
                        }
                      }}
                    >
                      <img src={calender} alt="" />
                    </span>
                  </div>
                  <div className={`active_time`}>
                    {moment
                      .tz(moment(this.state.activeTime), this.state.timeZone)
                      .format('hh:mm:ss A z')}
                    <span
                      className="icons-image"
                      onClick={() =>
                        this.state.fullscreen
                          ? this.exitFullScreen()
                          : this.enterFullScreen()
                      }
                    >
                      <img
                        src={this.state.fullscreen ? minimize : fullscreen}
                        alt="icon"
                      />
                    </span>
                  </div>
                </>
              )}
              {!this.state.liveStreamMode && (
                <>
                  <div className="timeline-controls-icons">
                    {!(
                      this.state.cvrMode ||
                      this.state.liveStreamMode ||
                      !this.state.manualClicked
                    ) && (
                      <span
                        onClick={() => this.onPlayCVR()}
                        className="icons-image"
                      >
                        <img width={16} src={play} alt="icon" />
                      </span>
                    )}

                    {(this.state.cvrMode ||
                      this.state.liveStreamMode ||
                      !this.state.manualClicked) && (
                      <span
                        onClick={(e) => this.onPauseCVR(e)}
                        className="icons-image"
                      >
                        <img width={16} src={pause} alt="icon" />
                      </span>
                    )}
                    <span
                      className="icons-image"
                      onClick={() => this.downloadPlaybackImage()}
                    >
                      <img src={camera} alt="icon" />
                    </span>
                    <button onClick={() => this.onClickMins()} className="mins">
                      {this.state.selectedOption === 'Mins' ? 'Mins' : ''}
                      {this.state.selectedOption === 'Days' ? 'Days' : ''}
                      {this.state.selectedOption === 'Secs' ? 'Secs' : ''}
                    </button>
                  </div>
                  <div className={`active_time`}>
                    {moment
                      .tz(moment(this.state.activeTime), this.state.timeZone)
                      .format('MMM DD, YYYY - hh:mm:ss A z')}
                    <div className="date">
                      <span
                        className="filter-date-image"
                        onClick={() => {
                          this.hideShowdateTimeModal(true);
                          if (this.state.fullscreen) {
                            this.exitFullScreen();
                            this.setState({
                              fullscreenBackup: true,
                            });
                          }
                        }}
                      >
                        <img src={calender} alt="" />
                      </span>
                    </div>
                  </div>
                  <div className="golive-view">
                    <button
                      className="live"
                      onClick={() => {
                        this.onGoLive();
                        this.callAllAPIs(new Date());
                      }}
                    >
                      Go Live
                    </button>
                    <span
                      className="icons-image"
                      onClick={() =>
                        this.state.fullscreen
                          ? this.exitFullScreen()
                          : this.enterFullScreen()
                      }
                    >
                      <img
                        src={this.state.fullscreen ? minimize : fullscreen}
                        alt="icon"
                      />
                    </span>
                  </div>
                </>
              )}
            </div>
            {!this.state.clickAction && this.state.metaDataHere && (
              <div className={`scrubber`}>
                <Timeline
                  ref={this.timeline}
                  options={this.getOptions()}
                  rangechangeHandler={(event) => this.rangeChangeHandler(event)}
                  rangechangedHandler={(event) =>
                    this.rangeChangedHandler(event)
                  }
                />
              </div>
            )}
          </div>
          {this.state.liveStreamMode && (
            <div className={`timeline-icons golive`}>
              <button
                onClick={() => {
                  this.onGoLive();
                  this.callAllAPIs(new Date());
                }}
                className="golive-button"
              >
                {constants.TOP_HEADER_LIVE_NAV_TITLE}
              </button>
            </div>
          )}
        </div>
        {/* Go to Date */}
        <SiteModal
          modalTitle={constants.CAMERAS_LIVE_GO_TO_DATE_MODAL_TITLE}
          showModal={this.state.showDateTimeModal}
          hideModal={() => {
            this.hideShowdateTimeModal(false);
          }}
          classes="date-time-picker-modal"
          size="sm"
        >
          <DateTimePicker
            date={this.state.selectedDate}
            time={this.state.selectedTime}
            minDate={this.props.streaming.cvrStartDate}
            onSubmit={this.onSubmit}
            timeZone={this.state.timeZone}
          />
        </SiteModal>
      </>
    );
  }
}

const mapDispatchToProps = {
  setPlaybackActiveTime,
  setMultiLiveStreamLoader,
  setKeepAliveRequest,
  resetEvents,
  setPlaybackBufferMeta,
  setMaxPeerCountForDevice,
  setOfferrecived,
  setIsOnMultiCameraViewPage,
};

const mapStoreStateToProps = (state) => ({
  account: state.accounts,
  streaming: state.streaming,
  views: state.views,
});

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps,
)(DeviceWallTimeline);
