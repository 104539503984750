import { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from '../../../../components/forms';
import {
  DeviceStatusEnum,
  DeviceTypeEnum,
  Utils,
  constants,
} from '../../../../helpers';
import { addDeviceToHubAPI } from '../addDeviceAPI';
import { PrimaryButton } from '../../../../components/common';
import DummyImageIcon from '../../../../assets/images/dummy-snapshot.svg';
import { getLocationsData } from '../../../../store/reducers/AccountReducer';
import axios from 'axios';
import {
  getManufacturerSKUList,
  setAddedAndClaimedDeviceId,
} from '../../../../store/reducers/NVRDeviceReducer';
import { useEffect } from 'react';
import { getAllScannedDevices } from '../../../../store/NVRDeviceStoreIDB';
import { observerInstance } from '../../../../store/indexDB/observer';
import useDebouncedCallback from '../../../../hooks/useDebouncedCallback';
import { getOrgInfo } from '../../../../store/reducers/OrganizationsReducer';

const AddAndUpdateDeviceControl = ({
  selectedDevice,
  nvrDeviceData,
  hubId,
  isAddButtonDisabled,
  deviceSelectHandler,
  selectedDeviceLoaderHandler,
  errorMsgHandler,
  fetchDeviceListForOrg,
  devicesListOfCurrOrgList,
  updateDeviceHandler,
  scanDeviceRequestHandler,
}) => {
  const formikRef = useRef();
  const orgInfo = useSelector(getOrgInfo);
  const [allScannedDevicesList, setAllScannedDevicesList] = useState();
  const allLocations = useSelector(getLocationsData);
  const manufacturerSKUList = useSelector(getManufacturerSKUList);
  const isEditMode =
    selectedDevice?.deviceAuthStatus === DeviceStatusEnum.CON_OFFLINE ||
    selectedDevice?.deviceAuthStatus === DeviceStatusEnum.ADDED_N_CLAIMED ||
    selectedDevice?.deviceAuthStatus === DeviceStatusEnum.ADDED_NOT_CLAIMED;
  const dispatch = useDispatch();
  const getAreas = () => {
    const location = allLocations?.find(
      (location) => location.locationId === nvrDeviceData?.locationId,
    );
    return location?.areas;
  };
  const locationsData = getAreas();

  const validateAuthenticated = Yup.object({
    deviceName: Yup.string()
      .max(40, constants.VIEW_NAME_MAX_LENGTH_MESSAGE)
      .required(constants.DEVICE_NAME_REQUIRED_ERROR_MESSAGE),
    areaId: Yup.string().required(),
  });

  const loadAllScannedDevices = useCallback(async () => {
    const allDevices = await getAllScannedDevices();
    setAllScannedDevicesList(allDevices || []);
  }, []);
  const debouncedLoadAllScannedDevices = useDebouncedCallback(
    loadAllScannedDevices,
    1000,
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'allScannedDevices') {
        await debouncedLoadAllScannedDevices();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadAllScannedDevices();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadAllScannedDevices]);

  useEffect(() => {
    formikRef?.current?.resetForm();
  }, [selectedDevice?.channel]);

  const getKeysArray = (list) => {
    const keys = Object.keys(list || {});
    return keys.filter(function (key) {
      return list[key];
    });
  };

  const getChannelPayload = (device) => ({
    channel: device.channel,
    serialNo: device.serialNumber,
    macAddress: device.macAddress.replaceAll('-', ':'),
    manufacturer:
      device?.manufacturer ||
      manufacturerSKUList?.find((item) => item.model === device.modelNumber)
        ?.manufacturer,
    model: device.modelNumber,
    locationId: nvrDeviceData?.locationId,
    timezone: nvrDeviceData?.properties?.['timezone'],
    deviceType: device.deviceType || 'onvifcam',
    requestedApps: getKeysArray(nvrDeviceData?.apps),
  });

  const handleAddAndClaimDeviceToHub = async (values) => {
    try {
      errorMsgHandler('');
      let payload = [];
      if (nvrDeviceData?.deviceType?.toUpperCase() === DeviceTypeEnum.NVR) {
        const parentDevice = allScannedDevicesList.find(
          (a) =>
            a.macAddress &&
            selectedDevice.macAddress &&
            a.macAddress.toUpperCase() ===
              selectedDevice.macAddress.toUpperCase(),
        );
        payload = [
          {
            ...getChannelPayload(parentDevice),
            deviceName: parentDevice.serialNumber,
            areaId: values.areaId,
            channels: [
              {
                ...getChannelPayload(selectedDevice),
                deviceName: values.deviceName || selectedDevice.serialNumber,
                areaId: values.areaId,
              },
            ],
          },
        ];
      } else {
        payload = [
          {
            ...getChannelPayload(selectedDevice),
            deviceName: values.deviceName || selectedDevice.serialNumber,
            areaId: values.areaId,
            channels: selectedDevice?.channels?.map((ch) => ({
              ...getChannelPayload(ch),
              deviceName: ch.serialNumber,
              areaId: values.areaId,
            })),
          },
        ];
      }
      selectedDeviceLoaderHandler(true);
      const deviceResponse = await addDeviceToHubAPI(orgInfo, payload, hubId);
      if (deviceResponse?.meta?.code === 200) {
        const data = deviceResponse?.data;
        const device = data.devices?.[0].device;
        const channels = data.devices?.[0].channels;
        const devicesToUpdate = [device, ...channels];
        if (device) {
          const newEntries = devicesToUpdate
            .map((d) => {
              const foundDevice = devicesListOfCurrOrgList.find(
                (x) =>
                  x.macAddress === d.macAddress && x.serialNo === d.serialNo,
              );
              return foundDevice ? undefined : d;
            })
            ?.filter((x) => x);
          const updatedDevices = [
            ...devicesListOfCurrOrgList,
            ...newEntries,
          ].map((d) => {
            const foundDevice = devicesToUpdate.find(
              (x) => x.macAddress === d.macAddress && x.serialNo === d.serialNo,
            );
            return foundDevice ? foundDevice : d;
          });
          dispatch(setAddedAndClaimedDeviceId(channels?.[0]?.deviceId));
          // await setDevicesListOfCurrOrg(updatedDevices);
          updateDeviceHandler(updatedDevices);
          // deviceSelectHandler(device.deviceId);
        }
      } else {
        errorMsgHandler(deviceResponse?.userMsg);
      }
      selectedDeviceLoaderHandler(false);
    } catch (error) {
      Utils.vmsLogger().error('ERROR: ', error);
      selectedDeviceLoaderHandler(false);
    }
  };

  const handleUpdateDeviceToHub = async (values) => {
    try {
      errorMsgHandler('');
      selectedDeviceLoaderHandler(true);
      const orgID = nvrDeviceData?.orgId;
      const locationId = selectedDevice?.locationId;
      const areaId = selectedDevice?.areaId;
      const reqBody = {
        newAreaId: values?.areaId,
        deviceName: values?.deviceName,
      };
      const res = await axios.put(
        `/device/orgs/${orgID}/locations/${locationId}/areas/${areaId}/devices/${selectedDevice.deviceId}`,
        reqBody,
        Utils.requestHeader(),
      );
      if (res?.data?.meta?.code === 200) {
        const data = res?.data?.data;
        const updatedDevices = devicesListOfCurrOrgList.map((item) => {
          if (item.deviceId === selectedDevice.deviceId) {
            return {
              ...item,
              deviceName: data.deviceName,
              areaId: data.newAreaId,
            };
          } else {
            return item;
          }
        });
        // await setDevicesListOfCurrOrg(updatedDevices);
        updateDeviceHandler(updatedDevices);
      } else {
        errorMsgHandler(res?.data?.meta?.userMsg);
      }
      selectedDeviceLoaderHandler(false);
    } catch (error) {
      selectedDeviceLoaderHandler(false);
      Utils.vmsLogger().error('ERROR: ', error);
    }
  };

  const handleRemoveClick = (deviceId) => {
    removeDevice(deviceId);
  };

  const removeDevice = async (deviceId) => {
    try {
      errorMsgHandler('');
      selectedDeviceLoaderHandler(true);
      const res = await axios.delete(
        `/device/orgs/${orgInfo?.orgId}/devices/${deviceId}/v2`,
        Utils.requestHeader(),
      );
      if (
        parseInt(res?.status) === 202 ||
        parseInt(res?.data?.meta?.code) === 200
      ) {
        deviceSelectHandler(deviceId);
        dispatch(setAddedAndClaimedDeviceId(deviceId));
        fetchDeviceListForOrg();
        scanDeviceRequestHandler();
      } else {
        errorMsgHandler(res?.data?.meta?.userMsg);
        selectedDeviceLoaderHandler(false);
      }
    } catch (error) {
      selectedDeviceLoaderHandler(false);
      Utils.vmsLogger().error(error);
    }
  };

  const getAreaId = () => {
    if (isEditMode) {
      return selectedDevice?.areaId;
    } else {
      const location = locationsData?.find((l) => l.isDefault);
      return location ? location?.areaId : locationsData?.[0]?.areaId || '';
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        deviceName: isEditMode ? selectedDevice.deviceName : '',
        areaId: getAreaId(),
      }}
      validationSchema={validateAuthenticated}
      onSubmit={async (values) => {
        if (isEditMode) {
          handleUpdateDeviceToHub(values);
        } else {
          handleAddAndClaimDeviceToHub(values);
        }
      }}
      innerRef={formikRef}
    >
      {({ dirty, isValid, values, handleSubmit, handleChange }) => (
        <Form className="modal-form" onSubmit={handleSubmit}>
          <div className="device-image-wrapper">
            <img src={DummyImageIcon} alt="DummyImageIcon" />
          </div>
          <div className="fieldTitle mb-1 mt-3">
            {constants.DEVICES_DEVICE_NAME_TEXT}
          </div>
          <div className="">
            <TextField
              placeholder={constants.DEVICES_DEVICE_NAME_TEXT}
              name="deviceName"
              type="text"
              removebottommargin="true"
              removetopmargin="true"
              onChange={handleChange}
            />
          </div>
          <div className="fieldTitle mb-1 mt-3">
            {constants.DEVICES_ASSIGN_DEVICE_AREA_TEXT}
          </div>
          <div className="radio-wrapper">
            {locationsData?.map((location) => (
              <div className="radiotitle" key={location.areaId}>
                <div className="area-name">{location.areaName}</div>
                <div className="makingBig">
                  <Field
                    type="radio"
                    name="areaId"
                    value={location.areaId}
                    onChange={handleChange}
                  />
                </div>
              </div>
            ))}
          </div>
          <PrimaryButton
            className="mt-4"
            fontSize="14px"
            backgroundColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--primary_40')}
            height="44px"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--brand_white',
            )}
            disabled={
              !dirty ||
              !values.deviceName ||
              !isValid ||
              isAddButtonDisabled ||
              selectedDevice?.deviceAuthStatus ===
                DeviceStatusEnum.ADDED_NOT_CLAIMED
            }
          >
            {selectedDevice?.deviceAuthStatus === DeviceStatusEnum.AUTHENTICATED
              ? constants.ADD_AND_CLAIM_DEVICE_BUTTON_TEXT
              : constants.DEVICES_UPDATE_DEVICE_BUTTON_TEXT}
          </PrimaryButton>
          {selectedDevice?.deviceAuthStatus !==
            DeviceStatusEnum.AUTHENTICATED && (
            <PrimaryButton
              className="adddevice-btn"
              fontSize="0.875rem"
              backgroundColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--brand_white')}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--error_48')}
              height="44px"
              borderColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--error_48')}
              hoverBorderColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--error_48')}
              color={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--error_48')}
              type="button"
              onClick={() => handleRemoveClick(selectedDevice.deviceId)}
            >
              {constants.DEVICES_REMOVE_DEVICE_BUTTON}
            </PrimaryButton>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default AddAndUpdateDeviceControl;
