import React, { useEffect, useState } from 'react';
import 'moment-timezone';
import '../../assets/css/timeline.scss';
import { useDispatch } from 'react-redux';
import { setOfferrecived } from '../../store/reducers/StreamingReducer';
import useEventsStore from '../../store/EventsStore';
import { XCircleFill } from 'react-bootstrap-icons';
import RegionSelect from 'react-region-select';
import { constants, Utils } from '../../helpers';
import { ReactComponent as CloudDownloadOff } from '../../assets/images/CloudDownloadOff.svg';

const PlaybackControlsView = ({
  showFetchImageGrid,
  hideFetchImageGrid,
  callCountAPI,
  deviceId,
  deviceInformation,
}) => {
  const dispatch = useDispatch();
  // Grid related state
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegions] = useState();
  const [finalCoordinates, setFinalCoordinates] = useState({});
  const [isRegionChanging, setIsRegionChanging] = useState(true);
  const [videoWidth, setVideoWidth] = useState();
  const [refResolution, setRefResolution] = useState();
  const {
    setSnapshotCoordinate,
    setSelectedRegion,
    getSelectedRegion,
    setRegion,
    getRegion,
  } = useEventsStore();
  // const [devicesDetails, setDevicesDetails] = useState([]);
  const [deviceDetails, setDeviceDetails] = useState(deviceInformation);
  const videoElementPlayback = document.getElementById(`playback-video`);

  useEffect(() => {
    dispatch(setOfferrecived(false));
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (selectedRegion && refResolution && videoElementPlayback) {
      const divResolutionWidth =
        document.getElementById('playback-canvas').offsetWidth;
      const divResolutionHeight =
        document.getElementById('playback-canvas').offsetHeight;
      const proportionWidth = parseInt(refResolution[0]) / divResolutionWidth; // guess ref resolution is 2592 x 1520
      const proportionHeight = parseInt(refResolution[1]) / divResolutionHeight; // guess ref resolution is 2592 x 1520

      const bottomLeftX =
        proportionWidth * ((divResolutionWidth / 100) * selectedRegion[0].x);
      const bottomLeftY =
        proportionHeight *
        ((divResolutionHeight / 100) *
          (selectedRegion[0].y + selectedRegion[0].height));
      const topRightX =
        proportionWidth *
        ((divResolutionWidth / 100) *
          (selectedRegion[0].x + selectedRegion[0].width));
      const topRightY =
        proportionHeight * ((divResolutionHeight / 100) * selectedRegion[0].y);

      const finalCoordinateObj = {
        bottomLeftX,
        bottomLeftY,
        topRightX,
        topRightY,
      };
      setFinalCoordinates(finalCoordinateObj);
    }
  }, [selectedRegion, videoWidth, videoElementPlayback]);

  useEffect(() => {
    if (!isRegionChanging && Object.keys(finalCoordinates).length > 0) {
      setSnapshotCoordinate(finalCoordinates);
      callCountAPI();
    } else {
      if (!isRegionChanging) {
        callCountAPI();
      }
    }
  }, [isRegionChanging, finalCoordinates]);

  useEffect(() => {
    if (deviceId && deviceDetails) {
      const selectedChildDevice = deviceDetails;
      if (selectedChildDevice) {
        const referenceResolution =
          selectedChildDevice?.properties?.['reference-resolution'];
        if (referenceResolution) {
          setRefResolution(referenceResolution.split('x'));
        }
      }
    }
  }, [deviceDetails]);

  useEffect(() => {
    if (showFetchImageGrid === false) {
      setSelectedRegions(null);
      setRegions([]);
      setFinalCoordinates({});
    } else {
      const ele = document.getElementById('playback-canvas');
      if (ele) {
        const divResolutionWidth = ele.offsetWidth;
        setVideoWidth(divResolutionWidth);
      }
    }
  }, [showFetchImageGrid, videoElementPlayback?.videoWidth]);

  const handleWindowSizeChange = () => {
    try {
      const divElement = document.getElementById('playback-canvas');
      if (divElement) {
        const divResolutionWidth = divElement.offsetWidth;
        setVideoWidth(divResolutionWidth);
      }
    } catch (err) {
      Utils.vmsLogger().log(err);
    }
  };

  const resetObjects = () => {
    hideFetchImageGrid();
    setSelectedRegions(null);
    setSelectedRegion(null);
    setRegions([]);
    setRegion([]);
    setFinalCoordinates({});
    setSnapshotCoordinate({});
  };

  const onChange = (regions) => {
    setRegions(regions);
    setSelectedRegions(regions);
    setSelectedRegion(regions);
    setRegion(regions);
    handleRemoveSelected();
  };

  const handleRemoveSelected = () => {
    if (selectedRegion) {
      const updatedRegions = regions?.filter(
        (region) => region !== selectedRegion,
      );
      setRegions(updatedRegions);
      setSelectedRegions(null);
    }
  };

  const regionRenderer = (regionProps) => {
    if (!regionProps.isChanging) {
      if (isRegionChanging) {
        setIsRegionChanging(false);
      }
      return (
        <div class="iconset">
          <XCircleFill
            size={24}
            onClick={resetObjects}
            style={{ cursor: 'pointer' }}
          />
        </div>
      );
    } else {
      setIsRegionChanging(true);
    }
  };

  return (
    <div id="remote-view-wrapper" className="remote-view-wrapper">
      {/* <video
          id="video2"
          // width="764"
          // height="450"
          ref={remoteVideoRef}
          autoPlay={true}
          playsInline={true}
          muted={muteAudio}
        />
        <audio
          id="audio"
          ref={remoteAudioRef}
          autoPlay={true}
          playsInline={true}
          controls={false}
          muted={muteAudio}
        ></audio> */}
      <canvas
        id="playback-canvas"
        width={videoElementPlayback?.videoWidth}
        height={videoElementPlayback?.videoHeight}
      ></canvas>
      <div id="noVideo" className="no-video-edge-ui">
        <CloudDownloadOff height="40" width="40" />
        <span className="no-video-edge-text">
          {constants.PLAYBACK_NO_VIDEO_TEXT}
        </span>
      </div>
      {videoElementPlayback &&
        videoElementPlayback?.videoWidth &&
        showFetchImageGrid && (
          <div className="region-select-main">
            <RegionSelect
              id="region-select"
              maxRegions={1}
              regions={getRegion()}
              selectedRegion={getSelectedRegion()}
              regionStyle={{
                background: '#ffffff70',
                zIndex: 70,
              }}
              onChange={onChange}
              regionRenderer={regionRenderer}
              style={{
                border: '0px solid black',
                position: 'absolute',
                width: videoWidth,
                height: '-webkit-fill-available',
                display: 'inline-block',
              }}
              constraint={true}
            >
              <div class="grid-image-fetch"></div>
            </RegionSelect>
          </div>
        )}
    </div>
  );
};

export default PlaybackControlsView;
