import { useEffect, useState, useRef, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Row } from 'react-bootstrap';
import axios from 'axios';

import { SiteSpinner, Tags } from '../../../components/common';
import { constants, Utils } from '../../../helpers';
import { manageTagName, replaceManageTagName } from '../../../helpers/enums';
import { useOrganizations } from '../../../store/OrganizationsStore';

import {
  HiOutlineExclamationCircle,
  HiOutlineMinusCircle,
} from 'react-icons/hi';
import './ManageTags.scss';
import { useSelector } from 'react-redux';
import { getCustomerOrgData } from '../../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../../store/indexDB/observer';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';

const MAX_CHARACTERS_ALLOWED = 25;

const ManageTags = ({
  openModal,
  clipCategory = 'IM',
  setAreTagsModified,
  shouldSaveTags = false,
  setShowButtonLoader,
  callback,
  setShowToast,
  setUserMsg,
  tagNameNotValid,
  setTagNameNotValid,
  setTagErrors,
  tagErrors,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [showLoader, setShowLoader] = useState(false);
  const [suppressButtonLoader, setSuppressButtonLoader] = useState(false);
  const [tags, setTags] = useState([]);
  const [systemTags, setSystemTags] = useState([]);
  const [customTags, setCustomTags] = useState([]);
  const [newTag, setNewTag] = useState(null);
  const [newTagCharCount, setNewTagCharCount] = useState(0);
  const [tagNameExists, setTagNameExists] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [isTagRemoved, setIsTagRemoved] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const newTagInputRef = new useRef();
  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );

  // const orgDetails = getCustomerOrgData()[0];
  const [orgDetails, setOrgDetails] = useState();

  useEffect(() => {
    const initialErrors = {};
    tags?.forEach((tag) => {
      initialErrors[tag.tagId] = false;
    });
    setTagErrors(initialErrors);
  }, []);

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setOrgDetails(orgs?.[0] || {});
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000,
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  useEffect(() => {
    const fetchCurrentTags = async () => {
      try {
        setShowLoader(true);

        const res = await axios.get(
          `/partner/orgs/${orgDetails?.orgId}/tags?category=${clipCategory}`,
          Utils.requestHeader(),
        );
        const responseData = res?.data;

        if (responseData?.meta?.code === 200) {
          if (
            Array.isArray(responseData?.data?.tags) &&
            responseData?.data?.tags?.length > 0
          ) {
            const currentTags = responseData?.data?.tags;
            const sysTags = [],
              custTags = [];

            currentTags.forEach((tag) => {
              if (tag?.isReadOnly === true) {
                sysTags.push(tag);
              } else {
                custTags.push(tag);
              }
            });

            setSystemTags(structuredClone(sysTags));
            setCustomTags(structuredClone(custTags));
            setTags(structuredClone(custTags));
          } else {
            setTags([]);
          }
        } else {
        }
      } catch (err) {
      } finally {
        setShowLoader(false);
      }
    };

    if (orgDetails?.orgId) {
      fetchCurrentTags();
    }
  }, [orgDetails?.orgId]);

  useEffect(() => {
    if (shouldSaveTags === true) {
      saveTagsHandler();
    }
  }, [shouldSaveTags]);

  const updateTag = (newName, tagId) => {
    let currentTags = tags;

    if (!tagId || !Array.isArray(currentTags) || currentTags.length < 1) {
      return;
    }

    const tagIndex = currentTags.findIndex((tag) => tag.tagId === tagId);

    if (tagIndex !== -1) {
      currentTags[tagIndex].name = newName;
      setTags(structuredClone(currentTags));
      updateTagsModificationStatus(currentTags);
    }
  };

  const updateTagsModificationStatus = (currentTags, newTagValue) => {
    if (checkAnyTagIsEmpty([...currentTags])) {
      setAreTagsModified && setAreTagsModified(false);
    } else if (
      checkAnyTagIsModified([...currentTags]) ||
      newTagValue ||
      isTagRemoved
    ) {
      setAreTagsModified && setAreTagsModified(true);
    } else {
      setAreTagsModified && setAreTagsModified(false);
    }
  };

  const checkAnyTagIsEmpty = (editedTags) => {
    const emptyNameTags = editedTags?.filter((tag) => !tag?.name);
    return emptyNameTags?.length;
  };

  const checkAnyTagIsModified = (editedTags) => {
    let modifiedTags = [];
    customTags.forEach((customtag) => {
      const editedTag = editedTags?.find(
        (tag) =>
          tag?.tagId === customtag?.tagId && tag?.name !== customtag?.name,
      );
      if (editedTag) {
        modifiedTags.push(modifiedTags);
      }
    });
    return modifiedTags?.length;
  };

  const deleteTag = (tagName) => {
    let currentTags = tags;

    if (!tagName || !Array.isArray(currentTags) || currentTags.length < 1) {
      return;
    }

    const tagIndex = currentTags.findIndex((tag) => tag.name === tagName);

    if (tagIndex !== -1) {
      currentTags.splice(tagIndex, 1);

      setTags(structuredClone(currentTags.length > 0 ? [...currentTags] : []));
      setCustomTags(
        structuredClone(
          currentTags.length > 0
            ? [...currentTags.filter((tag) => tag.isReadOnly === false)]
            : [],
        ),
      );
      setIsModified(true);
      setIsTagRemoved(true);
      setAreTagsModified && setAreTagsModified(true);
    }
  };

  const saveTagsHandler = async () => {
    try {
      if (!suppressButtonLoader) {
        setShowButtonLoader && setShowButtonLoader(true);
      }

      const finalTags =
        tags?.length > 0 || systemTags?.length > 0
          ? [...tags, ...systemTags]
          : [];
      const tagIndex = finalTags.findIndex(
        (tag) => tag.name?.toLowerCase() === newTag?.toLowerCase(),
      );

      if ((newTag && tagIndex === -1) || finalTags.length < 1) {
        if (newTag) {
          finalTags.push({
            name: newTag,
          });
        }
      } else {
        if (newTag) {
          setTagNameExists(true);
          setIsModified(false);
          setAreTagsModified && setAreTagsModified(false);
          throw new Error(`Tag ${newTag} already exists`);
        }
        if (isTagRemoved) {
          setIsModified(true);
        }
      }

      const uniqueTags = [...new Set(finalTags.map((item) => item.name))];

      if (uniqueTags.length !== finalTags?.length) {
        setAreTagsModified && setAreTagsModified(false);
        setShowToast(true);
        setUserMsg(
          constants.INCIDENTS_MANAGE_TAGS_MODAL_EDIT_TAG_ALREADY_EXISTS,
        );
        return;
      }

      const reqBody = {
        tags: finalTags,
      };

      setShowToast(false);
      setUserMsg('');

      let response = await axios.put(
        `/partner/orgs/${orgDetails?.orgId}/tags/${
          clipCategory === 'IM' ? 'incidents' : 'clips'
        }`,
        reqBody,
        Utils.requestHeader(),
      );

      let resData = response?.data;

      if (resData?.meta.code === 200) {
        const nonSystemTags = resData?.data?.tags?.filter(
          (tag) => tag?.isReadOnly === false,
        );
        setCustomTags(structuredClone(nonSystemTags));
        setTags(structuredClone(nonSystemTags));
        setNewTag(null);
        setNewTagCharCount(0);
        setIsModified(false);
        setIsTagRemoved(false);
        newTagInputRef.current.value = null;
        setAreTagsModified && setAreTagsModified(false);
      } else {
        setShowToast(true);
        setUserMsg(resData?.meta.userMsg);
        Utils.vmsLogger().error(
          `ERROR: ${resData?.meta?.code} - ${resData?.meta?.desc}`,
        );
      }
    } catch (err) {
      Utils.vmsLogger().error(err);
      setShowToast(true);
      setUserMsg(err.msg);
    } finally {
      setSuppressButtonLoader(false);
      setShowButtonLoader && setShowButtonLoader(false);
      callback && callback(false);
    }
  };

  const getCustomTagDescription = () => {
    return clipCategory === 'IM'
      ? constants.INCIDENTS_MANAGE_TAGS_MODAL_INCIDENTS_CUSTOM_TAGS_DESCRIPTION
      : constants.INCIDENTS_MANAGE_TAGS_MODAL_CLIPS_CUSTOM_TAGS_DESCRIPTION;
  };

  return (
    <>
      {!formSubmitted && (
        <form
          onSubmit={handleSubmit(saveTagsHandler)}
          className="manage-tags-form"
        >
          <div className="mb-3 manage-tags-modal-description">
            {constants.INCIDENTS_MANAGE_TAGS_MODAL_DESCRIPTION}
          </div>
          <Container>
            {showLoader ? (
              <div className="d-flex justify-content-around">
                <SiteSpinner height="50px" width="50px" />
              </div>
            ) : (
              <>
                {/* System Tags */}
                {clipCategory === 'IM' && (
                  <Row className="mb-3">
                    <div className="px-0 manage-tags-modal-system-tags-title">
                      {constants.INCIDENTS_MANAGE_TAGS_MODAL_SYSTEM_TAGS_TITLE}
                      <div className="manage-tags-modal-system-tags-description">
                        {
                          constants.INCIDENTS_MANAGE_TAGS_MODAL_SYSTEM_TAGS_DESCRIPTION
                        }
                      </div>
                    </div>
                    <Tags
                      tagCategory={clipCategory}
                      systemTagsOnly={true}
                      selectedTagsOnly={true}
                      preSelectedTags={systemTags}
                    />
                  </Row>
                )}
                {/* Custom Tags */}
                {clipCategory === 'IM' && (
                  <Row>
                    <div className="mb-3 px-0 manage-tags-modal-custom-tags-title">
                      {constants.INCIDENTS_MANAGE_TAGS_MODAL_CUSTOM_TAGS_TITLE}
                      <div className="manage-tags-modal-custom-tags-description">
                        {
                          constants.INCIDENTS_MANAGE_TAGS_MODAL_CUSTOM_TAGS_DESCRIPTION
                        }
                      </div>
                    </div>
                  </Row>
                )}
                {Array.isArray(tags) &&
                  tags.map((tag) => (
                    <Row key={tag?.tagId}>
                      <div className="d-flex px-0 justify-content-start align-items-center">
                        <div className="mb-3 px-0 tag-input-wrapper">
                          <input
                            onChange={(e) => {
                              let value = e?.target?.value;
                              e.preventDefault();
                              setTagErrors((prev) => ({
                                ...prev,
                                [tag.tagId]: false,
                              }));
                              const isSpecialChar = manageTagName.test(value);
                              const isSpace = value?.includes(' ');
                              Utils.vmsLogger().log(isSpecialChar, isSpace);
                              if (isSpecialChar || isSpace) {
                                setTagErrors((prev) => ({
                                  ...prev,
                                  [tag.tagId]: true,
                                }));
                              } else {
                                setTagErrors((prev) => ({
                                  ...prev,
                                  [tag.tagId]: false,
                                }));
                              }
                              if (tag.count < 1) {
                                updateTag(value, tag.tagId);
                                updateTagsModificationStatus(tags, value);
                              }
                            }}
                            placeholder={
                              constants.INCIDENTS_MANAGE_TAGS_MODAL_ENTER_TAG_NAME_PLACEHOLDER
                            }
                            className={`manage-tags-input tag-input ${
                              tag.count > 0 ? 'read-only' : ''
                            }`}
                            value={tag?.name}
                            readOnly={tag.count > 0}
                          />
                          <div className="inline-message-container">
                            <div className="d-flex justify-content-start align-items-center">
                              <HiOutlineExclamationCircle
                                className={`error-icon${
                                  !tagNameExists && !errors?.tag
                                    ? ' hidden'
                                    : ''
                                }`}
                              />
                              {tagErrors[tag.tagId] || false ? (
                                <div className="error-inline-message">
                                  {
                                    constants.INCIDENTS_MANAGE_TAGS_MODAL_TAG_SPECAIL_CHAR_NOT_ALLOWED
                                  }
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div
                          className="mb-3 px-0 text-center delete-btn-wrapper"
                          onClick={(e) => {
                            e?.preventDefault();
                            if (tag.count < 1) {
                              deleteTag(tag.name);
                            }
                          }}
                        >
                          <HiOutlineMinusCircle
                            className={`delete-btn-icon ${
                              tag.count > 0 ? 'read-only' : ''
                            }`}
                            color={
                              tag.count > 0
                                ? getComputedStyle(
                                    document.documentElement,
                                  ).getPropertyValue('--grayscale_56')
                                : getComputedStyle(
                                    document.documentElement,
                                  ).getPropertyValue('--error_64')
                            }
                            size={20}
                          />
                        </div>
                      </div>
                    </Row>
                  ))}
                {/* New Tag */}
                <Row>
                  <div className="mb-3 px-0 tag-input-full-wrapper">
                    <input
                      {...register('tag', { required: false })}
                      ref={newTagInputRef}
                      onChange={(e) => {
                        e.preventDefault();
                        let value = e.target.value;

                        setTagNameExists(false);
                        setTagNameNotValid(false);

                        if (!value) {
                          setIsModified(false);
                          setNewTag(null);
                          updateTagsModificationStatus(tags);
                          return;
                        }
                        const isSpecialChar = manageTagName.test(value);
                        const isSpace = value?.includes(' ');
                        if (isSpecialChar || isSpace) {
                          setTagNameNotValid(true);
                        } else {
                          setTagNameNotValid(false);
                        }
                        if (value.length <= MAX_CHARACTERS_ALLOWED) {
                          setNewTag(value);
                          setNewTagCharCount(value.length);
                          setIsModified(true);
                          updateTagsModificationStatus(tags, value);
                        }
                      }}
                      maxLength={MAX_CHARACTERS_ALLOWED}
                      placeholder={
                        constants.INCIDENTS_MANAGE_TAGS_MODAL_ENTER_TAG_NAME_PLACEHOLDER
                      }
                      className={`manage-tags-input tag-input-full${
                        tagNameExists ? ' error-input' : ''
                      }`}
                    />
                    <div className="inline-message-container">
                      <div className="d-flex justify-content-start align-items-center">
                        <HiOutlineExclamationCircle
                          className={`error-icon${
                            !tagNameExists && !errors?.tag ? ' hidden' : ''
                          }`}
                        />
                        {errors.tag && (
                          <div className="error-inline-message">
                            {
                              constants.INCIDENTS_MANAGE_TAGS_MODAL_VALID_TAG_NAME_ERROR
                            }
                          </div>
                        )}
                        {tagNameExists && (
                          <div className="error-inline-message">
                            {
                              constants.INCIDENTS_MANAGE_TAGS_MODAL_TAG_ALREADY_EXISTS
                            }
                          </div>
                        )}
                        {tagNameNotValid && (
                          <div className="error-inline-message">
                            {
                              constants.INCIDENTS_MANAGE_TAGS_MODAL_TAG_SPECAIL_CHAR_NOT_ALLOWED
                            }
                          </div>
                        )}
                        &nbsp;
                      </div>
                      <div className="px-0 manage-tags-input-char-limit text-right">
                        {newTagCharCount + `/${MAX_CHARACTERS_ALLOWED}`}
                      </div>
                    </div>
                  </div>
                </Row>
              </>
            )}
          </Container>
        </form>
      )}
    </>
  );
};

export default ManageTags;
