import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Col, Row } from 'react-bootstrap';
import { HiOutlineCalendar } from 'react-icons/hi';
import axios from 'axios';
import moment from 'moment';

import {
  ListBlock,
  PrimaryButton,
  SiteModal,
  SiteSpinner,
} from '../../../../components/common';
import {
  constants,
  LicenseStatuses,
  OfferingTypeEnum,
  Utils,
  AppDefaults,
} from '../../../../helpers';
import useLicensesStore from '../../../../store/LicensesStore';
import AddLicenseForm from './AddLicenseForm';
import DetachLicenseForm from './DetachLicenseForm';

import './LicensesBlock.scss';
import { getOrgInfo } from '../../../../store/reducers/OrganizationsReducer';
import { getDeviceInformation } from '../../../../store/reducers/AccountReducer';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { HiOutlineCheckCircle } from 'react-icons/hi';
import { PiWarning } from 'react-icons/pi';
import { useSelector } from 'react-redux';
import {
  LicenseSuccesIcon,
  LicenseSuccesMarkIcon,
} from '../../../../assets/images';
import { LicenseType, recordingTypes } from '../../../../helpers/enums';

const DATE_FORMAT = 'DD MMM YYYY';

const LicensesBlock = ({
  reloadCall,
  orgId,
  orgName,
  deviceId,
  deviceName,
  deviceTimeZone,
  showRemoveModal,
  hideRemoveModalCallback,
  deviceInfos,
  hasManagePermission,
  canAttachLicense,
  mdcDeviceData,
}) => {
  const navigate = useNavigate();
  const [deviceLicenses, setDeviceLicenses] = useState([]);
  const [availableLicenses, setAvailableLicenses] = useState([]);
  const [licenseName, setLicenseName] = useState('');
  const [licenseKey, setLicenseKey] = useState('');
  const [licenseRecType, setLicenseRecType] = useState('');
  const [showAddLicenseModal, setShowAddLicenseModal] = useState(false);
  const [showTypeOfLicense, setShowTypeOfLicense] = useState(false);
  const [isDisplayAddOn, setIsDisplayAddOn] = useState(false);
  const [showDetachLicenseModal, setShowDetachLicenseModal] =
    useState(showRemoveModal);
  const [showSuccessMessageModal, setShowSuccessMessageModal] = useState(false);
  const { setLicenses } = useLicensesStore();
  const [showLoader, setShowLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [licenseKeyArg, setLicenseKeyArg] = useState(null);
  const [activationDateArg, setActivationDateArg] = useState(null);
  const orgInfo = useSelector(getOrgInfo);
  const deviceDetails = useSelector(getDeviceInformation);
  const [userMsgArg, setUserMsgArg] = useState('');
  const [skuListLoaderArg, setSKUListLoaderArg] = useState([]);

  let lastExpirationDate;
  const showAttachLicenseButton = () => {
    return canAttachLicense ? (
      <PrimaryButton
        type="button"
        width="100%"
        className="device-btn-space attach-new-license-btn"
        onClick={() => {
          const activeLicense = findActiveLicense(availableLicenses);
          if (
            activeLicense &&
            activeLicense?.recordingType === recordingTypes.EDGE_RECORDING &&
            activeLicense?.offeringType === OfferingTypeEnum?.PAID
          ) {
            setIsDisplayAddOn(false);
            setShowTypeOfLicense(true);
          } else {
            setShowAddLicenseModal(true);
          }
        }}
      >
        {constants.DEVICES_TAB_LICENSES_ATTACH_LICENSE_LABEL}
      </PrimaryButton>
    ) : (
      <Button
        variant="primary"
        width="100%"
        className="attach-license-btn attach-new-license-btn disabled"
      >
        {constants.DEVICES_TAB_LICENSES_ATTACH_LICENSE_LABEL}
      </Button>
    );
  };

  const findActiveLicense = (licenses) => {
    return licenses.find(
      (license) =>
        license?.licenseStatus === LicenseStatuses.ACTIVE &&
        license?.licenseType === LicenseType.REGULAR &&
        license?.deviceId === deviceId,
    );
  };

  useEffect(() => {
    fetchLicenses();
  }, []);

  const fetchLicenses = async () => {
    setIsLoading(true);
    let licensesLoadSuccess, licensesData;
    const serviceIds = 'vms-service';
    try {
      const response = await axios.get(
        `/user/orgs/${orgId}/devices/${deviceId}/licenses?&page=0&size=100&serviceIds=${serviceIds}`,
      );
      licensesLoadSuccess = response?.data?.meta?.code;
      if (response?.data?.data?.licenses) {
        licensesData = response?.data?.data?.licenses;
      }
    } catch (error) {
      setIsLoading(false);
      Utils.vmsLogger().log('Error fetching timeline data:', error);
    }

    if (licensesLoadSuccess === 200 && licensesData) {
      await new Promise((resolve, reject) => {
        const currentTime = new Date().getTime();

        const filteredCustOrgLicenses = licensesData?.filter(
          (license) => license?.deviceId === deviceId,
        );
        const filterList = [...filteredCustOrgLicenses].sort(
          (a, b) => a.expiryDate - b.expiryDate,
        );
        const finalList = filterList?.filter(
          (license) =>
            !(
              license?.offeringType === OfferingTypeEnum.ONBOARDING &&
              license?.licenseStatus === LicenseStatuses.EXPIRED
            ) &&
            !(
              license?.offeringType === OfferingTypeEnum.EVAL &&
              license?.licenseStatus === LicenseStatuses.EXPIRED
            ),
        );
        const deviceLicenses = [...finalList];
        setAvailableLicenses([...licensesData]);
        setDeviceLicenses([...deviceLicenses]);
        resolve();
        setIsLoading(false);
      });
    } else {
      setAvailableLicenses([]);
      setDeviceLicenses([]);
      setIsLoading(false);
    }
  };

  const detachLicenseHandler = (
    detachLicenseName,
    detachLicenseKey,
    licenseType,
  ) => {
    if (!detachLicenseName || !detachLicenseKey) return;

    setLicenseName(detachLicenseName);
    setLicenseKey(detachLicenseKey);
    if (licenseType) setLicenseRecType(licenseType);
    setShowDetachLicenseModal(true);
  };

  const calculateAdjustedExpiryDate = (deviceLicense) => {
    const adjustedDate = moment(
      deviceLicense.expiryDate -
        deviceLicense.gracePeriod * AppDefaults.DAY_IN_MILLISECONDS,
    );
    return adjustedDate.format(DATE_FORMAT);
  };

  const getRemainingDays = (license) => {
    const expiryDate = license.expiryDate ? parseInt(license.expiryDate) : 0;
    let date = new Date(expiryDate);
    date.setDate(date.getDate() - (license.gracePeriod || 0));
    const currentDate = new Date();
    const timeDifference = date - currentDate;
    const diffInDays = Math.floor(
      timeDifference / AppDefaults.DAY_IN_MILLISECONDS,
    );
    const result = diffInDays <= 0 || isNaN(diffInDays) ? 0 : diffInDays;
    return result;
  };

  const showExpiringInLabel = (deviceLicense) => {
    return (
      deviceLicense?.licenseStatus !== LicenseStatuses.EXPIRED &&
      (getRemainingDays(deviceLicense) === 0 ||
        getRemainingDays(deviceLicense) <= deviceLicense?.expiringSoonInDays)
    );
  };

  const checkEvalOrOnboardingLicense = (deviceLicense) => {
    return (
      deviceLicense?.offeringType !== OfferingTypeEnum.ONBOARDING &&
      deviceLicense?.offeringType !== OfferingTypeEnum.EVAL
    );
  };

  const getLicenseStatusValues = (deviceLicense) => {
    return (
      <Row
        className={
          checkEvalOrOnboardingLicense(deviceLicense)
            ? 'license-prop-value'
            : 'license-prop-value-eval-onabording'
        }
      >
        <div>
          {(getRemainingDays(deviceLicense) === 0 ||
            deviceLicense?.licenseStatus === LicenseStatuses.EXPIRED) &&
            checkEvalOrOnboardingLicense(deviceLicense) && (
              <HiOutlineExclamationCircle className="license-expiring-soon-icon-style error-icon-style" />
            )}
          {getRemainingDays(deviceLicense) > 0 &&
            getRemainingDays(deviceLicense) <=
              deviceLicense?.expiringSoonInDays &&
            checkEvalOrOnboardingLicense(deviceLicense) && (
              <HiOutlineExclamationCircle className="license-expiring-soon-icon-style expiring-soon-icon-style" />
            )}
          {getRemainingDays(deviceLicense) >
            deviceLicense?.expiringSoonInDays &&
            checkEvalOrOnboardingLicense(deviceLicense) && (
              <HiOutlineCheckCircle className="license-expiring-soon-icon-style success-icon-style" />
            )}
          {deviceLicense?.licenseStatus === LicenseStatuses.EXPIRED ? (
            constants.DEVICES_EXPIRED_LABEL_DEVICE_STATUS
          ) : (
            <>
              {showExpiringInLabel(deviceLicense) ? (
                <>
                  {constants.DEVICES_TAB_LICENSES_LICENSE_EXPIRING_IN_LABEL}{' '}
                  {getRemainingDays(deviceLicense)}{' '}
                  {constants.DEVICES_TAB_LICENSES_LICENSE_EXPIRING_DAYS_LABEL}
                </>
              ) : (
                <>
                  {getRemainingDays(deviceLicense)}{' '}
                  {
                    constants.DEVICES_TAB_LICENSES_LICENSE_EXPIRING_DAYS_REMAINING_LABEL
                  }
                </>
              )}
            </>
          )}
        </div>
      </Row>
    );
  };

  const getLicenseExpirationDate = (deviceLicense) => {
    return (
      <div>
        {/* icon area starts */}
        {getRemainingDays(deviceLicense) === 0 ? (
          deviceLicense?.licenseStatus === LicenseStatuses.EXPIRED ? (
            <HiOutlineCalendar
              size={20}
              className="license-expiring-soon-icon-style"
            />
          ) : checkEvalOrOnboardingLicense(deviceLicense) ? (
            <PiWarning className="license-expiring-soon-icon-style warning-icon-style" />
          ) : (
            <HiOutlineCalendar
              size={20}
              className="license-expiring-soon-icon-style"
            />
          )
        ) : (
          getRemainingDays(deviceLicense) > 0 && (
            <HiOutlineCalendar
              size={20}
              className="license-expiring-soon-icon-style"
            />
          )
        )}
        {/* Icon area ends */}
        {/* Label area starts */}
        <span
          className={
            getRemainingDays(deviceLicense) === 0
              ? deviceLicense?.licenseStatus === LicenseStatuses.EXPIRED
                ? 'license-prop-date-value'
                : 'license-warning-prop-date-value'
              : 'license-prop-date-value'
          }
        >
          {calculateAdjustedExpiryDate(deviceLicense)}
        </span>
        {/* Label area ends */}
      </div>
    );
  };

  const addLicenseToDevice = async () => {
    setUserMsgArg('');

    try {
      const reqBody = {
        licenses: [
          {
            deviceId: deviceId,
            licenseKey: licenseKeyArg,
            activeDate: activationDateArg,
          },
        ],
      };
      setShowLoader(true);
      const orgIdAssign = orgId ? orgId : orgInfo?.orgId;
      const res = await axios.put(
        `/user/orgs/${orgIdAssign}/licenses/assign/v2`,
        reqBody,
        Utils.requestHeader(),
      );

      if (res?.data?.meta?.code === 200) {
        await setLicenses(`/user/orgs/${orgId}/licenses`);
        setShowLoader(false);
        fetchLicenses();
        setShowAddLicenseModal(false);
        setShowSuccessMessageModal(true);
        const licenseData = res?.data?.data?.[0];
        if (
          licenseData?.licenseStatus !==
          LicenseStatuses?.ASSIGNED_ACTIVATION_PENDING
        ) {
          setTimeout(() => {
            if (licenseData?.licenseType !== LicenseType?.ADD_ON) {
              reloadCall();
            }
          }, 2000);
        }
      } else {
        setUserMsgArg(res?.data?.meta.userMsg);
      }
    } catch (error) {
      setUserMsgArg(error.msg);
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <>
      <ListBlock
        className="list-block license-block card-header-block-area"
        contentWrapperClass="text-start"
        label={constants.ORG_DASHBOARD_LICENSES_SECTION_TITLE}
      >
        {isLoading ? (
          <div
            className="position-absolute top-70"
            style={{ left: '47%', top: '70%' }}
          >
            <SiteSpinner height="50px" width="50px" />
            <div className="mt-2 text-dark">{constants.LOADING}</div>
          </div>
        ) : (
          <Row>
            <Col>
              {/* to do : change later when work on locations part <Mapimage /> */}
              <ul className="license-features">
                {Array.isArray(deviceLicenses) && deviceLicenses.length > 0 ? (
                  deviceLicenses.map((deviceLicense, dlIndex) => {
                    const today = moment().unix() * 1000;

                    if (!lastExpirationDate) {
                      if (
                        deviceLicense?.offeringType ===
                        OfferingTypeEnum.ONBOARDING
                      ) {
                        lastExpirationDate = today;
                      } else {
                        lastExpirationDate = deviceLicense.expiryDate;
                      }
                    } else if (lastExpirationDate < deviceLicense.expiryDate) {
                      lastExpirationDate = deviceLicense.expiryDate;
                    }

                    return (
                      <li key={`devLicense${dlIndex}`}>
                        <Container>
                          <Row className="license-block-row-style">
                            <div className="Col col-md-8 col-sm-8 col-8">
                              <Row className="license-prop-title">
                                {constants.DEVICES_TAB_LICENSES_LICENSE_TYPE}
                              </Row>
                              <Row className="license-prop-value">
                                {deviceLicense.productDisplayName}
                              </Row>
                            </div>
                            <div className="Col col-md-4 col-sm-4 col-4">
                              <Row className="license-prop-title">
                                {constants.DEVICES_TAB_LICENSES_LICENSE_STATUS}
                              </Row>
                              {getLicenseStatusValues(deviceLicense)}
                            </div>
                          </Row>
                          <Row>
                            <div className="Col col-md-8 col-sm-8 col-8">
                              <Row className="license-prop-title">
                                {
                                  constants.DEVICES_TAB_LICENSES_LICENSE_START_DATE
                                }
                              </Row>
                              <Row className="license-prop-calender-value">
                                <div>
                                  <HiOutlineCalendar
                                    size={20}
                                    className="license-expiring-soon-icon-style"
                                  />
                                  <span className="license-prop-date-value">
                                    {moment(deviceLicense.activeDate).format(
                                      DATE_FORMAT,
                                    )}
                                  </span>
                                </div>
                              </Row>
                            </div>
                            <div className="Col col-md-4 col-sm-4 col-4">
                              <Row className="license-prop-title">
                                {
                                  constants.DEVICES_TAB_LICENSES_LICENSE_EXPIRATION_DATE
                                }
                              </Row>
                              <Row className="license-prop-calender-value">
                                {getLicenseExpirationDate(deviceLicense)}
                              </Row>
                            </div>
                          </Row>
                          <Row>
                            <div className="Col col-md-8 col-sm-8 col-8">
                              <div
                                className={`license-action license-action-style${
                                  deviceLicense?.offeringType ===
                                    OfferingTypeEnum.ONBOARDING ||
                                  !hasManagePermission
                                    ? ' hidden'
                                    : ''
                                }`}
                                onClick={() =>
                                  detachLicenseHandler(
                                    deviceLicense.productDisplayName,
                                    deviceLicense.licenseKey,
                                    deviceLicense?.recordingType ===
                                      'EDGE_RECORDING'
                                      ? 'edgeLicense'
                                      : 'cloudeLicense',
                                  )
                                }
                              >
                                {constants.DEVICES_TAB_LICENSES_DETACH_LICENSE}
                              </div>
                            </div>
                          </Row>
                        </Container>
                      </li>
                    );
                  })
                ) : Array.isArray(availableLicenses) &&
                  availableLicenses.length < 1 ? (
                  <li>
                    <Container>
                      <Row>
                        <Col>
                          <Row>
                            <div className="no-licenses-attached">
                              {
                                constants.DEVICES_TAB_LICENSES_NO_LICENSE_ATTACHED
                              }
                            </div>
                          </Row>
                          <Row>
                            <div className="no-licenses-available">
                              {
                                constants.DEVICES_TAB_LICENSES_NO_LICENSE_AVAILABLE
                              }
                            </div>
                          </Row>
                          {Utils.getModuleStatus()?.PRODUCT_LISTING && (
                            <Row>
                              <div className="purchase-button-container">
                                <PrimaryButton
                                  width="200px"
                                  onClick={() => {
                                    navigate(
                                      `/products/listing.html?orgId=${orgId}&orgName=${orgName}`,
                                    );
                                  }}
                                >
                                  {
                                    constants.DEVICES_TAB_LICENSES_PURCHASE_LICENSE
                                  }
                                </PrimaryButton>
                              </div>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </Container>
                  </li>
                ) : (
                  <li>
                    <Row>
                      <Col>
                        <Row>
                          <div className="no-licenses-attached">
                            {constants.DEVICES_TAB_LICENSES_NO_LICENSE_ATTACHED}
                          </div>
                        </Row>
                        <Row>
                          <div className="no-licenses-available-sub">
                            {
                              constants.DEVICES_TAB_LICENSES_NO_LICENSE_AVAILABLE
                            }
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </li>
                )}
              </ul>
              {showAttachLicenseButton()}
            </Col>
          </Row>
        )}
      </ListBlock>
      {/* MODALS */}

      {/* Attach License Modal */}
      <SiteModal
        modalTitle={Utils.replaceStringValues(
          constants.DEVICES_TAB_LICENSES_MODAL_TITLE,
          '$deviceName',
          deviceName,
        )}
        showModal={showAddLicenseModal}
        hideModal={() => {
          setShowAddLicenseModal(false);
        }}
        modalFooter={
          !skuListLoaderArg && (
            <div
              className="add-license-button-wrapper"
              style={{ width: '100%' }}
            >
              <PrimaryButton
                className="confirm-btn"
                onClick={() => addLicenseToDevice()}
                loader={showLoader}
                disabled={
                  !deviceId || !licenseKeyArg || !activationDateArg
                    ? true
                    : false
                }
              >
                {constants.ORG_DASHBOARD_LICENSES_ADD_LICENSE_CONFIRM_LABEL}
              </PrimaryButton>
            </div>
          )
        }
        classes="add-license-modal attach-license-style"
      >
        <AddLicenseForm
          isDisplayAddOn={isDisplayAddOn}
          mdcDeviceData={mdcDeviceData}
          deviceId={deviceId}
          deviceTimeZone={deviceTimeZone}
          orgId={orgId}
          expiryDate={lastExpirationDate}
          deviceLicenses={deviceLicenses}
          deviceShadow={deviceDetails?.properties}
          availableLicenses={availableLicenses}
          userMsgArg={userMsgArg}
          sendChildItems={(
            showLoader,
            licenseKey,
            activationDate,
            skuListLoader,
            productNames,
          ) => {
            setLicenseKeyArg(licenseKey);
            setActivationDateArg(activationDate);
            setSKUListLoaderArg(skuListLoader);
            setLicenseName(productNames);
          }}
        />
      </SiteModal>
      {/* Detach License */}
      <SiteModal
        modalTitle={Utils.replaceStringValues(
          constants.DEVICES_TAB_LICENSES_DETACH_LICENSE_MODAL_TITLE,
          '$licenseName',
          licenseName,
        )}
        showModal={showDetachLicenseModal}
        hideModal={() => {
          setShowDetachLicenseModal(false);
          if (
            hideRemoveModalCallback &&
            hideRemoveModalCallback.constructor !== String &&
            hideRemoveModalCallback.constructor !== Number &&
            hideRemoveModalCallback.constructor !== Object &&
            hideRemoveModalCallback.constructor !== Array
          ) {
            hideRemoveModalCallback && hideRemoveModalCallback(false);
          }
        }}
        classes="detach-license-modal"
      >
        <DetachLicenseForm
          deviceId={deviceId}
          licenseKey={licenseKey}
          licenseRecType={licenseRecType}
          orgId={orgId}
          callBack={(isDetach) => {
            if (isDetach) {
              setShowDetachLicenseModal(false);
              reloadCall();
            } else {
              fetchLicenses();
            }
            setShowDetachLicenseModal(false);
            hideRemoveModalCallback && hideRemoveModalCallback(false);
          }}
        />
      </SiteModal>
      {/* Success message for attach License */}
      <SiteModal
        modalTitle={Utils.replaceStringValues(
          constants.DEVICES_TAB_LICENSES_LICENSE_ATTACHED,
        )}
        isCenteredTitle={true}
        showModal={showSuccessMessageModal}
        hideModal={() => {
          setShowSuccessMessageModal(false);
        }}
        classes="detach-license-modal"
      >
        <div className="attached-success-message">
          {Utils.replaceStringValues(
            constants.ATTACHED_LICENSE_MESSAGE,
            '$licenseAttachedName',
            licenseName,
          )}{' '}
          {orgInfo?.orgName}.
        </div>
        <div className="d-flex align-items-center justify-content-center attached-license-mark-style">
          <img
            className="LicenseSuccessRoundStyle"
            src={LicenseSuccesIcon}
            alt="success-icon"
          />
          <img
            className="LicenseSuccessMarkStyle"
            src={LicenseSuccesMarkIcon}
            alt="success-mark-icon"
          />
        </div>
        <div className="go-back-btn-style">
          <PrimaryButton
            className="confirm-btn"
            onClick={() => setShowSuccessMessageModal(false)}
            loader={false}
            disabled={false}
          >
            {constants.CLIP_DETAILS_BACK_BUTTON_LABEL}
          </PrimaryButton>
        </div>
      </SiteModal>
      <SiteModal
        modalTitle={constants.TYPE_OF_LICENSE_TEXT}
        showModal={showTypeOfLicense}
        hideModal={() => {
          setIsDisplayAddOn(false);
          setShowTypeOfLicense(false);
        }}
        modalFooter={
          <div className="add-license-button-wrapper" style={{ width: '100%' }}>
            <PrimaryButton
              className="confirm-btn continue-btn"
              onClick={() => {
                setShowTypeOfLicense(false);
                setShowAddLicenseModal(true);
              }}
            >
              {constants.TYPE_0F_LICENSE_CONTINUE}
            </PrimaryButton>
            <PrimaryButton
              variant="outline-danger"
              className="detach-license-button cancel-btn m-t-10 w-600"
              backgroundColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue(
                showLoader ? '--brand_primary' : '--brand_white',
              )}
              color={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--brand_primary')}
              hoverColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--brand_white')}
              loader={showLoader}
              onClick={() => {
                setIsDisplayAddOn(false);
                setShowTypeOfLicense(false);
              }}
            >
              {constants.TYPE_0F_LICENSE_CANCEL}
            </PrimaryButton>
          </div>
        }
        classes="add-license-modal attach-license-style license-type"
      >
        <div className="license-text-holder">
          <div className="display-f">
            <input
              type="radio"
              name="isDisplayAddOn"
              // value={isDisplayAddOn}
              checked={isDisplayAddOn === false}
              onClick={(event) => {
                setIsDisplayAddOn(false);
              }}
              className="license-type-checkbox"
            />
            <div
              onClick={() => setIsDisplayAddOn(false)}
              className="license-type-label m-l-10"
            >
              {constants.EDGE_RECORDING_LICENSE}
            </div>
          </div>
          <div className="display-f m-t-10">
            <input
              type="radio"
              name="isDisplayAddOn"
              // value={isDisplayAddOn}
              checked={isDisplayAddOn === true}
              onClick={(event) => {
                setIsDisplayAddOn(true);
              }}
              className="license-type-checkbox"
            />
            <div
              onClick={() => setIsDisplayAddOn(true)}
              className="license-type-label m-l-10"
            >
              {constants.CLOUD_BACKUP_LICENSE}
            </div>
          </div>
        </div>
      </SiteModal>
    </>
  );
};

export default LicensesBlock;
