import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { constants, Utils } from '../../../helpers';
import { useLoggedInUserData } from '../../../store/LoggedInAccountStore';
import { devicesMQTTStore } from '../../../store/DevicesMQTTStore';
import { mqttPublish } from '../../../utils/connection/mqttConnection';

import { FiCameraOff } from 'react-icons/fi';
import FakeSnapshot from '../../../assets/images/cameras/snapshots/fake-snapshot.png';
import './LatestSnapshot.scss';
import { DefaultDevice } from '../../../assets/images';
import _ from 'lodash';
import { useDeviceSnapshots } from '../../../store/DeviceSnapshotsStore';

const LatestSnapshot = ({
  orgId,
  cdnInfo,
  deviceId,
  deviceElemId,
  deviceName,
  locationAreaName = '',
  imageURL = FakeSnapshot,
  currentLayoutStatus = false,
  clickHandler = null,
  conStatus,
  hubId,
  displayDeviceStatus,
  isScrolled,
  scrollPosition,
  ...props
}) => {
  const currentTime = Math.round(new Date().getTime() / 1000);
  const [appTopic, setAppTopic] = useState(`a/notify/${hubId}`);
  const [latestSnapshots, setLatestSnapshots] = useState({});

  const { getState, subscribe } = devicesMQTTStore;
  const state = getState();
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData,
  );
  const deviceSnapshots = useDeviceSnapshots((state) => state.deviceSnapshots);
  const { setDeviceSnapshots } = useDeviceSnapshots();
  const accountId = loggedInUserData.accountId;
  const [snapshotURL, setSnapshotURL] = useState(
    deviceSnapshots?.[deviceId] ? deviceSnapshots?.[deviceId] : imageURL,
  );
  const [isRequested, setIsRequested] = useState(null);

  useEffect(() => {
    if (Object.keys(deviceSnapshots).length && isRequested === null) {
      if (deviceSnapshots?.[deviceId]) {
        setIsRequested(false);
      } else {
        setIsRequested(true);
      }
    }
  }, [deviceSnapshots?.[deviceId]]);

  useEffect(() => {
    if (isRequested === null) {
      return;
    }

    if (state.getAccountId() !== accountId) {
      state.setAccountId(accountId);
    }

    if (!state.getSessionId()) {
      state.setSessionId(uuidv4());
    }

    state.setIsPublisherSubscribed(true);
    state.setIsReceiverSubscribed(true);

    subscribe((latestSnapshots) => setLatestSnapshots(latestSnapshots));
    if (
      displayDeviceStatus === constants.DEVICES_RETURN_ONLINE_STATUS &&
      isRequested
    ) {
      publishSnapshotRequest();
    } else if (!isRequested) {
      const snapshotImage = new Image();
      // Assign the valid URL to the snapshot URL state
      // variable
      snapshotImage.onload = () => {
        setSnapshotURL(snapshotImage.src);
      };
      // Handle the image loading error (e.g. 404 or 403 error)
      snapshotImage.onerror = () => {
        setSnapshotURL(imageURL);
      };
      snapshotImage.src = deviceSnapshots?.[deviceId];
    }
  }, [isRequested]);

  useEffect(() => {
    const fetchLatestSnapshot = async (lastSnapshot) => {
      if (!lastSnapshot || !cdnInfo) {
        return;
      }

      let bucket = cdnInfo?.bucket?.replace('${deviceId}', deviceId);

      const snapshotImage = new Image();
      // Assign the valid URL to the snapshot URL state
      // variable
      snapshotImage.onload = () => {
        setSnapshotURL(snapshotImage.src);
        setDeviceSnapshots(deviceId, snapshotImage.src);
      };

      // Handle the image loading error (e.g. 404 or 403 error)
      snapshotImage.onerror = () => {
        setSnapshotURL(imageURL);
      };
      const date = Utils.fetchDateInUnix(lastSnapshot);
      snapshotImage.src = `${cdnInfo.protocol}://${cdnInfo.host}/${bucket}/${date}/${lastSnapshot}.jpg`;
    };
    const lastSnapshot = state.latestSnapshots[deviceId]
      ? state.latestSnapshots[deviceId]
      : 0;
    const lastEvent = state.latestEvents[deviceId]
      ? state.latestEvents[deviceId]
      : 0;
    let deviceSnapshot;
    if (lastSnapshot && currentTime - lastEvent > 300) {
      deviceSnapshot = lastSnapshot;
    } else if (lastSnapshot < lastEvent) {
      deviceSnapshot = lastSnapshot;
    } else if (lastEvent) {
      deviceSnapshot = lastEvent;
    }
    if (deviceSnapshot && isRequested) {
      fetchLatestSnapshot(deviceSnapshot);
    }
  }, [latestSnapshots, cdnInfo]);

  const publishSnapshotRequest = useCallback(() => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const sessionId = state.getSessionId();

    if (!accountId) {
      return;
    }

    // Send the request
    const context = {
      topic: appTopic,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${hubId}`,
        from: `${accountId}`,
        msg: {
          action: 'get',
          resource: `ch/${deviceId}/camera/last-snap-timestamp`,
          publish: `d/notify/${accountId}/${sessionId}`,
        },
      }),
      qos: 0,
    };
    if (deviceId) {
      mqttPublish(context);
    }
  }, []);

  return (
    <div key={deviceId} id={deviceElemId} className={`cam cam-1x`}>
      <div className="cam-content">
        {conStatus !== 'offline' ? (
          <div className={`cam-snapshot`}>
            <picture className="cam-snapshot-wrapper">
              <LazyLoadImage
                src={snapshotURL}
                className={`cam-snapshot-image`}
                alt={`Snapshot of ${deviceName}`}
                scrollPosition={scrollPosition}
                onError={(e) => (e.target.src = `${DefaultDevice}`)}
              />
            </picture>
          </div>
        ) : (
          <div className={`cam-offline`}>
            <div className={`cam-offline-content`}>
              <div className="camera-offline-icon">
                <FiCameraOff
                  stroke={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--greyscale_56')}
                  size={22}
                />
              </div>
              <div className="camera-offline-label">
                {constants.CAMERAS_VIDEO_WALL_CAMERA_OFFLINE_TITLE}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LatestSnapshot;
