import axios from 'axios';
import { Utils } from '../../../helpers';

let cancelToken;
export const fetchCustomerOrgChannels = async (route) => {
  if (!route) return;
  let result;
  try {
    // Check if there are any previous pending requests
    if (typeof cancelToken !== 'undefined') {
      cancelToken.cancel('Operation canceled due to new request.');
    }

    // Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();

    const res = await axios.get(route, {
      ...Utils.requestHeader(),
      cancelToken: cancelToken.token,
    });
    const response = res?.data;
    if (response?.meta?.code === 200) {
      const data = response?.data;
      result = {
        status: 'success',
        msg: 'get customer organizations -> devices data.',
        data: data,
      };
    } else {
      if (res?.code) {
        result = {
          status: 'failure',
          msg: `${res.code}: ${res.message}`,
          data: [],
        };
      } else if (response?.data) {
        result = { status: 'failure', msg: response?.data?.userMsg };
      } else {
        result = { status: 'failure', msg: 'Unknown internal API error' };
      }
    }
  } catch (err) {
    result = { status: 'failure', msg: 'Unknown error' };
  } finally {
    return result;
  }
};

export const getCameraWallUserViewDevices = async (route, data) => {
  if (!route) return;
  let cancelToken;
  let result;
  try {
    // Check if there are any previous pending requests
    if (typeof cancelToken !== 'undefined') {
      cancelToken.cancel('Operation canceled due to new request.');
    }

    // Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();
    let requestBody = data;
    const res = await axios.post(route, requestBody, {
      ...Utils.requestHeader(),
      cancelToken: cancelToken.token,
    });
    const response = res?.data;

    if (response?.meta?.code === 200) {
      const data = response?.data;
      result = {
        status: 'success',
        msg: 'get customer organizations -> devices data.',
        data: data,
      };
    } else {
      if (res?.code) {
        result = {
          status: 'failure',
          msg: `${res.code}: ${res.message}`,
          data: [],
        };
      } else if (response?.data) {
        result = { status: 'failure', msg: response?.data?.userMsg };
      } else {
        result = { status: 'failure', msg: 'Unknown internal API error' };
      }
    }
  } catch (err) {
    result = { status: 'failure', msg: 'Unknown error' };
  } finally {
    return result;
  }
};
