import * as MP4Box from 'mp4box';
import { v4 as uuidv4 } from 'uuid';
import Store from '../../store/Store';
import {
  setCodecNotSupported,
  setElementUpdated,
  setPlaybackBufferMeta,
  setStreamLoader,
  setWSSConnection,
} from '../../store/reducers/StreamingReducer';
import { constants, Utils } from '../../helpers';
import moment from 'moment';

let videoElement = null;
let videoElementListener = null;
// const nextVideoElements = [];
let videoContainer = null;
let currentPlayStartTime = '';
let audioMuteUnmute = false;
let mp4BoxFile = null;
let virtualTimeInterval = null;
let virtualCurrentTime = null;
let nextAvailTime;
let noVideoFromTimestamp;
let noVideo = null;
let timelineRef;
let token;
let websocket;
let orgID;
let deviceID;
let cID;
let playbackUUID;
let sendComplete = false;
let requestedTime = null;
let isAutoPlay = false;
let fisheyeInfo;
let dewarpAngle;
let isNoVideo = false;
let exactTimeToPlay = null;

const startConnection = (serverDetails) => {
  const playbackServer = serverDetails?.timeline_server;
  websocket = new WebSocket(
    `${playbackServer.protocol}://${playbackServer.host}:${playbackServer.port}`,
  );

  const checkVideoStartTime = () => {
    if (exactTimeToPlay < videoElement.buffered.end(0)) {
      videoElement.currentTime = exactTimeToPlay;
    }
  };

  websocket.onopen = async () => {
    Utils.vmsLogger().log('PlayBack websocket open', Utils.getTimesinmili());
    Store.dispatch(setWSSConnection(true));
    sendCloudRegisterRequest(token);
  };

  websocket.onerror = (err) => {
    Utils.vmsLogger().log('websocket error', err);
    websocket.close();
  };

  websocket.onclose = (e) => {
    Utils.vmsLogger().log('cloud playback web socket closed', e);
    Store.dispatch(setWSSConnection(false));
  };

  const handleStream = async (event) => {
    try {
      const data = event.data;
      const blob = await data.arrayBuffer();
      const buffer = new Uint8Array(blob);
      const type = 'video';

      function appendBufferToSourceBuffer(targetVideoElement) {
        const { sourceBuffer, segmentQueue } = targetVideoElement;

        if (!sourceBuffer.updating && segmentQueue.length > 0) {
          sourceBuffer.appendBuffer(segmentQueue.shift());
        }
      }

      if (checkInitSegment(buffer)) {
        const { segmentQueue, mediaSource } = videoElement;

        mp4BoxFile = await loadMp4BoxFile(buffer);
        const mimeCodec = mp4BoxFile.getInfo().mime;

        if (!MediaSource.isTypeSupported(mimeCodec)) {
          const codecObj = {
            id: deviceID,
            isCodecNotSupport: true,
          };
          Store.dispatch(setCodecNotSupported(codecObj));
          Utils.vmsLogger().log('Mime codec not supported: codec=', mimeCodec);
          return;
        }

        if (mediaSource.sourceBuffers.length) {
          // Utils.vmsLogger().log(
          //   "=== mutliple resolution detected, but don't add new SourceBuffer",
          // );
          segmentQueue.push(buffer);
          return;
        }

        const sourceBuffer = mediaSource.addSourceBuffer(mimeCodec);
        videoElement.sourceBuffer = sourceBuffer;

        sourceBuffer.addEventListener('updateend', (event) => {
          appendBufferToSourceBuffer(videoElement);
          const targetSourceBuffer = event.target;
          if (
            videoElement.currentTime === 0 &&
            targetSourceBuffer.buffered.length > 0
          ) {
            checkVideoStartTime();
          }
        });
        sourceBuffer.addEventListener('error', (error) => {
          // Utils.vmsLogger().log('=== source buffer error: ', error, videoElement?.error);
        });
        segmentQueue.push(buffer);
      } else {
        const { sourceBuffer, segmentQueue } = videoElement;
        if (!sourceBuffer) {
          // Utils.vmsLogger().log(
          //   '=== Source buffer not initialized, data received before the init segment is discarded.',
          // );
          return;
        }
        segmentQueue.push(buffer);
        appendBufferToSourceBuffer(videoElement);
      }
    } catch (error) {
      // Utils.vmsLogger().log('=== media source error: ', error, videoElement?.error);
      //   mediaSource.endOfStream();
    }
  };

  websocket.onmessage = async (event) => {
    const data = event.data;
    if (
      (data instanceof ArrayBuffer || data instanceof Blob) &&
      typeof data !== 'string'
    ) {
      if (videoElement) await handleStream(event);
    } else {
      const responseMessage = JSON.parse(data);
      const type = responseMessage.msg.properties.type;
      Utils.vmsLogger().log(JSON.stringify(responseMessage, null, 2));
      switch (type) {
        case 'REGISTERED':
          Utils.vmsLogger().log(
            'PlayBack web socket registered',
            Utils.getTimesinmili(),
          );
          break;
        case 'READY':
          Utils.vmsLogger().log('Ready to stream');
          break;
        case 'ERROR':
          Utils.vmsLogger().log(event.data);
          break;
        case 'SEND_COMPLETE':
          sendComplete = true;
          break;
        case 'NO_VIDEO':
          noVideoFromTimestamp = responseMessage?.msg?.properties?.from;
          isNoVideo = true;
          // check whether video is in playing state or not
          Store.dispatch(setStreamLoader(false));

          const isVideoPlaying = !!(
            videoElement?.currentTime > 0 &&
            !videoElement?.paused &&
            !videoElement?.ended &&
            videoElement?.readyState > 2
          );
          if (
            !responseMessage?.msg?.properties?.next_avail_time &&
            !isVideoPlaying
          ) {
            clearLocalTimerCloud();
            if (isAutoPlay) startClientSideTimer();
          }
          if (responseMessage?.msg?.properties?.next_avail_time) {
            clearLocalTimerCloud();
            nextAvailTime = responseMessage?.msg?.properties?.next_avail_time;
            if (isAutoPlay) startClientSideTimer();
          }
        default:
          break;
      }
    }
  };
};

const checkInitSegment = (buffer) => {
  const ftypBox = String.fromCharCode.apply(null, buffer.subarray(4, 8));
  return ftypBox === 'ftyp';
};

const loadMp4BoxFile = (u8Buffer) => {
  return new Promise((resolve, reject) => {
    const mp4BoxFile = MP4Box.createFile(false);
    mp4BoxFile.onReady = (info) => {
      resolve(mp4BoxFile);
    };
    mp4BoxFile.onError = reject;
    u8Buffer.buffer.fileStart = 0;
    mp4BoxFile.appendBuffer(u8Buffer.buffer);
    mp4BoxFile.flush();
  });
};

const createVideoElement = () => {
  const videoElement = document.createElement('video');

  videoElement.controls = false;
  videoElement.muted = audioMuteUnmute;
  videoElement.style.width = '100%';
  videoElement.id = 'playback-video';

  const mediaSource = new MediaSource();
  videoElement.mediaSource = mediaSource;
  videoElement.src = URL.createObjectURL(mediaSource);
  videoElement.sourceBuffer = null;
  videoElement.segmentQueue = [];

  mediaSource.addEventListener('sourceopen', () => {
    mediaSource.duration = 0;
  });

  videoElement.addEventListener('error', handleVideoEvents);
  videoElement.addEventListener('progress', handleVideoEvents);
  videoElement.addEventListener('canplay', handleVideoEvents);
  videoElement.addEventListener('waiting', handleVideoEvents);
  return videoElement;
};

const handleVideoEvents = (event) => {
  const isLoaderDisplay = Store.getState()?.streaming?.streamLoader;
  switch (event.type) {
    case 'canplay':
      Utils.vmsLogger().log(
        'PlayBack first frame received',
        Utils.getTimesinmili(),
      );
      noVideo.style.display = 'none';
      if (isLoaderDisplay) {
        Store.dispatch(setStreamLoader(false));
      }
      break;

    case 'waiting':
      const bufferEnd =
        videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(0);
      const videoCurrentTime = parseInt(videoElement?.currentTime, 10) + 1;
      if (sendComplete && videoCurrentTime >= parseInt(bufferEnd, 10)) {
        handleNoVideoCases(event);
      } else {
        if (!isLoaderDisplay) {
          // Store.dispatch(setStreamLoader(true));
        }
      }
      break;

    case 'playing':
      noVideo.style.display = 'none';
      if (isLoaderDisplay) {
        Store.dispatch(setStreamLoader(false));
      }
      break;

    case 'progress':
      assignBufferedMeta();
      break;

    case 'error':
      handleVideoError(event);
      break;

    default:
      break;
  }
};

const handleNoVideoCases = (event) => {
  // handling this function when video is playing and gap occurred.
  try {
    const bufferEnd =
      videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(0);
    noVideo.style.display = 'flex';
    virtualCurrentTime = parseInt(bufferEnd, 10);
    startClientSideTimer();
  } catch (err) {}
};

const startClientSideTimer = () => {
  const isLoaderDisplay = Store.getState()?.streaming?.streamLoader;
  noVideo.style.display = 'flex';
  if (isLoaderDisplay) {
    Store.dispatch(setStreamLoader(false));
  }
  const bufferEnd =
    videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(0);
  virtualCurrentTime =
    parseInt(bufferEnd, 10) || virtualCurrentTime || currentPlayStartTime;
  // Utils.vmsLogger().log('nextAvailTime is available', nextAvailTime, virtualCurrentTime);
  if (nextAvailTime && virtualCurrentTime <= nextAvailTime / 1000) {
    virtualCurrentTime =
      parseInt(bufferEnd, 10) || virtualCurrentTime || currentPlayStartTime;
    videoElement.pause();
    videoElement.removeEventListener('progress', handleVideoEvents);
    videoElement.removeEventListener('canplay', handleVideoEvents);
    URL.revokeObjectURL(videoElement.src);
    videoElement.remove();
    Utils.vmsLogger().log(
      'nextAvailTime is available',
      nextAvailTime,
      virtualCurrentTime,
    );
    virtualTimeInterval = setInterval(() => {
      virtualCurrentTime += 1000 / 1000;
      if (virtualCurrentTime < nextAvailTime / 1000) {
        // Utils.vmsLogger().log('next avail time with timer');
        timelineRef?.current?.$el?.moveTo(
          moment(Utils.getDate(virtualCurrentTime)),
        );
      } else {
        // Utils.vmsLogger().log('reached to next avail time and play');
        clearLocalTimerCloud();
        noVideo.style.display = 'none';
        // videoElement.play();
        Store.dispatch(setStreamLoader(true));
        const requirePlayData = {
          playTime: Utils.getDate(parseInt(nextAvailTime / 1000, 10) + 1),
          audioValue: audioMuteUnmute,
          timeline: timelineRef,
          deviceID: deviceID,
          qualityData: 'SQ',
          isAutoPlay: isAutoPlay,
        };
        sendCloudPlayRequest(requirePlayData);
        Store.dispatch(setElementUpdated(true));
      }
    }, 1000);
  } else {
    virtualCurrentTime = parseInt(bufferEnd, 10) || currentPlayStartTime;
    if (
      (!nextAvailTime && virtualCurrentTime) ||
      parseInt(nextAvailTime / 1000, 10) < virtualCurrentTime
    ) {
      let nextPlayCheckTime = virtualCurrentTime + 60;
      if (videoElement) {
        videoElement.pause();
        sendCloudStopRequest(isAutoPlay);
        cleanupResources();
      }
      virtualTimeInterval = setInterval(() => {
        virtualCurrentTime += 1000 / 1000;
        if (
          !nextAvailTime ||
          parseInt(nextAvailTime / 1000, 10) > virtualCurrentTime
        ) {
          // Utils.vmsLogger().log('start client side timer is runnning');
          timelineRef?.current?.$el?.moveTo(
            moment(Utils.getDate(virtualCurrentTime)),
          );
        } else {
          // Utils.vmsLogger().log('stop the local timer and send play reuest');
          clearLocalTimerCloud();
          const requirePlayData = {
            playTime: Utils.getDate(parseInt(nextAvailTime / 1000, 10) + 1),
            audioValue: audioMuteUnmute,
            timeline: timelineRef,
            deviceID: deviceID,
            qualityData: 'SQ',
            isAutoPlay: isAutoPlay,
          };
          noVideo.style.display = 'none';
          sendCloudPlayRequest(requirePlayData);
        }
        if (nextPlayCheckTime === virtualCurrentTime) {
          const requirePlayData = {
            playTime: Utils.getDate(nextPlayCheckTime),
            audioValue: audioMuteUnmute,
            timeline: timelineRef,
            deviceID: deviceID,
            qualityData: 'SQ',
            isAutoPlay: isAutoPlay,
          };
          // Utils.vmsLogger().log('send play request for next available time');
          clearLocalTimerCloud();
          sendCloudPlayRequest(requirePlayData);
        }
      }, 1000);
    }
  }
};

const handleVideoError = (event) => {
  Utils.vmsLogger().log('video error', videoElement?.error);
};

const assignBufferedMeta = () => {
  if (
    videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered.length > 0
  ) {
    if (!isAutoPlay) {
      videoElement.pause();
    }
    const bufferStart =
      videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.start(0);
    const bufferEnd =
      videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(0);
    if (bufferStart && bufferEnd) {
      const obj = {
        id: deviceID,
        client: {
          start: parseInt(bufferStart, 10) * 1000,
          end: parseInt(bufferEnd, 10) * 1000,
          className: 'new-meta-buffer-class',
        },
      };
      Store.dispatch(setPlaybackBufferMeta(obj));
    }
  }
};

export const connectCloudPlayback = (
  getPlatformDetails,
  deviceId,
  orgId,
  jwt_token,
) => {
  deviceID = deviceId;
  orgID = orgId;
  token = jwt_token;
  startConnection(getPlatformDetails);
};

export const sendCloudRegisterRequest = (platformToken) => {
  cID = uuidv4();
  playbackUUID = uuidv4();
  token = platformToken;
  const registerObj = {
    appid: uuidv4(),
    token: token,
    cid: cID,
    tid: Date.now().toString(),
    to: deviceID,
    from: orgID,
    msg: {
      action: 'set',
      resource: 'media/handshake',
      properties: {
        org_id: orgID,
        dev_id: deviceID,
        uuid: playbackUUID,
        type: constants.WEBSOCKET_PLAYBACK_EVENT_REGISTER,
      },
    },
  };
  if (websocket && websocket.readyState === 1) {
    Utils.vmsLogger().log('Web socket register request sent');
    const registerMsg = JSON.stringify(registerObj, null, 2);
    websocket.send(registerMsg);
  }
};

export const sendCloudPlayRequest = (requirePlayData) => {
  isNoVideo = false;
  clearLocalTimerCloud();
  sendComplete = false;
  noVideo = document.getElementById('noVideo');
  timelineRef = requirePlayData?.timeline;
  currentPlayStartTime = Utils.getUnixDate(requirePlayData?.playTime);
  virtualCurrentTime = null;
  audioMuteUnmute = requirePlayData?.audioValue;
  requestedTime = Utils.getUnixDate(requirePlayData?.playTime);
  exactTimeToPlay = Utils.getUnixDate(requirePlayData?.playbackToPlayTime);
  const actualTime = requestedTime * 1000;
  cID = uuidv4();
  playbackUUID = uuidv4();
  videoContainer = document.getElementById('remote-view-wrapper');
  fisheyeInfo = requirePlayData?.fishEyeInfo;
  dewarpAngle = requirePlayData?.dewarpAngle;
  if (videoContainer) {
    videoElement = createVideoElement();
    if (videoElementListener) {
      videoElementListener(videoElement);
    }

    videoElement.autoplay = isAutoPlay;
    videoContainer.appendChild(videoElement);
    Store.dispatch(setElementUpdated(true));

    const playObj = {
      cid: cID,
      tid: Date.now().toString(),
      to: requirePlayData?.deviceID,
      from: orgID,
      token: token,
      msg: {
        action: 'set',
        resource: 'media/handshake',
        properties: {
          uuid: playbackUUID,
          org_id: orgID,
          dev_id: requirePlayData?.deviceID,
          start_time: actualTime,
          duration: 60000,
          quality: constants.CAMERA_SETTINGS_STANDARD_QUALITY_SHORT,
          type: constants.WEBSOCKET_PLAYBACK_EVENT_PLAY,
        },
      },
    };
    const playMsg = JSON.stringify(playObj, null, 2);
    if (websocket && websocket.readyState === 1) {
      Utils.vmsLogger().log(
        'PlayBack play request sent',
        Utils.getTimesinmili(),
        playMsg,
      );
      websocket.send(playMsg);
    }
    const elementCreated = 'elementCreated';
    return elementCreated;
  }
};

export const sendCloudContinueRequest = () => {
  const contunueObj = {
    cid: cID,
    tid: Date.now().toString(),
    to: deviceID,
    token: token,
    from: orgID,
    msg: {
      action: 'set',
      resource: 'media/streaming',
      properties: {
        uuid: playbackUUID,
        org_id: orgID,
        dev_id: deviceID,
        duration: 60000,
        quality: constants.CAMERA_SETTINGS_STANDARD_QUALITY_SHORT,
        type: constants.WEBSOCKET_PLAYBACK_EVENT_CONTINUE,
      },
    },
  };
  const continueMsg = JSON.stringify(contunueObj, null, 2);
  if (websocket && websocket.readyState === 1 && !isNoVideo) {
    Utils.vmsLogger().log(
      'PlayBack continue request sent',
      Utils.getTimesinmili(),
    );
    sendComplete = false;
    websocket.send(continueMsg);
  }
};

export const sendCloudStopRequest = (isAutoPlayVal) => {
  isAutoPlay = isAutoPlayVal;
  const playbackPlayer = document.getElementById('playback-video');
  if (playbackPlayer) {
    playbackPlayer.isAutoPlay = isAutoPlay;
  }
  const stopObj = {
    cid: cID,
    tid: Date.now().toString(),
    to: deviceID,
    from: orgID,
    token: token,
    msg: {
      resource: 'media/streaming',
      properties: {
        uuid: playbackUUID,
        org_id: orgID,
        dev_id: deviceID,
        type: constants.WEBSOCKET_PLAYBACK_EVENT_STOP,
      },
    },
  };
  const stopMsg = JSON.stringify(stopObj, null, 2);
  if (websocket && websocket.readyState === 1) {
    websocket.send(stopMsg);
  }
};

export const sendCloudDisConnectRequest = () => {
  const disconnectObj = {
    cid: cID,
    tid: Date.now().toString(),
    to: deviceID,
    from: orgID,
    token: token,
    msg: {
      resource: 'media/streaming',
      properties: {
        uuid: playbackUUID,
        org_id: orgID,
        dev_id: deviceID,
        type: constants.WEBSOCKET_PLAYBACK_EVENT_DISCONNECT,
      },
    },
  };

  const disconnectMsg = JSON.stringify(disconnectObj, null, 2);
  if (websocket && websocket.readyState === 1) {
    websocket.send(disconnectMsg);
  }
};

export const disconnectWithWebSocket = () => {
  if (websocket) {
    websocket.close();
  }
};

export const cleanupResources = () => {
  if (videoElement?.mediaSource?.readyState === 'open') {
    videoElement.mediaSource.endOfStream();
  }
  videoElement?.removeEventListener('progress', handleVideoEvents);
  videoElement?.removeEventListener('canplay', handleVideoEvents);
  videoElement?.removeEventListener('error', handleVideoEvents);
  videoElement?.remove();
  videoElement = null;
};

export const clearLocalTimerCloud = () => {
  if (virtualTimeInterval) {
    clearInterval(virtualTimeInterval);
  }
};

export const clearCurrentPlayTime = () => {
  currentPlayStartTime = '';
};

export const getCloudSendComplete = () => {
  return sendComplete;
};

export const addVideoElementListener = (listener) => {
  videoElementListener = listener;
};

export const setCloudIsAutoPlay = (isAutoPlayVal) => {
  isAutoPlay = isAutoPlayVal;
};
