import React, { useEffect, useState } from 'react';
import { PrimaryButton, SiteModal } from '../../../../components/common';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { BiWebcam } from 'react-icons/bi';
import { constants } from '../../../../helpers';
import { Checkbox, FormControlLabel, Radio } from '@mui/material';

import {
  getTimeZoneAbbreviation,
  minutesToTime,
} from '../../../../helpers/commonUtils';
import { RiErrorWarningLine } from 'react-icons/ri';
import axios from 'axios';

const ApplySchedule = ({
  isEdgeRecordSupport,
  isApplyToOtherIsOpen,
  setIsApplyToOtherIsOpen,
  deviceInfos,
  applyScheduleToApplyToOtehrDevices,
  handleApplyScheduleError,
  deviceTimeZone,
  availableDevices,
  availableLocations,
  localStoragePermissions,
}) => {
  const [locationsList, setLocationsList] = useState([]);
  const [openAccordion, setOpenAccordion] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [devicesToShow, setDevicesToShow] = useState([]);
  const [selectedLocationsList, setSelectedLocationsList] = useState([]);
  const [isApplyLoading, setIsApplyLoading] = useState(false);

  const getSelectedDeviceIds = (locations) => {
    let selectedDeviceIds = [];

    locations.forEach((location) => {
      location.areas.forEach((area) => {
        area.devices.forEach((device) => {
          if (device.checked) {
            selectedDeviceIds.push(device.deviceId);
          }
        });
      });
    });

    return selectedDeviceIds;
  };

  useEffect(() => {
    setSelectedLocationsList(getSelectedDeviceIds(devicesToShow));
  }, [devicesToShow]);

  const handleApplySchedule = async (selectedIds) => {
    setIsApplyLoading(true);
    try {
      const result = await axios.put(
        `device/orgs/${deviceInfos?.orgId}/devices/settings`,
        {
          deviceId: selectedIds,
          ...applyScheduleToApplyToOtehrDevices,
        },
      );

      if (result?.name === 'AxiosError') {
        throw result;
      }

      setIsApplyToOtherIsOpen(false);
      setIsApplyLoading(false);
    } catch (error) {
      setIsApplyToOtherIsOpen(false);
      setIsApplyLoading(false);
      handleApplyScheduleError(error?.data?.userMsg || error?.message);
    }
  };

  const transformLocationAndDevices = (locations, devices) => {
    return locations.map(({ locationId, locationName, areas }) => {
      return {
        locationId,
        locationName,
        areas: areas.map(({ areaId, areaName }) => {
          return {
            areaId,
            areaName,
            devices: devices
              .map((device) => {
                if (
                  device.areaId === areaId &&
                  device.deviceStatus !== 'DEACTIVATED'
                ) {
                  return device;
                } else {
                  return null;
                }
              })
              .filter(Boolean),
          };
        }),
      };
    });
  };

  useEffect(() => {
    if (!(availableLocations && availableDevices)) {
      return;
    }
    setLocationsList(
      transformLocationAndDevices(availableLocations, availableDevices),
    );
  }, [availableDevices, availableLocations]);

  const LocationListAccordion = () => {
    const checkBoxDisableHandler = (device) => {
      return (
        !isEdgeRecordSupport ||
        !(
          device?.properties?.['enable-cloud-backup'] === 'true' ||
          device?.properties?.['enable-cloud-backup'] === true
        )
      );
    };

    const handleAccordionOpen = (accordionKey) => {
      if (openAccordion?.includes(accordionKey)) {
        setOpenAccordion(
          openAccordion?.filter((item) => item !== accordionKey),
        );
      } else {
        setOpenAccordion([...openAccordion, accordionKey]);
      }
    };

    const handleLocationCheckBox = (locationId, isChecked) => {
      setDevicesToShow((prevLocations) => {
        return prevLocations.map((location) => {
          if (location.locationId === locationId) {
            return {
              ...location,
              checked: isChecked,
              areas: location.areas.map((area) => ({
                ...area,
                checked: isChecked,
                devices: area.devices.map((device) => {
                  if (checkBoxDisableHandler(device)) {
                    return device;
                  }
                  return {
                    ...device,
                    checked: isChecked,
                  };
                }),
              })),
            };
          }
          return location;
        });
      });
    };

    const handleAreaCheckBox = (areaId, isChecked) => {
      setDevicesToShow((prevLocations) => {
        return prevLocations.map((location) => ({
          ...location,
          areas: location.areas.map((area) => {
            if (area.areaId === areaId) {
              return {
                ...area,
                checked: isChecked,
                devices: area.devices.map((device) => {
                  if (checkBoxDisableHandler(device)) {
                    return device;
                  }

                  return {
                    ...device,
                    checked: isChecked,
                  };
                }),
              };
            }
            return area;
          }),
        }));
      });
    };

    const handleCameraCheckbox = (deviceId, isChecked) => {
      setDevicesToShow((prevLocations) => {
        return prevLocations.map((location) => ({
          ...location,
          areas: location.areas.map((area) => ({
            ...area,
            devices: area.devices.map((device) => {
              if (device.deviceId === deviceId) {
                return {
                  ...device,
                  checked: isChecked,
                };
              }
              return device;
            }),
          })),
        }));
      });
    };

    const locationSelection = (locationId) => {
      const location = devicesToShow?.find(
        (loc) => loc.locationId === locationId,
      );

      if (!location) return false;

      let totalDevices = 0;
      let checkedDevices = 0;

      location?.areas?.forEach((area) => {
        area?.devices?.forEach((device) => {
          totalDevices++;
          if (device?.checked) checkedDevices++;
        });
      });

      if (totalDevices === 0 || checkedDevices === 0) return false;

      if (checkedDevices === totalDevices) return 'total';

      return 'partial';
    };

    const getAreaSelectionStatus = (locationId, areaId) => {
      const location = devicesToShow?.find(
        (loc) => loc.locationId === locationId,
      );

      if (!location) return false;

      const area = location.areas.find((a) => a.areaId === areaId);

      if (!area) return false;

      let totalDevices = 0;
      let checkedDevices = 0;

      area.devices.forEach((device) => {
        totalDevices++;
        if (device.checked) checkedDevices++;
      });

      if (totalDevices === 0 || checkedDevices === 0) return false;

      if (checkedDevices === totalDevices) return 'total';

      return 'partial';
    };

    return (
      <div className="location-accordio-main-container">
        {devicesToShow.length !== 0 ? (
          devicesToShow?.map((item, index) => (
            <div
              key={`${item?.locationName}-${index}`}
              className="location-accordion-header"
            >
              <div
                className="d-flex justify-content-between w-100"
                onClick={() => handleAccordionOpen(item?.locationId)}
              >
                <div className="accordion-lable-arrow-container">
                  {openAccordion.includes(item?.locationId) ? (
                    <IoIosArrowUp size={20} className="accordion-arrow" />
                  ) : (
                    <IoIosArrowDown size={20} className="accordion-arrow" />
                  )}
                  <div className="parent-title">{item?.locationName}</div>
                </div>
                <FormControlLabel
                  className="accordion-main-checkbox"
                  control={
                    <Checkbox
                      checked={locationSelection(item?.locationId) === 'total'}
                      onClick={(event) => event.stopPropagation()}
                      indeterminate={
                        locationSelection(item?.locationId) === 'partial'
                      }
                      onChange={(e) => {
                        handleLocationCheckBox(
                          item?.locationId,
                          e.target.getAttribute('data-indeterminate'),
                        );
                      }}
                      sx={{
                        '&.Mui-checked': {
                          color: '#DE671B',
                        },
                        '&.MuiCheckbox-indeterminate': {
                          color: '#DE671B',
                        },
                      }}
                    />
                  }
                />
              </div>
              {openAccordion.includes(item?.locationId) &&
                item?.areas?.map((area, areaIndex) => (
                  <div>
                    <div
                      className="d-flex justify-content-between w-100 ps-3"
                      onClick={() => handleAccordionOpen(area?.areaId)}
                    >
                      <div className="accordion-lable-arrow-container">
                        {openAccordion.includes(area?.areaId) ? (
                          <IoIosArrowUp size={20} className="accordion-arrow" />
                        ) : (
                          <IoIosArrowDown
                            size={20}
                            className="accordion-arrow"
                          />
                        )}
                        <div className="parent-title">{area?.areaName}</div>
                      </div>
                      <FormControlLabel
                        className="accordion-main-checkbox"
                        control={
                          <Checkbox
                            checked={
                              getAreaSelectionStatus(
                                item?.locationId,
                                area?.areaId,
                              ) === 'total'
                            }
                            onClick={(event) => event.stopPropagation()}
                            indeterminate={
                              getAreaSelectionStatus(
                                item?.locationId,
                                area?.areaId,
                              ) === 'partial'
                            }
                            onChange={(e) => {
                              handleAreaCheckBox(
                                area?.areaId,
                                e.target.getAttribute('data-indeterminate'),
                              );
                            }}
                            sx={{
                              '&.Mui-checked': {
                                color: '#DE671B',
                              },
                              '&.MuiCheckbox-indeterminate': {
                                color: '#DE671B',
                              },
                            }}
                          />
                        }
                      />
                    </div>
                    {openAccordion.includes(area?.areaId) && (
                      <div className="location-accordion-body">
                        {area?.devices?.map((device, deviceIndex) => (
                          <div
                            key={`${device?.deviceName}-${deviceIndex}`}
                            className="location-option-container"
                          >
                            <div className="label-and-checkbox-holder-container">
                              <div
                                className={`location-option-label-container ${
                                  !device?.isEdgeRecSupport ||
                                  !(
                                    device?.properties?.[
                                      'enable-cloud-backup'
                                    ] === 'true' ||
                                    device?.properties?.[
                                      'enable-cloud-backup'
                                    ] === true
                                  )
                                    ? 'location-option-label-container-disabled'
                                    : ''
                                } d-flex flex-column align-items-start`}
                              >
                                <div className="d-flex justify-content-start gap-2">
                                  <BiWebcam size={20} />
                                  <div className="location-checkbox-label">
                                    <div> {device?.deviceName}</div>
                                    {checkBoxDisableHandler(device) && (
                                      <div className="reason-title-layout d-flex">
                                        <RiErrorWarningLine
                                          color={getComputedStyle(
                                            document.documentElement,
                                          ).getPropertyValue('--brand_primary')}
                                          size={15}
                                        />
                                        <div className="reason-title">
                                          {!device?.isEdgeRecSupport
                                            ? constants.CLOUD_BACKUP_APPLY_SCHEDULE_THIS_CAMERA_DOES_NOT_SUPPORT_EDGE_RECORDING
                                            : !(
                                                  device?.properties?.[
                                                    'enable-cloud-backup'
                                                  ] === 'true' ||
                                                  device?.properties?.[
                                                    'enable-cloud-backup'
                                                  ] === true
                                                )
                                              ? constants.CLOUD_BACKUP_APPLY_SCHEDULE_THE_CLOUD_BACKUP_IS_DISABLED_FOR_THIS_CAMERA
                                              : ''}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <Checkbox
                                disabled={checkBoxDisableHandler(device)}
                                type="checkbox"
                                className="checkbox-style"
                                checked={device?.checked || false}
                                sx={{
                                  '&.Mui-checked': {
                                    color: '#DE671B',
                                  },
                                  ...(checkBoxDisableHandler(device) && {
                                    svg: {
                                      backgroundColor: '#656667',
                                      height: '19px',
                                      width: '19px',
                                      borderRadius: '4px',
                                      border: '1.5px solid #444648',
                                    },
                                    path: {
                                      display: 'none',
                                    },
                                  }),
                                }}
                                onChange={(e) => {
                                  handleCameraCheckbox(
                                    device?.deviceId,
                                    e.target.checked,
                                  );
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
            </div>
          ))
        ) : (
          <div className="no-device-layout">
            {constants.SELECT_LOCATION_TO_SHOW_DEVICE}
          </div>
        )}
      </div>
    );
  };

  const daysMap = {
    1: constants.SUN,
    2: constants.MON,
    3: constants.TUE,
    4: constants.WED,
    5: constants.THU,
    6: constants.FRI,
    7: constants.SAT,
  };

  const UploadScheduleList = () => {
    return (
      <div>
        {applyScheduleToApplyToOtehrDevices?.uploadSchedule?.map((schedule) => (
          <div className="apply-schedule-list">
            {schedule?.['ucs-day']
              ?.split(',')
              ?.map((num) => daysMap[num])
              ?.join(', ')}
            ,&nbsp;
            {minutesToTime(schedule?.['ucs-st'])} -&nbsp;
            {minutesToTime(schedule?.['ucs-et'])}{' '}
            {getTimeZoneAbbreviation(deviceTimeZone)}
          </div>
        ))}
      </div>
    );
  };

  const BackupContentList = () => {
    return (
      <div>
        {applyScheduleToApplyToOtehrDevices?.backupSchedule?.map((schedule) => (
          <div className="apply-schedule-list">
            {schedule?.['cbs-day']
              ?.split(',')
              ?.map((num) => daysMap[num])
              ?.join(', ')}
            ,&nbsp;
            {minutesToTime(schedule?.['cbs-st'])} -&nbsp;
            {minutesToTime(schedule?.['cbs-et'])}{' '}
            {getTimeZoneAbbreviation(deviceTimeZone)}
          </div>
        ))}
      </div>
    );
  };

  const removeDeviceById = (locationData, deviceIdToRemove) => {
    return {
      ...locationData,
      areas: locationData.areas.map((area) => ({
        ...area,
        devices: area.devices.filter(
          (device) => device.deviceId !== deviceIdToRemove,
        ),
      })),
    };
  };

  return (
    <SiteModal
      showModal={isApplyToOtherIsOpen}
      modalTitle={constants.CLOUD_BACKUP_APPLY_TO_OTHER_DEVICES}
      hideModal={() => {
        setIsApplyToOtherIsOpen(false);
      }}
    >
      <div className="schedule-other-device-main-container">
        <div className="schedule-other-device-sub-container">
          <div className="section-heading">
            {constants.CLOUD_BACKUP_SCHEDULE}
          </div>
          <div className="schedule-data-container">
            <UploadScheduleList />
            <BackupContentList />
          </div>
        </div>
        <div className="schedule-other-device-sub-container">
          <div className="section-heading">
            {constants.CLOUD_BACKUP_DEVICES}
          </div>
          <div>
            {
              constants.CLOUD_BACKUP_SELECTE_ONE_OR_MORE_DEVICES_TO_APPLY_THE_ABOVE_SCHEDULE
            }
          </div>
        </div>
        <div className="schedule-other-device-sub-container">
          <div>
            <div className="section-heading">
              {constants.SELECT_LOCATION_TITLE}
            </div>
            <div className="select-location-container">
              {locationsList?.map((location) => (
                <li className="list-unstyled d-flex justify-content-between w-100 align-items-center">
                  <div>{location?.locationName}</div>
                  <div>
                    <Radio
                      checked={selectedLocation === location.locationName}
                      value={location.locationName}
                      onChange={(event) => {
                        setSelectedLocation(event.target.value);
                        setDevicesToShow([
                          removeDeviceById(location, deviceInfos?.deviceId),
                        ]);
                      }}
                      className="p-1"
                      sx={{
                        '&.Mui-checked': {
                          color: '#f37321', // Color when checked
                        },
                      }}
                    />
                  </div>
                </li>
              ))}
            </div>
          </div>
        </div>
        <div className="schedule-other-device-sub-container">
          <div className="section-heading">
            {constants.CLOUD_BACKUP_ADD_DEVICES}
          </div>
          <div>
            <LocationListAccordion />
          </div>
        </div>
      </div>

      <div className="backup-now-button-container">
        <PrimaryButton
          className="btn btn-primary"
          type="submit"
          height="44px"
          fontSize="0.875rem"
          width="auto"
          onClick={() => {
            handleApplySchedule(selectedLocationsList);
          }}
          loader={isApplyLoading}
          disabled={!selectedLocationsList?.length}
        >
          {constants.CLOUD_BACKUP_APPLY_BUTTON}
        </PrimaryButton>
        <PrimaryButton
          className="btn btn-primary-outline"
          type="button"
          width="auto"
          borderWidth="1.5px"
          hoverBorderWidth="1.5px"
          hoverBorderColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--primary_40')}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--primary_40',
          )}
          hoverColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--brand_white')}
          hoverBackgroundColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--primary_40')}
          backgroundColor="transparent"
          onClick={() => {
            setIsApplyToOtherIsOpen(false);
          }}
        >
          {constants.CLOUD_BACKUP_CONFIRMATION_POPUP_CANCEL_BUTTON}
        </PrimaryButton>
      </div>
    </SiteModal>
  );
};

export default ApplySchedule;
