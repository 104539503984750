import { Utils } from '../../../../../helpers';
import store from '../../../../../store/Store';
import {
  removeLiveStreams,
  setLiveStreams,
  setStremBitmapsData,
} from '../../../../../store/reducers/StreamingReducer';
import {
  getNoPlaybackContentAvailable,
  setNoPlaybackContentAvailable,
} from '../../../../../store/reducers/ViewsReducer';
import * as wsConnection from '../wssConnection/wssConnection';

let rtcPeerConnections = {};
let isLocalIceSent = [];

export const getLocalStream = (
  serverDetails,
  deviceId,
  cdnDetails,
  orgId,
  accountId,
  oneIceSend,
) => {
  if (isLocalIceSent.includes(deviceId)) {
    const findIndex = isLocalIceSent.findIndex((devId) => devId === deviceId);
    if (findIndex !== -1) {
      isLocalIceSent?.splice(findIndex, 1);
    }
  }
  createPeerConnection(
    serverDetails,
    deviceId,
    cdnDetails,
    orgId,
    accountId,
    oneIceSend,
  );
};

const createPeerConnection = (
  serverDetails,
  deviceId,
  cdnDetails,
  orgId,
  accountId,
  oneIceSend,
) => {
  const configuration = {
    iceServers: [
      {
        urls: `${serverDetails?.stun.protocol}:${serverDetails?.stun.host}:${serverDetails?.stun.port}`,
      },
      {
        urls: `${serverDetails?.turn.protocol}:${serverDetails?.turn.host}:${serverDetails?.turn.port}`,
        username: `${serverDetails.turn.userName}`,
        credential: `${serverDetails.turn.password}`,
      },
    ],
    iceTransportPolicy: 'all',
    rtcpMuxPolicy: 'require',
    bundlePolicy: 'balanced',
  };
  Utils.vmsLogger().log(
    'view createPeerConnection ~ configuration:',
    configuration,
  );

  let peerConnection;
  // Every time when new play request sent we need to create new peer connection.
  peerConnection = new RTCPeerConnection(configuration);

  // const transceiver = peerConnection.addTransceiver('video', {
  //   direction: 'recvonly',
  // });
  // const capabilities = RTCRtpSender.getCapabilities('video').codecs;
  // let h264_codecs = [];
  // // iterate over supported codecs and pull out the codecs we want
  // capabilities.forEach((codec, idx) => {
  //   if (
  //     codec.mimeType === 'video/H264' &&
  //     codec.sdpFmtpLine.includes('packetization-mode=1')
  //   ) {
  //     h264_codecs.push(codec);
  //   }
  // });
  // transceiver.setCodecPreferences(h264_codecs);
  // const transceiver_a = peerConnection.addTransceiver('audio', {
  //   direction: 'recvonly',
  // });
  // const capabilities_a = RTCRtpSender.getCapabilities('audio').codecs;
  // let audio_codecs = [];
  // // iterate over supported codecs and pull out the codecs we want
  // capabilities_a.forEach((codec, idx) => {
  //   if (
  //     codec.mimeType === 'audio/PCMU' ||
  //     codec.mimeType === 'audio/PCMA' ||
  //     codec.mimeType === 'audio/opus'
  //   ) {
  //     audio_codecs.push(codec);
  //   }
  // });
  // transceiver_a.setCodecPreferences(audio_codecs);

  peerConnection.ontrack = ({ streams: [stream] }) => {
    store.dispatch(setLiveStreams({ id: deviceId, stream: stream }));
    if (stream.getVideoTracks().length) {
      const track = stream.getVideoTracks()[0];
      const video = document.createElement('video');
      video.setAttribute('id', 'video-c-' + deviceId);
      video.srcObject = new MediaStream([track]);
      const handleLoadedMetadata = () => {
        // const aspectRatio = 16 / 9;
        let width = video.videoWidth;
        let height = video.videoHeight;
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(video, 0, 0, width, height);
        // const imageData = ctx.getImageData(0, 0, width, height);
        const data = {
          StreamHeight: height,
          StreamWidth: width,
          // You may need to handle imageData to send it or process it further
        };
        store.dispatch(
          setStremBitmapsData({ id: deviceId, bitmapObject: data }),
        );
        video.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
      video.addEventListener('loadedmetadata', handleLoadedMetadata);
      video.play().catch((error) => {
        Utils.vmsLogger().error('Error playing video: ', error);
      });
    }
    // if playback stream found for device it will remove device id from no playback store
    let noLiveStreamDevice = [
      ...store.getState(getNoPlaybackContentAvailable)?.views
        ?.noPlaybackContentAvailable,
    ];
    noLiveStreamDevice?.forEach((data, index, object) => {
      if (data === deviceId) {
        object.splice(index, 1);
      }
    });
    store.dispatch(setNoPlaybackContentAvailable(noLiveStreamDevice));
  };

  peerConnection.onicecandidate = (event) => {
    if (event.candidate) {
      if (oneIceSend) {
        if (!isLocalIceSent.includes(deviceId)) {
          // Utils.vmsLogger().log('Playback: Multi live candidate local ', deviceId, getTimesinmili());
          isLocalIceSent.push(deviceId);
          wsConnection.sendWebRTCCandidate(event, deviceId);
        }
      } else {
        // Utils.vmsLogger().log('Playback: Multi live candidate local ', deviceId, getTimesinmili());
        wsConnection.sendWebRTCCandidate(event, deviceId);
      }
    }
  };

  peerConnection.onconnectionstatechange = (event) => {
    if (peerConnection.connectionState === 'connected') {
      Utils.vmsLogger().log(
        'Playback: multi live peer connection connected',
        deviceId,
        '-',
        getTimesinmili(),
      );
    } else if (
      peerConnection.connectionState === 'disconnected' ||
      peerConnection.connectionState === 'failed'
    ) {
      Utils.vmsLogger().log(
        'Playback: multi live peer connection failed',
        deviceId,
        '-',
        getTimesinmili(),
      );
      wsConnection.connectWithWebSocket(
        cdnDetails,
        deviceId,
        orgId,
        accountId,
        true,
      );
    }
  };
  rtcPeerConnections[deviceId] = peerConnection;
  peerConnection.addTransceiver('video', {
    direction: 'recvonly',
  });

  peerConnection.addTransceiver('audio', {
    direction: 'recvonly',
  });

  // setTimeout(() => {
  sendOffer(deviceId);
  // }, 100);
};

const getTimesinmili = () => {
  const time =
    new Date().getHours() +
    ':' +
    new Date().getMinutes() +
    ':' +
    new Date().getSeconds() +
    ':' +
    new Date().getMilliseconds();
  return time;
};

const sendOffer = async (deviceId) => {
  const peerConnection = rtcPeerConnections?.[deviceId];
  peerConnection.onnegotiationneeded = async (ev) => {
    peerConnection
      .createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      })
      .then((offer) => {
        peerConnection.setLocalDescription(offer);
        // Utils.vmsLogger().log('Playback: Multi live send offer', deviceId, getTimesinmili());
        wsConnection.sendWebRTCOffer(offer, deviceId);
      })
      .catch((err) => {
        // handle error
        Utils.vmsLogger().error(
          'Playback: multi live error occurred while creating offer',
          err,
        );
      });
  };

  // const offer = await peerConnection.createOffer({
  //   offerToReceiveAudio: true,
  //   offerToReceiveVideo: true,
  // });

  // await peerConnection.setLocalDescription(offer);
  // wsConnection.sendWebRTCOffer(offer, deviceId);
};

export const handleAnswer = async (data, deviceId) => {
  const peerConnection = rtcPeerConnections?.[deviceId];
  await peerConnection
    ?.setRemoteDescription(new RTCSessionDescription(data))
    .then((data) => {
      // Utils.vmsLogger().log('Playback: Multi live handleAnswer', deviceId, getTimesinmili());
    })
    .catch((error) =>
      Utils.vmsLogger().error('Playback: Multi live error', error),
    );
};

export const handleCandidate = async (data, deviceId) => {
  Utils.vmsLogger().log(
    'Playback: Multi live handleCandidate candidate',
    data.candidate.candidate,
    ' ',
    deviceId,
    getTimesinmili(),
  );
  try {
    const peerConnection = rtcPeerConnections?.[deviceId];
    if (peerConnection.localDescription) {
      var candidate = new RTCIceCandidate(data.candidate);
      peerConnection.addIceCandidate(candidate);
    }
  } catch (err) {
    Utils.vmsLogger().error(
      'Playback: MultiLive error occurred when trying to add received ice candidate',
      err,
    );
  }
};

export const handleLeaveCall = async (deviceId) => {
  let peerConnection = rtcPeerConnections?.[deviceId];
  store.dispatch(removeLiveStreams(deviceId));
  if (peerConnection && peerConnection.close) {
    peerConnection.close();
    peerConnection.onicecandidate = null;
    peerConnection.ontrack = null;
    peerConnection = null;
  }
  delete rtcPeerConnections[deviceId];
};
