import { useCallback, useEffect, useRef, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ReactComponent as InfoCircleIcon } from '../../../../assets/images/Info-circle.svg';
import { PiWarningBold } from 'react-icons/pi';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {
  DateSelector,
  ListBlock,
  PrimaryButton,
  SiteToast,
  SiteSpinner,
} from '../../../../components/common';
import {
  constants,
  LicenseStatuses,
  OfferingTypeEnum,
  ServiceTypeEnum,
  Utils,
  AppDefaults,
} from '../../../../helpers';
import useLicensesStore from '../../../../store/LicensesStore';
import timezones from '../../../../data/support/timezone.json';

import './AddLicenseForm.scss';
import { getSelectedOrganization } from '../../../../store/AccountStoreIDB';
import { observerInstance } from '../../../../store/indexDB/observer';
import {
  findTimeZoneFromSelectedValue,
  getRemainingItemsCount,
} from '../../../../helpers/commonUtils';
import useDebouncedCallback from '../../../../hooks/useDebouncedCallback';
import { getOrgInfo } from '../../../../store/reducers/OrganizationsReducer';
import {
  recordingTypes,
  LicenseType,
  EntitlementsTypeEnum,
} from '../../../../helpers/enums';
import { debounce } from 'lodash';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const AddLicenseForm = ({
  isDisplayAddOn,
  deviceId,
  deviceTimeZone,
  orgId,
  expiryDate,
  callBack,
  deviceLicenses,
  deviceShadow,
  sendChildItems,
  userMsgArg,
  availableLicenses,
  mdcDeviceData,
}) => {
  const myDivRef = useRef(null);
  const orgInfo = useSelector(getOrgInfo);
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [productNames, setProductNames] = useState([]);
  const [groupedLicenses, setGroupedLicenses] = useState({});
  const [targetDeviceId, setTargetDeviceId] = useState(deviceId);
  const [licenseKey, setLicenseKey] = useState(null);
  const [existingLicense, setExistingLicense] = useState(null);
  const [selectedLicenseInfo, setSelectedLicenseInfo] = useState({});
  const [productSKUList, setProductSKUList] = useState([]);
  const [productSKUListDetail, setProductSKUListDetail] = useState([]);
  const [skuListLoader, setSKUListLoader] = useState(true);
  const [openAccordion, setOpenAccordion] = useState(null);
  const [productDetailsLoader, setProductDetailsLoader] = useState(true);
  const [loadMoreLoader, setLoadMoreLoader] = useState(false);
  const [showLodaMoreLabel, setShowLodaMoreLabel] = useState(true);
  const [isDateEditable, setIsDateEditable] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  let [queryParams, setQueryParams] = useState('');
  const [activationDate, setActivationDate] = useState(null);
  const sortedDeviceLicenses = [...deviceLicenses].sort(
    (a, b) => a.expiryDate - b.expiryDate,
  );
  const [showLoader, setShowLoader] = useState(false);

  const { licenses, getLicenses, setLicenses } = useLicensesStore();
  // const orgDetails = useSelector(getSelectedOrganization);
  const [orgDetails, setOrgDetails] = useState();
  const timeZones = timezones?.data;

  const loadSelectedOrgData = useCallback(async () => {
    const org = await getSelectedOrganization();
    setOrgDetails(org || {});
    const activeLicense = findActiveLicense(availableLicenses);
    const assignedActivationPendingLicense =
      findAssignedActivationPendingLicense(availableLicenses);
    const gracePeriodLicense = !activeLicense
      ? findGracePeriodLicense(availableLicenses)
      : null;
    // No current or stacked license
    if (
      !activeLicense &&
      !assignedActivationPendingLicense &&
      !gracePeriodLicense
    ) {
      handleNoLicenseAvailable(deviceShadow);
    }
    // If there is an assigned activation pending license
    else if (assignedActivationPendingLicense) {
      handleAssignedActivationPendingLicense(
        assignedActivationPendingLicense,
        activeLicense,
      );
    }
    // If there is an active license
    else if (activeLicense) {
      handleActiveLicense(activeLicense, deviceShadow);
    }
    // If there is a grace period license
    else if (gracePeriodLicense) {
      handleGracePeriodLicense(gracePeriodLicense);
    }

    // logic for existing license starts
    if (assignedActivationPendingLicense) {
      setExistingLicense(assignedActivationPendingLicense);
    } else if (activeLicense) {
      setExistingLicense(activeLicense);
    } else if (gracePeriodLicense) {
      setExistingLicense(gracePeriodLicense);
    } else {
      setExistingLicense(null);
    }
    // logic for existing license ends
  }, []);

  useEffect(() => {
    if (Object.values(selectedLicenseInfo)?.length === 0) {
      return;
    } else {
      const currentDate = new Date();
      const currentTimestamp = currentDate.getTime();
      // Algorithm to update activation date and editability based on the selectedLicense and existingLicense
      if (
        !existingLicense ||
        existingLicense.offeringType === OfferingTypeEnum.ONBOARDING
      ) {
        // No current or stacked license
        if (selectedLicenseInfo?.licenseStatus === LicenseStatuses.UNASSIGNED) {
          setActivationDate(currentTimestamp); // Default date: current time
          setIsDateEditable(true); // Allow date modification
        } else {
          // License is ACTIVE_UNASSIGNED
          setActivationDate(selectedLicenseInfo?.activeDate);
          setIsDateEditable(false); // Disable date modification
        }
      } else if (existingLicense?.offeringType === OfferingTypeEnum.EVAL) {
        if (selectedLicenseInfo?.offeringType === OfferingTypeEnum.EVAL) {
          // Stacking allowed, no overriding
          setActivationDate(calculateExpiryDate(existingLicense)); // Default date: existing expiry - grace period
          setIsDateEditable(false); // Disable date modification
        } else {
          // Paid license selected
          setActivationDate(currentTimestamp); // Default date: current time
          setIsDateEditable(true); // Allow date modification
        }
      } else {
        // Both existing and selected licenses are paid
        if (selectedLicenseInfo?.licenseStatus === LicenseStatuses.UNASSIGNED) {
          if (selectedLicenseInfo?.licenseType === LicenseType.ADD_ON) {
            setActivationDate(currentTimestamp); // Default date: current time
            setIsDateEditable(false); // Disable date modification
          } else {
            setActivationDate(calculateExpiryDate(existingLicense)); // Default date: existing expiry - grace period
            setIsDateEditable(false); // Disable date modification
          }
        } else {
          // License is ACTIVE_UNASSIGNED
          setActivationDate(selectedLicenseInfo?.activeDate);
          setIsDateEditable(false); // Disable date modification
        }
      }
    }
  }, [selectedLicenseInfo]);

  const calculateExpiryDate = (deviceLicense) => {
    const expiryDate = moment(
      deviceLicense?.expiryDate -
        deviceLicense?.gracePeriod * AppDefaults.DAY_IN_MILLISECONDS,
    );
    const timeStampToPass = moment(expiryDate);
    return timeStampToPass._i;
  };

  const debouncedLoadSelectedOrgData = useDebouncedCallback(
    loadSelectedOrgData,
    1000,
  );

  useEffect(() => {
    if (userMsgArg && userMsgArg !== '') {
      setShowToast(true);
      scrollToDiv();
      setUserMsg(userMsgArg);
    } else {
      setShowToast(false);
    }
  }, [userMsgArg]);

  useEffect(() => {
    sendChildItems(
      showLoader,
      licenseKey,
      activationDate,
      skuListLoader,
      productNames,
    );
  }, [showLoader, licenseKey, activationDate, skuListLoader, productNames]);

  useEffect(() => {
    if (openAccordion !== null) {
      const productSKUObj = productSKUList[openAccordion];
      fetchProductDetails(productSKUObj);
    }
  }, [openAccordion]);

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'selectedOrganization') {
        await debouncedLoadSelectedOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadSelectedOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadSelectedOrgData]);

  useEffect(() => {
    const generateAvailableLicenses = async () => {
      let organizationLicenses;
      let productNamesSet = new Set();
      let licensesByName = {};
      let unassignedLicenses, productSet;

      if (!Array.isArray(availableLicenses) || availableLicenses?.length < 1) {
        await setLicenses(`/user/orgs/${orgId}/licenses`);
      }

      // Retrieve all VMS System-based licenses only
      organizationLicenses = getLicenses(
        false,
        false,
        ServiceTypeEnum?.VMS_SERVICE,
        false,
      );

      // Filter for unassigned licenses (available)
      unassignedLicenses = organizationLicenses?.filter(
        (license) =>
          license.licenseStatus === LicenseStatuses.UNASSIGNED ||
          license.licenseStatus === LicenseStatuses.ACTIVE_UNASSIGNED,
      );

      unassignedLicenses.forEach((license) =>
        productNamesSet.add(license?.productDisplayName),
      );

      productSet = [...productNamesSet];

      productSet.forEach((productName) => {
        licensesByName[productName] = unassignedLicenses.filter(
          (license) => license.productDisplayName === productName,
        );
      });

      await new Promise((resolve) => {
        setGroupedLicenses(licensesByName);
        resolve();
      });
    };

    generateAvailableLicenses();
  }, []);

  const findActiveLicense = (licenses) => {
    return licenses.find(
      (license) =>
        license?.licenseStatus === LicenseStatuses.ACTIVE &&
        license?.licenseType === LicenseType.REGULAR &&
        license?.deviceId === deviceId,
    );
  };

  const findAssignedActivationPendingLicense = (licenses) => {
    const sortedLicenses = licenses?.sort(
      (a, b) => a?.expiryDate - b?.expiryDate,
    );
    const filteredLicenses = sortedLicenses?.filter(
      (license) =>
        license?.licenseStatus ===
          LicenseStatuses.ASSIGNED_ACTIVATION_PENDING &&
        license?.licenseType === LicenseType.REGULAR &&
        license?.deviceId === deviceId,
    );
    return filteredLicenses[filteredLicenses?.length - 1];
  };

  const findGracePeriodLicense = (licenses) => {
    const currentDate = new Date();
    const currentTimestamp = currentDate.getTime();
    const sortedLicenses = licenses?.sort(
      (a, b) => a?.expiryDate - b?.expiryDate,
    );
    const filteredLicenses = sortedLicenses?.filter(
      (license) =>
        license?.licenseStatus === LicenseStatuses.EXPIRED &&
        currentTimestamp <= license?.expiryDate &&
        license?.licenseType === LicenseType.REGULAR &&
        license?.offeringType === OfferingTypeEnum.PAID &&
        license?.deviceId === deviceId,
    );
    return filteredLicenses[filteredLicenses?.length - 1];
  };

  const handleNoLicenseAvailable = (deviceShadow) => {
    // No current or stacked license
    const recVersion = deviceShadow?.recVersion;
    if (
      mdcDeviceData &&
      mdcDeviceData?.isMDCdevice &&
      mdcDeviceData?.mdcEntitlementType?.type ===
        EntitlementsTypeEnum?.EDGE_STORAGE_CVR
    ) {
      const recordingType = recordingTypes.EDGE_RECORDING;
      const licenseStatus = [
        LicenseStatuses.UNASSIGNED,
        LicenseStatuses.ACTIVE_UNASSIGNED,
      ];
      const serviceIds = ServiceTypeEnum.VMS_SERVICE;
      const offeringType = [OfferingTypeEnum.EVAL, OfferingTypeEnum.PAID];
      const licenseType = LicenseType.REGULAR;
      getSKUAPI(
        recordingType,
        licenseStatus,
        serviceIds,
        offeringType,
        licenseType,
      );
    } else if (
      mdcDeviceData &&
      mdcDeviceData?.isMDCdevice &&
      mdcDeviceData?.mdcEntitlementType?.type ===
        EntitlementsTypeEnum?.CLOUD_STORAGE_CVR
    ) {
      const recordingType = recordingTypes.DIRECT_TO_CLOUD;
      const licenseStatus = [
        LicenseStatuses.UNASSIGNED,
        LicenseStatuses.ACTIVE_UNASSIGNED,
      ];
      const serviceIds = ServiceTypeEnum.VMS_SERVICE;
      const offeringType = [OfferingTypeEnum.EVAL, OfferingTypeEnum.PAID];
      const licenseType = LicenseType.REGULAR;
      getSKUAPI(
        recordingType,
        licenseStatus,
        serviceIds,
        offeringType,
        licenseType,
      );
    } else {
      if (recVersion === '3') {
        const recordingType = [
          recordingTypes.DIRECT_TO_CLOUD,
          recordingTypes.EDGE_RECORDING,
        ];
        const licenseStatus = [
          LicenseStatuses.UNASSIGNED,
          LicenseStatuses.ACTIVE_UNASSIGNED,
        ];
        const serviceIds = ServiceTypeEnum.VMS_SERVICE;
        const offeringType = [OfferingTypeEnum.EVAL, OfferingTypeEnum.PAID];
        const licenseType = LicenseType.REGULAR;
        getSKUAPI(
          recordingType,
          licenseStatus,
          serviceIds,
          offeringType,
          licenseType,
        );
      } else if (recVersion === '2') {
        const recordingType = recordingTypes.EDGE_RECORDING;
        const licenseStatus = [
          LicenseStatuses.UNASSIGNED,
          LicenseStatuses.ACTIVE_UNASSIGNED,
        ];
        const serviceIds = ServiceTypeEnum.VMS_SERVICE;
        const offeringType = [OfferingTypeEnum.EVAL, OfferingTypeEnum.PAID];
        const licenseType = LicenseType.REGULAR;
        getSKUAPI(
          recordingType,
          licenseStatus,
          serviceIds,
          offeringType,
          licenseType,
        );
      } else {
        // considering recVersion is not available or is less than 2
        const recordingType = recordingTypes.DIRECT_TO_CLOUD;
        const licenseStatus = [
          LicenseStatuses.UNASSIGNED,
          LicenseStatuses.ACTIVE_UNASSIGNED,
        ];
        const serviceIds = ServiceTypeEnum.VMS_SERVICE;
        const offeringType = [OfferingTypeEnum.EVAL, OfferingTypeEnum.PAID];
        const licenseType = LicenseType.REGULAR;
        getSKUAPI(
          recordingType,
          licenseStatus,
          serviceIds,
          offeringType,
          licenseType,
        );
      }
    }
  };

  const handleActiveLicense = (activeLicense, deviceShadow) => {
    if (activeLicense?.offeringType === OfferingTypeEnum.ONBOARDING) {
      // Trial license so list of licenses based on recVersion
      const recVersion = deviceShadow?.recVersion;
      if (recVersion === '3') {
        const recordingType = [
          recordingTypes.DIRECT_TO_CLOUD,
          recordingTypes.EDGE_RECORDING,
        ];
        const licenseStatus = [
          LicenseStatuses.UNASSIGNED,
          LicenseStatuses.ACTIVE_UNASSIGNED,
        ];
        const serviceIds = ServiceTypeEnum.VMS_SERVICE;
        const offeringType = [OfferingTypeEnum.EVAL, OfferingTypeEnum.PAID];
        const licenseType = LicenseType.REGULAR;
        getSKUAPI(
          recordingType,
          licenseStatus,
          serviceIds,
          offeringType,
          licenseType,
        );
      } else if (recVersion === '2') {
        const recordingType = recordingTypes.EDGE_RECORDING;
        const licenseStatus = [
          LicenseStatuses.UNASSIGNED,
          LicenseStatuses.ACTIVE_UNASSIGNED,
        ];
        const serviceIds = ServiceTypeEnum.VMS_SERVICE;
        const offeringType = [OfferingTypeEnum.EVAL, OfferingTypeEnum.PAID];
        const licenseType = LicenseType.REGULAR;
        getSKUAPI(
          recordingType,
          licenseStatus,
          serviceIds,
          offeringType,
          licenseType,
        );
      } else {
        // considering recVersion is not available or is less than 2
        const recordingType = recordingTypes.DIRECT_TO_CLOUD;
        const licenseStatus = [
          LicenseStatuses.UNASSIGNED,
          LicenseStatuses.ACTIVE_UNASSIGNED,
        ];
        const serviceIds = ServiceTypeEnum.VMS_SERVICE;
        const offeringType = [OfferingTypeEnum.EVAL, OfferingTypeEnum.PAID];
        const licenseType = LicenseType.REGULAR;
        getSKUAPI(
          recordingType,
          licenseStatus,
          serviceIds,
          offeringType,
          licenseType,
        );
      }
    } else if (activeLicense?.offeringType === OfferingTypeEnum.EVAL) {
      if (activeLicense?.recordingType === recordingTypes.EDGE_RECORDING) {
        const recordingType = recordingTypes.EDGE_RECORDING;
        const licenseStatus = LicenseStatuses.UNASSIGNED;
        const serviceIds = ServiceTypeEnum.VMS_SERVICE;
        const offeringType = [OfferingTypeEnum.EVAL, OfferingTypeEnum.PAID];
        const licenseType = LicenseType.REGULAR;
        getSKUAPI(
          recordingType,
          licenseStatus,
          serviceIds,
          offeringType,
          licenseType,
        );
      } else {
        // Implement filtering logic for D2C licenses
        const recordingType = recordingTypes.DIRECT_TO_CLOUD;
        const licenseStatus = LicenseStatuses.UNASSIGNED;
        const serviceIds = ServiceTypeEnum.VMS_SERVICE;
        const offeringType = [OfferingTypeEnum.EVAL, OfferingTypeEnum.PAID];
        const licenseType = LicenseType.REGULAR;
        getSKUAPI(
          recordingType,
          licenseStatus,
          serviceIds,
          offeringType,
          licenseType,
        );
      }
    } else {
      // Considering existing active is paid license
      if (activeLicense?.recordingType === recordingTypes.EDGE_RECORDING) {
        if (isDisplayAddOn) {
          const recordingType = recordingTypes.CLOUD_BACKUP;
          const licenseStatus = [
            LicenseStatuses.UNASSIGNED,
            LicenseStatuses.ACTIVE_UNASSIGNED,
          ];
          const serviceIds = ServiceTypeEnum.VMS_SERVICE;
          const offeringType = OfferingTypeEnum.PAID;
          const licenseType = [LicenseType.ADD_ON];
          getSKUAPI(
            recordingType,
            licenseStatus,
            serviceIds,
            offeringType,
            licenseType,
          );
        } else {
          const recordingType = recordingTypes.EDGE_RECORDING;
          const licenseStatus = LicenseStatuses.UNASSIGNED;
          const serviceIds = ServiceTypeEnum.VMS_SERVICE;
          const offeringType = OfferingTypeEnum.PAID;
          const licenseType = [LicenseType.REGULAR];
          getSKUAPI(
            recordingType,
            licenseStatus,
            serviceIds,
            offeringType,
            licenseType,
          );
        }
      } else {
        // Considering activeLicense either doesn't has recording type available OR it is D2C
        const recordingType = recordingTypes.DIRECT_TO_CLOUD;
        const licenseStatus = LicenseStatuses.UNASSIGNED;
        const serviceIds = ServiceTypeEnum.VMS_SERVICE;
        const offeringType = OfferingTypeEnum.PAID;
        const licenseType = LicenseType.REGULAR;
        getSKUAPI(
          recordingType,
          licenseStatus,
          serviceIds,
          offeringType,
          licenseType,
        );
      }
    }
  };

  const handleGracePeriodLicense = (gracePeriodLicense) => {
    // Considering grace period license is always a paid license
    if (gracePeriodLicense?.recordingType === recordingTypes.EDGE_RECORDING) {
      const recordingType = recordingTypes.EDGE_RECORDING;
      const licenseStatus = [
        LicenseStatuses.UNASSIGNED,
        LicenseStatuses.ACTIVE_UNASSIGNED,
      ];
      const serviceIds = ServiceTypeEnum.VMS_SERVICE;
      const offeringType = OfferingTypeEnum.PAID;
      const licenseType = LicenseType.REGULAR;
      getSKUAPI(
        recordingType,
        licenseStatus,
        serviceIds,
        offeringType,
        licenseType,
      );
    } else {
      // Considering gracePeriodLicense either doesn't has recording type available OR it is D2C
      const recordingType = recordingTypes.DIRECT_TO_CLOUD;
      const licenseStatus = [
        LicenseStatuses.UNASSIGNED,
        LicenseStatuses.ACTIVE_UNASSIGNED,
      ];
      const serviceIds = ServiceTypeEnum.VMS_SERVICE;
      const offeringType = OfferingTypeEnum.PAID;
      const licenseType = LicenseType.REGULAR;
      getSKUAPI(
        recordingType,
        licenseStatus,
        serviceIds,
        offeringType,
        licenseType,
      );
    }
  };

  const handleAssignedActivationPendingLicense = (
    assignedActivationPendingLicense,
    activeLicense,
  ) => {
    // Considering a stacked license can be EVAL OR PAID and not ONBOARDING
    if (
      assignedActivationPendingLicense?.offeringType === OfferingTypeEnum.EVAL
    ) {
      if (
        assignedActivationPendingLicense?.recordingType ===
        recordingTypes.EDGE_RECORDING
      ) {
        const recordingType = recordingTypes.EDGE_RECORDING;
        const licenseStatus = LicenseStatuses.UNASSIGNED;
        const serviceIds = ServiceTypeEnum.VMS_SERVICE;
        const offeringType = [OfferingTypeEnum.EVAL, OfferingTypeEnum.PAID];
        const licenseType = LicenseType.REGULAR;
        getSKUAPI(
          recordingType,
          licenseStatus,
          serviceIds,
          offeringType,
          licenseType,
        );
      } else {
        const recordingType = recordingTypes.DIRECT_TO_CLOUD;
        const licenseStatus = LicenseStatuses.UNASSIGNED;
        const serviceIds = ServiceTypeEnum.VMS_SERVICE;
        const offeringType = [OfferingTypeEnum.EVAL, OfferingTypeEnum.PAID];
        const licenseType = LicenseType.REGULAR;
        getSKUAPI(
          recordingType,
          licenseStatus,
          serviceIds,
          offeringType,
          licenseType,
        );
      }
    } else {
      // Considering assignedActivationPendingLicense is paid license
      if (
        assignedActivationPendingLicense?.recordingType ===
        recordingTypes.EDGE_RECORDING
      ) {
        if (activeLicense) {
          if (isDisplayAddOn) {
            const recordingType = recordingTypes.CLOUD_BACKUP;
            const licenseStatus = [
              LicenseStatuses.UNASSIGNED,
              LicenseStatuses.ACTIVE_UNASSIGNED,
            ];
            const serviceIds = ServiceTypeEnum.VMS_SERVICE;
            const offeringType = OfferingTypeEnum.PAID;
            const licenseType = LicenseType.ADD_ON;
            getSKUAPI(
              recordingType,
              licenseStatus,
              serviceIds,
              offeringType,
              licenseType,
            );
          } else {
            const recordingType = recordingTypes.EDGE_RECORDING;
            const licenseStatus = LicenseStatuses.UNASSIGNED;
            const serviceIds = ServiceTypeEnum.VMS_SERVICE;
            const offeringType = OfferingTypeEnum.PAID;
            const licenseType = LicenseType.REGULAR;
            getSKUAPI(
              recordingType,
              licenseStatus,
              serviceIds,
              offeringType,
              licenseType,
            );
          }
        } else {
          const recordingType = recordingTypes.EDGE_RECORDING;
          const licenseStatus = LicenseStatuses.UNASSIGNED;
          const serviceIds = ServiceTypeEnum.VMS_SERVICE;
          const offeringType = OfferingTypeEnum.PAID;
          const licenseType = LicenseType.REGULAR;
          getSKUAPI(
            recordingType,
            licenseStatus,
            serviceIds,
            offeringType,
            licenseType,
          );
        }
      } else {
        // Implement filtering logic for D2C licenses
        const recordingType = recordingTypes.DIRECT_TO_CLOUD;
        const licenseStatus = LicenseStatuses.UNASSIGNED;
        const serviceIds = ServiceTypeEnum.VMS_SERVICE;
        const offeringType = OfferingTypeEnum.PAID;
        const licenseType = LicenseType.REGULAR;
        getSKUAPI(
          recordingType,
          licenseStatus,
          serviceIds,
          offeringType,
          licenseType,
        );
      }
    }
  };

  const getSKUAPI = debounce(
    async (
      recordingType,
      licenseStatus,
      serviceIds,
      offeringType,
      licenseType,
    ) => {
      const filterQueryParams = [
        licenseStatus ? `licenseStatuses=${licenseStatus}` : null,
        serviceIds ? `serviceIds=${serviceIds}` : null,
        offeringType ? `offeringTypes=${offeringType}` : null,
        licenseType ? `licenseTypes=${licenseType}` : null,
        recordingType ? `recordingTypes=${recordingType}` : null,
      ]
        .filter(Boolean)
        .join('&');

      queryParams = filterQueryParams;
      setQueryParams(filterQueryParams);
      setSKUListLoader(true);

      try {
        const response = await axios.get(
          `/user/orgs/${orgInfo?.orgId}/products?${queryParams}`,
        );

        if (response?.data?.data) {
          const licensesData = response?.data?.data;
          setProductSKUList(licensesData);
          setSKUListLoader(false);
        } else {
          setProductSKUList([]);
          setSKUListLoader(false);
        }
      } catch (error) {
        setProductSKUList([]);
        setSKUListLoader(false);
        Utils.vmsLogger().log('Error fetching timeline data:', error);
      }
    },
    1000,
  );

  const fetchProductDetails = async (productSKUObj) => {
    let page = 0;
    setPageNumber(page);
    setProductDetailsLoader(true);
    try {
      let updatedQueryParams = queryParams
        .replace(/serviceIds/g, 'serviceIdFilter')
        .replace(/licenseStatuses/g, 'actualLicenseStatuses');

      const response = await axios.get(
        `/user/orgs/${orgId}/licenses/v3?dateColumn=all&size=10&page=${page}&orderBy=createdDate&isAsc=true&${updatedQueryParams}&skus=${productSKUObj?.sku}`,
        Utils.requestHeader(),
      );
      let licensesData = response?.data?.data?.licenses;
      let myObject = {
        sku: productSKUObj?.sku,
        list: licensesData,
      };
      setProductSKUListDetail([]);
      setProductSKUListDetail((prevList) => [...prevList, myObject]);
      setProductDetailsLoader(!(licensesData?.length > 0));
    } catch (error) {
      setProductDetailsLoader(false);
      Utils.vmsLogger().log('Error fetching product details:', error);
    }
  };

  const fetchProductDetailsLoadMore = async (productSKUObj, pageNumberArg) => {
    let page = pageNumberArg ? pageNumberArg : 0;
    setPageNumber(page);
    setLoadMoreLoader(true);
    try {
      let updatedQueryParams = queryParams
        .replace(/serviceIds/g, 'serviceIdFilter')
        .replace(/licenseStatuses/g, 'actualLicenseStatuses');

      const response = await axios.get(
        `/user/orgs/${orgId}/licenses/v3?dateColumn=all&size=10&page=${page}&orderBy=createdDate&isAsc=true&${updatedQueryParams}&skus=${productSKUObj?.sku}`,
        Utils.requestHeader(),
      );
      let licensesData = response?.data?.data?.licenses;
      if (pageNumberArg > 0 && licensesData?.length > 0) {
        let initialList = productSKUListDetail[0]?.list || [];
        licensesData = [...initialList, ...licensesData];
      }
      let myObject = {
        sku: productSKUObj?.sku,
        list: licensesData,
      };
      if (pageNumberArg > 0 && licensesData?.length > 0) {
        setProductSKUListDetail([myObject]);
      }
      setLoadMoreLoader(false);
      if (pageNumberArg > 0 && licensesData?.length === 0) {
        setPageNumber(pageNumberArg - 1);
      }
    } catch (error) {
      setLoadMoreLoader(false);
      Utils.vmsLogger().log('Error fetching product details:', error);
    }
  };

  const daysRemainingCount = (licenses) => {
    const adjustedDate = moment(
      licenses?.expiryDate -
        licenses?.gracePeriod * AppDefaults.DAY_IN_MILLISECONDS,
    );
    const remainingDays = Utils.calculateDaysFromEpoch(adjustedDate);
    return remainingDays < 10
      ? remainingDays +
          ' ' +
          constants.DEVICES_TAB_LICENSES_LICENSE_EXPIRING_DAY_REMAINING_LABEL
      : remainingDays +
          ' ' +
          constants.DEVICES_TAB_LICENSES_LICENSE_EXPIRING_DAYS_REMAINING_LABEL;
  };

  const renderAccordions = () => {
    const accordions = [];
    productSKUList?.forEach((productSKUObj, idx) => {
      accordions.push(
        <Accordion
          className="product-accordion"
          key={`accordion${idx}`}
          expanded={openAccordion === idx}
          onChange={(event, isExpanded) =>
            setOpenAccordion(isExpanded ? idx : null)
          }
        >
          <AccordionSummary
            className="product-summary"
            expandIcon={<ExpandMoreIcon size={16} />}
            aria-controls={`panel${idx}-content`}
            id={`panel${idx}-header`}
          >
            <div>
              <Typography className="product-name">
                {productSKUObj?.displayName}
              </Typography>
              <div className="num-of-licenses">
                {productSKUObj?.skuCount === 1
                  ? Utils.replaceStringValues(
                      constants.ORG_DASHBOARD_LICENSES_ADD_LICENSE_NUMBER_OF_LICENSE_AVAILABLE,
                      '$numOfLicenses',
                      productSKUObj?.skuCount,
                    )
                  : Utils.replaceStringValues(
                      constants.ORG_DASHBOARD_LICENSES_ADD_LICENSE_NUMBER_OF_LICENSES_AVAILABLE,
                      '$numOfLicenses',
                      productSKUObj?.skuCount,
                    )}
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className="product-details">
            {productDetailsLoader ? (
              <div className="spinner-area">
                <SiteSpinner height="12px" width="12px" />{' '}
                <span className="mt-2 spinner-area-label">
                  {constants.LOADING}
                </span>
              </div>
            ) : (
              productSKUListDetail?.length > 0 && (
                <ul className="product-list">
                  {productSKUListDetail
                    ?.filter((item) => item.sku === productSKUObj?.sku)[0]
                    ?.list.map((license, licenseIndex) => (
                      <li key={`license${licenseIndex}`}>
                        <div className="product-list-item-wrapper">
                          <div className="product-list-item">
                            <div>
                              <span className="product-list-item-name">
                                {license?.licenseKey}
                              </span>
                              <br></br>
                              {license?.licenseStatus ===
                                LicenseStatuses.ACTIVE_UNASSIGNED && (
                                <span className="license-remaining-days-label">
                                  <PiWarningBold className="daysRemainingCounticon" />
                                  <span className="daysRemainingLabelArea">
                                    {daysRemainingCount(license)}
                                  </span>
                                </span>
                              )}
                            </div>
                            <div>
                              <RadioGroup
                                aria-labelledby="controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={'value'}
                                onChange={() => {}}
                              >
                                <Radio
                                  className="product-list-item-radio-button"
                                  checked={licenseKey === license?.licenseKey}
                                  onChange={(e) => {
                                    setLicenseKey(license?.licenseKey);
                                    setProductNames(
                                      license?.productDisplayName,
                                    );
                                    setSelectedLicenseInfo(license);
                                  }}
                                  value={license?.licenseKey}
                                  name="radio-buttons"
                                  inputProps={{ 'aria-label': 'A' }}
                                  disabled={false}
                                />
                              </RadioGroup>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              )
            )}
            {!productDetailsLoader &&
              !loadMoreLoader &&
              productSKUListDetail?.length > 0 &&
              productSKUListDetail[0]?.list?.length <
                productSKUList[openAccordion]?.skuCount && (
                <div className="spinner-area">
                  <span
                    className="mt-2 spinner-area-label"
                    onClick={(e) => {
                      e.preventDefault();
                      fetchProductDetailsLoadMore(
                        productSKUObj,
                        pageNumber + 1,
                      );
                    }}
                  >
                    {constants.LIST_TABLE_LOAD_MORE_BTN}
                  </span>
                </div>
              )}
            {!productDetailsLoader && loadMoreLoader && (
              <div className="spinner-area">
                <SiteSpinner height="12px" width="12px" />{' '}
                <span className="mt-2 spinner-area-label">
                  {constants.LOADING}
                </span>
              </div>
            )}
          </AccordionDetails>
        </Accordion>,
      );
    });

    return accordions;
  };

  const scrollToDiv = () => {
    if (myDivRef?.current) {
      myDivRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const setActualTimeInActivationDate = (epochValue) => {
    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const currentSeconds = currentTime.getSeconds();
    const currentMilliseconds = currentTime.getMilliseconds();
    const newDate = new Date(epochValue);
    newDate.setHours(currentHours);
    newDate.setMinutes(currentMinutes);
    newDate.setSeconds(currentSeconds);
    newDate.setMilliseconds(currentMilliseconds);
    setActivationDate(newDate.getTime());
  };

  return (
    <>
      <Container ref={myDivRef} className="scrollable-container">
        {skuListLoader && (
          <div className="spinner-area-attach-license">
            <SiteSpinner className="spinner-center-attach-license" />
            <div className="mt-2">{constants.LOADING}</div>
          </div>
        )}
        {!skuListLoader && productSKUList?.length > 0 && (
          <Row>
            <Col>
              {
                constants.ORG_DASHBOARD_LICENSES_ADD_LICENSE_SELECT_LICENSE_TITLE
              }
            </Col>
          </Row>
        )}
        {!skuListLoader && productSKUList?.length > 0 && (
          <Row>
            <Col>
              <ListBlock
                className="license-block"
                contentWrapperClass="text-start"
              >
                {renderAccordions()}
              </ListBlock>
            </Col>
          </Row>
        )}
        {!skuListLoader && productSKUList?.length === 0 && (
          <Row>
            <Col>
              <ListBlock
                className="license-block no-data-style"
                contentWrapperClass="text-start"
              >
                {constants.SEARCH_DASHBOARD_RESULT_NOT_FOUND}
              </ListBlock>
            </Col>
          </Row>
        )}
        {!skuListLoader && productSKUList?.length > 0 && (
          <div className="license-date-area-style">
            <Row>
              <Col>
                <div>
                  {
                    constants.ORG_DASHBOARD_LICENSES_ADD_LICENSE_LICENSE_ACTIVATION_TITLE
                  }
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="date-selector-wrapper license-date-picker-style">
                  {activationDate != null ? (
                    <DateSelector
                      value={moment(activationDate)}
                      minDate={moment.utc(new Date().getTime())}
                      disableDate={!isDateEditable}
                      handleChange={(epochValue) => {
                        if (epochValue) {
                          setActualTimeInActivationDate(epochValue);
                        }
                      }}
                    />
                  ) : (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label=""
                        value={null}
                        onChange={() => {}}
                        minDate={null}
                        renderInput={(params) => <></>}
                        format="DD/MM/YYYY"
                        placeholder="DD/MM/YYYY"
                        mobileButtons={[
                          {
                            type: 'button',
                            className: 'rmdp-button rmdp-action-button',
                          },
                        ]}
                        className="customized-datepicker"
                        disablePast={false}
                        disabled={true}
                      />
                    </LocalizationProvider>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        )}
        <SiteToast
          customCss="licenses-list-toast"
          position="top-end"
          show={showToast}
          title="Error!"
          body={userMsg}
          delay={5000}
        />
      </Container>
    </>
  );
};

export default AddLicenseForm;
