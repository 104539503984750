import { useCallback, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import {
  BreadcrumbList,
  Header,
  PrimaryButton,
  SiteModal,
  SiteSpinner,
  Tabs,
  TextBlock,
} from '../../../components/common';
import { constants, Utils, roles, AppDefaults } from '../../../helpers';
import PageWrapper from '../../PageWrapper';
import { HubInfoBlock, HubNetworkBlock } from './blocks';
import { useDispatch, useSelector } from 'react-redux';
import {
  // getAllDevicesData,
  getDeviceInformation,
  // getSelectedOrganization,
  setDeviceInformation,
  // setAllDevicesData,
  getAllMqttDataFromResponse,
  resetAllMqttDataFromResponse,
  getAccountId,
} from '../../../store/reducers/AccountReducer';
import {
  setStartDate,
  getPlatformInfo,
  getCDNInfo,
  getMQTTConnection,
} from '../../../store/reducers/StreamingReducer';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import axios from 'axios';
import { useLoggedInUserData } from '../../../store/LoggedInAccountStore';
import {
  getCurrentEventResource,
  mqttSubscribe,
  mqttUnsubscribe,
} from '../../../utils/connection/mqttConnection';
import { TbCloudLock } from 'react-icons/tb';
import './ChannelDetails.scss';
import useLicensesStore from '../../../store/LicensesStore';
import { HiExclamationCircle } from 'react-icons/hi2';
import { ReactComponent as InfoCircleIcon } from '../../../assets/images/Info-circle.svg';
import { RxCross1 } from 'react-icons/rx';
import { devicesMQTTStore } from '../../../store/DevicesMQTTStore';
import { DefaultDevice } from '../../../assets/images';
import {
  getAllDevicesData,
  // getAllMqttDataFromResponse,
  getSelectedOrganization,
  // resetAllMqttDataFromResponse,
  setAllDevicesData,
} from '../../../store/AccountStoreIDB';
import { observerInstance } from '../../../store/indexDB/observer';
import { usePoliciesStore } from '../../../store/policiesStore';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import { getOrganizations } from '../../../store/OrganizationsStoreIDB';
import { getOrgInfo } from '../../../store/reducers/OrganizationsReducer';

const ChannelDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const orgInfo = useSelector(getOrgInfo);
  const isRedirectToNetwork = location?.state?.isRedirectToNetwork || false;
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  // const [allMqttData, setAllMqttData] = useState([]);
  const accountId = useSelector(getAccountId);
  let [searchParams] = useSearchParams();
  const deviceId = searchParams.get('deviceId');
  // const orgDetails = useSelector(getSelectedOrganization);
  const [orgDetails, setOrgDetails] = useState();
  const deviceDetails = useSelector(getDeviceInformation);
  // const devicesDetails = useSelector(getAllDevicesData);
  const [devicesDetails, setDevicesDetails] = useState([]);
  const dispatch = useDispatch();
  const [showAddLicenseModal, setShowAddLicenseModal] = useState(false);
  const [activeSection, setActiveSection] = useState(0);
  const [saveTabSettingsDataFlag, setSaveTabSettingsDataFlag] = useState(false);
  const [canAttachLicense, setCanAttachLicense] = useState(
    deviceDetails?.claimed && deviceDetails?.apps?.vms,
  );
  const [moveLocationData, setMoveLocationData] = useState({});
  const [showDeviceRemoveModal, setShowDeviceRemoveModal] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const platformDetails = useSelector(getPlatformInfo);
  const { setLicenses } = useLicensesStore();
  const publishTopic = platformDetails?.mqtt?.topic_details?.publish?.settings;
  const subscribeTopic =
    platformDetails?.mqtt?.topic_details?.subscribe?.settings;
  const { getState } = devicesMQTTStore;
  const state = getState();
  const sessionId = state.getSessionId();
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [isReload, setIsReload] = useState(false);
  const [isFromDownloadLog, setIsFromDownloadLog] = useState(false);
  const [deviceDataUpdated, setDeviceDataUpdated] = useState(false);
  const [removeButtonDisableFlag, setRemoveButtonDisableFlag] = useState(true);
  const deviceState = deviceInfo?.deviceStatus;
  const connectionState = deviceInfo?.connectionStatus;
  const MQTTConnectionStatus = useSelector(getMQTTConnection);
  const { getCustomerOrgPolicies } = usePoliciesStore();
  const userPolicies = getCustomerOrgPolicies();
  const [showLoaderCap, setShowLoaderCap] = useState(true);
  const getLoggedInUserUUID = useLoggedInUserData(
    (state) => state.getLoggedInUserUUID,
  );
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData,
  );
  const loggedInUser = getLoggedInUserData();
  const devicePublishId = Utils.replaceStringValues(
    publishTopic?.[0],
    '${deviceId}',
    `${deviceDetails?.gatewayId}`,
  );
  const breadList = [
    {
      url:
        loggedInUser?.orgType === AppDefaults.ORG_TYPE_INSTALLER
          ? '/customers/manage.html'
          : `/customers/dashboard.html?orgId=${orgInfo?.orgId}`,
      title:
        loggedInUser.role !== roles.ROLE2VMS
          ? constants.MANAGE_ORG_PAGE_TITLE
          : constants.MANAGE_ORG_PAGE_ORG_TITLE,
    },
    {
      url: `/customers/dashboard.html?orgId=${orgInfo?.orgId}`,
      title: `${orgInfo?.orgName}`,
      disable: userPolicies?.view_cust_org_dashboard ? false : true,
    },
    {
      url: `/devices/listing.html?orgId=${orgInfo?.orgId}`,
      title: constants.DEVICES_PAGE_TITLE,
    },
    {
      url: '/devices/channel-details.html?deviceId',
      title: `${deviceDetails?.deviceName}`,
    },
  ];

  const loadSelectedOrgData = useCallback(async () => {
    const org = await getSelectedOrganization();
    setOrgDetails(org || {});
  }, []);

  // const loadAllMqttData = useCallback(async () => {
  //   const mqttResp = await getAllMqttDataFromResponse();
  //   setAllMqttData(mqttResp || []);
  // }, []);

  const debouncedLoadSelectedOrgData = useDebouncedCallback(
    loadSelectedOrgData,
    1000,
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'selectedOrganization') {
        await debouncedLoadSelectedOrgData();
      }
      // if (data.key === 'allMqttDataFromResponse') {
      //   await loadAllMqttData();
      // }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadSelectedOrgData();
    // loadAllMqttData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadSelectedOrgData]);

  useEffect(() => {
    const fetchDevices = async () => {
      const allDevices = await getAllDevicesData();
      setDevicesDetails(allDevices);
    };

    fetchDevices();
    localStorage.removeItem('lastUpdatedLocationId');
  }, []);

  useEffect(() => {
    if (orgDetails?.orgId) {
      getLicensesCall();
    }
    if (isRedirectToNetwork) {
      setSectionBlock(1);
    }
  }, [orgDetails?.orgId]);

  useEffect(() => {
    if (
      MQTTConnectionStatus &&
      Array.isArray(subscribeTopic) &&
      subscribeTopic.length > 0 &&
      deviceDetails?.gatewayId
    ) {
      const deviceSubscription = {
        topic: Utils.replaceStringValues(
          subscribeTopic[0],
          '${deviceId}',
          `${deviceDetails?.gatewayId}`,
        ),
        qos: 0,
      };
      mqttSubscribe(deviceSubscription);

      return () => {
        mqttUnsubscribe(deviceSubscription);
      };
    }
  }, [deviceDetails?.deviceId, MQTTConnectionStatus]);

  const setDownloadLogStatus = (value) => {
    setIsFromDownloadLog(value);
  };

  useEffect(() => {
    if (orgDetails?.orgId && !isFromDownloadLog) {
      setShowLoaderCap(true);
      fetchDeviceData();
    }
  }, [deviceId, isReload, deviceDetails?.deviceId, orgDetails?.orgId]);

  const fetchDeviceData = async () => {
    axios
      .get(
        `device/orgs/${orgDetails?.orgId}/devices/${deviceId}/v2`,
        Utils.requestHeader(),
      )
      .then((response) => {
        const resultData = response.data;
        if (resultData) {
          const responseMeta = resultData.meta;
          const responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            onResponseV2(responseData);
          } else {
            if (deviceDetails?.deviceId === deviceId) {
              onResponseV2(deviceDetails);
            }
            setShowLoaderCap(false);
          }
        } else {
          if (deviceDetails?.deviceId === deviceId) {
            onResponseV2(deviceDetails);
          }
          setShowLoaderCap(false);
        }
      });
  };

  const onResponseV2 = (responseData) => {
    const responseDataWithChildDevices = JSON.parse(
      JSON.stringify(responseData),
    );
    responseDataWithChildDevices.childDevices = Utils.getTotalChildDevices([
      ...(responseData?.channels || []),
      responseData,
    ]);
    getCapabiltiesForDevice(responseDataWithChildDevices);
    let responseAppValue = responseDataWithChildDevices?.apps;
    if (!responseAppValue || Object.keys(responseAppValue).length === 0) {
      setRemoveButtonDisableFlag(false);
    } else {
      let deviceAppsAnyValueTrue = Object.values(responseAppValue).some(
        (value) => value === true,
      );
      if (deviceAppsAnyValueTrue) {
        setRemoveButtonDisableFlag(true);
      } else {
        setRemoveButtonDisableFlag(false);
      }
    }
  };

  const getCapabiltiesForDevice = async (deviceData) => {
    if (deviceData?.capability) {
      if (Object.keys(deviceDetails?.capDetails)?.length !== 0) {
        deviceData.capDetails = deviceDetails.capDetails;
        setDeviceInfo(deviceData);
        if (deviceDetails?.deviceId === deviceId || !deviceDetails) {
          dispatch(setDeviceInformation(deviceData));
        }
        setShowLoaderCap(false);
      } else {
        try {
          let response = await fetch(deviceData?.capability?.url);
          if (response.status === 200) {
            let responseJson = await response.json();
            deviceData.capDetails = responseJson;
            setDeviceInfo(deviceData);
            if (deviceDetails?.deviceId === deviceId || !deviceDetails) {
              dispatch(setDeviceInformation(deviceData));
            }
            setShowLoaderCap(false);
          } else {
            setDeviceInfo(deviceData);
            setShowLoaderCap(false);
          }
        } catch (error) {
          setDeviceInfo(deviceData);
          setShowLoaderCap(false);
        }
      }
    } else {
      setDeviceInfo(deviceData);
      if (deviceDetails?.deviceId === deviceId || !deviceDetails) {
        dispatch(setDeviceInformation(deviceData));
      }
      setShowLoaderCap(false);
    }
  };

  const getLicensesCall = async () => {
    await setLicenses(`/user/orgs/${orgDetails?.orgId}/licenses`);
  };

  useEffect(() => {
    if (
      getCurrentEventResource(allMqttData?.msg?.resource) &&
      deviceId === allMqttData?.from
    ) {
      updateDeviceStore(
        allMqttData?.msg?.properties,
        true,
        allMqttData?.msg?.action !== 'error',
      );
    }
  }, [allMqttData]);

  const updateDeviceStore = async (properties, isFromMqtt, mqttStatus) => {
    let openDeviceDetails = JSON.parse(JSON.stringify(deviceDetails));
    if (openDeviceDetails && openDeviceDetails?.deviceId === deviceId) {
      let deficeInfoProperty = openDeviceDetails?.properties;
      mqttStatus &&
        localStorage.setItem(
          'localStoreProperty',
          JSON.stringify({ ...deficeInfoProperty }),
        );
      let localStoreProperty = JSON.parse(
        localStorage.getItem('localStoreProperty'),
      );
      deficeInfoProperty = mqttStatus
        ? { ...deficeInfoProperty, ...properties }
        : localStoreProperty && {
            ...deficeInfoProperty,
            ...localStoreProperty,
          };
      openDeviceDetails = {
        ...openDeviceDetails,
        properties: deficeInfoProperty,
      };
      if (deviceDetails?.deviceId === deviceId && deviceDetails?.capDetails) {
        openDeviceDetails = {
          ...openDeviceDetails,
          capDetails: deviceDetails?.capDetails,
        };
      }
      let updatedList = devicesDetails?.map((alldevice, index) => {
        if (alldevice.deviceId === openDeviceDetails.deviceId) {
          return {
            ...openDeviceDetails,
          };
        } else {
          return { ...alldevice };
        }
      });
      let dList = await Promise.all(updatedList);
      // dispatch(setAllDevicesData(dList));
      await setAllDevicesData(dList);
      setDeviceInfo(openDeviceDetails);
      dispatch(setDeviceInformation(openDeviceDetails));
      isFromMqtt && (await resetAllMqttDataFromResponse());
    } else {
      Utils.vmsLogger().log(
        'Device with received mqtt message deviceId, is not listed.',
      );
    }
  };

  useEffect(() => {
    if (deviceDetails) {
      const url = deviceDetails?.entitlement?.url;
      if (url) {
        fetchData(url);
      }
    }
  }, [deviceDetails?.deviceId]);

  useEffect(() => {
    if (deviceInfo) {
      updateConnectionStatus();
    }
  }, [devicesDetails]);

  const updateConnectionStatus = () => {
    const deviceD = JSON.parse(JSON.stringify(deviceInfo));
    let isUpdated = false;
    const statusDevice = devicesDetails?.find(
      (deviceL) => deviceL.deviceId === deviceD.deviceId,
    );
    if (
      statusDevice &&
      statusDevice.deviceStatus &&
      statusDevice.deviceStatus !== deviceD.deviceStatus
    ) {
      isUpdated = true;
      deviceD['deviceStatus'] = statusDevice.deviceStatus;
      deviceD['displayDeviceStatus'] = Utils.getDeviceStatus(
        statusDevice.deviceStatus,
        deviceD.connectionStatus,
      );
    }
    if (
      statusDevice &&
      statusDevice.connectionStatus &&
      statusDevice.connectionStatus !== deviceD.connectionStatus
    ) {
      isUpdated = true;
      deviceD['connectionStatus'] = statusDevice.connectionStatus;
      deviceD['displayDeviceStatus'] = Utils.getDeviceStatus(
        deviceD.deviceStatus,
        statusDevice.connectionStatus,
      );
    }
    deviceD.childDevices &&
      deviceD.childDevices.forEach((child) => {
        const childStatusDevice = devicesDetails?.find(
          (deviceL) => deviceL.deviceId === child.deviceId,
        );

        if (
          childStatusDevice &&
          childStatusDevice.deviceStatus &&
          childStatusDevice.deviceStatus !== child.deviceStatus
        ) {
          isUpdated = true;
          child['deviceStatus'] = childStatusDevice.deviceStatus;
          child['displayDeviceStatus'] = Utils.getDeviceStatus(
            childStatusDevice.deviceStatus,
            child.connectionStatus,
          );
        }
        if (
          childStatusDevice &&
          childStatusDevice.connectionStatus &&
          childStatusDevice.connectionStatus !== child.connectionStatus
        ) {
          isUpdated = true;
          child['connectionStatus'] = childStatusDevice.connectionStatus;
          child['displayDeviceStatus'] = Utils.getDeviceStatus(
            child.deviceStatus,
            childStatusDevice.connectionStatus,
          );
        }
      });
    if (isUpdated) {
      setDeviceInfo(deviceD);
    }
  };

  const fetchData = async (url) => {
    fetch(url, {
      withCredentials: true,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          const expiryDate = data?.term?.expiry;
          const durationInDays = data?.term?.data?.durationInDays;
          const startDate =
            expiryDate - Utils.daysToMilliseconds(durationInDays);
          dispatch(setStartDate(startDate));
        }
      });
  };

  const setSectionBlock = (index) => {
    // setIsReload(!isReload);
    setSaveTabSettingsDataFlag(false);
    setActiveSection(index);
  };

  const addLicenseButtonHandler = () => {
    setShowAddLicenseModal(true);
  };

  const saveDeviceInfoSettingsButtonHandler = () => {
    setSaveTabSettingsDataFlag(true);
  };
  const saveDeviceMoveLocationAreaData = (locationData) => {
    setMoveLocationData(locationData);
  };
  const updateStoreDeviceSetting = (properties) => {
    updateDeviceStore(properties, false, true);
  };
  const moveDeviceLocation = async () => {
    try {
      const orgID = deviceDetails?.orgId;
      let lastUpdatedLocationId = localStorage.getItem('lastUpdatedLocationId');
      const locationId = lastUpdatedLocationId
        ? lastUpdatedLocationId
        : moveLocationData?.oldLocationId
          ? moveLocationData?.oldLocationId
          : deviceDetails?.locationId;
      const reqBody = {
        newLocationId: moveLocationData?.newLocationId
          ? moveLocationData?.newLocationId
          : deviceDetails?.locationId,
        deviceName: moveLocationData?.deviceName
          ? moveLocationData?.deviceName
          : deviceDetails?.deviceName,
      };
      const res = await axios.put(
        `/device/orgs/${orgID}/locations/${locationId}/devices/${deviceId}`,
        reqBody,
        Utils.requestHeader(),
      );
      if (res?.data?.meta?.code === 200) {
        if (res?.data?.data?.locationId) {
          localStorage.setItem(
            'lastUpdatedLocationId',
            res?.data?.data?.locationId,
          );
        }
        await axios
          .get(`device/orgs/${orgID}/devices`, Utils.requestHeader())
          .then(async (response) => {
            let resultData = response.data;
            if (resultData) {
              let responseMeta = resultData.meta;
              let responseData = resultData.data;
              if (parseInt(responseMeta?.code) === 200) {
                setDeviceDataUpdated(true);
                await getCapabiltiesFromDeviceList(responseData);
              }
            }
          });
        fetchOrganizationSettingData();
      } else {
        setDeviceDataUpdated(true);
        Utils.vmsLogger().error('ERROR: ', res?.data);
      }
    } catch (error) {
      setDeviceDataUpdated(true);
      Utils.vmsLogger().error('ERROR: ', error);
    }
  };

  const fetchOrganizationSettingData = async () => {
    try {
      await getOrganizations(`/partner/account/self/v3`);
    } catch (error) {
      Utils.vmsLogger().log(error);
    }
  };

  const getCapabiltiesFromDeviceList = async (deviceData) => {
    let deviceList = [...deviceData];
    let newDeviceList = deviceList?.map(async (device, index) => {
      const deviceInfo = devicesDetails?.find(
        (deviceStore) => deviceStore?.deviceId === device?.deviceId,
      );
      let isCapabilityCall =
        devicesDetails?.length === 0 ||
        deviceInfo?.capDetails === undefined ||
        deviceInfo?.capability?.version !== device?.capability?.version;
      if (device?.capability && isCapabilityCall) {
        let response = await fetch(device.capability.url);
        if (response.status === 200) {
          let responseJson = await response.json();
          return { ...device, capDetails: responseJson };
        } else {
          return { ...device };
        }
      } else if (
        device?.capability &&
        deviceInfo?.capability?.version === device?.capability?.version
      ) {
        return { ...device, capDetails: deviceInfo?.capDetails };
      } else {
        return { ...device };
      }
    });
    let deviceCapability = await Promise.all(newDeviceList);
    if (deviceCapability?.length) {
      const currentDeviceDetail = deviceCapability?.find(
        (device) => device.deviceId === deviceId,
      );
      setDeviceInfo(currentDeviceDetail);
      dispatch(setDeviceInformation(currentDeviceDetail));
    }
    await setAllDevicesData(deviceCapability);
  };

  // sectionBlock and buttonGroup must have the same number of keys.
  // Ensure entries in buttonGroup relate to keys in sectionBlock
  const sectionBlock = {
    0: (
      <HubInfoBlock
        orgId={orgDetails?.orgId}
        orgName={orgDetails?.orgName}
        deviceId={deviceId}
        deviceInfos={deviceInfo}
        deviceDetails={deviceDetails}
        saveDeviceSettingsFlag={saveTabSettingsDataFlag}
        accountId={accountId}
        sessionId={sessionId}
        uuid={state?.getSessionId()}
        devicePublishId={devicePublishId}
        saveDeviceSettingsCallback={(value) => {
          saveDeviceInfoSettingsButtonHandler(value);
        }}
        moveDeviceDetailCallback={(locationData) => {
          saveDeviceMoveLocationAreaData(locationData);
        }}
        updateStore={(publishData) => {
          setIsReload(!isReload);
          updateStoreDeviceSetting(publishData);
        }}
        updateFirmware={() => {
          setSectionBlock(1);
        }}
        deviceNameLocationChangeEvent={() => {
          setDeviceDataUpdated(false);
        }}
        hasManagePermission={userPolicies?.manage_device_settings}
      />
    ),
    1: (
      <HubNetworkBlock
        orgId={orgDetails?.orgId}
        orgName={orgDetails?.orgName}
        deviceDetails={deviceDetails}
        deviceId={deviceId}
        deviceInfos={deviceInfo}
        accountId={accountId}
        sessionId={sessionId}
        devicePublishId={devicePublishId}
        setDownloadStatusFlag={(value) => {
          setDownloadLogStatus(value);
        }}
        updateStore={(publishData, avoidReload) => {
          if (avoidReload) {
            setIsReload(!isReload);
          }
          updateStoreDeviceSetting(publishData);
        }}
        hasManagePermission={userPolicies?.manage_device_settings}
      />
    ),
  };

  const deleteDeviceCall = async () => {
    try {
      const res = await axios.delete(
        `/device/orgs/${orgDetails?.orgId}/devices/${deviceId}/v2`,
        Utils.requestHeader(),
      );
      if (
        parseInt(res?.status) === 202 ||
        parseInt(res?.data?.meta?.code) === 200
      ) {
        hideDeviceRemoveModel();
        navigate(`/devices/listing.html?orgId=${orgDetails?.orgId}`);
      } else {
        setUserMsg(res?.meta?.userMsg || res?.data?.meta?.userMsg);
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };

  const onDeleteDevice = () => {
    setShowDeviceRemoveModal(true);
  };

  const hideDeviceRemoveModel = () => {
    setShowDeviceRemoveModal(false);
    setUserMsg('');
  };

  const buttonGroup = {
    0: (
      <>
        <PrimaryButton
          type="button"
          width="150px"
          className="device-btn-space"
          onClick={() => moveDeviceLocation()}
          disabled={
            connectionState !== constants.DEVICES_ONLINE_CONNECTION_STATUS ||
            deviceState === constants.DEVICES_DEACTIVATED_DEVICE_STATUS ||
            !userPolicies?.manage_device_settings ||
            deviceDataUpdated
          }
        >
          {constants.DEVICES_SAVE_CHANGES_BUTTON}
        </PrimaryButton>
      </>
    ),
    4: canAttachLicense ? (
      <PrimaryButton
        type="button"
        width="165px"
        className="device-btn-space"
        onClick={() => addLicenseButtonHandler()}
      >
        {constants.DEVICES_TAB_LICENSES_ATTACH_NEW_LICENSE_LABEL}
      </PrimaryButton>
    ) : (
      <Button
        variant="primary"
        size="sm"
        className="attach-license-btn disabled"
      >
        {constants.DEVICES_TAB_LICENSES_ATTACH_NEW_LICENSE_LABEL}
      </Button>
    ),
  };

  const tabs = [
    {
      icon: <HiOutlineInformationCircle size={22} />,
      label: constants.DEVICES_TAB_DEVICE_INFO,
      clickHandler: (index) => setSectionBlock(index),
    },
    {
      icon: <TbCloudLock size={22} />,
      label: constants.DEVICES_TAB_DEVICE_PASSWORD,
      clickHandler: (index) => setSectionBlock(index),
    },
  ];

  return (
    <div className="App">
      <Header currentDeviceId={deviceId} />
      {showLoaderCap ? (
        <div className="position-absolute top-50 start-50 translate-middle">
          <SiteSpinner height="50px" width="50px" />
          <div className="mt-2 loading-text">{constants.LOADING}</div>
        </div>
      ) : (
        <PageWrapper className="mw-100">
          <div className="page-header mb-5">
            <Container className="container-top-offset mw-100">
              <Row className="g-4 mb-3">
                <Col className="text-start">
                  <BreadcrumbList list={breadList} />
                </Col>
              </Row>
              <Row className="g-4">
                <Col
                  md={6}
                  lg={6}
                  xl={6}
                  xs={12}
                  className="page-title text-start"
                >
                  {deviceDetails?.deviceName}

                  <p className="page-title-note">{orgDetails?.orgName}</p>
                </Col>
                <Col
                  md={6}
                  lg={6}
                  xl={6}
                  xs={12}
                  className="d-flex align-items-md-center justify-content-center justify-content-md-end"
                >
                  {buttonGroup[activeSection]}
                </Col>
              </Row>
              <Row className="channel-details device-tabs">
                <Col>
                  <Tabs defaultActive={activeSection} tabCollection={tabs} />
                </Col>
              </Row>
              <Row className="channel-content">
                <Col md={6} lg={6} xl={6} xs={12}>
                  <div className="channel-icon-container">
                    <img
                      src={
                        deviceDetails?.imageURL ? deviceDetails?.imageURL : ''
                      }
                      onError={(e) => (e.target.src = `${DefaultDevice}`)}
                      alt="device_image"
                      className="device-icon"
                    />
                  </div>
                </Col>
                <Col md={6} lg={6} xl={6} xs={12}>
                  <div
                    className={`details-section-container${
                      activeSection !== 0 && activeSection !== 5
                        ? ' inner-view'
                        : ''
                    }`}
                  >
                    {sectionBlock[activeSection]}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* Want to remove device - modal  */}
          <SiteModal
            modalTitle={
              constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_SURE_TO_REMOVE_MODAL_TITLE
            }
            showModal={showDeviceRemoveModal}
            hideModal={hideDeviceRemoveModel}
            classes="device-settings-restart-required"
          >
            {userMsg && userMsg.length > 0 && (
              <div className="error-container">
                <div className="info-icon-container">
                  <InfoCircleIcon />
                </div>
                <div className="error-body">
                  <div className="error-content">{userMsg}</div>
                  <div className="cross-icon-container">
                    <RxCross1 onClick={() => setUserMsg('')} />
                  </div>
                </div>
              </div>
            )}
            <TextBlock
              className="mb-3"
              fontWeight="400"
              fontSize="1rem"
              lineHeight="24px"
              color={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--greyscale_56')}
            >
              {
                constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_SURE_TO_REMOVE_MODAL_CONTENT
              }
            </TextBlock>

            <>
              <div className="text-center">
                <HiExclamationCircle
                  className="mb-4 icon-warning-color"
                  size={96}
                />
              </div>
              <PrimaryButton
                className="btn btn-primary-outline mb-4"
                type="button"
                width="100%"
                borderWidth="1.5px"
                hoverBorderWidth="1.5px"
                hoverBorderColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--primary_40')}
                color={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--primary_40')}
                hoverColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--brand_white')}
                hoverBackgroundColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--primary_40')}
                backgroundColor="transparent"
                height="56px"
                fontSize="1.125rem"
                lineHeight="24px"
                onClick={hideDeviceRemoveModel}
              >
                {
                  constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CANCEL_BUTTON_TITLE
                }
              </PrimaryButton>
              <PrimaryButton
                className="btn btn-primary"
                type="button"
                width="100%"
                height="56px"
                fontSize="1.125rem"
                lineHeight="24px"
                backgroundColor="transparent"
                hoverBackgroundColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--error_64')}
                borderColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--error_64')}
                hoverBorderColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--error_64')}
                borderWidth="1.5px"
                color={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--error_64')}
                hoverColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--brand_white')}
                onClick={() => {
                  deleteDeviceCall();
                }}
              >
                {constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_MODAL_BUTTON_TITLE}
              </PrimaryButton>
            </>
          </SiteModal>
        </PageWrapper>
      )}
    </div>
  );
};

export default ChannelDetails;
