import { create } from 'zustand';
import axios from 'axios';
import { Utils } from '../helpers';

const useEventsStore = create((set, get) => ({
  eventsData: [],
  eventsAllData: [],
  eventCategory: [],
  selectedTimestamp: null,
  deviceTimezone: null,
  eventFromSearch: false,
  modalContent: [],
  indexContent: '',
  eventCVRMode: false,
  eventsTotal: 0,
  eventsAPICalled: false,
  eventsAllTotal: 0,
  eventsLoading: false,
  eventsAllLoading: false,
  videoFilteredDateObj: {},
  snapshotCoordinates: {},
  selectedRegion: {},
  regions: [],
  pauseVideo: false,
  linkingData: {},

  getEvents: () => {
    return get().eventsData;
  },

  getEventsAPICalled: () => {
    return get().eventsAPICalled;
  },

  getAllEvents: () => {
    return get().eventsAllData;
  },

  resetAllEvents: () => {
    set({
      eventsAllData: [],
    });
    set({
      eventsAllTotal: 0,
    });
  },
  setEventsAPICalled: () => {
    set({
      eventsAPICalled: true,
    });
  },
  setAllEvents: async (route, reqBody) => {
    if (!route) return;
    set({ eventsLoading: true });
    set({ eventsAllLoading: true });
    const res = await axios.post(route, reqBody, Utils.requestHeader());
    const responseData = res?.data;
    let result = { status: 'failure', msg: 'Unknown internal API error' };
    // Set eventsData if request is successful
    if (responseData?.meta?.code === 200) {
      set({
        eventsAPICalled: true,
        eventsAllData: responseData?.data?.events,
      });
      set({
        eventsAllTotal: responseData?.data?.total,
      });
      set({
        eventsData: responseData?.data?.events,
      });
      set({
        eventsTotal: responseData?.data?.total,
      });
      result = { status: 'success', msg: 'Events retrieved`' };
    } else {
      if (res?.code) {
        result = { status: 'failure', msg: `${res.code}: ${res.message}` };
      } else if (responseData?.data) {
        result = { status: 'failure', msg: responseData?.data?.userMsg };
      }
    }
    set({ eventsLoading: false });
    set({ eventsAllLoading: false });
    return result;
  },

  setEvents: async (route, reqBody) => {
    if (!route) return;
    set({ eventsLoading: true });
    const res = await axios.post(route, reqBody, Utils.requestHeader());
    const responseData = res?.data;
    let result = { status: 'failure', msg: 'Unknown internal API error' };

    // Set eventsData if request is successful
    if (responseData?.meta?.code === 200) {
      set({
        eventsData: responseData?.data?.events,
      });
      set({
        eventsTotal: responseData?.data?.total,
      });
      set({
        eventsAllTotal: responseData?.data?.total,
      });

      result = { status: 'success', msg: 'Events retrieved`' };
    } else {
      if (res?.code) {
        result = { status: 'failure', msg: `${res.code}: ${res.message}` };
      } else if (responseData?.data) {
        result = { status: 'failure', msg: responseData?.data?.userMsg };
      }
    }
    set({ eventsLoading: false });
    return result;
  },

  removeEvents: () => set({ eventsData: [] }),

  setEventCategory: (category) => {
    if (!category) return;

    set(() => {
      return {
        eventCategory: category,
      };
    });
  },

  setSelectedEventStore: (timestamp) => {
    if (!timestamp) return;

    set(() => {
      if (timestamp === 'null') {
        return {
          selectedTimestamp: null,
        };
      } else {
        return {
          selectedTimestamp: timestamp,
        };
      }
    });
  },

  setModalEventContent: (data, i) => {
    if (!data) return;

    set(() => {
      return {
        modalContent: data,
        indexContent: i,
      };
    });
  },

  getModalEventContent: () => {
    return get().modalContent;
  },

  getModalIndexContent: () => {
    return get().indexContent;
  },

  setDeviceTimezone: (timezone) => {
    if (!timezone) return;

    set(() => {
      return {
        deviceTimezone: timezone,
      };
    });
  },

  setEventFromSearch: (value) => {
    set(() => {
      return {
        eventFromSearch: value,
      };
    });
  },

  setEventCVRMode: (value) => {
    set(() => {
      return {
        eventCVRMode: value,
      };
    });
  },

  getEventCVRMode: () => {
    return get().eventCVRMode;
  },

  getSelectedEvent: () => {
    return get().selectedTimestamp;
  },

  getEventFromSearch: () => {
    return get().eventFromSearch;
  },
  getEventTotal: () => {
    return get().eventsTotal;
  },
  setEventTotal: (value) => {
    set(() => {
      return {
        eventsTotal: value,
      };
    });
  },
  setEventsLoading: () => {
    set(() => {
      return {
        eventsLoading: true,
      };
    });
  },
  getEventsLoading: () => {
    return get().eventsLoading;
  },
  getEventsAllLoading: () => {
    return get().eventsAllLoading;
  },
  setVideoFilteredDateObj: (value) => {
    set(() => {
      return {
        videoFilteredDateObj: value,
      };
    });
  },

  getVideoFilteredDateObj: () => {
    return get().videoFilteredDateObj;
  },

  setSnapshotCoordinate: async (data) => {
    if (!data) return;

    set({
      snapshotCoordinates: data,
    });
  },

  getSnapshotCoordinates: () => {
    return get().snapshotCoordinates;
  },

  setSelectedRegion: async (data) => {
    if (!data) return;

    set({
      selectedRegion: data,
    });
  },

  getSelectedRegion: () => {
    return get().selectedRegion;
  },

  setRegion: async (data) => {
    if (!data) return;

    set({
      regions: data,
    });
  },

  getRegion: () => {
    return get().regions;
  },

  getPauseVideo: () => {
    return get().pauseVideo;
  },

  setPauseVideo: async (data) => {
    set({
      pauseVideo: data,
    });
  },

  getLinkingData: () => {
    return get().linkingData;
  },

  setLinkingData: (data) => {
    if (!data) return;

    set(() => {
      return {
        linkingData: data,
      };
    });
  },
}));

export default useEventsStore;
