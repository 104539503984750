import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  metaDataForEvents: null,
  latestEventsForDots: null,
};
export const events = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setMetaDataForEvents: (state, action) => {
      state.metaDataForEvents = action.payload;
    },
    setLatestEventsForDots: (state, action) => {
      state.latestEventsForDots = action.payload;
    },
    resetEvents: () => initialState,
  },
});

export const { setMetaDataForEvents, setLatestEventsForDots, resetEvents } =
  events.actions;
export const getMetaDataForEvents = (state) => state.events.metaDataForEvents;
export const getLatestEventsForDots = (state) =>
  state.events.latestEventsForDots;
export default events.reducer;
