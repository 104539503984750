import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  HiOutlineExclamation,
  HiOutlineCalendar,
  HiOutlineCheckCircle,
  HiOutlineXCircle,
  HiOutlineExclamationCircle,
  HiOutlineChevronDown,
} from 'react-icons/hi';
import { FaCircle } from 'react-icons/fa';
import { Button, Col, Container, Row, Dropdown } from 'react-bootstrap';
import moment from 'moment';
import axios from 'axios';
import {
  BreadcrumbList,
  Header,
  SiteSpinner,
  SiteToast,
} from '../../components/common';
import { AddLicense, ListTable } from './';
import {
  constants,
  Utils,
  roles,
  AppDefaults,
  LicenseStatuses,
} from '../../helpers/';
import useLicensesStore from '../../store/LicensesStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import './ManageLicenses.scss';
import { ReactComponent as LicensesIconNoRecord } from '../../assets/images/LicensesIconNoRecord.svg';

const gridFields = [
  {
    field: 'productDisplayName',
    displayName: constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_NAME,
  },
  {
    field: 'licenseKey',
    displayName: constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_LICENSE_ID,
  },
  {
    field: 'licenseStatus',
    displayName: constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_STATUS,
  },
  {
    field: 'licenseDaysRemaining',
    displayName:
      constants.DEVICES_TAB_LICENSES_LICENSE_EXPIRING_DAYS_REMAINING_LABEL,
  },
  {
    field: 'deviceName',
    displayName: constants.DEVICES_DEVICE_NAME_TEXT,
  },
  {
    field: 'locationName',
    displayName: constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_LOCATION,
  },
];

const getColDefs = () => {
  if (!Array.isArray(gridFields)) return;

  return gridFields.map((gridField, gridFieldIndex) => ({
    id: gridFieldIndex,
    field: gridField.field,
    displayName: gridField.displayName,
  }));
};

const defaultFilters = {
  licenseUpdate: [],
  applicationsLinked: [],
  licenseStatusUpdate: [],
  dateFilterUpdate: '',
  dateFilterRange: {},
};

const DATE_FORMAT = 'MMM Do YYYY';

const ManageLicenses = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [allLicensesReceived, setAllLicensesReceived] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [columnDefs] = useState([...getColDefs()]);
  const [rowData, setRowData] = useState([]);
  const [initialRowData, setInitialRowData] = useState([]);
  const [licensesUpdated, setLicensesUpdated] = useState(false);
  const [licensesListUpdated, setLicensesListUpdated] = useState({});
  const [filtersData, setFiltersData] = useState(defaultFilters);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(0);
  const [loadMoreCount, setLoadMoreCount] = useState(0); // New state for load more
  let [orderByArgLastSelected, setOrderByArgLastSelected] = useState();
  let [orderByArgValueLastSelected, setOrderByArgValueLastSelected] =
    useState();
  const [showDropDown, setShowDropDown] = useState(false);
  const filterOptions = [
    { value: 'ACTIVE', label: 'Active' },
    { value: 'AVAILABLE', label: 'Available' },
    { value: 'EXPIRED', label: 'Expired' },
    { value: 'EXPIRING SOON', label: 'Expiring Soon' },
    { value: 'ALL STATUSES', label: 'All Statuses ' },
  ];
  let [selectedOption, setSelectedOption] = useState('ALL STATUSES');
  const defaultPageDetails = {
    orderBy: 'createdDate',
    isAsc: true,
    page: pageNumber,
    size: 100,
    search: false,
    serviceIdFilter: 'vms-service',
    actualLicenseStatuses: 'ALL STATUSES',
  };
  const [pageDetails, setPageDetails] = useState(defaultPageDetails);
  const [sortingState, setSortingState] = useState({
    licenseKey: 'desc',
    licenseStatus: 'desc',
    licenseDaysRemaining: 'desc',
    deviceName: 'desc',
    locationName: 'desc',
  });
  const [sortingStateSelected, setSortingStateSelected] = useState({});
  const orgId = searchParams.get('orgId');
  const orgName = searchParams.get('orgName') || 'Duclo';
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData,
  );
  const handleChange = (event) => {
    const pageDetailsValues = pageDetails;
    pageDetailsValues.licenseStatuses = event;
    setPageDetails(pageDetailsValues);
    setSelectedOption(event);
    fetchData(event);
  };
  const loggedInUser = getLoggedInUserData();
  const breadList = [
    {
      url:
        loggedInUser?.orgType === AppDefaults.ORG_TYPE_INSTALLER
          ? '/customers/manage.html'
          : `/customers/dashboard.html?orgId=${orgId}&orgName=${orgName}`,
      title:
        loggedInUser.role !== roles.ROLE2VMS
          ? constants.MANAGE_ORG_PAGE_TITLE
          : constants.MANAGE_ORG_PAGE_ORG_TITLE,
    },
    {
      url: `/customers/dashboard.html?orgId=${orgId}&orgName=${orgName}`,
      title: `${orgName}`,
    },
    { url: null, title: constants.LICENSES_PAGE_TITLE },
  ];
  const { setLicenses } = useLicensesStore();
  const [licenses, setLicenseData] = useState([]);

  useEffect(() => {
    fetchData();
    getLicensesCall();
  }, []);

  useEffect(() => {
    if (licensesUpdated === true) {
      fetchData();
      setLicensesUpdated(false);
    }
  }, [licensesUpdated]);

  const calculateAdjustedExpiryDate = (deviceLicense) => {
    return deviceLicense?.expiryDate;
  };

  const getLicensesCall = async () => {
    await setLicenses(`/user/orgs/${orgId}/licenses`);
  };

  const transformedLicenses = (filteredPaidLicenses) =>
    filteredPaidLicenses.map((license) => {
      if (license?.gracePeriod > 0 && license?.expiryDate) {
        license.expiryDate = calculateAdjustedExpiryDate(license);
      }
      return {
        ...license,
      };
    });

  const fetchDataOnOrderBy = async (
    licenseStatus,
    OrderByKey,
    OrderByValue,
  ) => {
    const { page, size, orderBy, isAsc, search, serviceIdFilter } = pageDetails;
    const orderByArg = OrderByKey;
    const orderByArgValue = OrderByValue;
    setPageNumber(0);
    orderByArgLastSelected = orderByArg;
    setOrderByArgLastSelected(orderByArg);
    orderByArgValueLastSelected = orderByArgValue;
    setOrderByArgValueLastSelected(orderByArgValue);
    try {
      let filteredPaidLicenses;

      setShowLoader(true);
      let queryParams = '';

      if (
        filtersData?.licenseStatusUpdate?.length ||
        filtersData?.applicationsLinked?.length ||
        filtersData?.licenseUpdate?.length ||
        filtersData?.dateFilterUpdate
      ) {
        const licenseStatusUpdate = filtersData?.licenseStatusUpdate?.join(',');
        const applicationsLinked = filtersData?.applicationsLinked?.join(',');
        const licenseUpdate = filtersData?.licenseUpdate?.join(',');

        const filterQueryParams = [
          filtersData?.dateFilterUpdate !== 'all' && filtersData?.startDate
            ? `startTime=${filtersData.startDate}`
            : null,
          filtersData?.dateFilterUpdate !== 'all' && filtersData?.endDate
            ? `endTime=${filtersData.endDate}`
            : null,
          licenseStatusUpdate
            ? `licenseStatuses=${encodeURIComponent(licenseStatusUpdate)}`
            : null,
          applicationsLinked ? `skus=${applicationsLinked}` : null,
          licenseUpdate ? `offeringTypes=${licenseUpdate}` : null,
        ]
          .filter(Boolean)
          .join('&');
        queryParams = filterQueryParams;
      }

      const additionalQueryParams = [
        'dateColumn=all',
        size !== undefined && size !== false ? `size=${size}` : null,
        page !== undefined && page !== false ? `page=0` : null,
        orderBy !== undefined && orderBy !== false
          ? `orderBy=${orderByArg}`
          : null,
        isAsc !== undefined ? `isAsc=${orderByArgValue}` : null,
        search !== undefined && search !== false ? `search=${search}` : null,
        serviceIdFilter !== undefined && orderBy !== false
          ? `serviceIdFilter=${serviceIdFilter}`
          : null,
        licenseStatus !== 'ALL STATUSES' &&
        licenseStatus !== undefined &&
        orderByArg !== false
          ? `licenseStatuses=${licenseStatus}`
          : null,
      ]
        .filter(Boolean)
        .join('&');

      if (additionalQueryParams) {
        queryParams = queryParams
          ? `${queryParams}&${additionalQueryParams}`
          : additionalQueryParams;
      }
      const res = await axios.get(
        `/user/orgs/${orgId}/licenses/v3?${queryParams}`,
        Utils.requestHeader(),
      );
      const licenseData = res?.data?.data?.licenses;
      filteredPaidLicenses = transformedLicenses(licenseData);
      if (filteredPaidLicenses?.length > 0) {
        setLicenseData(licenseData);
        const tableRowData = Array.isArray(filteredPaidLicenses)
          ? getRowData(filteredPaidLicenses)
          : [];
        setRowData(tableRowData);
      } else {
        setRowData([]);
      }
      setAllLicensesReceived(filteredPaidLicenses?.length < 100);
    } catch (error) {
      Utils.vmsLogger().error('ERROR: ', error);
    } finally {
      setShowLoader(false);
    }
  };

  const fetchData = async (licenseStatus) => {
    const { page, size, orderBy, isAsc, search, serviceIdFilter } = pageDetails;
    try {
      let filteredPaidLicenses;

      setShowLoader(true);
      let queryParams = '';
      if (
        filtersData?.licenseStatusUpdate?.length ||
        filtersData?.applicationsLinked?.length ||
        filtersData?.licenseUpdate?.length ||
        filtersData?.dateFilterUpdate
      ) {
        const licenseStatusUpdate = filtersData?.licenseStatusUpdate?.join(',');
        const applicationsLinked = filtersData?.applicationsLinked?.join(',');
        const licenseUpdate = filtersData?.licenseUpdate?.join(',');

        const filterQueryParams = [
          filtersData?.dateFilterUpdate !== 'all' && filtersData?.startDate
            ? `startTime=${filtersData.startDate}`
            : null,
          filtersData?.dateFilterUpdate !== 'all' && filtersData?.endDate
            ? `endTime=${filtersData.endDate}`
            : null,
          licenseStatusUpdate
            ? `licenseStatuses=${encodeURIComponent(licenseStatusUpdate)}`
            : null,
          applicationsLinked ? `skus=${applicationsLinked}` : null,
          licenseUpdate ? `offeringTypes=${licenseUpdate}` : null,
        ]
          .filter(Boolean)
          .join('&');
        queryParams = filterQueryParams;
      }

      const additionalQueryParams = [
        'dateColumn=all',
        size !== undefined && size !== false ? `size=${size}` : null,
        page !== undefined && page !== false ? `page=${page}` : null,
        orderBy !== undefined && orderBy !== false
          ? `orderBy=${orderByArgLastSelected ?? orderBy}`
          : null,
        isAsc !== undefined
          ? `isAsc=${orderByArgValueLastSelected ?? isAsc}`
          : null,
        search !== undefined && search !== false ? `search=${search}` : null,
        serviceIdFilter !== undefined && orderBy !== false
          ? `serviceIdFilter=${serviceIdFilter}`
          : null,
        licenseStatus !== 'ALL STATUSES' &&
        licenseStatus !== undefined &&
        orderBy !== false
          ? `licenseStatuses=${licenseStatus}`
          : null,
      ]
        .filter(Boolean)
        .join('&');

      if (additionalQueryParams) {
        queryParams = queryParams
          ? `${queryParams}&${additionalQueryParams}`
          : additionalQueryParams;
      }
      const res = await axios.get(
        `/user/orgs/${orgId}/licenses/v3?${queryParams}`,
        Utils.requestHeader(),
      );
      const licenseData = res?.data?.data?.licenses;
      filteredPaidLicenses = transformedLicenses(licenseData);
      if (filteredPaidLicenses?.length > 0) {
        setLicenseData(licenseData);
        const tableRowData = Array.isArray(filteredPaidLicenses)
          ? getRowData(filteredPaidLicenses)
          : [];
        if (page === 0) {
          setInitialRowData(tableRowData);
        }
        setRowData(tableRowData);
      } else {
        setRowData([]);
      }
      setAllLicensesReceived(filteredPaidLicenses?.length < 100);
    } catch (error) {
      setAllLicensesReceived(true);
      Utils.vmsLogger().error('ERROR: ', error);
    } finally {
      setShowLoader(false);
    }
  };

  const loadMoreData = async () => {
    const updatedPage = pageNumber + 1;
    setPageNumber(updatedPage);
    const { page, size, orderBy, isAsc, search, serviceIdFilter } = pageDetails;
    try {
      let filteredPaidLicenses;

      setShowLoadMore(true);
      let queryParams = '';

      if (
        filtersData?.licenseStatusUpdate?.length ||
        filtersData?.applicationsLinked?.length ||
        filtersData?.licenseUpdate?.length ||
        filtersData?.dateFilterUpdate
      ) {
        const licenseStatusUpdate = filtersData?.licenseStatusUpdate?.join(',');
        const applicationsLinked = filtersData?.applicationsLinked?.join(',');
        const licenseUpdate = filtersData?.licenseUpdate?.join(',');

        const filterQueryParams = [
          filtersData?.dateFilterUpdate !== 'all' && filtersData?.startDate
            ? `startTime=${filtersData.startDate}`
            : null,
          filtersData?.dateFilterUpdate !== 'all' && filtersData?.endDate
            ? `endTime=${filtersData.endDate}`
            : null,
          licenseStatusUpdate
            ? `licenseStatuses=${encodeURIComponent(licenseStatusUpdate)}`
            : null,
          applicationsLinked ? `skus=${applicationsLinked}` : null,
          licenseUpdate ? `offeringTypes=${licenseUpdate}` : null,
        ]
          .filter(Boolean)
          .join('&');
        queryParams = filterQueryParams;
      }

      const additionalQueryParams = [
        'dateColumn=all',
        size !== undefined && size !== false ? `size=${size}` : null,
        page !== undefined && page !== false ? `page=${updatedPage}` : null,
        orderBy !== undefined && orderBy !== false
          ? `orderBy=${orderByArgLastSelected ?? orderBy}`
          : null,
        isAsc !== undefined
          ? `isAsc=${orderByArgValueLastSelected ?? isAsc}`
          : null,
        search !== undefined && search !== false ? `search=${search}` : null,
        serviceIdFilter !== undefined && orderBy !== false
          ? `serviceIdFilter=${serviceIdFilter}`
          : null,
        selectedOption !== 'ALL STATUSES' &&
        selectedOption !== undefined &&
        orderBy !== false
          ? `licenseStatuses=${selectedOption}`
          : null,
      ]
        .filter(Boolean)
        .join('&');

      if (additionalQueryParams) {
        queryParams = queryParams
          ? `${queryParams}&${additionalQueryParams}`
          : additionalQueryParams;
      }
      const res = await axios.get(
        `/user/orgs/${orgId}/licenses/v3?${queryParams}`,
        Utils.requestHeader(),
      );
      const licenseData = res?.data?.data?.licenses;
      setAllLicensesReceived(licenseData?.length === 0);
      const combinedLicenses = licenses.concat(licenseData);
      setLicenses(combinedLicenses);
      setLicenseData(combinedLicenses);
      filteredPaidLicenses = transformedLicenses(combinedLicenses);
      if (filteredPaidLicenses?.length > 0) {
        const tableRowData = Array.isArray(filteredPaidLicenses)
          ? getRowData(filteredPaidLicenses)
          : [];

        setRowData(tableRowData);
      }
      setLoadMoreCount((prevCount) => prevCount + 1); // Increment load more count
    } catch (error) {
      Utils.vmsLogger().error('ERROR: ', error);
      setUserMsg(error);
      setShowLoadMore(false);
    } finally {
      setShowLoadMore(false);
    }
  };

  const statusMap = {
    UNASSIGNED: constants.LICENSES_PAGE_GRID_LICENSE_STATUS_AVAILABLE,
    ASSIGNED_ACTIVATION_PENDING:
      constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVATION_PENDING,
    ACTIVE: constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVE,
    ACTIVE_UNASSIGNED: constants.LICENSES_PAGE_GRID_LICENSE_STATUS_AVAILABLE,
    EXPIRING_SOON: constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRING_SOON,
    EXPIRED: constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRED,
  };

  const getRemainingDays = (license) => {
    if (license?.expiryDate) {
      const expiryDate = license?.expiryDate;
      const gracePeriod = license?.gracePeriod ? license?.gracePeriod : 0;
      const currentTimestamp = Date.now();
      const actualDate =
        expiryDate - gracePeriod * AppDefaults.DAY_IN_MILLISECONDS;
      const differenceInMilliseconds = actualDate - currentTimestamp;
      const diffInDays = Math.floor(
        differenceInMilliseconds / AppDefaults.DAY_IN_MILLISECONDS,
      );
      const finalCount =
        differenceInMilliseconds > 0 && diffInDays === 0 ? 1 : diffInDays;
      const result =
        finalCount <= 0 || isNaN(finalCount)
          ? '0 Day'
          : finalCount + ' ' + (finalCount === 1 ? 'Day' : 'Days');
      return result;
    } else {
      return '0 Day';
    }
  };

  const getRowData = (licenses) => {
    let licenseProps = {};
    const rowData = [];
    const licenseStatusMap = {
      AVAILABLE: (
        <div className="status-field">
          <HiOutlineCheckCircle
            size={15}
            stroke={getComputedStyle(document.documentElement).getPropertyValue(
              '--success_48',
            )}
          />
          <span className="available-license-status">
            {constants.LICENSES_PAGE_GRID_LICENSE_STATUS_AVAILABLE}
          </span>
        </div>
      ),
      UNASSIGNED: (
        <div className="status-field">
          <HiOutlineCheckCircle
            size={15}
            stroke={getComputedStyle(document.documentElement).getPropertyValue(
              '--success_48',
            )}
          />
          <span className="available-license-status">
            {constants.LICENSES_PAGE_GRID_LICENSE_STATUS_UNASSIGNED}
          </span>
        </div>
      ),
      ASSIGNED_ACTIVATION_PENDING: (effectiveStartUnixTime) => (
        <div className="status-field">
          <HiOutlineCheckCircle
            size={15}
            stroke={getComputedStyle(document.documentElement).getPropertyValue(
              '--greyscale_08',
            )}
          />
          <span className="active-license-status">
            {
              constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ASSIGNED_ACTIVATION_PENDING
            }
          </span>
        </div>
      ),
      ACTIVE: (
        <div className="status-field">
          <HiOutlineCheckCircle
            size={15}
            stroke={getComputedStyle(document.documentElement).getPropertyValue(
              '--greyscale_08',
            )}
          />
          <span className="active-license-status">
            {constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVE}
          </span>
        </div>
      ),
      ACTIVE_UNASSIGNED: (
        <div className="status-field">
          <HiOutlineCheckCircle
            size={15}
            stroke={getComputedStyle(document.documentElement).getPropertyValue(
              '--success_48',
            )}
          />
          <span className="available-license-status">
            {constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVE_UNASSIGNED}
          </span>
        </div>
      ),
      EXPIRING_SOON: (
        <div className="status-field">
          <HiOutlineExclamationCircle
            size={15}
            stroke={getComputedStyle(document.documentElement).getPropertyValue(
              '--warning_48',
            )}
          />
          <span className="expiring-soon-license-status">
            {constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRING_SOON}
          </span>
        </div>
      ),
      EXPIRED: (
        <div className="status-field">
          <HiOutlineXCircle
            size={15}
            stroke={getComputedStyle(document.documentElement).getPropertyValue(
              '--error_48',
            )}
          />
          <span className="expired-license-status">
            {constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRED}
          </span>
        </div>
      ),
    };

    if (!Array.isArray(licenses)) return;

    licenses?.forEach((license) => {
      columnDefs.forEach((def) => {
        switch (def?.field?.toUpperCase()) {
          case 'LICENSESTATUS':
            const transformedText = license?.customLicenseStatus?.replace(
              / /g,
              '_',
            );
            licenseProps[def?.field] = licenseStatusMap[transformedText];
            break;
          case 'LICENSEDAYSREMAINING':
            licenseProps[def.field] = getRemainingDays(license);
            break;
          case 'DEVICENAME':
          case 'LOCATIONNAME':
            if (def?.field in license) {
              licenseProps[def.field] = license[def.field];
            } else {
              licenseProps[def.field] = '-';
            }
            break;

          case 'ACTIVEDATE':
            licenseProps[def?.field] = (
              <div className="date-field">
                <HiOutlineCalendar className="calendar-icon" />
                {def?.field in license
                  ? moment(license?.activeDate).format(DATE_FORMAT)
                  : constants.LICENSES_PAGE_GRID_DATE_FIELD_DEFAULT}
              </div>
            );
            break;

          case 'EXPIRYDATE':
            if (
              license?.expiryDate &&
              license.licenseStatus &&
              license.licenseStatus !== 'EXPIRED' &&
              Utils.getDateDifferenceInDays(
                license?.expiryDate,
                moment().valueOf(),
              ) <= license.expiringSoonInDays
            ) {
              licenseProps[def?.field] = (
                <div className="date-field">
                  <HiOutlineExclamation className="exclamation-icon" />
                  <span className="warning-date">
                    {moment(license.expiryDate).format(DATE_FORMAT)}
                  </span>
                </div>
              );
            } else {
              licenseProps[def?.field] = (
                <div className="date-field">
                  <HiOutlineCalendar className="calendar-icon" />
                  {def?.field in license
                    ? moment(license.expiryDate).format(DATE_FORMAT)
                    : constants.LICENSES_PAGE_GRID_DATE_FIELD_DEFAULT}
                </div>
              );
            }
            break;

          default:
            if (def?.field in license) {
              licenseProps[def?.field] = license[def?.field];
            }
        }
      });

      rowData.push(licenseProps);
      licenseProps = {};
    });

    return rowData;
  };

  const handleUpdateOrgLicense = (colField, LicenseStatus) => {
    setPageNumber(0);
    const newSortOrder = sortingState[colField] === 'asc' ? 'desc' : 'asc';
    if (colField !== 'productDisplayName') {
      setSortingState((prevState) => {
        const newState = { ...prevState };
        Object.keys(newState).forEach((key) => {
          if (key !== colField) {
            newState[key] = 'desc';
          }
        });
        newState[colField] = newSortOrder;
        return newState;
      });
      setSortingStateSelected(colField);
      const orderByItem =
        colField === 'licenseDaysRemaining' ? 'expiryDate' : colField;
      fetchDataOnOrderBy(selectedOption, orderByItem, newSortOrder === 'asc');
      // setRowData(initialRowData);
      setLicensesListUpdated({ colField, newSortOrder });
    }
  };

  return (
    <div className="App manage-licenses">
      <Header />
      <Container className="container-top-offset mw-100" fluid>
        <Row className="mb-5">
          <Col>
            <Container className="mw-100">
              <div className="page-header mt-4 mb-5">
                <Row xs={1} md={2} className="g-4">
                  <Col md={6} lg={6} xl={6} xs={12}>
                    <BreadcrumbList list={breadList} />
                  </Col>
                  {Utils.getModuleStatus()?.PRODUCT_LISTING && (
                    <Col md={6} lg={6} xl={6} xs={12} className="text-end">
                      <div className="licenses-button-wrapper">
                        <Button
                          size="md"
                          className="order-history-button"
                          onClick={() => {
                            navigate(
                              `/orders/history.html?orgId=${orgId}&orgName=${orgName}`,
                            );
                          }}
                        >
                          <span className="order-history-button-label">
                            {constants.LICENSES_PAGE_ORDER_HISTORY_BUTTON_LABEL}
                          </span>
                        </Button>
                        <Button
                          variant="primary"
                          size="md"
                          className="purchase-button"
                          onClick={() => {
                            navigate(
                              `/products/listing.html?orgId=${orgId}&orgName=${orgName}`,
                            );
                          }}
                        >
                          <span className="purchase-button-label">
                            {constants.PURCHASE_SERVICES_BUTTON_LABEL}
                          </span>
                        </Button>
                      </div>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col md={6} lg={6} xl={6} xs={12}>
                    <div className="toast-wrapper">
                      <SiteToast
                        customCss="licenses-list-toast"
                        position="top-end"
                        show={showToast}
                        title="Uh-oh!"
                        body={userMsg}
                        delay={5000}
                      />
                      <span className="page-title">
                        {constants.LICENSES_PAGE_TITLE}
                      </span>
                    </div>
                  </Col>
                  <Col md={6} lg={6} xl={6} xs={12} className="end-align">
                    <div className="license-filter">
                      <Dropdown
                        onToggle={() => setShowDropDown(!showDropDown)}
                        className="area-selector mr-3"
                        autoClose="outside"
                      >
                        <Dropdown.Toggle
                          variant="outline-secondary"
                          id="dropdown-basic"
                          className="area-selector-toggle custom-option-device-list"
                        >
                          <div className="area-selected-wrapper">
                            <span className="license-selected-filter custom-license-selected-filter">
                              {filterOptions
                                ?.filter(
                                  (option) => option.value === selectedOption,
                                )
                                ?.map((item) => item.label)}
                            </span>
                          </div>
                          <HiOutlineChevronDown size={20} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="device-status-selector-menu filter-area-style custom-option-device-menu">
                          {filterOptions.map((option) => (
                            <Dropdown.Item
                              key={`location-radio-${option.value}`}
                              className={`device-status-selector-item device-radio-wrapper license-filter-label ${option.label !== 'Active' && option.label !== 'Available' ? 'filter-option-color' : ''}`}
                              onClick={(e) => handleChange(option.value)}
                            >
                              <input
                                type="radio"
                                id={`location-radio-${option.value}`}
                                className="location-radio license-filter-label"
                                checked={selectedOption === option.value}
                              />
                              {option.label}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    {process.env.REACT_APP_PROJECT ===
                      AppDefaults.PROJECT_MEGATRON && (
                      <AddLicense
                        reloadData={() => {
                          setLicensesUpdated(true);
                        }}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {showLoader ? (
                      <div
                        className="position-absolute top-50"
                        style={{ left: '47%' }}
                      >
                        <SiteSpinner height="50px" width="50px" />
                        <div className="mt-2 text-dark">
                          {constants.LOADING}
                        </div>
                      </div>
                    ) : (
                      <div className="licenses-table-container">
                        {rowData?.length > 0 ? (
                          <ListTable
                            colsDef={columnDefs}
                            colSorting={true}
                            rowData={rowData}
                            statusMap={statusMap}
                            orgLicenses={true}
                            licensesListUpdated={licensesListUpdated}
                            loadMoreCount={loadMoreCount} // Pass loadMoreCount
                            updateOrgLicense={(
                              colField,
                              LicenseStatus,
                              sortOrder,
                            ) => {
                              handleUpdateOrgLicense(
                                colField,
                                LicenseStatus,
                                sortOrder,
                              );
                            }}
                            cssClasses="licenses-table"
                          />
                        ) : (
                          <div className="no-devices-container">
                            <div className="image-wrapper">
                              <div className="license-icon">
                                <LicensesIconNoRecord></LicensesIconNoRecord>
                              </div>
                            </div>
                            <div>
                              {
                                constants.SEARCH_DASHBOARD_RESULT_NO_LICENSE_TITLE
                              }
                            </div>
                          </div>
                        )}
                        {showLoadMore ? (
                          <div>
                            <SiteSpinner
                              height="16px"
                              width="16px"
                              styles={{
                                border: '3px solid #de671b',
                                padding: '0px',
                              }}
                            />

                            <span className="load-more-style loader-license-padding ">
                              {constants.LOADING}
                            </span>
                          </div>
                        ) : !allLicensesReceived ? (
                          <span
                            className="load-more-style"
                            onClick={loadMoreData}
                          >
                            {constants.LIST_TABLE_LOAD_MORE_BTN}
                          </span>
                        ) : null}
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ManageLicenses;
