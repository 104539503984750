import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  LazyLoadComponent,
  trackWindowScroll,
} from 'react-lazy-load-image-component';
import { Col, Row, Container, Dropdown } from 'react-bootstrap';
import PageWrapper from '../../../pages/PageWrapper';
import {
  Header,
  BreadcrumbList,
  PrimaryButton,
  SiteSpinner,
} from '../../../components/common/';
import {
  AppDefaults,
  constants,
  DeviceTypeEnum,
  LicenseStatuses,
  OfferingTypeEnum,
  roles,
  Utils,
  ServiceTypeEnum,
  DeviceStatusEnum,
} from '../../../helpers/';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAccountId,
  getAllMqttDataFromResponse,
  getLocationsData,
  setAllMqttDataFromResponse,
  setDeviceInformation,
  setNvrHubInformation,
  setLocationsData,
  getDeviceFilters,
  setDeviceFilters,
} from '../../../store/reducers/AccountReducer';
import { setOfferrecived } from '../../../store/reducers/StreamingReducer';
import AddDevice from '../addDevice/AddDevice';
import '../../../assets/css/loader.scss';
import {
  getMQTTConnection,
  getPlatformInfo,
  setLiveStream,
  setRemoteStream,
} from '../../../store/reducers/StreamingReducer';
import { usePoliciesStore } from '../../../store/policiesStore';
import {
  disconnectWithMQTT,
  mqttSubscribe,
} from '../../../utils/connection/mqttConnection';
import { useLoggedInUserData } from '../../../store/LoggedInAccountStore';
import { devicesMQTTStore } from '../../../store/DevicesMQTTStore';
import useLicensesStore from '../../../store/LicensesStore';
import { IoIosSearch } from 'react-icons/io';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { RiSettings5Fill } from 'react-icons/ri';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { FaCircle } from 'react-icons/fa';
import { HiOutlineVideoCamera } from 'react-icons/hi2';
import moment from 'moment';
import Accordion from 'react-bootstrap/Accordion';
import LatestSnapshot from './LatestSnapshot';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ExclamationRed } from '../../../assets/images/exclamation-circle.svg';
import { ReactComponent as ExclamationOrange } from '../../../assets/images/ExclamationOrange.svg';
import {
  setNVRDeviceData,
  setManufacturerSKUList,
  getManufacturerSKUList,
} from '../../../store/reducers/NVRDeviceReducer';
import { getCDNInfo } from '../../../store/reducers/StreamingReducer';
import { useCustomerOrgLocations } from '../../../store/CustomerOrgLocationsStore';
import { DefaultDevice } from '../../../assets/images';
import {
  setAllScannedDevices,
  setNVRScannedDevices,
} from '../../../store/NVRDeviceStoreIDB';
import {
  getCustomerOrgData,
  setCustomerOrgData,
} from '../../../store/OrganizationsStoreIDB';
import _ from 'lodash';
import { getOrgInfo } from '../../../store/reducers/OrganizationsReducer';
import { useLocation } from 'react-router-dom';
import LocationAreaDeviceList from './LocationAreaDeviceList';
import './DiscoveredDevices.scss';
import { checkUnslect } from '../../../assets/images';
import { ImCheckboxChecked } from 'react-icons/im';
import { useDeviceSnapshots } from '../../../store/DeviceSnapshotsStore';

const DeviceListCloud = () => {
  const orgInfo = useSelector(getOrgInfo);
  const [isDeviceAvailable, setIsDeviceAvailable] = useState(false);
  const cdnInfo = useSelector(getCDNInfo);
  const skuList = useSelector(getManufacturerSKUList);
  const mqttConnection = useSelector(getMQTTConnection);
  const accountId = useSelector(getAccountId);
  const locationDetails = useSelector(getLocationsData);
  const deviceFilterData = useSelector(getDeviceFilters);
  const [enableAttachAllLicenses, setEnableAttachAllLicenses] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const platformDetails = useSelector(getPlatformInfo);
  const [showLoader, setShowLoader] = useState(false);
  const [showListScrollLoader, setShowListScrollLoader] = useState(false);
  const navigate = useNavigate();
  const [showClaimDeviceModal, setShowClaimDeviceModal] = useState(false);
  const [isDirectToScannedPopup, setIsDirectToScannedPopup] = useState(false);
  const [manufacturerData, setManufacturerData] = useState([]);
  const [discoveredDevicesCount, setDiscoveredDevicesCount] = useState(0);
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  const [claimDeviceId, setClaimDeviceId] = useState(null);
  const [isDiscoverPopupLoading, setIsDiscoverPopupLoading] = useState(null);
  const deviceListRefs = useRef();
  const { setDeviceSnapshots, getDeviceSnapshots, resetDeviceSnapshots } =
    useDeviceSnapshots();
  //const filteredLicensesData = useLocation();
  //let { filteredExpiredLicenses } = filteredLicensesData.state || {};
  const filterOptions = [
    { value: 'online', label: 'Online', isSelected: false },
    { value: 'offline', label: 'Offline', isSelected: false },
    { value: 'available', label: 'Firmware Update', isSelected: false },
    { value: 'licenseExpiring', label: 'License Expiring', isSelected: false },
  ];
  const { getLicenses } = useLicensesStore();
  const deviceFilterDataOrg =
    deviceFilterData?.orgId === orgInfo?.orgId ? deviceFilterData : null;
  const deviceFilterOptions =
    deviceFilterDataOrg?.orgId === orgInfo?.orgId &&
    deviceFilterDataOrg?.filterOptions?.length > 0
      ? deviceFilterDataOrg?.filterOptions
      : filterOptions;
  const [searchText, setSearchText] = useState(
    deviceFilterDataOrg && deviceFilterDataOrg?.searchText !== ''
      ? deviceFilterDataOrg?.searchText
      : '',
  );
  const [selectedOption, setSelectedOption] = useState(deviceFilterOptions);
  const subscribeTopic =
    platformDetails?.mqtt?.topic_details?.subscribe?.settings;
  const { getState } = devicesMQTTStore;
  const mqttStoreState = getState();
  const { getCustomerOrgLocationsData, getCustomerOrgLocations } =
    useCustomerOrgLocations();
  const custOrgLocations = getCustomerOrgLocationsData();
  const [orglocations, setOrglocations] = useState(custOrgLocations);
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData,
  );
  const loggedInUser = getLoggedInUserData();
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const { locationIdMap } = location.state || {};
  const defaultLocID = orglocations?.find(
    (item) => item?.isDefault == 'true',
  )?.locationId;
  const filterLocId =
    deviceFilterDataOrg && deviceFilterDataOrg?.locationId !== ''
      ? deviceFilterDataOrg?.locationId
      : locationIdMap
        ? locationIdMap
        : defaultLocID
          ? defaultLocID
          : orglocations?.[0]?.locationId;
  const [locationId, setLocationId] = useState(filterLocId);
  const dispatch = useDispatch();
  const [showDropDown, setShowDropDown] = useState(false);
  //=== Store get/set actions
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies,
  );
  const defaultPageDetails = {
    orderBy: 'deviceName',
    isAsc: true,
    page: 0,
    size: 10,
    totalPages: 0,
  };
  const defaultChildPageDetails = {
    orderBy: 'deviceName',
    isAsc: true,
    page: -1,
    size: 10,
    totalPages: 0,
  };
  const [pageDetails, setPageDetails] = useState(defaultPageDetails);
  const [expandedItems, setExpandedItems] = useState({});
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const breadList = [
    {
      url:
        loggedInUser?.orgType === AppDefaults.ORG_TYPE_INSTALLER
          ? '/customers/manage.html'
          : `/customers/dashboard.html?orgId=${orgInfo?.orgId}`,
      title:
        loggedInUser.role !== roles.ROLE2VMS
          ? constants.MANAGE_ORG_PAGE_TITLE
          : constants.MANAGE_ORG_PAGE_ORG_TITLE,
    },
    {
      url: `/customers/dashboard.html?orgId=${orgInfo?.orgId}`,
      title: `${orgInfo?.orgName}`,
      disable: getCustomerOrgPolicies()?.view_cust_org_dashboard ? false : true,
    },
    {
      url: `/devices/listing.html?orgId=${orgInfo?.orgId}`,
      title: constants.DEVICES_PAGE_TITLE,
    },
  ];

  const [loadingDeviceId, setLoadingDeviceId] = useState(null); // Track which device is loading
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [loadMoreChildLoading, setLoadMoreChildLoading] = useState(false);

  useEffect(() => {
    getWayApi(pageDetails, false);
  }, [locationId, selectedOption]);

  const scrollToTop = () => {
    if (deviceListRefs?.current) {
      deviceListRefs.current.scrollIntoView();
    }
  };

  const onKeyDownSearch = (e) => {
    if (e.key === 'Enter') {
      dispatch(setDeviceFilters({ searchText: searchText }));
      scrollToTop();
      getWayApi(defaultPageDetails, false, true);
    }
  };

  const loadMoreGateway = (isFromLoadMore) => {
    setLoadMoreLoading(true);
    const { page } = pageDetails;
    const updatePageDetails = {
      ...pageDetails,
      page: page + 1,
    };
    getWayApi(updatePageDetails, isFromLoadMore);
  };

  const loadMoreChannels = (device, index) => {
    setLoadMoreChildLoading(true);
    channelDevices(device, index, device?.childPageDetails);
  };

  const getWayApi = async (
    pageDetails,
    isFromLoadMore,
    isFromSearch = false,
  ) => {
    !isFromLoadMore && setShowLoader(true);
    dispatch(
      setDeviceFilters({
        orgId: orgId,
      }),
    );
    const selectedValues = selectedOption
      ?.filter(
        (item) =>
          item.isSelected &&
          item.value !== 'available' &&
          item?.value !== 'licenseExpiring',
      )
      .map((item) => item.value);
    const firmwareUpdates =
      selectedOption?.find(
        (item) => item.value === 'available' && item.isSelected,
      )?.value || '';
    const licenseExpiring =
      selectedOption?.find(
        (item) => item.value === 'licenseExpiring' && item.isSelected,
      )?.value || false;
    const commaSeparatedValues = selectedValues
      ? selectedValues?.join(', ')
      : '';
    const availableValueString = firmwareUpdates ? firmwareUpdates : '';
    try {
      const response = await axios.get(
        `device/orgs/${orgId}/gateways/v2?locationId=${locationId}&page=${
          pageDetails?.page
        }
      &size=${pageDetails?.size}&orderBy=${pageDetails?.orderBy}&isAsc=${
        pageDetails?.isAsc
      }
      &search=${searchText ? encodeURIComponent(searchText) : ''}&licenseExpiring=${licenseExpiring ? true : false}&statusList=${commaSeparatedValues}&firmwareUpdates=${availableValueString}`,
        Utils.requestHeader(),
      );
      const resultData = await response.data;
      if (resultData) {
        const responseMeta = resultData.meta;
        const responseData = resultData?.data?.devices;
        if (
          responseMeta &&
          (responseMeta.code === 200 || responseMeta.code === '200')
        ) {
          setLoadMoreLoading(false);
          setShowLoader(false);
          if (responseData?.length == 0) {
            setDeviceList([]);
          }
          if (deviceList?.length <= 0 && !responseData?.length) {
            setIsDeviceAvailable(true);
            return;
          }
          let combinedList = [];
          if (deviceList?.length > 0) {
            combinedList = isFromSearch
              ? responseData
              : [...deviceList, ...responseData];
          } else {
            combinedList = responseData;
          }
          setDeviceList(combinedList);
          getCapabiltiesFromDeviceList(combinedList);
          const devicePropertyErrorSubscribe = {
            topic: `d/notify/${accountId}/${mqttStoreState?.getSessionId()}`,
            qos: 0,
          };
          mqttSubscribe(devicePropertyErrorSubscribe);
          const updatedData = combinedList.filter(
            (device) => device.deviceStatus !== 'DEACTIVATED',
          );
          // setAddedDeviceData(updatedData);
          setLoadMoreLoading(false);
          const { pageNumber, pageSize, totalPages } = resultData?.data;
          setPageDetails({
            ...pageDetails,
            page: pageNumber,
            size: pageSize,
            totalPages: totalPages,
          });
        } else {
          setShowLoader(false);
          setLoadMoreLoading(false);
          setIsDeviceAvailable(true);
        }
      } else {
        setIsDeviceAvailable(true);
        setLoadMoreLoading(false);
      }
    } catch (err) {
      throw new Error(err);
    }
  };

  useEffect(() => {
    const resetNVRScanData = async () => {
      await setNVRScannedDevices([]);
      await setAllScannedDevices([]);
    };
    const accountSnap = getDeviceSnapshots(accountId);
    if (new Date().getTime() - accountSnap > AppDefaults.DAY_IN_MILLISECONDS) {
      resetDeviceSnapshots();
      setDeviceSnapshots(accountId, new Date().getTime());
    }
    dispatch(setOfferrecived(false));
    //setShowLoader(true);
    localStorage.setItem('modalOpen', 0);
    fetchManufacturerData();
    getLocations();
    fetchDiscoveredDevicesCount();
    dispatch(setLiveStream(null));
    dispatch(setRemoteStream(null));
    dispatch(setDeviceInformation(null));
    dispatch(setNvrHubInformation(null));
    if (mqttConnection) {
      disconnectWithMQTT();
    }
    resetNVRScanData();
  }, []);

  useEffect(() => {
    if (
      allMqttData?.msg?.resource === 'camera/default-settings' ||
      allMqttData?.msg?.resource === 'camera/diag/duclo-fw-update' ||
      allMqttData?.msg?.resource === 'camera/diag/device-fw-update'
    ) {
      if (allMqttData?.msg?.properties) {
        const mqttDeviceId = allMqttData.from;
        const rawDeviceData = structuredClone(deviceList);
        rawDeviceData.forEach((rawDevice) => {
          if (rawDevice.deviceId === mqttDeviceId) {
            rawDevice.properties = {
              ...rawDevice.properties,
              ...allMqttData.msg.properties,
            };
            rawDevice.deviceStatus = constants.DEVICES_CLAIMED_DEVICE_STATUS;
          }
        });
        addDeviceProperties(rawDeviceData);
      }
    } else if (
      allMqttData?.msg?.resource?.includes('device/') ||
      allMqttData?.msg?.resource?.includes('devices/')
    ) {
      updateDevicesStatus(allMqttData);
    }
  }, [allMqttData]);

  const updateDevicesStatus = () => {
    const deviceId = allMqttData?.msg?.resource?.replace('device/', '');
    const deviceIdForUpdatingChannels = allMqttData?.msg?.resource?.replace(
      'devices/',
      '',
    );
    const connectionStatus = allMqttData?.msg?.properties?.connectionStatus;
    const deviceStatus = allMqttData?.msg?.properties?.deviceStatus;
    const action = allMqttData?.msg?.action;
    const channels = allMqttData?.msg?.properties?.data?.channels;

    if (action === 'add' && channels?.length > 0) {
      const updatedDevices = deviceList?.map((device) => {
        if (device?.deviceId === deviceIdForUpdatingChannels) {
          return {
            ...device,
            channels:
              channels?.length > 0
                ? channels
                : device?.channels
                  ? device?.channels
                  : [], // Update channels if received
          };
        } else {
          return {
            ...device,
          };
        }
      });
      addDeviceProperties(updatedDevices);
    } else {
      const updatedDevices = deviceList?.map((device) => {
        // Check if the parent device matches the deviceId
        if (device?.deviceId === deviceId) {
          return {
            ...device,
            connectionStatus: connectionStatus
              ? connectionStatus
              : device?.displayDeviceStatus,
            deviceStatus: deviceStatus ? deviceStatus : device?.deviceStatus,
          };
        }
        // Check if any channel matches the deviceId
        if (device?.channels) {
          const updatedChannels = device?.channels?.map((channel) =>
            channel?.deviceId === deviceId
              ? {
                  ...channel,
                  connectionStatus: connectionStatus
                    ? connectionStatus
                    : channel?.displayDeviceStatus,
                  deviceStatus: deviceStatus
                    ? deviceStatus
                    : channel?.deviceStatus,
                }
              : channel,
          );
          return { ...device, channels: updatedChannels };
        }
        return device;
      });
      addDeviceProperties(updatedDevices);
    }
  };

  const getDeviceDisplayLocation = (device) => {
    const displayLocation = locationDetails?.map((location) => {
      if (location.locationId === device.locationId) {
        return location.locationName;
      }
    });
    return displayLocation;
  };

  const getDeviceDisplayArea = (device) => {
    const displayArea = locationDetails?.map((location) => {
      if (location.locationId === device.locationId) {
        return location?.areas?.map((area) => {
          if (area.areaId === device.areaId) {
            return area.areaName;
          }
        });
      }
    });
    return displayArea;
  };

  const addDeviceProperties = (groupedDeviceData) => {
    if (!locationDetails) {
      getLocations();
    }
    groupedDeviceData?.length &&
      groupedDeviceData?.forEach((device) => {
        const avlbleDeviceVersion = device?.avlblDeviceVer;
        const firmwareVersion = device?.firmwareVersion;
        const checkStatus =
          avlbleDeviceVersion && firmwareVersion
            ? avlbleDeviceVersion?.toLowerCase() !==
              firmwareVersion?.toLowerCase()
            : false;
        if (Object.isExtensible(device)) {
          device['displayDeviceStatus'] = Utils.getDeviceStatus(
            device?.deviceStatus,
            device?.connectionStatus?.toLowerCase(),
          );
        } else {
          const newDevice = { ...device }; // Shallow copy
          newDevice['displayDeviceStatus'] = Utils.getDeviceStatus(
            device?.deviceStatus,
            device?.connectionStatus?.toLowerCase(),
          );
          device = newDevice; // Replace the old reference
        }
        device['isFirmwareUpgradeAvailable'] =
          device && device?.avlblDucloVer && device?.edgeAppVersion
            ? device?.avlblDucloVer !== device?.edgeAppVersion
            : false;
        if (!device['isFirmwareUpgradeAvailable'] && checkStatus) {
          device['isFirmwareUpgradeAvailable'] = checkStatus;
        }
        device['displayArea'] = locationDetails?.map((location) => {
          if (location.locationId === device.locationId) {
            return location?.areas?.map((area) => {
              if (area.areaId === device.areaId) {
                return area.areaName;
              }
            });
          }
        });
        device['displayLocation'] = locationDetails?.map((location) => {
          if (location.locationId === device.locationId) {
            return location.locationName;
          }
        });
        device.channels &&
          device.channels.forEach((child) => {
            const childAvlbleDeviceVersion = child?.avlblDeviceVer;
            const childFirmwareVersion = child?.firmwareVersion;
            const checkStatusChild =
              childAvlbleDeviceVersion &&
              childFirmwareVersion &&
              childFirmwareVersion?.toLowerCase() !==
                childFirmwareVersion?.toLowerCase();
            if (Object.isExtensible(child)) {
              child['displayDeviceStatus'] = Utils.getDeviceStatus(
                child?.deviceStatus,
                child?.connectionStatus?.toLowerCase(),
              );
            } else {
              const newChild = { ...child };
              newChild['displayDeviceStatus'] = Utils.getDeviceStatus(
                child?.deviceStatus,
                child?.connectionStatus?.toLowerCase(),
              );
              child = newChild;
            }
            child['isFirmwareUpgradeAvailable'] =
              child && child?.properties?.avlblDucloVer && child?.edgeAppVersion
                ? child?.avlblDucloVer !== child?.edgeAppVersion
                : false;
            if (!child['isFirmwareUpgradeAvailable'] && checkStatusChild) {
              child['isFirmwareUpgradeAvailable'] = checkStatusChild;
            }
            child['displayArea'] = locationDetails?.map((location) => {
              if (location.locationId === child.locationId) {
                return location?.areas?.map((area) => {
                  if (area.areaId === child.areaId) {
                    return area.areaName;
                  }
                });
              }
            });
            child['displayLocation'] = locationDetails?.map((location) => {
              if (location.locationId === child.locationId) {
                return location.locationName;
              }
            });
          });
      });
    setDeviceList(groupedDeviceData);
  };

  const handleChange = (event, eIndex) => {
    scrollToTop();
    setPageDetails(defaultPageDetails);
    setDeviceList([]);
    if (event.value === DeviceStatusEnum.SELECT_ALL) {
      const allSelected = selectedOption.every((option) => option.isSelected);
      if (allSelected) {
        return;
      }
      const newSelectedOption = selectedOption.map((option) => ({
        ...option,
        isSelected: true,
      }));
      dispatch(
        setDeviceFilters({
          filterOptions: newSelectedOption,
        }),
      );
      setSelectedOption(newSelectedOption);
      setIsAllSelected(true);
    } else {
      const statusOption = [...selectedOption];
      statusOption[eIndex] = {
        ...event,
        isSelected: !event?.isSelected,
      };
      dispatch(
        setDeviceFilters({
          filterOptions: statusOption,
        }),
      );
      setSelectedOption(statusOption);
    }
  };

  const onChangeSearchText = (e) => {
    setSearchText(e?.target?.value);
  };

  const setAddedDeviceData = async (responseData) => {
    let custOrg = await getCustomerOrgData();
    const deviceInfo = custOrg?.find((custOrg) => custOrg?.orgId === orgId);
    let allDevice = { ...deviceInfo, devices: responseData };
    let list = custOrg?.map((org, i) => {
      if (org?.orgId === orgId) {
        return allDevice;
      } else {
        return { ...org };
      }
    });
    let customerOrg = await Promise.all(list);
    await setCustomerOrgData(customerOrg);
  };

  const getCapabiltiesFromDeviceList = async (data) => {
    const deviceList = [...data];
    // const groupedDeviceData = Utils.getGroupedDevices(deviceList);
    const groupedDeviceData = deviceList;
    addDeviceProperties(groupedDeviceData);
    deviceList?.forEach((device) => {
      if (Array.isArray(subscribeTopic) && subscribeTopic.length > 0) {
        const deviceSubscription = {
          topic: Utils.replaceStringValues(
            subscribeTopic[0],
            '${deviceId}',
            `${device?.deviceId}`,
          ),
          qos: 0,
        };
        mqttSubscribe(deviceSubscription);
      }
    });
    deviceList?.length <= 0
      ? setIsDeviceAvailable(true)
      : setIsDeviceAvailable(false);
  };

  const fetchData = async () => {
    setIsDirectToScannedPopup(false);
  };

  const fetchDiscoveredDevicesCount = async () => {
    await axios
      .get(
        `/device/orgs/${orgId}/devices/discover/count`,
        Utils.requestHeader(),
      )
      .then((response) => {
        const resultData = response.data;
        if (resultData) {
          const responseMeta = resultData.meta;
          const responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            setDiscoveredDevicesCount(responseData.count);
          }
        }
      });
  };
  const getLocations = async () => {
    let fetchResult = await getCustomerOrgLocations(
      `partner/orgs/${orgId}/locations`,
    );
    if (fetchResult.status === 'success') {
      let orgLocations = getCustomerOrgLocationsData();
      const data = [...orgLocations];
      const defaultLocationData = data?.filter(
        (location) => location?.isDefault === 'true',
      );
      const defaultLocation = defaultLocationData[0]
        ? defaultLocationData[0]
        : data[0];

      const filterLocId =
        deviceFilterDataOrg && deviceFilterDataOrg?.locationId !== ''
          ? deviceFilterDataOrg?.locationId
          : locationIdMap
            ? locationIdMap
            : defaultLocationData && defaultLocationData?.[0]?.locationId
              ? defaultLocationData?.[0]?.locationId
              : data?.[0]?.locationId;
      dispatch(setDeviceFilters({ locationId: filterLocId }));
      setOrglocations(data);
      const finalData = data.sort(function (x, y) {
        return x === defaultLocation[0] ? -1 : y === defaultLocation[0] ? 1 : 0;
      });
      dispatch(setLocationsData(finalData));
      setLocationId(filterLocId);
    }
  };

  const fetchManufacturerData = async () => {
    await axios
      .get('/device/manufacturerSkuBanks', Utils.requestHeader())
      .then((response) => {
        const resultData = response.data;
        if (resultData?.meta?.code === 200) {
          const responseData = resultData.data;
          setManufacturerData(responseData);
          dispatch(setManufacturerSKUList(responseData));
        } else {
          setManufacturerData([]);
          dispatch(setManufacturerSKUList([]));
        }
      });
  };

  const getDeviceStatus = (device) => {
    if (
      device.deviceStatus?.toUpperCase() ===
      constants.DEVICES_DEACTIVATED_DEVICE_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-ready-to-claim" />
          {constants.DEVICES_RETURN_DEACTIVATED_STATUS}
        </div>
      );
    } else if (
      device.displayDeviceStatus === constants.DEVICES_RETURN_OFFLINE_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-offline" />{' '}
          {device.displayDeviceStatus}
        </div>
      );
    } else if (
      device.displayDeviceStatus === constants.DEVICES_RETURN_ONLINE_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-online" />{' '}
          {device.displayDeviceStatus}
        </div>
      );
    } else if (
      device.displayDeviceStatus ===
      constants.DEVICES_RETURN_READY_TO_CLAIM_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-ready-to-claim" />{' '}
          {device.displayDeviceStatus}
        </div>
      );
    } else if (
      device.displayDeviceStatus === constants.DEVICES_RETURN_ENTERED_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-entered" />{' '}
          {device.displayDeviceStatus}
        </div>
      );
    } else if (
      device.displayDeviceStatus === constants.DEVICES_RETURN_CLAIMING_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-claiming" />
          {device.displayDeviceStatus}
        </div>
      );
    } else if (
      device.displayDeviceStatus === constants.DEVICES_RETURN_DEACTIVATED_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-offline" />
          {device.displayDeviceStatus}
        </div>
      );
    }
  };

  const getCapabiltiesForDevice = async (deviceData) => {
    if (deviceData?.capability) {
      try {
        const response = await fetch(deviceData?.capability?.url);
        if (response.status === 200) {
          const responseJson = await response.json();
          return responseJson;
        } else {
          return {};
        }
      } catch (error) {
        return {};
      }
    } else {
      return {};
    }
  };

  const showDeviceDashboard = async (data, deviceParent) => {
    if (!getCustomerOrgPolicies()?.view_device_settings) {
      return;
    }
    dispatch(setNvrHubInformation(deviceParent));
    let updateProperty = data?.properties;
    let openDeviceDetails = data;
    const capDetails = await getCapabiltiesForDevice(data);
    // const DeviceProperty = deviceList?.find(
    //   (item) => item?.gatewayId === data?.gatewayId
    // )?.properties;
    // updateProperty = { ...updateProperty, ...DeviceProperty };
    openDeviceDetails = {
      ...openDeviceDetails,
      properties: updateProperty,
    };
    localStorage.setItem(
      'localStoreProperty',
      JSON.stringify(openDeviceDetails.properties),
    );
    dispatch(
      setDeviceInformation({ ...openDeviceDetails, capDetails: capDetails }),
    );
    data?.deviceId &&
      navigate(
        `/devices/dashboard.html?deviceId=${openDeviceDetails?.deviceId}`,
      );
  };

  const showChannelDetails = async (data) => {
    if (!getCustomerOrgPolicies()?.view_device_settings) {
      return;
    }
    const capDetails = await getCapabiltiesForDevice(data);
    localStorage.setItem('localStoreProperty', JSON.stringify(data.properties));
    dispatch(setDeviceInformation({ ...data, capDetails: capDetails }));
    data?.deviceId &&
      navigate(`/devices/channel-details.html?deviceId=${data?.deviceId}`);
  };

  const addAppLink = async (device, parentIndex, childIndex) => {
    setClaimDeviceId(device.deviceId);
    try {
      const reqBody = {
        apps: ['vms'],
      };
      const res = await axios.post(
        `/device/orgs/${orgId}/devices/${device?.deviceId}/claim/app`,
        reqBody,
        Utils.requestHeader(),
      );
      if (parseInt(res?.status) === 200) {
        // setShowActiveModal(false);
        const updatedList = [...deviceList];
        if (childIndex == null) {
          updatedList[parentIndex].apps.vms = true;
        } else {
          updatedList[parentIndex].channels[childIndex].apps.vms = true;
        }
        setDeviceList(updatedList);
        setShowLoader(false);
        setClaimDeviceId(null);
      } else {
        setShowLoader(false);
        setClaimDeviceId(null);
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
      setShowLoader(false);
      setClaimDeviceId(null);
    }
    // );
  };
  const handleScanNetworkForDeviceClick = async (device) => {
    setIsDiscoverPopupLoading(device?.deviceId);
    try {
      axios
        .get(
          `device/orgs/${orgInfo?.orgId}/devices/${device?.deviceId}/v2`,
          Utils.requestHeader(),
        )
        .then(async (response) => {
          setIsDiscoverPopupLoading(null);
          const resultData = response.data;
          if (resultData) {
            const responseMeta = resultData.meta;
            const responseData = resultData.data;
            if (
              responseMeta &&
              (responseMeta.code === 200 || responseMeta.code === '200')
            ) {
              dispatch(setAllMqttDataFromResponse(null));
              setIsDirectToScannedPopup(true);
              dispatch(
                setNVRDeviceData({
                  ...responseData,
                  channelCount: skuList?.find(
                    (item) => item.model === responseData.modelNumber,
                  )?.channelCount,
                  channels: device.channels,
                }),
              );
              await setNVRScannedDevices([]);
              await setAllScannedDevices([]);
            } else {
              Utils.vmsLogger().log('Error - ', responseMeta.userMsg);
            }
          }
        });
    } catch (error) {
      setIsDiscoverPopupLoading(null);
    }
  };

  const getLicenseStatus = (childDevice) => {
    const expiredLicensesDays = getExpiredLicensesDays(childDevice);
    return (
      <div>
        {expiredLicensesDays === 0 ? (
          <ExclamationRed />
        ) : expiredLicensesDays <=
          childDevice?.activeLicense?.expiringSoonInDays ? (
          <ExclamationOrange />
        ) : null}
        {' ' + expiredLicensesDays + ' ' + AppDefaults.DAYS}
      </div>
    );
  };

  const getLicenseStatusToolTip = (childDevice) => {
    const expiredLicensesDays = getExpiredLicensesDays(childDevice);
    return (
      <Tooltip>{' ' + expiredLicensesDays + ' ' + AppDefaults.DAYS}</Tooltip>
    );
  };

  const getExpiredLicensesDays = (device) => {
    const activeLicense = device?.activeLicense;
    if (activeLicense) {
      const convertGracePeriodIntoMilisec =
        activeLicense?.gracePeriod * AppDefaults.DAY_IN_MILLISECONDS;
      const noOfRemainingDays = moment(
        activeLicense?.expiryDate - convertGracePeriodIntoMilisec,
      );
      const remainingDays = Utils.calculateDaysFromEpoch(noOfRemainingDays);
      return remainingDays;
    } else {
      return 0;
    }
  };

  const showRemainingDaysColumn = (device) => {
    const licenses = getLicenses();
    if (device) {
      let licenseList = licenses?.filter(
        (license) => license?.deviceId === device?.deviceId,
      );
      const activeLicenses = licenseList?.filter(
        (item) => item.licenseStatus === LicenseStatuses.ACTIVE,
      );
      if (
        activeLicenses?.length > 0 &&
        (activeLicenses[0].offeringType === OfferingTypeEnum.ONBOARDING ||
          activeLicenses[0].offeringType === OfferingTypeEnum.EVAL)
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const channelDevices = async (deviceItem, index, childPageDetails) => {
    const selectedValues = selectedOption
      ?.filter(
        (item) =>
          item.isSelected &&
          item.value !== 'available' &&
          item.value !== 'licenseExpiring',
      )
      .map((item) => item.value);
    const firmwareUpdates =
      selectedOption?.find(
        (item) => item.value === 'available' && item.isSelected,
      )?.value || '';
    const licenseExpiring =
      selectedOption?.find(
        (item) => item.value === 'licenseExpiring' && item.isSelected,
      )?.value || false;
    const commaSeparatedValues = selectedValues
      ? selectedValues?.join(', ')
      : '';
    const availableValueString = firmwareUpdates ? firmwareUpdates : '';

    try {
      const response = await axios.get(
        `device/orgs/${orgId}/gateways/${deviceItem?.deviceId}/devices/v2?locationId=${locationId}&page=${
          childPageDetails ? childPageDetails.page + 1 : 0
        }&size=${childPageDetails?.size}&orderBy=${pageDetails?.orderBy}&isAsc=${pageDetails?.isAsc}&search=${
          searchText ? encodeURIComponent(searchText) : ''
        }&statusList=${commaSeparatedValues}&licenseExpiring=${licenseExpiring ? true : false}&firmwareUpdates=${availableValueString}`,
        Utils.requestHeader(),
      );

      const resultData = await response.data;

      if (resultData) {
        const responseMeta = resultData.meta;
        const responseData = Array.isArray(resultData?.data?.devices)
          ? resultData?.data?.devices
          : [];

        if (
          responseMeta &&
          (responseMeta.code === 200 || responseMeta.code === '200')
        ) {
          setLoadMoreChildLoading(false);
          setLoadingDeviceId(null);

          const { pageNumber, pageSize, totalPages } = resultData?.data;
          const pageDetailsChild = {
            ...childPageDetails,
            page: pageNumber,
            size: pageSize,
            totalPages: totalPages,
          };
          if (responseData?.length > 0) {
            const updatedDevices = Array.isArray(deviceList)
              ? [...deviceList]
              : [];
            const updatedChildDevices = Array.isArray(
              updatedDevices?.[index]?.channels,
            )
              ? [...updatedDevices[index]?.channels]
              : [];

            let combinedChildList = [...updatedChildDevices, ...responseData];
            updatedDevices[index] = {
              ...deviceItem,
              channels: combinedChildList,
              isSelected: true,
              childPageDetails: pageDetailsChild,
            };
            addDeviceProperties(updatedDevices);
            setDeviceList(updatedDevices);
          }
        } else {
          setLoadMoreChildLoading(false);
          setLoadingDeviceId(null);
        }
      }
    } catch (err) {
      setLoadMoreChildLoading(false);
      setLoadingDeviceId(null);
    }
  };

  const handleAccordionSelect = (e, device, index) => {
    e.preventDefault();
    e.stopPropagation();
    setExpandedItems((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the state of the clicked item
    }));
    if (!device?.isSelected) {
      const updatedDevices = [...deviceList]; // Copy the current devices state
      updatedDevices[index] = {
        ...device,
        isSelected: true,
      };
      setLoadingDeviceId(device.deviceId);
      setDeviceList(updatedDevices);
      channelDevices(device, index, defaultChildPageDetails);
    } else {
      const updatedDevices = [...deviceList]; // Copy the current devices state
      updatedDevices[index] = {
        ...device,
        isSelected: false,
        channels: [],
        childPageDetails: defaultChildPageDetails,
      };
      setLoadingDeviceId(null);
      setDeviceList(updatedDevices);
    }
  };

  const ShowOrgSingletonDevices = (style = null) => {
    return (
      <LazyLoadComponent visibleByDefault={true}>
        {deviceList.map((device, index) => {
          // const rowRef = useRef({});
          return (
            <div
              style={style}
              key={`device-${device?.deviceId}`}
              className="device-list-ref"
            >
              {device?.count > 2 ||
              device?.deviceType?.toUpperCase() === DeviceTypeEnum.NVR ? (
                <Accordion.Item
                  id={`div${index}`}
                  key={`item-${device.deviceId}`}
                  eventKey={index.toString()}
                  className="accordion-item"
                  active={expandedItems[index]}
                >
                  <Accordion.Header
                    onClick={(e) => handleAccordionSelect(e, device, index)}
                  >
                    <div className="header me-2">
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div className="image-wrapper">
                          <img
                            src={device.imageURL}
                            onError={(e) => (e.target.src = `${DefaultDevice}`)}
                            alt=""
                            className="device-icon"
                          />
                        </div>
                        {device.deviceName}
                        {device?.deviceStatus ===
                          constants.DEVICES_CLAIMED_DEVICE_STATUS &&
                        device.isFirmwareUpgradeAvailable ? (
                          <div className="firmware-update">
                            <div className="update-text">
                              {constants.DEVICES_FIRMWARE_UPGRADE_AVAILABLE}
                            </div>
                          </div>
                        ) : null}
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '10px',
                          alignItems: 'center',
                        }}
                      >
                        {loadingDeviceId === device.deviceId && (
                          <SiteSpinner
                            height={25}
                            width={25}
                            backgroundColor={getComputedStyle(
                              document.documentElement,
                            ).getPropertyValue('--primary_03')}
                          />
                        )}
                        {device?.deviceStatus ===
                          constants.DEVICES_CLAIMED_DEVICE_STATUS &&
                          skuList?.find(
                            (item) => item?.model === device?.modelNumber,
                          )?.vms !== device?.apps?.vms && (
                            <PrimaryButton
                              className="device-btn-list"
                              type="button"
                              width={
                                device.deviceId === claimDeviceId
                                  ? '153px'
                                  : '128px'
                              }
                              backgroundColor={getComputedStyle(
                                document.documentElement,
                              ).getPropertyValue('--primary_40')}
                              color={getComputedStyle(
                                document.documentElement,
                              ).getPropertyValue('--brand_white')}
                              fontSize="14px"
                              height="44px"
                              lineHeight="16px"
                              onClick={(e) => {
                                e.stopPropagation();
                                addAppLink(device, index, null);
                              }}
                            >
                              {constants.CLAIM_DEVICE}
                              {device.deviceId === claimDeviceId ? (
                                <SiteSpinner
                                  height={20}
                                  width={20}
                                  backgroundColor={getComputedStyle(
                                    document.documentElement,
                                  ).getPropertyValue('--brand_white')}
                                />
                              ) : null}
                            </PrimaryButton>
                          )}

                        {device?.deviceType?.toUpperCase() ===
                          DeviceTypeEnum.NVR && (
                          <PrimaryButton
                            className="device-btn-list"
                            type="button"
                            width="200px"
                            backgroundColor={getComputedStyle(
                              document.documentElement,
                            ).getPropertyValue('--primary_40')}
                            color={getComputedStyle(
                              document.documentElement,
                            ).getPropertyValue('--brand_white')}
                            fontSize="14px"
                            height="44px"
                            lineHeight="16px"
                            disabled={
                              device?.deviceStatus ===
                                constants.DEVICES_DEACTIVATED_DEVICE_STATUS ||
                              device?.deviceStatus ===
                                constants.DEVICES_PENDING_CLAIM_DEVICE_STATUS ||
                              device?.connectionStatus ===
                                constants.DEVICES_OFFLINE_CONNECTION_STATUS ||
                              !getCustomerOrgPolicies()?.install_device
                            }
                            onClick={(e) => {
                              if (!device?.isSelected) {
                                e.preventDefault();
                                e.stopPropagation();
                              }
                              handleScanNetworkForDeviceClick(device);
                            }}
                            loader={isDiscoverPopupLoading === device.deviceId}
                          >
                            {constants.SCAN_NETWORK_FOR_DEVICE_BUTTON}
                          </PrimaryButton>
                        )}
                        <RiSettings5Fill
                          size={24}
                          className={
                            getCustomerOrgPolicies()?.view_device_settings
                              ? 'setting-icon'
                              : 'setting-icon-disabled'
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            showChannelDetails(device);
                          }}
                        />
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    {device?.channels?.map((childDevice, childIndex) => (
                      <div className="camera-header-items">
                        <div
                          key={childDevice.deviceId}
                          className={`camera`}
                          role={
                            getCustomerOrgPolicies()?.view_device_settings
                              ? 'button'
                              : ''
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            showDeviceDashboard(childDevice, device);
                          }}
                          id={childDevice.deviceId}
                        >
                          <LatestSnapshot
                            key={childDevice.deviceId}
                            deviceElemId={`camera${index}${childIndex}`}
                            orgId={orgInfo?.orgId}
                            cdnInfo={cdnInfo}
                            locationAreaName={childDevice.locationAreaNames}
                            isHighlighted={false}
                            showZoomOptions={false}
                            conStatus={
                              childDevice.connectionStatus || 'offline'
                            }
                            hubId={device.deviceId}
                            displayDeviceStatus={Utils.getDeviceStatus(
                              childDevice?.deviceStatus,
                              childDevice?.connectionStatus?.toLowerCase(),
                            )}
                            {...childDevice}
                          />
                          <div className="camera-details">
                            <div className="camera-name d-flex align-items-center">
                              {childDevice.deviceName}
                              {childDevice?.deviceStatus ===
                                constants.DEVICES_CLAIMED_DEVICE_STATUS &&
                                !childDevice?.apps?.vms && (
                                  <PrimaryButton
                                    className="channel-claimed-btn device-btn-list"
                                    type="button"
                                    width={
                                      device.deviceId === claimDeviceId
                                        ? '125px'
                                        : '100px'
                                    }
                                    backgroundColor={getComputedStyle(
                                      document.documentElement,
                                    ).getPropertyValue('--primary_40')}
                                    color={getComputedStyle(
                                      document.documentElement,
                                    ).getPropertyValue('--brand_white')}
                                    fontSize="12px"
                                    borderRadius="8px"
                                    height="34px"
                                    lineHeight="16px"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      addAppLink(
                                        childDevice,
                                        index,
                                        childIndex,
                                      );
                                    }}
                                  >
                                    {constants.CLAIM_DEVICE}
                                    {childDevice?.deviceId === claimDeviceId ? (
                                      <SiteSpinner
                                        height={20}
                                        width={20}
                                        backgroundColor={getComputedStyle(
                                          document.documentElement,
                                        ).getPropertyValue('--brand_white')}
                                      />
                                    ) : null}
                                  </PrimaryButton>
                                )}
                            </div>
                            <div className="detail-table">
                              <div className="table-column">
                                <div className="table-header">
                                  {constants.DEVICES_LIST_LOCATION}
                                </div>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip>
                                      {getDeviceDisplayLocation(childDevice)}
                                    </Tooltip>
                                  }
                                >
                                  <div className="table-info width-120">
                                    {getDeviceDisplayLocation(childDevice)}
                                  </div>
                                </OverlayTrigger>
                              </div>
                              <div className="table-column">
                                <div className="table-header">
                                  {constants.DEVICES_LIST_AREA}
                                </div>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip>
                                      {getDeviceDisplayArea(childDevice)}
                                    </Tooltip>
                                  }
                                >
                                  <div className="table-info width-120">
                                    {getDeviceDisplayArea(childDevice)}
                                  </div>
                                </OverlayTrigger>
                              </div>
                              <div className="table-column">
                                <div className="table-header">
                                  {constants.DEVICES_LIST_SERIAL_NUMBER}
                                </div>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip>{childDevice.serialNo}</Tooltip>
                                  }
                                >
                                  <div className="table-info width-150">
                                    {childDevice.serialNo}
                                  </div>
                                </OverlayTrigger>
                              </div>
                              {
                                <div className="table-column">
                                  <div className="table-header">
                                    {
                                      constants.DEVICES_LIST_LICENSE_REMAINING_STATUS
                                    }
                                  </div>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={getLicenseStatusToolTip(
                                      childDevice,
                                    )}
                                  >
                                    <div className="table-info width-120">
                                      {getLicenseStatus(childDevice)}
                                    </div>
                                  </OverlayTrigger>
                                </div>
                              }

                              <div className="table-column">
                                <div className="table-header">
                                  {constants.DEVICES_LIST_STATUS}
                                </div>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip>
                                      {childDevice.displayDeviceStatus}
                                    </Tooltip>
                                  }
                                >
                                  <div className="table-info width-120">
                                    {getDeviceStatus(childDevice)}
                                  </div>
                                </OverlayTrigger>
                              </div>
                            </div>
                          </div>
                        </div>
                        {device?.channels?.length === childIndex + 1 &&
                          device?.childPageDetails?.page + 1 <
                            device?.childPageDetails?.totalPages &&
                          device?.channels?.length > 0 && (
                            <div className="load-more-parent-btn">
                              {loadMoreChildLoading && (
                                <SiteSpinner
                                  height={20}
                                  width={20}
                                  backgroundColor={getComputedStyle(
                                    document.documentElement,
                                  ).getPropertyValue('--brand_primary')}
                                />
                              )}
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  loadMoreChannels(device, index, true);
                                }}
                                className={`load-more-text ${loadMoreChildLoading ? 'loading-state' : ''}`}
                              >
                                {loadMoreChildLoading
                                  ? constants.LOADING
                                  : 'Load More'}
                              </div>
                            </div>
                          )}
                      </div>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <div
                  className={`accordion-item`}
                  key={`item-notnvr-${device?.deviceId}`}
                >
                  <div className="w-100">
                    <div className="header">
                      <div className="header-section-1">
                        <div className="image-wrapper">
                          <img
                            src={
                              device?.channels?.[0]?.imageURL ||
                              device?.imageURL
                            }
                            onError={(e) => (e.target.src = `${DefaultDevice}`)}
                            alt=""
                            className="device-icon"
                          />
                        </div>
                        {device?.deviceName}
                        {device?.deviceStatus ===
                          constants.DEVICES_CLAIMED_DEVICE_STATUS &&
                        device?.isFirmwareUpgradeAvailable ? (
                          <div className="firmware-update">
                            <div className="update-text">
                              {constants.DEVICES_FIRMWARE_UPGRADE_AVAILABLE}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="header-section-2">
                        {device?.deviceStatus ===
                          constants.DEVICES_CLAIMED_DEVICE_STATUS &&
                          skuList?.find(
                            (item) => item?.model === device?.modelNumber,
                          )?.vms !== device?.apps?.vms && (
                            <PrimaryButton
                              className="device-btn-list"
                              type="button"
                              width={
                                device.deviceId === claimDeviceId
                                  ? '153px'
                                  : '128px'
                              }
                              backgroundColor={getComputedStyle(
                                document.documentElement,
                              ).getPropertyValue('--primary_40')}
                              color={getComputedStyle(
                                document.documentElement,
                              ).getPropertyValue('--brand_white')}
                              fontSize="14px"
                              height="44px"
                              lineHeight="16px"
                              onClick={() => addAppLink(device, index, null)}
                            >
                              {constants.CLAIM_DEVICE}
                              {device.deviceId === claimDeviceId ? (
                                <SiteSpinner
                                  height={20}
                                  width={20}
                                  backgroundColor={getComputedStyle(
                                    document.documentElement,
                                  ).getPropertyValue('--brand_white')}
                                />
                              ) : null}
                            </PrimaryButton>
                          )}
                        <RiSettings5Fill
                          size={24}
                          className={
                            getCustomerOrgPolicies()?.view_device_settings
                              ? 'setting-icon'
                              : 'setting-icon-disabled'
                          }
                          onClick={() => {
                            showChannelDetails(device);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {device?.channels?.[0]?.deviceId && (
                    <div
                      className={`w-100`}
                      role={
                        getCustomerOrgPolicies()?.view_device_settings
                          ? 'button'
                          : ''
                      }
                      onClick={() =>
                        showDeviceDashboard(
                          device?.channels?.[0] || device,
                          device,
                        )
                      }
                      id={device?.channels?.[0]?.deviceId}
                    >
                      <div className="camera pt-2 pb-2">
                        <LatestSnapshot
                          key={
                            device?.channels?.[0]?.deviceId || device?.deviceId
                          }
                          deviceElemId={`camera${index}`}
                          orgId={orgInfo?.orgId}
                          cdnInfo={cdnInfo}
                          locationAreaName={
                            device?.channels?.[0]?.locationAreaNames ||
                            device?.locationAreaNames
                          }
                          isHighlighted={false}
                          showZoomOptions={false}
                          conStatus={
                            device?.channels?.[0]?.connectionStatus ||
                            device?.connectionStatus ||
                            'offline'
                          }
                          hubId={device?.deviceId}
                          isScrolled={isScrolled}
                          displayDeviceStatus={Utils.getDeviceStatus(
                            device?.channels?.[0]?.deviceStatus,
                            device?.channels?.[0]?.connectionStatus?.toLowerCase(),
                          )}
                          {...device?.channels?.[0]}
                        />
                        <div className="camera-details">
                          <div className="detail-table">
                            <div className="table-column">
                              <div className="table-header">
                                {constants.DEVICES_LIST_LOCATION}
                              </div>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip>
                                    {getDeviceDisplayLocation(
                                      device?.channels?.[0] || device,
                                    )}
                                  </Tooltip>
                                }
                              >
                                <div className="table-info width-120">
                                  {getDeviceDisplayLocation(
                                    device?.channels?.[0] || device,
                                  )}
                                </div>
                              </OverlayTrigger>
                            </div>
                            <div className="table-column">
                              <div className="table-header">
                                {constants.DEVICES_LIST_AREA}
                              </div>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip>
                                    {getDeviceDisplayArea(
                                      device?.channels?.[0] || device,
                                    )}
                                  </Tooltip>
                                }
                              >
                                <div className="table-info width-120">
                                  {getDeviceDisplayArea(
                                    device?.channels?.[0] || device,
                                  )}
                                </div>
                              </OverlayTrigger>
                            </div>
                            <div className="table-column">
                              <div className="table-header">
                                {constants.DEVICES_LIST_SERIAL_NUMBER}
                              </div>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip>
                                    {device?.channels?.[0]?.serialNo ||
                                      device?.serialNo}
                                  </Tooltip>
                                }
                              >
                                <div className="table-info width-150">
                                  {device?.channels?.[0]?.serialNo ||
                                    device?.serialNo}
                                </div>
                              </OverlayTrigger>
                            </div>
                            {
                              <div className="table-column">
                                <div className="table-header">
                                  {
                                    constants.DEVICES_LIST_LICENSE_REMAINING_STATUS
                                  }
                                </div>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={getLicenseStatusToolTip(
                                    device?.channels?.[0] || device,
                                  )}
                                >
                                  <div className="table-info width-120">
                                    {getLicenseStatus(
                                      device?.channels?.[0] || device,
                                    )}
                                  </div>
                                </OverlayTrigger>
                              </div>
                            }

                            <div className="table-column">
                              <div className="table-header">
                                {constants.DEVICES_LIST_STATUS}
                              </div>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip>
                                    {device?.channels?.[0]
                                      ?.displayDeviceStatus ||
                                      device?.displayDeviceStatus}
                                  </Tooltip>
                                }
                              >
                                <div className="table-info width-120">
                                  {getDeviceStatus(
                                    device?.channels?.[0] || device,
                                  )}
                                </div>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {deviceList?.length === index + 1 &&
                !showLoader &&
                pageDetails?.page + 1 < pageDetails?.totalPages &&
                deviceList?.length > 0 && (
                  <div className="load-more-parent-btn">
                    {loadMoreLoading && (
                      <SiteSpinner
                        height={20}
                        width={20}
                        backgroundColor={getComputedStyle(
                          document.documentElement,
                        ).getPropertyValue('--brand_primary')}
                      />
                    )}
                    <div
                      onClick={() => loadMoreGateway(true)}
                      className={`load-more-text ${loadMoreLoading ? 'loading-state' : ''}`}
                    >
                      {loadMoreLoading ? constants.LOADING : 'Load More'}
                    </div>
                  </div>
                )}
            </div>
          );
        })}
      </LazyLoadComponent>
    );
  };

  const handleDeviceClaimModel = (value) => {
    setIsDirectToScannedPopup(false);
    setShowClaimDeviceModal(value);
  };

  const optionStatusSelectedList = selectedOption
    ?.filter((option) => option?.isSelected)
    ?.map((item) => item.label)
    ?.join(', ');
  const optionSelected =
    optionStatusSelectedList == ''
      ? ` ${'Filter By'}`
      : optionStatusSelectedList;

  return (
    <div className="App manage-devices">
      <Header breadcrumbData={breadList} />
      <div className="page-header mb-3 sticky-top sticky-devices-header">
        <Container className="container-top-offset mw-100" lg={8}>
          <Col md={6} lg={6} xl={6} xs={12}>
            <BreadcrumbList list={breadList} />
          </Col>
          <Row>
            <Col md={6} lg={6} xl={6} xs={12} className="page-title text-start">
              {constants.DEVICES_PAGE_TITLE}
            </Col>

            <Col md={6} lg={6} xl={6} xs={12} className="end-align">
              <PrimaryButton
                className={`device-btn-list${
                  !enableAttachAllLicenses && ' d-none'
                }`}
                type="button"
                width="150px"
                backgroundColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--brand_white')}
                color={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--brand_black')}
                fontSize="0.875rem"
                height="44px"
              >
                {constants.DEVICES_ATTACH_ALL_LICENSES}
              </PrimaryButton>
              {getCustomerOrgPolicies()?.install_device && (
                <AddDevice
                  reloadData={fetchData}
                  showClaimDeviceModal={showClaimDeviceModal}
                  setShowClaimDeviceModal={handleDeviceClaimModel}
                  isDirectToScannedPopup={isDirectToScannedPopup}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                {/**
                 * Intentionally left blank to add a bottom offset.
                 */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="device-list-wrapper">
        <div
          className={
            'filter-wrapper sticky-top sticky-devices-header-filter ' +
            (showDropDown ? 'filter-wrapper-height' : '')
          }
        >
          <Row className="w-100">
            <Col>
              <div className="filter-container filter-column d-flex flex-direction-row gap-4">
                <div className="search-container">
                  <IoIosSearch className="search-icon" />
                  <input
                    value={searchText}
                    type={'text'}
                    placeholder={
                      constants.DISCOVERED_DEVICES_SEARCH_PLACEHOLDER
                    }
                    className="search-input"
                    onChange={(e) => onChangeSearchText(e)}
                    onKeyDown={(e) => onKeyDownSearch(e)}
                  />
                </div>
                <LocationAreaDeviceList
                  onToggle={() => setShowDropDown(!showDropDown)}
                  locations={orglocations}
                  selectedLocationId={locationId}
                  sendSelectedLocationId={(locationIds) => {
                    if (locationIds?.length > 0) {
                      if (locationId === locationIds[0]) {
                        return;
                      }
                      // setSelectedOption(filterOptions);
                      // setSearchText('');
                      scrollToTop();
                      setPageDetails(defaultPageDetails);
                      setDeviceList([]);
                      dispatch(
                        setDeviceFilters({ locationId: locationIds[0] }),
                      );
                      setLocationId(locationIds[0]);
                    }
                  }}
                />
                <Dropdown
                  onToggle={() => setShowDropDown(!showDropDown)}
                  className="area-selector mr-3 status-drop-down"
                  autoClose="outside"
                >
                  <Dropdown.Toggle
                    variant="outline-secondary"
                    id="dropdown-basic"
                    className="area-selector-toggle custom-option-device-list"
                  >
                    <div className="area-selected-wrapper">
                      <span className="area-selected-name area-width-status">
                        {optionSelected}
                      </span>
                    </div>
                    <HiOutlineChevronDown size={20} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="device-status-selector-menu filter-area-style custom-option-device-menu">
                    <div
                      className="select-all-label"
                      onClick={(e) => {
                        handleChange({ value: DeviceStatusEnum.SELECT_ALL });
                      }}
                    >
                      {constants.DEVICE_SELECT_ALL}
                    </div>
                    {selectedOption.map((option, optionIndex) => (
                      <Dropdown.Item
                        key={option.value}
                        className="device-status-selector-item device-radio-wrapper"
                        onClick={(e) => handleChange(option, optionIndex)}
                      >
                        {option?.isSelected ? (
                          <ImCheckboxChecked
                            className="img-selected"
                            color={getComputedStyle(
                              document.documentElement,
                            ).getPropertyValue('--primary_40')}
                            size={15}
                          />
                        ) : (
                          <img
                            alt=""
                            className="img-unselected"
                            src={checkUnslect}
                          ></img>
                        )}

                        {option.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </div>
        <PageWrapper className="mw-100 mt-4 device-list-page-wrapper">
          {showLoader ? (
            <div className="spinner-container-device-list">
              <div className="spinner-center-device-list">
                <SiteSpinner className="spinner-size-device-list" />
                <div className="mt-2">{constants.LOADING}</div>
              </div>
            </div>
          ) : (
            <>
              {discoveredDevicesCount ? (
                <div
                  ref={deviceListRefs}
                  className="discovered-devices-container"
                >
                  <div className="d-flex">
                    <HiOutlineInformationCircle size={20} />
                    <div className="discovered-device-message">
                      {constants.DISCOVERED_DEVICES_MESSAGE}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="pending-count">
                      {discoveredDevicesCount}{' '}
                      {constants.DISCOVERED_DEVICES_PENDING_COUNT}
                    </div>
                    <PrimaryButton
                      className="device-btn-list"
                      type="button"
                      width="132px"
                      backgroundColor={getComputedStyle(
                        document.documentElement,
                      ).getPropertyValue('--primary_40')}
                      color={getComputedStyle(
                        document.documentElement,
                      ).getPropertyValue('--brand_white')}
                      fontSize="12px"
                      height="32px"
                      lineHeight="16px"
                      borderRadius="8px"
                      onClick={() => {
                        navigate(
                          `/devices/discovered-devices.html?orgId=${orgInfo?.orgId}`,
                        );
                      }}
                    >
                      {constants.DISCOVERED_DEVICES_SETUP_BUTTON}
                    </PrimaryButton>
                  </div>
                </div>
              ) : (
                <div ref={deviceListRefs}></div>
              )}
              <div className="list-block">
                <Container className="h-100 mw-100">
                  {getCustomerOrgPolicies()?.view_device && (
                    <div className="list-wrapper">
                      {!isDeviceAvailable && deviceList?.length ? (
                        <div>
                          <Accordion
                            className="accordion accordion-list"
                            alwaysOpen
                          >
                            {ShowOrgSingletonDevices()}
                          </Accordion>
                        </div>
                      ) : (
                        isDeviceAvailable && (
                          <div className="no-devices-container">
                            <div className="image-wrapper">
                              <HiOutlineVideoCamera size={32} />
                            </div>
                            <div>{constants.DEVICE_NOT_ADDED}</div>
                          </div>
                        )
                      )}
                    </div>
                  )}
                  {showListScrollLoader ? (
                    <div className="show-variable-list-loader">
                      <SiteSpinner height="50px" width="50px"></SiteSpinner>
                    </div>
                  ) : null}
                </Container>
              </div>
            </>
          )}
        </PageWrapper>
      </div>
    </div>
  );
};
export default trackWindowScroll(DeviceListCloud);
