import { useEffect, useRef, useState } from 'react';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import {
  SelectField,
  ToggleInput,
  TextField,
  InputSlider,
} from '../../../../components/forms';
import {
  PrimaryButton,
  SiteModal,
  TextBlock,
} from '../../../../components/common';
import { Formik } from 'formik';
import {
  constants,
  EntitlementsTypeEnum,
  LicenseStatuses,
  OfferingTypeEnum,
  Utils,
} from '../../../../helpers';
import { HiOutlineExclamationCircle, HiOutlinePencil } from 'react-icons/hi';
import publishDeviceSetting from '../shared/PublishDeviceSettings';
import './DeviceSettingsBlock.scss';
import { useNavigate } from 'react-router-dom';
import { useLoggedInUserData } from '../../../../store/LoggedInAccountStore';
import {
  getAllMqttDataFromResponse,
  setAllMqttDataFromResponse,
} from '../../../../store/reducers/AccountReducer';
import { useSelector } from 'react-redux';
import { usePoliciesStore } from '../../../../store/policiesStore';
import { MdOutlineEdit } from 'react-icons/md';
import { Plusred } from '../../../../assets/images';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { devicesMQTTStore } from '../../../../store/DevicesMQTTStore';
import { mqttPublish } from '../../../../utils/connection/mqttConnection';
import useLicensesStore from '../../../../store/LicensesStore';
import { getOrgInfo } from '../../../../store/reducers/OrganizationsReducer';
import axios from 'axios';
import { EncodingTypes } from '../../../../helpers/enums';
import ReactSlider from 'react-slider';
import { boolean } from 'yup';
import BackLightArea from './BackLightArea';
import { useDispatch } from 'react-redux';
import { setOfferrecived } from '../../../../store/reducers/StreamingReducer';

const DeviceSettingsBlock = ({
  orgDetails,
  orgId,
  orgName,
  deviceId,
  deviceName,
  saveDeviceSettingsFlag,
  saveDeviceSettingsCallback,
  deviceInfos,
  accountId,
  updateStore,
  devicePublishId,
  uuid,
  hasManagePermission,
  deviceFPS,
  deviceRes,
  parent,
  deviceBitRate,
  entitlements,
}) => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const orgInfo = useSelector(getOrgInfo);
  const [variant, setVariant] = useState('danger');
  const [deviceBitrate, setDeviceBitRate] = useState(deviceBitRate);
  const [userMsg, setUserMsg] = useState('');
  const [showMsg, setShowMsg] = useState(true);
  const [fpsReuest, setFpsReuest] = useState(false);
  const [payloadProperty, setPayloadProperty] = useState({});
  const [pendingValue, setPendingValue] = useState('');
  const [fieldName, setFieldName] = useState('');
  const [selectedFrameRate, setSelectedFrameRate] = useState(null);
  const [allBrs, setAllBrs] = useState([]);
  const [isEditMode, setIsEditMode] = useState(null);
  const [NetworkContinueModal, setNetworkContinueModal] = useState(false);
  const [kbpsValue, setkbpsValue] = useState();
  const [daysList, setDaysList] = useState(Utils.getWeekDays(['0']));
  const [selectedIds, setSelectedIds] = useState([]);
  const [updateDayStaus, setUpdateDayStaus] = useState(false);
  const { licenses, getLicenses, setLicenses, setIslicensesAttachStatus } =
    useLicensesStore();
  const [availableLicenses, setAvailableLicenses] = useState([]);
  const [deviceLicenses, setDeviceLicenses] = useState([]);
  const [selectedDaysValues, setSelectedDaysValues] = useState([]);
  const [checkDisableStateRes, setCheckDisableStateRes] = useState();
  const [checkDisableStateFrameRate, setCheckDisableStateFrameRate] =
    useState();
  const [checkDisableStateBitRate, setCheckDisableStateBitRate] = useState();
  const [BackLightSelectedTab, setBackLightSelectedTab] = useState(
    deviceInfos?.properties?.['backlight-mode'] &&
      deviceInfos?.properties?.['backlight-mode'].toUpperCase(),
  );
  const [showBackLightArea, setShowBackLightArea] = useState(false);
  const defalutDateStart = new Date();
  defalutDateStart.setHours(0);
  defalutDateStart.setMinutes(0);
  defalutDateStart.setSeconds(0);
  const defalutDateEnd = new Date();
  defalutDateEnd.setHours(0);
  defalutDateEnd.setMinutes(5);
  defalutDateEnd.setSeconds(0);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(5);
  const [endTimeMoment, setEndTimeMoment] = useState(moment(defalutDateEnd));
  const [isOverlaping, setIsOverlaping] = useState(false);
  const { getState } = devicesMQTTStore;
  const state = getState();
  const appTopic = `a/notify/${deviceInfos.gatewayId}`;
  const resourceMaxBr = Utils.getDeviceSettingResource(9);
  const [bitrateToggleStatus, setBitrateToggleStatus] = useState({
    'mbr-enable': true,
  });
  const [wdrLevelbackLight, setWdrLevelbackLight] = useState(
    deviceInfos?.properties?.['wdr-level'],
  );
  const [wdrControlbackLight, setWdrControlbackLight] = useState(
    deviceInfos?.properties?.['wdr-control'],
  );
  const [blcLevelbackLight, setBlcLevelbackLight] = useState(
    deviceInfos?.properties?.['blc-level'],
  );
  const [blcAreaCoordinates, setBlcAreaCoordinates] = useState(
    deviceInfos?.properties?.['blc-area-coordinates'],
  );
  const [hlcAreaCoordinates, setHlcAreaCoordinates] = useState(
    deviceInfos?.properties?.['hlc-area-coordinates'],
  );

  const [hlcLevelBackLight, setHlcLevelBackLight] = useState(
    deviceInfos?.properties?.['hlc-level'],
  );
  const [hlcMaskBackLight, setHlcMaskBackLight] = useState(
    deviceInfos?.properties?.['hlc-mask'],
  );
  const [hlcMaskColorBackLight, setHlcMaskColorBackLight] = useState(
    deviceInfos?.properties?.['hlc-mask-color'],
  );
  const [hlcMaskToneBackLight, setHlcMaskToneBackLight] = useState(
    deviceInfos?.properties?.['hlc-mask-tone'],
  );
  const [hlcDimmingBackLight, setHlcDimmingBackLight] = useState(
    deviceInfos?.properties?.['hlc-dimming'],
  );
  const [showBitRateScheduleSection, setShowBitRateScheduleSection] =
    useState(false);

  const timeOutRef = useRef(null);
  const [startTimeMoment, setStartTimeMoment] = useState(
    moment(defalutDateStart),
  );
  const deviceInfoProp = deviceInfos?.properties;
  const dayDefault =
    selectedIds?.length > 1 && selectedIds?.length < 7
      ? constants.UPDATE_FIRMWARE_MULTIPLE
      : selectedIds?.length === 7
        ? constants.UPDATE_FIRMWARE_ALL_DAYS
        : selectedIds?.length === 1
          ? Utils.getWeekDays(selectedIds)?.find(
              (day) => day?.id?.toString() === selectedIds[0],
            )?.day
          : 'Sunday';

  const [recordingVideoCodecValue, setRecordingVideoCodecValue] = useState('');
  const [dewarpAngleValue, setDewarpAngleValue] = useState('');
  const [dewarpAngleNewValue, setDewarpAngleNewValue] = useState('');
  const [showDeviceRestartModal, setShowDeviceRestartModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const capabilityList = deviceInfos?.capDetails?.capabilities;
  const deviceState = deviceInfos?.deviceStatus;
  const connectionState = deviceInfos?.connectionStatus;
  const curDeviceStatus = Utils.getDeviceStatus(deviceState, connectionState);
  const [confirmRestartRequiredModal, setConfirmRestartRequiredModal] =
    useState(false);
  const [modalType, setModalType] = useState(1);
  const bitrateProps = {
    'mbr-enable': bitrateToggleStatus['mbr-enable'],
    'mbr-kbps': kbpsValue,
    'mbr-day': selectedIds?.join(','),
    'mbr-st': startTime,
    'mbr-et': endTime,
  };
  const [selectDay, setselectDay] = useState(dayDefault);
  const [isWdrVisible, setIsWdrVisible] = useState(true);
  const [isIrVisible, setIsIrVisible] = useState(true);

  const canChangeSettings =
    curDeviceStatus === constants.DEVICES_RETURN_ONLINE_STATUS &&
    hasManagePermission;
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies,
  );
  const resourceList = Utils.getDeviceSettingResource(1);
  const resourceListEdgeRec = Utils.getDeviceSettingResource(6);
  const resourceListFishEyeLens = Utils.getDeviceSettingResource(10);
  const capFlipRotate = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[0],
  );
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  const deviceDegreeRotation = Utils.getDropDownPair(
    capFlipRotate?.['rotation-angle'],
  )?.map((degree) => ({
    ...degree,
    label: constants.HALL_WAY_VIEW_DEGREES?.replace('{degrees}', degree?.label),
  }));
  const capVideoProfile = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[2],
  );
  const capEdgeRec = Utils.getCurrentTabProperty(
    capabilityList,
    resourceListEdgeRec[5],
  );
  const capBackLight = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[6],
  );
  const backLightModes = capBackLight?.['backlight-mode'];
  const desiredOrder = ['wdr', 'blc', 'hlc', 'off'];
  const sortedModes =
    backLightModes &&
    desiredOrder.filter((item) => backLightModes.includes(item));
  const capDewarpAngle = Utils.getCurrentTabProperty(
    capabilityList,
    resourceListFishEyeLens[1],
  );

  const capBacklightControl = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[6],
  );

  const capInclusionArea = {
    'md-enable': ['true', 'false'],
    'md-include-area-level': {
      min: '1',
      max: '100',
    },
    'md-include-area-sensitivity': {
      min: '1',
      max: '100',
    },
    'md-include-area-min-dur': {
      min: '0',
      max: '5',
    },
    'md-include-area-enable': ['true', 'false'],
    'md-include-area-index': {
      min: '0',
      max: '7',
    },
    'md-include-area-x-range': {
      min: '0',
      max: '3839',
    },
    'md-include-area-y-range': {
      min: '0',
      max: '2159',
    },
    'md-exclude-area-enable': ['true', 'false'],
    'md-exclude-area-index': {
      min: '0',
      max: '7',
    },
    'md-exclude-area-x-range': {
      min: '0',
      max: '3839',
    },
    'md-exclude-area-y-range': {
      min: '0',
      max: '2159',
    },
  };

  const blcCapabilityExists =
    capBacklightControl?.['backlight-mode']?.includes('blc');
  const hlcCapabilityExists =
    capBacklightControl?.['backlight-mode']?.includes('hlc');

  const [recordCodecList, setRecordCodecList] = useState([]);
  const [dewarpAngleList, setDewarpAngleList] = useState([]);
  const min =
    Number(capVideoProfile?.['bit-rate']?.min) >= 1
      ? Number(capVideoProfile?.['bit-rate']?.min)
      : 0;
  const max = deviceBitrate
    ? deviceBitrate.data.maxValue
    : Number(capVideoProfile?.['bit-rate']?.max);
  const timezone = parent?.properties?.timezone?.split('-')?.[0];

  const findHighestResolution = (resolutions) => {
    let maxResolution = null;
    let maxPixels = 0;

    resolutions.forEach((res) => {
      const [width, height] = res.value.split('x').map(Number);
      const pixels = width * height;

      if (pixels > maxPixels) {
        maxPixels = pixels;
        maxResolution = res.value;
      }
    });

    return maxResolution;
  };

  const shouldShowCloudStorageCvr = () => {
    if (entitlements) {
      const currentTimestamp = Date.now();
      const cloudStorageCvr = entitlements.find(
        (item) => item.type === EntitlementsTypeEnum.CLOUD_STORAGE_CVR,
      );

      if (cloudStorageCvr && cloudStorageCvr.expiry >= currentTimestamp) {
        setShowBitRateScheduleSection(true);
      } else {
        setShowBitRateScheduleSection(false);
      }
    }
  };

  useEffect(() => {
    shouldShowCloudStorageCvr();
  }, [entitlements]);

  const deviceResolution =
    deviceRes && capVideoProfile?.['mp-resolution']
      ? Utils.getDropDownPairRes(
          capVideoProfile?.['mp-resolution'],
          deviceRes.data.maxValue,
        )
      : capVideoProfile?.['mp-resolution']?.max
        ? Utils.getDropDownPairRes(
            capVideoProfile?.['mp-resolution'],
            capVideoProfile?.['mp-resolution']?.max,
          )
        : deviceInfos?.properties?.['max-mp-resolution']
          ? Utils.getDropDownPairRes(
              capVideoProfile?.['mp-resolution'],
              deviceInfos?.properties?.['max-mp-resolution'],
            )
          : [];

  const backLightWDRControl = capBackLight?.['wdr-control']
    ? Utils.getDropDownPairWithFirstCap(capBackLight?.['wdr-control'])
    : [];

  const backLightHLCMask = capBackLight?.['hlc-mask']
    ? Utils.getDropDownPairWithFirstCap(capBackLight?.['hlc-mask'])
    : [];

  const backLightHLCMaskColor = capBackLight?.['hlc-mask-color']
    ? Utils.getDropDownPairWithFirstCap(capBackLight?.['hlc-mask-color'])
    : [];

  const capWisestream = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[3],
  );
  const capIrMode = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[4],
  );
  const capEnhacement = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[5],
  );

  useEffect(() => {
    setBackLightSelectedTab(
      deviceInfos?.properties?.['backlight-mode'] &&
        deviceInfos?.properties?.['backlight-mode'].toUpperCase(),
    );
    setWdrLevelbackLight(deviceInfos?.properties?.['wdr-level']);
    setWdrControlbackLight(deviceInfos?.properties?.['wdr-control']);
    setBlcLevelbackLight(deviceInfos?.properties?.['blc-level']);
    setHlcLevelBackLight(deviceInfos?.properties?.['hlc-level']);
    setHlcMaskBackLight(deviceInfos?.properties?.['hlc-mask']);
    setHlcMaskColorBackLight(deviceInfos?.properties?.['hlc-mask-color']);
    setHlcMaskToneBackLight(deviceInfos?.properties?.['hlc-mask-tone']);
    setHlcDimmingBackLight(deviceInfos?.properties?.['hlc-dimming']);
    setBlcAreaCoordinates(deviceInfos?.properties?.['blc-area-coordinates']);
    setHlcAreaCoordinates(deviceInfos?.properties?.['hlc-area-coordinates']);
  }, [deviceInfos]);

  useEffect(() => {
    const edgeCvrExist = entitlements?.find(
      (item) => item.type === EntitlementsTypeEnum.EDGE_STORAGE_CVR,
    );
    const edgeRecSupport =
      edgeCvrExist && edgeCvrExist?.expiry > new Date().getTime();
    const filteredEncodingTypes = capVideoProfile?.['encoding-type']
      .filter((type) => type !== EncodingTypes?.MJPEG_TYPE) // Always remove 'mjpeg'
      .filter(
        (type) => !(type === EncodingTypes?.H265_TYPE && !edgeRecSupport),
      );
    setRecordCodecList(
      filteredEncodingTypes?.map((type) => ({
        label: type.toUpperCase().replace('H', 'H.'),
        value: type,
      })),
    );
    if (capDewarpAngle && capDewarpAngle?.['mount-angle']?.length > 0) {
      setDewarpAngleList(
        capDewarpAngle?.['mount-angle']?.map((type) => ({
          label: String(type).charAt(0).toUpperCase() + String(type).slice(1),
          value: type,
        })),
      );
    }
    setDewarpAngleValue(deviceInfos?.properties?.['mount-angle']);
    setRecordingVideoCodecValue(deviceInfos?.properties?.['encoding-type']);
  }, [deviceInfos]);

  const publishPayload = {
    deviceId: deviceId,
    devicePublishId: devicePublishId,
    uuid: uuid,
    accountId: accountId,
    hubId: deviceInfos.gatewayId,
  };
  const navigate = useNavigate();
  const prevFrameRateRef = useRef(formRef?.current?.values?.frameRate);

  useEffect(() => {
    if (
      prevFrameRateRef.current !== undefined &&
      prevFrameRateRef.current !== formRef?.current?.values?.frameRate
    ) {
      setSelectedFrameRate(formRef?.current?.values?.frameRate);
      const property = {
        'minimum-dynamic-fps': Math.floor(
          formRef?.current?.values?.frameRate / 2,
        ).toString(),
      };
      updateStore(property);
      publishDeviceSetting(property, resourceList[2], publishPayload);
    }
    prevFrameRateRef.current = formRef?.current?.values?.frameRate;
  }, [formRef?.current?.values?.frameRate]);

  const fetchData = async (url) => {
    if (!url) return;
    try {
      fetch(url, {
        withCredentials: true,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            const entitlements = data.entitlements;
            if (entitlements) {
              const videoBitRate = entitlements?.find(
                (item) => item.type === EntitlementsTypeEnum.BITRATE,
              );
              if (videoBitRate) setDeviceBitRate(videoBitRate);
            }
          }
        })
        .catch(function (error) {});
    } catch (error) {}
  };

  const getCapabiltiesForDevice = async () => {
    await axios
      .get(`device/orgs/${orgId}/devices`, Utils.requestHeader())
      .then(async (response) => {
        const resultData = response.data;
        if (resultData) {
          const responseMeta = resultData.meta;
          const responseData = resultData.data;
          if (responseData) {
            const matchedDevices = responseData.filter(
              (device) => device.deviceId === deviceInfos.deviceId,
            );
            fetchData(matchedDevices[0]?.entitlement?.url);
          }
        }
      });
  };

  useEffect(() => {
    getCapabiltiesForDevice();
  }, []);

  useEffect(() => {
    if (formRef?.current && saveDeviceSettingsFlag) {
      formRef?.current?.handleSubmit();
    }
  }, [saveDeviceSettingsFlag]);

  useEffect(() => {
    const noIndex = deviceInfos?.properties?.['max-br']?.find(
      (b) => !b?.['mbr-index'],
    );
    if (
      deviceInfos?.properties?.['max-br'] &&
      JSON.stringify(deviceInfos?.properties?.['max-br']) !==
        JSON.stringify(allBrs) &&
      !noIndex
    )
      setAllBrs(
        deviceInfos?.properties?.['max-br']
          ? deviceInfos?.properties?.['max-br']
          : [],
      );
  }, [deviceInfos?.properties]);

  const getAllBitRates = () => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const sessionId = state.getSessionId();
    if (!accountId) {
      return;
    }
    // Send the request
    const context = {
      topic: appTopic,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${deviceInfos.gatewayId}`,
        from: `${accountId}`,
        msg: {
          action: 'get',
          resource: `ch/${deviceId}/${resourceMaxBr[0]}`,
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
  };

  useEffect(() => {
    getAllBitRates();
  }, []);

  useEffect(() => {
    const getDynamicFPSMaxValue = (values) => {
      let value;
      if (
        capVideoProfile?.['minimum-dynamic-fps']?.[values?.deviceResolution]
      ) {
        value =
          capVideoProfile['minimum-dynamic-fps'][values?.deviceResolution]?.max;
      } else {
        value = capVideoProfile?.['minimum-dynamic-fps']?.max;
        const propertyData = allMqttData?.msg?.properties;
        if (
          propertyData?.hasOwnProperty('dynamic-fps-enable') ||
          propertyData?.hasOwnProperty('minimum-dynamic-fps')
        ) {
          setFpsReuest(false);
        }
      }
    };
    getDynamicFPSMaxValue();
  }, [allMqttData]);

  const getDynamicFPSMinValue = (values) => {
    let propertyValue;
    if (capVideoProfile?.['minimum-dynamic-fps']?.[values?.deviceResolution]) {
      propertyValue =
        capVideoProfile['minimum-dynamic-fps'][values.deviceResolution].min;
    } else {
      propertyValue = capVideoProfile?.['minimum-dynamic-fps']?.min;
    }
    return Utils.getPropertyValueIfExist(propertyValue, false);
  };

  const getCheckPropertyStatus = (value, key) => {
    return {
      [key]: value
        ? constants.PROPERTY_STATUS_TRUE
        : constants.PROPERTY_STATUS_FALSE,
    };
  };

  const handleChange = (setFieldValue) => {
    setFieldValue(fieldName, pendingValue);
    updateStore(payloadProperty);
    publishDeviceSetting(payloadProperty, resourceList[0], publishPayload);
  };

  const getSelectedDays = (br) => {
    let days = [];
    br['mbr-day'].split(',')?.forEach((day) => {
      const dayData = Utils.getWeekDays([day])?.find(
        (dayObj) => dayObj?.id === Number(day),
      );
      days.push(dayData?.day);
    });
    return days.join(', ');
  };

  const timeConversion = (time) => {
    const hours = Math.trunc(time / 60);
    const pref = hours > 11 ? 'PM' : 'AM';
    const hr = hours > 12 ? hours - 12 : hours;
    const hbr = hr ? hr : 12;
    const mins = time % 60;
    const finalMin = mins?.toString()?.length === 1 ? `0${mins}` : mins;
    const finalHr = hbr?.toString()?.length === 1 ? `0${hbr}` : hbr;
    return `${finalHr}.${finalMin} ${pref}`;
  };

  const onEditBr = (br) => {
    setIsEditMode(br['mbr-index']);
    const startD = new Date();
    const endD = new Date();
    const dayList = Utils.getWeekDays(br['mbr-day'].split(','));
    startD.setHours(Math.trunc(br['mbr-st'] / 60));
    startD.setMinutes(Math.trunc(br['mbr-st'] % 60));
    endD.setHours(Math.trunc(br['mbr-et'] / 60));
    endD.setMinutes(Math.trunc(br['mbr-et'] % 60));
    daysList?.forEach((days) => {
      selectedDay(days);
    });
    setDaysList(dayList);
    setStartTime(br['mbr-st']);
    setEndTime(br['mbr-et']);
    setStartTimeMoment(moment(startD));
    setEndTimeMoment(moment(endD));
    setkbpsValue(br['mbr-kbps']);
    setBitrateToggleStatus({ 'mbr-enable': br['mbr-enable'] });
    setNetworkContinueModal(true);
  };
  const resetProps = () => {
    setDaysList(Utils.getWeekDays(['0']));
    setStartTime(0);
    setEndTime(5);
    setkbpsValue(min);
    setStartTimeMoment(moment(defalutDateStart));
    setEndTimeMoment(moment(defalutDateEnd));
    setselectDay(dayDefault);
    setSelectedDaysValues([]);
    setSelectedIds([]);
    setBitrateToggleStatus({ 'mbr-enable': true });
    setModalType(1);
  };

  const formatValue = (value) => {
    if (value < 1024) {
      return `${value} ${constants.KBPS}`;
    } else {
      return `${(value / 1024).toFixed(1)} ${constants.MBPS}`;
    }
  };

  const setStartEndTime = (epochValue, isTimeStart) => {
    if (isNaN(epochValue)) return;
    const date = new Date(epochValue);
    const calculatedTime = date.getHours() * 60 + date.getMinutes();
    if (isTimeStart) {
      setStartTime(calculatedTime);
      setStartTimeMoment(epochValue);
    } else {
      setEndTime(calculatedTime);
      setEndTimeMoment(epochValue);
    }
    checkOverlaping(calculatedTime);
  };

  const selectedDay = (dayData) => {
    const dayListfirm = [...daysList];
    dayListfirm.map((data, i) => {
      if (dayData.id == data.id) {
        if (data.isSelected && selectedIds?.length != 1) {
          daysList[i].isSelected = false;
        } else {
          daysList[i].isSelected = true;
        }
      }
    });
    setDaysList(dayListfirm);
    setUpdateDayStaus(true);
  };

  const renderReactSlider = (props, state) => {
    setkbpsValue(state.value);
    return (
      <div {...props}>
        {state?.index === 0 && (
          <span className="range-slider-start-value max-recording-bitrate-style">{`${min} ${constants.KBPS}`}</span>
        )}
        {state?.index === 1 && (
          <span className="range-slider-last-value max-recording-bitrate-style">
            {formatValue(max)}
          </span>
        )}
      </div>
    );
  };

  useEffect(() => {
    const checks = [
      {
        key: 'mp-resolution',
        type: 'RESOLUTION',
        setState: setCheckDisableStateRes,
      },
      {
        key: 'frame-rate',
        type: 'FPS',
        setState: setCheckDisableStateFrameRate,
      },
      {
        key: 'bit-rate',
        type: 'BITRATE',
        setState: setCheckDisableStateBitRate,
      },
    ];

    checks.forEach(({ key, type, setState }) => {
      setState(
        key in deviceInfoProp &&
          entitlements.some((item) => item.type === type),
      );
    });
  }, [deviceInfoProp, entitlements]);

  const commaSeparatedDays = () => {
    return selectedDaysValues.join(', ');
  };

  const checkOverlaping = () => {
    if (!selectedIds.length) {
      return;
    }
    if (startTime >= endTime) {
      setIsOverlaping(true);
      setShowMsg(true);
      return;
    }
    allBrs.map((br) => {
      if (isEditMode !== br['mbr-index']) {
        let isCommonDay = false;
        selectedIds?.forEach((id) => {
          if (br['mbr-day'].includes(id)) {
            isCommonDay = true;
          }
        });

        if (
          isCommonDay &&
          (br['mbr-st'] === startTime ||
            startTime === br['mbr-et'] ||
            (br['mbr-st'] < startTime && startTime < br['mbr-et']))
        ) {
          setIsOverlaping(true);
          setShowMsg(true);
        }
        if (
          isCommonDay &&
          (br['mbr-st'] === endTime ||
            endTime === br['mbr-et'] ||
            (br['mbr-st'] < endTime && endTime < br['mbr-et']))
        ) {
          setIsOverlaping(true);
          setShowMsg(true);
        }
        if (isCommonDay && startTime < br['mbr-st'] && br['mbr-st'] < endTime) {
          setIsOverlaping(true);
          setShowMsg(true);
        }
        if (isCommonDay && startTime < br['mbr-et'] && br['mbr-et'] < endTime) {
          setIsOverlaping(true);
          setShowMsg(true);
        }
      }
    });
  };

  useEffect(() => {
    const dayIds = [];
    const defaultSelectedValue = [];
    daysList &&
      daysList.forEach((days) => {
        if (days.isSelected) {
          dayIds.push(days.id);
          defaultSelectedValue.push(days.shortName);
        }
      });
    if (dayIds?.length > 1 && dayIds?.length < 7) {
      setselectDay(constants.UPDATE_FIRMWARE_MULTIPLE);
    } else if (dayIds?.length === 7) {
      setselectDay(constants.UPDATE_FIRMWARE_ALL_DAYS);
    } else if (dayIds?.length === 1) {
      const day =
        dayIds?.length === 1
          ? Utils.getWeekDays(dayIds)?.find((day) => day?.id === dayIds[0])?.day
          : 'Sunday';
      setselectDay(day);
    }
    setSelectedIds(dayIds);
    setUpdateDayStaus(false);
    setSelectedDaysValues(defaultSelectedValue);
  }, [updateDayStaus]);

  useEffect(() => {
    setIsOverlaping(false);
    setShowMsg(false);
    checkOverlaping();
  }, [selectedIds, startTime, endTime]);

  const renderTimePicker = (timeValue, isStart) => {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopTimePicker
          onChange={(newValue) => {
            setStartEndTime(newValue, isStart);
          }}
          value={timeValue}
          renderInput={(params) => <TextField />}
          closeOnSelect={false}
        />
      </LocalizationProvider>
    );
  };

  const deleteMaxStrRequest = () => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const sessionId = state.getSessionId();
    if (!accountId) {
      return;
    }
    // Send the request
    const context = {
      topic: appTopic,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${deviceInfos.gatewayId}`,
        from: `${accountId}`,
        msg: {
          resource: `ch/${deviceId}/${resourceMaxBr[0]}`,
          action: 'delete',
          properties: {
            'mbr-index': isEditMode,
          },
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
    setIsEditMode(null);
    resetProps();
    setNetworkContinueModal(false);
  };

  const preUpdateRequest = () => {
    const newList = JSON.parse(JSON.stringify(allBrs));
    let index = -1;

    allBrs.forEach((br, ind) => {
      if (br['mbr-index'] === isEditMode) {
        index = ind;
      }
    });
    bitrateProps['mbr-index'] = isEditMode;
    newList[index] = bitrateProps;
    setAllBrs(newList);
    updateMaxBRRequest(bitrateProps, newList);
    setConfirmRestartRequiredModal(false);
  };

  const getUpdateConfirmation = () => {
    setModalType(3);
    setNetworkContinueModal(false);
    setConfirmRestartRequiredModal(true);
  };

  const updateMaxBRRequest = (props, newBrs) => {
    setIsEditMode(null);
    const sessionId = state.getSessionId();
    if (!accountId) {
      return;
    }
    // Send the request
    const tid = Math.floor(new Date().getTime());
    const context = {
      topic: appTopic,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${deviceInfos.gatewayId}`,
        from: `${accountId}`,
        msg: {
          action: 'set',
          resource: `ch/${deviceId}/${resourceMaxBr[0]}`,
          properties: props,
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
    setIsEditMode(null);
    resetProps();
    setNetworkContinueModal(false);
    updateStore({ 'max-br': newBrs });
  };

  const addMaxStrRequest = () => {
    const sessionId = state.getSessionId();
    if (!accountId) {
      return;
    }
    // Send the request
    const tid = Math.floor(new Date().getTime());
    const context = {
      topic: appTopic,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${deviceInfos.gatewayId}`,
        from: `${accountId}`,
        msg: {
          action: 'add',
          resource: `ch/${deviceId}/${resourceMaxBr[0]}`,
          properties: bitrateProps,
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
    const props = [
      ...(deviceInfos.properties['max-br']
        ? deviceInfos.properties['max-br']
        : []),
      ...[bitrateProps],
    ];
    resetProps();
    setConfirmRestartRequiredModal(false);
  };

  const getConfirmation = () => {
    setModalType(2);
    setNetworkContinueModal(false);
    setConfirmRestartRequiredModal(true);
  };

  const preUpdateKBPSRequest = (status, br, index) => {
    const newList = JSON.parse(JSON.stringify(allBrs));
    newList[index]['mbr-enable'] = status['mbr-enable'];
    setAllBrs(newList);

    const props = {
      'mbr-index': br?.['mbr-index'],
      'mbr-enable': status?.['mbr-enable'],
      'mbr-kbps': br?.['mbr-kbps'],
      'mbr-day': br?.['mbr-day'],
      'mbr-st': br?.['mbr-st'],
      'mbr-et': br?.['mbr-et'],
    };
    updateMaxBRRequest(props, newList);
  };

  const fetchLicenses = async () => {
    let licensesLoadSuccess = await setLicenses(`/user/orgs/${orgId}/licenses`);

    if (licensesLoadSuccess?.status === 'success') {
      await new Promise((resolve, reject) => {
        const custOrgLicenses = getLicenses();

        const deviceLicenses = [
          ...custOrgLicenses.filter(
            (license) =>
              license?.deviceId === deviceId &&
              license?.licenseStatus !== LicenseStatuses.EXPIRED,
          ),
        ];
        const availableLicenses = [...custOrgLicenses];
        if (deviceLicenses.length > 1) {
          setDeviceLicenses([
            ...deviceLicenses.filter(
              (deviceLicense) =>
                deviceLicense?.offeringType !== OfferingTypeEnum.ONBOARDING,
            ),
          ]);
        }
        if (deviceLicenses.length > 0) {
          setAvailableLicenses(true);
        } else {
          setAvailableLicenses(false);
        }
        resolve();
      });
    } else {
      throw new Error(
        'ERROR: there was problem retrieving the list of licenses for the organization',
      );
    }
  };

  useEffect(() => {
    fetchLicenses();
  }, []);

  useEffect(() => {
    // Fetch settings from localStorage
    const wdrSettings = JSON.parse(localStorage.getItem('WDRSettings'));
    const irSettings = JSON.parse(localStorage.getItem('IRSettings'));

    setIsWdrVisible(wdrSettings !== false);
    setIsIrVisible(irSettings !== false);
  }, []);

  // Custom debounce using useRef since lodash debounce isn't compatible. Do not remove.
  const debounce = (func, delay) => {
    return (...args) => {
      if (timeOutRef.current) {
        clearTimeout(timeOutRef.current);
      }
      timeOutRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const renderRegionOfInterest = () => {
    return (
      <div className="region-holder">
        <div className="region-text">{constants.DEVICE_REGION_OF_INTEREST}</div>
        <div>
          <HiOutlinePencil
            onClick={() => {
              dispatch(setAllMqttDataFromResponse(null));
              setShowBackLightArea(true);
            }}
            size={20}
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--primary_40',
            )}
            className="cur-pointer"
          />
        </div>
      </div>
    );
  };

  const handleBackLightChanges = (tab) => {
    setBackLightSelectedTab(tab);
  };

  return (
    <div
      className={`text-start device-settings-block ${
        curDeviceStatus !== constants.DEVICES_RETURN_ONLINE_STATUS
          ? ' editable-off-offline'
          : ''
      }`}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          deviceResolution: !availableLicenses
            ? ''
            : deviceInfos?.properties?.['mp-resolution']
              ? deviceInfos?.properties?.['mp-resolution']
              : '',
          flip:
            deviceInfos?.properties?.['flip-enable'] ===
            constants.PROPERTY_STATUS_TRUE,
          mirror:
            deviceInfos?.properties?.['mirror-enable'] ===
            constants.PROPERTY_STATUS_TRUE,
          deviceDegrees: deviceInfos?.properties?.['rotation-angle']
            ? deviceInfos?.properties?.['rotation-angle']
            : '',
          wisestream:
            deviceInfos?.properties?.['wisestream-enable'] ===
            constants.PROPERTY_STATUS_TRUE,
          dynamicgov:
            deviceInfos?.properties?.['dynamic-gov-enable'] ===
            constants.PROPERTY_STATUS_TRUE,
          dynamicgovlength: Utils.getPropertyValueIfExist(
            deviceInfos?.properties?.['dynamic-gov-length'],
            false,
          ),
          dynamicfps:
            deviceInfos?.properties?.['dynamic-fps-enable'] ===
            constants.PROPERTY_STATUS_TRUE,
          minimumfps: Utils.getPropertyValueIfExist(
            deviceInfos?.properties?.['minimum-dynamic-fps'],
            false,
          ),
          brightness: Utils.getPropertyValueIfExist(
            deviceInfos?.properties?.['brightness-level'],
            false,
          ),
          bitRate: deviceInfos?.properties?.['bit-rate'],
          frameRate: deviceInfos?.properties?.['frame-rate'],

          sharpness: Utils.getPropertyValueIfExist(
            deviceInfos?.properties?.['sharpness-level'],
            false,
          ),
          contrast: Utils.getPropertyValueIfExist(
            deviceInfos?.properties?.['contrast-level'],
            false,
          ),
          autoirmode:
            deviceInfos?.properties?.['ir-mode-enable'] ===
            constants.PROPERTY_STATUS_TRUE,
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          setVariant('danger');
          setUserMsg('');
          setSubmitting(false);
          saveDeviceSettingsCallback(false);
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <Form className="form" onSubmit={handleSubmit}>
            <div>
              {curDeviceStatus !== constants.DEVICES_RETURN_ONLINE_STATUS && (
                <div className="page-offline-message">
                  <div className="left-section">
                    <HiOutlineExclamationCircle
                      size={20}
                      stroke={getComputedStyle(
                        document.documentElement,
                      ).getPropertyValue('--error_24')}
                    />
                  </div>
                  <div className="right-section">
                    <div className="title-heading">
                      {constants.UNABLE_TO_SAVE_DEVICE_SETTINGS_HEADING}
                    </div>
                    <div className="sub-title-heading">
                      {constants.UNABLE_TO_SAVE_DEVICE_SETTINGS_MESSAGE}
                    </div>
                  </div>
                </div>
              )}

              <Row>
                <Col className="video-qty-top vdo-text-algn">
                  <div className="tab-title">
                    <label>{constants.VIDEO_QUALITY}</label>
                  </div>
                  {getCustomerOrgPolicies()?.view_metrics && (
                    <div
                      className="view-reports-text mt30"
                      onClick={() => {
                        Utils.vmsLogger().log('orgInfo', orgInfo);
                        navigate(
                          `/reports.html?deviceId=${deviceId}&deviceType=${'1'}&parentId=${
                            deviceInfos.parentId
                          }`,
                        );
                      }}
                    >
                      {constants.VIEW_REPORTS}
                    </div>
                  )}
                </Col>
              </Row>
            </div>
            {/* </div> */}
            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'mp-resolution',
              capVideoProfile,
            ) && (
              <Row>
                <Col className="display-g">
                  <div className="drop-holder">
                    <div>
                      <SelectField
                        name="deviceResolution"
                        options={deviceResolution}
                        label=""
                        prefixString={`${constants.RESOLUTION} : `}
                        defaultOptionLabel={
                          constants.DEVICES_TAB_VIDEO_SETTINGS_RESOLUTION_PLACEHOLDER
                        }
                        onChange={(evebr) => {
                          let selectedVal = evebr?.target?.value;
                          setFieldValue('deviceResolution', selectedVal);
                          const property = {
                            'mp-resolution': selectedVal.toString(),
                          };
                          updateStore(property);
                          publishDeviceSetting(
                            property,
                            resourceList[2],
                            publishPayload,
                          );
                        }}
                        placeholderOptionClass="d-none"
                        disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                        disabled={!canChangeSettings || !availableLicenses}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'frame-rate',
              capVideoProfile,
            ) && (
              <Row className="recording-video-codec ml0">
                <Col className="p0-l">
                  <div>
                    {Utils.getPropertyShowStatus(
                      curDeviceStatus,
                      'frame-rate',
                      capVideoProfile,
                    ) && (
                      <Row className="settings-block">
                        <Col className="p0 range-pad">
                          <InputSlider
                            classes={'p-5-all'}
                            label={constants.FRAME_RATE}
                            maxValue={
                              deviceFPS
                                ? deviceFPS.data.maxValue
                                : Utils.getPropertyValueIfExist(
                                    capVideoProfile?.['frame-rate']?.max,
                                    true,
                                  )
                            }
                            minValue={Utils.getPropertyValueIfExist(
                              capVideoProfile?.['frame-rate']?.min,
                              false,
                            )}
                            fieldName="FrameRate"
                            slideClick={true}
                            currentValue={parseInt(values.frameRate)}
                            changeHandler={debounce((value) => {
                              setFieldValue('frameRate', value);
                              const property = {
                                'frame-rate': value.toString(),
                              };
                              updateStore(property);

                              publishDeviceSetting(
                                property,
                                resourceList[2],
                                publishPayload,
                              );
                            }, 500)}
                            disabledClass={
                              !canChangeSettings ? 'cursor-auto' : ''
                            }
                            disabled={!canChangeSettings || !availableLicenses}
                          />
                        </Col>
                      </Row>
                    )}
                  </div>
                </Col>
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'bit-rate',
              capVideoProfile,
            ) && (
              <Row className="recording-video-codec ml0">
                <Col>
                  {Utils.getPropertyShowStatus(
                    curDeviceStatus,
                    'bit-rate',
                    capVideoProfile,
                  ) && (
                    <div className="drop-holder">
                      {/* <div className="title-vdo">
                        {constants.RECORDING_BITRATE}
                      </div>
                      <div className="title-vdo-2">
                        {constants.DEFAULT_BITRATE}
                      </div> */}
                      <div>
                        <Row className="settings-block">
                          <Col className="p0 range-pad">
                            <InputSlider
                              classes={'p-5-all-2'}
                              unitType={true}
                              label={constants.RECORDING_BITRATE}
                              maxValue={
                                // deviceInfos?.properties?.['max-bit-rate']
                                //   ? parseInt(
                                //       deviceInfos?.properties?.['max-bit-rate'],
                                //     )
                                //   :
                                deviceBitrate
                                  ? deviceBitrate.data.maxValue
                                  : Utils.getPropertyValueIfExist(
                                      capVideoProfile?.['bit-rate']?.max,
                                      true,
                                    )
                              }
                              minValue={Utils.getPropertyValueIfExist(
                                capVideoProfile?.['bit-rate']?.min,
                                false,
                              )}
                              fieldName="StreamingBitRate"
                              slideClick={true}
                              currentValue={parseInt(values.bitRate)}
                              changeHandler={debounce((value) => {
                                setFieldValue('bitRate', value);
                                const property = {
                                  'bit-rate': value.toString(),
                                };
                                updateStore(property);
                                publishDeviceSetting(
                                  property,
                                  resourceList[2],
                                  publishPayload,
                                );
                              }, 500)}
                              disabledClass={
                                !canChangeSettings ? 'cursor-auto' : ''
                              }
                              disabled={
                                !canChangeSettings || !availableLicenses
                              }
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                  {showBitRateScheduleSection && (
                    <>
                      <div className="bitrate-hint">
                        {constants.BITRATE_HINT}
                      </div>
                      <Row className="settings-block">
                        <Col>
                          {allBrs?.map((br, index) => (
                            <div className="toggle-field-wrap mb-2">
                              <ToggleInput
                                curDeviceStatus={curDeviceStatus}
                                label={`${br['mbr-kbps']} kbps`}
                                name="kbps"
                                value={br['mbr-enable']}
                                classes={'kbps p-0'}
                                changeHandler={() => {
                                  const status = {
                                    'mbr-enable': !br['mbr-enable'],
                                  };
                                  setBitrateToggleStatus(status);
                                  preUpdateKBPSRequest(status, br, index);
                                }}
                                labelClass={'kbps'}
                                disabledClass={
                                  !canChangeSettings ? 'cursor-auto' : ''
                                }
                                disabled={
                                  !canChangeSettings || !availableLicenses
                                }
                              />
                              <div className="d-flex flex-row justify-content-between align-items-center">
                                <div className="d-flex flex-column">
                                  <div className="d-flex">
                                    <div className="label-value">
                                      {getSelectedDays(br)}
                                    </div>
                                  </div>
                                  <div>
                                    {timeConversion(br['mbr-st'])} -{' '}
                                    {timeConversion(br['mbr-et'])} {timezone}
                                  </div>
                                </div>
                                <div>
                                  <MdOutlineEdit
                                    size={20}
                                    onClick={() => {
                                      if (
                                        !canChangeSettings ||
                                        !availableLicenses
                                      ) {
                                        return;
                                      }
                                      onEditBr(br);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </Col>
                      </Row>
                      {allBrs.length < 3 ? (
                        <Row className="settings-block ">
                          <Col>
                            <div
                              className={`setting-info-network ${
                                !canChangeSettings
                                  ? ' editable-off-offline'
                                  : ''
                              }`}
                              onClick={() => {
                                if (!availableLicenses) {
                                  return;
                                }
                                setNetworkContinueModal(true);
                              }}
                            >
                              <div className="setting-plus-icon drag-title">
                                <img src={Plusred} alt="plusicon icon" />
                              </div>
                              {constants.SET_A_SCHEDULE_FOR_RECORDING_BITRATE}
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'encoding-type',
              capVideoProfile,
            ) &&
              capVideoProfile?.['encoding-type']?.length > 0 && (
                <div className="recording-video-codec">
                  <div className="label-recording">
                    {constants.DEVICE_VIDEO_CODEC}
                  </div>
                  {recordCodecList.length > 0 &&
                    recordCodecList.map((record) => {
                      return (
                        <div
                          key={record.value}
                          className={`${
                            curDeviceStatus !==
                              constants.DEVICES_RETURN_ONLINE_STATUS &&
                            record.value
                              ? 'codec-selection-offline'
                              : 'codec-selection'
                          }`}
                        >
                          <div className="label-codec">{record.label}</div>
                          <input
                            type="radio"
                            name="codecId"
                            value={record?.value}
                            checked={recordingVideoCodecValue === record.value}
                            onClick={(event) => {
                              if (recordingVideoCodecValue === record?.value) {
                                return;
                              }
                              setModalType(1);
                              setRecordingVideoCodecValue(record?.value);
                              setConfirmRestartRequiredModal(true);
                            }}
                          />
                        </div>
                      );
                    })}
                </div>
              )}
            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'flip-enable',
              capFlipRotate,
            ) && (
              <Row className="settings-block">
                <Col>
                  <ToggleInput
                    curDeviceStatus={curDeviceStatus}
                    label={constants.DEVICES_TAB_VIDEO_SETTINGS_FLIP_LABEL}
                    name="flip"
                    value={values.flip}
                    changeHandler={() => {
                      const property = getCheckPropertyStatus(
                        !values.flip,
                        'flip-enable',
                      );
                      setPendingValue(!values.flip);
                      setFieldName('flip');
                      setPayloadProperty(property);
                      setShowConfirmationModal(true);
                    }}
                    disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                    disabled={!canChangeSettings}
                  />
                </Col>
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'mirror-enable',
              capFlipRotate,
            ) && (
              <Row className="settings-block">
                <Col>
                  <ToggleInput
                    curDeviceStatus={curDeviceStatus}
                    label={constants.DEVICES_TAB_VIDEO_SETTINGS_MIRROR_LABEL}
                    name="mirror"
                    value={values.mirror}
                    changeHandler={() => {
                      const property = getCheckPropertyStatus(
                        !values.mirror,
                        'mirror-enable',
                      );
                      setPendingValue(!values.mirror);
                      setFieldName('mirror');
                      setPayloadProperty(property);
                      setShowConfirmationModal(true);
                    }}
                    disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                    disabled={!canChangeSettings}
                  />
                </Col>
              </Row>
            )}

            {/* Dewapring options */}
            {capDewarpAngle && capDewarpAngle?.['mount-angle']?.length > 0 && (
              <div className="recording-video-codec">
                <div className="label-recording">
                  {constants.DEVICE_VIDEO_SETTING_DEWARP}
                </div>
                {dewarpAngleList?.length > 0 &&
                  dewarpAngleList?.map((angle) => {
                    return (
                      <div
                        key={angle.value}
                        className={`${
                          curDeviceStatus !==
                            constants.DEVICES_RETURN_ONLINE_STATUS &&
                          angle.value
                            ? 'codec-selection-offline'
                            : 'codec-selection'
                        }`}
                      >
                        <div className="label-codec">{angle.label}</div>
                        <input
                          type="radio"
                          name="dewarpData"
                          value={angle?.value}
                          checked={dewarpAngleValue === angle.value}
                          onClick={(event) => {
                            if (dewarpAngleValue === angle?.value) {
                              return;
                            }
                            setShowDeviceRestartModal(true);
                            setDewarpAngleNewValue(angle?.value);
                          }}
                        />
                      </div>
                    );
                  })}
              </div>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'rotation-angle',
              capFlipRotate,
            ) && (
              <Row className="settings-block">
                <Col>
                  <SelectField
                    name="deviceDegrees"
                    options={deviceDegreeRotation}
                    label=""
                    defaultOptionLabel={
                      constants.DEVICES_TAB_VIDEO_SETTINGS_DEGREES_PLACEHOLDER
                    }
                    value={values.deviceDegrees}
                    onChange={(evebr) => {
                      let selectedVal = evebr?.target?.value;
                      const property = { 'rotation-angle': selectedVal };
                      setPendingValue(selectedVal);
                      setFieldName('deviceDegrees');
                      setPayloadProperty(property);
                      setShowConfirmationModal(true);
                    }}
                    placeholderOptionClass="d-none"
                    disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                    disabled={!canChangeSettings}
                  />
                </Col>
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'wisestream-enable',
              capWisestream,
            ) && (
              <Row className="settings-block">
                <Col>
                  <ToggleInput
                    curDeviceStatus={curDeviceStatus}
                    label={
                      constants.DEVICES_TAB_VIDEO_SETTINGS_WISE_STREAM_LABEL
                    }
                    name="wisestream"
                    value={values.wisestream}
                    changeHandler={() => {
                      setFieldValue('wisestream', !values.wisestream);
                      const status = getCheckPropertyStatus(
                        !values.wisestream,
                        'wisestream-enable',
                      );
                      updateStore(status);
                      publishDeviceSetting(
                        status,
                        resourceList[3],
                        publishPayload,
                      );
                    }}
                    disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                    disabled={!canChangeSettings}
                  />
                </Col>
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'dynamic-fps-enable',
              capVideoProfile,
            ) && (
              <Row
                className={`settings-block ${
                  values.dynamicfps ? 'grouped-settings-block' : ''
                }`}
              >
                <Col md={12}>
                  <ToggleInput
                    curDeviceStatus={curDeviceStatus}
                    label={
                      constants.DEVICES_TAB_VIDEO_SETTINGS_DYNAMIC_FPS_LABEL
                    }
                    name="dynamicfps"
                    value={values.dynamicfps}
                    changeHandler={() => {
                      setFieldValue('dynamicfps', !values.dynamicfps);
                      const status = getCheckPropertyStatus(
                        !values.dynamicfps,
                        'dynamic-fps-enable',
                      );
                      updateStore(status);
                      publishDeviceSetting(
                        status,
                        resourceList[2],
                        publishPayload,
                      );
                    }}
                    disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                    disabled={!canChangeSettings}
                  />
                </Col>
                {values.dynamicfps && (
                  <Col md={12}>
                    <InputSlider
                      label={''}
                      fieldName="minimumfps"
                      maxValue={
                        selectedFrameRate !== null
                          ? parseInt(selectedFrameRate)
                          : parseInt(values.frameRate)
                      }
                      minValue={getDynamicFPSMinValue(values)}
                      currentValue={
                        selectedFrameRate !== null
                          ? Math.floor(selectedFrameRate / 2)
                          : values.minimumfps
                      }
                      slideClick={true}
                      changeHandler={debounce((value) => {
                        setSelectedFrameRate(null);
                        setFieldValue('minimumfps', value);
                        const property = {
                          'minimum-dynamic-fps': value.toString(),
                        };
                        updateStore(property);
                        publishDeviceSetting(
                          property,
                          resourceList[2],
                          publishPayload,
                        );
                      }, 500)}
                      disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                      disabled={!canChangeSettings}
                    />
                  </Col>
                )}
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'brightness-level',
              capEnhacement,
            ) && (
              <Row className="settings-block">
                <Col>
                  <InputSlider
                    label={
                      constants.DEVICES_TAB_VIDEO_SETTINGS_BRIGHTNESS_LABEL
                    }
                    maxValue={Utils.getPropertyValueIfExist(
                      capEnhacement?.['brightness-level']?.max,
                      true,
                    )}
                    minValue={Utils.getPropertyValueIfExist(
                      capEnhacement?.['brightness-level']?.min,
                      false,
                    )}
                    fieldName="brightness"
                    currentValue={values.brightness}
                    changeHandler={(value) => {
                      setFieldValue('brightness', value);
                      const property = {
                        'brightness-level': value.toString(),
                      };
                      updateStore(property);
                      publishDeviceSetting(
                        property,
                        resourceList[5],
                        publishPayload,
                      );
                    }}
                    disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                    disabled={!canChangeSettings}
                  />
                </Col>
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'sharpness-level',
              capEnhacement,
            ) && (
              <Row className="settings-block">
                <Col>
                  <InputSlider
                    label={constants.DEVICES_TAB_VIDEO_SETTINGS_SHARPNESS_LABEL}
                    fieldName="sharpness"
                    maxValue={Utils.getPropertyValueIfExist(
                      capEnhacement?.['sharpness-level']?.max,
                      true,
                    )}
                    minValue={Utils.getPropertyValueIfExist(
                      capEnhacement?.['sharpness-level']?.min,
                      false,
                    )}
                    currentValue={values.sharpness}
                    changeHandler={(value) => {
                      setFieldValue('sharpness', value);
                      const property = {
                        'sharpness-level': value.toString(),
                      };
                      updateStore(property);
                      publishDeviceSetting(
                        property,
                        resourceList[5],
                        publishPayload,
                      );
                    }}
                    disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                    disabled={!canChangeSettings}
                  />
                </Col>
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'contrast-level',
              capEnhacement,
            ) && (
              <Row className="settings-block">
                <Col>
                  <InputSlider
                    label={constants.DEVICES_TAB_VIDEO_SETTINGS_CONTRAST_LABEL}
                    fieldName="contrast"
                    maxValue={Utils.getPropertyValueIfExist(
                      capEnhacement?.['contrast-level']?.max,
                      true,
                    )}
                    minValue={Utils.getPropertyValueIfExist(
                      capEnhacement?.['contrast-level']?.min,
                      false,
                    )}
                    currentValue={values.contrast}
                    changeHandler={(value) => {
                      setFieldValue('contrast', value);
                      const property = {
                        'contrast-level': value.toString(),
                      };
                      updateStore(property);
                      publishDeviceSetting(
                        property,
                        resourceList[5],
                        publishPayload,
                      );
                    }}
                    disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                    disabled={!canChangeSettings}
                  />
                </Col>
              </Row>
            )}

            {isIrVisible &&
              Utils.getPropertyShowStatus(
                curDeviceStatus,
                'ir-mode-enable',
                capIrMode,
              ) && (
                <Row className="settings-block">
                  <Col>
                    <ToggleInput
                      curDeviceStatus={curDeviceStatus}
                      label={
                        constants.DEVICES_TAB_VIDEO_SETTINGS_AUTO_IR_MODE_LABEL
                      }
                      name="autoirmode"
                      value={values.autoirmode}
                      changeHandler={() => {
                        setFieldValue('autoirmode', !values.autoirmode);
                        const status = getCheckPropertyStatus(
                          !values.autoirmode,
                          'ir-mode-enable',
                        );
                        updateStore(status);
                        publishDeviceSetting(
                          status,
                          resourceList[4],
                          publishPayload,
                        );
                      }}
                      disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                      disabled={!canChangeSettings}
                    />
                  </Col>
                </Row>
              )}

            {/* BACKLIGHT SETTING  */}
            {Utils.getPropertyShowStatus(
              'curDeviceStatus',
              'backlight-mode',
              capBackLight,
            ) &&
              backLightModes && (
                <Row className="settings-block backlight-setting backlight-holder">
                  <Col>
                    <div className="range-field-wrap">
                      <label className="range-field-label-slider">
                        {constants.DEVICE_BACK_LIGHT_SETTINGS}
                      </label>

                      <div className="tab-holder">
                        {backLightModes &&
                          sortedModes.length > 0 &&
                          sortedModes.map((mode, index) => (
                            <div
                              key={index}
                              className={`tab-parent ${BackLightSelectedTab?.toLowerCase() === mode ? 'tab-active' : 'tab'}`}
                              onClick={() => {
                                if (!canChangeSettings) {
                                  return;
                                }
                                handleBackLightChanges(mode.toUpperCase());
                                const wdrJSON = {
                                  'backlight-mode':
                                    constants.DEVICE_BACK_LIGHT_WDR_LOWER,
                                  'wdr-level': wdrLevelbackLight,
                                  'wdr-control': wdrControlbackLight,
                                };
                                const blcJSON = {
                                  'backlight-mode':
                                    constants.DEVICE_BACK_LIGHT_BLC_LOWER,
                                  'blc-level': blcLevelbackLight,
                                  'blc-area-coordinates': blcAreaCoordinates,
                                };

                                const hlcJSON = {
                                  'backlight-mode':
                                    constants.DEVICE_BACK_LIGHT_HLC_LOWER,
                                  'hlc-level': hlcLevelBackLight,
                                  'hlc-area-coordinates': hlcAreaCoordinates,
                                  'hlc-dimming': hlcDimmingBackLight,
                                  'hlc-mask': hlcMaskBackLight,
                                  'hlc-mask-color': hlcMaskColorBackLight,
                                  'hlc-mask-tone': hlcMaskToneBackLight,
                                };
                                const offJSON = {
                                  'backlight-mode': 'off',
                                };
                                const property =
                                  mode === constants.DEVICE_BACK_LIGHT_WDR_LOWER
                                    ? wdrJSON
                                    : mode ===
                                        constants.DEVICE_BACK_LIGHT_BLC_LOWER
                                      ? blcJSON
                                      : mode ===
                                          constants.DEVICE_BACK_LIGHT_HLC_LOWER
                                        ? hlcJSON
                                        : offJSON;
                                updateStore(property);
                                publishDeviceSetting(
                                  property,
                                  resourceList[6],
                                  publishPayload,
                                );
                              }}
                            >
                              {mode === constants.DEVICE_BACK_LIGHT_OFF_LOWER
                                ? constants.DEVICE_BACK_LIGHT_OFF_CAMEL
                                : mode.toUpperCase()}{' '}
                            </div>
                          ))}
                      </div>
                      {BackLightSelectedTab ===
                        constants.DEVICE_BACK_LIGHT_WDR && (
                        <div className="wdr-parent">
                          {Utils.getPropertyShowStatus(
                            curDeviceStatus,
                            'wdr-level',
                            capBackLight,
                          ) && (
                            <>
                              <label className="range-field-label-slider">
                                {constants.DEVICE_BACK_LIGHT_VEVEL}
                              </label>
                              <div className="range-title">
                                <span className="ranger-upr-title">
                                  {constants.MIN_VALUE_LABEL}
                                </span>
                                <span className="ranger-upr-title">
                                  {constants.MEDIUM_VALUE_LABEL}
                                </span>
                                <span className="ranger-upr-title">
                                  {constants.MAX_VALUE_LABEL}
                                </span>
                              </div>
                              <ReactSlider
                                name="wdrLevelbackLight"
                                value={
                                  wdrLevelbackLight ===
                                  constants.DEVICE_BACK_lIGHT_WDR_LEVEL_LOW
                                    ? 0
                                    : wdrLevelbackLight ===
                                        constants.DEVICE_BACK_lIGHT_WDR_LEVEL_MEDIUM
                                      ? 1
                                      : 2
                                }
                                defaultValue={0}
                                min={0}
                                max={2}
                                className={`range-slider mb-4 ${!canChangeSettings ? 'disabled' : ''}`}
                                trackClassName="range-slider-track"
                                thumbClassName="range-slider-thumb"
                                onChange={(e) => {
                                  const isValue =
                                    e === 0
                                      ? 'low'
                                      : e === 1
                                        ? 'medium'
                                        : 'high';
                                  const property = {
                                    'backlight-mode':
                                      BackLightSelectedTab?.toLowerCase(),
                                    'wdr-level': isValue,
                                    'wdr-control': wdrControlbackLight,
                                  };
                                  setWdrLevelbackLight(isValue);
                                  updateStore(property);
                                  publishDeviceSetting(
                                    property,
                                    resourceList[6],
                                    publishPayload,
                                  );
                                }}
                                markClassName="range-slider-mark"
                                disabled={!canChangeSettings}
                              />
                            </>
                          )}
                          {Utils.getPropertyShowStatus(
                            curDeviceStatus,
                            'wdr-control',
                            capBackLight,
                          ) && (
                            <>
                              <label className="range-field-label-slider">
                                {constants.DEVICE_BACK_LIGHT_WDR_CONTROL}
                              </label>
                              <div className="drop-holder">
                                <div>
                                  <SelectField
                                    name="wdrControlbackLight"
                                    options={backLightWDRControl}
                                    label=""
                                    value={wdrControlbackLight}
                                    defaultOptionLabel={'Select WDR Control'}
                                    onChange={(evebr) => {
                                      let selectedVal = evebr?.target?.value;
                                      setWdrControlbackLight(selectedVal);
                                      const property = {
                                        'backlight-mode':
                                          BackLightSelectedTab?.toLowerCase(),
                                        'wdr-level': wdrLevelbackLight,
                                        'wdr-control': selectedVal,
                                      };
                                      updateStore(property);
                                      publishDeviceSetting(
                                        property,
                                        resourceList[6],
                                        publishPayload,
                                      );
                                    }}
                                    placeholderOptionClass="d-none"
                                    disabledClass={
                                      !canChangeSettings ? 'cursor-auto' : ''
                                    }
                                    disabled={!canChangeSettings}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                      {BackLightSelectedTab ===
                        constants.DEVICE_BACK_LIGHT_BLC && (
                        <div className="blc-parent">
                          {Utils.getPropertyShowStatus(
                            curDeviceStatus,
                            'blc-area-x-range',
                            capBackLight,
                          ) && renderRegionOfInterest()}
                          {Utils.getPropertyShowStatus(
                            curDeviceStatus,
                            'blc-level',
                            capBackLight,
                          ) && (
                            <div>
                              <InputSlider
                                label={`BLC Level (${capBackLight?.['blc-level']?.min}–${capBackLight?.['blc-level']?.max})`}
                                fieldName="blcLevelbackLight"
                                classes={'p-l-0 p-t-0 p-r-0'}
                                maxValue={Utils.getPropertyValueIfExist(
                                  capBackLight?.['blc-level']?.max,
                                  true,
                                )}
                                minValue={Utils.getPropertyValueIfExist(
                                  capBackLight?.['blc-level']?.min,
                                  false,
                                )}
                                currentValue={parseInt(blcLevelbackLight)}
                                isDetection={true}
                                changeHandler={debounce((value) => {
                                  setBlcLevelbackLight(value.toString());
                                  const property = {
                                    'backlight-mode':
                                      BackLightSelectedTab?.toLowerCase(),
                                    'blc-level': value.toString(),
                                    'blc-area-coordinates': blcAreaCoordinates,
                                  };
                                  updateStore(property);
                                  publishDeviceSetting(
                                    property,
                                    resourceList[6],
                                    publishPayload,
                                  );
                                }, 500)}
                                disabledClass={
                                  !canChangeSettings ? 'cursor-auto' : ''
                                }
                                disabled={!canChangeSettings}
                              />
                            </div>
                          )}
                        </div>
                      )}
                      {BackLightSelectedTab ===
                        constants.DEVICE_BACK_LIGHT_HLC && (
                        <div className="hlc-parent">
                          {Utils.getPropertyShowStatus(
                            curDeviceStatus,
                            'hlc-area-x-range',
                            capBackLight,
                          ) && renderRegionOfInterest()}
                          {Utils.getPropertyShowStatus(
                            curDeviceStatus,
                            'hlc-level',
                            capBackLight,
                          ) && (
                            <div>
                              <InputSlider
                                label={`HLC Level (${capBackLight?.['hlc-level']?.min}–${capBackLight?.['hlc-level']?.max})`}
                                classes={'p-l-0 p-t-0 p-r-0'}
                                fieldName="hlcLevel"
                                isDetection={true}
                                maxValue={Utils.getPropertyValueIfExist(
                                  capBackLight?.['hlc-level']?.max,
                                  true,
                                )}
                                minValue={Utils.getPropertyValueIfExist(
                                  capBackLight?.['hlc-level']?.min,
                                  false,
                                )}
                                currentValue={parseInt(hlcLevelBackLight)}
                                changeHandler={(value) => {
                                  setHlcLevelBackLight(value);
                                  const property = {
                                    'backlight-mode':
                                      BackLightSelectedTab?.toLowerCase(),
                                    'hlc-level': value.toString(),
                                    'hlc-area-coordinates': hlcAreaCoordinates,
                                    'hlc-dimming': hlcDimmingBackLight,
                                    'hlc-mask': hlcMaskBackLight,
                                    'hlc-mask-color': hlcMaskColorBackLight,
                                    'hlc-mask-tone': hlcMaskToneBackLight,
                                  };

                                  updateStore(property);
                                  publishDeviceSetting(
                                    property,
                                    resourceList[6],
                                    publishPayload,
                                  );
                                }}
                                disabledClass={
                                  !canChangeSettings ? 'cursor-auto' : ''
                                }
                                disabled={!canChangeSettings}
                              />
                            </div>
                          )}

                          <div className="drop-holder">
                            {Utils.getPropertyShowStatus(
                              curDeviceStatus,
                              'hlc-mask',
                              capBackLight,
                            ) && (
                              <>
                                <div className="p-b-20">
                                  <label className="range-field-label-slider">
                                    {constants.DEVICE_BACK_LIGHT_HLC_MASK}
                                  </label>
                                  <SelectField
                                    name="hlcMaskBackLight"
                                    options={backLightHLCMask}
                                    label=""
                                    defaultOptionLabel={'Select mask'}
                                    value={hlcMaskBackLight}
                                    onChange={(e) => {
                                      setHlcMaskBackLight(e.target.value);
                                      const property = {
                                        'backlight-mode':
                                          BackLightSelectedTab?.toLowerCase(),
                                        'hlc-level': hlcLevelBackLight,
                                        'hlc-area-coordinates':
                                          hlcAreaCoordinates,
                                        'hlc-dimming': hlcDimmingBackLight,
                                        'hlc-mask': e.target.value,
                                        'hlc-mask-color': hlcMaskColorBackLight,
                                        'hlc-mask-tone': hlcMaskToneBackLight,
                                      };

                                      updateStore(property);
                                      publishDeviceSetting(
                                        property,
                                        resourceList[6],
                                        publishPayload,
                                      );
                                    }}
                                    placeholderOptionClass="d-none"
                                    disabledClass={
                                      !canChangeSettings ? 'cursor-auto' : ''
                                    }
                                    disabled={!canChangeSettings}
                                  />
                                </div>
                              </>
                            )}

                            {Utils.getPropertyShowStatus(
                              curDeviceStatus,
                              'hlc-mask-color',
                              capBackLight,
                            ) &&
                              hlcMaskBackLight !== 'off' && (
                                <div>
                                  <label className="range-field-label-slider">
                                    {constants.DEVICE_BACK_LIGHT_HLC_MASK_COLOR}
                                  </label>
                                  <SelectField
                                    name="hlcMaskColorBackLight"
                                    options={backLightHLCMaskColor}
                                    label=""
                                    defaultOptionLabel={'Select mask'}
                                    value={hlcMaskColorBackLight}
                                    onChange={(e) => {
                                      setHlcMaskColorBackLight(e.target.value);
                                      const property = {
                                        'backlight-mode':
                                          BackLightSelectedTab?.toLowerCase(),
                                        'hlc-level': hlcLevelBackLight,
                                        'hlc-area-coordinates':
                                          hlcAreaCoordinates,
                                        'hlc-dimming': hlcDimmingBackLight,
                                        'hlc-mask': hlcMaskBackLight,
                                        'hlc-mask-color': e.target.value,
                                        'hlc-mask-tone': hlcMaskToneBackLight,
                                      };

                                      updateStore(property);
                                      publishDeviceSetting(
                                        property,
                                        resourceList[6],
                                        publishPayload,
                                      );
                                    }}
                                    placeholderOptionClass="d-none"
                                    disabledClass={
                                      !canChangeSettings ? 'cursor-auto' : ''
                                    }
                                    disabled={!canChangeSettings}
                                  />
                                </div>
                              )}

                            {Utils.getPropertyShowStatus(
                              curDeviceStatus,
                              'hlc-mask-tone',
                              capBackLight,
                            ) &&
                              hlcMaskBackLight !== 'off' && (
                                <div>
                                  <InputSlider
                                    label={`Mask Tone (${capBackLight?.['hlc-mask-tone']?.min}–${capBackLight?.['hlc-mask-tone']?.max})`}
                                    fieldName="hlcMaskTone"
                                    isDetection={true}
                                    classes={'p-l-0 p-r-0'}
                                    maxValue={Utils.getPropertyValueIfExist(
                                      capBackLight?.['hlc-mask-tone']?.max,
                                      true,
                                    )}
                                    minValue={Utils.getPropertyValueIfExist(
                                      capBackLight?.['hlc-mask-tone']?.min,
                                      false,
                                    )}
                                    currentValue={parseInt(
                                      hlcMaskToneBackLight,
                                    )}
                                    changeHandler={(value) => {
                                      setHlcMaskToneBackLight(value);
                                      const property = {
                                        'backlight-mode':
                                          BackLightSelectedTab?.toLowerCase(),
                                        'hlc-level': hlcLevelBackLight,
                                        'hlc-area-coordinates':
                                          hlcAreaCoordinates,
                                        'hlc-dimming': hlcDimmingBackLight,
                                        'hlc-mask': hlcMaskBackLight,
                                        'hlc-mask-color': hlcMaskColorBackLight,
                                        'hlc-mask-tone': value.toString(),
                                      };

                                      updateStore(property);
                                      publishDeviceSetting(
                                        property,
                                        resourceList[6],
                                        publishPayload,
                                      );
                                    }}
                                    disabledClass={
                                      !canChangeSettings ? 'cursor-auto' : ''
                                    }
                                    disabled={!canChangeSettings}
                                  />
                                </div>
                              )}
                            {Utils.getPropertyShowStatus(
                              curDeviceStatus,
                              'hlc-dimming',
                              capBackLight,
                            ) && (
                              <div>
                                <ToggleInput
                                  label={constants.DEVICE_BACK_LIGHT_DIMMING}
                                  name="hlcDimmingBackLight"
                                  classes={'p-l-0 p-r-0 p-t-0 p-b-0'}
                                  value={
                                    hlcDimmingBackLight === 'true'
                                      ? true
                                      : false
                                  }
                                  changeHandler={(e) => {
                                    const isVal =
                                      hlcDimmingBackLight === 'true'
                                        ? 'false'
                                        : 'true';
                                    setHlcDimmingBackLight(isVal);
                                    const property = {
                                      'backlight-mode':
                                        BackLightSelectedTab?.toLowerCase(),
                                      'hlc-level': hlcLevelBackLight,
                                      'hlc-area-coordinates':
                                        hlcAreaCoordinates,
                                      'hlc-dimming': isVal,
                                      'hlc-mask': hlcMaskBackLight,
                                      'hlc-mask-color': hlcMaskColorBackLight,
                                      'hlc-mask-tone': hlcMaskToneBackLight,
                                    };
                                    updateStore(property);
                                    publishDeviceSetting(
                                      property,
                                      resourceList[6],
                                      publishPayload,
                                    );
                                  }}
                                  disabledClass={
                                    !canChangeSettings ? 'cursor-auto' : ''
                                  }
                                  disabled={!canChangeSettings}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              )}

            {capVideoProfile?.['dynamic-gov-length'] &&
            Utils.getPropertyShowStatus(
              curDeviceStatus,
              'dynamic-gov-length',
              capVideoProfile,
            ) ? (
              <Row
                className={`settings-block ${
                  values.dynamicgov ? 'grouped-settings-block' : ''
                }`}
              >
                <Col md={12}>
                  <ToggleInput
                    curDeviceStatus={curDeviceStatus}
                    label={
                      constants.DEVICES_TAB_VIDEO_SETTINGS_DYNAMIC_GOV_LABEL
                    }
                    name="dynamicgov"
                    value={values.dynamicgov}
                    changeHandler={() => {
                      setFieldValue('dynamicgov', !values.dynamicgov);
                      const status = getCheckPropertyStatus(
                        !values.dynamicgov,
                        'dynamic-gov-enable',
                      );
                      updateStore(status);
                      publishDeviceSetting(
                        status,
                        resourceList[2],
                        publishPayload,
                      );
                    }}
                    disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                    disabled={!canChangeSettings}
                  />
                </Col>
                {values.dynamicgov && (
                  <Col md={12}>
                    <InputSlider
                      slideClick={true}
                      fieldName="dynamicgovlength"
                      maxValue={capVideoProfile?.['dynamic-gov-length']?.max}
                      minValue={capVideoProfile?.['dynamic-gov-length']?.min}
                      currentValue={values.dynamicgovlength}
                      changeHandler={debounce((value) => {
                        setFieldValue('dynamicgovlength', value.toString());
                        const property = {
                          'dynamic-gov-length': value.toString(),
                        };
                        updateStore(property);
                        publishDeviceSetting(
                          property,
                          resourceList[2],
                          publishPayload,
                        );
                      }, 500)}
                      disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                      disabled={!canChangeSettings}
                    />
                  </Col>
                )}
              </Row>
            ) : (
              Utils.getPropertyShowStatus(
                curDeviceStatus,
                'dynamic-gov-enable',
                capVideoProfile,
              ) && (
                <Row className="settings-block">
                  <Col>
                    <ToggleInput
                      curDeviceStatus={curDeviceStatus}
                      label={
                        constants.DEVICES_TAB_VIDEO_SETTINGS_DYNAMIC_GOV_LABEL
                      }
                      name="dynamicgov"
                      value={values.dynamicgov}
                      changeHandler={() => {
                        setFieldValue('dynamicgov', !values.dynamicgov);
                        const status = getCheckPropertyStatus(
                          !values.dynamicgov,
                          'dynamic-gov-enable',
                        );
                        updateStore(status);
                        publishDeviceSetting(
                          status,
                          resourceList[2],
                          publishPayload,
                        );
                      }}
                      disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                      disabled={!canChangeSettings}
                    />
                  </Col>
                </Row>
              )
            )}

            <SiteModal
              modalTitle={constants.DEVICES_TAB_CUSTOM_ANALYTICS_MODAL_TITLE}
              showModal={showConfirmationModal}
              hideModal={() => {
                setShowConfirmationModal(false);
              }}
              classes="device-settings-restart-required"
              analyticSetting={true}
              isCenteredTitle={true}
            >
              <TextBlock
                className="mb-4"
                fontWeight="400"
                fontSize="1rem"
                lineHeight="24px"
                color={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--greyscale_40')}
              >
                {
                  constants.DEVICES_TAB_VIDEO_SETTINGS_CUSTOM_ANALYTICS_MODAL_DESC_1
                }
              </TextBlock>
              {(fieldName === 'flip' || fieldName === 'deviceDegrees') && (
                <TextBlock
                  className="mb-4"
                  fontWeight="400"
                  fontSize="1rem"
                  lineHeight="24px"
                  color={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--greyscale_40')}
                >
                  {
                    constants.DEVICES_TAB_VIDEO_SETTINGS_CUSTOM_ANALYTICS_MODAL_DESC_2
                  }
                </TextBlock>
              )}
              <TextBlock
                className="mb-4"
                fontWeight="400"
                fontSize="1rem"
                lineHeight="24px"
                color={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--greyscale_40')}
              >
                {
                  constants.DEVICES_TAB_VIDEO_SETTINGS_CUSTOM_ANALYTICS_MODAL_DESC_3
                }
              </TextBlock>
              <PrimaryButton
                className="btn btn-primary mb-4"
                type="button"
                width="100%"
                height="56px"
                fontSize="1.125rem"
                lineHeight="24px"
                onClick={() => {
                  handleChange(setFieldValue);
                  setShowConfirmationModal(false);
                }}
              >
                {
                  constants.DEVICES_TAB_VIDEO_SETTINGS_MODAL_CONTINUE_BUTTON_TITLE
                }
              </PrimaryButton>
              <PrimaryButton
                className="btn btn-primary-outline"
                type="button"
                width="100%"
                borderWidth="1.5px"
                hoverBorderWidth="1.5px"
                hoverBorderColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--primary_40')}
                color={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--primary_40')}
                hoverColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--brand_white')}
                hoverBackgroundColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--primary_40')}
                backgroundColor="transparent"
                height="56px"
                fontSize="1.125rem"
                lineHeight="24px"
                onClick={() => {
                  setShowConfirmationModal(false);
                }}
              >
                {
                  constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CANCEL_BUTTON_TITLE
                }
              </PrimaryButton>
            </SiteModal>
          </Form>
        )}
      </Formik>
      {/* CONFIRM POPUP */}
      <SiteModal
        modalTitle={constants.DEVICES_TAB_VIDEOSETTING_RESTART_REQUIRED_TITLE}
        showModal={confirmRestartRequiredModal}
        hideModal={() => {
          // setIsEditMode(null);
          setConfirmRestartRequiredModal(false);
          setRecordingVideoCodecValue(
            deviceInfos?.properties?.['encoding-type'],
          );
        }}
        classes="device-settings-restart-required"
      >
        <TextBlock
          className="mb-3"
          fontWeight="400"
          fontSize="1rem"
          lineHeight="24px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--greyscale_56',
          )}
        >
          <div className="contentTitle">
            {constants.DEVICE_TAB_VIDEO_RESTART_TEXT}
          </div>
        </TextBlock>
        <PrimaryButton
          className="btn mb-4"
          type="button"
          width="100%"
          borderWidth="1.5px"
          backgroundColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--brand_primary')}
          height="56px"
          fontSize="1rem"
          lineHeight="24px"
          onClick={() => {
            if (recordingVideoCodecValue === EncodingTypes?.H264_TYPE) {
              dispatch(setOfferrecived(false));
            }
            if (modalType === 1) {
              const property = {
                'encoding-type': recordingVideoCodecValue,
              };
              updateStore(property);
              publishDeviceSetting(property, resourceList[2], publishPayload);
            }
            if (modalType === 2) {
              addMaxStrRequest();
            }
            if (modalType === 3) {
              preUpdateRequest();
            }
            setConfirmRestartRequiredModal(false);
          }}
        >
          {constants.DEVICES_CONFIRM_TEXT}
        </PrimaryButton>
        <Row className="settings-block">
          <Col>
            <PrimaryButton
              className="btn btn-primary-outline"
              type="button"
              width="100%"
              height="56px"
              fontSize="1.125rem"
              lineHeight="24px"
              backgroundColor="transparent"
              hoverBackgroundColor="transparent"
              borderColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--error_64')}
              hoverBorderColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--error_64')}
              borderWidth="1.5px"
              color={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--error_64')}
              hoverColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--error_64')}
              onClick={() => {
                setRecordingVideoCodecValue(
                  deviceInfos?.properties?.['encoding-type'],
                );
                setConfirmRestartRequiredModal(false);
              }}
            >
              {
                constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CANCEL_BUTTON_TITLE
              }
            </PrimaryButton>
          </Col>
        </Row>
      </SiteModal>

      <SiteModal
        modalTitle={constants.SET_SCHEDULE_FOR_RECORDING}
        showModal={NetworkContinueModal}
        hideModal={() => {
          setNetworkContinueModal(false);
          setIsEditMode(null);
          resetProps();
        }}
        classes="device-settings-restart-required streamingSetting streamingSetting-title"
      >
        <div className="alert-recording-close">
          {showMsg ? (
            <Alert
              variant={variant}
              onClose={() => setShowMsg(false)}
              dismissible
              className="alert-recording-bitrate"
            >
              <Row>
                <Col md={1} className="alert-icon-recording-bitrate">
                  <HiOutlineInformationCircle
                    size={15}
                    stroke={getComputedStyle(
                      document.documentElement,
                    ).getPropertyValue('--error_24')}
                  />
                </Col>
                <Col>
                  <p>{constants.DEVICES_TIME_OVERLAPPING_MESSAGE}</p>
                </Col>
              </Row>
            </Alert>
          ) : (
            <></>
          )}

          <div className="settings-block settings-block-range">
            <div class="max-recording-bitrate-label">
              {constants.MAXIMUM_RECORDING_BITRATE}
            </div>
            <div className="max-recording-bitrate-range">
              <div className="range-field-container">
                <InputSlider
                  classes={'p-5-all-2'}
                  unitType={true}
                  maxValue={max}
                  minValue={min}
                  isErrorMessage={false}
                  fieldName="StreamingBitRate"
                  currentValue={parseInt(kbpsValue)}
                  changeHandler={(value) => {
                    setkbpsValue(value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="week-header mb-0 p-0">
            <div className="max-recording-bitrate-label">
              {constants.DEVICES_TAB_NETWORK_MAX_STREAMING_BITRATE_MODAL_DAY}
            </div>
          </div>
          <div
            className={`select-day-container ${showMsg ? 'select-day-container-error' : ''}`}
          >
            {daysList?.map((days) => {
              return (
                <div
                  onClick={() => selectedDay(days)}
                  key={days.id}
                  className={`day-blocks ${days.isSelected ? 'selected' : ''}`}
                >
                  <div className="label-value">{days.dayPrefix}</div>
                </div>
              );
            })}
          </div>
          <div className="sm-lable selectd-day"> {commaSeparatedDays()}</div>
          <div className="week-header choose-time p-0 mb-2">
            <div className="choose-day site-text-block d-block mb-0">
              {constants.TIME_RANGE}
              <br />
              <p className="sm-lable mb-0">
                {constants.CHOOSE_TIME_RANGE_SUB_CONTENT} {timezone}
              </p>
            </div>
          </div>
          <div className="time-container">
            <div className={`timer-start-end ${showMsg ? 'error' : ''}`}>
              {renderTimePicker(startTimeMoment, true)}
            </div>
            <div className="seprator">-</div>
            <div className={`timer-start-end ${showMsg ? 'error' : ''}`}>
              {renderTimePicker(endTimeMoment, false)}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end mt-4">
          {isEditMode !== null ? (
            <PrimaryButton
              className="btn btn-primary-outline"
              type="button"
              width="120px"
              height="44px"
              fontSize="14PX"
              lineHeight="24px"
              borderWidth="1.5px"
              backgroundColor="transparent"
              hoverBackgroundColor="transparent"
              borderColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--slider-red')}
              hoverBorderColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--slider-red')}
              color={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--slider-red')}
              hoverColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--slider-red')}
              onClick={() => deleteMaxStrRequest()}
            >
              {constants.DEVICES_TAB_NETWORK_MAXIMUM_DELETE}
            </PrimaryButton>
          ) : null}
          <PrimaryButton
            className="btn ms-2"
            width="120px"
            type="button"
            borderWidth="1.5px"
            backgroundColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--brand_primary')}
            height="44PX"
            fontSize="14PX"
            lineHeight="24px"
            disabled={!selectedIds.length || isOverlaping}
            onClick={() =>
              isEditMode !== null ? getUpdateConfirmation() : getConfirmation()
            }
          >
            {isEditMode !== null
              ? constants.DEVICES_TAB_NETWORK_MAXIMUM_UPDATE
              : constants.DEVICES_TAB_NETWORK_MAXIMUM_ADD}
          </PrimaryButton>
        </div>
      </SiteModal>
      <SiteModal
        modalTitle={constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_TITLE}
        showModal={showDeviceRestartModal}
        hideModal={() => {
          setShowDeviceRestartModal(false);
        }}
        classes="device-settings-restart-required"
      >
        <TextBlock
          className="mb-4"
          fontWeight="400"
          fontSize="1rem"
          lineHeight="24px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--greyscale_40',
          )}
        >
          {constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CONTENT}
        </TextBlock>
        <PrimaryButton
          className="btn btn-primary mb-4"
          type="button"
          width="100%"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          onClick={() => {
            const property = {
              'mount-angle': dewarpAngleNewValue,
            };
            publishDeviceSetting(
              property,
              resourceListFishEyeLens[1],
              publishPayload,
            );
            updateStore(property);
            setDewarpAngleValue(dewarpAngleNewValue);
            setShowDeviceRestartModal(false);
          }}
        >
          {
            constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CONFIRM_BUTTON_TITLE
          }
        </PrimaryButton>
        <PrimaryButton
          className="btn btn-primary-outline"
          type="button"
          width="100%"
          borderWidth="1.5px"
          hoverBorderWidth="1.5px"
          hoverBorderColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--primary_40')}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--primary_40',
          )}
          hoverColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--brand_white')}
          hoverBackgroundColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--primary_40')}
          backgroundColor="transparent"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          onClick={() => {
            setShowDeviceRestartModal(false);
          }}
        >
          {
            constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CANCEL_BUTTON_TITLE
          }
        </PrimaryButton>
      </SiteModal>
      <SiteModal
        modalTitle={constants.REGION_OF_INTEREST_POPUP_TITLE}
        showModal={showBackLightArea}
        hideModal={() => {
          setShowBackLightArea(false);
        }}
        classes="analytics-settings-activity-zone backlight-settings-activity-zone"
      >
        <BackLightArea
          accountId={accountId}
          refResolution={deviceInfos?.properties?.[
            'reference-resolution'
          ]?.split('x')}
          deviceId={deviceId}
          deviceInfos={deviceInfos}
          devicePublishId={devicePublishId}
          uuid={uuid}
          hideModelPopup={() => {
            setShowBackLightArea(false);
          }}
          selectedTab={BackLightSelectedTab}
          blcLevelbackLight={blcLevelbackLight}
          hlcLevelBackLight={hlcLevelBackLight}
          hlcMaskBackLight={hlcMaskBackLight}
          hlcMaskColorBackLight={hlcMaskColorBackLight}
          hlcMaskToneBackLight={hlcMaskToneBackLight}
          hlcDimmingBackLight={hlcDimmingBackLight}
          updateStore={updateStore}
        />
      </SiteModal>
    </div>
  );
};

export default DeviceSettingsBlock;
