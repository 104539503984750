import { useEffect, useLayoutEffect, useState } from 'react';
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { VscChevronDown, VscChevronUp } from 'react-icons/vsc';

const localConstants = {
  LICENSE_STATUS: 'LICENSE_STATUS',
};

const ListTable = ({
  colsDef = [],
  colSorting,
  rowData = [],
  statusMap,
  orgLicenses = false,
  cssClasses,
  updateOrgLicense,
  licensesListUpdated,
  loadMoreCount,
}) => {
  const [columns, setColumns] = useState([...colsDef]);
  const [rows, setRows] = useState([...rowData]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortField, setSortField] = useState(null);
  const minTooltipLength = 10; // Set your desired minimum length for tooltip

  useEffect(() => {
    if (licensesListUpdated?.colField) {
      setSortField(licensesListUpdated?.colField);
    }
    if (licensesListUpdated?.newSortOrder) {
      setSortOrder(licensesListUpdated?.newSortOrder);
    }
  }, [licensesListUpdated]);

  // Update rows when rowData or loadMoreCount changes
  useEffect(() => {
    setRows([...rowData]);
  }, [rowData, loadMoreCount]); // Add loadMoreCount to the dependency array

  const handleSort = (field, columnType) => {
    if (!field || (field === 'productDisplayName' && orgLicenses)) return;

    setSortField(field);
    const compare = (a, b) => {
      if (sortOrder === 'asc') {
        if (columnType === localConstants.LICENSE_STATUS) {
          if (a[field].props.children[1] < b[field].props.children[1]) {
            return -1;
          }
          if (a[field].props.children[1] > b[field].props.children[1]) {
            return 1;
          }
        } else {
          if (a[field] === undefined) {
            return 1;
          }
          if (b[field] === undefined) {
            return -1;
          }
          if (a[field] < b[field]) {
            return -1;
          }
          if (a[field] > b[field]) {
            return 1;
          }
        }
      } else {
        if (columnType === localConstants.LICENSE_STATUS) {
          if (a[field].props.children[1] > b[field].props.children[1]) {
            return -1;
          }
          if (a[field].props.children[1] < b[field].props.children[1]) {
            return 1;
          }
        } else {
          if (a[field] === undefined) {
            return 1;
          }
          if (b[field] === undefined) {
            return -1;
          }
          if (a[field] > b[field]) {
            return -1;
          }
          if (a[field] < b[field]) {
            return 1;
          }
        }
      }

      return 0;
    };

    if (Array.isArray(rows)) {
      rows.sort(compare);
    }

    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const renderHeader = (columns) => {
    const headerContent = (columns) => {
      if (!Array.isArray(columns)) {
        return <th></th>;
      }

      return columns.map((col) => {
        const thClass =
          col.field === 'licenseDaysRemaining' ? 'custom-width-column' : '';
        let thContent =
          colSorting === true ? (
            <th
              id={`header${col?.field}`}
              key={`header${col?.field}`}
              className={thClass}
            >
              <div
                key={`headerDiv${col?.field}`}
                className="header-sort"
                onClick={() => {
                  if (
                    col.field === 'licenseStatus' ||
                    col.field === 'activeDate' ||
                    col.field === 'expiryDate'
                  ) {
                    handleSort(col.field, localConstants.LICENSE_STATUS);
                    if (orgLicenses) {
                      updateOrgLicense(
                        col.field,
                        localConstants.LICENSE_STATUS,
                        sortOrder,
                      );
                    }
                  } else {
                    handleSort(col.field);
                    if (orgLicenses) {
                      updateOrgLicense(col.field, null, sortOrder);
                    }
                  }
                }}
              >
                <OverlayTrigger
                  key={`triggerHeader${col.field}`}
                  placement="top"
                  overlay={
                    <Tooltip key={`tooltipHeader${col.field}`}>
                      {col.displayName}
                    </Tooltip>
                  }
                >
                  <div key={`columnDiv${col?.field}`} className="column-name">
                    {col?.displayName}
                  </div>
                </OverlayTrigger>
                {orgLicenses ? (
                  sortField === col?.field &&
                  licensesListUpdated?.newSortOrder === 'asc' ? (
                    col?.field !== 'productDisplayName' && (
                      <VscChevronUp
                        key={`sortIcon${col?.field}`}
                        className="sort-icon"
                      />
                    )
                  ) : (
                    col?.field !== 'productDisplayName' && (
                      <VscChevronDown
                        key={`sortIcon${col?.field}`}
                        className="sort-icon"
                      />
                    )
                  )
                ) : sortField === col?.field && sortOrder === 'desc' ? (
                  <VscChevronUp
                    key={`sortIcon${col?.field}`}
                    className="sort-icon"
                  />
                ) : (
                  <VscChevronDown
                    key={`sortIcon${col?.field}`}
                    className="sort-icon"
                  />
                )}
              </div>
            </th>
          ) : (
            <th key={`header${col?.field}`}>
              <div key={`columnDiv${col?.field}`} className="column-name">
                {col?.displayName}
              </div>
            </th>
          );

        return thContent;
      });
    };

    return <tr className="table-columns">{headerContent(columns)}</tr>;
  };

  const rowContent = (row, rowIdx) => {
    let content = [];

    if (!Array.isArray(columns)) return <></>;

    columns.forEach((col, colIdx) => {
      let cellId = 'cell' + rowIdx + colIdx;
      let colContent = (
        <td id={cellId} key={cellId}>
          {row[col.field]?.length > minTooltipLength ? ( // Check if text length exceeds minTooltipLength
            <OverlayTrigger
              key={`triggerCell${rowIdx}${col.field}`}
              placement="top"
              overlay={
                <Tooltip key={`tooltipCell${rowIdx}${col.field}`}>
                  {row[col.field]}
                </Tooltip>
              }
            >
              <div
                key={`divCell${rowIdx}${col.field}`}
                className={orgLicenses ? 'license-row-value-style' : ''}
              >
                {row[col.field]}
              </div>
            </OverlayTrigger>
          ) : (
            <div
              key={`divCell${rowIdx}${col.field}`}
              className={orgLicenses ? 'license-row-value-style' : ''}
            >
              {row[col.field]}
            </div>
          )}
        </td>
      );

      content.push(colContent);
    });

    return content;
  };

  useEffect(() => {
    setColumns([...colsDef]);
  }, [colsDef]);

  // Update rows when rowData or loadMoreCount changes
  useEffect(() => {
    setRows([...rowData]);
  }, [rowData, loadMoreCount]); // Add loadMoreCount to the dependency array

  // When the browser window is resized, we rehydrate the columns and rows states
  // so that the table is re-rendered to determine the list of popovers to enable
  // dynamically.
  useLayoutEffect(() => {
    const rehydrate = () => {
      setColumns([...colsDef]);
      setRows([...rowData]);
    };
    window.addEventListener('resize', rehydrate);
    return () => window.removeEventListener('resize', rehydrate);
  }, [colsDef, rowData]);

  return (
    <Table className={cssClasses}>
      <thead>{renderHeader(columns)}</thead>
      <tbody>
        {Array.isArray(rows) &&
          rows.map((row, rowIndex) => (
            <tr key={`license${rowIndex}`}>{rowContent(row, rowIndex)}</tr>
          ))}
      </tbody>
    </Table>
  );
};

export default ListTable;
