import { Utils } from '../../helpers';
import * as mqttClient from '../connection/mqttConnection';
import { v4 as uuidv4 } from 'uuid';
import * as MP4Box from 'mp4box';
import Store from '../../store/Store';
import {
  setMultiLiveStreamLoader,
  setPlaybackBufferMeta,
} from '../../store/reducers/StreamingReducer';

// ====================
const nextVideoElements = [];
let dataChannels = {};
let peerConnections = {};
let sendCompleteDeviceIds = {};
let currentPlayStartTime = '';
let mp4BoxFile = null;
const ctsArray = [];
let virtualTimeInterval = null;
let nextAvailTime;
let noVideoDevices = [];
let allDevices = [];
let isAutoPlay = false;
let playbackPlayTime = '';

const generatePayload = (type, offerCandidate, data, subTopic) => {
  if (type === 'offer') {
    return {
      tid: new Date().getTime().toString(),
      to: data.gatewayId,
      from: data.accountId,
      msg: {
        // resource: `ch/${deviceId}/streaming/live`,
        resource: `ch/${data.deviceId}/playback`,
        // resource: `ch/${deviceId}/camera/streaming`,
        properties: {
          id: `${data.streamId}`,
          type: 'offer',
          sdp: offerCandidate,
        },
      },
      publish: subTopic,
    };
  }
  if (type === 'candidate') {
    return {
      tid: new Date().getTime().toString(),
      to: data.gatewayId,
      from: data.accountId,
      msg: {
        resource: `ch/${data.deviceId}/playback`,
        // resource: `ch/${deviceId}/camera/streaming`,
        properties: {
          id: `${data.streamId}`,
          type: 'candidate',
          ...JSON.parse(JSON.stringify(offerCandidate)),
        },
      },
      publish: subTopic,
    };
  }
};

// function ntpTimestampToUnixTimestamp(ntpTimestamp) {
//   /* global BigInt */
//   const bigIntNtpTimestamp = BigInt(ntpTimestamp);
//   const NTP_UNIX_EPOCH_DIFF = 2208988800n; // Seconds between 1900 and 1970
//   const TWO_TO_THE_32 = 2n ** 32n; // 2^32 to separate seconds and fractions

//   const seconds = bigIntNtpTimestamp >> 32n; // High 32 bits for seconds
//   const fractional = bigIntNtpTimestamp & (TWO_TO_THE_32 - 1n); // Low 32 bits for fractional part
//   const fractionalMilliseconds = Number((fractional * 1000n) / TWO_TO_THE_32);
//   const unixSeconds = Number(seconds - NTP_UNIX_EPOCH_DIFF);
//   const unixTimestamp = unixSeconds * 1000 + fractionalMilliseconds;

//   return unixTimestamp;
// }

const startConnection = async (deviceId) => {
  let peerConnection;
  const deviceName = allDevices?.find(
    (device) => device?.deviceId === deviceId,
  )?.deviceName;
  const allConnectionData = mqttClient?.getMqttClient(false, deviceId);
  // Utils.vmsLogger().log('playback start connection', allConnectionData);

  if (allConnectionData) {
    const { stun, turn } = allConnectionData?.serverDetails;
    const turnConfig = {
      iceServers: [
        {
          urls: `${stun.protocol}:${stun.host}:${stun.port}`,
        },
        {
          urls: `${turn.protocol}:${turn.host}:${turn.port}`,
          username: `${turn.userName}`,
          credential: `${turn.password}`,
        },
      ],
      // iceTransportPolicy: "all",
      // rtcpMuxPolicy: "require",
      // bundlePolicy: "balanced",
    };
    if (peerConnections?.[deviceId]) {
      Utils.vmsLogger().log('already exist peer connection');
      peerConnection = peerConnections[deviceId];
    } else {
      peerConnection = new RTCPeerConnection(turnConfig);
      peerConnections[deviceId] = peerConnection;
    }

    peerConnection.createDataChannel('test_datachannel', {
      protocol: 'protocol',
    });
    const clientMQTT = allConnectionData?.mqttclient;
    let pubTopic = `a/notify/${allConnectionData?.gatewayId}`;
    const subTopic = `d/notify/${allConnectionData?.accountId}/${allConnectionData?.sid}`;
    const candidateArray = [];

    const checkVideoStartTime = () => {
      const videoElement = document.getElementById(`playback-video${deviceId}`);
      if (videoElement && playbackPlayTime < videoElement.buffered.end(0)) {
        videoElement.currentTime = playbackPlayTime;
      }
    };

    clientMQTT.subscribe(subTopic);

    // send offer
    const offer = await peerConnection.createOffer();
    await peerConnection.setLocalDescription(offer);
    pubTopic = `a/notify/${allConnectionData?.gatewayId}`;
    mqttClient.sendWebRTCOffer(
      pubTopic,
      generatePayload('offer', offer.sdp, allConnectionData, subTopic),
      deviceId,
    );

    // local candidate
    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        pubTopic = `a/notify/${allConnectionData?.gatewayId}`;
        mqttClient.sendWebRTCCandidate(
          pubTopic,
          generatePayload(
            'candidate',
            event.candidate,
            allConnectionData,
            subTopic,
          ),
        );
      }
    };

    // peer connection state
    peerConnection.onconnectionstatechange = () => {
      // Utils.vmsLogger().log(
      //   'Multi PlayBack peer connection ',
      //   JSON.stringify(peerConnection.connectionState),
      //   deviceId,
      //   ' ',
      //   deviceName,
      //   Utils.getTimesinmili(),
      // );
    };

    peerConnection.ondatachannel = (event) => {
      let dataChannel;
      if (dataChannels?.[deviceId]) {
        dataChannel = dataChannels?.[deviceId];
      } else {
        dataChannel = event.channel;
        dataChannels[deviceId] = event.channel;
      }
      const type = dataChannel.label;

      function appendBufferToSourceBuffer(targetVideoElement) {
        const { sourceBuffers, segmentQueues } = targetVideoElement;
        const sourceBuffer = sourceBuffers[type];
        const segmentQueue = segmentQueues[type];

        if (!sourceBuffer.updating && segmentQueue.length > 0) {
          sourceBuffer.appendBuffer(segmentQueue.shift());
        }
      }

      dataChannel.onopen = () => {
        Utils.vmsLogger().log(
          'Multi PlayBack data channel open ',
          deviceId,
          deviceName,
          Utils.getTimesinmili(),
        );
        sendPlayCommand(currentPlayStartTime, 'PLAY', deviceId);
      };

      dataChannel.onclose = () => {
        Utils.vmsLogger().log('=== data channel closed');
        cleanupResources(deviceId);
      };

      dataChannel.onerror = (error) => {
        Utils.vmsLogger().log('==== data channel error: ', error);
      };

      dataChannel.onmessage = async (event) => {
        const videoElement = document.getElementById(
          `playback-video${deviceId}`,
        );
        try {
          if (typeof event.data !== 'string') {
            const buffer = new Uint8Array(event.data);
            // Utils.vmsLogger().log(
            //   '=== data channel received message size: ',
            //   buffer.byteLength,
            // );
            if (checkInitSegment(buffer)) {
              const { sourceBuffers, segmentQueues, mediaSource } =
                videoElement;
              if (!segmentQueues[type]) segmentQueues[type] = [];
              const segmentQueue = segmentQueues[type];

              mp4BoxFile = await loadMp4BoxFile(buffer);
              window.mp4BoxFile = mp4BoxFile;
              const mimeCodec = mp4BoxFile.getInfo().mime;
              Utils.vmsLogger().log('=== mime codec:', mimeCodec);
              if (!MediaSource.isTypeSupported(mimeCodec)) {
                Utils.vmsLogger().log(
                  'Mime codec not supported: codec=',
                  mimeCodec,
                );
                return;
              }
              if (mediaSource.sourceBuffers.length) {
                Utils.vmsLogger().log(
                  "=== mutliple resolution detected, but don't add new SourceBuffer",
                );
                segmentQueue.push(buffer);
                return;
              }
              sourceBuffers[type] = mediaSource.addSourceBuffer(mimeCodec);
              const sourceBuffer = sourceBuffers[type];
              sourceBuffer.addEventListener('updateend', (event) => {
                // Utils.vmsLogger().log('=== source buffer update end');
                appendBufferToSourceBuffer(videoElement);
                const targetSourceBuffer = event.target;
                if (
                  videoElement.currentTime === 0 &&
                  targetSourceBuffer.buffered.length > 0
                ) {
                  checkVideoStartTime();
                }
              });
              sourceBuffer.addEventListener('error', (error) => {
                Utils.vmsLogger().log(
                  '=== source buffer error: ',
                  error,
                  videoElement?.error,
                );
              });
              segmentQueue.push(buffer);
            } else {
              if (videoElement) {
                const { sourceBuffers, segmentQueues } = videoElement;
                const segmentQueue = segmentQueues[type];

                if (!sourceBuffers[type]) {
                  Utils.vmsLogger().log(
                    '=== Source buffer not initialized, data received before the init segment is discarded.',
                  );
                  return;
                }
                segmentQueue.push(buffer);
                const mp4Buffer = buffer.buffer;
                mp4Buffer.fileStart = mp4BoxFile.lastFileStart;
                mp4BoxFile.lastFileStart = mp4BoxFile.appendBuffer(mp4Buffer);
                appendBufferToSourceBuffer(videoElement);
              }
            }
          } else {
            const receivedData = JSON.parse(event.data);
            const type = receivedData?.msg?.properties?.type;
            if (type === 'NO_VIDEO') {
              noVideoDevices.push(receivedData?.msg?.properties?.dev_id);
              // check whether video is in playing state or not
              const isVideoPlaying = !!(
                videoElement?.currentTime > 0 &&
                !videoElement?.paused &&
                !videoElement?.ended &&
                videoElement?.readyState > 2
              );
              if (
                // !receivedData?.msg?.properties?.next_avail_time &&
                !isVideoPlaying
              ) {
                // startClientSideTimer();
              }
              if (receivedData?.msg?.properties?.next_avail_time) {
                nextAvailTime[receivedData?.msg?.properties?.dev_id] =
                  receivedData?.msg?.properties?.next_avail_time;
              }
            }
            if (type === 'SEND_COMPLETE') {
              sendCompleteDeviceIds[deviceId] = true;
            }
            Utils.vmsLogger().log(
              'Received data:',
              JSON.stringify(receivedData, null, 2),
            );
          }
        } catch (error) {
          Utils.vmsLogger().error('=== media source error: ', error);
          // dataChannel.close();
          // mediaSource.endOfStream();
        }
      };
    };
  }
};

const checkInitSegment = (buffer) => {
  const ftypBox = String.fromCharCode.apply(null, buffer.subarray(4, 8));
  return ftypBox === 'ftyp';
};

const loadMp4BoxFile = (u8Buffer) => {
  return new Promise((resolve, reject) => {
    const mp4BoxFile = MP4Box.createFile(false);
    mp4BoxFile.onReady = (info) => {
      Utils.vmsLogger().log('=== mp4 box info: ', info);
      const videoTrack = info.tracks.find((track) => track.type === 'video');
      mp4BoxFile.setExtractionOptions(videoTrack.id, null, { nbSamples: 1 });
      mp4BoxFile.onSamples = (_id, _user, samples) => {
        const cts = samples[0].cts / samples[0].timescale;
        ctsArray.push(cts);
      };
      resolve(mp4BoxFile);
    };
    mp4BoxFile.onError = reject;
    u8Buffer.buffer.fileStart = 0;
    mp4BoxFile.lastFileStart = mp4BoxFile.appendBuffer(u8Buffer.buffer);
    mp4BoxFile.start();
  });
};

const addNewVideoElement = (event, deviceId) => {
  try {
    const previouseVideoElement = event.target;
    previouseVideoElement.pause();
    previouseVideoElement.removeEventListener('ended', (event) => {
      addNewVideoElement(event, deviceId);
    });
    previouseVideoElement.removeEventListener('progress', (event) => {
      handleVideoEvents(event, deviceId);
    });
    previouseVideoElement.removeEventListener('canplay', (event) => {
      handleVideoEvents(event, deviceId);
    });
    URL.revokeObjectURL(previouseVideoElement.src);
    previouseVideoElement.remove();
    const existingVideoTag = document.getElementById(
      `playback-video${deviceId}`,
    );
    if (existingVideoTag) {
      existingVideoTag.remove();
    }
    const nextVideoElement = nextVideoElements.shift();
    nextVideoElement.play();
  } catch (err) {
    Utils.vmsLogger().log('Error while adding new element', err);
  }
};

const createVideoElement = (deviceId) => {
  const oldVideoElement = document.getElementById(`playback-video${deviceId}`);
  if (oldVideoElement) {
    cleanVideoElements(deviceId);
  }

  const videoElement = document.createElement('video');

  videoElement.controls = false;
  videoElement.muted = true; //audioMuteUnmute;
  videoElement.style.width = '100%';
  videoElement.style.height = '100%';
  videoElement.id = `playback-video${deviceId}`;

  const mediaSource = new MediaSource();
  videoElement.mediaSource = mediaSource;
  videoElement.src = URL.createObjectURL(mediaSource);
  videoElement.sourceBuffers = {};
  videoElement.segmentQueues = {};
  videoElement.setAttribute('data-device-id', deviceId);
  // mediaSource.addEventListener('sourceopen', () => {
  //   mediaSource.duration = 0;
  // });
  mediaSource.addEventListener('sourceopen', handleSourceOpen);
  videoElement.addEventListener('error', handleVideoEvents);
  videoElement.addEventListener('progress', handleVideoEvents);
  videoElement.addEventListener('canplay', handleVideoEvents);
  videoElement.addEventListener('waiting', handleVideoEvents);
  return videoElement;
};

const handleSourceOpen = (event) => {
  if (event?.target?.duration) {
    event.target.duration = 0;
  }
};

const handleVideoEvents = (event) => {
  const deviceId = event.target.getAttribute('data-device-id');
  if (!deviceId) {
    return;
  }
  const deviceName = allDevices?.find(
    (device) => device?.deviceId === deviceId,
  )?.deviceName;
  // Utils.vmsLogger().log('handleLoader ~ event:', event);
  const loaderData = Store.getState()?.streaming?.multiLiveStreamLoader;
  const isLoaderDisplay = loaderData?.[deviceId];
  const findNoVideoIndex = noVideoDevices?.findIndex(
    (device) => device === deviceId,
  );
  switch (event.type) {
    case 'canplay':
      Utils.vmsLogger().log(
        'PlayBack first frame received ',
        deviceId,
        deviceName,
        Utils.getTimesinmili(),
      );
      if (findNoVideoIndex !== -1) noVideoDevices?.splice(findNoVideoIndex, 1);
      if (isLoaderDisplay) {
        const updatedObj = {
          deviceId: deviceId,
          isLoading: false,
        };
        Store.dispatch(setMultiLiveStreamLoader(updatedObj));
      }
      break;

    case 'waiting':
      Utils.vmsLogger().log('Multi edge playback waiting for buffer', deviceId);
      break;

    case 'playing':
      if (findNoVideoIndex !== -1) noVideoDevices?.splice(findNoVideoIndex, 1);
      if (isLoaderDisplay) {
        const updatedObj = {
          deviceId: deviceId,
          isLoading: false,
        };
        Store.dispatch(setMultiLiveStreamLoader(updatedObj));
      }
      // if (virtualTimeInterval) clearInterval(virtualTimeInterval);
      // virtualTimeInterval = null;
      // noVideo.style.display = 'none';
      break;

    case 'progress':
      assignBufferedMeta(deviceId);
      break;

    case 'error':
      handleVideoError(event, deviceId);
      break;

    default:
      break;
  }
};

const assignBufferedMeta = (deviceId) => {
  const videoElement = document.getElementById(`playback-video${deviceId}`);
  if (
    videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered.length > 0
  ) {
    if (!isAutoPlay) {
      videoElement.pause();
    }
    const bufferStart =
      videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.start(0);
    const bufferEnd =
      videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(0);
    if (bufferStart && bufferEnd) {
      const obj = {
        // id: allConnectionData?.deviceId,
        id: deviceId,
        client: {
          start: parseInt(bufferStart, 10) * 1000,
          end: parseInt(bufferEnd, 10) * 1000,
          className: 'new-meta-buffer-class',
        },
      };
      Store.dispatch(setPlaybackBufferMeta(obj));
    }
  }
};

const handleVideoError = (event, deviceId) => {
  const videoElement = document.getElementById(`playback-video${deviceId}`);
  Utils.vmsLogger().log('video error', videoElement?.error);
};

export const startPlaybackProcess = (requirePlayData) => {
  const deviceObj = {
    deviceId: requirePlayData?.deviceId,
    deviceName: requirePlayData?.deviceName,
  };
  const deviceIdx = allDevices?.findIndex(
    (device) => device.deviceId === requirePlayData?.deviceId,
  );
  if (deviceIdx === -1) allDevices?.push(deviceObj);
  // const videoElement = document.getElementById(`playback-video${deviceId}`);
  currentPlayStartTime = requirePlayData?.playTime;
  // audioMuteUnmute = requirePlayData?.audioValue;
  const videoContainer = document.getElementById(
    `remote-view-wrapper${requirePlayData?.deviceId}`,
  );
  if (videoContainer) {
    const videoElement = createVideoElement(requirePlayData?.deviceId);
    nextVideoElements.push(createVideoElement(requirePlayData?.deviceId));
    videoElement.autoplay = isAutoPlay;
    videoElement.preload = true;
    const existingVideoTag = document.getElementById(
      `playback-video${requirePlayData?.deviceId}`,
    );
    if (existingVideoTag) {
      cleanVideoElements(requirePlayData?.deviceId);
    }
    videoContainer.appendChild(videoElement);
    const peerConnection = peerConnections?.[requirePlayData?.deviceId];
    // Utils.vmsLogger().log('playback peerConnection', peerConnection);
    if (!peerConnection || peerConnection?.connectionState !== 'connected') {
      startConnection(requirePlayData?.deviceId);
    }
    const elementCreated = 'elementCreated';
    return elementCreated;
  }
};

export const sendPlayCommand = (timestamp, command, deviceId) => {
  const dataChannel = dataChannels?.[deviceId];
  const deviceName = allDevices?.find(
    (device) => device?.deviceId === deviceId,
  )?.deviceName;
  playbackPlayTime = Utils.getUnixDate(timestamp);
  const actualTime = Utils.getUnixDate(timestamp) * 1000;
  sendCompleteDeviceIds[deviceId] = false;
  const uniqueId = uuidv4();
  if (dataChannel?.readyState === 'open') {
    const allConnectionData = mqttClient?.getMqttClient(false, deviceId);
    const playPayload = {
      cid: uniqueId, //-- optional in case of Edge playback
      tid: new Date().getTime().toString(),
      to: allConnectionData?.deviceId,
      from: allConnectionData?.accountId,
      msg: {
        action: 'set',
        resource: 'edge/playback',
        properties: {
          uuid: `${allConnectionData?.streamId}`, //-- optional in case of Edge playback
          org_id: allConnectionData?.orgId, //-- optional in case of Edge playback
          dev_id: allConnectionData?.deviceId, //-- optional in case of Edge playback
          start_time: actualTime, //-- epoch millisec
          duration: 60000, //-- +30000, -30000 (+ forward / - rewind)
          quality: 'SQ', //-- HQ or SQ
          type: command,
        },
      },
    };
    Utils.vmsLogger().log(
      'PlayBack play command sent ',
      deviceId,
      deviceName,
      Utils.getTimesinmili(),
    );
    dataChannel?.send(JSON.stringify(playPayload));
  }
};

export const sendContinueCommand = (command, deviceId) => {
  const deviceName = allDevices?.find(
    (device) => device?.deviceId === deviceId,
  )?.deviceName;
  const uniqueId = uuidv4();
  const dataChannel = dataChannels?.[deviceId];
  sendCompleteDeviceIds[deviceId] = false;
  if (dataChannel?.readyState === 'open') {
    const allConnectionData = mqttClient?.getMqttClient(false, deviceId);
    const playPayload = {
      cid: uniqueId, //-- optional in case of Edge playback
      tid: new Date().getTime().toString(),
      to: allConnectionData?.deviceId,
      from: allConnectionData?.accountId,
      msg: {
        action: 'set',
        resource: 'edge/playback',
        properties: {
          uuid: `${allConnectionData?.streamId}`, //-- optional in case of Edge playback
          org_id: allConnectionData?.orgId, //-- optional in case of Edge playback
          dev_id: allConnectionData?.deviceId, //-- optional in case of Edge playback
          // start_time: actualTime, //-- epoch millisec
          duration: 60000, //-- +30000, -30000 (+ forward / - rewind)
          quality: 'SQ', //-- HQ or SQ
          type: command,
        },
      },
    };
    Utils.vmsLogger().log(
      'PlayBack continue command sent ',
      deviceId,
      deviceName,
      Utils.getTimesinmili(),
    );
    dataChannel?.send(JSON.stringify(playPayload));
  }
};

export const sendStopCommand = (deviceId, isAutoPlayVal) => {
  isAutoPlay = isAutoPlayVal;
  const playbackPlayer = document.getElementById('playback-video');
  if (playbackPlayer) {
    playbackPlayer.isAutoPlay = isAutoPlay;
  }
  const uniqueId = uuidv4();
  const dataChannel = dataChannels?.[deviceId];
  const allConnectionData = mqttClient?.getMqttClient(false, deviceId);
  if (dataChannel?.readyState === 'open') {
    const playPayload = {
      cid: uniqueId, //-- optional in case of Edge playback
      tid: new Date().getTime().toString(),
      to: allConnectionData?.deviceId,
      from: allConnectionData?.accountId,
      msg: {
        action: 'set',
        resource: 'edge/playback',
        properties: {
          uuid: `${allConnectionData?.streamId}`, //-- optional in case of Edge playback
          org_id: allConnectionData?.orgId, //-- optional in case of Edge playback
          dev_id: allConnectionData?.deviceId, //-- optional in case of Edge playback
          type: 'STOP',
        },
      },
    };
    dataChannel?.send(JSON.stringify(playPayload));
  }
};

export const stopPlaybackConnection = (deviceId) => {
  if (!deviceId) {
    Object.keys(peerConnections).forEach((id) => {
      let peerConnection = peerConnections?.[id];
      let dataChannel = dataChannels?.[deviceId];
      if (peerConnection) {
        peerConnection.close();
        peerConnection.onicecandidate = null;
        peerConnection.ontrack = null;
        if (peerConnection.video) {
          peerConnection.video.oncanplay = null;
          peerConnection.video = null;
        }
        peerConnection = null;
        dataChannel = null;
      }
      delete peerConnections?.[id];
      delete dataChannels?.[deviceId];
    });
    peerConnections = {};
    dataChannels = {};
  } else if (peerConnections?.[deviceId]) {
    let peerConnection = peerConnections?.[deviceId];
    let dataChannel = dataChannels?.[deviceId];
    if (peerConnection) {
      peerConnection?.close();
      peerConnection.onicecandidate = null;
      peerConnection.ontrack = null;
      if (peerConnection.video) {
        peerConnection.video.oncanplay = null;
        peerConnection.video = null;
      }
      peerConnection = null;
      dataChannel = null;
    }
    delete peerConnections?.[deviceId];
    delete dataChannels?.[deviceId];
  }
  clearInterval(virtualTimeInterval);
  cleanupResources(deviceId);
};

const cleanupResources = (deviceId) => {
  cleanVideoElements(deviceId);
  if (peerConnections?.[deviceId]) {
    delete peerConnections[deviceId];
  }
  currentPlayStartTime = '';
};

export const cleanVideoElements = (deviceId) => {
  const videoElement = document.getElementById(`playback-video${deviceId}`);
  if (
    videoElement?.mediaSource?.readyState === 'open' &&
    !videoElement?.sourceBuffers?.['dc-media']?.updating
  ) {
    videoElement?.mediaSource?.endOfStream();
    videoElement?.mediaSource?.removeEventListener(
      'sourceopen',
      handleSourceOpen,
    );
  }
  videoElement?.removeEventListener('error', handleVideoEvents);
  videoElement?.removeEventListener('progress', handleVideoEvents);
  videoElement?.removeEventListener('canplay', handleVideoEvents);
  videoElement?.removeEventListener('waiting', handleVideoEvents);
  videoElement?.remove();
  nextVideoElements.length = 0;
};

export const getPeerConnectionState = (deviceId) => {
  return peerConnections?.[deviceId];
};
export const getNextAvailTime = (deviceId) => {
  return nextAvailTime?.[deviceId] || false;
};
export const getNoVideoDevices = (deviceId) => {
  let res = '';
  if (noVideoDevices.includes(deviceId)) {
    return deviceId;
  } else {
    return res;
  }
};

export const getSendComplete = (deviceId) => {
  return sendCompleteDeviceIds?.[deviceId];
};

export const setMultiEdgeIsAutoPlay = (isAutoPlayVal) => {
  isAutoPlay = isAutoPlayVal;
};
