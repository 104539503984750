import React, { useEffect, useRef, useState } from 'react';
import RegionSelect from 'react-region-select';
import { useForm } from 'react-hook-form';
import { RiErrorWarningLine } from 'react-icons/ri';
import { RxCross1 } from 'react-icons/rx';
import { PrimaryButton, SiteSpinner } from '../../../../components/common';
import { Utils, constants } from '../../../../helpers';
import { useSelector } from 'react-redux';
import { getAllMqttDataFromResponse } from '../../../../store/reducers/AccountReducer';
import { getCDNInfo } from '../../../../store/reducers/StreamingReducer';
import publishDeviceSetting from '../shared/PublishDeviceSettings';
import InclusionExclusionSnapShot from './InclusionExclusionSnapShot';
import './BackLightArea.scss';

const BackLightArea = ({
  accountId,
  refResolution,
  deviceId,
  deviceInfos,
  devicePublishId,
  uuid,
  hideModelPopup,
  selectedTab,
  blcLevelbackLight,
  hlcLevelBackLight,
  hlcMaskBackLight,
  hlcMaskColorBackLight,
  hlcMaskToneBackLight,
  hlcDimmingBackLight,
  updateStore,
}) => {
  const initialValues = {
    zoneName: '',
  };
  const { handleSubmit, reset, setValue } = useForm({
    defaultValues: initialValues,
  });
  const formRef = useRef();
  const expiredTime = 20;
  const backLightResourceList = Utils.getDeviceSettingResource(1);
  const REGION_X_RANGE = +refResolution?.[0] || 1;
  const REGION_Y_RANGE = +refResolution?.[1] || 1;
  const [regions, setRegions] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const publishPayload = {
    deviceId: deviceId,
    devicePublishId: devicePublishId,
    uuid: uuid,
    accountId: accountId,
    hubId: deviceInfos.gatewayId,
  };
  const [imageWidth, setImageWidth] = useState(0);
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  const blcAreaCoordinates = deviceInfos?.properties?.['blc-area-coordinates'];
  const hlcAreaCoordinates = deviceInfos?.properties?.['hlc-area-coordinates'];
  const [aspectRatio, setAspectRatio] = useState(1.7);
  const cdnInfo = useSelector(getCDNInfo);

  useEffect(() => {
    const updatedRegions = regions.map((reg) => {
      return {
        ...reg,
        data: {
          ...reg.data,
          regionStyle: {
            ...reg.data.regionStyle,
            display:
              selectedRegion.data.regionColor === reg.data.regionColor
                ? 'block'
                : 'none',
          },
        },
      };
    });
    setRegions(updatedRegions);
    document.getElementsByClassName('modal-body')[0].scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion]);

  useEffect(() => {
    const resource = allMqttData?.msg?.resource;
    if (
      resource?.includes(backLightResourceList[6]) &&
      resource?.includes(deviceId)
    ) {
      const properties = allMqttData?.msg?.properties;
      const action = allMqttData?.msg?.action;
      if (action === 'error') {
        setRemainingTime(0);
        properties.desc === constants.INVALID_PAYLOAD
          ? setErrorMsg(constants.INVALID_PAYLOAD_REGION_ERROR_MESSAGE)
          : setErrorMsg(properties.desc);
      } else if (action === 'is') {
        setRemainingTime(0);
        hideModelPopup();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMqttData]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime((prevTime) => prevTime - 1);
      } else {
        setShowLoader(false);
        clearInterval(timerInterval);
      }
    }, 1000);
    return () => {
      clearInterval(timerInterval);
    };
  }, [remainingTime]);

  useEffect(() => {
    if (REGION_X_RANGE && REGION_Y_RANGE && imageWidth) {
      setAspectRatio(REGION_X_RANGE / REGION_Y_RANGE);
      const coordinates =
        selectedTab?.toLowerCase() === 'blc'
          ? blcAreaCoordinates.split(',')
          : hlcAreaCoordinates.split(',');
      const areaCoordinates = getAreaCoordinates(...coordinates);
      const regionList = [];
      const dimensions = getZoneDimensions(...areaCoordinates);
      const color = getComputedStyle(document.documentElement).getPropertyValue(
        '--custom_color_18',
      );
      regionList.push({
        x: dimensions?.left,
        y: dimensions?.top,
        width: dimensions?.width,
        height: dimensions?.height,
        data: {
          motion: 'true',
          regionColor: color,
          regionStyle: {
            border: `2px solid ${color}`,
          },
          x: dimensions?.left,
          y: dimensions?.top,
          width: dimensions?.width,
          height: dimensions?.height,
          ...dimensions,
        },
        isChanging: false,
      });

      if (regionList?.length) {
        setRegions(regionList);
        const updatedSelectedRegion = regionList?.find(
          (region) =>
            region.data.regionColor === selectedRegion?.data?.regionColor,
        );
        updateRegionAndForm(
          updatedSelectedRegion
            ? updatedSelectedRegion
            : regionList?.length > 0
              ? regionList[0]
              : null,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageWidth]);

  const getAreaCoordinates = (x1, y1, x2, y2) => {
    const result = [x1, y2, x2, y2, x2, y1, x1, y1]; // Working 1st
    return result;
  };

  const getAreaDimentions = (data) => {
    const { TLx, TLy, BRx, BRy } = data;
    const result = [
      TLx > REGION_X_RANGE - 1 ? REGION_X_RANGE - 1 : TLx,
      TLy > REGION_Y_RANGE - 1 ? REGION_Y_RANGE - 1 : TLy,
      BRx > REGION_X_RANGE - 1 ? REGION_X_RANGE - 1 : BRx,
      BRy > REGION_Y_RANGE - 1 ? REGION_Y_RANGE - 1 : BRy,
    ];
    return result.join(',');
  };

  const onRegionChange = (regs) => {
    let updatedRegions = [];
    if (regs.filter((x) => x.isChanging)?.length === 0) {
      updatedRegions = regs.map((reg) => {
        return {
          ...reg,
          data: {
            ...reg.data,
            ...getZoneCoordinates(reg.x, reg.y, reg.width, reg.height),
            x: reg.x,
            y: reg.y,
            height: reg.height,
            width: reg.width,
          },
        };
      });
    }
    setRegions(updatedRegions?.length > 0 ? updatedRegions : regs);
  };

  const getZoneCoordinates = (left, top, width, height) => {
    const divResolutionWidth =
      document.getElementById('img-snapshot')?.offsetWidth;
    const divResolutionHeight =
      document.getElementById('img-snapshot')?.offsetHeight;
    const proportionWidth = parseInt(REGION_X_RANGE - 1) / divResolutionWidth;
    const proportionHeight = parseInt(REGION_Y_RANGE - 1) / divResolutionHeight;

    const updatedLeft = proportionWidth * ((divResolutionWidth / 100) * left);
    const updatedTop = proportionHeight * ((divResolutionHeight / 100) * top);
    const updatedHeight =
      proportionHeight * ((divResolutionHeight / 100) * height);
    const updatedWidth = proportionWidth * ((divResolutionWidth / 100) * width);

    const TL = { x: updatedLeft, y: updatedTop };
    const TR = { x: updatedLeft + updatedWidth, y: updatedTop };
    const BL = { x: updatedLeft, y: updatedTop + updatedHeight };
    const BR = { x: updatedLeft + updatedWidth, y: updatedTop + updatedHeight };

    return {
      TLx: Math.round(TL.x),
      TLy: Math.round(TL.y),
      TRx: Math.round(TR.x),
      TRy: Math.round(TR.y),
      BLx: Math.round(BL.x),
      BLy: Math.round(BL.y),
      BRx: Math.round(BR.x),
      BRy: Math.round(BR.y),
    };
  };

  const getZoneDimensions = (BLx, BLy, BRx, BRy, TRx, TRy, TLx, TLy) => {
    const divResolutionWidth =
      document.getElementById('img-snapshot')?.offsetWidth;
    const divResolutionHeight =
      document.getElementById('img-snapshot')?.offsetHeight;
    const proportionWidth = parseInt(REGION_X_RANGE - 1) / divResolutionWidth;
    const proportionHeight = parseInt(REGION_Y_RANGE - 1) / divResolutionHeight;
    const left = +TLx / proportionWidth / (divResolutionWidth / 100);
    const top = +TLy / proportionHeight / (divResolutionHeight / 100);
    const width = (+BRx - +BLx) / proportionWidth / (divResolutionWidth / 100);
    const height =
      (+TLy - +BLy) / proportionHeight / (divResolutionHeight / 100);

    return {
      left: Math.abs(left),
      top: Math.abs(top),
      width: Math.abs(width),
      height: Math.abs(height),
      TLx,
      TLy,
      TRx,
      TRy,
      BLx,
      BLy,
      BRx,
      BRy,
    };
  };

  const saveZoneHandler = (values) => {
    setErrorMsg('');
    setShowLoader(true);
    setRemainingTime(expiredTime);
    try {
      const regionsWithUpdatedCoordinates = regions.find(
        (region) =>
          region.data.regionColor === selectedRegion?.data?.regionColor,
      );
      const resourceListData = backLightResourceList[6];
      const coordinates = getAreaDimentions(regionsWithUpdatedCoordinates.data);

      let mainProperties = {};
      if (selectedTab?.toLowerCase() === 'blc') {
        mainProperties = {
          'blc-area-coordinates': coordinates,
          'backlight-mode': selectedTab?.toLowerCase(),
          'blc-level': blcLevelbackLight,
        };
      } else {
        mainProperties = {
          'hlc-area-coordinates': coordinates,
          'backlight-mode': selectedTab?.toLowerCase(),
          'hlc-level': hlcLevelBackLight,
          'hlc-mask': hlcMaskBackLight,
          'hlc-mask-color': hlcMaskColorBackLight,
          'hlc-mask-tone': hlcMaskToneBackLight,
          'hlc-dimming': hlcDimmingBackLight,
        };
      }
      updateStore(mainProperties);
      publishDeviceSetting(mainProperties, resourceListData, publishPayload);
    } catch (error) {
      Utils.vmsLogger().log('error', error);
      setShowLoader(false);
      setRemainingTime(0);
    }
  };

  const updateRegionAndForm = (region) => {
    reset();
    const zoneName = region?.data?.zoneName ? region.data.zoneName : '';
    setValue('zoneName', zoneName);
    setSelectedRegion(region);
  };

  const handleErrorMsgClose = () => {
    setErrorMsg('');
  };

  return (
    <div className="component-container">
      <div className={`${showLoader ? 'disable-bg' : ''}`}></div>
      <div className="activity-zone-info">
        {constants.REGION_OF_INTEREST_POPUP_TITLE_INFO}
      </div>
      {showLoader && (
        <SiteSpinner
          height="50px"
          width="50px"
          styles={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            bottom: '50%',
            right: '50%',
            zIndex: 15,
          }}
        ></SiteSpinner>
      )}
      {errorMsg && (
        <div className="mt-3 error-conatiner">
          <div className="error-message-container">
            <RiErrorWarningLine className="icon-warning-col" />
            <div className="">{errorMsg}</div>
          </div>
          <RxCross1
            onClick={() => handleErrorMsgClose()}
            className="icon-warning-col"
          />
        </div>
      )}
      <br></br>
      <div className="snap-shot-container">
        <div className="live-snapshot">
          <InclusionExclusionSnapShot
            key={deviceInfos?.deviceId}
            deviceElemId={`camera${deviceInfos?.deviceId}`}
            orgId={deviceInfos?.orgId}
            cdnInfo={cdnInfo}
            locationAreaName={deviceInfos?.locationAreaNames}
            isHighlighted={false}
            showZoomOptions={false}
            conStatus={deviceInfos?.connectionStatus || 'offline'}
            hubId={deviceInfos?.gatewayId}
            displayDeviceStatus={Utils.getDeviceStatus(
              deviceInfos?.deviceStatus,
              deviceInfos?.connectionStatus,
            )}
            setImageWidth={setImageWidth}
            aspectRatio={aspectRatio}
            analytics={true}
            {...deviceInfos}
          />
          <RegionSelect
            id="region-select"
            maxRegions={3}
            regions={regions}
            selectedRegion={selectedRegion}
            regionStyle={{
              outline: 'none',
              zIndex: 70,
            }}
            className={`region-container region${selectedRegion?.index}`}
            onChange={onRegionChange}
            style={{
              border: '0px solid black',
              position: 'absolute',
              height: '-webkit-fill-available',
              display: 'inline-block',
              width: imageWidth,
            }}
            constraint={true}
          >
            {regions
              .filter(
                (region) =>
                  region.data.regionColor !== selectedRegion?.data?.regionColor,
              )
              .map((region) => (
                <div
                  style={{
                    border: `2px solid ${region.data.regionColor}`,
                    position: 'absolute',
                    height: `${region.data.height}%`,
                    width: `${region.data.width}%`,
                    zIndex: 20,
                    pointerEvents: 'none',
                    left: `${region.data.x}%`,
                    top: `${region.data.y}%`,
                  }}
                ></div>
              ))}
            <div></div>
          </RegionSelect>
        </div>
      </div>
      <form ref={formRef} onSubmit={handleSubmit(saveZoneHandler)}>
        <div className={`button-conatiner-backlight-area`}>
          <PrimaryButton
            className="btn btn-primary mt-4 btn-margin-save"
            type="submit"
            width="20%"
            disabled={!selectedRegion}
          >
            {constants.CAMERAS_CAMERA_VIEW_SAVE_CHANGES_MODAL_SAVE_BUTTON}
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};

export default BackLightArea;
