import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cdnInfo: '',
  metadata: '',
  mqttConnection: '',
  platform: null,
  snapShotDownloadLoading: false,
  liveStream: null,
  remoteStream: null,
  isRemoteStreamPlay: false,
  isActiveStream: false,
  remoteStreamAudio: null,
  deviceStartDate: '',
  loadingStream: false,
  wssConnection: '',
  liveCameraIds: [],
  reloadAsFilterTriggered: false,
  wssConnections: null,
  liveStreams: null,
  remoteStreams: null,
  offerRecieved: false,
  codecNotSupported: null,
  playbackBufferMeta: null,
  maxPeerCountReached: null,
  elementUpdated: false,
  isLiveViewEntitlementExist: false,
  isLiveViewEntitlementExpired: false,
  channelTimeStamp: false,
  cvrStartDate: '',
  isReloadedStream: true,
  noSnapshotError: '',
  isOnMultiCameraViewPage: false,
  bitmapObject: null,
  endOfStream: false,
  bitmapObjects: null,
  receivedOffers: null,
  streamLoader: false,
  isFetchLicensesDataAgain: false,
  playbackActiveTime: '',
  multiLiveStreamLoader: null,
  licenseAndDeviceData: [],
  isAudioSettingsChanged: false,
};

export const streaming = createSlice({
  name: 'streaming',
  initialState,
  reducers: {
    setPlatformInfo: (state, action) => {
      state.platform = action.payload;
    },
    resetPlatformInfo: (state) => {
      state.platform = null;
    },
    setCDNInfo: (state, action) => {
      state.cdnInfo = action.payload;
    },
    resetCDNInfo: (state) => {
      state.cdnInfo = '';
    },
    setStartDate: (state, action) => {
      state.deviceStartDate = action.payload;
    },
    setMetaData: (state, action) => {
      state.metadata = action.payload;
    },
    setSnapShoDownloadLoading: (state, action) => {
      state.snapShotDownloadLoading = action.payload;
    },
    setLiveStream: (state, action) => {
      state.liveStream = action.payload;
    },
    setRemoteStream: (state, action) => {
      state.remoteStream = action.payload;
    },
    setIsRemoteStreamPlay: (state, action) => {
      state.isRemoteStreamPlay = action.payload;
    },
    setIsActiveStream: (state, action) => {
      state.isActiveStream = action.payload;
    },
    setLiveCameraIds: (state, action) => {
      state.liveCameraIds = action.payload;
    },
    setRemoteStreamAudio: (state, action) => {
      state.remoteStreamAudio = action.payload;
    },
    setStremBitmapData: (state, action) => {
      state.bitmapObject = action.payload;
    },
    setStremBitmapsData: (state, action) => {
      state.bitmapObjects = {
        ...state.bitmapObjects,
        [action.payload.id]: action.payload.bitmapObject,
      };
    },
    setMQTTConnection: (state, action) => {
      state.mqttConnection = action.payload;
    },
    setWSSConnection: (state, action) => {
      state.wssConnection = action.payload;
    },
    setLoadingStream: (state, action) => {
      state.loadingStream = action.payload;
    },
    resetStreaming: () => initialState,
    setReloadAsFilterTriggered: (state, action) => {
      state.reloadAsFilterTriggered = action.payload;
    },
    setWSSConnections: (state, action) => {
      state.wssConnections = {
        ...state.wssConnections,
        [action.payload.id]: action.payload.client,
      };
    },
    removeWSSConnections: (state, action) => {
      const updatedConnections = { ...state.wssConnections };
      delete updatedConnections[action.payload];
      state.wssConnections = updatedConnections;
    },
    clearWSSConnections: (state) => {
      state.wssConnections = null;
    },
    setLiveStreams: (state, action) => {
      state.liveStreams = {
        ...state.liveStreams,
        [action.payload.id]: action.payload.stream,
      };
    },
    removeLiveStreams: (state, action) => {
      const updatedStreams = { ...state.liveStreams };
      delete updatedStreams[action.payload];
      state.liveStreams = updatedStreams;
    },
    clearLiveStreams: (state) => {
      state.liveStreams = null;
    },
    setRemoteStreams: (state, action) => {
      state.remoteStreams = {
        ...state.remoteStreams,
        [action.payload.id]: action.payload.stream,
      };
    },
    setCodecNotSupported: (state, action) => {
      state.codecNotSupported = action.payload;
      if (action.payload === null) {
        state.codecNotSupported = null;
      } else {
        const updatedData = { ...state.codecNotSupported };
        const findIndex = Object.keys(updatedData).findIndex((data) => {
          return data === action.payload.id;
        });
        if (findIndex !== -1) {
          delete updatedData[action.payload.id];
        }
        state.codecNotSupported = {
          ...updatedData,
          [action.payload.id]: action.payload.isCodecNotSupport,
        };
      }
    },
    removeRemoteStreams: (state, action) => {
      const updatedStreams = { ...state.remoteStreams };
      delete updatedStreams[action.payload];
      state.remoteStreams = updatedStreams;
    },
    clearRemoteStreams: (state) => {
      state.remoteStreams = null;
    },
    setPlaybackBufferMeta: (state, action) => {
      if (action.payload === null) {
        state.playbackBufferMeta = null;
      } else {
        const updatedData = { ...state.playbackBufferMeta };
        const findIndex = Object.keys(updatedData).findIndex((data) => {
          return data === action.payload.id;
        });
        if (findIndex !== -1) {
          delete updatedData[action.payload.id];
        }
        state.playbackBufferMeta = {
          ...updatedData,
          [action.payload.id]: action.payload.client,
        };
      }
    },
    setMaxPeerCountForDevice: (state, action) => {
      if (action.payload === null) {
        state.maxPeerCountReached = null;
      } else {
        const updatedData = { ...state.maxPeerCountReached };
        const findIndex = Object.keys(updatedData).findIndex((data) => {
          return data === action.payload.id;
        });
        if (findIndex !== -1) {
          delete updatedData[action.payload.id];
        }
        state.maxPeerCountReached = {
          ...updatedData,
          [action.payload.id]: action.payload.isMaxPeer,
        };
      }
    },
    setElementUpdated: (state, action) => {
      state.elementUpdated = action.payload;
    },
    setIsLiveViewEntitlementExist: (state, action) => {
      state.isLiveViewEntitlementExist = action.payload;
    },
    setOfferrecived: (state, action) => {
      state.offerRecieved = action.payload;
    },
    setIsLiveViewEntitlementExpired: (state, action) => {
      state.isLiveViewEntitlementExpired = action.payload;
    },
    setChannelTimeStamp: (state, action) => {
      state.channelTimeStamp = action.payload;
    },
    setCVRStartDate: (state, action) => {
      state.cvrStartDate = action.payload;
    },
    setIsReloadedStream: (state, action) => {
      state.isReloadedStream = action.payload;
    },
    setNoSnapshotError: (state, action) => {
      state.noSnapshotError = action.payload;
    },
    setIsOnMultiCameraViewPage: (state, action) => {
      state.isOnMultiCameraViewPage = action.payload;
    },
    setEndOfStream: (state, action) => {
      state.endOfStream = action.payload;
    },
    setRecievedOffers: (state, action) => {
      state.receivedOffers = {
        ...state.receivedOffers,
        [action.payload.id]: action.payload.value,
      };
    },
    resetRecievedOffers: (state, action) => {
      state.receivedOffers = null;
    },
    setStreamLoader: (state, action) => {
      state.streamLoader = action.payload;
    },
    setIsFetchLicensesDataAgain: (state) => {
      state.isFetchLicensesDataAgain = !state.isFetchLicensesDataAgain;
    },
    setPlaybackActiveTime: (state, action) => {
      state.playbackActiveTime = action.payload;
    },
    setMultiLiveStreamLoader: (state, action) => {
      if (action.payload === null) {
        state.multiLiveStreamLoader = null;
      } else {
        const updatedData = { ...state.multiLiveStreamLoader };
        const findIndex = Object.keys(updatedData).findIndex(
          (data) => data === action.payload.deviceId,
        );
        if (findIndex !== -1) {
          delete updatedData[action.payload.deviceId];
        }
        state.multiLiveStreamLoader = {
          ...updatedData,
          [action.payload.deviceId]: action.payload.isLoading,
        };
      }
    },
    setLicenseAndDeviceData: (state, action) => {
      state.licenseAndDeviceData = action.payload;
    },
    setIsAudioSettingsChanged: (state, action) => {
      state.isAudioSettingsChanged = action.payload;
    },
  },
});

export const {
  setPlatformInfo,
  resetPlatformInfo,
  setCDNInfo,
  resetCDNInfo,
  setMetaData,
  setLiveStream,
  setSnapShoDownloadLoading,
  setRemoteStream,
  setIsRemoteStreamPlay,
  setIsActiveStream,
  setRemoteStreamAudio,
  setMQTTConnection,
  resetStreaming,
  setLoadingStream,
  setStartDate,
  setWSSConnection,
  setLiveCameraIds,
  setReloadAsFilterTriggered,
  setWSSConnections,
  removeWSSConnections,
  clearWSSConnections,
  setLiveStreams,
  removeLiveStreams,
  clearLiveStreams,
  setRemoteStreams,
  setCodecNotSupported,
  removeRemoteStreams,
  clearRemoteStreams,
  setMQTTPeerConnections,
  setIsLiveViewEntitlementExist,
  setIsLiveViewEntitlementExpired,
  setChannelTimeStamp,
  setCVRStartDate,
  setIsReloadedStream,
  setNoSnapshotError,
  setIsOnMultiCameraViewPage,
  setStremBitmapData,
  setEndOfStream,
  setStremBitmapsData,
  setOfferrecived,
  setRecievedOffers,
  resetRecievedOffers,
  setStreamLoader,
  setIsFetchLicensesDataAgain,
  setPlaybackActiveTime,
  setMultiLiveStreamLoader,
  setLicenseAndDeviceData,
  setPlaybackBufferMeta,
  setMaxPeerCountForDevice,
  setElementUpdated,
  setIsAudioSettingsChanged,
} = streaming.actions;
export const getPlatformInfo = (state) => state.streaming.platform;
export const getCDNInfo = (state) => state.streaming.cdnInfo;
export const getMetaData = (state) => state.streaming.metadata;
export const getSnapShotDownloadLoading = (state) =>
  state.streaming.snapShotDownloadLoading;
export const getLiveStream = (state) => state.streaming.liveStream;
export const getRemoteStream = (state) => state.streaming.remoteStream;
export const getIsRemoteStreamPlay = (state) =>
  state.streaming.isRemoteStreamPlay;
export const getIsActiveStream = (state) => state.streaming.isActiveStream;
export const getRemoteStreamAudio = (state) =>
  state.streaming.remoteStreamAudio;
export const getStreamBitmapData = (state) => state.streaming.bitmapObject;
export const getMQTTConnection = (state) => state.streaming.mqttConnection;
export const getWSSConnection = (state) => state.streaming.wssConnection;
export const getStartDate = (state) => state.streaming.deviceStartDate;
export const getLoadingStream = (state) => state.streaming.loadingStream;
export const getLiveCameraIds = (state) => state.streaming.liveCameraIds;
export const getReloadAsFilterTriggered = (state) =>
  state.streaming.reloadAsFilterTriggered;
export const getWSSConnections = (state) => state.streaming.wssConnections;
export const getLiveStreams = (state) => state.streaming.liveStreams;
export const getRemoteStreams = (state) => state.streaming.remoteStreams;
export const getOfferRecivedStatus = (state) => state.streaming.offerRecieved;
export const getCodecNotSupported = (state) =>
  state.streaming.codecNotSupported;
export const getPlaybackBufferMeta = (state) =>
  state.streaming.playbackBufferMeta;
export const getMaxPeerCountForDevice = (state) =>
  state.streaming.maxPeerCountReached;
export const getIsLiveViewEntitlementExist = (state) =>
  state.streaming.isLiveViewEntitlementExist;
export const getIsLiveViewEntitlementExpired = (state) =>
  state.streaming.isLiveViewEntitlementExpired;
export const getChannelTimeStamp = (state) => state.streaming.channelTimeStamp;
export const getCVRStartDate = (state) => state.streaming.cvrStartDate;
export const getIsReloadedStream = (state) => state.streaming.isReloadedStream;
export const getNoSnapshotError = (state) => state.streaming.noSnapshotError;
export const getIsOnMultiCameraViewPage = (state) =>
  state.streaming.isOnMultiCameraViewPage;
export const getEndOfStream = (state) => state.streaming.endOfStream;
export const getStremBitmapsData = (state) => state.streaming.bitmapObjects;
export const getRecievedOffers = (state) => state.streaming.receivedOffers;
export const getStreamLoader = (state) => state.streaming.streamLoader;
export const getElementUpdated = (state) => state.streaming.elementUpdated;
export const getMultiLiveStreamLoader = (state) =>
  state.streaming.multiLiveStreamLoader;
export const getPlaybackBackActiveTime = (state) =>
  state.streaming.playbackActiveTime;
export const getIsFetchLicensesDataAgain = (state) =>
  state.streaming.isFetchLicensesDataAgain;
export const getLicenseAndDeviceData = (state) =>
  state.streaming.licenseAndDeviceData;
export const getIsAudioSettingsChanged = (state) =>
  state.streaming.isAudioSettingsChanged;
export default streaming.reducer;
