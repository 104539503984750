import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import {
  Box,
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  Zoom,
} from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

// Third Party Libraries
import axios from 'axios';
import moment from 'moment';
import ReactPlayer from 'react-player';
import 'moment-timezone';

// Data Stores
// import { useCustomerOrgDevices } from '../../store/CustomerOrgDevicesStore';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { useCustomerOrgUsers } from '../../store/CustomerOrgUsersStore';
import { useCustomerOrgClips } from '../../store/CustomerOrgClipsStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { usePoliciesStore } from '../../store/policiesStore';
import {
  fetchCustomerOrgDevices,
  getCustomerOrgDevices,
} from '../../store/CustomerOrgDevicesStoreIDB';
import { getCustomerOrgData } from '../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';

// Components
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import { constants, Utils } from '../../helpers';
import {
  ContextMenu,
  Header,
  PrimaryButton,
  SharedWith,
  SiteModal,
  SiteToast,
  Spinner,
  Tags,
} from '../../components/common';
import { Share } from '../clips';
import NewIncident from '../incidents/modalForms/NewIncident';
import ClipDetailsLogs from './ClipDetailsLogs';

// Custom Icons
import {
  HiArrowLeft,
  HiDotsVertical,
  HiOutlineArrowCircleUp,
  HiOutlinePlusSm,
  HiOutlineShare,
} from 'react-icons/hi';
import { GoArrowLeft } from 'react-icons/go';
import { ExclamationCircleWhite } from '../../assets/images';
import { ReactComponent as CheckboxDark } from '../../assets/images/icons/CheckboxDark.svg';
import { ReactComponent as CheckedIconDisabled } from '../../assets/images/icons/CheckedIconDisabled.svg';
import { ReactComponent as UpArrowWhite } from '../../assets/images/icons/ChevronUpWhite.svg';
import { ReactComponent as EditIcon } from '../../assets/images/icons/Pencil.svg';
import { ReactComponent as TrashCanIcon } from '../../assets/images/icons/TrashCan.svg';

// Custom styles (SASS files)
import './ClipDetails.scss';

const MAX_COMMENT_NUM_CHARACTERS = 250;

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip
    TransitionComponent={Zoom}
    {...props}
    classes={{ popper: className }}
  />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 218,
    marginLeft: '-3px',
    backgroundColor: '#000',
  },
});

const ClipDetails = ({ location }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const [downloadButtonLoader, setDownloadButtonLoader] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [hasData, setHasData] = useState(false);
  const [currentClip, setCurrentClip] = useState(null);
  const [currentClipWithoutOwner, setCurrentClipWithoutOwner] = useState(null);
  const [currentComment, setCurrentComment] = useState(null);
  const [editCommentId, setEditCommentId] = useState(null);
  const [editComment, setEditComment] = useState(null);
  const [clipDevice, setClipDevice] = useState(null);
  const [clipLocationName, setClipLocationName] = useState(null);
  const [clipAreaName, setClipAreaName] = useState(null);
  const [clipTimezone, setClipTimezone] = useState(null);
  const [clipLocalizedStartTime, setClipLocalizedStartTime] = useState(null);
  const [isClipShared, setIsClipShared] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isClipDeleted, setIsClipDeleted] = useState(false);
  const [isClipAttachedToIncident, setIsClipAttachedToIncident] =
    useState(false);
  const [showCurrentCommentSubmitting, setShowCurrentCommentSubmitting] =
    useState(false);
  const [showDeleteClipModal, setShowDeleteClipModal] = useState(false);
  const [showShareVideoClipModal, setShowShareVideoClipModal] = useState(false);
  const [showNewIncidentModal, setShowNewIncidentModal] = useState(false);
  const [isNewIncidentFormComplete, setIsNewIncidentFormComplete] =
    useState(false);
  const [submitNewIncidentForm, setSubmitNewIncidentForm] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [incidents, setIncidents] = useState([]);
  const [incidentSelections, setIncidentSelections] = useState([
    constants.CLIP_DETAILS_ADD_TO_INCIDENT_LABEL,
  ]);
  const [clipCommenters, setClipCommenters] = useState([]);
  const [selectedOption, setSelectedOption] = useState(
    constants.CLIP_DETAILS_ADD_TO_INCIDENT_LABEL,
  );
  const [noAccess, setNoAccess] = useState(false);

  const customerOrgLocationsData = useCustomerOrgLocations(
    (state) => state.customerOrgLocationsData,
  );
  const [customerOrgDevices, setCustomerOrgDevices] = useState([]);
  const getLoggedInUserPolicies = usePoliciesStore(
    (state) => state.getLoggedInUserPolicies,
  );

  const currentUserPolicies = getLoggedInUserPolicies();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [orgDetails, setOrgDetails] = useState();
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData,
  );
  const custOrgUsers = useCustomerOrgUsers(
    (state) => state.customerOrgUsersData,
  );
  const { customerOrgClipsData, setCustomerOrgClipsData } =
    useCustomerOrgClips();

  const commentRef = useRef();
  const clipDetailsRef = useRef();

  const origin = searchParams.get('o');
  const clipId = searchParams.get('clipId');

  const haveViewLogsAccess =
    currentUserPolicies?.manage_all_clips ||
    (currentUserPolicies?.view_clip_logs &&
      currentClip?.subscribers.find(
        (subscriber) =>
          subscriber?.sharedByAccountId === loggedInUserData.accountId,
      ) &&
      loggedInUserData?.canShareAndDownload);

  const haveSharingAccess =
    currentUserPolicies?.manage_all_clips ||
    (currentUserPolicies?.share_video_clip &&
      currentClip?.subscribers.find(
        (subscriber) =>
          subscriber?.sharedByAccountId === loggedInUserData.accountId,
      ));

  const haveDownloadAccess =
    currentUserPolicies?.manage_all_clips ||
    (currentUserPolicies?.download_video_clip &&
      currentClip?.subscribers.find(
        (subscriber) =>
          subscriber?.sharedByAccountId === loggedInUserData.accountId,
      ) &&
      loggedInUserData?.canShareAndDownload);

  const clipDetailsContextMenu = [
    {
      id: 'deleteClip',
      icon: <TrashCanIcon />,
      text: constants.CLIP_DETAILS_CONTEXT_MENU_MAIN_DELETE_CLIP_OPTION,
      clickHandler: (e, data) => {
        setShowDeleteClipModal(true);
      },
    },
  ];

  const clipCommentContextMenu = [
    {
      id: 'edit',
      icon: <EditIcon />,
      text: constants.CLIP_DETAILS_CONTEXT_MENU_COMMENT_EDIT_OPTION,
      clickHandler: (e, data) => {
        if (!data) return;
        setIsEditMode(true);
        setEditCommentId(data);
      },
    },
    {
      id: 'delete',
      icon: <TrashCanIcon />,
      text: constants.CLIP_DETAILS_CONTEXT_MENU_COMMENT_DELETE_OPTION,
      clickHandler: (e, data) => {
        if (!data) return;
        // Delete comment
        handleDeleteComment(data);
      },
    },
  ];

  const disabledOptions = {
    field: 'deleteClip',
    shouldDisable: (optionIndex) => {
      if (
        isNaN(optionIndex) ||
        optionIndex === null ||
        optionIndex === undefined
      )
        return false;
      if (optionIndex === 0) return true;
    },
  };

  const disabledChatOptions = {
    field: 'delete',
    shouldDisable: (optionIndex) => {
      if (
        isNaN(optionIndex) ||
        optionIndex === null ||
        optionIndex === undefined
      )
        return false;
      if (optionIndex === 1) return true;
    },
  };

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setOrgDetails(orgs?.[0] || {});
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000,
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        // await debouncedLoadCustomerOrgData();
        await loadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    loadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [loadCustomerOrgData]);

  useEffect(() => {
    fetchDevices();

    // Remove any shareed clip local storage keys
    localStorage.removeItem('sharedClipParams');
  }, []);

  useEffect(() => {
    if (orgDetails?.orgId) {
      if (customerOrgDevices?.length < 1) {
        fetchDevices();
      }
      fetchData();
    }
  }, [orgDetails?.orgId, customerOrgDevices?.length]);

  const getLocationArea = (locationId, areaId) => {
    if (!locationId || !areaId) return;

    const location = customerOrgLocationsData.find(
      (orgLocation) => orgLocation.locationId === locationId,
    );

    if (location) {
      setClipLocationName(location.locationName);
      const area = location.areas.find((area) => area.areaId === areaId);
      if (area) {
        setClipAreaName(area.areaName);
      }
    }
  };

  const fetchDevices = async () => {
    let custOrgDevices = await getCustomerOrgDevices();

    if (custOrgDevices.length < 1) {
      await fetchCustomerOrgDevices(`device/orgs/${orgDetails?.orgId}/devices`);
      custOrgDevices = await getCustomerOrgDevices();
    }

    setCustomerOrgDevices([...custOrgDevices]);
  };

  const fetchClipById = async (clipId) => {
    try {
      let clipData, subscribers, commentOwner;

      if (!clipId) return;

      const res = await axios.get(
        `/clip/orgs/${orgDetails?.orgId}/clips/${clipId}`,
        Utils.requestHeader(),
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        clipData = responseData.data;
        const clipWithoutOwner = JSON.parse(JSON.stringify(clipData));
        fetchCameraName(clipData);

        // Determine if the clip is currently shared with others
        setIsClipShared(clipData?.subscribers.length > 1);

        // Remove current user from subscribers list
        subscribers = clipData?.subscribers.filter(
          (subscriber) => subscriber.accountId !== loggedInUserData?.accountId,
        );

        if (subscribers.length > 0) {
          clipWithoutOwner.subscribers = [...subscribers];
        } else {
          clipWithoutOwner.subscribers = [];
        }
        setCurrentClipWithoutOwner(clipWithoutOwner);

        // Get the clip's timezone using the device ID
        const clipDevice = customerOrgDevices.find(
          (device) => device?.deviceId === clipData?.deviceId,
        );
        let deviceClipTimezone;

        // Get local start time for clip based on device's timezone
        if (clipDevice) {
          if (clipDevice?.properties?.timezone) {
            deviceClipTimezone =
              clipDevice?.properties?.timezone || moment.tz.guess();
          } else {
            const clipLocation = customerOrgLocationsData.find(
              (orgLocation) => orgLocation.locationId === clipData?.locationId,
            );

            const getLocationSelected = Utils.getTimezone(
              clipLocation.timezone,
            );

            deviceClipTimezone =
              getLocationSelected?.location || moment.tz.guess();
          }

          setClipTimezone(deviceClipTimezone || moment.tz.guess());
          setClipLocalizedStartTime(
            moment(clipData?.startTime * 1000)
              .tz(deviceClipTimezone || moment.tz.guess())
              .format('LTS z'),
          );

          if (clipData?.incidentIds?.length > 0) {
            setIncidentSelections([...clipData?.incidentIds]);
          }
        } else {
          setClipTimezone(moment.tz.guess());
          setClipLocalizedStartTime(
            moment(clipData?.startTime * 1000)
              .tz(moment.tz.guess())
              .format('LTS z'),
          );
        }

        setCurrentClip(clipData);

        if (
          Array.isArray(clipData?.incidentIds) &&
          clipData.incidentIds.length > 0
        ) {
          setSelectedOption(clipData.incidentIds[0]);
          setIncidentSelections([...clipData.incidentIds]);
        }

        // Check if clip is attached to at least one incident
        if (
          Array.isArray(clipData?.incidentIds) &&
          clipData.incidentIds.length > 0
        ) {
          setIsClipAttachedToIncident(true);
        }
      } else {
        if (responseData?.meta?.code === 3236) {
          setNoAccess(true);
          setHasData(false);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        } else if (res?.code) {
          Utils.vmsLogger().error(`${res.code}: ${res.message}`);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };

  const fetchIncidents = async () => {
    try {
      let filteredIncidents;

      const res = await axios.get(
        `/incident/orgs/${orgDetails?.orgId}/incidents`,
        Utils.requestHeader(),
      );
      const responseData = res?.data;

      // Set incidents if request is successful
      if (responseData?.meta?.code === 200) {
        filteredIncidents = responseData?.data?.incidents.filter(
          (incident) =>
            incident.status === 'OPEN' || incident.status === 'REOPENED',
        );
        // Sort incidents by incident summary in ascending order
        // and then save to state
        setIncidents([
          ...filteredIncidents.sort((a, b) => {
            if (a?.summary?.toLowerCase() > b?.summary?.toLowerCase()) {
              return 1;
            }

            if (a?.summary?.toLowerCase() < b?.summary?.toLowerCase()) {
              return -1;
            }

            return 0;
          }),
        ]);
      } else {
        if (res?.code) {
          Utils.vmsLogger().error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };

  const fetchComments = async (clipId) => {
    let comments;
    try {
      if (!clipId) return;

      const res = await axios.get(
        `/clip/orgs/${orgDetails?.orgId}/clips/${clipId}/comments`,
        Utils.requestHeader(),
      );
      const responseData = res?.data;

      // Set comments if request is successful
      if (responseData?.meta?.code === 200) {
        comments = responseData?.data?.comments;
      } else {
        if (res?.code) {
          Utils.vmsLogger().error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    } finally {
      return comments;
    }
  };

  const fetchAccountDetails = async (accountId) => {
    let accountDetails;

    if (!accountId) {
      return;
    }

    try {
      accountDetails = custOrgUsers.find(
        (user) => user.accountId === accountId,
      );
    } catch (error) {
      Utils.vmsLogger().error(error);
    } finally {
      return accountDetails;
    }
  };

  const fetchCameraName = async (clip) => {
    try {
      const targetDevice = customerOrgDevices.find(
        (orgDevice) => orgDevice.deviceId === clip?.deviceId + '',
      );

      if (clip) {
        getLocationArea(clip.locationId, clip.areaId);
        setClipDevice(targetDevice);
      } else {
        throw new Error('ERROR: could not retrieve device name');
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };

  const fetchData = async () => {
    try {
      setShowLoading(true);
      fetchClipById(clipId);
      fetchIncidents();
      const commentsData = await fetchComments(clipId);
      const commentsPromises = commentsData?.map(async (comment) => {
        const accountData = await fetchAccountDetails(comment?.accountId);
        return {
          commenterComment: {
            ...comment,
          },
          ...accountData,
        };
      });

      if (commentsPromises) {
        const commenters = await Promise.all(commentsPromises);
        setClipCommenters([...commenters]);
      }
      setHasData(true);
    } catch (error) {
      Utils.vmsLogger().error(error);
      setHasData(false);
    } finally {
      setShowLoading(false);
    }
  };

  const updateSubscribers = (subscribers, mode = 'GRANT') => {
    let currentSubscribers = [],
      mergedSubscribers,
      subscriberIndex;

    if (!Array.isArray(subscribers)) return;

    currentSubscribers = [...currentClip?.subscribers];

    if (mode.toUpperCase() === 'GRANT') {
      mergedSubscribers = currentSubscribers.concat(subscribers);
    } else {
      // Find the subscriber with the revoked access
      subscriberIndex = currentSubscribers.findIndex(
        (subscriber) => subscriber.email === subscribers[0].email,
      );

      if (subscriberIndex !== -1) {
        // Update the subscriber with revoked access
        currentSubscribers[subscriberIndex] = subscribers[0];
      }

      mergedSubscribers = [...currentSubscribers];
    }

    const updatedCurrentClip = {
      ...currentClip,
      subscribers: mergedSubscribers,
    };

    setCurrentClip(updatedCurrentClip);
  };

  const handleDeleteClip = async () => {
    try {
      if (!currentClip || !currentClip.clipId) {
        return;
      }
      setShowLoader(true);
      const res = await axios.delete(
        `/clip/orgs/${orgDetails?.orgId}/clips/${currentClip.clipId}`,
        Utils.requestHeader(),
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        setIsClipDeleted(true);
        setShowDeleteClipModal(false);

        const updatedClips = customerOrgClipsData[
          loggedInUserData?.accountId
        ].filter(
          (customerOrgClip) => customerOrgClip?.clipId !== currentClip?.clipId,
        );

        updatedClips.sort((clipA, clipB) => {
          if (clipA?.createdDate < clipB?.createdDate) {
            return 1;
          } else if (clipA?.createdDate > clipB?.createdDate) {
            return -1;
          }
          return 0;
        });

        await new Promise((resolve) => {
          setCustomerOrgClipsData(
            [...updatedClips],
            loggedInUserData?.accountId,
          );
          resolve();
        });

        navigate('/clips/list.html');
      } else {
        if (res?.code) {
          Utils.vmsLogger().error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleSubmitComment = async () => {
    try {
      if (!currentComment) {
        return;
      }

      setShowCurrentCommentSubmitting(true);
      const commentInputRef = commentRef?.current;
      const reqBody = {
        comment: currentComment,
      };

      const res = await axios.post(
        `/clip/orgs/${orgDetails?.orgId}/clips/${currentClip.clipId}/comments`,
        reqBody,
        Utils.requestHeader(),
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        const updatedCommenters = [...clipCommenters];
        updatedCommenters.push(responseData.data);

        const commentsPromises = updatedCommenters?.map(async (comment) => {
          let returnValue;
          const accountData = await fetchAccountDetails(comment?.accountId);

          if (comment.commenterComment) {
            returnValue = comment;
          } else {
            returnValue = {
              commenterComment: {
                ...comment,
              },
              ...accountData,
            };
          }

          return returnValue;
        });

        if (commentsPromises) {
          const commenters = await Promise.all(commentsPromises);
          setClipCommenters([...commenters]);
          setCurrentComment('');
        }
      } else {
        if (res?.code) {
          Utils.vmsLogger().error(`${res.code}: ${res.message}`);
          setCurrentComment(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
          setCurrentComment(responseData?.data?.userMsg);
        }
        commentInputRef?.classList?.add('error');
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    } finally {
      setShowCurrentCommentSubmitting(false);
    }
  };

  const handleEditComment = async () => {
    try {
      if (!editCommentId) return;

      const reqBody = {
        comment: editComment,
      };

      const res = await axios.put(
        `/clip/orgs/${orgDetails?.orgId}/clips/${currentClip.clipId}/comments/${editCommentId}`,
        reqBody,
        Utils.requestHeader(),
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        const currentCommenters = [...clipCommenters];
        const commentIndex = currentCommenters.findIndex(
          (comment) => comment?.commenterComment?.commentId === editCommentId,
        );
        const updatedComment = responseData.data;

        const accountData = await fetchAccountDetails(
          updatedComment?.accountId,
        );

        const updatedCommenter = {
          commenterComment: {
            ...updatedComment,
          },
          ...accountData,
        };

        if (commentIndex !== -1) {
          currentCommenters[commentIndex] = updatedCommenter;
          setClipCommenters([...currentCommenters]);
          setIsEditMode(false);
          setEditCommentId(null);
          setEditComment('');
        }
      } else {
        if (res?.code) {
          Utils.vmsLogger().error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      if (!commentId) return;

      const res = await axios.delete(
        `/clip/orgs/${orgDetails?.orgId}/clips/${currentClip.clipId}/comments/${commentId}`,
        Utils.requestHeader(),
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        const currentClipCommenters = [...clipCommenters];
        const commentIndex = currentClipCommenters.findIndex(
          (clipCommenter) =>
            clipCommenter?.commenterComment?.commentId === commentId,
        );

        if (commentIndex !== -1) {
          currentClipCommenters.splice(commentIndex, 1);
          setClipCommenters([...currentClipCommenters]);
        }
      } else {
        if (res?.code) {
          Utils.vmsLogger().error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };

  const handleDownload = async () => {
    if (!currentClip) {
      throw new Error('Clip ID is missing');
    }

    const response = await axios.get(
      `clip/orgs/${orgDetails?.orgId}/clips/${clipId}/download/v2`,
      Utils.requestHeader(),
    );

    const responseData = response?.data;
    if (responseData?.meta?.code === 200) {
      if (responseData.data?.url) {
        setDownloadButtonLoader(true);
        // Retrieve the video clip from remote server
        fetch(responseData.data?.url)
          .then((response) => response.blob())
          .then((blob) => {
            // Create a URL based on the binary data of the video clip
            const url = URL.createObjectURL(blob);
            // Create a temporary anchor element with the download URL
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `video-clip_${moment(currentClip.startTime * 1000)
                .tz(clipTimezone)
                .format('YYYY-MM-DD_HH-mm-ss_A_z')}.mp4`,
            );
            // Simulate a click event to trigger the browser's download prompt
            document.body.appendChild(link);
            link.click();
            // Remove the temporary element
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            Utils.vmsLogger().error(error.message || error);
          })
          .finally(() => {
            setDownloadButtonLoader(false);
          });
      }
    }
  };

  const handleSelectIncident = async (incidentId) => {
    try {
      if (!currentClip || !incidentId) return;

      const reqBody = {
        deviceId: currentClip.deviceId,
        clipStartTime: currentClip.startTime,
        clipEndTime: currentClip.endTime,
        clipId: currentClip.clipId,
      };

      const res = await axios.post(
        `/incident/orgs/${orgDetails?.orgId}/incidents/${incidentId}/clips`,
        reqBody,
        Utils.requestHeader(),
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        // Ensure the clip is marked as having an association
        // with an incident
        setIsClipAttachedToIncident(true);
      } else {
        if (res?.code) {
          Utils.vmsLogger().error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };

  const handleAddClipTags = async (tagIds) => {
    try {
      if (!Array.isArray(tagIds) || tagIds.length < 1) {
        return;
      }

      const reqBody = {
        tags: tagIds,
      };

      const res = await axios.post(
        `/clip/orgs/${orgDetails?.orgId}/clips/${clipId}/tags`,
        reqBody,
        Utils.requestHeader(),
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        const currentClipData = currentClip;
        const updatedClipData = responseData?.data;

        if (Array.isArray(updatedClipData?.tags)) {
          currentClipData.tags = [...updatedClipData.tags];

          setCurrentClip(currentClipData);
        }
      } else {
        if (responseData?.meta?.code) {
          Utils.vmsLogger().error(
            `${responseData?.meta?.code}: ${responseData?.meta?.message}`,
          );
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };

  const handleRemoveClipTag = async (tagId) => {
    try {
      if (!tagId) {
        return;
      }

      const res = await axios.delete(
        `/clip/orgs/${orgDetails?.orgId}/clips/${clipId}/tags/${tagId}`,
        Utils.requestHeader(),
      );
      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        const clipData = responseData?.data;

        if (clipData?.success) {
          const currentClipData = currentClip;
          const deletedTagIndex = currentClipData?.tags?.indexOf(tagId);

          if (!isNaN(deletedTagIndex) && deletedTagIndex >= 0) {
            currentClipData?.tags?.splice(deletedTagIndex, 1);
            setCurrentClip(currentClipData);
          }
        }
      } else {
        if (responseData?.meta?.code) {
          Utils.vmsLogger().error(
            `${responseData?.meta?.code}: ${responseData?.meta?.message}`,
          );
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };

  const renderIncidentsList = () => {
    return (
      <FormControl
        className={`incidents-list-form-control ${
          selectedOption !== constants.CLIP_DETAILS_ADD_TO_INCIDENT_LABEL
            ? `wider`
            : ''
        }`}
        InputLabelProps={{ shrink: false }}
        fullWidth
      >
        <InputLabel
          id="incident-select-input-label"
          className="incident-select-label"
        >
          <span>{constants.CLIP_DETAILS_ADD_TO_INCIDENT_LABEL}</span>
        </InputLabel>
        <Select
          labelId={
            incidentSelections.length > 0
              ? 'added-to-incident-select'
              : 'add-to-incident-select'
          }
          MenuProps={{
            anchorOrigin: {
              vertical: 'auto',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
          className={`incident-select ${
            incidentSelections.length > 0 ? 'added-to-incident' : ''
          }`}
          value={incidentSelections}
          onChange={(e) => {
            if (!e) return;

            let currentSelections = [...incidentSelections];
            if (
              currentSelections.indexOf(
                constants.CLIP_DETAILS_ADD_TO_INCIDENT_LABEL,
              ) !== -1
            ) {
              currentSelections.splice(
                currentSelections.indexOf(
                  constants.CLIP_DETAILS_ADD_TO_INCIDENT_LABEL,
                ),
                1,
              );
            }

            const {
              target: { value },
            } = e;

            if (!value || value.toUpperCase() === 'NEW') return;

            currentSelections.push(value);
            setIncidentSelections([...currentSelections]);

            setSelectedOption(value);
            handleSelectIncident(value);
          }}
          IconComponent={() =>
            incidentSelections.includes(
              constants.CLIP_DETAILS_ADD_TO_INCIDENT_LABEL,
            ) ? (
              <UpArrowWhite
                className="incident-select-icon"
                color={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--primary_64')}
                size={'0.6em'}
              />
            ) : null
          }
          renderValue={() =>
            incidentSelections.includes(
              constants.CLIP_DETAILS_ADD_TO_INCIDENT_LABEL,
            ) ? (
              <div className="incident-select-add-to-incident-value">
                {constants.CLIP_DETAILS_ADD_TO_INCIDENT_LABEL}
              </div>
            ) : (
              <div className="incident-select-added-to-incident-value">
                {constants.CLIP_DETAILS_ADDED_TO_INCIDENT_LABEL}
              </div>
            )
          }
        >
          {Array.isArray(incidents) &&
            incidents.map((incident) => {
              return (
                <MenuItem
                  key={incident?.incidentId}
                  className="incident-item-wrapper"
                  value={incident?.incidentId}
                >
                  <Checkbox
                    checked={
                      incidentSelections?.indexOf(incident?.incidentId) > -1
                    }
                    disabled={
                      incidentSelections?.indexOf(incident?.incidentId) > -1
                    }
                    icon={<CheckboxDark />}
                    checkedIcon={
                      <CheckedIconDisabled className="checked-icon" />
                    }
                    size="small"
                  />
                  {incident?.summary?.length > 31 ? (
                    <CustomWidthTooltip
                      title={incident?.summary}
                      placement="bottom-start"
                    >
                      <ListItemText
                        className="incident-item-name"
                        primary={incident?.summary}
                        disableTypography
                        sx={{
                          fontSize: '14px',
                          lineHeight: '14px',
                          fontWeight: 500,
                        }}
                      />
                    </CustomWidthTooltip>
                  ) : (
                    <ListItemText
                      className="incident-item-name"
                      primary={incident?.summary}
                      disableTypography
                      sx={{
                        fontSize: '14px',
                        lineHeight: '14px',
                        fontWeight: 500,
                      }}
                    />
                  )}
                </MenuItem>
              );
            })}
          <MenuItem
            key="clip-new-incident"
            className="incident-item-wrapper-sticky"
            value="new"
            onClick={(e) => {
              e.preventDefault();
              setShowNewIncidentModal(true);
            }}
          >
            <HiOutlinePlusSm />
            <span className="incident-item-wrapper-sticky-label">
              {constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_CREATE_NEW_LABEL}
            </span>
          </MenuItem>
        </Select>
      </FormControl>
    );
  };

  return (
    <div className="App clip-details">
      <Header />
      <Container
        ref={clipDetailsRef}
        className="container-top-offset clip-details-container mw-100"
        fluid
      >
        <Row className="mb-5">
          <Col>
            <Container className="mw-100">
              {showLoading ? (
                <Spinner
                  height="25"
                  width="25"
                  strokeColor={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--brand_white')}
                  strokeWidth="5"
                  fill="none"
                />
              ) : hasData && !noAccess ? (
                <div className="page-header mt-4 mb-5">
                  {origin?.toUpperCase() === 'CLIPS' && (
                    <Row xs={1} md={2} className="g-4">
                      <div
                        className="back-link"
                        onClick={(e) => {
                          e?.preventDefault();
                          navigate('/clips/list.html');
                        }}
                      >
                        <HiArrowLeft size={'0.975rem'} />
                        <span className="back-link-label">
                          {constants.CLIP_DETAILS_BACK_BUTTON_LABEL}
                        </span>
                      </div>
                    </Row>
                  )}
                  <Row xs={1} md={2} className="g-4">
                    <Col md={6} lg={6} xl={6} xs={12} className="left-align">
                      <div className="page-title">
                        <div className="clip-camera-time">
                          {`${
                            clipDevice?.deviceName ||
                            constants.CLIP_DETAILS_UNKNOWN_DEVICE
                          } - ${clipLocalizedStartTime}`}
                        </div>
                        <div className="clip-location">
                          {`${
                            clipAreaName || constants.CLIP_DETAILS_UNKNOWN_AREA
                          } - ${
                            clipLocationName ||
                            constants.CLIP_DETAILS_UNKNOWN_LOCATION
                          }`}
                        </div>
                      </div>
                    </Col>
                    <Col md={6} lg={6} xl={6} xs={12} className="text-end">
                      <div className="clip-details-top-cta-wrapper">
                        {currentClipWithoutOwner?.subscribers?.length > 0 && (
                          <div>
                            <SharedWith
                              orgId={orgDetails?.orgId}
                              subscribers={currentClipWithoutOwner?.subscribers}
                              showLabel={true}
                            />
                          </div>
                        )}
                        {currentUserPolicies?.share_video_clip &&
                          haveSharingAccess && (
                            <Button
                              variant="outline-primary"
                              size="md"
                              className="share-btn"
                              onClick={() => setShowShareVideoClipModal(true)}
                            >
                              <HiOutlineShare
                                className="share-btn-icon"
                                size={'1.2rem'}
                              />
                              <span className="share-button-label">
                                {constants.CLIP_DETAILS_SHARE_BUTTON_LABEL}
                              </span>
                            </Button>
                          )}
                        <Button
                          variant="outline-primary"
                          size="md"
                          className="contextual-btn"
                          onClick={() => {}}
                        >
                          <ContextMenu
                            iconButton={
                              <HiDotsVertical
                                className="clip-details-menu-icon"
                                size={'1.25rem'}
                              />
                            }
                            menuId={'clip-details-menu'}
                            menuItems={clipDetailsContextMenu}
                            menuMinWidth="152px"
                            customItemTextClass="clip-details-menu-item-option"
                            customData={null}
                            disabledOptions={disabledOptions}
                            externalDisableCondition={
                              !currentUserPolicies?.manage_all_clips &&
                              (!currentUserPolicies?.delete_video_clip ||
                                !currentClip?.subscribers.find(
                                  (subscriber) =>
                                    subscriber?.sharedByAccountId ===
                                    loggedInUserData.accountId,
                                ))
                            }
                          />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="toast-wrapper">
                      <SiteToast
                        customCss="clip-details-toast"
                        position="top-end"
                        show={showToast}
                        title="Uh-oh!"
                        body={userMsg}
                        delay={5000}
                      />
                    </div>
                  </Row>
                  <Row>
                    <Col>
                      <div className="clips-container mt-sm-3 mt-xs-4">
                        <Row className="mb-3">
                          <Col md={8} sm={6}>
                            <div className="mb-1 clip-details-no-video-container">
                              {currentClip?.status?.toUpperCase() ===
                              'VIDEO_PROCESSING' ? (
                                <div className="clip-details-video-processing-container">
                                  <div className="clip-details-video-processing-alert-wrapper">
                                    <div className="clip-details-video-processing-alert-content">
                                      <div className="clip-details-video-processing-alert-icon">
                                        <img
                                          src={ExclamationCircleWhite}
                                          width={24}
                                          height={24}
                                          alt="Clip Video Processing Alert Icon"
                                        />
                                      </div>
                                      <div className="clip-details-video-processing-alert-text">
                                        {
                                          constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_VIDEO_PROCESSING_TITLE
                                        }
                                      </div>
                                      <div
                                        className="clip-details-video-processing-alert-message"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          fetchClipById(currentClip?.clipId);
                                        }}
                                      >
                                        {constants.REFRESH_BUTTON_TEXT}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <ReactPlayer
                                  className="clip-video-player"
                                  url={currentClip?.clip?.url}
                                  controls={true}
                                  config={{
                                    file: {
                                      attributes: {
                                        controlsList: 'nodownload',
                                        disablePictureInPicture: true,
                                      },
                                    },
                                  }}
                                  onContextMenu={(e) => e.preventDefault()}
                                  width="100%"
                                  height="100%"
                                />
                              )}
                            </div>
                          </Col>
                          <Col md={4} sm={6}>
                            <div className="clip-details-comments-section">
                              <div className="comment-creator-wrapper">
                                <div className="comment-initiator-text">
                                  {
                                    constants.CLIP_DETAILS_COMMENT_CREATED_BY_TEXT
                                  }{' '}
                                  <span className="comment-creator-name">
                                    {`${
                                      clipCommenters.length > 0
                                        ? clipCommenters[0]?.firstName
                                        : ''
                                    }`}
                                  </span>
                                </div>
                                <div className="comment-initiator-text">
                                  <span className="comment-create-date">
                                    {`${moment(currentClip?.createdDate)
                                      ?.tz(clipTimezone || moment.tz.guess())
                                      ?.format('LL')} - ${moment(
                                      currentClip?.createdDate,
                                    )
                                      ?.tz(clipTimezone || moment.tz.guess())
                                      ?.format('LTS z')} 
                                    `}
                                  </span>
                                </div>
                                <div className="comment-item-wrapper">
                                  {Array.isArray(clipCommenters) &&
                                    clipCommenters.map(
                                      (commenter, commenterIndex) => (
                                        <div
                                          className="comment-item"
                                          key={`commenter-${commenterIndex}`}
                                        >
                                          <div className="comment-item-avatar-wrapper">
                                            <div className="avatar avatar-1 avatar-no-offset">
                                              {`${commenter?.firstName?.charAt(
                                                0,
                                              )}${commenter?.lastName?.charAt(
                                                0,
                                              )}`}
                                            </div>
                                          </div>
                                          <div className="comment-item-author-wrapper">
                                            <div className="comment-author">
                                              {commenter?.name}
                                              <span className="comment-create-datetime">
                                                {Utils.getElapsedTime(
                                                  commenter?.commenterComment
                                                    ?.createdDate,
                                                  moment().valueOf(),
                                                )}
                                              </span>
                                            </div>
                                            {isEditMode &&
                                            editCommentId ===
                                              commenter.commenterComment
                                                .commentId ? (
                                              <div className="comment-edit-form">
                                                <div className="comment-edit-form-content">
                                                  <OutlinedInput
                                                    className="comment-edit-form-input-field"
                                                    type="text"
                                                    maxRows={2}
                                                    inputProps={{
                                                      maxLength:
                                                        MAX_COMMENT_NUM_CHARACTERS,
                                                    }}
                                                    notched="true"
                                                    defaultValue={
                                                      commenter
                                                        ?.commenterComment
                                                        ?.comment
                                                    }
                                                    onChange={(e) => {
                                                      setEditComment(
                                                        e?.target?.value,
                                                      );
                                                    }}
                                                    fullWidth
                                                    multiline
                                                  />
                                                </div>
                                                <div className="comment-edit-form-button-group">
                                                  <Button
                                                    className="comment-edit-form-cancel-button"
                                                    variant="outlined-primary"
                                                    onClick={(e) => {
                                                      e?.preventDefault();
                                                      setEditCommentId(null);
                                                      setEditComment('');
                                                      setIsEditMode(false);
                                                    }}
                                                  >
                                                    {
                                                      constants.CLIP_DETAILS_COMMENT_EDIT_FORM_CANCEL_BUTTON_LABEL
                                                    }
                                                  </Button>
                                                  <Button
                                                    className="comment-edit-form-save-button"
                                                    onClick={(e) => {
                                                      e?.preventDefault();

                                                      handleEditComment();
                                                    }}
                                                  >
                                                    {
                                                      constants.CLIP_DETAILS_COMMENT_EDIT_FORM_SAVE_BUTTON_LABEL
                                                    }
                                                  </Button>
                                                </div>
                                              </div>
                                            ) : (
                                              <div className="comment-message">
                                                ”
                                                {
                                                  commenter?.commenterComment
                                                    ?.comment
                                                }
                                                ”
                                              </div>
                                            )}
                                          </div>
                                          <div className="comment-item-option-wrapper">
                                            {!isEditMode &&
                                              loggedInUserData?.accountId ===
                                                commenter?.accountId && (
                                                <ContextMenu
                                                  iconButton={
                                                    <HiDotsVertical className="comment-option" />
                                                  }
                                                  menuId={'clip-details-menu'}
                                                  menuItems={
                                                    clipCommentContextMenu
                                                  }
                                                  menuMinWidth="102px"
                                                  customItemTextClass="comment-item-text"
                                                  customData={
                                                    commenter?.commenterComment
                                                      ?.commentId
                                                  }
                                                  disabledOptions={
                                                    disabledChatOptions
                                                  }
                                                  externalDisableCondition={
                                                    commenterIndex === 0 ||
                                                    commenter?.accountId !==
                                                      loggedInUserData?.accountId
                                                  }
                                                />
                                              )}
                                          </div>
                                        </div>
                                      ),
                                    )}
                                </div>
                              </div>
                              <div className="comment-input-wrapper">
                                <FormControl width="100%" variant="outlined">
                                  <OutlinedInput
                                    ref={commentRef}
                                    className="comment-input-field"
                                    placeholder={
                                      constants.CLIP_DETAILS_COMMENT_INPUT_PLACEHOLDER
                                    }
                                    type="text"
                                    fullWidth="true"
                                    multiline="true"
                                    maxRows={3}
                                    inputProps={{
                                      maxLength: MAX_COMMENT_NUM_CHARACTERS,
                                    }}
                                    notched="true"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <HiOutlineArrowCircleUp
                                          className="comment-input-field-submit-icon"
                                          size={'20px'}
                                          onClick={(e) => {
                                            e?.preventDefault();
                                            handleSubmitComment();
                                          }}
                                        />
                                      </InputAdornment>
                                    }
                                    value={
                                      showCurrentCommentSubmitting
                                        ? constants.CLIP_DETAILS_COMMENT_INPUT_SUBMITTING
                                        : currentComment || ''
                                    }
                                    onChange={(e) => {
                                      setCurrentComment(e?.target?.value);
                                    }}
                                    onKeyDown={(e) => {
                                      if (e?.code === 'Enter') {
                                        handleSubmitComment();
                                      }
                                    }}
                                  />
                                </FormControl>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {/* Tags */}
                        <Row>
                          <Col md={8}>
                            <Row>
                              <Col md={7}>
                                <div className="clip-details-tags-title-wrapper">
                                  <span className="clip-details-tags-title">
                                    {constants.CLIP_DETAILS_TAGS_LABEL}
                                  </span>
                                </div>
                                <div className="mt-sm-3 mt-xs-4">
                                  <Tags
                                    variant="dark"
                                    tagCategory="CLIP"
                                    preSelectedTags={currentClip?.tags}
                                    addTagsCallback={handleAddClipTags}
                                    removeTagCallback={handleRemoveClipTag}
                                  />
                                </div>
                              </Col>
                              <Col
                                className="clip-details-button-column"
                                md={5}
                              >
                                <div className="clip-details-button-group">
                                  {haveDownloadAccess ? (
                                    <PrimaryButton
                                      className={`download-button ${
                                        selectedOption !==
                                        constants.CLIP_DETAILS_ADD_TO_INCIDENT_LABEL
                                          ? `wider`
                                          : ''
                                      }`}
                                      loader={downloadButtonLoader}
                                      variant="outline-primary"
                                      color={getComputedStyle(
                                        document.documentElement,
                                      ).getPropertyValue('--primary_40')}
                                      backgroundColor="transparent"
                                      hoverBackgroundColor={getComputedStyle(
                                        document.documentElement,
                                      ).getPropertyValue('--primary_40')}
                                      borderWidth={'1px'}
                                      borderColor={getComputedStyle(
                                        document.documentElement,
                                      ).getPropertyValue('--primary_24')}
                                      hoverBorderWidth={0}
                                      borderRadius="10px"
                                      width="110px"
                                      onClick={(e) => {
                                        e?.preventDefault();
                                        handleDownload();
                                      }}
                                    >
                                      {
                                        constants.CLIP_DETAILS_DOWNLOAD_BUTTON_LABEL
                                      }
                                    </PrimaryButton>
                                  ) : null}
                                  {currentUserPolicies?.create_contribute_and_view_incidents ? (
                                    <div className="add-to-incidents-wrapper">
                                      {renderIncidentsList()}
                                    </div>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={4}>
                            <div>&nbsp;</div>
                          </Col>
                        </Row>
                        {/* Logs */}
                        {haveViewLogsAccess && (
                          <Row>
                            <Col md={8}>
                              <div className="mt-sm-3 mt-xs-4 clip-details-logs-title-wrapper">
                                <span className="clip-details-logs-title">
                                  {constants.CLIP_DETAILS_LOGS_LABEL}
                                </span>
                              </div>
                              <div className="mt-sm-3 mt-xs-4">
                                <ClipDetailsLogs
                                  clipId={clipId || currentClip?.clipId}
                                  parentRef={clipDetailsRef?.current}
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div>&nbsp;</div>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : (
                <div className="unknown-clip-container">
                  <Box className="unknown-clip-content">
                    <p className="unknown-clip-alert">
                      {constants.CLIP_DETAILS_CLIP_NO_LONGER_EXISTS}
                    </p>
                    <p className="unknown-clip-reason">
                      {constants.CLIP_DETAILS_NO_LONGER_EXISTS_REASON}
                    </p>
                    <div className="unknown-clip-btn-wrapper">
                      <PrimaryButton
                        className="unknown-clip-back-btn"
                        width="177"
                        onClick={(e) => {
                          e?.preventDefault();
                          navigate('/clips/list.html');
                        }}
                      >
                        <GoArrowLeft className="unknown-clip-back-btn-icon" />
                        <span className="unknown-clip-back-btn-label">
                          {
                            constants.CLIP_DETAILS_BACK_TO_CLIPS_PAGE_BUTTON_LABEL
                          }
                        </span>
                      </PrimaryButton>
                    </div>
                  </Box>
                </div>
              )}
            </Container>
          </Col>
        </Row>
      </Container>
      {/* Modals */}
      {/* Delete Clip  */}
      <SiteModal
        key={'delete-clip-modal'}
        classes="delete-clip-modal"
        wrapperClass="delete-clip-modal-wrapper"
        modalTitle="Delete Clip"
        showModal={showDeleteClipModal}
        hideModal={() => setShowDeleteClipModal(false)}
      >
        {isClipAttachedToIncident ? (
          <div className="delete-clip-confirmed-container">
            <div className="my-4 delete-clip-confirmed-message">
              {
                constants.CLIP_DETAILS_DELETE_CONFIRMATION_MODAL_CONFIRMED_MESSAGE
              }
            </div>
            <div className="delete-clip-confirmed-button-group">
              <PrimaryButton onClick={() => setShowDeleteClipModal(false)}>
                {constants.CLIP_DETAILS_DELETE_CONFIRMATION_CLOSE_BUTTON_LABEL}
              </PrimaryButton>
            </div>
          </div>
        ) : (
          <div className="delete-clip-confirmation-container">
            <div className="delete-clip-confirmation-message">
              <>
                {
                  constants.CLIP_DETAILS_DELETE_CONFIRMATION_MODAL_DELETE_MESSAGE_1A
                }
                <span className="delete-clip-confirmation-clip-identifier">{`${
                  clipDevice?.deviceName ||
                  constants.CLIP_DETAILS_UNKNOWN_DEVICE
                } - ${clipLocalizedStartTime}`}</span>
                {
                  constants.CLIP_DETAILS_DELETE_CONFIRMATION_MODAL_DELETE_MESSAGE_1B
                }{' '}
                {isClipShared ? (
                  <>
                    <span>
                      {
                        constants.CLIP_DETAILS_DELETE_CONFIRMATION_MODAL_OWNER_MESSAGE_1A
                      }
                    </span>{' '}
                    <span className="delete-clip-confirmation-message-emphasis">
                      {
                        constants.CLIP_DETAILS_DELETE_CONFIRMATION_MODAL_OWNER_MESSAGE_1B
                      }
                    </span>
                    .{' '}
                  </>
                ) : (
                  <>
                    <span>
                      {
                        constants.CLIP_DETAILS_DELETE_CONFIRMATION_MODAL_SUBSCRIBER_MESSAGE_1A
                      }
                    </span>{' '}
                    <span className="delete-clip-confirmation-message-emphasis">
                      {
                        constants.CLIP_DETAILS_DELETE_CONFIRMATION_MODAL_SUBSCRIBER_MESSAGE_1B
                      }
                    </span>
                    .{' '}
                  </>
                )}
                {
                  constants.CLIP_DETAILS_DELETE_CONFIRMATION_MODAL_DELETE_MESSAGE_2
                }
              </>
            </div>
            <div className="delete-clip-confirmation-button-group">
              <PrimaryButton
                className="delete-clip-confirmation-delete-btn"
                type="button"
                loader={showLoader}
                width="100%"
                height="56px"
                fontSize="1.125rem"
                lineHeight="24px"
                onClick={(e) => {
                  e?.preventDefault();
                  handleDeleteClip();
                }}
              >
                {constants.CLIP_DETAILS_DELETE_CONFIRMATION_DELETE_BUTTON_LABEL}
              </PrimaryButton>
              <PrimaryButton
                variant="outline-primary"
                className="delete-clip-confirmation-cancel-btn"
                height="56px"
                fontSize="1.125rem"
                lineHeight="24px"
                backgroundColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--brand_white')}
                borderColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--primary_40')}
                color={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--primary_40')}
                borderWidth="1.5px"
                hoverBorderWidth="1.5px"
                hoverColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--primary_40')}
                hoverBackgroundColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--brand_white')}
                hoverBorderColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--primary_40')}
                onClick={() => setShowDeleteClipModal(false)}
              >
                {constants.CLIP_DETAILS_DELETE_CONFIRMATION_CANCEL_BUTTON_LABEL}
              </PrimaryButton>
            </div>
          </div>
        )}
      </SiteModal>
      {/* Share Video Clip */}
      <SiteModal
        key={'share-video-clip-modal'}
        classes="share-video-clip-modal"
        wrapperClass="share-video-clip-modal-wrapper"
        modalTitle={constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_TITLE}
        showModal={showShareVideoClipModal}
        hideModal={() => setShowShareVideoClipModal(false)}
      >
        <Share
          clipId={currentClip?.clipId}
          grantCallback={(subscribers) => {
            updateSubscribers(subscribers);
            setShowShareVideoClipModal(false);
          }}
          revokeCallback={(updatedSubscriber) => {
            updateSubscribers([{ ...updatedSubscriber }], 'REVOKE');
          }}
          extendCallback={(updatedSubscriber) => {
            updateSubscribers([{ ...updatedSubscriber }], 'EXTEND');
          }}
          setNotificationData={setNotificationData}
        />
      </SiteModal>
      {/* Create New Incident */}
      <SiteModal
        key={'clip-details-create-new-incident-modal'}
        classes="new-incident-modal"
        wrapperClass="new-incident-modal-wrapper"
        modalTitle={constants.INCIDENTS_MODAL_NEW_INCIDENT_TITLE}
        showModal={showNewIncidentModal}
        hideModal={() => {
          setShowButtonLoader(false);
          setIsNewIncidentFormComplete(false);
          setShowNewIncidentModal(false);
          setSubmitNewIncidentForm(false);
        }}
        modalFooter={
          <div className="new-incident-button-wrapper">
            <PrimaryButton
              className="save-btn"
              loader={showButtonLoader}
              type="submit"
              disabled={!isNewIncidentFormComplete}
              onClick={(e) => {
                e?.preventDefault();
                setSubmitNewIncidentForm(true);
                setShowButtonLoader(true);
                // setSelectedOption()
              }}
            >
              {constants.INCIDENTS_MODAL_NEW_INCIDENT_CREATE_BUTTON_LABEL}
            </PrimaryButton>
          </div>
        }
      >
        <NewIncident
          shouldSubmitForm={submitNewIncidentForm}
          openModal={(showModal) => {
            setShowNewIncidentModal(showModal);
          }}
          enableButtonLoader={(enable) => setShowButtonLoader(enable)}
          setFormComplete={setIsNewIncidentFormComplete}
          submitCallback={(data) => {
            if (data) {
              // Associate clip with this new incident ID
              handleSelectIncident(data);
              setSelectedOption(data);
            }

            fetchIncidents();
          }}
        />
      </SiteModal>
    </div>
  );
};

export default ClipDetails;
