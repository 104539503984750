import { Utils } from '../../../helpers';
import { rateUnits, usageUnits } from '../../../helpers/commonUtils';

export const calculateMinRecordedBW = (
  totalSumProduct,
  reTransmit,
  overhead,
) => {
  const result = totalSumProduct * (1 + reTransmit) * (1 + overhead);
  return Utils.roundUpToTwoDecimals(result);
};

export const calculateExpectedLiveStreamBW = (
  liveStreamUsers,
  liveStream,
  networkQualityAverage,
  networkReliabilityAverage,
  networkCongestionAverage,
  reTransmit,
  overhead,
) => {
  const result =
    liveStreamUsers *
    liveStream *
    (1 + networkQualityAverage) *
    (1 + networkReliabilityAverage) *
    (1 + networkCongestionAverage) *
    (1 + reTransmit) *
    (1 + overhead);

  return Utils.roundUpToTwoDecimals(result);
};

export const calculateExpectedRecordedBW = (
  totalSumProduct,
  liveStreamUsers,
  liveStream,
  liveStreamHours,
  networkQualityAverage,
  networkReliabilityAverage,
  networkCongestionAverage,
  reTransmit,
  overhead,
) => {
  const result =
    (totalSumProduct + (liveStreamUsers * liveStream * liveStreamHours) / 24) *
    (1 + networkQualityAverage) *
    (1 + networkReliabilityAverage) *
    (1 + networkCongestionAverage) *
    (1 + reTransmit) *
    (1 + overhead);

  return Utils.roundUpToTwoDecimals(result);
};

export const calculateExpectedTotalBW = (
  minRecordedBW,
  expectedLiveStreamBW,
  expectedEventStreamBW,
  additionalCapacity,
) => {
  const result =
    (minRecordedBW + expectedLiveStreamBW + expectedEventStreamBW) *
    (1 + additionalCapacity);
  return result.toFixed(2);
};

export const calculateExpectedDailyBWUsage = (total, gbPer1Mbps) => {
  const result = total * gbPer1Mbps;
  return Utils.roundUpToTwoDecimals(result);
};

export const calculateExpectedWeeklyBWUsage = (value, daysPerWeek) => {
  const result = value * daysPerWeek;
  return Utils.roundUpToTwoDecimals(result);
};

export const calculateExpectedMonthlyBWUsage = (value, daysPerMonth) => {
  const result = value * daysPerMonth;
  return Utils.roundUpToTwoDecimals(result);
};

/**
 * Converts a given bandwidth value in megabytes (MB) to a different unit based on the type and initial unit provided.
 *
 * @param {number|string} mb - The bandwidth value in megabytes (MB) to be converted.
 * @param {string} type - The type of conversion, either 'rate' or 'usage'.
 * @param {"MB"|"GB"|"TB"|"PB"|"ZB"|"YB"} [initialUnit='MB'] - The initial unit of the bandwidth value. Defaults to 'MB'.
 * @param {number} [decimalPoints=2] - The number of decimal points to round the converted value to.
 * @param {number} [binaryBase=1024] - The base number to use for the conversion.
 * @returns {string} The converted bandwidth value with the appropriate unit.
 *
 */
export const bandWidthConverter = (
  mb,
  type,
  initialUnit = 'MB',
  decimalPoints = 2,
  binaryBase = 1024,
) => {
  let mbNumber = Number(mb);
  const units = type === 'rate' ? rateUnits : usageUnits;

  let index = units.findIndex(
    (unit) => unit.toUpperCase() === initialUnit.toUpperCase(),
  );

  index = index === -1 ? 0 : index;

  while (mbNumber >= binaryBase && index < units?.length - 1) {
    mbNumber /= binaryBase;
    index++;
  }

  return `${mbNumber?.toFixed(decimalPoints)} ${units[index]}`;
};

export const processStorageCalculations = (
  subscriptionPlans,
  cloudBackupRate,
) => {
  const STORAGE_SIZES = [256, 512, 1000, 2000];
  const CLOUD_BITRATE_CONSTANT = cloudBackupRate;

  let processedData = subscriptionPlans?.map((plan) => {
    const bitrateMap = new Map();

    plan?.planDetails
      ?.filter((item) => Number(item?.cameras) !== 0)
      ?.forEach(({ videoBitrate, cameras }) => {
        videoBitrate = Number(videoBitrate);
        cameras = Number(cameras);

        bitrateMap.set(
          videoBitrate,
          (bitrateMap.get(videoBitrate) || 0) + cameras,
        );
      });

    const storageData = Array.from(
      bitrateMap,
      ([videoBitrate, totalCameras]) => {
        const cloudBackup = plan?.edgeRecordingUsage?.cloudBackup;
        const cloudBitrate = cloudBackup ? CLOUD_BITRATE_CONSTANT : 0;

        const storageDays = STORAGE_SIZES.map((storageSize) => {
          return {
            storageSize: bandWidthConverter(
              storageSize,
              '',
              'GB',
              storageSize === 256 || storageSize === 512 ? 0 : 1,
              1000,
            ),
            days: Math.floor(
              storageSize / ((videoBitrate + cloudBitrate) * 10.8),
            ),
          };
        });

        return {
          videoBitrate,
          totalCameras,
          storageDays,
        };
      },
    );

    return {
      cloudBackup: plan?.edgeRecordingUsage?.cloudBackup,
      planDetails: storageData,
    };
  });

  const aggregatedBitrates = new Map();

  processedData?.forEach((item) => {
    item.planDetails?.forEach((detail) => {
      const { videoBitrate, storageDays } = detail;

      if (aggregatedBitrates.has(videoBitrate)) {
        const existingStorage = aggregatedBitrates.get(videoBitrate);
        storageDays.forEach((day, index) => {
          existingStorage[index].days += day.days;
        });
      } else {
        aggregatedBitrates.set(
          videoBitrate,
          storageDays.map((day) => ({ ...day })),
        );
      }
    });
  });

  const result = Array.from(
    aggregatedBitrates,
    ([videoBitrate, storageDays]) => ({
      videoBitrate,
      storageDays,
    }),
  );

  return result?.sort((a, b) => a?.videoBitrate - b?.videoBitrate);
};

export const calculateRemoteEventStreamingBandwidth = (
  data = [],
  reTransmitConstant,
  overHeadConstant,
) => {
  return data
    ?.map((plan) => {
      const { planDetails, edgeRecordingUsage } = plan;

      const {
        dailyViewCameraPercentage,
        dailyViewDuration,
        incidentReviewCameraPercentage,
      } = edgeRecordingUsage;

      const totalValue = planDetails?.reduce((total, planRow) => {
        const recordingBitrate = planRow?.videoBitrate || 0;
        const numCameras = parseInt(planRow?.cameras) || 0;

        if (numCameras === 0) {
          return total;
        }

        const incidentReview =
          recordingBitrate *
          (numCameras * (incidentReviewCameraPercentage / 100));

        const dailyStreaming =
          recordingBitrate *
          (numCameras * (dailyViewCameraPercentage / 100)) *
          (dailyViewDuration / 24);

        const maximumValue = Math.max(
          recordingBitrate,
          incidentReview,
          dailyStreaming,
        );

        return total + maximumValue;
      }, 0);

      const remoteEventStreaming =
        totalValue * (1 + reTransmitConstant) * (1 + overHeadConstant);

      return Number(remoteEventStreaming?.toFixed(2));
    })
    .reduce((total, item) => total + item, 0);
};
