import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allDevices: [],
  filtersChanged: false,
};

export const devices = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setAllDevicesList: (state, action) => {
      state.allDevices = action.payload;
    },
    setFiltersChanged: (state, action) => {
      state.filtersChanged = action.payload;
    },
  },
});

export const { setAllDevicesList, setFiltersChanged } = devices.actions;

export const getAllDevicesList = (state) => state.devices.allDevices;
export const getFiltersChanged = (state) => state.devices.filtersChanged;

export default devices.reducer;
