import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Utils } from '../../helpers';

import './TimeInput.scss';

const TimeInput = ({
  classes,
  wrapperClasses,
  useCurrentTime = true,
  initialTime, // must be in EPOCH format
  newTime, // must be in EPOCH format
  timeZone,
  isUTC = false,
  isInvalid = false,
  submitCallback,
  mouseEnterHandler,
  mouseLeaveHandler,
}) => {
  const [displayTime, setDisplayTime] = useState('');
  const [previousDisplayTime, setPreviousDisplayTime] = useState('');
  const [enteredTime, setEnteredTime] = useState(moment().valueOf());

  const inputRef = useRef(null);

  useEffect(() => {
    let initTimeInEpoch;

    if (useCurrentTime === true || !initialTime) {
      initTimeInEpoch = new Date().getTime();

      setEnteredTime(initTimeInEpoch);
      setDisplayTime(
        moment.utc(initTimeInEpoch).tz(timeZone).format('hh:mm:ss'),
      );

      setPreviousDisplayTime(
        moment.utc(initTimeInEpoch).tz(timeZone).format('hh:mm:ss'),
      );
    }

    if (initialTime) {
      setDisplayTime(moment.utc(initialTime).tz(timeZone).format('hh:mm:ss'));
    }
  }, [useCurrentTime, initialTime]);

  useEffect(() => {
    Utils.vmsLogger().log('TimeInput newTime: ', newTime);

    if (!newTime || isNaN(newTime)) return;

    setDisplayTime(moment.utc(newTime).tz(timeZone).format('hh:mm:ss'));

    if (isUTC === true) {
      inputRef.current.value = moment
        .utc(newTime)
        .tz(timeZone)
        .format('hh:mm:ss');
    } else {
      inputRef.current.value = moment(newTime).tz(timeZone).format('hh:mm:ss');
    }
  }, [newTime]);

  const handleKeyDown = (event) => {
    let retVal;

    if (!event) return;

    if (['Backspace'].includes(event.key)) {
      return;
    }

    if (['Enter', 'NumpadEnter'].includes(event.key)) {
      event.preventDefault();

      // Convert "hh:mm:ss" to epoch time
      const value = moment.utc(inputRef.current.value, 'hh:mm:ss').format('x');

      // Update the display time
      setDisplayTime(value);
      inputRef.current.value = moment.utc(parseInt(value)).format('hh:mm:ss');

      if (submitCallback) {
        retVal = submitCallback(inputRef.current.value);

        if (retVal === true) {
          setPreviousDisplayTime(moment.utc(value, 'hh:mm:ss').format('x'));
          setDisplayTime(moment.utc(value, 'hh:mm:ss').format('x'));
        } else {
          setDisplayTime(previousDisplayTime);
        }
      }
    }
  };

  const handleChange = (event) => {
    if (!event) return;

    const cursorStart = inputRef.current.selectionStart;
    const cursorEnd = inputRef.current.selectionEnd;
    const newValue = event.target.value;

    if (/^\d*$/.test(newValue) || newValue === '') {
      // Formatting to "hh:mm:ss"
      const formattedValue = moment(newValue === '' ? initialTime : newValue)
        .tz(timeZone)
        .format('hh:mm:ss');

      inputRef.current.value = formattedValue;
      inputRef.current.setSelectionRange(cursorStart, cursorEnd); // Maintain cursor position
    }
  };

  const handleMouseEnter = () => {
    if (mouseEnterHandler && typeof mouseEnterHandler === 'function') {
      mouseEnterHandler();
    }
  };

  const handleMouseLeave = () => {
    if (mouseLeaveHandler && typeof mouseLeaveHandler === 'function') {
      mouseLeaveHandler();
    }
  };

  return (
    <div
      className={`time-input-wrapper ${wrapperClasses} ${
        isInvalid ? 'invalid-value' : ''
      }`}
    >
      <input
        className={`${classes}`}
        type="text"
        ref={inputRef}
        defaultValue={displayTime}
        maxLength={8}
        placeholder="hh:mm:ss"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </div>
  );
};

export default TimeInput;
