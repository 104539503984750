import { useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useKeycloak } from '@react-keycloak/web';
import { useNavigate } from 'react-router-dom';
import {
  NoDataBlock,
  PrimaryButton,
  SiteModal,
  SiteSpinner,
  SiteToast,
} from '../../components/common/';
import {
  constants,
  LicenseStatuses,
  OfferingTypeEnum,
  ServiceTypeEnum,
  Utils,
} from '../../helpers/';
import OrgListTable from '../organizations/OrgListTable';
import { usePoliciesStore } from '../../store/policiesStore';
import { NewOrganization } from './';
import { icontick, cross, error } from '../../assets/images';
import { BsExclamationLg } from 'react-icons/bs';
import {
  HiOutlineExclamationCircle,
  HiOutlineCheckCircle,
} from 'react-icons/hi';
import { RxPlus } from 'react-icons/rx';
import './ManageOrganizations.scss';
import axios from 'axios';
import {
  getLicenseAndDeviceData,
  setLicenseAndDeviceData,
} from '../../store/reducers/StreamingReducer';
import { subscribeWithOrgIds } from '../../utils/connection/mqttConnection';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  getCustomerOrgData,
  getOrganizations,
} from '../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { useCustomerOrgUsers } from '../../store/CustomerOrgUsersStore';
//import useEventsStore from '../../store/EventsStore';
function PartnerLandingPage() {
  const [orgStatus, setOrgStatus] = useState(false);
  const { keycloak } = useKeycloak();
  const token = keycloak.token;
  const locationData = localStorage.getItem('inviteData');
  const location = locationData && JSON.parse(locationData);
  const invitationData = location;
  const inviteCode = invitationData?.meta?.code;
  const inviteStatus = invitationData?.data?.accepted;
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData,
  );
  const inviteTitle =
    inviteCode == 200
      ? inviteStatus
        ? constants.PARTNER_INVITE_ACCEPTED_TITLE
        : constants.PARTNER_INVITE_REJECTED_TITLE
      : inviteCode == 1032
        ? constants.PARTNER_INVITE_INVALID_TITLE
        : inviteCode == 1030
          ? constants.PARTNER_INVITE_PROCESSED_TITLE
          : inviteCode == 1031 || inviteCode == 1033
            ? constants.PARTNER_INVALID_INVITATION_TITLE
            : invitationData?.meta?.desc;
  const inviteSubTitle =
    inviteCode == 200
      ? inviteStatus
        ? `${invitationData?.data?.orgName} ${constants.PARTNER_INVITE_ACCEPTED_SUB_TITLE}`
        : `${invitationData?.data?.orgName} ${constants.PARTNER_INVITE_REJECTED_SUB_TITLE}`
      : inviteCode == 1030
        ? ''
        : inviteCode == 1032
          ? invitationData?.meta?.userMsg?.replace('Invalid Account.', '')
          : invitationData?.meta?.userMsg;
  const [partnerInvitiationStatus, setPartnerInvitiationStatus] = useState(
    invitationData && Object.keys(invitationData).length > 0 ? true : false,
  );

  const getLoggedInUserPolicies = usePoliciesStore(
    (state) => state.getLoggedInUserPolicies,
  );
  const [showLoader, setShowLoader] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [showAddOrgModal, setShowAddOrgModal] = useState(false);
  const [fetchOrgsStatus, setFetchOrgsStatus] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const dispatch = useDispatch();
  const [licensesListByOrgIds, setLicensesListByOrgIds] = useState([]);
  const [devicesCountByOrgIds, setDevicesCountByOrgIds] = useState([]);
  const licenseAndDeviceData = useSelector(getLicenseAndDeviceData);
  const [orgListWithLicences, setOrgListWithLicences] = useState();
  const [isChanged, setIsChanged] = useState(false);
  const navigate = useNavigate();
  const setPatnerOrgUser = useCustomerOrgUsers(
    (state) => state.setPatnerOrgUser,
  );

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    if (orgs && orgs?.length <= 0) {
      setOrgStatus(true);
    }
    setOrgListWithLicences(orgs);
  }, []);

  useEffect(() => {
    setShowLoader(true);
  }, []);

  useEffect(() => {
    const orgId = getLoggedInUserData()?.orgId;
    if (orgId) {
      fetchData(orgId);
    }
  }, [getLoggedInUserData()?.orgId]);

  const fetchData = async (orgId) => {
    try {
      await axios
        .get(`partner/orgs/${orgId}/accounts`, {
          ...Utils.requestHeader(),
        })
        .then((response) => {
          if (
            response?.data?.meta?.code === 200 ||
            response?.data?.meta?.code === '200'
          ) {
            setPatnerOrgUser(response?.data?.data);
          }
        });
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };

  useEffect(() => {
    if (licensesListByOrgIds?.length > 0 && devicesCountByOrgIds?.length > 0) {
      const combineResult = licensesListByOrgIds?.map((licenseItem) => {
        const item = devicesCountByOrgIds?.find(
          (x) => x.orgId === licenseItem.orgId,
        );
        return item
          ? {
              ...licenseItem,
              totalDevicesCount: item.totalDevicesCount,
              totalOfflineDevicesCount: item.totalOfflineDevicesCount,
            }
          : licenseItem;
      });
      dispatch(setLicenseAndDeviceData(combineResult));
    }
  }, [licensesListByOrgIds, devicesCountByOrgIds]);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000,
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  useEffect(() => {
    // setLinkingData({})
    const posOrgId = localStorage.getItem('posOrgId');
    if (
      posOrgId != null &&
      posOrgId != 'null' &&
      posOrgId != 'undefined' &&
      posOrgId != ''
    ) {
      const findOrgId = getCustomerOrgData()?.find(
        (item) => item?.orgId === posOrgId,
      );
      if (findOrgId) {
        navigate(`/app/pos?orgId=${posOrgId}`);
      }
    }
  });

  //=== Get organizations data
  const fetchOrganizations = async (token) => {
    if (token) {
      setShowSpinner(true);
      setFetchOrgsStatus(false);
      await getOrganizations(`/partner/account/self/v3`);
      setShowSpinner(false);
      //=== Subscribe the topics for all added customer organizations
      subscribeWithOrgIds(orgListWithLicences);
    } else {
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    updateLicenseInfoForAllOrgs(orgListWithLicences);
    updateDevicesCountForAllOrgs(orgListWithLicences);
  }, [orgListWithLicences?.length]);

  useEffect(() => {
    if (licenseAndDeviceData?.length > 0) {
      const list = orgListWithLicences?.map((org) => {
        const item = licenseAndDeviceData?.find((x) => x?.orgId === org?.orgId);
        return {
          ...org,
          expiredLicenseCount: item?.expiredLicenseCount || 0,
          expiringSoonLicenseCount: item?.expiringSoonLicenseCount || 0,
          cameraOfflineCount: `${item?.totalOfflineDevicesCount || 0} / ${
            item?.totalDevicesCount || 0
          }`,
        };
      });
      setOrgListWithLicences(list);
      setTimeout(() => {
        if (list && list?.length <= 0) {
          setOrgStatus(true);
          setShowLoader(false);
        } else if (list && list?.length > 0) {
          setShowLoader(false);
        } else {
          setShowLoader(false);
        }
      }, 100);
    } else {
      setOrgListWithLicences(orgListWithLicences);
      setTimeout(() => {
        if (orgListWithLicences && orgListWithLicences?.length <= 0) {
          setOrgStatus(true);
          setShowLoader(false);
        } else if (orgListWithLicences && orgListWithLicences?.length > 0) {
          setShowLoader(false);
        } else {
          setShowLoader(false);
        }
      }, 100);
    }
    setIsChanged(!isChanged);
  }, [licenseAndDeviceData, JSON.stringify(orgListWithLicences)]);

  const updateLicenseInfoForAllOrgs = (orgList) => {
    if (orgList?.length > 0) {
      const licenseList = orgList?.map(async (org) => {
        if (org.role) {
          const url = `/user/orgs/${org?.orgId}/licenses`;
          const license = await axios
            .get(url, Utils.requestHeader())
            .then((res) => {
              if (res && res.data) {
                const respData = res.data.data?.filter(
                  (license) =>
                    license?.offeringType !== OfferingTypeEnum.ONBOARDING ||
                    license?.offeringType === 'PAID' ||
                    (license?.offeringType === 'EVAL' &&
                      license.serviceId.toUpperCase() ===
                        ServiceTypeEnum?.CLOUD_STORAGE?.toUpperCase() &&
                      license.serviceId.toUpperCase() ===
                        ServiceTypeEnum?.VMS_SERVICE?.toUpperCase()),
                );

                const tempLic = {
                  orgId: org.orgId,
                  expiredLicenseCount: getExpiredLicenseCount(respData),
                  expiringSoonLicenseCount:
                    getExpiringSoonLicenseCount(respData),
                };
                return tempLic;
              }
            });
          return license;
        }
      });
      Promise.all(licenseList).then((values) => {
        setLicensesListByOrgIds(values.filter(Boolean));
      });
    }
  };

  const updateDevicesCountForAllOrgs = async (orgList) => {
    if (!orgList) return;

    const devicePromises = orgList.map(async (org) => {
      if (!org.role) return null;

      const url = `/device/orgs/${org.orgId}/devices`;
      try {
        const { data } = await axios.get(url, Utils.requestHeader());
        const respData = data?.data || [];

        const totalDevices =
          Utils.getTotalChildDevices(respData)?.filter(
            (device) =>
              device.deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS ||
              device.deviceStatus ===
                constants.DEVICES_DEACTIVATED_DEVICE_STATUS,
          ) || [];

        const nvrDeviceList =
          Utils.getTotalParentDevices(respData)?.filter(
            (device) =>
              (device.deviceStatus ===
                constants.DEVICES_CLAIMED_DEVICE_STATUS ||
                device.deviceStatus ===
                  constants.DEVICES_DEACTIVATED_DEVICE_STATUS) &&
              device.deviceType === 'nvr',
          ) || [];

        const offlineNVRDevicesCount =
          nvrDeviceList.filter(
            (device) =>
              !device.connectionStatus ||
              device.connectionStatus ===
                constants.DEVICES_OFFLINE_CONNECTION_STATUS,
          )?.length || 0;

        const offlineCamerasCount =
          totalDevices.filter(
            (device) =>
              !device.connectionStatus ||
              device.connectionStatus ===
                constants.DEVICES_OFFLINE_CONNECTION_STATUS,
          )?.length || 0;

        return {
          orgId: org.orgId,
          totalDevicesCount: totalDevices.length + nvrDeviceList.length,
          totalOfflineDevicesCount:
            offlineCamerasCount + offlineNVRDevicesCount,
        };
      } catch (error) {
        return {
          orgId: org.orgId,
          totalDevicesCount: 0,
          totalOfflineDevicesCount: 0,
        };
      }
    });

    const values = await Promise.all(devicePromises);
    setDevicesCountByOrgIds(values.filter(Boolean));
  };

  const getExpiredLicenseCount = (licenses) => {
    let count = 0;
    licenses?.forEach((license) => {
      if (
        license.licenseStatus === LicenseStatuses.EXPIRED &&
        (license.serviceId.toUpperCase() ===
          ServiceTypeEnum?.VMS_SERVICE?.toUpperCase() ||
          license.serviceId.toUpperCase() ===
            ServiceTypeEnum?.CLOUD_STORAGE?.toUpperCase())
      ) {
        count++;
      }
    });
    return count;
  };

  const isLicenseExpiredOrExpiringSoon = (license) => {
    let countOfExpiringSoonDays = false;
    const currentTimestamp = Date.now();
    const actualExpiryData =
      license.expiryDate - license.gracePeriod * 24 * 60 * 60 * 1000;

    const differenceInMilliseconds = Math.abs(
      actualExpiryData - currentTimestamp,
    );
    const differenceInDays = Math.floor(
      differenceInMilliseconds / (1000 * 60 * 60 * 24),
    );
    return (
      differenceInDays <= license.expiringSoonInDays &&
      license.licenseStatus !== LicenseStatuses.EXPIRED
    );
  };

  const getExpiringSoonLicenseCount = (licenses) => {
    let count = 0;
    licenses.forEach((license) => {
      if (
        (license.serviceId.toUpperCase() ===
          ServiceTypeEnum?.VMS_SERVICE?.toUpperCase() ||
          license.serviceId.toUpperCase() ===
            ServiceTypeEnum?.CLOUD_STORAGE?.toUpperCase()) &&
        license?.offeringType !== OfferingTypeEnum.ONBOARDING &&
        isLicenseExpiredOrExpiringSoon(license)
      ) {
        count++;
      }
    });
    return count;
  };

  const columnsData = useMemo(
    () => [
      {
        Header: constants.NAME_TABLE_HEADER,
        accessor: 'orgName',
        sortType: (sortPrev, sortAfter) => {
          if (
            sortPrev.values['orgName'].toLowerCase() >
            sortAfter.values['orgName'].toLowerCase()
          ) {
            return -1;
          } else if (
            sortPrev.values['orgName'].toLowerCase() <
            sortAfter.values['orgName'].toLowerCase()
          ) {
            return 1;
          } else {
            return 0;
          }
        },
      },
      {
        Header: constants.CAMERAS_OFFLINE_TABLE_HEADER,
        accessor: 'cameraOfflineCount',
        Cell: (props) => {
          if (props?.value !== null && props?.value !== undefined) {
            const cameraOffline = props.value;
            const [totalOfflineDevicesCount, totalDevicesCount] =
              cameraOffline.split('/');
            return (
              <>
                {totalDevicesCount > 0 ? (
                  <>
                    {totalOfflineDevicesCount === 0 ? (
                      <>
                        {totalOfflineDevicesCount}
                        <HiOutlineCheckCircle
                          stroke={getComputedStyle(
                            document.documentElement,
                          ).getPropertyValue('--success_48')}
                          className="device-offline-count-icon"
                        />
                      </>
                    ) : (
                      <>
                        {cameraOffline}
                        <HiOutlineExclamationCircle
                          stroke={getComputedStyle(
                            document.documentElement,
                          ).getPropertyValue('--error_64')}
                          className="device-offline-count-icon"
                        />
                      </>
                    )}
                  </>
                ) : (
                  0
                )}
              </>
            );
          }
          return 0;
        },
        sortType: 'basic',
      },
      {
        Header: constants.LICENSES_EXPIRED_TABLE_HEADER,
        accessor: 'expiredLicenseCount',
        Cell: (props) => {
          return props.value || 0;
        },
        sortType: 'basic',
      },
      {
        Header: constants.LICENSES_EXPIRING_TABLE_HEADER,
        accessor: 'expiringSoonLicenseCount',
        Cell: (props) => {
          return props.value || 0;
        },
        sortType: 'basic',
      },
    ],
    [],
  );

  const sortingData = useMemo(
    () => [
      {
        id: 'orgName',
        desc: true,
      },
      {
        id: 'devices',
        desc: true,
      },
    ],
    [],
  );

  const hideModaInvite = () => {
    setPartnerInvitiationStatus(false);
    localStorage.removeItem('inviteData');
  };

  return (
    <div className="App partner-landing-page">
      {showLoader && (
        <div className="position-absolute top-50" style={{ left: '47%' }}>
          <SiteSpinner height="50px" width="50px" />
          <div className="mt-2 text-dark">{constants.LOADING}</div>
        </div>
      )}
      {!showSpinner ? (
        <div>
          <div className="page-header">
            <Container className="mw-100">
              <Row>
                <div className="customer-list-toast-wrapper">
                  <SiteToast
                    customCss="customer-list-toast"
                    position="top-end"
                    show={showToast}
                    title="Error"
                    body={userMsg}
                    delay={5000}
                  />
                </div>
              </Row>
              <Row className="g-4">
                <Col
                  md={6}
                  lg={6}
                  xl={6}
                  xs={12}
                  className="page-title text-start"
                >
                  {constants.MANAGE_ORG_PAGE_TITLE}
                </Col>
                <Col
                  md={6}
                  lg={6}
                  xl={6}
                  xs={12}
                  className="text-end page-header-buttons"
                >
                  {getLoggedInUserPolicies()?.create_cust_org && (
                    <PrimaryButton
                      className="btn btn-primary"
                      type="button"
                      width="auto"
                      onClick={() => {
                        setShowAddOrgModal(true);
                      }}
                    >
                      <RxPlus
                        className="plus-icon-btn"
                        strokeWidth={'1.5px'}
                        size={13.33}
                      />
                      {constants.MANAGE_ORG_ADD_CUSTOMER_BUTTON_TITLE}
                    </PrimaryButton>
                  )}
                </Col>
              </Row>
            </Container>
          </div>

          <div className="list-block">
            <Container className="h-100 mw-100">
              <>
                {getLoggedInUserPolicies()?.view_cust_org === true &&
                  (Array.isArray(orgListWithLicences) &&
                  orgListWithLicences?.length > 0 ? (
                    <OrgListTable
                      columns={columnsData}
                      data={orgListWithLicences}
                      defaultSortByData={sortingData}
                    />
                  ) : (
                    orgStatus && (
                      <NoDataBlock
                        contentBlock={
                          constants.NO_MANAGE_ORG_CUSTOMER_FOUND_TITLE
                        }
                      />
                    )
                  ))}

                {userMsg && (
                  <div className="mt-5 mb-5">
                    <strong>{userMsg}</strong>
                  </div>
                )}
              </>
            </Container>
          </div>
        </div>
      ) : (
        <div className="w-100 text-center">
          <SiteSpinner height="100px" width="100px"></SiteSpinner>
        </div>
      )}

      <SiteModal
        modalTitle={constants.NEW_CUSTOMER_ORG_PAGE_TITLE}
        showModal={showAddOrgModal}
        hideModal={() => setShowAddOrgModal(false)}
      >
        <NewOrganization
          reloadData={() => {
            fetchOrganizations(token);
          }}
          hideModal={() => setShowAddOrgModal(false)}
        />
      </SiteModal>
      <SiteModal
        modalTitle={inviteTitle}
        showModal={partnerInvitiationStatus}
        hideModal={() => hideModaInvite()}
        classes={'send-invitation-partner remove-org-modal'}
      >
        <div className={`sub-title-modal ${inviteCode == 1035 && 'error-m'}`}>
          {inviteSubTitle}
        </div>
        {
          <div>
            <div className="org-remove-invite-status">
              <div className="device-claimed-status">
                <div
                  className={`${
                    inviteCode == 200 && inviteStatus
                      ? 'claimed-view '
                      : (inviteCode == 200 && !inviteStatus) ||
                          inviteCode == 1032
                        ? 'claimed-view reject'
                        : (inviteCode == 1030 || inviteCode == 1035) &&
                          'claimed-view warning'
                  }`}
                >
                  {inviteCode == 200 && (
                    <img
                      className="image-tick"
                      src={inviteStatus ? icontick : cross}
                    ></img>
                  )}
                  {(inviteCode == 1030 || inviteCode == 1035) && (
                    <BsExclamationLg
                      size={40}
                      color={getComputedStyle(
                        document.documentElement,
                      ).getPropertyValue('--warning_24')}
                    />
                  )}
                  {(inviteCode == 1031 || inviteCode == 1033) && (
                    <img className="image-tick" src={error}></img>
                  )}
                  {inviteCode == 1032 && (
                    <img className="image-tick" src={cross}></img>
                  )}
                </div>
              </div>
            </div>
            <PrimaryButton
              className="btn mt-4"
              type="button"
              width="100%"
              borderWidth="1.5px"
              hoverBorderWidth="1.5px"
              color={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--brand_white')}
              borderColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--brand_white')}
              hoverColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--brand_white')}
              backgroundColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--primary_40')}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--primary_72')}
              hoverBorderColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--brand_white')}
              onClick={() => {
                hideModaInvite();
              }}
            >
              {constants.PARTNER_INVITE_BTN_LABEL}
            </PrimaryButton>
          </div>
        }
      </SiteModal>
    </div>
  );
}

export default PartnerLandingPage;
