import { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Button } from 'react-bootstrap';
import { IoIosSearch } from 'react-icons/io';
import { FaCircle } from 'react-icons/fa';
import { deviceOptions } from '../../assets/images';
import { constants, Utils, AppDefaults } from '../../helpers/';
import { useCustOrgLocationArea } from '../../store/CustOrgLocationAreaStore';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import {
  setRemoteStream,
  getCDNInfo,
  setIsReloadedStream,
} from '../../store/reducers/StreamingReducer';
import { setDeviceInformation } from '../../store/reducers/AccountReducer';
import { SiteSpinner } from '../../components/common/';
import { disconnectWithMQTT } from '../../utils/connection/mqttConnection';
import './CameraWallDevices.scss';
import NavDropdown from 'react-bootstrap/NavDropdown';
import CameraWallSnapshot from './CameraWallSnapshot';
import CameraWallTable from './CameraWallTable';
import LocationAreaSelector from './LocationAreaSelector';
import { usePoliciesStore } from '../../store/policiesStore';
import { FiCameraOff } from 'react-icons/fi';
import { MdOutlineAddAPhoto } from 'react-icons/md';
import { HiOutlineVideoCamera } from 'react-icons/hi2';
import { MdOutlineDragIndicator } from 'react-icons/md';
import { FiSettings } from 'react-icons/fi';
import { RxCrossCircled } from 'react-icons/rx';
import { fetchCustomerOrgDevices } from '../../store/CustomerOrgDevicesStoreIDB';
import {
  setAllDevicesData,
  setAllDevicesDataNotification,
} from '../../store/AccountStoreIDB';
import { setSelfStatus } from '../../store/reducers/OrganizationsReducer';
import { useDeviceSnapshots } from '../../store/DeviceSnapshotsStore';
import { getAccountId } from '../../store/reducers/AccountReducer';
import { fetchCustomerOrgChannels } from './utils/CameraAPIUtils';
import {
  setAllDevicesList,
  getAllDevicesList,
  setFiltersChanged,
  getFiltersChanged,
} from '../../store/reducers/DevicesReducer';
import { cameraWallEnum } from '../../helpers/enums';

const CameraWallDevices = ({
  viewDetails,
  setSelectedViewDevices,
  setTotalDeviceCount,
  newViewDragMode,
  setNewViewDragMode,
  editView = false,
  isDragging,
  selectedViewDevices,
  orgDetails,
  custOrgDevices,
  setCustOrgDevices,
  setCameraWallDevices,
  cameraWallDevicesMetaData,
  setCameraWallDevicesMetaData,
}) => {
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [currentSession, setCurrentSession] = useState(uuidv4());
  // const [devices, setDevices] = useState([]);
  const devicesRef = useRef([]);
  // const [filteredDevices, setFilteredDevices] = useState([]);
  const filteredDevicesRef = useRef([]);
  const localSelectedViewDevices = useRef(selectedViewDevices);
  const [isLoading, setIsLoading] = useState(true);
  const [isLocationLoading, setIsLocationLoading] = useState(true);
  const [isChannelDevicesLoading, setIsChannelDevicesLoading] = useState(true);
  const [isDevicesLoading, setIsDevicesLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [redirectingLoader, setRedirectingLoader] = useState(false);
  const [searchText, setSearchText] = useState('');
  const cdnInfo = useSelector(getCDNInfo);
  const [hasDevices, setHasDevices] = useState(0);
  let [searchParams] = useSearchParams();
  const viewId = searchParams.get('viewId');
  // const [viewDevices, setViewDevices] = useState([]);
  const [viewDevicesDetails, setViewDevicesDetails] = useState({});
  const [isScrolled, setIsScrolled] = useState(false);
  const [filterChannelData, setFilterChannelData] = useState(false);
  const dispatch = useDispatch();
  const {
    getDeviceWallSelectedLocation,
    setDeviceWallSelectedLocation,
    getDeviceWallSelectedArea,
    setDeviceWallSelectedArea,
    getDeviceWallSelectedDeviceStatus,
  } = useCustOrgLocationArea();
  const { getCustomerOrgLocations, getCustomerOrgLocationsData } =
    useCustomerOrgLocations();

  const selectedLocation = getDeviceWallSelectedLocation();
  const selectedDeviceConnectionStatus = getDeviceWallSelectedDeviceStatus();
  const selectedArea = getDeviceWallSelectedArea();
  const navigate = useNavigate();
  const custOrgLocations = getCustomerOrgLocationsData();
  const { getLoggedInUserPolicies } = usePoliciesStore();
  const userPolicies = getLoggedInUserPolicies();
  const { setDeviceSnapshots, getDeviceSnapshots, resetDeviceSnapshots } =
    useDeviceSnapshots();
  const accountId = useSelector(getAccountId);
  const cameraWallListMetaData = {
    pageNo: 0,
    pageSize: 50,
    searchString: '',
    orderBy: 'deviceStatus',
    sortOrder: 'desc',
  };
  const [currentCameraWallListMetaData, setCurrentCameraWallListMetaData] =
    useState(structuredClone(cameraWallListMetaData));

  const [isReFetchData, setIsRefetchData] = useState(false);
  const allDevices = useSelector(getAllDevicesList);
  const [showLoadingText, setShowLoadingText] = useState(false);
  const filterChanged = useSelector(getFiltersChanged);

  useEffect(() => {
    if (selectedViewDevices !== localSelectedViewDevices.current) {
      localSelectedViewDevices.current = [...new Set(selectedViewDevices)];
    }
  }, [selectedViewDevices]);

  useEffect(() => {
    updateViewDevicesDetils(viewDetails?.devices ? viewDetails?.devices : []);
    if (
      viewDetails &&
      custOrgDevices?.length &&
      custOrgLocations?.length &&
      allDevices?.length
    ) {
      combineDeviceAndLocationData();
    }
    setNewViewDragMode(false);
  }, [viewDetails]);

  const combineDeviceAndLocationDataCallback = () => {
    if (custOrgLocations?.length) {
      combineDeviceAndLocationData();
    }
  };
  const debounceCombineDeviceAndLocationData = _.debounce(
    combineDeviceAndLocationDataCallback,
    100,
  );

  useEffect(() => {
    if (custOrgLocations?.length) {
      debounceCombineDeviceAndLocationData();
    }
  }, [
    custOrgLocations?.length,
    custOrgDevices,
    allDevices?.length,
    isReFetchData,
  ]);

  useEffect(() => {
    setIsDataLoading(true);
    setCurrentCameraWallListMetaData({
      ...currentCameraWallListMetaData,
      pageNo: 0,
    });
    setIsRefetchData(!isReFetchData);
  }, [
    selectedLocation,
    selectedArea,
    searchText,
    selectedDeviceConnectionStatus,
  ]);

  useEffect(() => {
    setCurrentSession(uuidv4());
    if (!selectedLocation.locationId) {
      setDeviceWallSelectedLocation({
        locationId: AppDefaults.ALL_LOCATIONS_ID,
      });
    }
    if (!selectedArea.areaId) {
      setDeviceWallSelectedArea({
        areaId: constants.LOCATION_DROPDOWN_ALL_AREAS_ID,
      });
    }
    fetchCameraWallData();
  }, [orgDetails?.orgId]);

  const fetchChannelsCallback = () => {
    fetchChannels();
  };

  const debounceFetchChannels = _.debounce(fetchChannelsCallback, 1000);

  useEffect(() => {
    debounceFetchChannels();
  }, [isReFetchData]);

  const updateViewDevicesDetils = (viewDevices) => {
    const data = {};
    viewDevices.forEach((deviceId) => {
      const childDevice = devicesRef?.current?.find(
        (device) => device.deviceId === deviceId,
      );
      data[deviceId] = childDevice;
    });
    setViewDevicesDetails(data);
  };

  const fetchCameraWallData = () => {
    fetchLocations();
    fetchDevices();
    fetchChannels();
    dispatch(setRemoteStream(null));
    disconnectWithMQTT();
  };

  useEffect(() => {
    if (
      isChannelDevicesLoading ||
      isLocationLoading ||
      !filterChannelData
      // ||
      // isDevicesLoading
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    isChannelDevicesLoading,
    isLocationLoading,
    filterChannelData,
    isDevicesLoading,
  ]);

  const fetchChannels = async () => {
    try {
      if (orgDetails?.orgId) {
        if (!showLoadingText && !filterChanged) {
          setIsChannelDevicesLoading(true);
        }
        setIsDataLoading(true);
        const { pageNo, pageSize, orderBy, sortOrder } =
          currentCameraWallListMetaData;
        let sortBy = sortOrder == 'desc' ? false : true;
        let orderByFeild =
          orderBy == 'displayDeviceStatus' ? 'deviceStatus' : orderBy;
        let locationId =
          selectedLocation.locationId != 'Location0'
            ? selectedLocation.locationId
            : '';
        let areaId = selectedArea.areaId != 'Area0' ? selectedArea.areaId : '';
        const params = `?areaId=${areaId || ''}&locationId=${locationId || ''}&search=${searchText}&page=${pageNo}&size=${pageSize}&orderBy=${orderByFeild}&isAsc=${sortBy}&connectionStatuses=${selectedDeviceConnectionStatus.join(',')}`;
        const fetchResult = await fetchCustomerOrgChannels(
          `device/orgs/${orgDetails?.orgId}/channels/v2${params}`,
        );

        if (fetchResult.status === 'success') {
          if (fetchResult?.data?.devices?.length > 0) {
            setHasDevices(true);
            if (fetchResult.data.pageNumber === 0) {
              setCustOrgDevices(fetchResult.data.devices);
              if (!filterChanged) {
                setCameraWallDevices(fetchResult.data.devices);
              }
              dispatch(setAllDevicesList(fetchResult.data.devices));
            } else {
              setCustOrgDevices([
                ...custOrgDevices,
                ...fetchResult.data.devices,
              ]);
              if (!filterChanged) {
                setCameraWallDevices([
                  ...custOrgDevices,
                  ...fetchResult.data.devices,
                ]);
              }
              dispatch(
                setAllDevicesList([
                  ...custOrgDevices,
                  ...fetchResult.data.devices,
                ]),
              );
            }
            delete fetchResult.data.devices;
            setCameraWallDevicesMetaData(fetchResult.data);
          } else {
            setHasDevices(false);
            setCustOrgDevices([]);
            if (!filterChanged) {
              setCameraWallDevices([]);
            }
            dispatch(setAllDevicesList([]));
          }
          dispatch(setSelfStatus('1'));
        } else {
          setHasDevices(false);
          dispatch(setSelfStatus('1'));
          if (!fetchResult?.msg?.includes(AppDefaults.ERR_CANCELED)) {
            setUserMsg(fetchResult?.msg);
            setShowToast(true);
          }
        }
      } else {
        throw new Error('ERROR: Could not retrieve organization details');
      }
    } catch (err) {
      dispatch(setSelfStatus('1'));
      if (!err?.msg?.includes(AppDefaults.ERR_CANCELED)) {
        setUserMsg(err?.msg);
        setShowToast(true);
      }
    } finally {
      setIsDataLoading(false);
      setShowLoadingText(false);
      setIsChannelDevicesLoading(false);
      dispatch(setFiltersChanged(false));
    }
  };

  const handleLoadMore = () => {
    setShowLoadingText(true);
    dispatch(setFiltersChanged(true));
    setCurrentCameraWallListMetaData({
      ...currentCameraWallListMetaData,
      pageNo: currentCameraWallListMetaData.pageNo + 1,
    });
    setIsRefetchData(!isReFetchData);
  };

  const fetchDevices = useCallback(async () => {
    try {
      setIsDevicesLoading(true);
      if (orgDetails?.orgId && !editView) {
        const fetchResult = await fetchCustomerOrgDevices(
          `device/orgs/${orgDetails?.orgId}/devices`,
        );
        if (fetchResult.status === 'success') {
          dispatch(setSelfStatus('1'));
          setIsDevicesLoading(false);
        } else {
          dispatch(setSelfStatus('1'));
          setIsDevicesLoading(false);
          if (!fetchResult?.msg?.includes(AppDefaults.ERR_CANCELED)) {
            setShowToast(true);
            setUserMsg(fetchResult?.msg);
          }
        }
      } else {
        setIsDevicesLoading(false);
        throw new Error('ERROR: Could not retrieve organization details');
      }
    } catch (err) {
      setIsDevicesLoading(false);
      dispatch(setSelfStatus('1'));
      if (!err?.msg?.includes(AppDefaults.ERR_CANCELED)) {
        setShowToast(true);
        setUserMsg(err?.msg);
      }
    }
  });

  const fetchLocations = useCallback(async () => {
    setIsLocationLoading(true);
    try {
      if (orgDetails?.orgId) {
        let fetchResult;
        if (
          !custOrgLocations?.length ||
          (custOrgLocations?.length &&
            custOrgLocations[0].orgId !== orgDetails?.orgId)
        ) {
          fetchResult = await getCustomerOrgLocations(
            `partner/orgs/${orgDetails?.orgId}/locations`,
          );
          if (fetchResult.status === 'success') {
          } else {
            if (!fetchResult?.msg?.includes(AppDefaults.ERR_CANCELED)) {
              setShowToast(true);
              setUserMsg(fetchResult?.msg);
            }
          }
        }
      } else {
        throw new Error('ERROR: Could not retrieve organization details');
      }
    } catch (err) {
      if (!err?.msg?.includes(AppDefaults.ERR_CANCELED)) {
        setShowToast(true);
        setUserMsg(err?.msg);
      }
    } finally {
      setIsLocationLoading(false);
    }
  });

  const combineDeviceAndLocationData = async () => {
    const locationAreas = [];
    const checkedStatusArray = [];

    const cameraWallDevices = structuredClone(custOrgDevices);

    // Retrieve the location name for each device
    cameraWallDevices.forEach((cameraDevice) => {
      const currentArea = custOrgLocations.find(
        (orgLocation) => orgLocation.locationId === cameraDevice?.locationId,
      );

      if (
        locationAreas.findIndex(
          (locationArea) =>
            locationArea.locationId === cameraDevice?.locationId,
        ) === -1
      ) {
        const deviceArea = {
          deviceId: cameraDevice?.deviceId,
          areas: currentArea?.areas.length > 0 ? [...currentArea?.areas] : [],
        };

        locationAreas.push({
          locationId: cameraDevice?.locationId,
          areas: [...deviceArea.areas],
        });
      }

      cameraDevice.locationName = currentArea?.locationName;
      const deviceAreaName =
        currentArea?.areas.find((area) => area.areaId === cameraDevice.areaId)
          ?.areaName || '';

      cameraDevice.areaName = deviceAreaName;
      cameraDevice.locationAreaNames =
        currentArea?.locationName + ' - ' + deviceAreaName;

      const filteredLocationArea = locationAreas.find(
        (locationArea) => locationArea?.locationId === cameraDevice?.locationId,
      ).areas;

      cameraDevice.areas = filteredLocationArea
        ? [...filteredLocationArea]
        : [];

      const viewStatus = localSelectedViewDevices?.current?.includes(
        cameraDevice?.deviceId,
      );
      cameraDevice.style = null;
      if (cameraDevice?.connectionStatus !== null) {
        if (cameraDevice?.connectionStatus === cameraWallEnum.online_sm) {
          cameraDevice.displayDeviceStatus =
            constants.DEVICES_RETURN_ONLINE_STATUS;
        } else if (
          cameraDevice?.connectionStatus === cameraWallEnum.offline_sm
        ) {
          cameraDevice.displayDeviceStatus =
            constants.DEVICES_RETURN_OFFLINE_STATUS;
        }
      }
      if (viewStatus) {
        checkedStatusArray.push(cameraDevice?.deviceId);
      }
    });
    updateViewDevicesDetils(checkedStatusArray);
    const updatedAllDevices = allDevices.map((d) => {
      const foundDevice = cameraWallDevices.find(
        (c) => c.deviceId === d.deviceId,
      );
      return foundDevice ? foundDevice : d;
    });
    devicesRef.current =
      cameraWallDevices?.length > 0 ? [...cameraWallDevices] : [];
    setTotalDeviceCount(
      cameraWallDevices?.length > 0 ? cameraWallDevices?.length : 0,
    );
    if (cameraWallDevices?.length) {
      filterChannelByLocationAndArea(cameraWallDevices);
    } else {
      filterChannelByLocationAndArea([]);
    }
    if (updatedAllDevices?.length > 0) {
      await setAllDevicesData([...updatedAllDevices]);
    }
    await setAllDevicesDataNotification(
      cameraWallDevices?.length > 0 ? [...cameraWallDevices] : [],
    );
  };

  const filterChannelByLocationAndArea = (channelList) => {
    const fillterByChannel = JSON.parse(JSON.stringify([...channelList]));
    filteredDevicesRef.current =
      fillterByChannel?.length > 0 ? [...fillterByChannel] : [];
    setIsScrolled(!isScrolled);
    setFilterChannelData(true);
  };

  const onChangeSearchText = (e) => {
    if (e.key === 'Enter') {
      dispatch(setFiltersChanged(true));
      setIsDataLoading(true);
      setSearchText(e?.target?.value);
    }
  };

  const OnClickCameraDetails = (device) => {
    setRedirectingLoader(true);
    dispatch(setIsReloadedStream(false));
    getCapabiltiesForDevice(device, redirectToCameraDashboardPage);
  };

  const redirectToCameraDashboardPage = (device) => {
    setRedirectingLoader(false);
    navigate(`/cameras/dashboard.html`, {
      state: {
        id: device?.deviceId,
        cdnInfo: cdnInfo ? cdnInfo : {},
      },
    });
  };

  const getCapabiltiesForDevice = async (deviceData, callback) => {
    if (deviceData?.capability) {
      try {
        const response = await fetch(deviceData?.capability?.url);
        if (response.status === 200) {
          const responseJson = await response.json();
          callback({ ...deviceData, capDetails: responseJson });
        } else {
          callback(deviceData);
        }
      } catch (error) {
        callback(deviceData);
      }
    } else {
      callback(deviceData);
    }
  };

  const OnClickCameraSettings = (device) => {
    setRedirectingLoader(true);
    getCapabiltiesForDevice(device, redirectToChannelSettingsPage);
  };

  const redirectToChannelSettingsPage = (device) => {
    setRedirectingLoader(false);
    dispatch(setDeviceInformation(device));
    device?.deviceId &&
      navigate(`/devices/dashboard.html?deviceId=${device?.deviceId}`);
  };

  const onChanegCheckBox = (e, device) => {
    const { checked } = e.target;
    if (filteredDevicesRef?.current?.length) {
      let viewList = structuredClone(localSelectedViewDevices?.current);
      if (checked) {
        viewList.push(device?.deviceId);
        if (!newViewDragMode) {
          setNewViewDragMode(true);
        }
      } else {
        const index = viewList.indexOf(device?.deviceId, 0);
        if (index > -1) {
          viewList.splice(index, 1);
        }
      }
      updateViewDevicesDetils(viewList);
      localSelectedViewDevices.current = [...new Set(viewList)];
      setSelectedViewDevices(localSelectedViewDevices?.current);
    }
  };
  const getDeviceStatus = (device) => {
    if (
      device?.displayDeviceStatus === constants.DEVICES_RETURN_OFFLINE_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-offline" />{' '}
          {device?.displayDeviceStatus}
        </div>
      );
    } else if (
      device?.displayDeviceStatus === constants.DEVICES_RETURN_ONLINE_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-online" />{' '}
          {device?.displayDeviceStatus}
        </div>
      );
    }

    return null;
  };

  const columnsData1 = useMemo(
    () => [
      {
        id: 'viewChecked',
        Header: '',
        accessor: 'viewChecked',
        disableSortBy: true,
        Cell: ({ row }) => {
          let device = row.original;
          return (
            <div className="viewCheckbox">
              {device?.viewChecked ? (
                <MdOutlineDragIndicator className="drag-icon" />
              ) : null}
              <input
                id={`checkbox-${device.deviceId}`}
                type="checkbox"
                checked={localSelectedViewDevices?.current?.includes(
                  device?.deviceId,
                )}
                onChange={(e) => onChanegCheckBox(e, device)}
              />
            </div>
          );
        },
      },
      {
        Header: '',
        accessor: 'snapshot',
        disableSortBy: true,
        Cell: ({ row }) => {
          let device = row.original;
          return (
            <span className="snapshot-wrapper">
              <CameraWallSnapshot
                key={device.deviceId}
                deviceElemId={`camera${device.deviceId}`}
                orgId={orgDetails?.orgId}
                cdnInfo={cdnInfo}
                locationAreaName={device.locationAreaNames}
                isHighlighted={false}
                showZoomOptions={false}
                conStatus={device.connectionStatus || 'offline'}
                hubId={device.deviceId}
                isScrolled={isScrolled}
                displayDeviceStatus={Utils.getDeviceStatus(
                  device?.deviceStatus,
                  device?.connectionStatus,
                )}
                {...device}
              />
            </span>
          );
        },
      },
      {
        Header: constants.DEVCIE_WALL_HEADER_DEVICE,
        accessor: 'deviceName',
      },
      {
        Header: constants.DEVCIE_WALL_HEADER_lOCATION,
        accessor: 'locationName',
        sortType: 'basic',
      },
      {
        Header: constants.DEVCIE_WALL_HEADER_AREA,
        accessor: 'areaName',
        sortType: 'basic',
      },
      {
        Header: constants.DEVCIE_WALL_HEADER_STATUS,
        accessor: 'displayDeviceStatus',
        Cell: ({ row }) => {
          let device = row.original;
          return <>{getDeviceStatus(device)}</>;
        },
      },
    ],
    [],
  );

  const columnsData2 = useMemo(
    () => [
      {
        id: 'viewChecked',
        Header: '',
        accessor: 'viewChecked',
        disableSortBy: true,
        Cell: ({ row }) => {
          let device = row.original;
          return (
            <div className="viewCheckbox">
              {device?.viewChecked ? (
                <MdOutlineDragIndicator className="drag-icon" />
              ) : null}
              <input
                id={`checkbox-${device.deviceId}`}
                type="checkbox"
                checked={localSelectedViewDevices?.current?.includes(
                  device?.deviceId,
                )}
                onChange={(e) => onChanegCheckBox(e, device)}
              />
            </div>
          );
        },
      },
      {
        Header: '',
        accessor: 'snapshot',
        disableSortBy: true,
        Cell: ({ row }) => {
          let device = row.original;
          return (
            <span className="snapshot-wrapper">
              <CameraWallSnapshot
                key={device.deviceId}
                deviceElemId={`camera${device.deviceId}`}
                orgId={orgDetails?.orgId}
                cdnInfo={cdnInfo}
                locationAreaName={device.locationAreaNames}
                isHighlighted={false}
                showZoomOptions={false}
                conStatus={device.connectionStatus || 'offline'}
                hubId={device.gatewayId}
                isScrolled={isScrolled}
                displayDeviceStatus={Utils.getDeviceStatus(
                  device?.deviceStatus,
                  device?.connectionStatus,
                )}
                {...device}
              />
            </span>
          );
        },
      },
      {
        Header: constants.DEVCIE_WALL_HEADER_DEVICE,
        accessor: 'deviceName',
        disableSortBy: false,
        Cell: ({ row }) => {
          let device = row.original;
          return (
            <span
              className="deviceNameClick"
              onClick={() => OnClickCameraDetails(row.original)}
            >
              {device.deviceName}
            </span>
          );
        },
      },
      {
        Header: constants.DEVCIE_WALL_HEADER_lOCATION,
        accessor: 'locationName',
        disableSortBy: false,
        sortType: 'basic',
      },
      {
        Header: constants.DEVCIE_WALL_HEADER_AREA,
        accessor: 'areaName',
        disableSortBy: false,
        sortType: 'basic',
      },
      {
        Header: constants.DEVCIE_WALL_HEADER_STATUS,
        accessor: 'displayDeviceStatus',
        Cell: ({ row }) => {
          let device = row.original;
          return <>{getDeviceStatus(device)}</>;
        },
        disableSortBy: false,
      },
      {
        Header: '',
        accessor: 'cameraOptions',
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <NavDropdown
              className="table-action-dropdown"
              title={
                <div>
                  <img src={deviceOptions} alt=""></img>
                </div>
              }
            >
              <NavDropdown.Item
                onClick={() => OnClickCameraDetails(row.original)}
              >
                <HiOutlineVideoCamera
                  size={20}
                  color={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--greyscale_64')}
                />
                {constants.DEVCIE_WALL_DEVICE_OPTION_DETAILS}
              </NavDropdown.Item>
              {userPolicies.view_device_settings && (
                <NavDropdown.Item
                  onClick={() => OnClickCameraSettings(row.original)}
                >
                  <FiSettings
                    size={20}
                    color={getComputedStyle(
                      document.documentElement,
                    ).getPropertyValue('--greyscale_64')}
                  />
                  {constants.DEVCIE_WALL_DEVICE_OPTION_SETTINGS}
                </NavDropdown.Item>
              )}
            </NavDropdown>
          );
        },
      },
    ],
    [],
  );

  const sortingData = useMemo(
    () => [
      {
        id: 'deviceName',
        desc: true,
      },
      {
        id: 'locationName',
        desc: true,
      },
      {
        id: 'areaName',
        desc: true,
      },
      {
        id: 'connectionStatus',
        desc: true,
      },
      {
        id: 'displayDeviceStatus',
        desc: true,
      },
    ],
    [],
  );

  useEffect(() => {
    const accountSnap = getDeviceSnapshots(accountId);
    if (new Date().getTime() - accountSnap > AppDefaults.DAY_IN_MILLISECONDS) {
      resetDeviceSnapshots();
      setDeviceSnapshots(accountId, new Date().getTime());
    }
  }, []);

  const onSortingChange = (field, sortOrder) => {
    setIsDataLoading(true);
    dispatch(setFiltersChanged(true));
    setCurrentCameraWallListMetaData({
      ...currentCameraWallListMetaData,
      pageNo: 0,
      orderBy: field,
      sortOrder: sortOrder,
    });
    setIsRefetchData(!isReFetchData);
  };

  return (
    <div
      className="camera-wall-devices"
      style={redirectingLoader ? { pointerEvents: 'none' } : {}}
    >
      {redirectingLoader && (
        <div className="redirecting-loader loading-container">
          <SiteSpinner height="50px" width="50px" />
          <div className="mt-2">{constants.LOADING}</div>
        </div>
      )}
      <div className="main-page-wrapper">
        <div className="filter-wrapper">
          <div className="search-all-container">
            <IoIosSearch className="search-camera-icon" />
            <input
              id="deviceSearch"
              type="text"
              placeholder="Search All"
              className="search-camera-input"
              onKeyDown={(e) => onChangeSearchText(e)}
            />
            {searchText ? (
              <RxCrossCircled
                className="search-close"
                onClick={() => {
                  setSearchText('');
                  document.getElementById('deviceSearch').value = '';
                }}
              />
            ) : null}
          </div>
          {userPolicies.view_location ? (
            <LocationAreaSelector locations={custOrgLocations} />
          ) : null}
        </div>
        <div className="table-scroll relative">
          {isLoading || (hasDevices && !filteredDevicesRef?.current?.length) ? (
            <div className="loading-container">
              <SiteSpinner height="50px" width="50px" />
              <div className="mt-2">{constants.LOADING}</div>
            </div>
          ) : hasDevices || filteredDevicesRef?.current?.length !== 0 ? (
            <CameraWallTable
              columns={viewId ? columnsData1 : columnsData2}
              data={filteredDevicesRef?.current}
              defaultSortByData={sortingData}
              viewDevices={localSelectedViewDevices.current}
              viewId={viewId}
              newViewDragMode={newViewDragMode}
              viewDevicesDetails={viewDevicesDetails}
              cdnInfo={cdnInfo}
              getDeviceStatus={getDeviceStatus}
              editView={editView}
              isDragging={isDragging}
              isScrolled={isScrolled}
              hasMore={
                cameraWallDevicesMetaData
                  ? !cameraWallDevicesMetaData.last
                  : false
              }
              showLoadingText={showLoadingText}
              loadMore={handleLoadMore}
              onSortingChange={onSortingChange}
              isDataLoading={isDataLoading}
            />
          ) : (
            hasDevices === false &&
            filteredDevicesRef?.current?.length === 0 && (
              <div className="no-cameras-container">
                <div className="no-cameras-icon-container">
                  <FiCameraOff
                    stroke={getComputedStyle(
                      document.documentElement,
                    ).getPropertyValue('--greyscale_56')}
                    size={32}
                  />
                </div>
                <div className="no-cameras-notice">
                  {constants.CAMERAS_VIDEO_WALL_NO_CAMERAS_AVAILABLE_LABEL}
                </div>
                {userPolicies.install_device && userPolicies.claim_device && (
                  <div className="no-cameras-cta">
                    <Button
                      className="no-cameras-add-device-btn"
                      onClick={(e) => {
                        navigate(
                          `/devices/listing.html?orgId=${orgDetails?.orgId}`,
                        );
                      }}
                    >
                      <span>
                        <MdOutlineAddAPhoto
                          stroke={getComputedStyle(
                            document.documentElement,
                          ).getPropertyValue('--brand_white')}
                          size={16}
                        />
                      </span>
                      {constants.CAMERAS_VIDEO_WALL_ADD_CAMERA_LABEL}
                    </Button>
                  </div>
                )}
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default CameraWallDevices;
