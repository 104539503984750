import { useEffect, useRef, useState } from 'react';
import { Accordion, Alert, Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SelectField, TextField } from '../../../../components/forms';
import { Field, Formik } from 'formik';
import { constants, Utils } from '../../../../helpers';
import {
  HiOutlineExclamationCircle,
  HiOutlineInformationCircle,
} from 'react-icons/hi';
import { FaCircle } from 'react-icons/fa';
import moment from 'moment';
import timezones from '../../../../data/support/timezone.json';
import {
  deleteKeepLiveSession,
  publishAddDeviceSetting,
  publishHubDeviceSetting,
  publishNetworkChannelLocalSetting,
  publishDeviceAccessUiStatus,
} from '../shared/PublishDeviceSettings';
import './HubInfoBlock.scss';
import {
  getLocationsData,
  getDeviceInformation,
  getAllMqttDataFromResponse,
  setAllMqttDataFromResponse,
  setDeviceInformation,
} from '../../../../store/reducers/AccountReducer';
import { findTimeZoneFromSelectedValue } from '../../../../helpers/commonUtils';
import LocationSelectField from '../../../../components/forms/LocationSelectField';
import { publishFirmwareDeviceSetting } from '../shared/PublishDeviceSettings';
import * as Yup from 'yup';
import {
  PrimaryButton,
  SiteModal,
  SiteSpinner,
  TextBlock,
} from '../../../../components/common';
import { getManufacturerSKUList } from '../../../../store/reducers/NVRDeviceReducer';
import {
  ErrorInfo,
  LaunchDisable,
  LaunchEnable,
  ErrorInfoActive,
} from '../../../../assets/images';
import { LocalUIHttp } from '../../../../helpers/enums';
import {
  mqttSubscribe,
  mqttUnsubscribe,
} from '../../../../utils/connection/mqttConnection';
import { RxCross2 } from 'react-icons/rx';
import { useDispatch } from 'react-redux';
import axios from 'axios';

const HubInfoBlock = ({
  deviceId,
  deviceInfos,
  deviceDetails,
  saveAudioSettingsFlag,
  saveAudioSettingsCallback,
  moveDeviceDetailCallback,
  deviceNameLocationChangeEvent,
  updateStore,
  accountId,
  uuid,
  devicePublishId,
  updateFirmware,
  hasManagePermission,
  sessionId,
}) => {
  const deviceHubDetails = useSelector(getDeviceInformation);
  const { t } = useTranslation();
  const formRef = useRef();
  const [variant, setVariant] = useState('danger');
  const [userMsg, setUserMsg] = useState('');
  const [showMsg, setShowMsg] = useState(false);
  const [deviceName, setDeviceName] = useState(deviceInfos?.deviceName);
  const [deviceInfo, setDeviceInfo] = useState(deviceInfos);
  const [domainStatusHub, setDomainStatusHub] = useState('');
  const [selectedAreaLocation, setSelectedAreaLocation] = useState({});
  const zoneTimeList = timezones?.data;
  const timezone = deviceInfos?.properties?.timezone;
  const idLocation = deviceInfos?.locationId;
  const [locationId, setlocationId] = useState(idLocation);
  const [currentUiStatus, setCurrentUiStatus] = useState(1);
  const [showUrl, setShowUrl] = useState(false);
  const [activeSessionModal, setActiveSessionModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [currentUserTimezone, setCurrentUserTimezone] = useState(timezone);
  const [showLoader, setShowLoader] = useState(false);
  const [currentTime, setCurrentTime] = useState(
    moment
      .tz(
        moment(),
        timezone
          ? findTimeZoneFromSelectedValue(timezone)?.location
          : moment.tz.guess(),
      )
      .format('MMM DD, YYYY hh:mm:ss A z'),
  );
  const locationsData = useSelector(getLocationsData);
  const deviceChannel = useSelector(getDeviceInformation);
  const deviceState = deviceDetails?.deviceStatus;
  const connectionState = deviceDetails?.connectionStatus;
  const curDeviceStatus = Utils.getDeviceStatus(deviceState, connectionState);
  const canChangeSettings =
    curDeviceStatus === constants.DEVICES_RETURN_ONLINE_STATUS &&
    hasManagePermission;
  const resourceList = Utils.getDeviceSettingResource(0);
  const neworkProperty = deviceInfos?.properties;
  const resourceListUpdate = Utils.getDeviceSettingResource(6);
  const capabilityList = deviceInfos?.capDetails?.capabilities;
  const [showSpiner, setShowSpiner] = useState(false);
  const capbilityLocalUi = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[2],
  );
  const tid = Math.floor(new Date().getTime() / 1000.0);
  const publishPayload = {
    transactionID: tid,
    accountId: accountId,
    deviceId: deviceId,
    hubId: deviceInfos?.gatewayId,
    uuid: sessionId,
    devicePublishId: devicePublishId,
  };
  const [updateDeviceFirmwareModal, setUpdateDeviceFirmwareModal] =
    useState(false);
  const [updateCloudConnectModal, setUpdateCloudConnectModal] = useState(false);
  const manufacturerSKUList = useSelector(getManufacturerSKUList);
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  const [isTabActive, setIsTabActive] = useState(true); // Track tab visibility
  const intervalTime = 78000;
  let timeoutId;
  const dispatch = useDispatch();

  useEffect(() => {
    const devicePropertyErrorSubscribe = {
      topic: `d/notify/${accountId}/${sessionId}`,
      qos: 0,
    };
    mqttSubscribe(devicePropertyErrorSubscribe);

    return () => {
      const channelPropertyKeepAlive = {
        sessionId: uuid,
        events: [
          {
            notifications: ['proxyConnection'],
          },
        ],
      };
      deleteKeepLiveSession(
        channelPropertyKeepAlive,
        LocalUIHttp?.KEEP_ALIVE_RESOURCE,
        publishPayload,
      );
      mqttUnsubscribe(devicePropertyErrorSubscribe);
    };
  }, []);

  const sendRequest = () => {
    const channelProperty = {
      sessionId: uuid,
      events: [
        {
          notifications: ['proxyConnection'],
        },
      ],
    };
    setTimeout(() => {
      publishNetworkChannelLocalSetting(
        channelProperty,
        LocalUIHttp?.KEEP_ALIVE_RESOURCE,
        publishPayload,
      );
    }, 7000);
  };

  // Function to handle the task, schedules the next task after 85 seconds
  const performTask = () => {
    if (isTabActive) {
      sendRequest();
    }

    // Schedule the next task to be performed after 85 seconds
    timeoutId = setTimeout(performTask, intervalTime); // Recurse every 85 seconds
  };

  useEffect(() => {
    setDomainStatusHub(
      deviceInfo?.domainStatus ? deviceInfo?.domainStatus : '',
    );
  }, [deviceInfo?.gatewayId]);

  // UseEffect to start the task on component mount
  useEffect(() => {
    setActiveSessionModal(false);
    // Function to check if the tab is active
    if (deviceInfos?.gatewayId) {
      const handleVisibilityChange = () => {
        if (document.hidden) {
          setIsTabActive(false); // Tab is inactive
        } else {
          setIsTabActive(true); // Tab is active
        }
      };

      // Listen for visibility change events
      document.addEventListener('visibilitychange', handleVisibilityChange);

      // Start the first task
      performTask();

      // Cleanup function to clear timeout and remove event listener
      return () => {
        clearTimeout(timeoutId); // Clear the timeout when the component unmounts
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange,
        );
      };
    }
  }, [deviceInfos?.gatewayId]);

  useEffect(() => {
    let subscribeForEvents;
    if (deviceInfos?.gatewayId) {
      subscribeForEvents = {
        topic: `d/rt-events/${deviceInfos?.gatewayId}`,
        qos: 0,
      };
      mqttSubscribe(subscribeForEvents);
    }
    return () => {
      if (subscribeForEvents) {
        mqttUnsubscribe(subscribeForEvents);
      }
    };
  }, [deviceInfos?.gatewayId]);

  useEffect(() => {
    if (deviceInfos?.gatewayId) {
      if (isTabActive) {
        const channelProperty = {
          channelId: deviceInfos?.deviceId,
          session: uuid,
        };
        publishDeviceAccessUiStatus(
          channelProperty,
          LocalUIHttp?.DEVICE_ACCESS_UI_STATUS,
          publishPayload,
        );
        setTimeout(() => {
          sendAddRequestKeepAlive();
        }, 2000);
      }
    }
  }, [deviceInfos?.gatewayId, isTabActive]);

  const connectDisconnectCall = () => {
    setShowError(false);
    if (currentUiStatus === 1) {
      mqttCallConnect(false);
    } else if (currentUiStatus === 3) {
      setActiveSessionModal(true);
    } else if (currentUiStatus === 2) {
      if (deviceInfos?.gatewayId) {
        setShowSpiner(true);
        const channelProperty = {
          channelId: deviceInfos?.deviceId,
          session: uuid,
        };
        publishNetworkChannelLocalSetting(
          channelProperty,
          LocalUIHttp?.DEVICE_ACCESS_UI_DISCONNECT,
          publishPayload,
        );
      }
    } else if (currentUiStatus === 5) {
      setShowSpiner(true);
      createConfigurationCall();
    }
  };

  const createConfigurationCall = () => {
    axios
      .post(
        `device/orgs/${deviceInfo?.orgId}/devices/${deviceId}/domain/configuration`,
        {},
        Utils.requestHeader(),
      )
      .then((response) => {
        const resultData = response.data;
        if (resultData) {
          const responseMeta = resultData.meta;
          const responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            console.log('responseData', responseData);
            if (
              responseData?.config?.domainStatus ===
              LocalUIHttp.STATUS_IN_PROGRESS
            ) {
              updateDeviceStore(LocalUIHttp.STATUS_IN_PROGRESS, 4);
            } else if (
              responseData?.config?.domainStatus ===
              LocalUIHttp.STATUS_AVAILABLE
            ) {
              updateDeviceStore(LocalUIHttp.STATUS_AVAILABLE, 1);
            } else {
              updateDeviceStore(LocalUIHttp.STATUS_ERROR, 5);
            }
            setShowSpiner(false);
          } else {
            setShowSpiner(false);
          }
        }
      });
  };

  const updateDeviceStore = (domainStatus, status) => {
    const updatedDeviceInformation = {
      ...deviceHubDetails,
      domainStatus: domainStatus,
    };
    setDomainStatusHub(domainStatus);
    setCurrentUiStatus(status);
    dispatch(setDeviceInformation(updatedDeviceInformation));
  };

  const mqttCallConnect = (isNormalConnect) => {
    setShowSpiner(true);
    const channelProperty = {
      forceDisconnect: isNormalConnect,
      session: uuid,
    };
    publishNetworkChannelLocalSetting(
      channelProperty,
      LocalUIHttp?.DEVICE_ACCESS_UI,
      publishPayload,
    );
  };

  const sendAddRequestKeepAlive = () => {
    const channelPropertyKeepAlive = {
      sessionId: uuid,
      events: [
        {
          notifications: ['proxyConnection'],
        },
      ],
    };
    publishAddDeviceSetting(
      channelPropertyKeepAlive,
      LocalUIHttp?.KEEP_ALIVE_RESOURCE,
      publishPayload,
    );
  };

  useEffect(() => {
    if (
      allMqttData?.msg?.resource === LocalUIHttp?.DEVICE_ACCESS_UI_STATUS &&
      allMqttData?.msg?.action?.toLowerCase() === LocalUIHttp?.ACTION_IS
    ) {
      const uiStatusObj = allMqttData?.msg?.properties;
      if (
        uiStatusObj?.status === LocalUIHttp?.STATUS_ACTIVE &&
        uiStatusObj?.channelId === deviceInfos?.deviceId
      ) {
        updateDeviceStore(LocalUIHttp.STATUS_AVAILABLE, 2);
        setShowUrl(uiStatusObj?.subdomain);
        setCurrentUiStatus(2);
        setShowSpiner(false);
      } else if (
        uiStatusObj?.status === LocalUIHttp?.STATUS_ACTIVE &&
        uiStatusObj?.channelId != deviceInfos?.deviceId
      ) {
        updateDeviceStore(LocalUIHttp.STATUS_AVAILABLE, 3);
        setCurrentUiStatus(3);
        setShowSpiner(false);
      } else if (uiStatusObj?.status === LocalUIHttp?.IN_ACTIVE_STATUS) {
        if (
          domainStatusHub != '' &&
          domainStatusHub &&
          domainStatusHub === LocalUIHttp.STATUS_AVAILABLE
        ) {
          setCurrentUiStatus(1);
        } else if (
          domainStatusHub != '' &&
          domainStatusHub &&
          domainStatusHub === LocalUIHttp.STATUS_IN_PROGRESS
        ) {
          setCurrentUiStatus(4);
        } else {
          setCurrentUiStatus(5);
        }
        setShowSpiner(false);
      }
    } else if (
      allMqttData?.msg?.resource === LocalUIHttp?.KEEP_ALIVE_RESOURCE &&
      allMqttData?.msg?.action?.toLowerCase() === LocalUIHttp?.ACTION_ERROR &&
      allMqttData?.from === deviceInfos?.gatewayId
    ) {
      if (
        allMqttData?.msg?.properties?.desc != 'The subsciber is already exist'
      ) {
        sendAddRequestKeepAlive();
      }
    } else if (
      allMqttData?.msg?.resource === LocalUIHttp?.KEEP_ALIVE_RESOURCE &&
      allMqttData?.msg?.action?.toLowerCase() === LocalUIHttp?.ACTION_IS
    ) {
      const property = allMqttData?.msg?.properties;
      const dataKeepAlive = property?.[0]?.eventMeta?.events?.[0]?.eventProps;
      if (dataKeepAlive) {
        const uiStatusObj = dataKeepAlive;
        if (
          uiStatusObj?.status === LocalUIHttp?.STATUS_ACTIVE &&
          uiStatusObj?.channelId === deviceInfos?.deviceId
        ) {
          updateDeviceStore(LocalUIHttp.STATUS_AVAILABLE, 2);
          setShowUrl(uiStatusObj?.subdomain);
          setCurrentUiStatus(2);
        } else if (
          uiStatusObj?.status === LocalUIHttp?.STATUS_ACTIVE &&
          uiStatusObj?.channelId != deviceInfos?.deviceId
        ) {
          updateDeviceStore(LocalUIHttp.STATUS_AVAILABLE, 3);
          setCurrentUiStatus(3);
        } else if (uiStatusObj?.status === LocalUIHttp?.IN_ACTIVE_STATUS) {
          if (
            domainStatusHub != '' &&
            domainStatusHub &&
            domainStatusHub === LocalUIHttp.STATUS_AVAILABLE
          ) {
            setCurrentUiStatus(1);
          } else if (
            domainStatusHub != '' &&
            domainStatusHub &&
            domainStatusHub === LocalUIHttp.STATUS_IN_PROGRESS
          ) {
            setCurrentUiStatus(4);
          } else {
            setCurrentUiStatus(5);
          }
        }
      }
    } else if (
      allMqttData?.msg?.resource === LocalUIHttp?.DEVICE_ACCESS_UI_DISCONNECT &&
      allMqttData?.msg?.action?.toLowerCase() === LocalUIHttp?.ACTION_IS
    ) {
      if (
        allMqttData?.msg?.properties?.status ===
        LocalUIHttp?.STATUS_DISCONNECTED
      ) {
        updateDeviceStore(LocalUIHttp.STATUS_AVAILABLE, 1);
        setCurrentUiStatus(1);
        setShowSpiner(false);
      }
    } else if (
      allMqttData?.msg?.resource === LocalUIHttp?.DEVICE_ACCESS_UI_STATUS &&
      allMqttData?.msg?.action?.toLowerCase() === LocalUIHttp?.ACTION_ERROR
    ) {
      setShowSpiner(false);
      setActiveSessionModal(false);
    } else {
      if (
        allMqttData?.msg?.resource === LocalUIHttp?.DEVICE_ACCESS_UI &&
        allMqttData?.msg?.action?.toLowerCase() === LocalUIHttp?.ACTION_IS
      ) {
        setShowSpiner(false);
        setShowLoader(false);
        if (allMqttData?.msg?.properties?.url) {
          const domainUrl = allMqttData?.msg?.properties?.url;
          setShowUrl(domainUrl);
          let fullUrl =
            domainUrl?.indexOf('://') === -1
              ? 'https://' + domainUrl
              : domainUrl;
          window.open(fullUrl, '_blank');
          if (currentUiStatus === 1 || currentUiStatus === 3) {
            setCurrentUiStatus(2);
            setActiveSessionModal(false);
          }
        }
      } else if (
        (allMqttData?.msg?.resource === LocalUIHttp?.DEVICE_ACCESS_UI ||
          allMqttData?.msg?.resource ===
            LocalUIHttp?.DEVICE_ACCESS_UI_DISCONNECT) &&
        allMqttData?.msg?.action?.toLowerCase() === LocalUIHttp?.ACTION_ERROR &&
        allMqttData?.from === deviceInfos?.gatewayId
      ) {
        setShowSpiner(false);
        setShowLoader(false);
        const errorCode = allMqttData?.msg?.properties?.code;
        if (
          errorCode === '3001' ||
          errorCode === '3002' ||
          errorCode === '3003' ||
          errorCode === '3005'
        ) {
          setShowError(constants.HTTP_PROXY_MODAL_TUNNEL_ERROR);
        } else {
          setShowError(constants.HTTP_PROXIY_MODAL_UNKNOWN_ERROR);
        }
        setActiveSessionModal(true);
      }
    }
  }, [allMqttData]);

  useEffect(() => {
    setDeviceInfo(deviceInfos);
  }, [deviceInfos]);

  const onUpdateFirmware = () => {
    const properties = {
      'manual-update': constants.PROPERTY_STATUS_TRUE,
    };
    updateStore(properties);
    publishFirmwareDeviceSetting(
      properties,
      resourceListUpdate[2],
      publishPayload,
      false,
    );
  };

  const onUpdateDeviceFirmware = () => {
    const properties = {
      'manual-dev-update': constants.PROPERTY_STATUS_TRUE,
    };
    updateStore(properties);
    publishFirmwareDeviceSetting(
      properties,
      resourceListUpdate[3],
      publishPayload,
      false,
    );
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, []);

  useEffect(() => {
    if (currentUserTimezone) {
      let secTimer = setInterval(() => {
        setCurrentTime(
          moment
            .tz(
              moment(),
              findTimeZoneFromSelectedValue(currentUserTimezone)?.location,
            )
            .format('MMM DD, YYYY hh:mm:ss A z'),
        );
      }, 1000);
      return () => clearInterval(secTimer);
    }
  }, [currentUserTimezone]);

  useEffect(() => {
    setCurrentUserTimezone(timezone);
    if (locationId !== deviceInfos?.locationId) {
      setlocationId(locationId);
    } else {
      setlocationId(deviceInfos?.locationId);
    }
  }, [deviceInfos]);

  useEffect(() => {
    if (formRef?.current && saveAudioSettingsFlag) {
      formRef?.current?.handleSubmit();
    }
  }, [saveAudioSettingsFlag]);

  useEffect(() => {
    moveDeviceLocation(selectedAreaLocation);
  }, [deviceName, locationId]);

  useEffect(() => {
    if (Object.keys(selectedAreaLocation)?.length !== 0) {
      const getLocationSelected = findTimeZoneFromSelectedValue(
        selectedAreaLocation?.timezone || currentUserTimezone,
      );
      setlocationId(selectedAreaLocation?.locationId);
      setCurrentUserTimezone(getLocationSelected?.location);
    }
  }, [selectedAreaLocation]);

  const moveDeviceLocation = async (slectedAreaLocation) => {
    try {
      const reqBody = {
        oldLocationId: deviceInfos?.locationId,
        oldAreaId: deviceInfos?.areaId,
        olddeviceName: deviceInfos?.deviceName,
        newLocationId:
          Object.keys(slectedAreaLocation).length === 0
            ? deviceInfos?.locationId
            : locationId,
        newAreaId:
          Object.keys(slectedAreaLocation).length === 0
            ? deviceInfos?.areaId
            : slectedAreaLocation.areaId,
        deviceName: deviceName ? deviceName : deviceInfos?.deviceName,
        orgId: deviceInfos?.orgId,
        deviceId: deviceInfos?.deviceId,
      };
      moveDeviceDetailCallback(reqBody);
    } catch (error) {
      Utils.vmsLogger().error('ERROR: ', error);
    }
  };

  const validateAuthenticated = Yup.object({
    deviceName: Yup.string().max(40, constants.DEVICE_NAME_MAX_LENGTH_MESSAGE),
  });

  const onDeviceNameChange = (e, setFieldValue) => {
    const value = e?.target?.value;
    if (value?.length <= 40) {
      deviceNameLocationChangeEvent();
      setDeviceName(value);
      setFieldValue('deviceName', value);
    } else {
      setFieldValue('deviceName', value);
      setDeviceName(deviceInfos?.deviceName);
    }
  };
  const avlbleDeviceVersion = deviceInfos?.properties?.['avlbl-device-ver'];
  const firmwareVersion = deviceInfos?.properties['firmware-version'];

  const hidePopUp = async () => {
    dispatch(setAllMqttDataFromResponse({}));
    setActiveSessionModal(false);
    setShowError(false);
  };
  const openInNewTab = () => {
    setShowError(false);
    if (showUrl) {
      let fullUrl =
        showUrl?.indexOf('://') === -1 ? 'https://' + showUrl : showUrl;
      window.open(fullUrl, '_blank');
    }
  };

  return (
    <div
      className={`text-start audio-settings-block ${
        curDeviceStatus !== constants.DEVICES_RETURN_ONLINE_STATUS
          ? ' editable-off-offline'
          : ''
      }`}
    >
      {showMsg && (
        <Alert variant={variant} onClose={() => setShowMsg(false)} dismissible>
          <div className="d-flex">
            <div>
              <HiOutlineExclamationCircle
                size={20}
                stroke={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--error_24')}
              />
            </div>
            <div className="text-alignment">
              {!userMsg ? (
                <p>{userMsg}</p>
              ) : (
                <>
                  <Alert.Heading>
                    {constants.UNABLE_TO_SAVE_DEVICE_INFO_HEADING}
                  </Alert.Heading>
                  <p>{constants.UNABLE_TO_SAVE_DEVICE_INFO_MESSAGE}</p>
                </>
              )}
            </div>
          </div>
        </Alert>
      )}
      {deviceInfos && (
        <Formik
          enableReinitialize
          innerRef={formRef}
          initialValues={{
            deviceName: deviceInfos?.deviceName,
            volume: 2,
            sensitivity: 2,
            deviceDegrees: '',
            noiseReduction: false,
            audioOutVolume: 2,
            areaData: deviceInfos?.areaId,
            locationData: locationId,
            timezone: findTimeZoneFromSelectedValue(
              currentUserTimezone ? currentUserTimezone : timezone,
            )?.location,
          }}
          validationSchema={validateAuthenticated}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            setVariant('danger');
            setUserMsg('');
            setSubmitting(false);
            saveAudioSettingsCallback(false);
          }}
        >
          {({ values, handleSubmit, setFieldValue, handleChange }) => (
            <Form className="form" onSubmit={handleSubmit}>
              <Row className="settings-block">
                <Col>
                  <div className="section-heading mb-2">
                    <label>{constants.CHANNELS_INFO_DEVICE_NAME_TITLE}</label>
                  </div>
                  <TextField
                    required={true}
                    removebottommargin="true"
                    removetopmargin="true"
                    label=""
                    placeholder={constants.DEVICE_INFO_MEETING_ROOM_TITLE}
                    name="deviceName"
                    value={values.deviceName}
                    type="text"
                    onChange={(e) => onDeviceNameChange(e, setFieldValue)}
                  />
                </Col>
              </Row>
              <div className="section-heading mb-2 mt-4">
                <label>{constants.CHANNELS_INFO_DEVICE_TITLE}</label>
              </div>
              <div className="device-info-section">
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {constants.DEVICE_INFO_DEVICE_STATUS_TITLE}
                    </div>
                    <div className="hub-info-block-content">
                      {curDeviceStatus ===
                      constants.DEVICES_RETURN_ONLINE_STATUS ? (
                        <FaCircle size={10} className="status-icon-online" />
                      ) : curDeviceStatus ===
                        constants.DEVICES_RETURN_OFFLINE_STATUS ? (
                        <FaCircle size={10} className="status-icon-offline" />
                      ) : curDeviceStatus ===
                        constants.DEVICES_RETURN_DEACTIVATED_STATUS ? (
                        <FaCircle
                          size={10}
                          className="status-icon-deactivated"
                        />
                      ) : (
                        <FaCircle size={10} className="status-icon-entered" />
                      )}
                      <div className="block-content">{curDeviceStatus}</div>
                    </div>
                  </Col>
                </Row>
                {curDeviceStatus ===
                  constants.DEVICES_RETURN_OFFLINE_STATUS && (
                  <Alert variant="danger">
                    <div className="d-flex">
                      <div>
                        <HiOutlineInformationCircle
                          size={20}
                          stroke={getComputedStyle(
                            document.documentElement,
                          ).getPropertyValue('--error_24')}
                        />
                      </div>
                      <div className="text-alignment">
                        {userMsg !== '' ? (
                          <p>{userMsg}</p>
                        ) : (
                          <>
                            {constants.CHANNELS_INFO_CANNOT_AUTH_DEVICE_TITLE}
                            <p>
                              {t('CHANNELS_INFO_CANNOT_AUTH_DEVICE_CONTENT')}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </Alert>
                )}
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {constants.DEVICES_MANUFACTURER_TEXT}
                    </div>
                    <div className="hub-info-block-content">
                      {Utils.getManufacturer(deviceInfos?.manufacturer)}
                    </div>
                  </Col>
                </Row>
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {constants.DEVICES_MODEL_TEXT}
                    </div>
                    <div className="hub-info-block-content">
                      {deviceInfos?.modelNumber}
                    </div>
                  </Col>
                </Row>
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {constants.DEVICES_SERIAL_NO_TEXT}
                    </div>
                    <div className="hub-info-block-content">
                      {deviceInfos?.serialNo}
                    </div>
                  </Col>
                </Row>
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {constants.CHANNELS_INFO_CHANNELS_COUNT_TITLE}
                    </div>
                    <div className="hub-info-block-content">
                      {manufacturerSKUList?.find(
                        (item) => item.model === deviceInfos.modelNumber,
                      )?.channelCount || '--'}
                    </div>
                  </Col>
                </Row>
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {constants.DEVICE_INFO_DEVICES_MAC_ADDRESS_TITLE}
                    </div>
                    <div className="hub-info-block-content">
                      {deviceInfos?.macAddress}
                    </div>
                  </Col>
                </Row>
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {constants.CHANNELS_INFO_DEVICE_FIRMWARE_TITLE}
                    </div>

                    <div className="hub-info-block-content">
                      {firmwareVersion ? firmwareVersion : '--'}
                    </div>
                  </Col>
                </Row>
                {connectionState ===
                  constants.DEVICES_ONLINE_CONNECTION_STATUS &&
                  avlbleDeviceVersion &&
                  firmwareVersion?.toLowerCase() !==
                    avlbleDeviceVersion?.toLowerCase() && (
                    <Row className="settings-block">
                      <Col>
                        <div
                          className="alert alert-danger info-update"
                          role="alert"
                        >
                          <div className="alert-message">
                            <HiOutlineExclamationCircle
                              size={15}
                              stroke={getComputedStyle(
                                document.documentElement,
                              ).getPropertyValue('--error_24')}
                              className="alert-icon"
                            />
                          </div>
                          <p>
                            {constants.CHANNELS_INFO_UPDATE_AVAILABLE}{' '}
                            <span
                              onClick={() => setUpdateDeviceFirmwareModal(true)}
                              style={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                textDecorationColor: getComputedStyle(
                                  document.documentElement,
                                ).getPropertyValue('--error_24'),
                              }}
                            >
                              {constants.HERE_LABEL}
                            </span>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  )}
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {t('CHANNELS_INFO_FIRMWARE_TITLE')}
                    </div>
                    <div className="hub-info-block-content">
                      {deviceInfos?.properties?.['edge-app-version']
                        ? deviceInfos?.properties?.['edge-app-version']
                        : '--'}
                    </div>
                  </Col>
                </Row>
                {connectionState ===
                  constants.DEVICES_ONLINE_CONNECTION_STATUS &&
                  deviceInfos?.properties?.['avlbl-duclo-ver'] &&
                  deviceInfos?.properties?.['edge-app-version'] !==
                    deviceInfos?.properties?.['avlbl-duclo-ver'] && (
                    <Row className="settings-block">
                      <Col>
                        <div
                          className="alert alert-danger info-update"
                          role="alert"
                        >
                          <div className="alert-message">
                            <HiOutlineExclamationCircle
                              size={15}
                              stroke={getComputedStyle(
                                document.documentElement,
                              ).getPropertyValue('--error_24')}
                              className="alert-icon"
                            />
                          </div>
                          <p>
                            {constants.CHANNELS_INFO_UPDATE_AVAILABLE}{' '}
                            <span
                              onClick={() => setUpdateCloudConnectModal(true)}
                              style={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                textDecorationColor: getComputedStyle(
                                  document.documentElement,
                                ).getPropertyValue('--error_24'),
                              }}
                            >
                              {constants.HERE_LABEL}
                            </span>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  )}
              </div>
              <div className="section-heading mb-2 mt-4">
                <label>{constants.CHANNELS_INFO_NETWORK_TITLE}</label>
              </div>
              <div className="device-info-section">
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {constants.DEVICES_TAB_NETWORK_IP_TYPE_TITLE}
                    </div>
                    <div className="hub-info-block-content">
                      {neworkProperty?.['ip-type'] || '--'}
                    </div>
                  </Col>
                </Row>
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {constants.DEVICES_TAB_NETWORK_IP_ADDRESS_TITLE}
                    </div>
                    <div className="hub-info-block-content">
                      {neworkProperty?.['ip-address'] || '--'}
                    </div>
                  </Col>
                </Row>
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {constants.DEVICES_MAC_ADDRESS_TEXT}
                    </div>
                    <div className="hub-info-block-content">
                      {deviceInfos?.macAddress}
                    </div>
                  </Col>
                </Row>
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {constants.DEVICES_TAB_NETWORK_SUBNET_MASK_TITLE}
                    </div>
                    <div className="hub-info-block-content">
                      {neworkProperty?.['subnet-mask'] || '--'}
                    </div>
                  </Col>
                </Row>
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {constants.DEVICES_TAB_NETWORK_DNS1_TITLE}
                    </div>
                    <div className="hub-info-block-content">
                      {neworkProperty?.['primary-dns'] || '--'}
                    </div>
                  </Col>
                </Row>
                {neworkProperty?.['secondary-dns'] && (
                  <Row className="settings-block">
                    <Col>
                      <div className="hub-info-block-heading">
                        {constants.DEVICES_TAB_NETWORK_DNS2_TITLE}
                      </div>
                      <div className="hub-info-block-content">
                        {neworkProperty?.['secondary-dns'] || '--'}
                      </div>
                    </Col>
                  </Row>
                )}
              </div>

              <div className="section-heading mb-2 mt-4">
                <label>{constants.CHANNELS_INFO_LOCATION_TEXT}</label>
              </div>
              <div className="device-info-section">
                <Accordion>
                  <Accordion.Item>
                    <div className="channel-info radio-wrapper">
                      {locationsData?.map((location) => (
                        <div className="radiotitle" key={location.locationId}>
                          <Col>
                            <div
                              style={{
                                color: getComputedStyle(
                                  document.documentElement,
                                ).getPropertyValue('--greyscale_08'),
                              }}
                            >
                              {location.locationName}
                            </div>
                          </Col>
                          <Col xs={1} md={1} lg={1} className="makingBig">
                            <Field
                              type="radio"
                              name="locationId"
                              value={values.locationData}
                              checked={
                                values.locationData === location.locationId
                              }
                              onClick={() => {
                                if (!canChangeSettings) {
                                  return;
                                }
                                deviceNameLocationChangeEvent();
                                const selectedArea = location.areas.find(
                                  (area) => area.isDefault,
                                );
                                const selectedLocationArea = {
                                  areaId:
                                    selectedArea?.areaId ||
                                    location?.areas?.[0]?.areaId,
                                  locationId: location.locationId,
                                  timezone:
                                    location?.timezone || moment.tz.guess(),
                                };
                                setFieldValue(
                                  'locationData',
                                  selectedLocationArea?.locationId,
                                );
                                setFieldValue(
                                  'areaData',
                                  selectedLocationArea?.areaId,
                                );
                                setSelectedAreaLocation(selectedLocationArea);
                                moveDeviceLocation(selectedLocationArea);
                              }}
                            />
                          </Col>
                        </div>
                      ))}
                    </div>
                  </Accordion.Item>
                </Accordion>
              </div>
              <Row className="settings-block">
                <Col>
                  <div className="section-heading mb-2 mt-4">
                    <label>{constants.CHANNELS_INFO_DATE_TIME_TITLE}</label>
                  </div>

                  <div className="hub-info-block-heading">
                    {constants.DEVICE_INFO_CURRENT_TIME_TITLE}
                  </div>
                  <div className="hub-info-block-content time-zone-select">
                    {currentTime}
                  </div>
                  <div className="hub-info-block-content device">
                    <LocationSelectField
                      name="timezone"
                      options={zoneTimeList}
                      label=""
                      value={values.timezone}
                      onChange={(evebr) => {
                        if (!canChangeSettings) {
                          return;
                        }
                        let selectedVal = evebr?.target?.value;
                        const getLocationSelected =
                          findTimeZoneFromSelectedValue(selectedVal);
                        setCurrentUserTimezone(getLocationSelected.location);
                        setFieldValue('timezone', getLocationSelected.label);
                        const property = {
                          timezone: selectedVal.toString(),
                        };
                        updateStore(property);
                        publishHubDeviceSetting(property, resourceList[1], {
                          deviceId: deviceId,
                          devicePublishId: devicePublishId,
                          hubId: deviceInfos?.gatewayId,
                          uuid: uuid,
                          accountId: accountId,
                        });
                      }}
                    />
                  </div>
                  <div className="label-timezone device-setting">
                    {constants.DEVICE_ADD_TIMEZONE_MESSAGE}
                  </div>
                </Col>
              </Row>

              {Utils.getPropertyShowStatus(
                curDeviceStatus,
                'isProxyConnSupport',
                capbilityLocalUi,
              ) && (
                <Row className="settings-block mt-3">
                  <Col>
                    <div className="local-camera-setting">
                      {constants.HTTP_PROXIY_LOCAL_CAMERA_SETTING_HUB}
                    </div>
                    <div className="http-proxiy-label-title">
                      {constants.HTTP_PROXIY_LOCAL_CAMERA_SETTING_MESSAGE_HUB}
                    </div>
                    {currentUiStatus != 1 &&
                      currentUiStatus != 4 &&
                      currentUiStatus != 5 && (
                        <div
                          className={`error-container-http ${
                            currentUiStatus == 2 &&
                            'active-seesion-same-channel'
                          }`}
                        >
                          <img
                            className="img-error-info"
                            alt=""
                            src={
                              currentUiStatus == 2 ? ErrorInfoActive : ErrorInfo
                            }
                          ></img>
                          <div
                            className={`error-message-http ${
                              currentUiStatus == 2 &&
                              'active-seesion-same-channel'
                            } `}
                          >
                            {currentUiStatus == 2
                              ? constants.HTTP_PROXIY_ACTIVE_SESSION_SAME_CHANNEL_SAME_HUB
                              : constants.HTTP_PROXIY_ACTIVE_SESSION_OTHER_CHANNEL_SAME_HUB_DEVICE}
                          </div>
                        </div>
                      )}
                    <div className="remotly-connect-layout">
                      <div className="left-container-setting">
                        <div className="remotly-label">
                          {constants.HTTP_PROXIY_REMOTELY_CONNECT_HUB}
                        </div>
                        <img
                          onClick={() => openInNewTab()}
                          className={`img-launch ${
                            currentUiStatus !== 2 && 'disable-launch'
                          }`}
                          alt=""
                          src={
                            currentUiStatus == 3 ||
                            currentUiStatus == 1 ||
                            currentUiStatus == 4 ||
                            currentUiStatus == 5
                              ? LaunchDisable
                              : LaunchEnable
                          }
                        ></img>
                      </div>
                      <div className="right-container-setting">
                        {!showSpiner && (
                          <div
                            onClick={(e) => connectDisconnectCall(e)}
                            className={`${
                              curDeviceStatus ===
                                constants.DEVICES_RETURN_OFFLINE_STATUS ||
                              currentUiStatus == 4
                                ? 'connect-disconnect-disabled-text'
                                : 'connect-disconnect-text'
                            }`}
                          >
                            {currentUiStatus == 3 || currentUiStatus == 1
                              ? constants.HTTP_PROXIY_CONNECT_LABEL
                              : currentUiStatus == 5
                                ? constants.HTTP_PROXIY_CREATE_CONFIGURATION
                                : currentUiStatus == 4
                                  ? constants.HTTP_PROXIY_IN_PROGRESS_CONFIGURATION
                                  : constants.HTTP_PROXIY_DISCONNECT_LABEL}
                          </div>
                        )}
                        {showSpiner && (
                          <SiteSpinner height="20px" width="20px" />
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </Form>
          )}
        </Formik>
      )}
      {/* update device firmware - modal */}
      <SiteModal
        modalTitle={constants.UPDATE_DEVICE_FIRMWARE}
        showModal={updateDeviceFirmwareModal}
        hideModal={() => {
          setUpdateDeviceFirmwareModal(false);
        }}
        classes="device-settings-restart-required"
      >
        <TextBlock
          className="mb-4"
          fontWeight="400"
          fontSize="1rem"
          lineHeight="24px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--greyscale_56',
          )}
        >
          {constants.UPDATE_DEVICE_FIRMWARE_TITLE_INFO}
        </TextBlock>

        <PrimaryButton
          className="btn btn-primary-outline mb-2"
          type="button"
          width="100%"
          borderWidth="1.5px"
          hoverBorderWidth="1.5px"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          onClick={() => {
            setUpdateDeviceFirmwareModal(false);
          }}
        >
          {constants.UPDATE_FIRMWARE_CANCEL_BUTTON_LABEL}
        </PrimaryButton>

        <PrimaryButton
          className="btn btn-primary-outline text-capitalize mt-1"
          type="button"
          width="100%"
          borderWidth="1.5px"
          hoverBorderWidth="1.5px"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          borderColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--error_64')}
          hoverBorderColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--error_64')}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--error_64',
          )}
          hoverColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--brand_white')}
          hoverBackgroundColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--error_64')}
          backgroundColor="transparent"
          onClick={() => {
            onUpdateDeviceFirmware();
            setUpdateDeviceFirmwareModal(false);
          }}
        >
          {constants.UPDATE_FIRMWARE_BUTTON_LABEL}
        </PrimaryButton>
      </SiteModal>
      {/* update cloud connect firmware - modal */}
      <SiteModal
        modalTitle={t('UPDATE_CLOUD_CONNECT_FIRMWARE_TITLE')}
        showModal={updateCloudConnectModal}
        hideModal={() => {
          setUpdateCloudConnectModal(false);
        }}
        classes="device-settings-restart-required"
      >
        <TextBlock
          className="mb-4"
          fontWeight="400"
          fontSize="1rem"
          lineHeight="24px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--greyscale_56',
          )}
        >
          {constants.UPDATE_DEVICE_FIRMWARE_TITLE_INFO}
        </TextBlock>

        <PrimaryButton
          className="btn btn-primary-outline mb-2"
          type="button"
          width="100%"
          borderWidth="1.5px"
          hoverBorderWidth="1.5px"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          onClick={() => {
            setUpdateCloudConnectModal(false);
          }}
        >
          {constants.UPDATE_FIRMWARE_CANCEL_BUTTON_LABEL}
        </PrimaryButton>
        <PrimaryButton
          className="btn btn-primary-outline text-capitalize mt-1"
          type="button"
          width="100%"
          borderWidth="1.5px"
          hoverBorderWidth="1.5px"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          borderColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--error_64')}
          hoverBorderColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--error_64')}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--error_64',
          )}
          hoverColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--brand_white')}
          hoverBackgroundColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--error_64')}
          backgroundColor="transparent"
          onClick={() => {
            onUpdateFirmware();
            setUpdateCloudConnectModal(false);
          }}
        >
          {constants.UPDATE_FIRMWARE_BUTTON_LABEL}
        </PrimaryButton>
      </SiteModal>
      <SiteModal
        modalTitle={constants.HTTP_PROXIY_LOCAL_CAMERA_SETTING_HUB}
        showModal={activeSessionModal}
        hideModal={() => {
          hidePopUp();
        }}
        classes="device-settings-restart-required"
      >
        <div className="modal-local-ui">
          <div className="image-icon-div">
            <RxCross2 size={30} className="img-cross" />
          </div>
          <div className="message-modal-connect">
            {!showError
              ? constants.HTTP_PROXIY_MODAL_ERROR_MESSAGE_REMOTE_SESSION
              : showError}
          </div>
          {!showError && (
            <div className="label-modal-text">
              {constants.HTTP_PROXIY_MODAL_SUB_TITLE_HUB}
            </div>
          )}
          <PrimaryButton
            className="btn btn-primary mt-3"
            type="button"
            width="100%"
            height="56px"
            fontSize="18px"
            lineHeight="24px"
            onClick={() => {
              hidePopUp();
            }}
          >
            {!showError
              ? constants.HTTP_PROXIY_MODAL_TRY_AGAIN_LATER
              : showError === constants.HTTP_PROXIY_MODAL_UNKNOWN_ERROR ||
                  showError === constants.HTTP_PROXY_MODAL_TUNNEL_ERROR
                ? constants.HTTP_PROXIY_MODAL_RETURN_TO_ONCLOUD
                : constants.HTTP_PROXIY_MODAL_TRY_AGAIN_LATER}
          </PrimaryButton>
          {!showError && (
            <PrimaryButton
              className="adddevice-btn"
              fontSize="18px"
              backgroundColor={
                showLoader
                  ? getComputedStyle(document.documentElement).getPropertyValue(
                      '--primary_40',
                    )
                  : getComputedStyle(document.documentElement).getPropertyValue(
                      '--brand_white',
                    )
              }
              height="56px"
              color={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--primary_40')}
              type="submit"
              loader={showLoader}
              onClick={() => {
                setShowLoader(true);
                mqttCallConnect(true);
              }}
            >
              {constants.HTTP_PROXIY_CONNECT_LABEL}
            </PrimaryButton>
          )}
        </div>
      </SiteModal>
    </div>
  );
};

export default HubInfoBlock;
