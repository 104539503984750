import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Col, Row } from 'react-bootstrap';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';
import axios from 'axios';
import moment from 'moment';

import {
  HiOutlineCloudDownload,
  HiOutlineExclamationCircle,
} from 'react-icons/hi';

import {
  Avatar,
  PrimaryButton,
  SiteSpinner,
  SiteToast,
  TextBlock,
} from '../../../components/common';
import { constants, Utils } from '../../../helpers';

import './ExportTab.scss';
import { useSelector } from 'react-redux';
import {
  getIncidentExportNotification,
  setIncidentExportNotification,
} from '../../../store/reducers/AccountReducer';
import { useDispatch } from 'react-redux';

const ExportTab = ({ orgDetails, selectedIncident, includesIncidentVideo }) => {
  const {
    register,
    formState: { errors },
  } = useForm();

  const [showLoading, setShowLoading] = useState(false);
  const [showExportFileLoader, setShowExportFileLoader] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [hasVideo, setHasVideo] = useState(includesIncidentVideo);
  const [fileExported, setFileExported] = useState(false);
  const [exportLogEntries, setExportLogEntries] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [toastVariant, setToastVariant] = useState('info');
  const incidentExportNotification = useSelector(getIncidentExportNotification);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      selectedIncident?.incidentId === incidentExportNotification?.incidentId
    ) {
      setFileExported(false);
      dispatch(setIncidentExportNotification({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incidentExportNotification]);

  const fetchData = async () => {
    try {
      const exportLogs = await fetchExportLogEntries();
      const logEntriesWithAvatars = await fetchAvatarsForLogEntries(exportLogs);

      if (Array.isArray(logEntriesWithAvatars)) {
        if (logEntriesWithAvatars?.length > 0) {
          // Sort the log entries in descending chronological order
          logEntriesWithAvatars.sort((a, b) => {
            if (a?.createdDate < b?.createdDate) {
              return 1;
            }

            if (a?.createdDate > b?.createdDate) {
              return -1;
            }

            return 0;
          });
          setExportLogEntries([...logEntriesWithAvatars]);
          setHasData(true);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };

  const fetchExportLogEntries = useCallback(async () => {
    let logEntries;

    if (!orgDetails || !selectedIncident?.incidentId) {
      return;
    }

    try {
      const res = await axios.get(
        `/incident/orgs/${orgDetails?.orgId}/incidents/${selectedIncident?.incidentId}/logs`,
        {
          ...Utils.requestHeader(),
          credentials: 'include',
          withCredentials: true,
        },
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        if (responseData?.data?.logs?.length > 0) {
          logEntries = responseData.data.logs.filter(
            (log) => log?.activity?.toUpperCase() === 'EXPORT',
          );
        }
      } else {
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    } finally {
      return logEntries;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAvatarsForLogEntries = async (logEntries) => {
    let logEntriesWithAvatars;

    try {
      if (!Array.isArray(logEntries) || logEntries.length < 1) {
        logEntriesWithAvatars = [];
      } else {
        const exportLogEntriesPromises = logEntries?.map(async (logEntry) => {
          const getAccountAvatarURLResponse = await Utils?.getAccountAvatarURL(
            orgDetails?.orgId,
            logEntry?.accountId,
          );

          return {
            ...logEntry,
            avatarURL: getAccountAvatarURLResponse?.avatarURL,
          };
        });

        if (exportLogEntriesPromises) {
          logEntriesWithAvatars = await Promise.all(exportLogEntriesPromises);
        } else {
          logEntriesWithAvatars = [];
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
      logEntriesWithAvatars = [];
    } finally {
      return logEntriesWithAvatars;
    }
  };

  const handleEnableTranscode = (event) => {
    if (!event) return;

    const { name, value } = event?.target;
    Utils.vmsLogger().log('name: ', name);
    Utils.vmsLogger().log('value: ', value);
  };

  const handleFileExport = async () => {
    let isSuccessful = false;

    if (!orgDetails || !selectedIncident) {
      return;
    }

    try {
      setShowExportFileLoader(true);
      const res = await axios.get(
        `/incident/orgs/${orgDetails?.orgId}/incidents/${selectedIncident?.incidentId}/bundle`,
        {
          ...Utils.requestHeader(),
          credentials: 'include',
          withCredentials: true,
        },
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        isSuccessful = responseData?.data?.success;
        setFileExported(isSuccessful);
        // Re-fetch log entries
        const exportLogs = await fetchExportLogEntries();
        const logEntriesWithAvatars =
          await fetchAvatarsForLogEntries(exportLogs);

        if (Array.isArray(logEntriesWithAvatars)) {
          if (logEntriesWithAvatars?.length > 0) {
            // Sort the log entries in descending chronological order
            logEntriesWithAvatars.sort((a, b) => {
              if (a?.createdDate < b?.createdDate) {
                return 1;
              }

              if (a?.createdDate > b?.createdDate) {
                return -1;
              }

              return 0;
            });
            setExportLogEntries([...logEntriesWithAvatars]);
            setHasData(true);
          }
        }
        if (isSuccessful) {
          setToastVariant(constants.INCIDENTS_PAGE_TOAST_SUCCESS_VARIANT);
          setUserMsg(
            constants.INCIDENTS_PAGE_EXPORT_TAB_SUCCESS_TOAST_TITLE_FILE_EXPORT,
          );
          setShowToast(true);
        }
        setFileExported(false);
      } else {
        Utils.vmsLogger().error('ERROR: bundle not created');
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    } finally {
      setShowExportFileLoader(false);
      return isSuccessful;
    }
  };

  return (
    <Container>
      <Row>
        <div className="toast-wrapper-incident-export">
          <SiteToast
            customCss="incident-details-toast"
            position="top-end"
            show={showToast}
            title={constants.INCIDENTS_PAGE_EXPORT_TAB_SUCCESS_TOAST_TITLE}
            body={userMsg}
            variant={toastVariant}
            delay={5000}
            onCloseCallback={setShowToast}
          />
        </div>
      </Row>
      {/* Export Title */}
      <Row className="export-incident-row-container">
        <Col className="export-incident-title-column">
          <div className="d-flex justify-content-start mt-4 mb-2">
            <span className="export-incident-title">
              {constants.INCIDENTS_EXPORT_EXPORT_INCIDENT_TITLE_TEXT}
            </span>
          </div>
          <div className="d-flex justify-content-start">
            <span className="export-incident-description">
              {constants.INCIDENTS_EXPORT_DESCRIPTION_TEXT}
            </span>
          </div>
        </Col>
        <Col className="pl-0 export-incident-col-container export-log-column">
          <div className="d-flex justify-content-start mt-4 mb-2">
            <span className="export-incident-export-log-title">
              {constants.INCIDENTS_EXPORT_EXPORT_LOG_TITLE_TEXT}
            </span>
          </div>
        </Col>
      </Row>
      {/* Export Content */}
      <Row>
        <Col>
          {showLoading ? (
            <div className="mb-2 contributors-content">
              <SiteSpinner height="50px" width="50px" />
            </div>
          ) : (
            <div className="mb-2 export-incident-content">
              <Container className=" export-incident-button-container">
                <Row className="export-incident-row-container">
                  <Col>
                    <div className="export-incident-main-content">
                      <div
                        className={`mt-3 mb-3 export-incident-checkbox-wrapper ${
                          !hasVideo ? 'hidden' : ''
                        }`}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              className="transcode-checkbox"
                              size="small"
                              disabled={fileExported}
                              name="transcodeOption"
                              onChange={(e) => {
                                e?.preventDefault();
                                handleEnableTranscode(e);
                              }}
                              sx={{
                                [`&, &.${checkboxClasses.checked}`]: {
                                  color: getComputedStyle(
                                    document.documentElement,
                                  ).getPropertyValue('--brand_primary'),
                                },
                              }}
                            />
                          }
                          label={
                            constants.INCIDENTS_EXPORT_TRANSCODE_VIDEO_OPTION_TEXT
                          }
                        />
                      </div>
                      <div
                        className={`export-incident-notification-wrapper${
                          !fileExported ? ' hidden' : ''
                        }`}
                      >
                        <div>
                          <HiOutlineExclamationCircle className="export-incident-notification-icon" />
                        </div>
                        <div className="export-incident-notification-content">
                          <div className="export-incident-notification-title">
                            {
                              constants.INCIDENTS_EXPORT_FILE_COMPRESSION_IN_PROGRESS_TEXT
                            }
                          </div>
                          <div className="export-incident-notification-description">
                            {
                              constants.INCIDENTS_EXPORT_FILE_COMPRESSION_EMAIL_NOTIFICATION_TEXT
                            }
                          </div>
                        </div>
                      </div>
                      <div
                        className={`mb-3 mx-0 export-incident-button-wrapper ${
                          !hasVideo ? 'mt-4' : ''
                        }`}
                      >
                        <PrimaryButton
                          className="export-incident-export-btn"
                          loader={showExportFileLoader}
                          width="125px"
                          disabled={fileExported}
                          onClick={(e) => {
                            e?.preventDefault();
                            handleFileExport();
                          }}
                        >
                          {constants.INCIDENTS_EXPORT_EXPORT_FILE_BUTTON_LABEL}
                        </PrimaryButton>
                      </div>
                    </div>
                  </Col>
                  <Col className="export-incident-col-container export-log-column">
                    <div className="mb-2 export-incident-export-log-wrapper">
                      <div>
                        {hasData ? (
                          <Container className="export-log-detail-container">
                            {Array.isArray(exportLogEntries) &&
                              exportLogEntries.length > 0 &&
                              exportLogEntries.map((log) => (
                                <Row>
                                  <Col>
                                    <div
                                      key={log.createdDate}
                                      className="mb-2 export-log-detail-wrapper"
                                    >
                                      <TextBlock className="export-log-detail-content">
                                        <div className="export-log-detail-avatar">
                                          <Avatar
                                            valueType={
                                              log?.avatarURL ? 'icon' : 'text'
                                            }
                                            value={
                                              log?.avatarURL ||
                                              Utils.getInitialsFromFullName(
                                                log?.activityBy,
                                              )
                                            }
                                            size="semiLarge"
                                            avatarStyle="roundedCircle"
                                          />
                                        </div>
                                        <div className="export-log-detail-creator">
                                          <div className="export-log-detail-creator-name">
                                            {log?.activityBy}
                                          </div>
                                          <div className="export-log-detail-creator-date">
                                            {`${constants.EXPORTED_ON} ${moment(
                                              log?.createdDate,
                                            ).format(
                                              'MMM DD, YYYY hh:mm:ss A',
                                            )}`}
                                          </div>
                                        </div>
                                      </TextBlock>
                                    </div>
                                  </Col>
                                </Row>
                              ))}
                          </Container>
                        ) : (
                          <Container className="export-log-detail-container">
                            <Row>
                              <Col>
                                <div className="mb-3 export-log-detail-wrapper">
                                  <TextBlock className="export-log-detail-no-history-content">
                                    <div className="export-log-detail-no-logs">
                                      <HiOutlineCloudDownload className="export-log-detail-export-icon" />
                                      <div className="export-log-detail-no-history">
                                        {
                                          constants.INCIDENT_EXPORT_EXPORT_LOG_NOTHING_LOGGED
                                        }
                                      </div>
                                    </div>
                                  </TextBlock>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ExportTab;
