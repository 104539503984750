import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { HiOutlineVideoCamera } from 'react-icons/hi2';
import { FiSettings } from 'react-icons/fi';
import moment from 'moment';
import { WiTime4 } from 'react-icons/wi';
import './LiveGridStructure.scss';
import {
  clearWSSConnections,
  getCDNInfo,
  getMQTTConnection,
  getMultiLiveStreamLoader,
  getPlatformInfo,
  getRecievedOffers,
  getWSSConnections,
  setIsReloadedStream,
  setMultiLiveStreamLoader,
  setRecievedOffers,
  setWSSConnection,
} from '../../../store/reducers/StreamingReducer';
import { publishWithMQTTs } from '../../../utils/connection/mqttConnection';
import {
  getAccountId,
  setDeviceInformation,
} from '../../../store/reducers/AccountReducer';
import { ReactComponent as ThreeDotIcon } from '../../../assets/images/VerticalThreeDots.svg';
import OfflineCamera from './OfflineCamera';
import { constants, EntitlementsTypeEnum, Utils } from '../../../helpers';
import LoadingCamera from './LoadingCamera';
import { usePoliciesStore } from '../../../store/policiesStore';
import { useDeviceSnapshots } from '../../../store/DeviceSnapshotsStore';
import NoLicenseData from './NoLicenseData';
import { sendCloudDisConnectRequest } from '../../../utils/webRTC/multi-cloud-playback-handler';

const LiveGridItem = ({
  layout,
  deviceId,
  device,
  hubId,
  activeTime,
  timeZone,
  uniqueId,
  entitleData,
  metadataByDeviceId,
  isEdgeRecording,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cdnInfo = useSelector(getCDNInfo);
  const mqttConnection = useSelector(getMQTTConnection);
  const accountId = useSelector(getAccountId);
  const platformDetails = useSelector(getPlatformInfo);
  const recievedOffers = useSelector(getRecievedOffers);
  const [loading, setLoading] = useState(false);
  const intervalRef = useRef();
  const tryAgainIntervalRef = useRef();
  const hideLoaderRef = useRef(null);
  const wssConnections = useSelector(getWSSConnections);
  const { getLoggedInUserPolicies } = usePoliciesStore();
  const userPolicies = getLoggedInUserPolicies();
  const [currentDevice, setCurrentDevice] = useState(device);
  const loaderData = useSelector(getMultiLiveStreamLoader);
  const imageRef = useRef();
  const playerRef = useRef();
  const flag = useRef(false);
  const [liveSnapshot, setLiveSnapshot] = useState();
  const deviceSnapshots = useDeviceSnapshots((state) => state.deviceSnapshots);
  const [isLicenseExpire, setIsLicenseExpire] = useState(null);
  const [showTryAgain, setShowTryAgain] = useState(false);
  const [metaEnd, setMetaEnd] = useState();
  const { setDeviceSnapshots } = useDeviceSnapshots();
  const [isLiveStreamReady, setIsLiveStreamReady] = useState(false);

  useEffect(() => {
    if (recievedOffers?.[deviceId]) {
      clearInterval(intervalRef?.current);
      clearInterval(tryAgainIntervalRef?.current);
    }
  }, [recievedOffers]);

  useEffect(() => {
    getSnapShotImageFromCache();
    return () => {
      clearInterval(intervalRef?.current);
      clearInterval(tryAgainIntervalRef?.current);
    };
  }, []);

  useEffect(() => {
    if (entitleData && Object.keys(entitleData).length > 0) {
      const checkLicense = entitleData?.[deviceId]?.find(
        (entitle) =>
          entitle.type === EntitlementsTypeEnum.LIVE_VIEW &&
          entitle.expiry < Utils.getUnixDate(new Date()) * 1000,
      );
      if (checkLicense) {
        setIsLicenseExpire(true);
        setTimeout(() => {
          startStopLoader(false);
        }, 1500);
      } else {
        setIsLicenseExpire(false);
      }
    } else {
      setIsLicenseExpire(true);
      setTimeout(() => {
        if (entitleData && Object.keys(entitleData).length === 0) {
          startStopLoader(false);
        }
      }, 1500);
    }
  }, [JSON.stringify(entitleData), uniqueId]);

  useEffect(() => {
    if (metadataByDeviceId) {
      const findMeta = metadataByDeviceId.find(
        (meta) => meta?.deviceId === deviceId,
      );
      if (findMeta) {
        setMetaEnd(findMeta?.metadata?.[findMeta?.metadata?.length - 1]?.end);
      }
    }
  }, [metadataByDeviceId]);

  useEffect(() => {
    if (metaEnd) {
      getSnapshotImageForLastMetaEnd();
    }
  }, [metaEnd]);

  const getSnapShotImageFromCache = () => {
    if (deviceSnapshots?.[deviceId]) {
      setLiveSnapshot(deviceSnapshots?.[deviceId]);
    }
  };
  const getSnapshotImageForLastMetaEnd = () => {
    const lastMetaEndTime = Utils.getUnixDate(moment(metaEnd).toDate());
    const bucket = (cdnInfo?.bucket).replace('${deviceId}', deviceId);
    const date = Utils.fetchDateInUnix(lastMetaEndTime);
    if (deviceId) {
      fetch(
        `${cdnInfo?.protocol}://${cdnInfo?.host}/${bucket}/${date}/${lastMetaEndTime}.jpg`,
        {
          credentials: 'include',
        },
      )
        .then((response) => response.blob())
        .then((blob) => {
          setLiveSnapshot(URL.createObjectURL(blob));
          setDeviceSnapshots(
            deviceId,
            `${cdnInfo.protocol}://${cdnInfo.host}/${bucket}/${date}/${lastMetaEndTime}.jpg`,
          );
        })
        .catch(() => {
          Utils.vmsLogger().log('Error got', deviceId);
        });
    }
  };

  // const getCapabiltiesForDevice = async (deviceData, signal) => {
  //   if (deviceData?.capability) {
  //     try {
  //       const response = await fetch(deviceData?.capability?.url, {
  //         signal: signal,
  //       });
  //       if (response.status === 200) {
  //         const responseJson = await response.json();
  //         const storageCapability = Utils.getDeviceSettingResource(6);
  //         const localStorageCap = Utils.getCurrentTabProperty(
  //           responseJson?.capabilities,
  //           storageCapability[5],
  //         );
  //         Utils.vmsLogger().log(
  //           'getCapabiltiesForDevice ~ localStorageCap:',
  //           localStorageCap,
  //         );
  //         if (
  //           localStorageCap &&
  //           localStorageCap?.['edge-rec-support']?.includes('true') &&
  //           device?.displayDeviceStatus?.toLowerCase() ===
  //             constants.DEVICES_RETURN_ONLINE_STATUS.toLowerCase()
  //         ) {
  //           setIsEdgeRecording(true);
  //         } else {
  //           setIsEdgeRecording(false);
  //         }
  //         setCurrentDevice({ ...deviceData, capDetails: responseJson });
  //       } else {
  //         setCurrentDevice(deviceData);
  //       }
  //     } catch (error) {
  //       setCurrentDevice(deviceData);
  //     }
  //   } else {
  //     setCurrentDevice(deviceData);
  //   }
  // };

  useEffect(() => {
    if (loaderData && Object.keys(loaderData)?.length > 0) {
      setLoading(loaderData?.[deviceId]);
    }
  }, [JSON.stringify(loaderData)]);

  useEffect(() => {
    // const controller = new AbortController();
    // const signal = controller.signal;
    // getCapabiltiesForDevice(device, signal);

    if (
      device &&
      device?.displayDeviceStatus?.toLowerCase() !==
        currentDevice?.displayDeviceStatus?.toLowerCase()
    ) {
      if (device?.displayDeviceStatus?.toLowerCase() === 'online') {
        setShowTryAgain(true);
      }
      setCurrentDevice(device);
    }

    // return () => {
    //   controller.abort();
    // };
  }, [JSON.stringify(device)]);

  useEffect(() => {
    if (
      currentDevice?.displayDeviceStatus !==
        constants.DEVICES_RETURN_OFFLINE_STATUS &&
      currentDevice?.displayDeviceStatus !==
        constants.DEVICES_RETURN_DEACTIVATED_STATUS
    ) {
      callTimeoutLoader();
    }
    return () => {
      clearTimeout(hideLoaderRef.current);
    };
  }, [uniqueId]);

  useEffect(() => {
    if (
      device?.displayDeviceStatus === constants.DEVICES_RETURN_OFFLINE_STATUS ||
      device?.displayDeviceStatus ===
        constants.DEVICES_RETURN_DEACTIVATED_STATUS
    ) {
      startStopLoader(false);
    }
  }, [JSON.stringify(device)]);

  useEffect(() => {
    const deviceDetails = {
      deviceId: deviceId,
      gatewayId: hubId,
      orgId: device?.orgId,
      deviceName: device?.deviceName,
    };
    if (mqttConnection) {
      if (
        !recievedOffers?.[deviceId] &&
        isLicenseExpire === false &&
        currentDevice?.displayDeviceStatus?.toLowerCase() ===
          constants.DEVICES_ONLINE_CONNECTION_STATUS &&
        isEdgeRecording !== null &&
        isEdgeRecording !== undefined
      ) {
        publishWithMQTTs(
          platformDetails.mqtt,
          platformDetails.p2p_server,
          deviceDetails,
          accountId,
          isEdgeRecording,
        );
        if (!flag.current) {
          flag.current = true;
          playerRef.current?.play(null, deviceId);
        }
        // const id = setInterval(() => {
        //   publishWithMQTTs(
        //     platformDetails.mqtt,
        //     platformDetails.p2p_server,
        //     deviceDetails,
        //     accountId,
        //   );
        // }, 10000);
        // intervalRef.current = id;
      }
    }

    setIsLiveStreamReady(accountId && deviceId && mqttConnection);
  }, [mqttConnection, uniqueId, isLicenseExpire, isEdgeRecording]);

  const callTimeoutLoader = () => {
    setLoading(true);
    clearTimeout(hideLoaderRef.current);
    // hideLoaderRef.current = setTimeout(() => {
    //   startStopLoader(false);
    //   setShowTryAgain(true);
    // }, 60000);
  };

  const OnClickCameraDetails = () => {
    // disconnectWithMQTT();
    if (wssConnections) {
      Object.keys(wssConnections).forEach((key) => {
        sendCloudDisConnectRequest(key);
      });
    }
    dispatch(clearWSSConnections());
    dispatch(setIsReloadedStream(false));
    dispatch(setWSSConnection(false));
    dispatch(setIsReloadedStream(false));
    // dispatch(setDeviceInformation(currentDevice));
    navigate(`/cameras/dashboard.html`, {
      state: {
        id: deviceId,
        cdnInfo: cdnInfo ? cdnInfo : {},
        origin: 'multiview-contextual-menu',
      },
    });
    Utils.vmsLogger().log(
      'Livestream OnClickCameraDetails end',
      Utils.getTimesinmili(),
    );
  };

  const handleDoubleClick = () => {
    Utils.vmsLogger().log(
      'Livestream handle double clicked',
      Utils.getTimesinmili(),
    );
    OnClickCameraDetails();
  };

  const OnClickCameraSettings = () => {
    dispatch(setDeviceInformation(currentDevice));
    deviceId && navigate(`/devices/dashboard.html?deviceId=${deviceId}`);
  };

  const handleTryAgain = () => {
    dispatch(setRecievedOffers({ id: deviceId, value: false }));
    if (tryAgainIntervalRef?.current) {
      clearInterval(tryAgainIntervalRef?.current);
    }
    callTimeoutLoader();
    const deviceDetails = {
      deviceId: deviceId,
      gatewayId: hubId,
      orgId: device?.orgId,
      device: device?.deviceName,
    };
    if (mqttConnection) {
      if (
        currentDevice?.displayDeviceStatus?.toLowerCase() ===
        constants.DEVICES_ONLINE_CONNECTION_STATUS
      ) {
        startStopLoader(true);
        publishWithMQTTs(
          platformDetails.mqtt,
          platformDetails.p2p_server,
          deviceDetails,
          accountId,
          isEdgeRecording,
        );
        // if (!flag.current) {
        // flag.current = true;
        playerRef.current?.play(null, deviceId);
        // }
        // const id = setInterval(() => {
        //   publishWithMQTTs(
        //     platformDetails.mqtt,
        //     platformDetails.p2p_server,
        //     deviceDetails,
        //     accountId,
        //   );
        // }, 10000);
        // tryAgainIntervalRef.current = id;
      }
    }
  };

  const startStopLoader = (isLoading) => {
    const loaderObj = {
      deviceId,
      isLoading,
    };
    dispatch(setMultiLiveStreamLoader(loaderObj));
  };

  return (
    <div className="grid-stack-item-content">
      {loading && (
        <div className="live-grid-snapshot-div device-stack-item-content">
          <img
            ref={imageRef}
            id="img-snapshot"
            src={liveSnapshot}
            alt=""
            className="live-img-snapshot"
            onError={(e) => (e.target.style.display = 'none')}
          />
          <LoadingCamera />
        </div>
      )}
      {device?.displayDeviceStatus ===
        constants.DEVICES_RETURN_OFFLINE_STATUS ||
      device?.displayDeviceStatus ===
        constants.DEVICES_RETURN_DEACTIVATED_STATUS ? (
        <OfflineCamera
          deviceId={deviceId}
          device={currentDevice}
          activeTime={activeTime}
          timeZone={timeZone}
          onRefresh={handleTryAgain}
        />
      ) : !loading && isLicenseExpire ? (
        <NoLicenseData
          deviceId={deviceId}
          device={currentDevice}
          activeTime={activeTime}
          timeZone={timeZone}
          onRefresh={handleTryAgain}
        />
      ) : (
        <div>
          <div className="streaming-container">
            {isLiveStreamReady && (
              <ump-player
                ref={playerRef}
                key={deviceId}
                // className="ump-player-size"
                // id="ump-player-test"
                id={`ump-player-test${layout}${layout}`}
                hostname="192.168.125.100"
                datachannel="192.168.125.9:8080"
                username="admin"
                password=""
                channel={1}
                device="camera"
                https={false}
                audiosync={true}
                secure={false}
                profile="H.264"
                profileusage="Network"
                controls
                port={80}
                deviceId={deviceId}
              />
            )}
            {/* <video
                id={`video${layout}${layout}`}
                width="auto"
                height="100%"
                data-device-id={deviceId}
                // ref={remoteVideoRef}
                autoPlay={true}
                playsInline={true}
                muted={true}
              />
              <audio
                id={`audio${layout}${layout}`}
                // ref={remoteAudioRef}
                autoPlay={true}
                playsInline={true}
                controls={false}
                muted={true}
              /> */}
            <canvas
              id={`canvas${layout}${layout}`}
              width="764"
              height="450"
              className="d-none"
            />
          </div>
          {isLiveStreamReady && (
            <div
              className={`device-overlay hovered grid-stack-item-content device-stack-item-content`}
              onDoubleClick={(e) => {
                e.stopPropagation();
                handleDoubleClick();
              }}
              data-gs-width="6"
              data-gs-height="4"
            >
              <div className="device-title-container">
                <div className="device-name">{currentDevice?.deviceName}</div>
                <div className="device-location">
                  {currentDevice?.locationName} • {currentDevice?.areaName}
                </div>
              </div>
              <div className="date-time-wrapper">
                <div className="date-time">
                  <WiTime4 size={14} />
                  {moment
                    .tz(moment(activeTime), timeZone)
                    .format('hh:mm:ss A z')}
                </div>
              </div>
              <div className="menu-icon">
                <NavDropdown
                  className="devices-dropdown"
                  title={<ThreeDotIcon />}
                >
                  <NavDropdown.Item
                    className="devices-dropdown-options"
                    onClick={(e) => {
                      e.stopPropagation();
                      OnClickCameraDetails();
                    }}
                  >
                    <HiOutlineVideoCamera size={20} />
                    <span className="devices-dropdown-options-label">
                      {constants.CAMERAS_VIDEO_CAMERA_DETAILS_LABEL}
                    </span>
                  </NavDropdown.Item>
                  {userPolicies.view_device_settings && (
                    <NavDropdown.Item
                      className="devices-dropdown-options"
                      onClick={(e) => {
                        e.stopPropagation();
                        OnClickCameraSettings();
                      }}
                    >
                      <FiSettings size={20} />
                      <span className="devices-dropdown-options-label">
                        {constants.CAMERAS_VIDEO_SETTINGS_LABEL}
                      </span>
                    </NavDropdown.Item>
                  )}
                </NavDropdown>
              </div>
            </div>
          )}
          {/* <div className="primary-circle"></div>
            <div className="streaming-container">
              {accountId &&
                deviceId &&
                // deviceDetails?.deviceId &&
                mqttConnection && (
                  <ump-player
                    ref={playerRef}
                    key={deviceId}
                    // id="ump-player-test"
                    id={`ump-player-test${layout}${layout}`}
                    hostname="192.168.125.100"
                    datachannel="192.168.125.9:8080"
                    username="admin"
                    password=""
                    channel={1}
                    device="camera"
                    https={false}
                    audiosync={true}
                    secure={false}
                    profile="H.264"
                    profileusage="Network"
                    controls
                    port={80}
                    deviceId={deviceId}
                  />
                )}
              {***** <video
                id={`video${layout}${layout}`}
                width="auto"
                height="100%"
                data-device-id={deviceId}
                autoPlay={true}
                playsInline={true}
                muted={true}
              />
              <audio
                id={`audio${layout}${layout}`}
                autoPlay={true}
                playsInline={true}
                controls={false}
                muted={true}
              /> *****}
              <canvas
                id={`canvas${layout}${layout}`}
                width="764"
                height="450"
                className="d-none"
              />
            </div>
            {remoteStream?.active ? (
              <div
                className={`device-overlay hovered grid-stack-item-content device-stack-item-content`}
                onDoubleClick={() => handleDoubleClick()}
                data-gs-width="6"
                data-gs-height="4"
              >
                <div className="device-title-container">
                  <div className="device-name">{currentDevice?.deviceName}</div>
                  <div className="device-location">
                    {currentDevice?.locationName} • {currentDevice?.areaName}
                  </div>
                </div>
                <div className="date-time-wrapper">
                  <div className="date-time">
                    <WiTime4 size={14} />
                    {moment
                      .tz(moment(activeTime), timeZone)
                      .format('hh:mm:ss A z')}
                  </div>
                </div>
                <div className="menu-icon">
                  <NavDropdown
                    className="devices-dropdown"
                    title={<ThreeDotIcon />}
                  >
                    <NavDropdown.Item
                      className="devices-dropdown-options"
                      onClick={() => OnClickCameraDetails()}
                    >
                      <HiOutlineVideoCamera size={16} />
                      <span className="devices-dropdown-options-label">
                        {constants.CAMERAS_VIDEO_CAMERA_DETAILS_LABEL}
                      </span>
                    </NavDropdown.Item>
                    {userPolicies.view_device_settings && (
                      <NavDropdown.Item
                        className="devices-dropdown-options"
                        onClick={() => OnClickCameraSettings()}
                      >
                        <FiSettings size={14} />
                        <span className="devices-dropdown-options-label">
                          {constants.CAMERAS_VIDEO_SETTINGS_LABEL}
                        </span>
                      </NavDropdown.Item>
                    )}
                  </NavDropdown>
                </div>
              </div>
            ) : null}
          </div>
          <>
            {!loading && !remoteStream?.active && showTryAgain && (
              <NoContentScreen
                deviceId={deviceId}
                device={device}
                activeTime={activeTime}
                timeZone={timeZone}
                onTryAgain={handleTryAgain}
              />
            )}
          </> */}
          {/* // )} */}
        </div>
      )}
    </div>
  );
};

export default LiveGridItem;
