import moment from 'moment';
import { useEffect, useState } from 'react';
import ReImageFetch from '../../../components/common/ReImageFetch';
import useFiltersStore from '../../../store/FiltersStore';
import { CacheNameEnum, Utils } from '../../../helpers';
import './EventsWrapper.scss';

const EventsWrapper = ({
  data,
  category,
  deviceId,
  cdnValue,
  timezone,
  selectedEvent,
  selectedEventId,
  handleOnClick,
}) => {
  const [eventData, setEventData] = useState([]);
  const { getAppliedTags } = useFiltersStore();

  useEffect(() => {
    // Open the Camera Details Events Cache
    Utils.initCache(CacheNameEnum.CAMERA_DETAILS_EVENTS_CACHE);
  }, []);

  useEffect(() => {
    const allData = [];
    let eventClassFilter =
      'face, person, vehicle, licensePlate, explosion, glass-breaking, scream, gun-shot, sound, motion-start, audio-start';

    if (getAppliedTags() !== '') {
      eventClassFilter = getAppliedTags();
      if (getAppliedTags()?.includes('sound')) {
        eventClassFilter = getAppliedTags()?.replace(
          'sound',
          'explosion, glass-breaking, scream, gun-shot, sound, audio-start',
        );
      } else if (getAppliedTags()?.includes('Motion')) {
        eventClassFilter = getAppliedTags()?.replace('Motion', 'motion-start');
      }
    }

    data?.events?.map((insideEvent, i) => {
      insideEvent?.eventMeta?.events?.map((actualEvent, ij) => {
        actualEvent?.event?.map((newEvent, index) => {
          if (
            category?.includes(newEvent.eventType.toLowerCase()) &&
            eventClassFilter !== '' &&
            eventClassFilter
              ?.toLowerCase()
              ?.includes(newEvent.eventClass.toLowerCase()) &&
            insideEvent.deviceId === deviceId
          ) {
            const eData = {
              eventId: insideEvent?.eventMeta?.eventId,
              id: `${i}-${ij}-${index}`,
              class: newEvent.eventClass,
              timestamp: insideEvent?.eventTimestamp,
            };
            allData.push(eData);
          }
        });
      });
    });
    allData.sort((a, b) => {
      return b.timestamp - a.timestamp;
    });
    setEventData(allData);
  }, [JSON.stringify(data)]);

  return (
    <div
      key={`scrollable-area-${deviceId}`}
      className="event-all-category-scrollable-area"
    >
      {eventData?.map((event, i) => {
        if (event) {
          return (
            <div
              key={`event-${event.timestamp}-${deviceId}-${i}`}
              className={`${
                selectedEventId === event.eventId &&
                (selectedEvent === event.timestamp ||
                  selectedEvent ===
                    Utils.getUnixDate(Utils.getDate(event.timestamp / 1000)) *
                      1000)
                  ? 'selectedEvent'
                  : ''
              } event-wrapper-list`}
              onClick={() => {
                handleOnClick(event.timestamp, event.eventId);
              }}
            >
              <div className="event-list-image">
                <ReImageFetch
                  time={event?.timestamp}
                  cdnValue={cdnValue}
                  deviceId={deviceId}
                  cacheName={CacheNameEnum.CAMERA_DETAILS_EVENTS_CACHE}
                />
              </div>
              <div className="event-list-content">
                <div className="event-list-content-details">
                  <div className="event-name">
                    {event.class === 'motion-start' ? 'Motion' : event.class}
                  </div>
                  <div className="event-timestamp">
                    {moment
                      .tz(moment(event.timestamp), timezone)
                      .format('hh:mm:ss A z')}
                  </div>
                </div>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default EventsWrapper;
