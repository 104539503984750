import React, { useEffect, useRef, useState } from 'react';
import RegionSelect from 'react-region-select';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { RiErrorWarningLine } from 'react-icons/ri';
import { RxCross1, RxPlus } from 'react-icons/rx';
import { PrimaryButton, SiteSpinner } from '../../../../components/common';
import { Utils, constants } from '../../../../helpers';
import { regexAnalytics } from '../../../../helpers/enums';
import { useSelector } from 'react-redux';
import {
  getAllMqttDataFromResponse,
  setDeviceInformation,
} from '../../../../store/reducers/AccountReducer';
import { ReactComponent as InformationCircle } from '../../../../assets/images/icons/InformationCircle.svg';
import publishDeviceSetting, {
  deleteDeviceSetting,
} from '../shared/PublishDeviceSettings';
import {
  getCDNInfo,
  getStreamBitmapData,
} from '../../../../store/reducers/StreamingReducer';
// import { getAllMqttDataFromResponse } from '../../../../store/AccountStoreIDB';
import InclusionExclusionSnapShot from './InclusionExclusionSnapShot';
import CategoryEventSelection from './CategoryEventSelection';
import { EventType } from '../../../../helpers/enums';
import CategoryTypeIVA from './CategoryTypeIVA';
import { v4 as uuidv4 } from 'uuid';
import { getDefaultAreaName } from '../../../../helpers/commonUtils';

const VirtualAreaIva = ({
  accountId,
  refResolution,
  deviceId,
  deviceInfos,
  devicePublishId,
  uuid,
  capExclusionArea,
  exclusionZonesData,
  hideModelPopup,
  canChangeSettings,
}) => {
  const initialValues = {
    zoneName: '',
  };
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: initialValues,
  });
  const dispatch = useDispatch();
  const watchedFields = watch();
  const formRef = useRef();
  const expiredTime = 20;
  const resourceList = Utils.getDeviceSettingResource(7);
  const MAX_REGION_TO_BE_ADDED_COUNT =
    +capExclusionArea?.['virtual-area-index']?.max + 1 || 1;
  const REGION_X_RANGE = +refResolution?.[0] || 1;
  const REGION_Y_RANGE = +refResolution?.[1] || 1;
  const colors = Utils.getAllVirtualLineColors();
  const [exclusionAreas, setExclusionAreas] = useState(
    exclusionZonesData['virtual-areas'] || [],
  );
  const [regions, setRegions] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [isAddZoneClicked, setIsAddZoneClicked] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const [property, setProperty] = useState({});
  const [propertyInitial, setPropertyInitial] = useState({});
  const [categoryType, setCategoryType] = useState({});
  const [categoryUpdated, setCategoryUpdated] = useState({});
  const [isObjectSelected, setIsAnyObjectSelected] = useState(false);

  const publishPayload = {
    deviceId: deviceId,
    devicePublishId: devicePublishId,
    uuid: uuid,
    accountId: accountId,
    hubId: deviceInfos.gatewayId,
  };
  const [snapshot, setSnapshot] = useState('');
  const [imageWidth, setImageWidth] = useState(0);
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  // const [allMqttData, setAllMqttData] = useState([]);
  const bitmapObject = useSelector(getStreamBitmapData);
  const [isDeleteTriggered, setIsDeleteTriggered] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(1.7);
  const cdnInfo = useSelector(getCDNInfo);
  const [eventTypeListObj, setEventTypeListObj] = useState(
    Utils.getObjectTypesVirtualAreaIVA(capExclusionArea),
  );
  const [unsavedZone, setUnsavedZone] = useState('');
  const [duplicateZone, setDuplicateZone] = useState('');
  const [showDuplicateZone, setShowDuplicateZone] = useState(false);
  const [initialZoneName, setInitialZoneName] = useState('');
  const [lastSelectedZoneName, setLastSelectedZoneName] = useState('');
  const [eventList, setEventList] = useState([]);
  // const loadAllMqttData = useCallback(async () => {
  //   const mqttResp = await getAllMqttDataFromResponse();
  //   setAllMqttData(mqttResp || []);
  // }, []);

  // useEffect(() => {
  //   const handleUpdate = async (data) => {
  //     if (data.key === 'allMqttDataFromResponse') {
  //       await loadAllMqttData();
  //     }
  //   };
  //   observerInstance.addObserver(handleUpdate);
  //   loadAllMqttData();

  //   return () => {
  //     observerInstance.removeObserver(handleUpdate);
  //   };
  // }, [loadAllMqttData]);

  useEffect(() => {
    const resource = allMqttData?.msg?.resource;
    if (resource?.includes(resourceList[13]) && resource?.includes(deviceId)) {
      const properties = allMqttData?.msg?.properties;
      const action = allMqttData?.msg?.action;
      if (action === 'error') {
        setRemainingTime(0);
        setErrorMsg(properties.desc);
      } else if (action === 'is') {
        setRemainingTime(0);
        // Update Device Details
        const localStoreProperty =
          JSON.parse(localStorage.getItem('localStoreProperty')) || {};
        const updatedProperties = {
          ...localStoreProperty,
          'virtual-area': properties['virtual-area'],
        };
        localStorage.setItem(
          'localStoreProperty',
          JSON.stringify(updatedProperties),
        );
        dispatch(
          setDeviceInformation({
            ...deviceInfos,
            properties: {
              ...deviceInfos.properties,
              'virtual-area': properties['virtual-area'],
            },
          }),
        );
        setExclusionAreas(
          JSON.parse(properties['virtual-area'])['virtual-areas'],
        );
        if (isDeleteTriggered) {
          setIsDeleteTriggered(false);
        } else if (showLoader) {
          hideModelPopup();
        }
      }
    }
  }, [allMqttData]);

  useEffect(() => {
    const updatedRegions = regions.map((reg) => {
      return {
        ...reg,
        data: {
          ...reg.data,
          regionStyle: {
            ...reg.data.regionStyle,
            display:
              selectedRegion.data.regionColor === reg.data.regionColor
                ? 'block'
                : 'none',
          },
        },
      };
    });
    setRegions(updatedRegions);
    document.getElementsByClassName('modal-body')[0].scrollTo(0, 0);
  }, [selectedRegion]);

  useEffect(() => {
    setErrorMsg('');
    const snapshotURL = fetchSnapshotCanvas(`canvas`, `video`);
    setSnapshot(snapshotURL);
  }, []);

  const fetchSnapshotCanvas = (typeCanvas, typeVideo) => {
    let canvas = document.getElementById(typeCanvas);
    let video = document.getElementById(typeVideo);
    const context = canvas?.getContext('2d');
    const bitmapObjectHeight = bitmapObject ? bitmapObject?.StreamHeight : 450;
    const bitmapObjectWidth = bitmapObject ? bitmapObject?.StreamWidth : 764;
    bitmapObjectHeight &&
      bitmapObjectWidth &&
      video &&
      context?.drawImage(video, 0, 0, bitmapObjectWidth, bitmapObjectHeight);
    const data = canvas?.toDataURL('image/png');
    return data;
  };

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime((prevTime) => prevTime - 1);
      } else {
        setShowLoader(false);
        clearInterval(timerInterval);
      }
    }, 1000);
    return () => {
      clearInterval(timerInterval);
    };
  }, [remainingTime]);

  const resetCoords = (regions) => {
    const regionList = regions?.map((reg) => {
      const r = exclusionAreas?.find(
        (item) => item?.['virtual-area-index'] == reg?.index,
      );
      if (r) {
        const dimensions = getZoneDimensions(
          ...r['virtual-area-coordinates']?.split(','),
        );
        const color = colors[r['virtual-area-index']];
        return {
          x: dimensions.left,
          y: dimensions.top,
          width: dimensions.width,
          height: dimensions.height,
          index: r['virtual-area-index'],
          data: {
            zoneName: r['virtual-area-name'],
            regionColor: color,
            regionStyle: {
              border: `2px solid ${color}`,
            },
            x: dimensions.left,
            y: dimensions.top,
            width: dimensions.width,
            height: dimensions.height,
            ...dimensions,
          },
          isChanging: false,
        };
      } else {
        return reg;
      }
    });
    if (regionList?.length) {
      setRegions(regionList);
    }
  };

  useEffect(() => {
    if (REGION_X_RANGE && REGION_Y_RANGE && imageWidth) {
      setAspectRatio(REGION_X_RANGE / REGION_Y_RANGE);
      const regionList = exclusionAreas.map((r) => {
        const dimensions = getZoneDimensions(
          ...r['virtual-area-coordinates']?.split(','),
        );
        const color = colors[r['virtual-area-index']];
        return {
          x: dimensions.left,
          y: dimensions.top,
          width: dimensions.width,
          height: dimensions.height,
          index: r['virtual-area-index'],
          data: {
            zoneName: r['virtual-area-name'],
            regionColor: color,
            regionStyle: {
              border: `2px solid ${color}`,
            },
            x: dimensions.left,
            y: dimensions.top,
            width: dimensions.width,
            height: dimensions.height,
            ...dimensions,
          },
          isChanging: false,
        };
      });
      if (exclusionAreas.length === 0) {
        setSelectedRegion(null);
        setRegions([]);
      }
      if (regionList.length) {
        setRegions(regionList);
        Utils.vmsLogger().log(regionList);
        updateRegionAndForm(regionList.length > 0 ? regionList[0] : null);
      }
    }
  }, [imageWidth, exclusionAreas]);

  const handleAddZoneClick = () => {
    // If there is any region without name then it should not allow to add new region
    const withoutName = regions.filter(
      (x) => x.data.zoneName === '' || x?.isAreaNotSaved,
    );
    if (withoutName.length > 0) {
      const unsavedObject = regions.find((item) => item.isAreaNotSaved);
      setUnsavedZone(unsavedObject.data.zoneName);
      // formRef.current.requestSubmit();
      return;
    }
    const occupiedIndices = exclusionAreas.map((x) => x['virtual-area-index']);
    const index = Utils.getMinAvailableIndex(occupiedIndices);
    const color = colors[index];
    const initialRegion = {
      x: 26,
      y: 25,
      width: 44,
      height: 50,
      new: false,
      isAreaNotSaved: true,
      index: index,
      data: {
        ...getZoneCoordinates(26, 25, 44, 50),
        zoneName: getDefaultAreaName(regions),
        // person: false,
        // vehicle: false,
        // motion: false,
        regionColor: color,
        regionStyle: {
          border: `2px solid ${color}`,
        },
        x: 26,
        y: 25,
        width: 44,
        height: 50,
      },
      isChanging: false,
    };
    setRegions([...regions, initialRegion]);
    resetCoords([...regions, initialRegion]);
    updateRegionAndForm(initialRegion);
    setIsAddZoneClicked(true);
  };

  useEffect(() => {
    if (regions?.length > 0) {
      setValue('zoneName', getSelectedArea(false));
    }
    setIsAddZoneClicked(false);
  }, [isAddZoneClicked]);

  const onRegionChange = (regs) => {
    let updatedRegions = [];
    if (regs.filter((x) => x.isChanging).length === 0) {
      updatedRegions = regs.map((reg) => {
        return {
          ...reg,
          data: {
            ...reg.data,
            ...getZoneCoordinates(reg.x, reg.y, reg.width, reg.height),
            x: reg.x,
            y: reg.y,
            height: reg.height,
            width: reg.width,
          },
        };
      });
    }
    setInitialZoneName('');
    setRegions(updatedRegions.length > 0 ? updatedRegions : regs);
  };

  const getZoneCoordinates = (left, top, width, height) => {
    const divResolutionWidth =
      document.getElementById('img-snapshot')?.offsetWidth;
    const divResolutionHeight =
      document.getElementById('img-snapshot')?.offsetHeight;
    const proportionWidth = parseInt(REGION_X_RANGE - 1) / divResolutionWidth;
    const proportionHeight = parseInt(REGION_Y_RANGE - 1) / divResolutionHeight;

    const updatedLeft = proportionWidth * ((divResolutionWidth / 100) * left);
    const updatedTop = proportionHeight * ((divResolutionHeight / 100) * top);
    const updatedHeight =
      proportionHeight * ((divResolutionHeight / 100) * height);
    const updatedWidth = proportionWidth * ((divResolutionWidth / 100) * width);

    const TL = { x: updatedLeft, y: updatedTop };
    const TR = { x: updatedLeft + updatedWidth, y: updatedTop };
    const BL = { x: updatedLeft, y: updatedTop + updatedHeight };
    const BR = { x: updatedLeft + updatedWidth, y: updatedTop + updatedHeight };

    return {
      TLx: Math.round(TL.x),
      TLy: Math.round(TL.y),
      TRx: Math.round(TR.x),
      TRy: Math.round(TR.y),
      BLx: Math.round(BL.x),
      BLy: Math.round(BL.y),
      BRx: Math.round(BR.x),
      BRy: Math.round(BR.y),
    };
  };

  const getZoneDimensions = (BLx, BLy, BRx, BRy, TRx, TRy, TLx, TLy) => {
    const divResolutionWidth =
      document.getElementById('img-snapshot')?.offsetWidth;
    const divResolutionHeight =
      document.getElementById('img-snapshot')?.offsetHeight;
    const proportionWidth = parseInt(REGION_X_RANGE - 1) / divResolutionWidth;
    const proportionHeight = parseInt(REGION_Y_RANGE - 1) / divResolutionHeight;
    const left = +TLx / proportionWidth / (divResolutionWidth / 100);
    const top = +TLy / proportionHeight / (divResolutionHeight / 100);
    const width = (+BRx - +BLx) / proportionWidth / (divResolutionWidth / 100);
    const height =
      (+TLy - +BLy) / proportionHeight / (divResolutionHeight / 100);

    return {
      left: Math.abs(left),
      top: Math.abs(top),
      width: Math.abs(width),
      height: Math.abs(height),
      TLx,
      TLy,
      TRx,
      TRy,
      BLx,
      BLy,
      BRx,
      BRy,
    };
  };

  const getMsgProperties = (region) => {
    const index = colors.indexOf(region.data.regionColor) || 0;
    const jsonObject = {};
    const keyPrefix = `virtual-area`;
    jsonObject[`${keyPrefix}-index-enable`] = 'true';
    jsonObject[`${keyPrefix}-uuid`] = uuidv4();
    jsonObject[`${keyPrefix}-enable`] = 'true';
    jsonObject[`${keyPrefix}-index`] = index;
    jsonObject[`${keyPrefix}-name`] = region.data.zoneName;
    jsonObject[`${keyPrefix}-coordinates`] =
      `${region.data.BLx},${region.data.BLy},${region.data.BRx},${region.data.BRy},${region.data.TRx},${region.data.TRy},${region.data.TLx},${region.data.TLy}`;
    return jsonObject;
  };

  const saveZoneHandler = (values) => {
    setErrorMsg('');
    setShowLoader(true);
    setRemainingTime(expiredTime);
    try {
      const regionsWithUpdatedCoordinates = regions.find(
        (region) =>
          region.data.regionColor === selectedRegion?.data?.regionColor,
      );
      const updatedSelectedRegion = {
        ...regionsWithUpdatedCoordinates,
        data: {
          ...regionsWithUpdatedCoordinates.data,
          ...values,
        },
      };
      const msgProperties = getMsgProperties(updatedSelectedRegion);
      const propertyData =
        eventList?.length > 0
          ? { ...property, ...msgProperties, ...categoryType }
          : { ...msgProperties, ...categoryType };
      publishDeviceSetting(propertyData, resourceList[13], publishPayload);
      setSelectedRegion(updatedSelectedRegion);
    } catch (error) {
      Utils.vmsLogger().log('error', error);
      setShowLoader(false);
      setRemainingTime(0);
    }
  };

  const checkDuplicateAreaOnTabChange = (regions) => {
    const zoneNamesList = regions
      .map((region) => region?.data?.zoneName?.trim())
      .filter(Boolean);
    const seen = new Set();
    const duplicates = [];
    for (const zoneName of zoneNamesList) {
      if (seen.has(zoneName)) {
        if (!duplicates.includes(zoneName)) {
          duplicates.push(zoneName);
          setDuplicateZone(zoneName);
        }
      } else {
        seen.add(zoneName);
      }
    }
    setDuplicateZone(duplicates);
    return duplicates.length > 0;
  };

  const handleZoneClick = (region) => {
    setIsAddZoneClicked(true);
    updateRegionAndForm(region);
    setPropertyInitial({});
    setUnsavedZone('');
    resetCoords(regions);
    if (checkDuplicateAreaOnTabChange(regions)) {
      setShowDuplicateZone(true);
    } else {
      setDuplicateZone('');
      setShowDuplicateZone(false);
    }
  };

  const updateRegionAndForm = (region) => {
    reset();
    const zoneName = region?.data?.zoneName ? region.data.zoneName : '';
    setInitialZoneName(zoneName); // Save the initial zone name when the region is selected
    setValue('zoneName', zoneName);
    setSelectedRegion(region);
  };

  const handleRemoveZone = (e, region) => {
    e.stopPropagation();
    setErrorMsg('');
    setShowLoader(true);

    const duplicateZoneNameExist = checkZoneNameDuplicates(
      regions,
      region.data.zoneName,
      region,
    );
    if (duplicateZoneNameExist) {
      setDuplicateZone(e.target.value);
      setShowDuplicateZone(true);
    } else {
      setDuplicateZone('');
      setShowDuplicateZone(false);
    }

    try {
      const currentIndex = colors.indexOf(region.data.regionColor);
      if (
        exclusionAreas.find((q) => q['virtual-area-index'] === currentIndex)
      ) {
        setErrorMsg('');
        setShowLoader(true);
        setRemainingTime(expiredTime);
        const msgProperties = {
          'virtual-area-index': currentIndex,
          'virtual-area-enable': 'true',
        };
        deleteDeviceSetting(msgProperties, resourceList[13], publishPayload);
        setIsDeleteTriggered(true);
      } else {
        const updatedRegions = regions.filter(
          (item) => item.data.regionColor !== region.data.regionColor,
        );
        setRegions(updatedRegions);
        setSelectedRegion(updatedRegions.length > 0 ? updatedRegions[0] : null);
        updateRegionAndForm(
          updatedRegions.length > 0 ? updatedRegions[0] : null,
        );
        setTimeout(() => {
          setShowLoader(false);
        }, 1000);
      }
    } catch (error) {
      Utils.vmsLogger().log('error', error);
      setShowLoader(false);
      setRemainingTime(0);
    }
  };

  const handleErrorMsgClose = () => {
    setErrorMsg('');
  };

  const getSelectedArea = (isRemove) => {
    if (isRemove) {
      return regions?.find(
        (region) =>
          region?.data?.regionColor === selectedRegion?.data?.regionColor,
      );
    }
    // Check if regions exist
    if (!regions || regions?.length <= 0) {
      return '';
    }
    // Find the selected area based on region color
    const selectedArea = regions?.find(
      (region) =>
        region?.data?.regionColor === selectedRegion?.data?.regionColor,
    );
    // Return the appropriate Area based on conditions
    if (selectedArea) {
      return watchedFields.zoneName || `Area${regions?.length}`;
    } else if (selectedRegion?.data?.zoneName) {
      return selectedRegion.data.zoneName;
    }
    return '';
  };

  const eventTypeSelected = (publishData) => {
    if (Object.keys(property)?.length == 0) {
      setPropertyInitial(publishData);
    }
    const isAnyPropertyTrue = Object.values(publishData).includes('true');
    if (isAnyPropertyTrue) {
      setIsAnyObjectSelected(false);
    } else {
      setIsAnyObjectSelected(true);
    }
    setProperty(publishData);
  };

  useEffect(() => {
    const virtualAreaIva = deviceInfos?.properties?.['virtual-area']
      ? JSON.parse(deviceInfos?.properties?.['virtual-area'])
      : { 'virtual-areas': [] };
    const selctedObjtTypes = virtualAreaIva?.['virtual-areas']?.find(
      (item) => item?.['virtual-area-index'] == selectedRegion?.index,
    );
    const objeProperty = {
      'virtual-area-person': selctedObjtTypes?.['virtual-area-person'],
      'virtual-area-vehicle': selctedObjtTypes?.['virtual-area-vehicle'],
      'virtual-area-vehicle-bicycle-detection':
        selctedObjtTypes?.['virtual-area-vehicle-bicycle-detection'],
      'virtual-area-vehicle-bus-detection':
        selctedObjtTypes?.['virtual-area-vehicle-bus-detection'],
      'virtual-area-vehicle-car-detection':
        selctedObjtTypes?.['virtual-area-vehicle-car-detection'],
      'virtual-area-vehicle-motorcycle-detection':
        selctedObjtTypes?.['virtual-area-vehicle-motorcycle-detection'],
      'virtual-area-vehicle-truck-detection':
        selctedObjtTypes?.['virtual-area-vehicle-truck-detection'],
    };
    const categoryType = {
      'virtual-area-appear-disappear':
        selctedObjtTypes?.['virtual-area-appear-disappear'],
      'virtual-area-appear-disappear-min-dur':
        selctedObjtTypes?.['virtual-area-appear-disappear-min-dur'],
      'virtual-area-entering': selctedObjtTypes?.['virtual-area-entering'],
      'virtual-area-exiting': selctedObjtTypes?.['virtual-area-exiting'],
      'virtual-area-intrusion': selctedObjtTypes?.['virtual-area-intrusion'],
      'virtual-area-intrusion-min-dur':
        selctedObjtTypes?.['virtual-area-intrusion-min-dur'],
      'virtual-area-loitering': selctedObjtTypes?.['virtual-area-loitering'],
      'virtual-area-loitering-min-dur':
        selctedObjtTypes?.['virtual-area-loitering-min-dur'],
    };
    eventTypeListObj?.forEach((item) => {
      if (virtualAreaIva && virtualAreaIva?.['virtual-areas']?.length > 0) {
        const apiValue = selctedObjtTypes?.[item.keyName];
        if (apiValue !== undefined && !isDeleteTriggered) {
          item.isSlected = apiValue === 'true' ? '1' : '0'; // Update main category
        } else {
          item.isSlected = '0'; // Update vehicle type
        }
        // If the item is the Vehicle category, update vehicle types
        if (item.typeName === EventType?.VEHICLE && item?.vehicleTypes) {
          item?.vehicleTypes?.forEach((vehicle) => {
            const vehicleApiValue = selctedObjtTypes?.[vehicle.keyName];
            if (vehicleApiValue !== undefined && !isDeleteTriggered) {
              vehicle.isSlected = vehicleApiValue === 'true' ? '1' : '0'; // Update vehicle type
            } else {
              vehicle.isSlected = '0'; // Update vehicle type
            }
          });
        }
      } else {
        item.isSlected = '0'; // Update main category
        item?.vehicleTypes?.forEach((vehicle) => {
          vehicle.isSlected = '0'; // Update vehicle type
        });
      }
    });
    let anyUndefined = false;
    let objectSelected = false;
    for (let key in objeProperty) {
      if (objeProperty[key] === undefined) {
        anyUndefined = true;
        objeProperty[key] = 'false'; // Change undefined values to "false"
      }
      if (objeProperty[key] === 'true') {
        objectSelected = true;
      }
    }
    setCategoryUpdated(categoryType);
    if (objectSelected) {
      setIsAnyObjectSelected(false);
    } else {
      setIsAnyObjectSelected(true);
    }
    if (anyUndefined) {
      setPropertyInitial(objeProperty);
      setProperty(objeProperty);
    } else {
      setPropertyInitial(objeProperty);
    }
    setEventTypeListObj(eventTypeListObj);

    const eventListData = eventTypeListObj;
    const keys = Object.keys(capExclusionArea ? capExclusionArea : {});
    const filteredKeys = keys?.filter((key) =>
      Utils.selectedKeysVirtualAreaIVA.includes(key),
    );
    const hasVehicleDetection = Utils.vehicleKeysVirtualAreaIVA.some((key) =>
      filteredKeys?.includes(key),
    );
    if (
      hasVehicleDetection &&
      !filteredKeys?.includes(constants.VIRTUAL_AREA_IVA_VEHICLE_KEY)
    ) {
      filteredKeys?.push(constants.VIRTUAL_AREA_IVA_VEHICLE_KEY);
    }
    const filteredEventList = eventListData?.filter((item) =>
      filteredKeys?.includes(item.keyName),
    );
    const finalEventList = filteredEventList?.map((item) => {
      if (
        item?.keyName === constants.VIRTUAL_AREA_IVA_VEHICLE_KEY &&
        item?.vehicleTypes?.length
      ) {
        item.vehicleTypes = item.vehicleTypes.filter((vehicle) =>
          filteredKeys?.includes(vehicle.keyName),
        );
      }
      return item;
    });
    setEventList(finalEventList || []);
  }, [deviceInfos, selectedRegion, isDeleteTriggered]);

  const checkZoneNameDuplicates = (
    regions,
    eventTargetValue,
    selectedRegion,
  ) => {
    for (const region of regions) {
      const zoneName = region?.data?.zoneName;
      const regionColor = region?.data?.regionColor;
      const selectedRegionColor = selectedRegion?.data?.regionColor;
      if (
        zoneName === eventTargetValue &&
        regionColor !== selectedRegionColor
      ) {
        return true;
      }
    }
    return false;
  };

  const handleChangeZoneName = (e, selectedregion) => {
    if (e.target.value && !regexAnalytics.test(e.target.value)) {
      setValue('zoneName', lastSelectedZoneName);
      setRegions((prevRegions) => {
        return prevRegions.map((region) =>
          region?.isAreaNotSaved && region?.index === selectedregion?.index
            ? {
                ...region,
                data: {
                  ...region.data,
                  zoneName: lastSelectedZoneName,
                },
                isFieldAdded: true,
              }
            : region,
        );
      });
      e.preventDefault();
      return;
    } else {
      setLastSelectedZoneName(e.target.value);
    }
    setValue('zoneName', e.target.value);
    setRegions((prevRegions) => {
      return prevRegions.map((region) =>
        region?.isAreaNotSaved && region?.index === selectedregion?.index
          ? {
              ...region,
              data: { ...region.data, zoneName: e.target.value },
              isFieldAdded: true,
            }
          : region,
      );
    });
    const duplicateZoneNameExist = checkZoneNameDuplicates(
      regions,
      e.target.value,
      selectedregion,
    );
    if (duplicateZoneNameExist) {
      setDuplicateZone(e.target.value);
      setShowDuplicateZone(true);
    } else {
      setDuplicateZone('');
      setShowDuplicateZone(false);
    }
  };

  const isStateChanged = () => {
    // Shallow comparison: Check if any key in the states has different values
    return Object.keys(categoryUpdated).some(
      (key) => categoryUpdated[key] !== categoryType[key],
    );
  };

  const isStateChangedProperty = () => {
    return Object.keys(propertyInitial).some((key) => {
      return propertyInitial[key] !== property[key];
    });
  };

  useEffect(() => {
    if (isStateChanged()) {
      setInitialZoneName('');
    } else {
      setInitialZoneName(selectedRegion?.data?.zoneName);
    }
  }, [categoryType]);

  useEffect(() => {
    if (isStateChangedProperty()) {
      setInitialZoneName('');
    }
  }, [property, propertyInitial]);

  const isZoneNameChanged = watchedFields.zoneName !== initialZoneName;
  const selectedRegionData = regions?.find(
    (region) => region?.data?.regionColor === selectedRegion?.data?.regionColor,
  );
  const checkAreaName = selectedRegionData
    ? watchedFields?.zoneName || 'Area' + regions?.length
    : selectedRegionData?.isFieldAdded
      ? selectedRegionData?.data?.zoneName
      : selectedRegionData?.isAreaNotSaved
        ? `${selectedRegionData?.data?.zoneName} ${regions?.length}`
        : selectedRegionData?.data?.zoneName;

  return (
    <div className="component-container">
      <div className={`${showLoader ? 'disable-bg' : ''}`}></div>
      {showLoader && (
        <SiteSpinner
          height="50px"
          width="50px"
          styles={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            bottom: '50%',
            right: '50%',
            zIndex: 15,
          }}
        ></SiteSpinner>
      )}
      <div className="activity-zone-info obj-detection-type">
        {constants.VIRTUAL_AREA_MODAL_MESSAGE}
      </div>
      <div className="hr-devider"></div>
      {errorMsg && (
        <div className="mb-4 error-conatiner">
          <div className="error-message-container">
            <RiErrorWarningLine className="icon-warning-col" />
            <div className="">{errorMsg}</div>
          </div>
          <RxCross1
            onClick={() => handleErrorMsgClose()}
            className="icon-warning-col"
          />
        </div>
      )}
      {!unsavedZone && exclusionAreas && exclusionAreas.length > 0 ? (
        <></>
      ) : (
        (unsavedZone || exclusionAreas?.length > 0) && (
          <div className="mb-4 error-conatiner">
            <div className="error-message-container">
              <InformationCircle className="icon-error-col" />
              <div className="error-headline">
                {constants.YOU_HAVE_UNSAVED_ZONE}
              </div>
            </div>
            <RxCross1
              onClick={() => setUnsavedZone('')}
              className="icon-warning-col"
            />
            <div className="unsaved">
              {checkAreaName ? checkAreaName : unsavedZone}
            </div>
          </div>
        )
      )}
      {!duplicateZone ? (
        <></>
      ) : (
        showDuplicateZone &&
        duplicateZone?.length > 0 && (
          <div className="mb-4 error-conatiner">
            <div className="error-message-container">
              <InformationCircle className="icon-error-col" />
              <div className="error-headline">
                {constants.YOU_HAVE_DUPLICATE_ZONE}
              </div>
            </div>
            <RxCross1
              onClick={() => setShowDuplicateZone(false)}
              className="icon-warning-col"
            />
            <div className="unsaved">{duplicateZone}</div>
          </div>
        )
      )}
      <div className="area-conatiner">
        <div className="modal-sub-heading-left">
          {constants.INCLUSION_ZONE_POPUP_CREATE_ZONES}
        </div>
        {regions.length < MAX_REGION_TO_BE_ADDED_COUNT && (
          <PrimaryButton
            className="add-zone-btn"
            type="button"
            width="112px"
            fontSize="12px"
            lineHeight="16px"
            borderWidth="0"
            height="8px"
            fontWeight="600"
            hoverBorderWidth="0"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--primary_40',
            )}
            hoverColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--primary_40')}
            disabledBackgroundColor="transparent"
            disabledColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--greyscale_72')}
            hoverBackgroundColor="transparent"
            backgroundColor="transparent"
            onClick={handleAddZoneClick}
          >
            <RxPlus
              className="plus-icon-btn-areas"
              strokeWidth={'1.5px'}
              size={16}
            />
            {constants.ANALYTICS_SETTINGS_POPUP_ADD_ZONE}
          </PrimaryButton>
        )}
      </div>
      {regions?.length > 0 && (
        <div className="activity-zone-info">
          {constants.ANALYTICS_SETTINGS_POPUP_CREATE_ZONES_INFO}
        </div>
      )}
      <div className="regions-container mt-3 mb-3">
        <span className="regions-button-container">
          {regions.map((region, index) => (
            <PrimaryButton
              key={index}
              className={`region-btn ${
                region.data.regionColor === selectedRegion?.data?.regionColor &&
                'selected-region'
              }`}
              type="button"
              fontSize="12px"
              width="fit-content"
              lineHeight="16px"
              borderWidth="0"
              hoverBorderWidth="0"
              color={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--primary_40')}
              hoverColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--primary_40')}
              hoverBackgroundColor="transparent"
              disabledBackgroundColor="transparent"
              backgroundColor="transparent"
              onClick={() => handleZoneClick(region)}
            >
              <div
                className="circle-dot"
                style={{ backgroundColor: region.data.regionColor }}
              ></div>
              {region.data.regionColor === selectedRegion?.data?.regionColor
                ? watchedFields.zoneName
                : region?.isFieldAdded
                  ? region.data.zoneName
                  : region?.isAreaNotSaved
                    ? `${region.data.zoneName}`
                    : region.data.zoneName}
            </PrimaryButton>
          ))}
        </span>
      </div>
      <div className="snap-shot-container">
        <div className="live-snapshot">
          <InclusionExclusionSnapShot
            key={deviceInfos?.deviceId}
            deviceElemId={`camera${deviceInfos?.deviceId}`}
            orgId={deviceInfos?.orgId}
            cdnInfo={cdnInfo}
            locationAreaName={deviceInfos?.locationAreaNames}
            isHighlighted={false}
            showZoomOptions={false}
            conStatus={deviceInfos?.connectionStatus || 'offline'}
            hubId={deviceInfos?.gatewayId}
            displayDeviceStatus={Utils.getDeviceStatus(
              deviceInfos?.deviceStatus,
              deviceInfos?.connectionStatus,
            )}
            setImageWidth={setImageWidth}
            aspectRatio={aspectRatio}
            analytics={true}
            {...deviceInfos}
          />
          <RegionSelect
            id="region-select"
            maxRegions={3}
            regions={regions}
            selectedRegion={selectedRegion}
            regionStyle={{
              outline: 'none',
              zIndex: 70,
            }}
            className={`region-container region${selectedRegion?.index}`}
            onChange={onRegionChange}
            style={{
              border: '0px solid black',
              position: 'absolute',
              height: '-webkit-fill-available',
              display: 'inline-block',
              // top: '0px',
              // left: '0px',
              width: imageWidth,
              // maxWidth: '100%',
              // maxHeight: '100%',
            }}
            constraint={true}
          >
            {regions
              .filter(
                (region) =>
                  region.data.regionColor !== selectedRegion?.data?.regionColor,
              )
              .map((region) => (
                <div
                  style={{
                    border: `2px solid ${region.data.regionColor}`,
                    position: 'absolute',
                    height: `${region.data.height}%`,
                    width: `${region.data.width}%`,
                    zIndex: 20,
                    pointerEvents: 'none',
                    left: `${region.data.x}%`,
                    top: `${region.data.y}%`,
                  }}
                ></div>
              ))}
          </RegionSelect>
        </div>
      </div>
      <form ref={formRef} onSubmit={handleSubmit(saveZoneHandler)}>
        {selectedRegion && (
          <>
            <div className="add-zone-container">
              <div className="mb-4 mt-4 zone-input-wrapper">
                <div className="placeholder-wrap">
                  <input
                    {...register('zoneName', { required: true, maxLength: 25 })}
                    defaultValue={selectedRegion?.data?.zoneName}
                    className="input-box"
                    autoFocus
                    maxLength={25}
                    onKeyDown={(e) => {
                      if (!regexAnalytics.test(e.key)) {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    onPaste={(e) => {
                      const pastedText = e.clipboardData.getData('text');
                      if (!regexAnalytics.test(pastedText)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => handleChangeZoneName(e, selectedRegion)} // Manually handle input changes
                  />
                  {!watchedFields.zoneName && (
                    <div className="custom-placeholder">
                      {
                        constants.ANALYTICS_SETTINGS_POPUP_CREATE_ZONES_PLACEHOLDER
                      }
                      <span className="required">*</span>
                    </div>
                  )}
                </div>
                {errors.zoneName && (
                  <div className="error">
                    {errors.zoneName.type === 'required'
                      ? constants.ANALYTICS_SETTINGS_POPUP_CREATE_ZONES_ERROR
                      : constants.ANALYTICS_SETTINGS_POPUP_CREATE_ZONES_MAXLENGTH_ERROR}
                  </div>
                )}
                <div className="line-crossing-count">
                  {watchedFields.zoneName.length || 0}/25
                </div>
              </div>
            </div>

            {eventList?.length > 0 && (
              <CategoryEventSelection
                selectedRegion={selectedRegion}
                saveBtnEnable={false}
                title={EventType?.OBJ_TYPE}
                subtitle={EventType?.SUB_OBJ_CATEGORIES}
                deviceInfosProperty={deviceInfos}
                callBackMethod={(publishData) => {
                  eventTypeSelected(publishData);
                }}
                eventListData={eventList}
              />
            )}

            <CategoryTypeIVA
              isObjectSelected={
                eventList?.length > 0 ? isObjectSelected : false
              }
              title={EventType?.CATEGORIES}
              subtitle={EventType?.SUB_CATEGORIES}
              deviceInfos={deviceInfos}
              canChangeSettings={canChangeSettings}
              capVirtualAreaIVA={capExclusionArea}
              callBackMethod={(publishData) => {
                if (Object?.keys(categoryType)?.length == 0) {
                  setCategoryUpdated(publishData);
                }
                setCategoryType(publishData);
              }}
              virtualAreaIva={
                deviceInfos?.properties?.['virtual-area']
                  ? JSON.parse(deviceInfos?.properties?.['virtual-area'])
                  : { 'virtual-areas': [] }
              }
              selectedRegion={selectedRegion}
              isDeleteTriggered={isDeleteTriggered}
            />

            <div className="button-conatiner-excluded-area">
              <PrimaryButton
                className="btn btn-primary mt-4"
                type="button"
                width="auto"
                color={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--primary_40')}
                backgroundColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--brand_white')}
                disabled={
                  !selectedRegion ||
                  !watchedFields.zoneName ||
                  watchedFields?.zoneName?.length > 25
                }
                onClick={(e) => handleRemoveZone(e, getSelectedArea(true))}
              >
                {`${constants.DEVICES_TAB_NETWORK_MAXIMUM_DELETE} ${getSelectedArea(false)}`}
              </PrimaryButton>
              <PrimaryButton
                className="btn btn-primary mt-4 btn-margin-save"
                type="submit"
                width="20%"
                disabled={
                  !selectedRegion || // No region selected
                  !watchedFields.zoneName || // No zone name entered
                  watchedFields?.zoneName?.length > 25 || // Name exceeds max length
                  !isZoneNameChanged ||
                  (eventList?.length > 0 ? isObjectSelected : false) ||
                  duplicateZone ||
                  !regexAnalytics.test(watchedFields?.zoneName)
                }
              >
                {constants.ANALYTICS_SETTINGS_POPUP_SAVE}
              </PrimaryButton>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default VirtualAreaIva;
