import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Zoom,
} from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import axios from 'axios';
import moment from 'moment';
import timezones from '../../../data/support/timezone.json';
import 'moment-timezone';

import { SiteToast, Spinner, Tags } from '../../../components/common/';
import { ClipTypeEnum, constants, Utils } from '../../../helpers';

import { usePoliciesStore } from '../../../store/policiesStore';

import { ReactComponent as InformationCircle } from '../../../assets/images/icons/InformationCircle.svg';

import {
  HiOutlineCheckCircle,
  HiOutlineFilm,
  HiOutlineFolderAdd,
  HiOutlinePlusSm,
  HiOutlineShare,
} from 'react-icons/hi';
import { ReactComponent as UpArrow } from '../../../assets/images/icons/ChevronUp.svg';

import { Share } from '../../clips';
import ImageFetch from '../components/ImageFetch';
import colors from '../../../assets/css/_colors.scss';
import './modalForms.scss';
import { useSelector } from 'react-redux';
import { getCustomerOrgData } from '../../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../../store/indexDB/observer';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';

import './CreateClip.scss';
import { devicesMQTTStore } from '../../../store/DevicesMQTTStore';
import {
  getCurrentEventResource,
  mqttPublish,
} from '../../../utils/connection/mqttConnection';
import {
  getDeviceInformation,
  getAllMqttDataFromResponse,
} from '../../../store/reducers/AccountReducer';

const MAX_COMMENT_NUM_CHARACTERS = 250;

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip
    TransitionComponent={Zoom}
    {...props}
    classes={{ popper: className }}
  />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 450,
    marginLeft: '-3px',
    backgroundColor: '#000',
  },
});

const CreateClip = ({
  cdnValue,
  orgId,
  deviceId,
  deviceTimezone,
  startTime,
  endTime,
  canSubmitEnabler = null,
  shouldSubmitCreateClipRequest = false,
  shouldResetCreateClipForm = false,
  shouldShowPostCreateClipForm = false,
  enableShareVideoClipModal,
  openNewIncidentModal,
  newIncidentId,
  saveClipObject = null,
  clipObject,
  addedToIncident,
  buttonLoaderCallback = () => {},
  commentCallback = null,
  tagsCallback = null,
  callback = () => {},
  errorCallback = () => {},
  hideClipModal,
  entitleData,
  hubId,
  account,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const deviceDetails = useSelector(getDeviceInformation);
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState(
    constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_CLIP_CREATED_NOTICE,
  );
  const [errorMsg, setErrorMsg] = useState('');
  const [showShareVideoClipModal, setShowShareVideoClipModal] = useState(false);
  const [clipComment, setClipComment] = useState('');
  const [clipTags, setClipTags] = useState([]);
  const [clipV2Call, setClipV2Call] = useState(false);
  const [incidents, setIncidents] = useState([]);
  const [numOfCharacters, setNumOfCharacters] = useState(0);
  const [selectedOption, setSelectedOption] = useState(
    constants.CLIP_DETAILS_ADD_TO_INCIDENT_LABEL,
  );
  const [incidentSelections, setIncidentSelections] = useState([]);
  const [clipId, setClipId] = useState(null);
  const [clipRequestSubmitted, setClipRequestSubmitted] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isClipCreated, setIsClipCreated] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [isStorageCapacityExceeded, setIsStorageCapacityExceeded] =
    useState(false);
  const [placeholderVisible, setPlaceholderVisible] = useState(true);
  const [comment, setComment] = useState('');
  const [isCommentDisabled, setIsCommentDisabled] = useState(false);
  const [orgDetails, setOrgDetails] = useState();
  const getLoggedInUserPolicies = usePoliciesStore(
    (state) => state.getLoggedInUserPolicies,
  );
  const currentUserPolicies = getLoggedInUserPolicies();
  const capabilityList = deviceDetails?.properties;
  const navigate = useNavigate();
  const commentRef = useRef(null);

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setOrgDetails(orgs?.[0] || {});
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000,
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  useEffect(() => {
    const responseDeviceId = allMqttData?.msg?.resource
      ?.toString()
      ?.split('/')?.[1];
    const resource = allMqttData?.msg?.resource
      ?.toString()
      ?.split(`${deviceId}/`)?.[1];
    if (
      resource === ClipTypeEnum.CREATE_CLIP_IS_CLIP_AVAILABLE &&
      deviceId === responseDeviceId &&
      allMqttData?.msg?.properties
    ) {
      const propertyData = allMqttData?.msg?.properties;
      setClipV2Call(propertyData.isClipAvailable);
      if (!propertyData) {
        setUserMsg(constants.CLIP_EDGE_RECORDING_ERROR);
        setShowToast(true);
        typeof errorCallback === 'function' && errorCallback();
      }
    }
  }, [allMqttData]);

  useEffect(() => {
    if (orgDetails?.orgId) {
      // Retrieve all incidents
      fetchIncidents();
    }
  }, [orgDetails?.orgId]);

  useEffect(() => {
    if (shouldResetCreateClipForm === true) {
      resetCreateClipForm();
    }
  }, [shouldResetCreateClipForm]);

  const getEdgeClipFlag = () => {
    const mqttState = devicesMQTTStore.getState();
    const tid = Math.floor(new Date().getTime());
    const context = {
      topic: `a/notify/${hubId}`,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: hubId,
        from: `app`,
        msg: {
          action: 'get',
          resource: `ch/${deviceId}/local-storage/rec-available`,
          properties: {
            startTime: startTime,
            endTime: endTime,
          },
        },
        publish: `d/notify/${account?.accountId}/${mqttState?.sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
  };

  const getClipperVer = () => {
    const deviceEntitlement = entitleData;
    const currentTimestamp = Date.now();
    const cloudStorageArchives = deviceEntitlement.find(
      (item) => item.type === ClipTypeEnum.CREATE_CLIP_CLOUD_STORAGE_ARCHIVES,
    );
    const edgeRecordingClipUpload = deviceEntitlement.find(
      (item) =>
        item.type === ClipTypeEnum.CREATE_CLIP_EDGE_RECORDING_CLIP_UPLOAD,
    );

    if (
      cloudStorageArchives &&
      currentTimestamp <= cloudStorageArchives.expiry
    ) {
      return 'clipperV1';
    } else if (
      edgeRecordingClipUpload &&
      currentTimestamp <= edgeRecordingClipUpload.expiry
    ) {
      return 'clipperV2';
    }
  };

  const executeClipRequestV2 = async () => {
    if (clipV2Call === true || clipV2Call === 'true') {
      const url = `/clip/orgs/${orgDetails?.orgId}/clips/edge`;
      await submitClipRequest(url);
    } else {
      setUserMsg(constants.CLIP_EDGE_RECORDING_ERROR);
      setShowToast(true);
      setClipComment(null);
      setNumOfCharacters(0);
      commentRef.current.disabled = true;
      setIsCommentDisabled(true);
      typeof canSubmitEnabler === 'function' && canSubmitEnabler(false);
      typeof errorCallback === 'function' && errorCallback();
    }
  };

  useEffect(() => {
    if (clipV2Call) executeClipRequestV2();
  }, [clipV2Call]);

  useEffect(() => {
    const executeClipRequest = async () => {
      if (getClipperVer() === 'clipperV1') {
        const url = `/clip/orgs/${orgDetails?.orgId}/clips`;
        await submitClipRequest(url);
      } else if (getClipperVer() === 'clipperV2') {
        if (
          deviceDetails?.connectionStatus ===
          ClipTypeEnum.DEVICES_CLIP_ONLINE_CONNECTION_STATUS
        ) {
          getEdgeClipFlag();
        } else {
          setUserMsg(constants.CLIP_EDGE_RECORDING_DEVICE_OFFLINE);
          setShowToast(true);
          typeof errorCallback === 'function' && errorCallback();
        }
      }
    };

    if (shouldSubmitCreateClipRequest === true) {
      executeClipRequest();
    }
  }, [shouldSubmitCreateClipRequest]);

  useEffect(() => {
    setClipRequestSubmitted(!!shouldShowPostCreateClipForm);
  }, [shouldShowPostCreateClipForm]);

  useEffect(() => {
    if (addedToIncident === true) {
      setSuccessMsg(
        constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_CLIP_ADDED_TO_INCIDENT_NOTICE,
      );
      setIsClipCreated(true);
    }
  }, [addedToIncident]);

  useEffect(() => {
    if (newIncidentId) {
      setSelectedOption(newIncidentId);
      let currentSelections = [...incidentSelections];
      currentSelections.push(newIncidentId);
      setIncidentSelections([...currentSelections]);
    }
  }, [newIncidentId]);

  const fetchIncidents = async () => {
    try {
      let filteredIncidents;

      const res = await axios.get(
        `/incident/orgs/${orgDetails?.orgId}/incidents`,
        Utils.requestHeader(),
      );
      const responseData = res?.data;

      // Set incidents if request is successful
      if (responseData?.meta?.code === 200) {
        filteredIncidents = responseData?.data?.incidents.filter(
          (incident) =>
            incident.status === 'OPEN' || incident.status === 'REOPENED',
        );
        // Sort incidents by incident summary in ascending order
        // and then save to state
        setIncidents([
          ...filteredIncidents.sort((a, b) => {
            if (a?.summary?.toLowerCase() > b?.summary?.toLowerCase()) {
              return 1;
            }

            if (a?.summary?.toLowerCase() < b?.summary?.toLowerCase()) {
              return -1;
            }

            return 0;
          }),
        ]);
      } else {
        if (res?.code) {
          Utils.vmsLogger().error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };

  const submitClipRequest = async (url) => {
    setClipV2Call(false);

    try {
      const reqBody = {
        startTime: startTime,
        endTime: endTime,
        tags: clipTags,
        comment: clipComment,
        deviceId: deviceId,
      };

      typeof buttonLoaderCallback === 'function' && buttonLoaderCallback(true);

      const res = await axios.post(url, reqBody, Utils.requestHeader());

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        setClipId(responseData.data?.clipId);
        setIsClipCreated(true);
        saveClipObject && saveClipObject(responseData?.data);
        setClipRequestSubmitted(true);
        typeof callback === 'function' && callback();
      } else {
        if (responseData?.meta?.code) {
          Utils.vmsLogger().error(
            `${responseData?.meta?.code}: ${responseData?.meta?.userMsg}`,
          );
          setUserMsg(responseData?.meta?.userMsg);
          setErrorMsg(responseData?.meta?.userMsg);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
          setUserMsg(responseData?.data?.userMsg);
          setErrorMsg(responseData?.data?.userMsg);
        }
        throw new Error(responseData?.meta?.userMsg);
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
      setUserMsg(error);
      setShowToast(true);
      typeof errorCallback === 'function' && errorCallback();
    } finally {
      // Disable the primary button loading animation
      typeof buttonLoaderCallback === 'function' && buttonLoaderCallback(false);
    }
  };

  const getUTCDateFromTimezone = (datetime, timezone) => {
    if (!timezone) return;

    const zone = Utils.getTimezone(timezone, 'olson') || moment.tz.guess();

    const UTCDateJSX = (
      <>
        <div className="clipper-date">
          {moment(datetime).tz(zone).format('LL')}
        </div>
        <div className="clipper-time-with-timezone">
          {moment(datetime).tz(zone).format('LTS z')}
        </div>
      </>
    );
    return UTCDateJSX;
  };

  const renderIncidentsList = () => {
    return (
      <FormControl
        className="incidents-list-form-control"
        InputLabelProps={{ shrink: false }}
        fullWidth
      >
        <InputLabel
          className="incident-select-label"
          defaultValue={''}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <ListItemIcon className="incident-select-icon">
            <HiOutlineFolderAdd />
          </ListItemIcon>
          <span>{constants.CAMERAS_LIVE_PLAYBACK_ADD_TO_INCIDENT_TEXT}</span>
        </InputLabel>
        <Select
          className="incident-select"
          value={incidentSelections}
          disabled={!currentUserPolicies?.create_contribute_and_view_incidents}
          onChange={(e) => {
            e.preventDefault();

            let currentSelections = [...incidentSelections];

            if (
              currentSelections.indexOf(
                constants.CLIP_DETAILS_ADD_TO_INCIDENT_LABEL,
              ) !== -1
            ) {
              currentSelections.splice(
                currentSelections.indexOf(
                  constants.CLIP_DETAILS_ADD_TO_INCIDENT_LABEL,
                ),
                1,
              );
            }

            const incidentId = e?.target?.value;

            if (!incidentId || incidentId.toUpperCase() === 'NEW') return;

            currentSelections.push(incidentId);
            setIncidentSelections([...currentSelections]);
            setSelectedOption(incidentId);
            addToIncident(incidentId);
          }}
          IconComponent={() =>
            incidentSelections.includes(
              constants.CLIP_DETAILS_ADD_TO_INCIDENT_LABEL,
            ) ? (
              <UpArrow className="incident-select-dropdown-icon" />
            ) : null
          }
          renderValue={(selected) =>
            incidentSelections.includes(
              constants.CLIP_DETAILS_ADD_TO_INCIDENT_LABEL,
            ) ? (
              <div className="incident-select-dropdown-default">
                <ListItemIcon className="incident-select-add-to-incident-icon">
                  <HiOutlineFolderAdd size={'1.25em'} />
                </ListItemIcon>
                <div className="incident-select-add-to-incident-value">
                  {constants.CLIP_DETAILS_ADD_TO_INCIDENT_LABEL}
                </div>
              </div>
            ) : (
              // <div>
              //   {
              //     incidents[
              //       incidents.findIndex(
              //         (incident) => incident.incidentId === selected
              //       )
              //     ]?.summary
              //   }
              // </div>
              <div className="incident-select-added-to-incident-value">
                {constants.CLIP_DETAILS_ADDED_TO_INCIDENT_LABEL}
              </div>
            )
          }
        >
          {Array.isArray(incidents) &&
            incidents.map((incident) => {
              return (
                <MenuItem
                  key={`incident-${incident?.incidentId}`}
                  className="create-clip-incident-item-wrapper"
                  value={incident?.incidentId}
                >
                  {incident?.summary?.length > 55 ? (
                    <>
                      <CustomWidthTooltip
                        title={incident?.summary}
                        placement="bottom-start"
                      >
                        <ListItemText
                          className="incident-item-name"
                          primary={incident.summary}
                        />
                      </CustomWidthTooltip>
                      <Checkbox
                        checked={
                          incidentSelections?.indexOf(incident?.incidentId) > -1
                        }
                        disabled={
                          incidentSelections?.indexOf(incident?.incidentId) > -1
                        }
                        size="small"
                        sx={{
                          color: getComputedStyle(
                            document.documentElement,
                          ).getPropertyValue('--brand_primary'),
                          '&.Mui-checked': {
                            color: getComputedStyle(
                              document.documentElement,
                            ).getPropertyValue('--brand_primary'),
                          },
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <ListItemText
                        className="incident-item-name"
                        primary={incident.summary}
                      />
                      <Checkbox
                        checked={
                          incidentSelections?.indexOf(incident?.incidentId) > -1
                        }
                        disabled={
                          incidentSelections?.indexOf(incident?.incidentId) > -1
                        }
                        size="small"
                        sx={{
                          color: getComputedStyle(
                            document.documentElement,
                          ).getPropertyValue('--brand_primary'),
                          '&.Mui-checked': {
                            color: getComputedStyle(
                              document.documentElement,
                            ).getPropertyValue('--brand_primary'),
                          },
                        }}
                      />
                    </>
                  )}
                </MenuItem>
              );
            })}
          <MenuItem
            key="create-clip-new-incident"
            className="incident-item-wrapper-sticky"
            value="new"
            onClick={(e) => {
              e.preventDefault();
              setClipRequestSubmitted(true);
              openNewIncidentModal && openNewIncidentModal(true);
            }}
          >
            <HiOutlinePlusSm />
            <span className="incident-item-wrapper-sticky-label">
              {constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_CREATE_NEW_LABEL}
            </span>
          </MenuItem>
        </Select>
      </FormControl>
    );
  };

  const handleAddTag = (tagsArray) => {
    let updatedTags = clipTags;

    if (!Array.isArray(tagsArray)) return;

    setClipTags([...updatedTags.concat(tagsArray)]);
  };

  const handleRemoveTag = (tagId) => {
    const currentClipTagIds = clipTags;

    if (!tagId) return;

    const tagIndex = currentClipTagIds.findIndex(
      (currentTagId) => currentTagId === tagId,
    );

    if (tagIndex !== -1) {
      currentClipTagIds.splice(tagIndex, 1);

      // setClipTags([...currentClipTagIds]);
      tagsCallback && tagsCallback([...currentClipTagIds]);
    }
  };

  const addToIncident = async (incidentId) => {
    try {
      if (!incidentId) {
        throw new Error('ERROR: Missing Incident ID');
      }

      const reqBody = {
        deviceId: deviceId,
        clipStartTime: startTime,
        clipEndTime: endTime,
        clipId: clipId || clipObject?.clipId,
      };

      const res = await axios.post(
        `/incident/orgs/${orgDetails?.orgId}/incidents/${incidentId}/clips`,
        reqBody,
        Utils.requestHeader(),
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        setSuccessMsg(
          constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_CLIP_ADDED_TO_INCIDENT_NOTICE,
        );
        // Update the list of incidents
        fetchIncidents();
      } else {
        Utils.vmsLogger().error(
          `ERROR: ${responseData?.meta?.code} - ${responseData?.meta?.desc}`,
        );
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };

  const saveClipHandler = () => {};

  const createClipForm = !formSubmitted && (
    <form onSubmit={handleSubmit(saveClipHandler)}>
      <SiteToast
        customCss="create-clip-toast"
        position="top-end"
        show={showToast}
        title="Uh-oh!"
        body={userMsg}
        delay={5000}
        onCloseCallback={errorCallback}
      />
      <div className="mb-3 create-clip-snapshot-wrapper">
        <ImageFetch time={startTime} cdnValue={cdnValue} deviceId={deviceId} />
      </div>
      {/* Clip Start Time and End Time */}
      <Row>
        <Col>
          <div className="mb-3 clip-duration-wrapper">
            <div className="clip-time clip-start-time">
              <div className="clip-duration-time">
                {constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_STARTS_AT_TEXT}
              </div>
              <div className="clip-duration-time-box">
                {getUTCDateFromTimezone(startTime, deviceTimezone)}
              </div>
            </div>
            <div className="clip-time clip-end-time">
              <div className="clip-duration-time">
                {constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_ENDS_AT_TEXT}
              </div>
              <div className="clip-duration-time-box">
                {getUTCDateFromTimezone(endTime, deviceTimezone)}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {/* Clip Comment */}
      <Row>
        <Col>
          <div className="mb-1 clip-comment">
            <TextField
              id="clipComment"
              ref={commentRef}
              className="clip-comment-field"
              multiline
              rows={3}
              name="comment"
              onChange={(e) => {
                const inputValue = e.target.value;
                setNumOfCharacters(inputValue?.length);
                setClipComment(inputValue);
                canSubmitEnabler && canSubmitEnabler(!!inputValue);
              }}
              disabled={isCommentDisabled}
              fullWidth
              required
              inputProps={{
                ...register('comment', { required: true }),
                maxLength: MAX_COMMENT_NUM_CHARACTERS,
              }}
              onFocus={() => setPlaceholderVisible(false)}
              onBlur={(e) => {
                if (!e.target.value) {
                  setPlaceholderVisible(true);
                }
              }}
            />
            {!numOfCharacters && placeholderVisible && (
              <div
                className="custom-placeholder"
                onClick={() => {
                  setPlaceholderVisible(false);
                  document.getElementById('clipComment').focus();
                }}
              >
                {constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_COMMENT_PLACEHOLDER}
                <span className="required">*</span>
              </div>
            )}
            {errors.comment && (
              <div>
                {
                  constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_COMMENT_REQUIRED_ERROR
                }
              </div>
            )}
          </div>
          <div className="char-count">
            {numOfCharacters} / {MAX_COMMENT_NUM_CHARACTERS}
          </div>
        </Col>
      </Row>
      {/* Clip Tags Title */}
      <Row>
        <Col>
          <div className="mb-3 clip-tags-title">
            {constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_TAGS_TEXT}
          </div>
        </Col>
      </Row>
      {/* Clip Tags */}
      <Row>
        <Col>
          <div className="mb-3 clip-tags-content">
            <Tags
              tagCategory="CLIP"
              addTagsCallback={handleAddTag}
              removeTagCallback={handleRemoveTag}
            />
          </div>
        </Col>
      </Row>
    </form>
  );

  const postCreateClipForm = (
    <>
      <div className="mb-3 create-clip-snapshot-wrapper">
        <div className="create-clip-video-processing">
          <div>
            <ImageFetch
              customClass="create-clip-video-processing-snapshot"
              time={startTime}
              cdnValue={cdnValue}
              deviceId={deviceId}
            />
          </div>
          <div className="create-clip-video-processing-status">
            <div className="create-clip-video-processing-status-title">
              {constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_VIDEO_PROCESSING_TITLE}
            </div>
            <div className="create-clip-video-processing-status-message">
              {
                constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_VIDEO_PROCESSING_MESSAGE
              }
            </div>
          </div>
        </div>
      </div>
      <Row>
        <div className="mb-3 create-clip-notification-wrapper">
          {isClipCreated ? (
            <div className="notification success">
              <div className="success-title-wrapper">
                <HiOutlineCheckCircle size={'1.25rem'} />
                <span className="success-title">{successMsg}</span>
              </div>
            </div>
          ) : (
            <div className="notification error">
              {hasErrors ? (
                isStorageCapacityExceeded ? (
                  <>
                    <div className="error-title-wrapper">
                      <InformationCircle />
                      <span className="error-title">
                        {
                          constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_STORAGE_CAPACITY_NOTICE_TITLE
                        }
                      </span>
                    </div>
                    <div className="error-description">
                      {
                        constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_STORAGE_CAPACITY_NOTICE_DESCRIPTION
                      }
                    </div>
                  </>
                ) : (
                  <>
                    <div className="error-title-wrapper">
                      <InformationCircle />
                      <span className="error-title">
                        {
                          constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_GENERIC_NOTICE_TITLE
                        }
                      </span>
                    </div>
                    <div className="error-description">
                      {errorMsg ||
                        constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_GENERIC_NOTICE_DESCRIPTION}
                    </div>
                  </>
                )
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      </Row>
      <Row>
        <div className="mb-3 create-clip-shortcuts-wrapper">
          {constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_SHORTCUTS_TEXT}
        </div>
      </Row>
      <Row>
        <div className="mb-3 d-flex" style={{ gap: '16px' }}>
          <Col>
            <Button
              className="create-clip-shortcut-icon"
              variant="outlined"
              startIcon={<HiOutlineFilm size={'1rem'} />}
              disabled={false}
              onClick={(e) => {
                e.preventDefault();
                navigate('/clips/list.html');
              }}
            >
              {
                constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_GO_TO_CLIPS_BUTTON_LABEL
              }
            </Button>
          </Col>
          <Col>
            <Button
              className="create-clip-shortcut-icon"
              variant="outlined"
              startIcon={<HiOutlineShare size={'1rem'} />}
              onClick={(e) => {
                e?.preventDefault();
                setShowShareVideoClipModal(true);
                enableShareVideoClipModal && enableShareVideoClipModal(true);
              }}
            >
              {constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_SHARE_BUTTON_LABEL}
            </Button>
          </Col>
        </div>
      </Row>
      <Row>
        <div className="mb-3 create-clip-incidents-wrapper">
          {renderIncidentsList()}
        </div>
      </Row>
    </>
  );

  const shareModalForm = (
    <>
      <Share
        clipId={clipId}
        grantCallback={() => {
          setShowShareVideoClipModal(false);
          hideClipModal();
        }}
      />
    </>
  );

  const resetCreateClipForm = () => {
    setClipComment(null);
    setClipTags([]);
    setNumOfCharacters(0);
    setSelectedOption(constants.CLIP_DETAILS_ADD_TO_INCIDENT_LABEL);
    setIncidentSelections([]);
    canSubmitEnabler && canSubmitEnabler(false);
  };

  if (showShareVideoClipModal) {
    // enableShareVideoClipModal && enableShareVideoClipModal();
    return shareModalForm;
  } else {
    return clipRequestSubmitted === true ? postCreateClipForm : createClipForm;
  }
};

export default CreateClip;
