import * as MP4Box from 'mp4box';
import { v4 as uuidv4 } from 'uuid';
import Store from '../../store/Store';
import {
  removeWSSConnections,
  setMultiLiveStreamLoader,
  setPlaybackBufferMeta,
  setWSSConnections,
} from '../../store/reducers/StreamingReducer';
import { Utils } from '../../helpers';
import moment from 'moment';

let virtualTimeInterval = null;
let nextAvailTime;
let noVideoDevices = [];
let token;
let websockets = {};
let sendCompleteDeviceIds = {};
let jwtTokensDeviceIds = {};
let isAutoPlay = false;
let orgID;
let cID;
let playbackUUID;
let allDevices = [];
let playbackPlayTime = '';

function startConnection(serverDetails, deviceID) {
  // const peerId = getOurId();
  const playbackServer = serverDetails?.timeline_server;
  let websocket;
  if (websockets?.[deviceID]) {
    websocket = websockets?.[deviceID];
  } else {
    websocket = new WebSocket(
      `${playbackServer.protocol}://${playbackServer.host}:${playbackServer.port}`,
    );
    websockets[deviceID] = websocket;
  }
  websocket.deviceId = deviceID;
  Store.dispatch(setWSSConnections({ id: deviceID, client: websocket }));
  const checkVideoStartTime = () => {
    const videoElement = document.getElementById(`playback-video${deviceID}`);
    if (videoElement && playbackPlayTime < videoElement.buffered.end(0)) {
      videoElement.currentTime = playbackPlayTime;
    }
  };

  websocket.onopen = async () => {
    // const con_time = Date.now() - time_dict[index];
    // Utils.vmsLogger().log("=== websocket client connected", index, con_time / 1000);
    cID = uuidv4();
    playbackUUID = uuidv4();
    const registerObj = {
      appid: uuidv4(),
      token: jwtTokensDeviceIds?.[deviceID],
      cid: cID,
      tid: Date.now().toString(),
      to: deviceID,
      from: orgID,
      msg: {
        action: 'set',
        resource: 'media/handshake',
        properties: {
          org_id: orgID,
          dev_id: deviceID,
          uuid: playbackUUID,
          type: 'REGISTER',
        },
      },
    };
    if (websocket && websocket.readyState === 1) {
      const registerMsg = JSON.stringify(registerObj, null, 2);
      websocket.send(registerMsg);
      Utils.vmsLogger().log(
        'Registering with server, setting button value to Connect',
      );
    }
  };

  websocket.onerror = (err) => {
    Utils.vmsLogger().log('websocket error', err);
    websocket.close();
  };

  websocket.onclose = (e) => {
    Utils.vmsLogger().log('cloud playback web socket closed', e);
    Store.dispatch(removeWSSConnections(websocket?.deviceId));
  };

  async function handleStream(event) {
    const videoElement = document.getElementById(`playback-video${deviceID}`);
    try {
      const data = event.data;
      const blob = await data.arrayBuffer();
      const buffer = new Uint8Array(blob);
      const type = 'video';

      function appendBufferToSourceBuffer(targetVideoElement) {
        const { sourceBuffers, segmentQueues } = targetVideoElement;
        const sourceBuffer = sourceBuffers[type];
        const segmentQueue = segmentQueues[type];

        if (sourceBuffer && !sourceBuffer.updating && segmentQueue.length > 0) {
          const nextSegment = segmentQueue.shift();
          if (nextSegment) {
            sourceBuffer?.appendBuffer(nextSegment);
          }
        }
      }

      if (videoElement) {
        if (checkInitSegment(buffer)) {
          const { sourceBuffers, segmentQueues, mediaSource } = videoElement;
          if (!segmentQueues[type]) segmentQueues[type] = [];
          const segmentQueue = segmentQueues[type];

          const mp4BoxFile = await loadMp4BoxFile(buffer);
          window.mp4BoxFile = mp4BoxFile;
          const mimeCodec = mp4BoxFile.getInfo().mime;
          Utils.vmsLogger().log('=== mime codec:', mimeCodec);
          if (!MediaSource.isTypeSupported(mimeCodec)) {
            Utils.vmsLogger().log(
              'Mime codec not supported: codec=',
              mimeCodec,
            );
            return;
          }
          if (mediaSource.sourceBuffers.length) {
            Utils.vmsLogger().log(
              "=== mutliple resolution detected, but don't add new SourceBuffer",
            );
            segmentQueue.push(buffer);
            return;
          }
          sourceBuffers[type] = mediaSource.addSourceBuffer(mimeCodec);
          const sourceBuffer = sourceBuffers[type];
          sourceBuffer.addEventListener('updateend', (event) => {
            // Utils.vmsLogger().log("=== source buffer update end");
            appendBufferToSourceBuffer(videoElement);
            const targetSourceBuffer = event.target;
            if (
              videoElement.currentTime === 0 &&
              targetSourceBuffer.buffered.length > 0
            ) {
              checkVideoStartTime();
            }
          });
          sourceBuffer.addEventListener('error', (error) => {
            Utils.vmsLogger().error(
              '=== source buffer error: ',
              error,
              videoElement.error,
            );
          });
          segmentQueue.push(buffer);
          // init_time = Date.now() - time_dict[index];
          // Utils.vmsLogger().log("=== init time: ", index, init_time);
        } else {
          const { sourceBuffers, segmentQueues } = videoElement;
          const segmentQueue = segmentQueues[type];

          if (!sourceBuffers[type]) {
            Utils.vmsLogger().log(
              '=== Source buffer not initialized, data received before the init segment is discarded.',
            );
            return;
          }
          segmentQueue.push(buffer);
          appendBufferToSourceBuffer(videoElement);
        }
      }
    } catch (error) {
      Utils.vmsLogger().log(
        '=== media source error: ',
        error,
        videoElement?.error,
      );
      //   mediaSource.endOfStream();
    }
  }

  websocket.onmessage = async (event) => {
    // Utils.vmsLogger().log("=== received message: ", event.data);
    const data = event.data;
    if (data instanceof ArrayBuffer || data instanceof Blob) {
      // Utils.vmsLogger().log("handling the stream...")
      await handleStream(event);
    } else {
      const responseMessage = JSON.parse(data);
      const type = responseMessage.msg.properties.type;
      const msgDeviceId = responseMessage?.from;
      // Utils.vmsLogger().log(JSON.stringify(responseMessage, null, 2));
      const videoElement = document.getElementById(`playback-video${deviceID}`);
      switch (type) {
        case 'REGISTERED':
          Utils.vmsLogger().log('Registered with server');
          // sendPlayRequest();
          break;
        case 'READY':
          Utils.vmsLogger().log('Ready to stream');
          break;
        case 'ERROR':
          Utils.vmsLogger().log(event.data);
          break;
        case 'SEND_COMPLETE':
          sendCompleteDeviceIds[deviceID] = true;
          break;
        case 'NO_VIDEO':
          // noVideoFromTimestamp = responseMessage?.msg?.properties?.from;
          noVideoDevices.push(responseMessage?.msg?.properties?.dev_id);
          // check whether video is in playing state or not
          const isVideoPlaying = !!(
            videoElement?.currentTime > 0 &&
            !videoElement?.paused &&
            !videoElement?.ended &&
            videoElement?.readyState > 2
          );
          if (
            !responseMessage?.msg?.properties?.next_avail_time &&
            !isVideoPlaying
          ) {
            clearLocalTimer();
            // startClientSideTimer(msgDeviceId);
          }
          if (responseMessage?.msg?.properties?.next_avail_time) {
            // clearLocalTimer();
            // nextAvailTime = responseMessage?.msg?.properties?.next_avail_time;
            if (responseMessage?.msg?.properties?.next_avail_time) {
              nextAvailTime[responseMessage?.msg?.properties?.dev_id] =
                responseMessage?.msg?.properties?.next_avail_time;
            }
            // startClientSideTimer(msgDeviceId);
          }
        default:
          break;
      }
    }
  };
}

function checkInitSegment(buffer) {
  // Utils.vmsLogger().log("=== check init segment: ", buffer.byteLength);
  const ftypBox = String.fromCharCode.apply(null, buffer.subarray(4, 8));
  return ftypBox === 'ftyp';
}

function loadMp4BoxFile(u8Buffer) {
  return new Promise((resolve, reject) => {
    const mp4BoxFile = MP4Box.createFile(false);
    mp4BoxFile.onReady = (info) => {
      Utils.vmsLogger().log('=== mp4 box info: ', info);
      resolve(mp4BoxFile);
    };
    mp4BoxFile.onError = reject;
    // u8Buffer.buffer.fileStart = 0;
    // mp4BoxFile.appendBuffer(u8Buffer.buffer);
    // mp4BoxFile.flush();
    if (checkInitSegment(u8Buffer)) {
      u8Buffer.buffer.fileStart = 0;
      mp4BoxFile.appendBuffer(u8Buffer.buffer);
      mp4BoxFile.flush();
    } else {
      reject('Non-init segment detected, skipping MP4Box processing.');
    }
  });
}

function createVideoElement(deviceID) {
  const videoElement = document.createElement('video');

  videoElement.controls = false;
  videoElement.muted = true;
  videoElement.style.width = '100%';
  videoElement.style.height = '100%';
  videoElement.id = `playback-video${deviceID}`;
  const mediaSource = new MediaSource();
  videoElement.mediaSource = mediaSource;
  videoElement.src = URL.createObjectURL(mediaSource);
  videoElement.sourceBuffers = {};
  videoElement.segmentQueues = {};
  videoElement.setAttribute('data-device-id', deviceID);

  // mediaSource.addEventListener('sourceopen', () => (mediaSource.duration = 0));
  mediaSource.addEventListener('sourceopen', handleSourceOpen);
  videoElement.addEventListener('error', handleVideoEvents);
  videoElement.addEventListener('progress', handleVideoEvents);
  videoElement.addEventListener('canplay', handleVideoEvents);
  videoElement.addEventListener('waiting', handleVideoEvents);
  return videoElement;
}

const handleSourceOpen = (event) => {
  if (event?.target?.duration) {
    event.target.duration = 0;
  }
};

const handleVideoEvents = (event) => {
  const deviceID = event?.target?.getAttribute('data-device-id');
  if (!deviceID) {
    return;
  }
  const loaderData = Store.getState()?.streaming?.multiLiveStreamLoader;
  const isLoaderDisplay = loaderData?.[deviceID];
  const findNoVideoIndex = noVideoDevices?.findIndex(
    (device) => device === deviceID,
  );
  const deviceName = allDevices?.find(
    (device) => device?.deviceId === deviceID,
  )?.deviceName;
  switch (event.type) {
    case 'canplay':
      Utils.vmsLogger().log(
        'Multi PlayBack continue command sent ',
        deviceID,
        deviceName,
        Utils.getTimesinmili(),
      );
      if (findNoVideoIndex !== -1) noVideoDevices?.splice(findNoVideoIndex, 1);
      if (isLoaderDisplay) {
        const updatedObj = {
          deviceId: deviceID,
          isLoading: false,
        };
        Store.dispatch(setMultiLiveStreamLoader(updatedObj));
      }
      break;

    case 'waiting':
      // This code is under observation
      // if (!isLoaderDisplay) {
      //   const updatedObj = {
      //     deviceId: deviceID,
      //     isLoading: true,
      //   };
      //   Store.dispatch(setMultiLiveStreamLoader(updatedObj));
      // }
      break;

    case 'playing':
      // const findNoVideoIndex = noVideoDevices?.findIndex((device) => device === deviceID);
      if (findNoVideoIndex !== -1) noVideoDevices?.splice(findNoVideoIndex, 1);
      if (isLoaderDisplay) {
        const updatedObj = {
          deviceId: deviceID,
          isLoading: false,
        };
        Store.dispatch(setMultiLiveStreamLoader(updatedObj));
      }
      break;

    case 'progress':
      assignBufferedMeta(deviceID);
      break;

    case 'error':
      handleVideoError(event, deviceID);
      break;

    default:
      break;
  }
};

const handleVideoError = (event, deviceID) => {
  const videoElement = document.getElementById(`playback-video${deviceID}`);
  Utils.vmsLogger().log('video error', event, videoElement?.error, deviceID);
};

const assignBufferedMeta = (deviceID) => {
  const videoElement = document.getElementById(`playback-video${deviceID}`);
  if (
    videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered.length > 0
  ) {
    if (!isAutoPlay) {
      videoElement.pause();
    }
    const bufferStart =
      videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.start(0);
    const bufferEnd =
      videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(0);
    if (bufferStart && bufferEnd) {
      const obj = {
        id: deviceID,
        client: {
          start: parseInt(bufferStart, 10) * 1000,
          end: parseInt(bufferEnd, 10) * 1000,
          className: 'new-meta-buffer-class',
        },
      };
      Store.dispatch(setPlaybackBufferMeta(obj));
    }
  }
};

export const connectCloudPlayback = (
  getPlatformDetails,
  deviceId,
  orgId,
  jwt_token,
) => {
  // deviceID = deviceId;
  orgID = orgId;
  jwtTokensDeviceIds[deviceId] = jwt_token;
  startConnection(getPlatformDetails, deviceId);
};

export const sendCloudPlayRequest = (requirePlayData) => {
  const deviceObj = {
    deviceId: requirePlayData?.deviceId,
    deviceName: requirePlayData?.deviceName,
  };
  const deviceIdx = allDevices?.findIndex(
    (device) => device.deviceId === requirePlayData?.deviceId,
  );
  if (deviceIdx === -1) allDevices?.push(deviceObj);

  // timelineRef = requirePlayData?.timeline;
  sendCompleteDeviceIds[requirePlayData?.deviceId] = false;
  playbackPlayTime = Utils.getUnixDate(requirePlayData?.playTime);
  const actualTime = Utils.getUnixDate(requirePlayData?.playTime) * 1000;
  cID = uuidv4();
  playbackUUID = uuidv4();
  const videoContainer = document.getElementById(
    `remote-view-wrapper${requirePlayData?.deviceId}`,
  );
  if (!videoContainer) {
    return 'videoContainerNotFound';
  }
  let videoElement = createVideoElement(requirePlayData?.deviceId);
  videoElement.autoplay = isAutoPlay;
  const videoTagId = `playback-video${requirePlayData?.deviceId}`;
  const existingVideoTag = document.getElementById(videoTagId);
  if (existingVideoTag) {
    cleanupResources(requirePlayData?.deviceId);
    // existingVideoTag.remove();
  }
  videoContainer.appendChild(videoElement);

  const playObj = {
    cid: cID,
    tid: Date.now().toString(),
    to: requirePlayData?.deviceId,
    from: orgID,
    token: jwtTokensDeviceIds?.[requirePlayData?.deviceId],
    msg: {
      action: 'set',
      resource: 'media/handshake',
      properties: {
        uuid: playbackUUID,
        org_id: orgID,
        dev_id: requirePlayData?.deviceId,
        start_time: actualTime,
        duration: 60000,
        quality: 'SQ',
        type: 'PLAY',
      },
    },
  };
  const playMsg = JSON.stringify(playObj, null, 2);
  const websocket = websockets?.[requirePlayData?.deviceId];
  if (websocket && websocket.readyState === 1) {
    Utils.vmsLogger().log(
      'Multi PlayBack play command sent ',
      requirePlayData?.deviceId,
      requirePlayData?.deviceName,
      Utils.getTimesinmili(),
    );
    websocket.send(playMsg);
  } else {
    const updatedObj = {
      deviceId: requirePlayData?.deviceId,
      isLoading: false,
    };
    Store.dispatch(setMultiLiveStreamLoader(updatedObj));
    noVideoDevices.push(requirePlayData?.deviceId);
  }
  const elementCreated = 'elementCreated';
  return elementCreated;
};

export const sendCloudContinueRequest = (deviceID) => {
  const deviceName = allDevices?.find(
    (device) => device?.deviceId === deviceID,
  )?.deviceName;
  sendCompleteDeviceIds[deviceID] = false;
  const contunueObj = {
    cid: cID,
    tid: Date.now().toString(),
    to: deviceID,
    from: orgID,
    token: jwtTokensDeviceIds?.[deviceID],
    msg: {
      action: 'set',
      resource: 'media/streaming',
      properties: {
        uuid: playbackUUID,
        org_id: orgID,
        dev_id: deviceID,
        duration: 60000,
        quality: 'SQ',
        type: 'CONTINUE',
      },
    },
  };
  const continueMsg = JSON.stringify(contunueObj, null, 2);
  const websocket = websockets?.[deviceID];
  if (websocket && websocket.readyState === 1) {
    Utils.vmsLogger().log(
      'PlayBack continue command sent ',
      deviceName,
      Utils.getTimesinmili(),
    );
    websocket.send(continueMsg);
  }
};

export const sendCloudStopRequest = (deviceID, isAutoPlayVal) => {
  isAutoPlay = isAutoPlayVal;
  const playbackPlayer = document.getElementById('playback-video');
  if (playbackPlayer) {
    playbackPlayer.isAutoPlay = isAutoPlay;
  }
  const stopObj = {
    cid: cID,
    tid: Date.now().toString(),
    to: deviceID,
    from: orgID,
    token: jwtTokensDeviceIds?.[deviceID],
    msg: {
      resource: 'media/streaming',
      properties: {
        uuid: playbackUUID,
        org_id: orgID,
        dev_id: deviceID,
        type: 'STOP',
      },
    },
  };
  const stopMsg = JSON.stringify(stopObj, null, 2);
  // Utils.vmsLogger().log('sendCloudStopRequest ~ stopMsg:', stopMsg);
  const websocket = websockets?.[deviceID];
  if (websocket && websocket.readyState === 1) {
    websocket.send(stopMsg);
  }
};

export const sendCloudDisConnectRequest = (deviceID) => {
  const disconnectObj = {
    cid: cID,
    tid: Date.now().toString(),
    to: deviceID,
    from: orgID,
    token: jwtTokensDeviceIds?.[deviceID],
    msg: {
      resource: 'media/streaming',
      properties: {
        uuid: playbackUUID,
        org_id: orgID,
        dev_id: deviceID,
        type: 'DISCONNECT',
      },
    },
  };

  const disconnectMsg = JSON.stringify(disconnectObj, null, 2);
  // Utils.vmsLogger().log('sendCloudDisConnectRequest ~ DISCONNECT:', disconnectMsg);
  const websocket = websockets?.[deviceID];
  if (websocket && websocket.readyState === 1) {
    websocket.send(disconnectMsg);
  }
  disconnectWithWebSocket(deviceID);
};

export const getCloudNextAvailTime = (deviceId) => {
  return nextAvailTime?.[deviceId] || false;
};

export const getNoVideoCloudDevices = (deviceId) => {
  let res = '';
  if (noVideoDevices.includes(deviceId)) {
    return deviceId;
  } else {
    return res;
  }
};

export const disconnectWithWebSocket = (deviceID) => {
  Store.dispatch(removeWSSConnections(deviceID));
  if (websockets?.[deviceID]) {
    websockets[deviceID].close();
    delete websockets[deviceID];
    delete jwtTokensDeviceIds[deviceID];
  }
};

export const cleanupResources = (deviceID) => {
  let videoElement = document.getElementById(`playback-video${deviceID}`);
  if (!videoElement) return;

  if (
    videoElement?.mediaSource?.readyState === 'open' &&
    !videoElement?.sourceBuffers?.['dc-media']?.updating
  ) {
    videoElement?.mediaSource?.endOfStream();
    videoElement?.mediaSource?.removeEventListener(
      'sourceopen',
      handleSourceOpen,
    );
  }
  videoElement?.removeEventListener('progress', handleVideoEvents);
  videoElement?.removeEventListener('canplay', handleVideoEvents);
  videoElement?.removeEventListener('error', handleVideoEvents);
  videoElement?.removeEventListener('waiting', handleVideoEvents);
  URL.revokeObjectURL(videoElement.src);
  videoElement.src = '';
  videoElement?.remove();
};

export const clearLocalTimer = () => {
  clearInterval(virtualTimeInterval);
};

export const getCloudSendComplete = (deviceId) => {
  return sendCompleteDeviceIds?.[deviceId];
};

export const setMultiCloudIsAutoPlay = (isAutoPlayVal) => {
  isAutoPlay = isAutoPlayVal;
};
