import React, { useCallback, useRef } from 'react';
import { useState } from 'react';
import { useTable } from 'react-table';
import { VscChevronDown, VscChevronUp } from 'react-icons/vsc';
import { Tooltip, tooltipClasses } from '@mui/material';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import CameraWallSnapshot from './CameraWallSnapshot';
import { MdOutlineDragIndicator } from 'react-icons/md';
import { RiLoader4Fill } from 'react-icons/ri';

import '../../assets/css/base.scss';
import { FixedSizeList } from 'react-window';
import { SiteSpinner } from '../../components/common';
import { constants } from '../../helpers';

const CameraWallTable = ({
  columns,
  data,
  viewDevices,
  viewId,
  newViewDragMode,
  defaultSortByData = [],
  viewDevicesDetails,
  cdnInfo,
  getDeviceStatus,
  editView,
  isDragging,
  hasMore,
  loadMore,
  onSortingChange,
  isDataLoading,
  showLoadingText,
}) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [draggingDeviceId, setDraggingDeviceId] = useState(null);
  const [isSorted, setIsSorted] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortField, setSortField] = useState(null);
  const listRef = useRef();
  // Use the useTable Hook to send the columns and data to build the table
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
      viewDevices,
      viewId,
      newViewDragMode,
      viewDevicesDetails,
      cdnInfo,
      getDeviceStatus,
      editView,
      isDragging,
      isSorted,
      disableSortRemove: true,
      autoResetSortBy: false,
    });

  const getCameraRowDisableStatus = (device) => {
    let isDisable = viewDevices?.includes(device?.deviceId)
      ? false
      : viewDevices?.length >= 12
        ? true
        : false;

    return isDisable;
  };

  const RenderRow = useCallback(
    ({ index, style, isScrolling }) => {
      if (isScrolling) {
        setShowLoader(true);
      } else {
        setShowLoader(false);
      }
      const row = rows?.[index];
      prepareRow(row);
      return (
        <>
          <Draggable
            draggableId={row?.original.deviceId}
            key={row?.original.deviceId}
            index={row?.index}
            isDragDisabled={!viewDevices?.includes(row?.original.deviceId)}
          >
            {(provided, snapshot) => {
              return (
                <div
                  style={style}
                  className={`${
                    viewDevices?.includes(row?.original?.deviceId) &&
                    !snapshot.isDragging
                      ? 'selected-row'
                      : selectedRow === index && !snapshot.isDragging
                        ? 'active-row non-Selected-row-hover'
                        : row?.original?.deviceId === draggingDeviceId &&
                            snapshot?.isDragging
                          ? 'dragging-row'
                          : 'non-Selected-row-hover'
                  } camera-device-item`}
                  // onClick={() => setSelectedRow(index)}
                >
                  <tr
                    {...row?.getRowProps()}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    key={`${index}-index`}
                    id={row?.original?.deviceId}
                    className={
                      snapshot?.isDragging
                        ? 'border-0'
                        : getCameraRowDisableStatus(row?.original)
                          ? 'cameraRowDisabled'
                          : ''
                    }
                  >
                    {row.cells.map((cell, cellIndex) => {
                      return (
                        !snapshot.isDragging && (
                          <>
                            {cell.column.id === 'deviceName' ? (
                              <td
                                className={`${cell.column.id} ${getCameraRowDisableStatus(row?.original) ? 'deviceNameDisabled' : ''}`}
                                {...cell.getCellProps()}
                              >
                                {cell.render('Cell')}
                              </td>
                            ) : cell.column.id !== 'cameraOptions' &&
                              cell.column.id !== 'displayDeviceStatus' &&
                              cell.column.id !== 'conStatus' &&
                              cell.column.id !== 'viewChecked' &&
                              cell.column.id !== 'snapshot' ? (
                              <Tooltip
                                placement="top-start"
                                arrow
                                title={cell.render('Cell')}
                                slotProps={{
                                  popper: {
                                    modifiers: [
                                      {
                                        name: 'offset',
                                        options: {
                                          offset: [0, -25],
                                        },
                                      },
                                    ],
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        fontSize: 12,
                                        background: `var(--brand_white)`,
                                        padding: '10px',
                                        color: `var(--brand_black)`,
                                        fontWeight: 600,
                                      },
                                      [`& .${tooltipClasses.arrow}`]: {
                                        color: `var(--brand_white)`,
                                      },
                                    },
                                  },
                                }}
                              >
                                <td
                                  className={cell.column.id}
                                  {...cell.getCellProps()}
                                >
                                  {cell.render('Cell')}
                                </td>
                              </Tooltip>
                            ) : (
                              <td
                                {...cell.getCellProps()}
                                className={`${cell.column.id}
                              ${
                                viewDevices?.includes(
                                  row?.original?.deviceId,
                                ) &&
                                (cell?.column?.id === 'cameraOptions' ||
                                  cellIndex === row?.cells?.length - 1)
                                  ? 'selected-cell-right'
                                  : ''
                              }
                              ${
                                viewDevices?.includes(
                                  row?.original?.deviceId,
                                ) &&
                                (cell?.column?.id === 'viewChecked' ||
                                  cellIndex === 0)
                                  ? 'selected-cell-left'
                                  : ''
                              }
                            `}
                              >
                                {cell.render('Cell')}
                              </td>
                            )}
                          </>
                        )
                      );
                    })}
                    {snapshot.isDragging ? (
                      <div className="drag-details">
                        {viewDevices?.length &&
                          viewDevices.map((deviceId, idIndex) => {
                            setDraggingDeviceId(row?.original?.deviceId);
                            const data = viewDevicesDetails[deviceId];
                            return idIndex < 6 ? (
                              <div
                                className={`drag-details-wrapper drag-opacity-${idIndex}`}
                              >
                                <div className="viewChecked">
                                  <MdOutlineDragIndicator
                                    className="drag-icon"
                                    color={getComputedStyle(
                                      document.documentElement,
                                    ).getPropertyValue('--brand_white')}
                                  />
                                </div>
                                <div className="snapshot">
                                  <span className="snapshot-wrapper">
                                    <CameraWallSnapshot
                                      key={data?.deviceId}
                                      deviceElemId={`camera${data?.deviceId}`}
                                      orgId={data?.orgId}
                                      cdnInfo={cdnInfo}
                                      locationAreaName={data?.locationAreaNames}
                                      isHighlighted={false}
                                      showZoomOptions={false}
                                      conStatus={
                                        data?.connectionStatus || 'offline'
                                      }
                                      hubId={data?.gatewayId}
                                      {...data}
                                    />
                                  </span>
                                </div>
                                <div className="deviceName drag-clmn-padding">
                                  {data?.deviceName}
                                </div>
                                <div className="locationName drag-clmn-padding">
                                  {data?.locationName}
                                </div>
                                <div className="areaName drag-clmn-padding">
                                  {data?.areaName}
                                </div>
                              </div>
                            ) : (
                              <></>
                            );
                          })}
                      </div>
                    ) : null}
                  </tr>
                </div>
              );
            }}
          </Draggable>
          {isDragging && draggingDeviceId === row?.original.deviceId && (
            <div
              style={style}
              className={
                viewDevices?.includes(row?.original.deviceId)
                  ? 'selected-row'
                  : selectedRow === index
                    ? 'active-row non-Selected-row-hover'
                    : 'non-Selected-row-hover'
              }
              // onClick={() => setSelectedRow(index)}
            >
              <tr {...row.getRowProps()} key={`${index}-index`}>
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <>
                      {cell.column.id === 'deviceName' ? (
                        <td className={cell.column.id} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      ) : (
                        <td
                          {...cell.getCellProps()}
                          className={`${cell.column.id}
                        ${
                          viewDevices?.includes(row?.original?.deviceId) &&
                          (cell?.column?.id === 'cameraOptions' ||
                            cellIndex === row?.cells?.length - 1)
                            ? 'selected-cell-right'
                            : ''
                        }
                        ${
                          viewDevices?.includes(row?.original?.deviceId) &&
                          (cell?.column?.id === 'viewChecked' ||
                            cellIndex === 0)
                            ? 'selected-cell-left'
                            : ''
                        }
                      `}
                        >
                          {cell.render('Cell')}
                        </td>
                      )}
                    </>
                  );
                })}
              </tr>
            </div>
          )}
        </>
      );
    },
    [
      prepareRow,
      rows,
      viewDevices,
      isDragging,
      JSON.stringify(viewDevicesDetails),
      draggingDeviceId,
    ],
  );
  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */

  const handleSort = (column) => {
    let sortType = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(sortType);
    setSortField(column.id);
    onSortingChange(column.id, sortType);
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table table-borderless" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr
                className="table-header-row"
                {...headerGroup.getHeaderGroupProps()}
                key={index}
                onClick={() => setIsSorted(!isSorted)}
              >
                {headerGroup.headers.map((column, index) => (
                  <th
                    className={`${column.id} ${
                      column.isSorted
                        ? column.isSortedDesc
                          ? 'columnheader-asc'
                          : 'columnheader-desc'
                        : ''
                    }`}
                    key={index}
                    onClick={() => handleSort(column)}
                  >
                    {column.render('Header')}
                    {column.id !== 'cameraOptions' &&
                    column.id !== 'viewChecked' &&
                    column.id !== 'snapshot' ? (
                      <span className="sort-icon">
                        {sortField === column.id ? (
                          sortOrder !== 'desc' ? (
                            <VscChevronDown size={16} strokeWidth="1px" />
                          ) : (
                            <VscChevronUp size={16} strokeWidth="1px" />
                          )
                        ) : (
                          <VscChevronDown size={16} strokeWidth="1px" />
                        )}
                      </span>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <Droppable droppableId="table-body" isDropDisabled={isDragging}>
            {(provided, snapshot) => (
              <tbody
                {...getTableBodyProps()}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <FixedSizeList
                  useIsScrolling
                  height={window.innerHeight - 300}
                  itemCount={rows.length}
                  itemData={rows}
                  itemSize={48}
                  className="react-window-list"
                  innerRef={listRef}
                >
                  {RenderRow}
                </FixedSizeList>
              </tbody>
            )}
          </Droppable>
        </table>
        {hasMore && (
          <div key={`loadMore`} className="load-more-row">
            <div className="load-more-col">
              {!showLoadingText ? (
                <span className="load-more-text" onClick={loadMore}>
                  {constants.LIST_TABLE_LOAD_MORE_BTN}
                </span>
              ) : (
                <span className="load-more-text">
                  <span className="loading-icon">
                    <RiLoader4Fill size={16} strokeWidth="1px" />
                  </span>
                  {constants.LOADING}
                </span>
              )}
            </div>
          </div>
        )}
      </div>
      {isDataLoading ? (
        <div className="show-data-loader">
          <SiteSpinner height="50px" width="50px"></SiteSpinner>
        </div>
      ) : null}
    </>
  );
};

export default CameraWallTable;
