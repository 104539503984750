/**
 *  Class of the UX Control mouse and touchscreen
 *  Example code for controlling the camera (not part of the SDK, not supported)
 *  Must overwrite this class for custom control, the SDK will load this file
 */
export class Control {
  // Static property to track which control instance is currently active
  static activeControl = null;

  /**
   * The default UX Control mouse and touchscreen for views
   * @param {domElement} domElement of container
   * @param {object} [options]
   * @param {function} [options.onRotate=null] rotation callback(tiltDelta, panDelta)
   * @param {function} [options.onZoom=null] zoom callback (zoomDelta)
   * @param {number} [option.deltaZoom=0.05] mouseWheel zoom incremental
   */
  constructor(domElement, options = {}) {
    this.domElement = domElement;

    this.onZoom = options.onZoom;
    this.onRotate = options.onRotate;
    this.deltaZoom = options.deltaZoom || 0.05;

    domElement.style = 'touch-action: none;';

    this.touchEvents = {};

    this.onPointerDown = (event) => {
      // Claim this control as the active control
      Control.activeControl = this;

      // Ignore the event if user is resizing
      if (event.target.closest('[data-is-resizing="true"]')) {
        return;
      }

      this.lastPointerX = event.pageX;
      this.lastPointerY = event.pageY;

      if (this.onZoom && event.pointerType === 'touch') {
        this.touchEvents[event.pointerId] = event;
        let touches = Object.values(this.touchEvents);
        if (touches.length === 2) {
          this.lastPinchDistance = Math.hypot(
            touches[0].pageX - touches[1].pageX,
            touches[0].pageY - touches[1].pageY,
          );
        }
      }

      this.domElement.ownerDocument.addEventListener(
        'pointermove',
        this.onPointerMove,
        false,
      );
      this.domElement.ownerDocument.addEventListener(
        'pointerup',
        this.onPointerUp,
        false,
      );
      this.domElement.ownerDocument.addEventListener(
        'pointercancel',
        this.onPointerUp,
        false,
      );
    };

    this.onPointerMove = (event) => {
      // Ignore the event if this is not the active control
      if (Control.activeControl !== this) return;

      // Ignore the event if user is resizing
      if (event.target.closest('[data-is-resizing="true"]')) {
        return;
      }

      event.preventDefault();
      if (event.pointerType === 'touch') {
        this.touchEvents[event.pointerId] = event;
      }
      let touches = Object.values(this.touchEvents);

      if (
        this.onRotate &&
        (touches.length === 1 || event.pointerType === 'mouse')
      ) {
        let rotX = (event.pageY - this.lastPointerY) / 200;
        let rotY = (event.pageX - this.lastPointerX) / 200;
        this.lastPointerX = event.pageX;
        this.lastPointerY = event.pageY;

        this.onRotate(rotX, rotY);
      } else if (this.onZoom && touches.length === 2 && event.isPrimary) {
        let distance = Math.hypot(
          touches[0].pageX - touches[1].pageX,
          touches[0].pageY - touches[1].pageY,
        );

        if (this.lastPinchDistance) {
          this.onZoom(distance / this.lastPinchDistance);
        }

        this.lastPinchDistance = distance;
      }
    };

    this.onPointerUp = (event) => {
      // Ignore the event if this is not the active control
      if (Control.activeControl !== this) return;

      this.domElement.ownerDocument.removeEventListener(
        'pointermove',
        this.onPointerMove,
        false,
      );
      this.domElement.ownerDocument.removeEventListener(
        'pointerup',
        this.onPointerUp,
        false,
      );
      this.domElement.ownerDocument.removeEventListener(
        'pointercancel',
        this.onPointerUp,
        false,
      );

      this.touchEvents = {}; // Reset touch events

      // Release the active control
      if (Object.keys(this.touchEvents).length === 0) {
        Control.activeControl = null;
      }
    };

    this.onMouseWheel = (event) => {
      event.preventDefault();
      event.stopPropagation();

      // Ignore the event if user is resizing
      if (event.target.closest('[data-is-resizing="true"]')) {
        return;
      }

      this.onZoom(event.deltaY < 0 ? 1 - this.deltaZoom : 1 + this.deltaZoom);
    };

    this.domElement.addEventListener('pointerdown', this.onPointerDown, false);

    if (this.onZoom) {
      this.domElement.addEventListener('wheel', this.onMouseWheel, {
        passive: false,
      });
    }
  }
}
