import mqtt from 'mqtt';
import store from '../../store/Store';
import {
  setMQTTConnection,
  getPlatformInfo,
  setPlatformInfo,
  getIsOnMultiCameraViewPage,
  setRemoteStream,
  setMaxPeerCountForDevice,
} from '../../store/reducers/StreamingReducer';
import {
  getAccountId,
  setAllMqttDataFromResponse,
  setKeepAliveRequest,
  setMqttUpdateStatusFromResponse,
  setSubscribeAreasRequest,
} from '../../store/reducers/AccountReducer';
import { devicesMQTTStore } from '../../store/DevicesMQTTStore';

import { v4 as uuidv4 } from 'uuid';
import {
  setLatestEventsForDots,
  setMetaDataForEvents,
} from '../../store/reducers/EventsReducer';

import { Utils } from '../../helpers';
import { debounce } from 'lodash';
import * as player from '../webRTC/ump-player-interleave';
import * as multiEdgePlaybackHandler from '../webRTC/multi-edge-playback-handler';
import { getPeerConnectionState } from '../webRTC/edge-playback-handler';

let client;
let deviceDataForPublish = {};
let candidateArrayByDeviceId = {};
let playbackCandidateArrayByDeviceId = {};
const { getState } = devicesMQTTStore;
const state = getState();
const messageQueue = [];
const processNextMessage = debounce(() => {
  if (messageQueue.length > 0) {
    const { topic, message } = messageQueue.shift();
    handleMessage(topic, message);
    processNextMessage();
  }
}, 300);

const handleMessage = async (topic, message) => {
  const payload = JSON.parse(message.toString());
  const data = payload?.msg?.properties;
  const error = payload?.msg?.action;
  const resource = payload?.msg?.resource;

  if (resource?.includes('/live') || resource?.includes('/camera/streaming')) {
    try {
      // const data = JSON.parse(byteMessage.toString()).msg
      //   .properties;
      // const messageData = JSON.parse(message.toString()).msg;
      // const data = messageData.properties;
      const msgDeviceId = resource
        .split('ch/')[1]
        .split('/live')[0]
        .split('/camera/streaming')[0];
      if (!data) return;
      const allData = deviceDataForPublish?.[msgDeviceId];
      const peerConnection = player.getPeerConnection(msgDeviceId);
      if (allData?.isLiveStream && peerConnection) {
        let candidateArray = candidateArrayByDeviceId?.[msgDeviceId] || [];

        if (data?.code === '1011') {
          const maxPeerObj = {
            id: msgDeviceId,
            isMaxPeer: true,
          };
          store.dispatch(setMaxPeerCountForDevice(maxPeerObj));
        }

        switch (data.type) {
          case 'answer':
            Utils.vmsLogger().log(
              'LiveStream: answer received. ',
              msgDeviceId,
              ' ',
              Utils.getTimesinmili(),
            );
            const answer = new RTCSessionDescription({
              type: data.type,
              sdp: data.sdp,
            });
            await peerConnection
              ?.setRemoteDescription(answer)
              .then(() =>
                Utils.vmsLogger().log(
                  'LiveStream: remote description set. ',
                  msgDeviceId,
                  ' ',
                  Utils.getTimesinmili(),
                ),
              )
              .catch(() => {});
            while (candidateArray.length > 0) {
              await peerConnection.addIceCandidate(
                new RTCIceCandidate(candidateArray.shift()),
              );
            }
            break;
          case 'candidate':
            Utils.vmsLogger().log(
              'LiveStream: candidate received. ',
              msgDeviceId,
              ' ',
              Utils.getTimesinmili(),
            );
            const fixedData = {
              ...data,
              ...(!data.sdpMLineIndex && { sdpMLineIndex: 0 }),
            };
            const candidate = new RTCIceCandidate(fixedData);
            if (peerConnection?.remoteDescription) {
              await peerConnection.addIceCandidate(candidate);
            } else {
              candidateArray.push(candidate);
              candidateArrayByDeviceId[msgDeviceId] = candidateArray;
            }
            break;
          default:
          // Utils.vmsLogger().log("MQTT message (not matched)", topic, data);
        }
      }
    } catch {
      // Utils.vmsLogger().log('MQTT message invalid');
    }
  } else if (resource?.includes('/playback')) {
    try {
      // const strData = byteMessage.toString();
      // const jsonData = JSON.parse(strData);
      // const data = jsonData.msg.properties;
      // const msgDeviceId = jsonData.msg.resource
      const isOnMultiCameraViewPage = store.getState(getIsOnMultiCameraViewPage)
        ?.streaming?.isOnMultiCameraViewPage;
      // if (!isOnMultiCameraViewPage) {
      const msgDeviceId = resource.split('ch/')[1].split('/playback')[0];
      if (!data) return;
      let candidateArray =
        playbackCandidateArrayByDeviceId?.[msgDeviceId] || [];
      const peerConnection = !isOnMultiCameraViewPage
        ? getPeerConnectionState()
        : multiEdgePlaybackHandler.getPeerConnectionState(msgDeviceId);

      if (data?.code === '1011') {
        const maxPeerObj = {
          id: msgDeviceId,
          isMaxPeer: true,
        };
        store.dispatch(setMaxPeerCountForDevice(maxPeerObj));
      }
      switch (data.type) {
        case 'answer':
          Utils.vmsLogger().log(
            'Playback answer received',
            msgDeviceId,
            Utils.getTimesinmili(),
          );
          const answer = new RTCSessionDescription({
            type: data.type,
            sdp: data.sdp,
          });
          await peerConnection
            ?.setRemoteDescription(answer)
            .then(() =>
              Utils.vmsLogger().log(
                'Playback remote description set ',
                msgDeviceId,
                Utils.getTimesinmili(),
              ),
            )
            .catch(() => {});
          while (candidateArray.length > 0) {
            await peerConnection?.[msgDeviceId].addIceCandidate(
              new RTCIceCandidate(candidateArray.shift()),
            );
          }
          break;
        case 'candidate':
          Utils.vmsLogger().log(
            'Playback candidate received ',
            msgDeviceId,
            Utils.getTimesinmili(),
          );
          const candidate = new RTCIceCandidate({
            ...data,
            ...(!data.sdpMLineIndex && { sdpMLineIndex: 0 }),
          });
          if (peerConnection.remoteDescription)
            peerConnection.addIceCandidate(candidate);
          else {
            candidateArray.push(candidate);
            playbackCandidateArrayByDeviceId[msgDeviceId] = candidateArray;
          }
          break;
        default:
        // Utils.vmsLogger().log(
        //   '==== mqtt message (not related to playback)',
        //   topic,
        //   data,
        // );
      }
      // }
    } catch (error) {
      // Utils.vmsLogger().log('==== mqtt message invalid: ', error);
    }
  } else if (payload?.msg?.resource?.includes('camera/last-snap-timestamp')) {
    const accountId = payload.to;
    const sessionId = state.getSessionId();
    const deviceId = payload?.msg?.resource.toString().split('/')[1];
    if (payload.msg.properties) {
      const { tLastSnapshot } = payload.msg.properties;

      if (!isNaN(tLastSnapshot)) {
        // Store latest snapshot from the device
        state.setSnapshotByDeviceId(
          accountId,
          sessionId,
          deviceId,
          tLastSnapshot,
        );
      }
      const { tLastEvent } = payload.msg.properties;

      if (!isNaN(tLastEvent)) {
        // Store latest event from the device
        state.setEventByDeviceId(accountId, sessionId, deviceId, tLastEvent);
      }
    }
  } else if (
    Array.isArray(payload?.eventMeta?.events) &&
    payload.eventMeta.events.length > 0
  ) {
    let targetEvents = [];

    // Search for events with the type ObjectClass
    payload.eventMeta.events.every((eventEl) => {
      if (Array.isArray(eventEl.event)) {
        targetEvents = eventEl.event.filter((el) => {
          return el.eventType.toUpperCase() === 'OBJECTCLASS';
        });
        return !(targetEvents.length > 0);
      }
      return true;
    });

    // If at least one such event was received, let's
    // save it to the local data store
    if (targetEvents.length > 0) {
      const accountId = state.getAccountId();
      const sessionId = state.getSessionId();
      const deviceId = payload.src?.srcId;

      if (!isNaN(payload.t)) {
        const tLastEvent = parseInt(payload.t / 1000);

        if (!isNaN(tLastEvent)) {
          // Store latest event from the device
          state.setEventByDeviceId(accountId, sessionId, deviceId, tLastEvent);
        }
      }
    }
  } else if (payload?.msg?.resource?.includes('streams/')) {
    const deviceId = payload?.msg?.resource.toString().split('/')[1];
    const liveMetaObject = {
      ...data,
      deviceId,
    };
    store.dispatch(setMetaDataForEvents(liveMetaObject));
  } else if (
    payload?.msg?.resource !== 'camera/events/proxy-events' &&
    payload?.msg?.resource?.includes('camera/events/')
  ) {
    if (error && error === 'error') {
      if (payload.msg.properties.desc === 'The subsciber does not exist') {
        store.dispatch(setSubscribeAreasRequest(false));
      } else if (
        payload.msg.properties.desc === 'The subsciber is already exist'
      ) {
        // store.dispatch(setKeepAliveRequest(false));
      } else {
        store.dispatch(setSubscribeAreasRequest(false));
        store.dispatch(setKeepAliveRequest(false));
      }
    } else if (payload.msg?.action === 'is') {
      store.dispatch(setLatestEventsForDots(data));
    }
  }
  if (
    resource?.includes('/camera/system/device-status') &&
    data.hasOwnProperty('online')
  ) {
    store.dispatch(setAllMqttDataFromResponse(payload));
  } else if (
    resource?.includes('device/') ||
    resource?.includes('devices/') ||
    resource?.includes('hub/channels/auth-creds') ||
    resource?.includes('hub/channels') ||
    resource?.includes('diag/uploadLogs') ||
    resource?.includes('camera/') ||
    resource?.includes('backup/') ||
    resource?.includes('hub/') ||
    resource?.includes('pos/') ||
    resource?.includes('camera/events/proxy-events') ||
    resource?.includes('local-storage/rec-available')
  ) {
    if (payload?.msg?.properties?.restart) {
      setTimeout(async () => {
        store.dispatch(setAllMqttDataFromResponse(payload));
        store.dispatch(setMqttUpdateStatusFromResponse(true));
      }, 1000);
    } else {
      store.dispatch(setAllMqttDataFromResponse(payload));
      store.dispatch(setMqttUpdateStatusFromResponse(true));
    }
  }
};

const handleConnect = () => {
  store.dispatch(setMQTTConnection(true));
  Utils.vmsLogger().log('mqtt connected', client);
};

const handleError = (error, platformDetails) => {
  Utils.vmsLogger().log('mqtt error: ' + error);
  client.end(true, {}, () => {
    Utils.vmsLogger().log('mqtt client disconnected');
    const platformData = JSON.parse(JSON.stringify(platformDetails));
    const isMqttTokenNotExpired =
      platformDetails?.mqtt?.expiry * 1000 > new Date().getTime();
    if (isMqttTokenNotExpired) {
      const currAccountId = store.getState(getAccountId)?.accounts?.accountId;
      currAccountId && connectWithMQTT(currAccountId);
    } else {
      platformData.mqtt.token = null;
      store.dispatch(setPlatformInfo(platformData));
    }
  });
};

const handleClose = () => {
  store.dispatch(setMQTTConnection(false));
  Utils.vmsLogger().log('mqtt closed');
};

const handleOffline = () => {
  Utils.vmsLogger().log('offline');
};

const handleReconnect = (platformDetails) => {
  Utils.vmsLogger().log('mqtt reconnecting', client);
  client.end(true, {}, () => {
    Utils.vmsLogger().log('mqtt client closing');
    const platformData = JSON.parse(JSON.stringify(platformDetails));
    const isMqttTokenNotExpired =
      platformDetails?.mqtt?.expiry * 1000 > new Date().getTime();
    if (isMqttTokenNotExpired) {
      const currAccountId = store.getState(getAccountId)?.accounts?.accountId;
      currAccountId && connectWithMQTT(currAccountId);
    } else {
      platformData.mqtt.token = null;
      store.dispatch(setPlatformInfo(platformData));
    }
  });
};

const handleAllMessage = (topic, message) => {
  const payload = JSON.parse(message.toString());
  const properties = payload?.msg?.properties;
  // Ashish Kumar Kosti - 12/07/2024 - This is under observation.
  // Only messages having the ConnectionStatus and DeviceStatus property will go in messageQueue and execute one by one with 300ms delay.
  if (properties?.connectionStatus || properties?.deviceStatus) {
    messageQueue.push({ topic, message });
    processNextMessage();
  } else {
    handleMessage(topic, message);
  }
};

const cleanupMQTTConnection = (platformDetails) => {
  if (client) {
    client.off('connect', handleConnect);
    client.off('error', (error) => handleError(error, platformDetails));
    client.off('close', handleClose);
    client.off('offline', handleOffline);
    client.off('reconnect', () => handleReconnect(platformDetails));
    client.off('message', handleAllMessage);
    client.end();
    client = null;
  }
};

const registerEventListeners = (platformDetails) => {
  client.on('connect', handleConnect);
  client.on('error', (error) => handleError(error, platformDetails));
  client.on('close', handleClose);
  client.on('offline', handleOffline);
  client.on('reconnect', () => handleReconnect(platformDetails));
  client.on('message', handleAllMessage);
};

export const connectWithMQTT = (accountId) => {
  const platformDetails = store.getState(getPlatformInfo)?.streaming?.platform;

  if (platformDetails) {
    if (client) {
      cleanupMQTTConnection(platformDetails);
    }

    const sessionid = uuidv4();
    state.setSessionId(sessionid);

    Utils.vmsLogger().log('<<----------Fresh connection with mqtt ------>>>');
    Utils.vmsLogger().log('sessionid', sessionid);
    Utils.vmsLogger().log('accountId', accountId);
    Utils.vmsLogger().log('password', platformDetails?.mqtt?.token);

    client = mqtt.connect(
      `wss://${platformDetails?.mqtt?.wsHost}:${platformDetails?.mqtt?.wsPort}/mqtt`,
      {
        clientId: `u#web#${accountId}#${sessionid}`,
        username: accountId,
        password: platformDetails?.mqtt?.token,
        protocolVersion: 5,
      },
    );

    registerEventListeners(platformDetails);
  }
};

export const subscribeWithMQTT = (platformDetails, id, uuid) => {
  if (client) {
    const updatedTopic =
      (platformDetails?.topic_details?.subscribe?.signaling).replace(
        '${accountId}',
        id,
      );
    const subscriptionTopic = updatedTopic
      .replace('${session}', uuid)
      .split('/#');
    mqttSubscribe({
      topic: subscriptionTopic[0],
      qos: 0,
    });
  }
};

export const subscribeWithOrgIds = (orgList) => {
  if (client && Array.isArray(orgList)) {
    orgList.forEach((org) => {
      const topic = `b/notify/${org?.orgId}`;
      if (!Object.keys(client._resubscribeTopics).includes(topic)) {
        client.subscribe(topic, function (err) {
          if (err) Utils.vmsLogger().error(err);
        });
      }
    });
  }
};

export const subscribeWithAccountId = (id) => {
  if (client) {
    client.subscribe(`b/notify/${id}`, function (err) {
      if (err) Utils.vmsLogger().error(err);
    });
  }
};

export const mqttSubscribe = (subscription) => {
  if (!subscription) {
    Utils.vmsLogger().error('Subscribe: subscription context required');
    return;
  }

  if (client) {
    const { topic, qos } = subscription;

    if (!topic) {
      Utils.vmsLogger().error('Subscribe: topic required');
      return;
    }

    if (!Object.keys(client._resubscribeTopics).includes(topic)) {
      client.subscribe(topic, { qos }, (error) => {
        if (error) {
          Utils.vmsLogger().error('Subscribe to topic error: ', error);
          return;
        }
      });
    }
  }
};

export const mqttUnsubscribe = (subscription) => {
  if (!subscription) {
    Utils.vmsLogger().error('Unsubscribe: subscription context required');
    return;
  }

  if (client) {
    const { topic } = subscription;

    if (!topic) {
      Utils.vmsLogger().error('Unsubscribe: topic required');
      return;
    }

    if (Object.keys(client._resubscribeTopics).includes(topic)) {
      Utils.vmsLogger().log(
        'TOPICSSUB mqttUnsubscribe topic main mqttconnection js',
        subscription,
      );
      client.unsubscribe(topic, (error) => {
        if (error) {
          Utils.vmsLogger().error('Unsubscribe from topic error', error);
          return;
        }
      });
    }
  }
};

export const mqttDisconnectRequest = () => {
  if (client) {
    client.end(() => {
      Utils.vmsLogger().log('mqtt Disconnected');
    });
  }
};

/**
 * Universal publish action
 * @param {Object} context - contains the topic, payload, and qos, if any
 */
export const mqttPublish = (context) => {
  if (!context) {
    Utils.vmsLogger().error('Publish: context required');
    return;
  }

  if (client) {
    const { topic, qos, payload } = context;

    if (!topic || !payload) {
      Utils.vmsLogger().error('Publish: topic and payload required');
      return;
    }

    if (Array.isArray(topic)) {
      topic.forEach((newTopic) => {
        client.publish(newTopic, payload, { qos }, (error) => {
          if (error) {
            Utils.vmsLogger().error('Publish error: ', error);
          }
        });
      });
    } else {
      client.publish(topic, payload, { qos }, (error) => {
        if (error) {
          Utils.vmsLogger().error('Publish error: ', error);
        }
      });
    }
  }
};

export const getMqttClient = (isLive, deviceId) => {
  if (deviceDataForPublish?.[deviceId]) {
    deviceDataForPublish[deviceId].isLiveStream = isLive;
    if (client) return deviceDataForPublish[deviceId];
  }
};

export const setMqttClient = () => {
  if (client) return (deviceDataForPublish = null);
};

export const publishWithMQTT = (
  platformDetails,
  serverDetails,
  deviceDetails,
  accountId,
  { isEdgeRecording, fishEyeInfo, dewarpAngle },
) => {
  deviceDataForPublish[deviceDetails.deviceId] = {
    deviceId: deviceDetails.deviceId,
    gatewayId: deviceDetails.gatewayId,
    accountId: accountId,
    orgId: deviceDetails.orgId,
    quality: 'SQ',
    type: 'LIVE',
    mqttclient: client,
    sid: state.getSessionId(),
    isLegacy: isEdgeRecording,
    fishEyeInfo,
    dewarpAngle: dewarpAngle || deviceDetails?.properties?.['mount-angle'],
    streamId: new Date().getTime(),
    serverDetails,
    deviceName: deviceDetails.deviceName,
  };
  player.clearPeerConnection(deviceDetails.deviceId);
  if (client) {
    if (!state.getSessionId()) {
      state.setSessionId(uuidv4());
    }
    subscribeWithMQTT(platformDetails, accountId, state.getSessionId());
  }
};

export const publishWithMQTTs = (
  platformDetails,
  serverDetails,
  deviceDetails,
  accountId,
  isEdgeRecording,
) => {
  deviceDataForPublish[deviceDetails.deviceId] = {
    deviceId: deviceDetails.deviceId,
    gatewayId: deviceDetails.gatewayId,
    accountId: accountId,
    orgId: deviceDetails.orgId,
    quality: 'SQ',
    type: 'LIVE',
    mqttclient: client,
    sid: state.getSessionId(),
    isLegacy: isEdgeRecording,
    streamId: new Date().getTime(),
    serverDetails,
    deviceName: deviceDetails.deviceName,
  };
  player.clearPeerConnection(deviceDetails.deviceId);
  if (client) {
    if (!state.getSessionId()) {
      state.setSessionId(uuidv4());
    }
    subscribeWithMQTT(platformDetails, accountId, state.getSessionId());
  }
};

export const multiStreamSnapDownload = async (
  time,
  typeCanvas,
  typeVideo,
  deviceId,
) => {
  try {
    let canvas = document.getElementById(typeCanvas);
    let video = document.getElementById(typeVideo);
    const context = canvas?.getContext('2d');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    await context?.drawImage(video, 0, 0, canvas.width, canvas.height);
    const url = canvas?.toDataURL('image/png');
    const link = document.createElement('a');
    link.download = `${time}.png`;
    link.href = url;
    link.click();
    return deviceId;
  } catch (err) {}
};

export const disconnectWithMQTT = async () => {
  Utils.vmsLogger().log(
    'Livestream disconnectWithMQTT call',
    Utils.getTimesinmili(),
  );
  store.dispatch(setRemoteStream(null));
  player.clearPeerConnection();
};

// emitting events to server related with direct call
export const sendWebRTCOffer = (requestType, message, deviceName) => {
  Utils.vmsLogger().log(
    'LiveStream / Playback: offer sent. ',
    deviceName,
    Utils.getTimesinmili(),
  );
  client.publish(requestType, JSON.stringify(message), {
    qos: 0,
    retain: false,
  });
};

export const sendWebRTCAnswer = (data) => {
  client.emit('answer', data);
};

export const sendWebRTCCandidate = (requestType, message) => {
  client.publish(requestType, JSON.stringify(message), {
    qos: 0,
    retain: false,
  });
};

export const checkMQTTConnection = () => {
  if (client) return !client.disconnected;
};

export const getCurrentEventResource = (mqttresource) => {
  const resourceList = [
    'camera/system/device-info',
    'camera/image/rotate',
    'camera/image/wdr',
    'camera/media/video-profile',
    'camera/media/wisestream',
    'camera/image/ir-mode',
    'camera/image/image-enhancement',
    'camera/image/focus',
    'camera/media/audio-input',
    'camera/network/ip-support',
    'camera/system/date',
    'camera/event/motion-detection',
    'camera/event/tamper-detection',
    'camera/event/defocus-detection',
    'camera/event/virtual-line',
    'camera/event/virtual-area',
    'camera/event/audio-detection',
    'camera/event/sound-classification',
    'camera/event/people-count',
    'camera/event/queue-mgt',
    'camera/media/audio-output',
    'camera/event/object-detection',
    'camera/event/shock-detection',
    'camera/default-settings',
    'camera/diag/duclo-fw-update',
    'camera/diag/device-fw-update',
    'camera/system/device-status',
    'camera/settings/max-br',
    'diag/uploadLogs',
    'backup/local-storage/status',
    'camera/event/sound-class',
    'camera/event/fog-detection',
    'camera/event/iva-enable',
    'camera/event/include-area',
    'camera/event/exclude-area',
    'camera/event/obj-detection',
    'camera/settings/cvr',
    'backup/local-storage/config',
    'backup/local-storage/format',
    'backup/cbs',
    'backup/ucs',
    'backup/force-backup',
    'backup/local-storage',
    'local-storage/rec-available',
    'camera/image/backlight-control',
  ];
  if (mqttresource != null && mqttresource != undefined) {
    return resourceList?.find((resource) => resource === mqttresource);
  }
};
