const SiteSpinner = (props) => {
  const {
    backgroundColor = getComputedStyle(
      document.documentElement,
    ).getPropertyValue('--brand_primary'),
    styles,
  } = props;
  return (
    <div
      className="site-spinner"
      style={{
        backgroundColor: props.backgroundColor
          ? props.backgroundColor
          : backgroundColor,
        width: props.width,
        height: props.height,
        padding: props.padding,
        border: props.border,
        ...styles,
      }}
    ></div>
  );
};

SiteSpinner.defaultProps = {
  width: '40px',
  height: '40px',
};

export default SiteSpinner;
