import { constants, Utils } from '../../helpers';
import * as mqttClient from '../connection/mqttConnection';
import { v4 as uuidv4 } from 'uuid';
import * as MP4Box from 'mp4box';
import Store from '../../store/Store';
import {
  setElementUpdated,
  setPlaybackBufferMeta,
  setStreamLoader,
  setMaxPeerCountForDevice,
  setCodecNotSupported,
} from '../../store/reducers/StreamingReducer';
import moment from 'moment';

// ====================
let videoElement = null;
let videoElementListener = null;
const nextVideoElements = [];
let videoContainer = null;
let dataChannel = null;
let allConnectionData = null;
let peerConnection = null;
let currentPlayStartTime = '';
let audioMuteUnmute = false;
let mp4BoxFile = null;
const ctsArray = [];
let virtualTimeInterval = null;
let virtualCurrentTime = null;
let nextAvailTime;
let noVideoFromTimestamp;
let noVideo = null;
let timelineRef;
let sendComplete = false;
let quality = '';
let isAutoPlay = false;
let requestedTime = null;
let isNoVideo = false;
let exactTimeToPlay = null;

const generatePayload = (type, offerCandidate, data, subTopic) => {
  if (type === 'offer') {
    return {
      tid: new Date().getTime().toString(),
      to: data?.gatewayId,
      from: data?.accountId,
      msg: {
        resource: `ch/${data?.deviceId}/playback`,
        properties: {
          id: `${data?.streamId}`,
          type: constants.WEBSOCKET_PLAYBACK_EVENT_OFFER,
          sdp: offerCandidate,
        },
      },
      publish: subTopic,
    };
  }
  if (type === 'candidate') {
    return {
      tid: new Date().getTime().toString(),
      to: data?.gatewayId,
      from: data?.accountId,
      msg: {
        resource: `ch/${data?.deviceId}/playback`,
        properties: {
          id: `${data?.streamId}`,
          type: constants.WEBSOCKET_PLAYBACK_EVENT_ICE,
          ...JSON.parse(JSON.stringify(offerCandidate)),
        },
      },
      publish: subTopic,
    };
  }
};

const startConnection = async (deviceID) => {
  allConnectionData = mqttClient?.getMqttClient(false, deviceID);

  if (allConnectionData) {
    const { stun, turn } = allConnectionData?.serverDetails;
    const turnConfig = {
      iceServers: [
        {
          urls: `${stun.protocol}:${stun.host}:${stun.port}`,
        },
        {
          urls: `${turn.protocol}:${turn.host}:${turn.port}`,
          username: `${turn.userName}`,
          credential: `${turn.password}`,
        },
      ],
      // iceTransportPolicy: "all",
      // rtcpMuxPolicy: "require",
      // bundlePolicy: "balanced",
    };
    Utils.vmsLogger().log('startConnection ~ turnConfig:', turnConfig);
    peerConnection = new RTCPeerConnection(turnConfig);

    peerConnection.createDataChannel('test_datachannel', {
      protocol: 'protocol',
    });
    const clientMQTT = allConnectionData?.mqttclient;
    const pubTopic = `a/notify/${allConnectionData?.gatewayId}`;
    const subTopic = `d/notify/${allConnectionData?.accountId}/${allConnectionData?.sid}`;
    const candidateArray = [];

    const checkVideoStartTime = () => {
      if (exactTimeToPlay < videoElement.buffered.end(0)) {
        videoElement.currentTime = exactTimeToPlay;
      }
    };

    clientMQTT.subscribe(subTopic);

    // send offer
    const offer = await peerConnection.createOffer();
    await peerConnection.setLocalDescription(offer);
    mqttClient.sendWebRTCOffer(
      pubTopic,
      generatePayload('offer', offer.sdp, allConnectionData, subTopic),
    );

    // send local candidate
    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        mqttClient.sendWebRTCCandidate(
          pubTopic,
          generatePayload(
            'candidate',
            event.candidate,
            allConnectionData,
            subTopic,
          ),
        );
      }
    };

    // peer connection state
    peerConnection.onconnectionstatechange = () => {
      Utils.vmsLogger().log(
        'PlayBack peer connection',
        peerConnection?.connectionState,
        ' ',
        Utils.getTimesinmili(),
      );
    };

    peerConnection.ondatachannel = (event) => {
      dataChannel = event.channel;
      const type = dataChannel.label;
      function appendBufferToSourceBuffer(targetVideoElement) {
        if (targetVideoElement) {
          const { sourceBuffer, segmentQueue } = targetVideoElement;

          if (!sourceBuffer?.updating && segmentQueue?.length > 0) {
            sourceBuffer?.appendBuffer(segmentQueue.shift());
          }
        }
      }
      dataChannel.onopen = () => {
        Utils.vmsLogger().log(
          'PlayBack data channel open',
          Utils.getTimesinmili(),
        );
        sendPlayCommand(currentPlayStartTime, quality, exactTimeToPlay);
      };

      dataChannel.onclose = () => {
        Utils.vmsLogger().log('=== data channel closed');
        cleanupResources();
      };

      dataChannel.onerror = (error) => {
        Utils.vmsLogger().log('==== data channel error: ', error);
      };

      dataChannel.onmessage = async (event) => {
        try {
          if (typeof event.data !== 'string') {
            const buffer = new Uint8Array(event.data);
            if (checkInitSegment(buffer)) {
              const { segmentQueue, mediaSource } = videoElement;

              mp4BoxFile = await loadMp4BoxFile(buffer);
              const mimeCodec = mp4BoxFile.getInfo().mime;
              if (!MediaSource.isTypeSupported(mimeCodec)) {
                const codecObj = {
                  id: deviceID,
                  isCodecNotSupport: true,
                };
                Store.dispatch(setCodecNotSupported(codecObj));
                Utils.vmsLogger().log(
                  'Mime codec not supported: codec=',
                  mimeCodec,
                );
                return;
              }
              if (mediaSource.sourceBuffers.length) {
                // multiple resolution detected, but don't add new SourceBuffer
                segmentQueue.push(buffer);
                return;
              }
              const sourceBuffer = mediaSource.addSourceBuffer(mimeCodec);
              videoElement.sourceBuffer = sourceBuffer;
              sourceBuffer.addEventListener('updateend', (event) => {
                // Utils.vmsLogger().log('=== source buffer update end');
                appendBufferToSourceBuffer(videoElement);
                const targetSourceBuffer = event.target;
                if (
                  videoElement.currentTime === 0 &&
                  targetSourceBuffer.buffered.length > 0
                ) {
                  checkVideoStartTime();
                }
              });
              sourceBuffer.addEventListener('error', (error) => {
                // Utils.vmsLogger().log(
                //   '=== source buffer error: ',
                //   error,
                //   videoElement?.error,
                // );
              });
              segmentQueue.push(buffer);
            } else {
              const { sourceBuffer, segmentQueue } = videoElement;

              if (!sourceBuffer) {
                // Utils.vmsLogger().log(
                //   '=== Source buffer not initialized, data received before the init segment is discarded.',
                // );
                return;
              }
              segmentQueue.push(buffer);
              appendBufferToSourceBuffer(videoElement);
            }
          } else {
            const receivedData = JSON.parse(event.data);
            const type = receivedData?.msg?.properties?.type;
            if (type === 'NO_VIDEO') {
              isNoVideo = true;
              Store.dispatch(setStreamLoader(false));
              noVideoFromTimestamp = receivedData?.msg?.properties?.from;
              // check whether video is in playing state or not
              const isVideoPlaying = !!(
                videoElement?.currentTime > 0 &&
                !videoElement?.paused &&
                !videoElement?.ended &&
                videoElement?.readyState > 2
              );
              if (
                // !receivedData?.msg?.properties?.next_avail_time &&
                !isVideoPlaying &&
                isAutoPlay
              ) {
                startClientSideTimer();
              }
              if (receivedData?.msg?.properties?.next_avail_time) {
                nextAvailTime = receivedData?.msg?.properties?.next_avail_time;
              }
            }

            if (type === 'SEND_COMPLETE') {
              sendComplete = true;
            }
            Utils.vmsLogger().log(
              'Received data:',
              JSON.stringify(receivedData, null, 2),
            );
          }
        } catch (error) {
          // Utils.vmsLogger().log('=== media source error: ', error, videoElement?.error);
        }
      };
    };
  }
};

const checkInitSegment = (buffer) => {
  const ftypBox = String.fromCharCode.apply(null, buffer.subarray(4, 8));
  return ftypBox === 'ftyp';
};

const loadMp4BoxFile = (u8Buffer) => {
  return new Promise((resolve, reject) => {
    const mp4BoxFile = MP4Box.createFile(false);
    mp4BoxFile.onReady = (info) => {
      resolve(mp4BoxFile);
    };
    mp4BoxFile.onError = reject;
    u8Buffer.buffer.fileStart = 0;
    mp4BoxFile.appendBuffer(u8Buffer.buffer);
    mp4BoxFile.flush();
  });
};

const createVideoElement = () => {
  const videoElement = document.createElement('video');

  videoElement.controls = false;
  videoElement.muted = audioMuteUnmute;
  videoElement.style.width = '100%';
  videoElement.id = 'playback-video';

  const mediaSource = new MediaSource();
  videoElement.mediaSource = mediaSource;
  videoElement.src = URL.createObjectURL(mediaSource);
  videoElement.sourceBuffer = null;
  videoElement.segmentQueue = [];
  mediaSource.addEventListener('sourceopen', () => {
    mediaSource.duration = 0;
  });
  // videoElement.addEventListener('ended', addNewVideoElement);
  videoElement.addEventListener('error', handleVideoEvents);
  videoElement.addEventListener('progress', handleVideoEvents);
  videoElement.addEventListener('canplay', handleVideoEvents);
  videoElement.addEventListener('waiting', handleVideoEvents);
  return videoElement;
};

const handleVideoEvents = (event) => {
  const isLoaderDisplay = Store.getState()?.streaming?.streamLoader;
  switch (event.type) {
    case 'canplay':
      Utils.vmsLogger().log(
        'PlayBack first frame received',
        Utils.getTimesinmili(),
      );
      if (noVideo) {
        noVideo.style.display = 'none';
      }
      if (isLoaderDisplay) {
        Store.dispatch(setStreamLoader(false));
      }
      break;

    case 'waiting':
      const bufferEnd =
        videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(0);
      const videoCurrentTime = parseInt(videoElement?.currentTime, 10) + 1;
      if (sendComplete && videoCurrentTime >= parseInt(bufferEnd, 10)) {
        handleNoVideoCases(event);
      } else {
        if (!isLoaderDisplay) {
          // Store.dispatch(setStreamLoader(true));
        }
      }
      break;

    case 'playing':
      noVideo.style.display = 'none';
      if (isLoaderDisplay) {
        Store.dispatch(setStreamLoader(false));
      }
      break;

    case 'progress':
      assignBufferedMeta();
      break;

    case 'error':
      handleVideoError(event);
      break;

    default:
      break;
  }
};

const handleNoVideoCases = (event) => {
  // handling this function when video is playing and gap occurred.
  try {
    const bufferEnd =
      videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(0);
    noVideo.style.display = 'flex';
    virtualCurrentTime = parseInt(bufferEnd, 10);
    startClientSideTimer();
  } catch (err) {}
};

const startClientSideTimer = () => {
  const isLoaderDisplay = Store.getState()?.streaming?.streamLoader;
  noVideo.style.display = 'flex';
  if (isLoaderDisplay) {
    Store.dispatch(setStreamLoader(false));
  }
  const bufferEnd =
    videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(0);
  virtualCurrentTime =
    parseInt(bufferEnd, 10) || virtualCurrentTime || currentPlayStartTime;

  videoElement.pause();
  videoElement.removeEventListener('progress', handleVideoEvents);
  videoElement.removeEventListener('canplay', handleVideoEvents);
  URL.revokeObjectURL(videoElement.src);
  videoElement.remove();

  if (nextAvailTime && virtualCurrentTime <= nextAvailTime / 1000) {
    virtualCurrentTime =
      parseInt(bufferEnd, 10) || virtualCurrentTime || currentPlayStartTime;

    virtualTimeInterval = setInterval(() => {
      virtualCurrentTime += 1000 / 1000;
      if (virtualCurrentTime < nextAvailTime / 1000) {
        // Utils.vmsLogger().log('next avail time with timer');
        timelineRef?.current?.$el?.moveTo(
          moment(Utils.getDate(virtualCurrentTime)),
        );
      } else {
        // Utils.vmsLogger().log('reached to next avail time and play');
        clearLocalTimer();
        videoContainer = document.getElementById('remote-view-wrapper');
        if (videoContainer) {
          videoElement = createVideoElement();
          nextVideoElements.push(createVideoElement());
          videoElement.autoplay = isAutoPlay;
          videoElement.preload = true;
          const existingVideoTag = document.getElementById('playback-video');
          if (existingVideoTag) {
            existingVideoTag.remove();
          }
          videoContainer.appendChild(videoElement);
          if (videoElementListener) {
            videoElementListener(videoElement);
          }
          Store.dispatch(setStreamLoader(true));
          sendPlayCommand(
            Utils.getDate(parseInt(nextAvailTime / 1000, 10)),
            quality,
            Utils.getDate(parseInt(nextAvailTime / 1000, 10)),
          );
          Store.dispatch(setElementUpdated(true));
        }
        noVideo.style.display = 'none';
      }
    }, 1000);
  } else {
    virtualCurrentTime = parseInt(bufferEnd, 10) || currentPlayStartTime / 1000;
    if (
      (!nextAvailTime && virtualCurrentTime) ||
      parseInt(nextAvailTime / 1000, 10) < virtualCurrentTime
    ) {
      let nextPlayCheckTime = virtualCurrentTime + 20;
      if (videoElement) {
        videoElement.pause();
        sendStopCommand(isAutoPlay);
        // cleanupResources();
      }
      virtualTimeInterval = setInterval(() => {
        virtualCurrentTime += 1000 / 1000;
        if (
          !nextAvailTime ||
          parseInt(nextAvailTime / 1000, 10) > virtualCurrentTime
        ) {
          // Utils.vmsLogger().log('start client side timer is runnning');
          timelineRef?.current?.$el?.moveTo(
            moment(Utils.getDate(virtualCurrentTime)),
          );
        } else {
          // Utils.vmsLogger().log('stop the local timer and send play reuest');
          clearLocalTimer();
          videoElement = createVideoElement();
          nextVideoElements.push(createVideoElement());
          videoElement.autoplay = isAutoPlay;
          videoElement.preload = true;
          const existingVideoTag = document.getElementById('playback-video');
          if (existingVideoTag) {
            existingVideoTag.remove();
          }
          videoContainer = document.getElementById('remote-view-wrapper');
          videoContainer.appendChild(videoElement);
          if (videoElementListener) {
            videoElementListener(videoElement);
          }
          Store.dispatch(setStreamLoader(true));
          sendPlayCommand(
            Utils.getDate(parseInt(nextAvailTime / 1000, 10)),
            quality,
            Utils.getDate(parseInt(nextAvailTime / 1000, 10)),
          );
          Store.dispatch(setElementUpdated(true));
          noVideo.style.display = 'none';
        }
        if (nextPlayCheckTime === virtualCurrentTime) {
          // Utils.vmsLogger().log('send play request for next available time');
          clearLocalTimer();
          videoElement = createVideoElement();
          nextVideoElements.push(createVideoElement());
          videoElement.autoplay = isAutoPlay;
          videoElement.preload = true;
          const existingVideoTag = document.getElementById('playback-video');
          if (existingVideoTag) {
            existingVideoTag.remove();
          }
          videoContainer = document.getElementById('remote-view-wrapper');
          videoContainer.appendChild(videoElement);
          if (videoElementListener) {
            videoElementListener(videoElement);
          }
          Store.dispatch(setStreamLoader(true));
          sendPlayCommand(
            Utils.getDate(parseInt(nextPlayCheckTime)),
            quality,
            Utils.getDate(parseInt(nextPlayCheckTime)),
          );
          Store.dispatch(setElementUpdated(true));
        }
      }, 1000);
    }
  }
};

const assignBufferedMeta = () => {
  if (
    videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered.length > 0
  ) {
    if (!isAutoPlay) {
      videoElement.pause();
    }
    const bufferStart =
      videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.start(0);
    const bufferEnd =
      videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(0);
    if (bufferStart && bufferEnd) {
      const obj = {
        id: allConnectionData?.deviceId,
        client: {
          start: parseInt(bufferStart, 10) * 1000,
          end: parseInt(bufferEnd, 10) * 1000,
          className: 'new-meta-buffer-class',
        },
      };
      Store.dispatch(setPlaybackBufferMeta(obj));
    }
  }
};

const handleVideoError = (event) => {
  Utils.vmsLogger().log('video error', videoElement?.error);
};

export const startPlaybackProcess = (requirePlayData) => {
  quality = requirePlayData?.qualityData;
  currentPlayStartTime = requirePlayData?.playTime;
  exactTimeToPlay = requirePlayData?.playbackToPlayTime;
  audioMuteUnmute = requirePlayData?.audioValue;
  videoContainer = document.getElementById('remote-view-wrapper');
  if (videoContainer) {
    videoElement = createVideoElement();
    if (videoElementListener) {
      videoElementListener(videoElement);
    }
    nextVideoElements.push(createVideoElement());
    videoElement.autoplay = isAutoPlay;
    videoElement.preload = true;
    const existingVideoTag = document.getElementById('playback-video');
    if (existingVideoTag) {
      existingVideoTag.remove();
    }
    videoContainer.appendChild(videoElement);
    if (videoElementListener) {
      videoElementListener(videoElement);
    }
    if (!peerConnection || peerConnection?.connectionState !== 'connected') {
      startConnection(requirePlayData?.deviceID);
    }
    noVideo = document.getElementById('noVideo');
    timelineRef = requirePlayData?.timeline;
    const elementCreated = 'elementCreated';
    return elementCreated;
  }
};

export const sendPlayCommand = (timestamp, qualityData, playbackPlayTime) => {
  isNoVideo = false;
  Store.dispatch(setPlaybackBufferMeta(null));
  quality = qualityData;
  sendComplete = false;
  requestedTime = Utils.getUnixDate(timestamp);
  exactTimeToPlay = nextAvailTime
    ? nextAvailTime / 1000
    : Utils.getUnixDate(playbackPlayTime);
  const actualTime = nextAvailTime ? nextAvailTime : requestedTime * 1000;
  currentPlayStartTime = actualTime;
  nextAvailTime = null;
  const uniqueId = uuidv4();
  if (dataChannel?.readyState === 'open') {
    const playPayload = {
      cid: uniqueId, //-- optional in case of Edge playback
      tid: new Date().getTime().toString(),
      to: allConnectionData?.deviceId,
      from: allConnectionData?.accountId,
      msg: {
        action: 'set',
        resource: 'edge/playback',
        properties: {
          uuid: allConnectionData?.sid, //-- optional in case of Edge playback
          org_id: allConnectionData?.orgId, //-- optional in case of Edge playback
          dev_id: allConnectionData?.deviceId, //-- optional in case of Edge playback
          start_time: actualTime, //-- epoch millisec
          duration: 30000, //-- +30000, -30000 (+ forward / - rewind)
          quality: quality, //-- HQ or SQ
          type: constants.WEBSOCKET_PLAYBACK_EVENT_PLAY,
        },
      },
    };
    Utils.vmsLogger().log(
      'PlayBack play command sent',
      Utils.getTimesinmili(),
      playPayload,
    );
    dataChannel?.send(JSON.stringify(playPayload));
  }
};

export const sendContinueCommand = (qualityData) => {
  quality = qualityData;
  const uniqueId = uuidv4();
  if (dataChannel?.readyState === 'open') {
    const playPayload = {
      cid: uniqueId, //-- optional in case of Edge playback
      tid: new Date().getTime().toString(),
      to: allConnectionData?.deviceId,
      from: allConnectionData?.accountId,
      msg: {
        action: 'set',
        resource: 'edge/playback',
        properties: {
          uuid: allConnectionData?.sid,
          org_id: allConnectionData?.orgId,
          dev_id: allConnectionData?.deviceId,
          duration: 30000, //-- +30000, -30000 (+ forward / - rewind)
          quality: quality, //-- HQ or SQ
          type: constants.WEBSOCKET_PLAYBACK_EVENT_CONTINUE,
        },
      },
    };
    const bufferEnd =
      videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(0);
    if (
      !(parseInt(nextAvailTime / 1000, 10) > parseInt(bufferEnd, 10)) &&
      !isNoVideo
    ) {
      sendComplete = false;
      Utils.vmsLogger().log(
        'PlayBack continue command sent',
        Utils.getTimesinmili(),
      );
      dataChannel?.send(JSON.stringify(playPayload));
    }
  }
};

export const sendStopCommand = (isAutoPlayVal) => {
  isAutoPlay = isAutoPlayVal;
  const playbackPlayer = document.getElementById('playback-video');
  if (playbackPlayer) {
    playbackPlayer.autoplay = isAutoPlay;
  }
  const uniqueId = uuidv4();
  if (dataChannel?.readyState === 'open') {
    const playPayload = {
      cid: uniqueId,
      tid: new Date().getTime().toString(),
      to: allConnectionData?.deviceId,
      from: allConnectionData?.accountId,
      msg: {
        action: 'set',
        resource: 'edge/playback',
        properties: {
          uuid: allConnectionData?.sid,
          org_id: allConnectionData?.orgId,
          dev_id: allConnectionData?.deviceId,
          type: constants.WEBSOCKET_PLAYBACK_EVENT_STOP,
        },
      },
    };
    dataChannel?.send(JSON.stringify(playPayload));
  }
};

export const stopPlaybackConnection = () => {
  if (peerConnection && dataChannel?.readyState === 'open') {
    peerConnection?.close();
    peerConnection.onicecandidate = null;
    peerConnection.ontrack = null;
    peerConnection = null;
    dataChannel = null;
  }
  clearLocalTimer();
  cleanupResources();
};

const cleanupResources = () => {
  cleanVideoElements();
  dataChannel = null;
  allConnectionData = null;
  peerConnection = null;
  currentPlayStartTime = '';
};

export const cleanVideoElements = () => {
  if (
    !videoElement?.sourceBuffer?.updating &&
    videoElement?.mediaSource?.readyState === 'open'
  ) {
    videoElement?.mediaSource?.endOfStream();
  }
  // videoElement?.removeEventListener('ended', addNewVideoElement);
  videoElement?.removeEventListener('progress', handleVideoEvents);
  videoElement?.removeEventListener('canplay', handleVideoEvents);
  videoElement?.removeEventListener('error', handleVideoEvents);
  videoElement?.removeEventListener('waiting', handleVideoEvents);
  videoElement?.remove();
  videoElement = null;
  nextVideoElements.length = 0;
  videoContainer = null;
};

export const getPeerConnectionState = () => {
  return peerConnection;
};

export const clearLocalTimer = () => {
  if (virtualTimeInterval) {
    clearInterval(virtualTimeInterval);
  }
};

export const getSendComplete = () => {
  return sendComplete;
};

export const addVideoElementListener = (listener) => {
  videoElementListener = listener;
};

export const setEdgeIsAutoPlay = (isAutoPlayVal) => {
  isAutoPlay = isAutoPlayVal;
};
