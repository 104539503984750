import React, { useState } from 'react';
import {
  Collapse,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { usePoliciesStore } from '../../store/policiesStore';

import { HiMinus, HiMinusSm, HiPlus, HiPlusSm } from 'react-icons/hi';
import { IoEllipsisHorizontalSharp } from 'react-icons/io5';
import { constants, customEventName } from '../../helpers';
import './ContextMenu.scss';

const ContextMenu = ({
  iconButton = <IoEllipsisHorizontalSharp />,
  iconButtonOnClickCallback,
  menuId = null,
  menuItems,
  menuMinWidth = '160px',
  menuOnCloseCallback,
  customItemIconClass,
  customItemTextClass,
  customAnchorOrigin,
  customTransformOrigin,
  customData,
  disabledOptions = null,
  externalDisableCondition = false,
  isFromNotification = false,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const open = !!anchorEl;
  const submenuOpen = !!subMenuAnchorEl;
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData,
  );
  const getLoggedInUserPolicies = usePoliciesStore(
    (state) => state.getLoggedInUserPolicies,
  );
  const currentUserPolicies = getLoggedInUserPolicies();

  const handleClick = (event) => {
    if (!event) return;
    setAnchorEl(event?.currentTarget);
    // setIsMenuOpen((prev) => !prev);
    iconButtonOnClickCallback && iconButtonOnClickCallback();
  };

  const handleClose = () => {
    setAnchorEl(null);
    menuOnCloseCallback && menuOnCloseCallback();
  };

  const handleSubmenuToggle = (event) => {
    if (!event) return;

    setSubMenuAnchorEl((prev) => (!prev ? event.currentTarget : null));
    // setIsSubMenuOpen((prev) => !prev);
  };

  const handleMenuItemClick = (e, cb, index) => {
    if (!e || !cb) return;

    if (customData) {
      cb(e, customData, index);
    } else {
      cb(e, index);
    }
    handleClose();
    props?.setNotificationData && props.setNotificationData(customData);
    menuOnCloseCallback && menuOnCloseCallback();
  };

  const checkOptionEnablePermission = (menuItem) => {
    if (
      menuItem?.optionEnablePermission &&
      menuItem.optionEnablePermission === 'download_video_clip'
    ) {
      return !(
        currentUserPolicies?.manage_all_clips ||
        (currentUserPolicies?.[menuItem?.optionEnablePermission] &&
          customData?.subscribers?.find(
            (subscriber) =>
              subscriber?.sharedByAccountId === loggedInUserData.accountId,
          ) &&
          loggedInUserData?.canShareAndDownload)
      );
    } else if (
      menuItem?.optionEnablePermission &&
      menuItem.optionEnablePermission === 'create_contribute_and_view_incidents'
    ) {
      return !currentUserPolicies?.create_contribute_and_view_incidents;
    } else if (menuItem?.optionEnablePermission) {
      return !(
        currentUserPolicies?.manage_all_clips ||
        (currentUserPolicies?.[menuItem?.optionEnablePermission] &&
          customData?.subscribers?.find(
            (subscriber) =>
              subscriber?.sharedByAccountId === loggedInUserData.accountId,
          ))
      );
    }
    return false;
  };

  return (
    <div key={`more-icon-container more-icon-container-${menuId}`}>
      <IconButton
        aria-label="more"
        id={`more-button-${menuId}`}
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => {
          e?.preventDefault();
          e?.stopPropagation();
          handleClick(e);
        }}
        disableRipple={isSafari}
        disableTouchRipple={isSafari}
        disableFocusRipple={isSafari}
      >
        {iconButton}
      </IconButton>
      <Menu
        id={`menu-${menuId}`}
        key={`menu-${menuId}`}
        open={open}
        onClose={(e) => {
          e?.preventDefault();
          e?.stopPropagation();
          handleClose();
        }}
        className="context-menu "
        sx={{ width: 320, maxWidth: '100%' }}
        transitionDuration={0}
        MenuListProps={{
          'aria-labelledby': 'long-button',
          style: {
            minWidth: menuMinWidth,
            overflowY: isFromNotification ? 'hidden' : 'auto',
            height: isFromNotification ? '47%' : 'auto',
            maxHeight: isFromNotification ? '47%' : 'none',
          },
          className: 'context-menu-list',
        }}
        anchorOrigin={
          customAnchorOrigin
            ? customAnchorOrigin
            : {
                vertical: 'bottom',
                horizontal: 'left',
              }
        }
        transformOrigin={
          customTransformOrigin
            ? customTransformOrigin
            : {
                vertical: 'top',
                horizontal: 'left',
              }
        }
        anchorEl={anchorEl}
        onClick={(e) => {
          e?.stopPropagation();
        }}
      >
        {Array.isArray(menuItems) &&
          menuItems.map((menuItem, menuItemIndex) => {
            let isDisabled;
            if (!menuItem?.optionEnablePermission) {
              isDisabled =
                disabledOptions?.field === menuItem?.id &&
                disabledOptions?.shouldDisable &&
                disabledOptions?.shouldDisable(menuItemIndex) &&
                externalDisableCondition;
            } else {
              isDisabled = checkOptionEnablePermission(menuItem);
            }
            return (
              <MenuItem
                variant="menu"
                key={`menu-item-wrapper-${menuId}-${menuItemIndex}`}
                data-index={menuItemIndex}
                onClick={(e) => {
                  e?.preventDefault();
                  e?.stopPropagation();
                  handleMenuItemClick(e, menuItem?.clickHandler, menuItemIndex);
                }}
                disabled={isDisabled}
                disableRipple={isSafari}
                disableTouchRipple={isSafari}
                disableFocusRipple={isSafari}
              >
                {menuItem?.icon && (
                  <ListItemIcon
                    className={`menu-item-icon ${customItemIconClass}${
                      isDisabled ? ' disabled' : ''
                    }`}
                    disableRipple={isSafari}
                    disableTouchRipple={isSafari}
                    disableFocusRipple={isSafari}
                  >
                    {menuItem?.icon}
                  </ListItemIcon>
                )}
                {menuItem?.isExpandable ? (
                  <div className="menu-item-expandable">
                    <ListItemButton
                      className="menu-item-expandable-item"
                      onClick={(e) => {
                        e?.preventDefault();
                        e?.stopPropagation();
                        handleSubmenuToggle(e);
                      }}
                      disableRipple={isSafari}
                      disableTouchRipple={isSafari}
                      disableFocusRipple={isSafari}
                    >
                      <ListItemText
                        className={`${customItemTextClass}${
                          isDisabled ? ' disabled' : ''
                        }`}
                        primary={menuItem?.text}
                      />
                      {submenuOpen ? <HiMinusSm /> : <HiPlusSm />}
                    </ListItemButton>
                    <Collapse
                      in={submenuOpen}
                      timeout={isSafari ? 0 : 'auto'}
                      unmountOnExit
                    >
                      {menuItem?.submenuComponent(customData)}
                    </Collapse>
                  </div>
                ) : (
                  <ListItemText
                    className={`${
                      isFromNotification
                        ? 'menu-item-text notification-text'
                        : 'menu-item-text'
                    }   ${customItemTextClass}${isDisabled ? ' disabled' : ''}`}
                  >
                    {menuItem?.text}
                  </ListItemText>
                )}
              </MenuItem>
            );
          })}
      </Menu>
    </div>
  );
};

export default ContextMenu;
