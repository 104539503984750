import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSearchParams } from 'react-router-dom';
import {
  AssignLocations,
  SelectField,
  TextField,
  ToggleInput,
} from '../../components/forms';
import { constants, Utils, roles } from '../../helpers';
import { PrimaryButton, SiteToast } from '../../components/common';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { HiArrowLeft } from 'react-icons/hi2';
import axios from 'axios';
import { IoShareSocialOutline } from 'react-icons/io5';
import { usePoliciesStore } from '../../store/policiesStore';

// Schema for yup
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
    .matches(/^[a-zA-Z ]+$/, constants.ONLY_ALPHABETS_ERROR_MESSAGE)
    .required(constants.FIRST_NAME_REQUIRED_ERROR_MESSAGE),
  lastName: Yup.string()
    .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
    .matches(/^[a-zA-Z ]+$/, constants.ONLY_ALPHABETS_ERROR_MESSAGE)
    .required(constants.LAST_NAME_REQUIRED_ERROR_MESSAGE),
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      constants.EMAIL_VALID_ERROR_MESSAGE,
    )
    .required(constants.EMAIL_REQUIRED_ERROR_MESSAGE),
  phoneNumber: Yup.string()
    .min(0, constants.VALID_PHONE_NUMBER_ERROR_MESSAGE)
    .max(99999999999, constants.VALID_PHONE_NUMBER_ERROR_MESSAGE)
    .matches(/^\d+$/, constants.PHONE_NUMERIC_ALPHA_ERROR)
    .typeError(constants.VALID_PHONE_NUMBER_ERROR_MESSAGE),
});
function NewUser(props) {
  const [variant, setVariant] = useState('error');
  const [userMsg, setUserMsg] = useState('');
  const [searchParams] = useSearchParams();
  const [roleChange, setRolechange] = useState(false);
  const [locationsData, setLocationsData] = useState([]);
  const [hasAreaSelected, setHasAreaSelected] = useState(false);
  const [fetchLocationsStatus, setFetchLocationsStatus] = useState(false);
  const orgId = searchParams.get('orgId');

  const getCustomerOrgLocations = useCustomerOrgLocations(
    (state) => state.getCustomerOrgLocations,
  );
  const customerOrgLocationsData = useCustomerOrgLocations(
    (state) => state.customerOrgLocationsData,
  );
  const getCustomerOrgLocationsData = useCustomerOrgLocations(
    (state) => state.getCustomerOrgLocationsData,
  );
  const getAllFilteredLocations = useCustomerOrgLocations(
    (state) => state.getAllFilteredLocations,
  );
  const getAreasCount = useCustomerOrgLocations((state) => state.getAreasCount);
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies,
  );

  const loggedInUserPolicies = getCustomerOrgPolicies();

  const fetchLocations = async () => {
    try {
      await getCustomerOrgLocations(`partner/orgs/${orgId}/locations`);
    } catch (error) {
      setVariant('error');
      setUserMsg(error.message);
    }
  };

  useEffect(() => {
    orgId && fetchLocations();
  }, [orgId]);

  useEffect(() => {
    if (fetchLocationsStatus) {
      let modalTitleHtml = (
        <>
          <PrimaryButton
            className="btn btn-primary-outline p-0"
            type="button"
            width="auto"
            height="20px"
            borderWidth="0"
            hoverBorderWidth="0"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--primary_40',
            )}
            hoverColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--primary_40')}
            hoverBackgroundColor="transparent"
            backgroundColor="transparent"
            onClick={() => {
              handleBackButtonClick();
            }}
          >
            <HiArrowLeft size={15} /> {constants.BACK_BUTTON_TITLE}
          </PrimaryButton>
          <br />
          {constants.CUSTOMER_NEW_USER_ASSIGN_LOCATIONS_POPUP_TITLE}
        </>
      );
      props.handleModalTitle(modalTitleHtml);

      if (locationsData?.length <= 0) {
        checkAllLocationAreas();
        setHasAreaSelected(true);
      }
    }
  }, [fetchLocationsStatus]);

  const handleBackButtonClick = () => {
    setFetchLocationsStatus(!fetchLocationsStatus);
    props.handleModalTitle(props.modalTitle);
  };

  // === Get Location and Areas HTML
  const getLocationAndAreasFields = (locations) => {
    if (locations?.length >= 1) {
      let locationsHtml = locations.map((location) => {
        let areas = location?.areas;
        let areaHtml = '';
        if (areas) {
          areaHtml = areas.map((area) => {
            return (
              <div className="location-areas-item" key={area.areaId}>
                <label
                  className="area-form-check-label"
                  htmlFor={`area-${area.areaId}`}
                >
                  {area.areaName}
                </label>
                <input
                  className="area-form-check-input"
                  type="checkbox"
                  checked={
                    getAreaExists(area.areaId) ||
                    getAreaExists(area.areaId) === 'true'
                      ? true
                      : false
                  }
                  value=""
                  id={`area-${area.areaId}`}
                  onChange={(event) => {
                    handleAddRemoveAreas(
                      event,
                      location.locationId,
                      area.areaId,
                    );
                  }}
                />
              </div>
            );
          });
        }

        return (
          <div className="locations-list-wrap" key={location.locationId}>
            <div className="location-label">{location.locationName}</div>
            <div className="location-areas-list">{areaHtml}</div>
          </div>
        );
      });
      return locationsHtml;
    }
  };

  //=== Check if Area is checked or not
  const getAreaExists = (areaId) => {
    let newLocation = [...locationsData];
    let location = false;

    if (newLocation?.length > 0) {
      location = newLocation?.find((location) => {
        const area = location?.areas.find(
          (area) =>
            area.areaId === areaId &&
            (area.checked === true || area.checked === 'true'),
        );
        if (area) {
          return !!location.locationId;
        }
      });
    }
    return location;
  };

  //=== Check/uncheck areas
  const handleAddRemoveAreas = (event, locationId, areaId) => {
    let chkdValue = event.target.checked;
    let newLocation = [...locationsData];

    if (chkdValue) {
      //=== If checked
      if (newLocation.length === 0) {
        //=== If blank
        const userAreas = [];
        if (areaId) {
          userAreas.push({ areaId: areaId, checked: true });
        }
        newLocation.push({ locationId: locationId, areas: userAreas });
        setLocationsData(newLocation);
      } else {
        //=== If not blank
        const foundLoc = newLocation.some((el) => el.locationId === locationId);
        if (foundLoc) {
          //=== if location already exits - add area into it
          newLocation = newLocation.filter((obj) => {
            let objArea = obj.areas;
            if (obj.locationId === locationId) {
              const foundArea = objArea.some((el) => el.areaId === areaId);
              if (!foundArea) {
                objArea.push({ areaId: areaId, checked: true });
                obj.areas = objArea;
              }
            }
            return obj;
          });
          setLocationsData(newLocation);
        } else {
          //=== if location not exits - add locaton & area both
          const userAreas = [];
          if (areaId) {
            userAreas.push({ areaId: areaId, checked: true });
          }
          newLocation.push({ locationId: locationId, areas: userAreas });
          setLocationsData(newLocation);
        }
      }
    } else {
      //=== If checked off - remove value from array
      const foundLoc = newLocation.some((el) => el.locationId === locationId);
      if (foundLoc) {
        const newLocations = newLocation.filter((obj) => {
          if (obj?.areas.length > 0) {
            const areaIndex = obj.areas.findIndex(
              (area) => area.areaId === areaId,
            );

            if (areaIndex !== -1) {
              obj.areas.splice(areaIndex, 1);
            }
          }

          return obj;
        });

        setLocationsData([...newLocations]);
      }
    }

    setHasAreaSelected(checkIfNoAreaSelected());
  };

  const checkIfNoAreaSelected = () => {
    const filteredAllLocations = getAllFilteredLocations();
    let hasAreaSelected = false;

    for (let location of locationsData) {
      if (location.areas.length > 0) {
        hasAreaSelected = true;
        break;
      }
    }
    if (filteredAllLocations && filteredAllLocations.length === 1) {
      hasAreaSelected = true;
    }
    return hasAreaSelected;
  };

  //=== Check all location areas
  const checkAllLocationAreas = () => {
    let newData = getAllFilteredLocations();

    try {
      if (newData?.length >= 1) {
        const allCheckedLocationData = newData.map((location) => {
          if (location?.areas) {
            location.areas = location?.areas.map((area) => {
              area.checked = true;
              return area;
            });
          }
          return location;
        });
        setLocationsData([...allCheckedLocationData]); //=== Update state to load data
        setHasAreaSelected(true);
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };

  const showAssignLocationStatus = (values) => {
    if (values?.role === '' || values.role === roles.ROLE2VMS) {
      return false;
    } else if (customerOrgLocationsData?.length > 1) {
      return true;
    } else if (
      customerOrgLocationsData?.length === 1 &&
      customerOrgLocationsData?.[0]?.areas?.length > 0
    ) {
      return false;
    } else {
      return false;
    }
  };

  return (
    <section className="modal-main-content">
      <Formik
        enableReinitialize
        initialValues={{
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: '',
          role: '',
          accountLanguage: 'en_US',
          canShareAndDownload: false,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          const controller = new AbortController();
          const { signal } = controller;
          setSubmitting(true);
          setUserMsg('');
          const filteredAllLocations = getAllFilteredLocations();
          // Simulate submitting to database
          if (orgId !== null && filteredAllLocations.length > 0) {
            // values.role = roleChange ? roles.ROLE2VMS : roles.ROLE3VMS;

            if (filteredAllLocations.length > 0) {
              if (filteredAllLocations.length === 1) {
                if (getAreasCount() === 1) {
                  values.locations = filteredAllLocations;
                } else {
                  const getCustomerOrgLocationsCompleteData =
                    getCustomerOrgLocationsData();
                  const updatedLocationData =
                    getCustomerOrgLocationsCompleteData?.map((location) => ({
                      ...location,
                      areas: location?.areas?.filter((area) => area.isDefault),
                    }));
                  const filteredUpdatedLocationData = updatedLocationData?.map(
                    (location) => ({
                      locationId: location.locationId,
                      areas: location.areas.map((area) => ({
                        areaId: area.areaId,
                      })),
                    }),
                  );
                  values.locations = filteredUpdatedLocationData;
                }
              } else {
                if (roleChange || values.role === roles.ROLE2VMS) {
                  values.locations = filteredAllLocations;
                } else {
                  let checkedLocationsData = [];
                  let areas,
                    userAreas = [];
                  if (locationsData?.length >= 1) {
                    locationsData.forEach((location) => {
                      areas = location?.areas;
                      userAreas = [];
                      if (areas?.length > 0) {
                        areas.forEach((area) => {
                          if (
                            area.checked === true ||
                            area.checked === 'true'
                          ) {
                            userAreas.push({ areaId: area.areaId });
                          }
                        });
                        checkedLocationsData.push({
                          locationId: location.locationId,
                          areas: userAreas,
                        });
                      }
                    });
                  }
                  values.locations = checkedLocationsData;
                }
              }
            }
            axios
              .post(`partner/orgs/${orgId}/accounts`, values, {
                ...Utils.requestHeader(),
                signal,
              })
              .then((res) => {
                let response = res.data;
                if (response.meta.code === 200) {
                  setSubmitting(false);
                  props.reloadData();
                  props.hideModal();
                } else {
                  setVariant('error');
                  setUserMsg(response?.meta?.userMsg);
                  setSubmitting(false);
                }
              })
              .catch(function (error) {
                setVariant('error');
                setUserMsg(error?.message);
                setSubmitting(false);
              });
          } else setSubmitting(false);
        }}
      >
        {({
          values,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
          setFieldValue,
        }) => (
          <>
            <Form className="modal-form" onSubmit={handleSubmit}>
              {!fetchLocationsStatus ? (
                <>
                  <TextField
                    required={true}
                    removebottommargin="true"
                    removetopmargin="true"
                    label=""
                    placeholder={constants.FIRST_NAME_FIELD}
                    name="firstName"
                    type="text"
                  />
                  <TextField
                    required={true}
                    removebottommargin="true"
                    removetopmargin="true"
                    label=""
                    placeholder={constants.LAST_NAME_FIELD}
                    name="lastName"
                    type="text"
                  />
                  <TextField
                    removebottommargin="true"
                    removetopmargin="true"
                    label=""
                    placeholder={constants.PHONE_NUMBER_FIELD}
                    name="phoneNumber"
                    maxLength={11}
                    onChange={(e) => {
                      const value = e.target.value;
                      setFieldValue('phoneNumber', value);
                    }}
                    type="text"
                  />
                  <TextField
                    required={true}
                    removebottommargin="true"
                    removetopmargin="true"
                    label=""
                    placeholder={constants.EMAIL_ADDRESS_FIELD}
                    name="email"
                    type="text"
                  />

                  <SelectField
                    isFromOrg={true}
                    name="role"
                    key="role"
                    options={props.roleOption ?? []}
                    onChange={(event) => {
                      setFetchLocationsStatus(false);
                      setHasAreaSelected(false);
                      setFieldValue('role', event.target.value);
                      if (event.target.value === roles.ROLE2VMS) {
                        setFieldValue('canShareAndDownload', true);
                      } else {
                        setFieldValue('canShareAndDownload', false);
                      }
                    }}
                    defaultOptionLabel={
                      constants.NEW_USER_SELECT_ROLE_DROPDOWN_DEFAULT_LABEL
                    }
                  />
                  {loggedInUserPolicies?.update_video_clip_share_option ? (
                    <div
                      className={`settings-block-share_recipients ${
                        values.role === roles.ROLE2VMS ? 'toggledisable' : ''
                      }`}
                    >
                      <IoShareSocialOutline className="add-share-icon"></IoShareSocialOutline>
                      <Col>
                        <ToggleInput
                          label={constants.ADD_NEW_USERS_SHARE_VIDEO_RECIPIENTS}
                          name="canShareAndDownload"
                          changeHandler={() =>
                            setFieldValue(
                              'canShareAndDownload',
                              !values.canShareAndDownload,
                            )
                          }
                          disabled={
                            values.role === roles.ROLE2VMS
                              ? true
                              : getCustomerOrgPolicies()
                                    ?.update_video_clip_share_option
                                ? false
                                : true
                          }
                          value={values.canShareAndDownload}
                          className="toggle-field-wrap"
                        />
                      </Col>
                    </div>
                  ) : null}
                  {showAssignLocationStatus(values) && (
                    <AssignLocations
                      label={constants.ASSIGN_LOCATIONS_AREAS_FIELD}
                      clickHandler={(evebr) => {
                        setHasAreaSelected(!fetchLocationsStatus);
                        setFetchLocationsStatus(!fetchLocationsStatus);
                      }}
                    />
                  )}
                </>
              ) : (
                <div className="locations-area-list-wrap">
                  {getCustomerOrgLocationsData()?.length >= 1 &&
                    getLocationAndAreasFields(getCustomerOrgLocationsData())}
                </div>
              )}

              <Form.Group
                as={Row}
                className="mt-3"
                style={{ display: fetchLocationsStatus ? 'block' : 'none' }}
              >
                <Col>
                  <PrimaryButton
                    className="btn btn-primary w-100"
                    type="button"
                    height="44px"
                    fontSize="14px"
                    onClick={() => checkAllLocationAreas()}
                  >
                    {constants.CUSTOMER_NEW_USER_ASSIGN_LOCATIONS_BUTTON_TITLE}
                  </PrimaryButton>
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mt-3"
                style={{ display: fetchLocationsStatus ? 'none' : 'block' }}
              >
                <Col>
                  <PrimaryButton
                    className="btn btn-primary w-100"
                    type="submit"
                    disabled={
                      !(dirty && isValid) ||
                      !values?.role ||
                      (showAssignLocationStatus(values) && !hasAreaSelected) ||
                      (showAssignLocationStatus(values) &&
                        checkIfNoAreaSelected() === false)
                    }
                    loader={isSubmitting}
                    height="44px"
                    fontSize="14px"
                  >
                    {constants.NEW_USER_SUBMIT_BUTTON_TITLE}
                  </PrimaryButton>
                </Col>
              </Form.Group>
            </Form>

            {/* Show error messages */}
            <SiteToast
              title={
                variant === 'error'
                  ? constants.ERROR_TOAST_TITLE
                  : constants.SUCCESS_TOAST_TITLE
              }
              show={!!userMsg}
              body={userMsg}
              variant={variant}
              position="top-center"
            />
          </>
        )}
      </Formik>
    </section>
  );
}

export default NewUser;
